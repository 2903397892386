import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import FormControl from '../../../Form/v001/FormControl';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class ResourceEntry extends React.PureComponent {
  state = {
    refreshId: 0
  }
  refresh = (label) => {
    if (label === 'Component') {
      let part = this.props.Resource.Fields.find(x => x.Label === 'Part');
      if (part) {
        part.Value = '';
      }
    }

    this.setState({ refreshId: this.state.refreshId + 1 });
  }

  getCostText = (fields) => {
    let result = '';
    let part = fields.find(x => x.Label === 'Part');
    let qty = fields.find(x => x.Label === 'Qty');
    let cost = fields.find(x => x.Label === 'Cost');

    if (part && qty && part.Value && qty.Value) {
      let lookup = helpers.getLookupItem(part.Value);
      if (lookup && !isNaN(Number(lookup.Name2) && !isNaN(Number(qty.Value)))) {
        result = `$${Number(lookup.Name2).toFixed(2)} * ${qty.Value} = $${(Number(lookup.Name2) * Number(qty.Value)).toFixed(2)}`;
        cost.Value = Number((Number(lookup.Name2) * Number(qty.Value)).toFixed(2));
      }
    }

    return result;
  }

  render() {
    let fields = this.props.Resource.Fields;
    if (fields.find(x => x.ControlType === 'DatePicker') && fields.find(x => x.ControlType === 'DatePicker').Value === 'now') {
      let dateObj = new Date();
      let month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
      let day = ('0' + dateObj.getDate()).slice(-2);
      let year = dateObj.getUTCFullYear();
      fields.find(x => x.ControlType === 'DatePicker').Value = year + '-' + month + '-' + day;
    }

    return (
      <div className="resource-entry">
        <div className="entry-label" style={{ backgroundColor: this.props.Resource.HeaderColor }}>
          <div>{this.props.Resource.Label + ' Entry'}</div>
        </div>
        <div className="fields">
          {this.props.Resource.Fields.filter(x => x.Label).map((x, idx) => (
            <React.Fragment key={idx}>
              {x.ControlType !== 'CostCalculation' && <FormControl control={x} trackChanges={false} allProps={this.props.Resource.Fields} refresh={this.refresh} forceRefreshId={this.state.refreshId} />}
              {x.ControlType === 'CostCalculation' &&
                <div className="resource-cost">
                  <div>{this.getCostText(this.props.Resource.Fields)}</div>
                </div>}
              { }
            </React.Fragment>
          ))}
        </div>
        <div className="entry-button-container">
          <div className="entry-button entry-add" onClick={() => { this.props.isEditMode ? this.props.updateRow(this.props.Resource) : this.props.addRow(this.props.Resource) }}>
            <div>{this.props.isEditMode ? 'Update' : 'Add'}</div>
          </div>
          {/* <div className="entry-button entry-clear" onClick={this.props.clearEntry}>
            <div>Clear</div>
          </div> */}
          {!this.props.isEditMode ? null :
            <div className="entry-button entry-remove" onClick={this.props.removeRow}>
              <div>Remove</div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ResourceEntry);