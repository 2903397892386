import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state.ent_Current,
    Selected: state.ent_Selected,
    CurrentEvent: state.ent_CurrentEvent,
    TransferDate: state.SelectedTimeSlot,
    EventSave: state.btn_EventSave,
    UserId: state.CurrentUser ? state.CurrentUser.Id : null
  };
};

export class UB_Title_Submit extends React.PureComponent {

  componentDidMount() {
    console.log('create a new final');
    console.log(this.props.Selected);

    if (!this.props.CurrentEvent)
      return;

    if (!this.props.TransferDate) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: {
          Message: 'Select a Transfer Date to continue',
          Title: 'Transfer Date Required',
          Buttons: []
        }
      }));
      return;
    }

    let statusProp = this.props.CurrentEvent.Properties.find(x => x.Name === 'StatusLId');
    if (!statusProp) {
      console.log('cannot find status prop on current event.  Submit failed.');
      return;
    }

    let lookups = helpers.getLookupItems(statusProp.LookupSetId);
    let subStatus = lookups.find(x => x.Name1 === 'Submitted');

    if (!subStatus) {
      console.log('submitted lookup item not found.  Submit failed.');
      return;
    }

    helpers.addSaveData(this.props.dispatch, { ...statusProp.SaveData, SaveQueue: 'dbo_EventSaveData' }, statusProp.Name, subStatus.LookupItemId);
    helpers.addSaveData(this.props.dispatch, { ...statusProp.SaveData, SaveQueue: 'dbo_EventSaveData' }, 'AssignedEId', this.props.UserId);

    let tdateProp = this.props.CurrentEvent.Properties.find(x => x.Name == 'TransferDate');
    if (tdateProp) {
      tdateProp.Value = this.props.TransferDate.SelectedDate;
      //console.log('set tdate prop to: ' + helpers.dateToInputFormat(new Date(this.props.TransferDate.SelectedDate)));
    }

    helpers.addSaveData(this.props.dispatch, { ...tdateProp.SaveData, SaveQueue: 'dbo_EventSaveData' }, 'TransferDate', tdateProp.Value);

    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_EventSave',
      Value: {
        ...this.props.EventSave,
        ClickId: this.props.EventSave.ClickId + 1
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_Title_Submit);