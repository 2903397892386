import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEvent: state.ent_CurrentEvent,
    SelectedEvent: state.ent_SelectedEvent,
    SaveData: state.dbo_EventSaveData || [],
    CurrentService: state.ent_CurrentService,
    EventSave: state.btn_EventSave
  };
};

export class UBMeterExchange extends React.PureComponent {

  componentDidMount() {
    if (!this.props.CurrentEvent || !this.props.CurrentService || this.props.CurrentEvent.EntityTypeId !== 2020104)
      return;

    let updateProp = this.props.CurrentEvent.Properties.find(x => x.Name === 'UBUpdateCorresponding' && x.Value);

    if (!updateProp)
      return;

    let exampleServiceProp = this.props.CurrentService.Properties.find(x => x.Name === 'AcctId');

    if (!exampleServiceProp) {
      console.log('Could not find acctId in service properties.  Meter exchange scriptrunner failed.');
      return;
    }

    let baseObj = {
      Id: exampleServiceProp.SaveData.Id,
      InsertKey: null,
      Table: exampleServiceProp.SaveData.Table,
      IsEventData: true,
      IsBaseTable: true
    }

    let newMeterName = this.props.CurrentEvent.Properties.find(x => x.Name === 'NewMeterNumber');
    let newEndpoint = this.props.CurrentEvent.Properties.find(x => x.Name === 'NewMeterEndpointNum');
    let newDiameter = this.props.CurrentEvent.Properties.find(x => x.Name === 'NewMeterDiameter');

    if (!newMeterName || !newEndpoint || !newDiameter) {
      console.log('Looking for NewMeterNumber, NewMeterEndpointNum, NewMeterDiameter properties on the meter exchange event.  One or more of those does not exist.');
      return;
    }

    let saveData = [
      helpers.saveDataItem(baseObj, 'Serial#', newMeterName.Value),
      helpers.saveDataItem(baseObj, 'EndpointId', newEndpoint.Value),
      helpers.saveDataItem(baseObj, 'Diameter', newDiameter.Value)
    ];

    setTimeout(() => {
      saveData.forEach(sd => {
        this.props.dispatch(actions.AddSaveData(sd));
      });

      setTimeout(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'btn_EventSave',
          Value: {
            ...this.props.EventSave,
            ClickId: this.props.EventSave.ClickId + 1
          }
        }));
      }, 100);
    }, 0);


    // setTimeout(() => {
    //   batch(() => {

    //   });
    // }, 100);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UBMeterExchange);