import React from 'react';
import { connect } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from './../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';
import * as icons from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  // let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'TextSource');
  return {
    Contact: state.ent_Selected,
    Data: state.usr_UserActivityData || {}
  };
};

export class UserActivity extends React.PureComponent {

  componentDidMount() {
    let data = this.getContactData();
    if (!data.init) {
      this.setData('init', true);
      this.getActivity();
    }
  }

  getActivity = () => {
    let data = this.getContactData();

    let body = {
      ContactId: this.props.Contact.EntityId,
      ActivityDate: data.ActivityDate ? new Date(new Date(data.ActivityDate).toISOString()).toJSON() : null
    }

    actions.ApiRequest('Logging/UserActivity', body, (result) => {
      if (!result || result.length === 0) {
        this.setData('Rows', [{ Path: 'No Records', Date: '', Body: {} }])
        return;
      }
      this.setData('Rows', result);
    });
  }

  getContactData = () => {
    return this.props.Data[this.props.Contact.EntityId] || {};
  }

  setData = (prop, val, refreshRows = false) => {
    let contactData = this.getContactData();
    contactData[prop] = val;

    this.props.dispatch(actions.UpdateProp({
      Key: 'usr_UserActivityData',
      Value: {
        ...this.props.Data,
        [this.props.Contact.EntityId]: contactData
      }
    }));

    if (refreshRows) {
      setTimeout(() => { this.getActivity() }, 0);
    }
  }

  PrettyObjectString = (obj) => {
    let result = [];
    for (var key in obj) {
      var propVal = obj[key];
      if (typeof propVal !== 'object') {
        result.push(key + ': ' + (typeof propVal === 'function' ? ' [Function]' : propVal.toString()));
      }
      if (typeof propVal === 'object' && !Array.isArray(propVal)) {
        for (var key2 in propVal) {
          var propVal2 = obj[key][key2];
          if (typeof propVal2 !== 'object') {
            result.push(key + '.' + key2 + ': ' + (typeof propVal2 === 'function' ? ' [Function]' : propVal2.toString()));
          }
          if (typeof propVal2 === 'object' && !Array.isArray(propVal2)) {
            for (var key3 in propVal2) {
              var propVal3 = obj[key][key2][key3];
              if (typeof propVal3 !== 'object') {
                result.push(key + '.' + key2 + '.' + key3 + ': ' + (typeof propVal3 === 'function' ? ' [Function]' : propVal3.toString()));
              }
            }
          }
        }
      }
    }
    return result;
  }

  render() {
    let data = this.getContactData();
    let activityDate = data.ActivityDate;

    if (!activityDate) {
      activityDate = new Date();
      activityDate.setMinutes(activityDate.getMinutes() - activityDate.getTimezoneOffset());
      activityDate = activityDate.toISOString().slice(0, -1);
    }

    return (
      <div className="user-activity">
        <div className="activity-label">User Activity</div>
        <div className="activity-time">
          <div>Show records before</div>
          <input type="datetime-local" value={activityDate} onChange={(e) => { this.setData('ActivityDate', e.target.value, true) }} />
        </div>
        <div className="activity-rows">
          {(data.Rows || []).map((x, idx) => (
            <div key={idx} className="activity-row">
              <div className="log-title">
                <div className="log-path">{x.Path}</div>
                <div className="log-date">{x.Date ? (helpers.shortDate(new Date(x.Date)) + ' ' + (new Date(x.Date).toLocaleTimeString())) : ''}</div>
              </div>
              <div className="log-body">
                {this.PrettyObjectString(x.Body).map((objLine, objIdx) => (
                  <div key={objIdx}>{objLine}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(UserActivity);