import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../../Utils/Helpers';
import TextField from './FieldTypes/TextField';
import DateField from './FieldTypes/DateField';
import LookupField from './FieldTypes/LookupField';
import BoolField from './FieldTypes/BoolField';

const mapStateToProps = (state, ownProps) => {
  return {
    Filter: ownProps.settings.PendingFilter,
    ConfirmedFilter: ownProps.settings.Filter,
    Query: state.qry_Queries[state.qry_SelectedQuery.QueryId],
    RefreshCurrentPage: state.qry_RefreshCurrentPage
  };
};

export class BaseFilter extends React.PureComponent {
  filterOptions = [
    { Value: 's_', Label: 'Starts With', Sql: "{column} LIKE '{value}%'", NeedsValue: true, DataType: 'String' },
    { Value: '_e', Label: 'Ends With', Sql: "{column} LIKE '%{value}'", NeedsValue: true, DataType: 'String' },
    { Value: '.c.', Label: 'Contains', Sql: "{column} LIKE '%{value}%'", NeedsValue: true, DataType: 'String' },
    { Value: '=', Label: 'Equals', Sql: "{column} = {value}", NeedsValue: true, DataType: 'Number' },
    { Value: '≠', Label: 'Does Not Equal', Sql: "{column} != {value}", NeedsValue: true, DataType: 'Number' },
    { Value: '>', Label: 'Greater Than', Sql: "{column} > {value}", NeedsValue: true, DataType: 'Number' },
    { Value: '<', Label: 'Less Than', Sql: "{column} < {value}", NeedsValue: true, DataType: 'Number' },
    { Value: '><', Label: 'Between', Sql: "{column} >= {value} AND {column} <= {value2}", NeedsValue: true, DataType: 'Number', TwoValues: true },
    { Value: '[ ]', Label: 'Empty', Sql: "ISNULL({column}, '') = ''", NeedsValue: false, DataType: 'String' },
    { Value: '[~]', Label: 'Not Empty', Sql: "ISNULL({column}, '') != ''", NeedsValue: false, DataType: 'String' },
    { Value: '[ ]', Label: 'Empty', Sql: "{column} IS NULL", NeedsValue: false, DataType: 'Number' },
    { Value: '[~]', Label: 'Not Empty', Sql: "{column} IS NOT NULL", NeedsValue: false, DataType: 'Number' },
    { Value: '⟵', Label: 'Before', Sql: "{column} < '{value}'", NeedsValue: true, DataType: 'Date' },
    { Value: '⟶', Label: 'After', Sql: "{column} > '{value}'", NeedsValue: true, DataType: 'Date' },
    { Value: '⟷', Label: 'Between', Sql: "{column} >= '{value}' AND {column} <= '{value2}'", NeedsValue: true, DataType: 'Date', TwoValues: true },
    { Value: '[ ]', Label: 'Empty', Sql: "{column} IS NULL", NeedsValue: false, DataType: 'Date' },
    { Value: '[~]', Label: 'Not Empty', Sql: "{column} IS NOT NULL", NeedsValue: false, DataType: 'Date' },
    { Value: 'lookup', Label: 'Not Empty', Sql: "", NeedsValue: false, DataType: 'Lookup' },
    { Value: 'bool', Label: 'Not Empty', Sql: "", NeedsValue: false, DataType: 'Bool', BoolEmpty: true, BoolTrue: true, BoolFalse: true },
  ]

  state = {
    ShowFilters: false,
    Editing: false
  }

  constructor(props) {
    super(props);
    this.primaryInput = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.windowClick);
    document.addEventListener('keyup', this.keyPress);
    if (!this.props.Filter.Mode) {
      this.loadDefaultOption();
    }
  }

  componentDidUpdate() {
    if (!this.props.Filter.Mode) {
      this.loadDefaultOption();
    }
  }

  setEditing = (val) => {
    if (this.state.Editing !== val) {
      this.setState({ Editing: val });
      if (val) {
        this.focusPrimaryInput();
      }
      if (val && ['Lookup', 'Bool'].includes(this.props.settings.DataType)) {
        this.setState({ ShowFilters: true });
      }
    }
  }

  loadDefaultOption = () => {
    let options = this.filterOptions.filter(x => [this.props.settings.DataType, 'All'].includes(x.DataType));
    this.setMode(options[0]);
  }

  onChange = (e, key) => {
    this.props.dispatch(actions.SetPendingFilter({
      colId: this.props.settings.ColumnId,
      filter: {
        ...this.props.Filter,
        [key]: e.target.value
      }
    }));
  }

  setMode = (val) => {
    if (this.state.ShowFilters) {
      this.showFilterToggle();
      this.focusPrimaryInput();
    }
    this.props.dispatch(actions.SetPendingFilter({
      colId: this.props.settings.ColumnId,
      filter: {
        ...this.props.Filter,
        Mode: { ...val }
      }
    }));
  }

  showFilterToggle = () => {
    console.log('toggle filter');
    let val = !this.state.ShowFilters;
    this.setState({ ShowFilters: val });
  }

  confirmFilter = () => {
    batch(() => {
      this.setEditing(false);
      this.props.dispatch(actions.SetTopRow({
        topRow: 0
      }));

      this.props.dispatch(actions.ConfirmFilter(this.props.settings.ColumnId));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_RefreshCurrentPage',
        Value: this.props.RefreshCurrentPage + 1
      }));
    });
  }

  clearFilter = () => {
    batch(() => {
      this.props.dispatch(actions.ClearFilter(this.props.settings.ColumnId));
      if (this.props.ConfirmedFilter) {
        this.props.dispatch(actions.SetTopRow({
          topRow: 0
        }));
        this.props.dispatch(actions.UpdateProp({
          Key: 'qry_RefreshCurrentPage',
          Value: this.props.RefreshCurrentPage + 1
        }));
      }

      setTimeout(() => { this.setEditing(false) }, 0);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowClick);
    document.removeEventListener('keyup', this.keyPress);
  }

  isDirty = () => {
    let current = this.props.Filter;
    let actual = this.props.ConfirmedFilter

    if (!current)
      return false;

    return (current && (current.Value || (current.Mode && !current.Mode.NeedsValue)) && !actual) ||
      (actual && current.Value !== actual.Value) ||
      (actual && current.Value2 !== actual.Value2) ||
      (actual && actual.Mode && current.Mode.Sql !== actual.Mode.Sql) ||
      (current && current.Mode && current.Mode.Value === 'lookup') ||
      (current && current.Mode && current.Mode.Value === 'bool');
  }

  toggleLookupItem = (idx) => {
    this.props.dispatch(actions.ToggleLookupItem({
      colId: this.props.settings.ColumnId,
      lookupIdx: idx
    }));
  }

  setLookupItems = (items) => {
    this.props.dispatch(actions.SetLookupItems({
      colId: this.props.settings.ColumnId,
      items: items
    }));
  }

  focusPrimaryInput = () => {
    setTimeout(() => {
      if (this.primaryInput.current)
        this.primaryInput.current.focus();
    }, 0);
  }

  windowClick = (e) => {
    let editingClasses = ['filter-col-' + this.props.settings.ColumnId, 'filter-mode-select'];
    if (this.state.Editing && !helpers.elementOrAncestorHasClass(e.target, editingClasses)) {
      this.setEditing(false);
    }

    let filterClasses = ['filter-mode-select', 'filter-mode-box', 'lookup-filter-container', 'bool-options-dropdown'];
    if (this.state.ShowFilters && !helpers.elementOrAncestorHasClass(e.target, filterClasses)) {
      this.setState({ ShowFilters: false });
    }
  }

  keyPress = (e) => {
    if (this.state.Editing && e.key === 'Escape') {
      this.setEditing(false);
    }

    if (this.state.Editing && this.isDirty() && e.key === 'Enter') {
      this.confirmFilter();
      e.target.blur();
    }
  }

  render() {
    let dataType = this.props.settings.DataType;
    let myOptions = this.filterOptions.filter(x => [dataType, 'All'].includes(x.DataType));
    let mode = this.props.Filter ? this.props.Filter.Mode : {};
    let isFiltered = this.props.Query[this.props.settings.ColumnId].Filter ? true : false;
    // console.log(this.props.settings);
    // console.log(this.props.Filter);
    // console.log(this.props.ConfirmedFilter);
    return (
      <div className="flex-box" onClick={() => { this.setEditing(true) }}>
        {
          !this.state.Editing && !isFiltered &&
          <div className={'type-filter text-filter filter-col-' + this.props.settings.ColumnId}>
            <input type="text" />
          </div>
        }
        {
          (this.state.Editing || isFiltered) && ['String', 'Number'].includes(dataType) &&
          <TextField mode={mode} myOptions={myOptions} dataType={dataType} parent={this} />
        }
        {
          (this.state.Editing || isFiltered) && dataType === 'Date' &&
          <DateField mode={mode} myOptions={myOptions} dataType={dataType} parent={this} />
        }
        {
          (this.state.Editing || isFiltered) && dataType === 'Lookup' &&
          <LookupField mode={mode} myOptions={myOptions} dataType={dataType} parent={this} />
        }
        {
          (this.state.Editing || isFiltered) && dataType === 'Bool' &&
          <BoolField mode={mode} myOptions={myOptions} dataType={dataType} parent={this} />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(BaseFilter);