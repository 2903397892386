import React from 'react';
import { connect } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from './../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    Log: state.MobileLog
  };
};

export class Text extends React.PureComponent {
  render() {
    let logs = this.props.Log.map(x => x.text).reverse();
    return (
      this.props.Log.length === 0 ? null :
        <div className="mobile-log">
          {logs.map((x, idx) => (
            <div key={idx} className="log-item">{x}</div>
          ))}
        </div>
    );
  }
}

export default connect(mapStateToProps)(Text);