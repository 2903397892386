import React from 'react';
import { connect } from 'react-redux';

export default class ModeSelector extends React.PureComponent {
  optionActive = (val) => {
    return (this.props.Filter.Mode.Value === val ? 'active' : '');
  }

  render() {
    return (
      <div className="filter-mode-select">
        {this.props.filterOptions.map((opt, idx) => (
          <div key={idx} className={this.optionActive(opt.Value)} onClick={() => { this.props.setMode(opt) }}>
            <div className="filter-mode-val">
              <div>{opt.Value}</div>
            </div>
            <div className="filter-mode-label">{opt.Label}</div>
          </div>
        ))}
      </div>
    );
  }
}
