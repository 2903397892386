import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as hub from './../../../../Utils/PubSub';
import bhFilter from './Filter';
import bhFloatingFilter from './FloatingFilter';
import DropDownEditor from './Editors/DropDown';
import FormControlCell from './Editors/FormControlCell';

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let tableName = ownProps.widget.Parameters.find(x => x.ParameterName === 'TableName');
  let parameterRequired = ownProps.widget.Parameters.find(x => x.ParameterName === 'ParameterRequired');
  let refreshBucketsOnSave = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshBucketsOnSave');

  let swid = ownProps.widget.SceneWidgetId
  let dataKey = '_agTable' + swid;

  var data = null
  var columnDefs = null
  if(state[dataKey]) {
    data = state[dataKey].Rows
    columnDefs = state[dataKey].ColumnDefs
  }

  var grouped = []
  var columnData = null
  if(data != null) {
    if(swid === 5479) {
      grouped = data.reduce(function (memo, x, i) {
        let index = -1
        if(memo.length > 0) {
          index = memo.findIndex(e => e.costPerUnit === `$${x["*$/Unit "]}` && e.costPerGate === `$${x['*$/Gate']}` && e.type === columnDefs[0].valMap[`${x.ddbucket4_Type}`] && e.lengthPerUnit === x['*Length/Unit'] && e.style === columnDefs[1].valMap[`${x['*ddbucket5_Style']}`])
        } else {
          memo = []
        }
        if(index > -1) {
          //memo[index].style.push(columnDefs[1].valMap[`${x['*ddbucket5_Style']}`])
          memo[index].color.push(columnDefs[2].valMap[`${x['*ddbucket6_Color']}`])
          memo[index].height.push(columnDefs[3].valMap[`${x['*ddbucket7_Height']}`])
        } else {
          let type = columnDefs[0].valMap[`${x.ddbucket4_Type}`]
          let style = columnDefs[1].valMap[`${x['*ddbucket5_Style']}`]
          let colorArr = [columnDefs[2].valMap[`${x['*ddbucket6_Color']}`]]
          let heightArr = [columnDefs[3].valMap[`${x['*ddbucket7_Height']}`]]
          let row = {type: type, style: style, color: colorArr, height: heightArr, costPerUnit: `$${x["*$/Unit "]}`, costPerGate: `$${x['*$/Gate']}`, lengthPerUnit: x['*Length/Unit'], agId: i}
          
          memo.push(row)
        }
        return memo
      }, {})
      if(grouped.length > 0) {
        grouped = grouped.map((row, i) => {
          if(row.color.length > 1) {
            row.color = row.color.filter((value, index, array) => array.indexOf(value) === index);
          }
          if(row.height.length > 1) {
            row.height = row.height.filter((value, index, array) => array.indexOf(value) === index);
          }
          return row
        })
      }
      columnData = [
        {
          headerName: 'Type',
          field: 'type',
          hide: true,
          rowGroup: true
        },
        {
          headerName: 'Style',
          field: 'style',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Color',
          field: 'color',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Height',
          field: 'height',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: '$/Unit',
          field: 'costPerUnit',
          maxWidth: 100,
          resizable: true
        },
        {
          headerName: 'Length/Unit',
          field: 'lengthPerUnit',
          maxWidth: 125,
          resizable: true
        },
        {
          headerName: '$/Gate',
          field: 'costPerGate',
          maxWidth: 100,
          resizable: true
        }
      ]
    } else if (swid === 5579) {
      grouped = data.reduce(function (memo, x, i) {
        let index = -1
        if(memo.length > 0) {
          index = memo.findIndex(e => e.costArea === `$${x["*$/Area"]}` && e.service === columnDefs[0].valMap[`${x.ddbucket10_Service}`])
        } else {
          memo = []
        }
        if(index > -1) {
          memo[index].type.push(columnDefs[1].valMap[`${x['*ddbucket11_Type']}`])
          memo[index].stain.push(columnDefs[2].valMap[`${x['*ddbucket12_Wash']}`])
        } else {
          let service = columnDefs[0].valMap[`${x.ddbucket10_Service}`]
          let typeArr = [columnDefs[1].valMap[`${x['*ddbucket11_Type']}`]]
          let stainArr = [columnDefs[2].valMap[`${x['*ddbucket12_Wash']}`]]
          let row = {service: service, type: typeArr, stain: stainArr, costArea: `$${x["*$/Area"]}`, agId: i}
          
          memo.push(row)
        }
        return memo
      }, {})
      if(grouped.length > 0) {
        grouped = grouped.map((row, i) => {
          if(row.type.length > 1) {
            row.type = row.type.filter((value, index, array) => array.indexOf(value) === index);
          }
          if(row.stain.length > 1) {
            row.stain = row.stain.filter((value, index, array) => array.indexOf(value) === index);
          }
          return row
        })
      }
      columnData = [
        {
          headerName: 'Service',
          field: 'service',
          hide: true,
          rowGroup: true
        },
        {
          headerName: 'Type',
          field: 'type',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Wash',
          field: 'stain',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: '$/Area',
          field: 'costArea',
          maxWidth: 100,
          resizable: true
        }
      ]
    } else if (swid === 5580) {
      grouped = data.reduce(function (memo, x, i) {
        let index = -1
        if(memo.length > 0) {
          index = memo.findIndex(e => e.costArea === `$${x["*$/Area"]}` && e.type === columnDefs[0].valMap[`${x.ddbucket13_Type}`])
        } else {
          memo = []
        }
        if(index > -1) {
          memo[index].stain.push(columnDefs[1].valMap[`${x['*ddbucket14_Wash']}`])
        } else {
          let type = columnDefs[0].valMap[`${x.ddbucket13_Type}`]
          let stainArr = [columnDefs[1].valMap[`${x['*ddbucket14_Wash']}`]]
          let row = {type: type, stain: stainArr, costArea: `$${x["*$/Area"]}`, agId: i}
          
          memo.push(row)
        }
        return memo
      }, {})
      if(grouped.length > 0) {
        grouped = grouped.map((row, i) => {
          if(row.stain.length > 1) {
            row.stain = row.stain.filter((value, index, array) => array.indexOf(value) === index);
          }
          return row
        })
      }
      columnData = [
        {
          headerName: 'Type',
          field: 'type',
          hide: true,
          rowGroup: true
        },
        {
          headerName: 'Wash',
          field: 'stain',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: '$/Area',
          field: 'costArea',
          maxWidth: 100,
          resizable: true
        }
      ]
    } else if (swid === 5581) {
      grouped = data.reduce(function (memo, x, i) {
        let index = -1
        if(memo.length > 0) {
          index = memo.findIndex(e => e.costPerUnit === `$${x["*$/Unit"]}` && e.service === columnDefs[0].valMap[`${x.ddbucket15_Service}`])
        } else {
          memo = []
        }
        if(index > -1) {
          memo[index].type.push(columnDefs[1].valMap[`${x['*ddbucket16_Type']}`])
          memo[index].height.push(columnDefs[2].valMap[`${x['*ddbucket17_Height']}`])
          memo[index].stain.push(columnDefs[3].valMap[`${x['*ddbucket18_Wash']}`])
        } else {
          let service = columnDefs[0].valMap[`${x.ddbucket15_Service}`]
          let typeArr = [columnDefs[1].valMap[`${x['*ddbucket16_Type']}`]]
          let heightArr = [columnDefs[2].valMap[`${x['*ddbucket17_Height']}`]]
          let stainArr = [columnDefs[3].valMap[`${x['*ddbucket18_Wash']}`]]
          let row = {service: service, type: typeArr, height: heightArr, stain: stainArr, costPerUnit: `$${x["*$/Unit"]}`, lengthPerUnit: `${x["*Length/Unit"]}`, agId: i}
          
          memo.push(row)
        }
        return memo
      }, {})
      if(grouped.length > 0) {
        grouped = grouped.map((row, i) => {
          if(row.type.length > 1) {
            row.type = row.type.filter((value, index, array) => array.indexOf(value) === index);
          }
          if(row.stain.length > 1) {
            row.stain = row.stain.filter((value, index, array) => array.indexOf(value) === index);
          }
          if(row.height.length > 1) {
            row.height = row.height.filter((value, index, array) => array.indexOf(value) === index);
          }
          return row
        })
      }
      columnData = [
        {
          headerName: 'Service',
          field: 'service',
          hide: true,
          rowGroup: true
        },
        {
          headerName: 'Type',
          field: 'type',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Height',
          field: 'height',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Wash',
          field: 'stain',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: '$/Unit',
          field: 'costPerUnit',
          maxWidth: 100,
          resizable: true
        },
        {
          headerName: 'Length/Unit',
          field: 'lengthPerUnit',
          maxWidth: 150,
          resizeable: true
        }
      ]
    } else if (swid === 5644) {
      grouped = data.reduce(function (memo, x, i) {
        let index = -1
        if(memo.length > 0) {
          index = memo.findIndex(e => e.costPerArea === `$${x["*$/Area"]}` && e.costPerGate === `$${x['*$/Stair']}` && e.type === columnDefs[0].valMap[`${x.ddbucket19_Type}`])
        } else {
          memo = []
        }
        if(index > -1) {
          memo[index].color.push(columnDefs[2].valMap[`${x['*ddbucket21_Color']}`])
          memo[index].height.push(columnDefs[3].valMap[`${x['*ddbucket22_Height']}`])
        } else {
          let type = columnDefs[0].valMap[`${x.ddbucket19_Type}`]
          let style = columnDefs[1].valMap[`${x['*ddbucket20_Style']}`]
          let colorArr = [columnDefs[2].valMap[`${x['*ddbucket21_Color']}`]]
          let heightArr = [columnDefs[3].valMap[`${x['*ddbucket22_Height']}`]]
          let row = {type: type, style: style, color: colorArr, height: heightArr, costPerArea: `$${x["*$/Area"]}`, costPerGate: `$${x['*$/Stair']}`, agId: i}
          
          memo.push(row)
        }
        return memo
      }, {})
      if(grouped.length > 0) {
        grouped = grouped.map((row, i) => {
          if(row.color.length > 1) {
            row.color = row.color.filter((value, index, array) => array.indexOf(value) === index);
          }
          if(row.height.length > 1) {
            row.height = row.height.filter((value, index, array) => array.indexOf(value) === index);
          }
          return row
        })
      }
      columnData = [
        {
          headerName: 'Type',
          field: 'type',
          hide: true,
          rowGroup: true
        },
        {
          headerName: 'Style',
          field: 'style',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Color',
          field: 'color',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Height',
          field: 'height',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: '$/Area',
          field: 'costPerArea',
          maxWidth: 100,
          resizable: true
        },
        {
          headerName: '$/Stair',
          field: 'costPerGate',
          maxWidth: 100,
          resizable: true
        }
      ]
    } else if (swid === 5686) {
      grouped = data.reduce(function (memo, x, i) {
        let index = -1
        if(memo.length > 0) {
          index = memo.findIndex(e => e.costPerArea === `$${x["*$/Area"]}` && e.costPerGate === `$${x['*$/Stair']}` && e.condition === columnDefs[0].valMap[`${x.ddbucket27_Condition}`])
        } else {
          memo = []
        }
        if(index > -1) {
          memo[index].color.push(columnDefs[1].valMap[`${x['*ddbucket28_Color']}`])
          memo[index].height.push(columnDefs[2].valMap[`${x['*ddbucket29_Height']}`])
        } else {
          let condition = columnDefs[0].valMap[`${x.ddbucket27_Condition}`]
          let colorArr = [columnDefs[1].valMap[`${x['*ddbucket28_Color']}`]]
          let heightArr = [columnDefs[2].valMap[`${x['*ddbucket29_Height']}`]]
          let row = {condition: condition, color: colorArr, height: heightArr, costPerArea: `$${x["*$/Area"]}`, costPerGate: `$${x['*$/Stair']}`, agId: i}
          
          memo.push(row)
        }
        return memo
      }, {})
      if(grouped.length > 0) {
        grouped = grouped.map((row, i) => {
          if(row.color.length > 1) {
            row.color = row.color.filter((value, index, array) => array.indexOf(value) === index);
          }
          if(row.height.length > 1) {
            row.height = row.height.filter((value, index, array) => array.indexOf(value) === index);
          }
          return row
        })
      }
      columnData = [
        {
          headerName: 'Condition',
          field: 'condition',
          hide: true,
          rowGroup: true
        },
        {
          headerName: 'Color',
          field: 'color',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Height',
          field: 'height',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: '$/Area',
          field: 'costPerArea',
          maxWidth: 100,
          resizable: true
        },
        {
          headerName: '$/Stair',
          field: 'costPerGate',
          maxWidth: 100,
          resizable: true
        }
      ]
    } else if (swid === 5744) {
      grouped = data.reduce(function (memo, x, i) {
        let index = -1
        if(memo.length > 0) {
          index = memo.findIndex(e => e.costPerArea === `$${x["*$/Square"]}` && e.type === columnDefs[0].valMap[`${x.ddbucket30_Type}`])
        } else {
          memo = []
        }
        if(index > -1) {
          memo[index].material.push(columnDefs[1].valMap[`${x['*ddbucket31_Material']}`])
          memo[index].height.push(columnDefs[2].valMap[`${x['*ddbucket32_Height']}`])
          memo[index].pitch.push(columnDefs[3].valMap[`${x['*ddbucket33_Pitch']}`])
        } else {
          let type = columnDefs[0].valMap[`${x.ddbucket30_Type}`]
          let materialArr = [columnDefs[1].valMap[`${x['*ddbucket31_Material']}`]]
          let heightArr = [columnDefs[2].valMap[`${x['*ddbucket32_Height']}`]]
          let pitchArr = [columnDefs[3].valMap[`${x['*ddbucket33_Pitch']}`]]

          let row = {type: type, material: materialArr, height: heightArr, pitch: pitchArr, costPerArea: `$${x["*$/Square"]}`, agId: i}

          memo.push(row)
        }
        return memo
      }, {})
      if(grouped.length > 0) {
        grouped = grouped.map((row, i) => {
          if(row.material.length > 1) {
            row.material = row.material.filter((value, index, array) => array.indexOf(value) === index);
          }
          if(row.height.length > 1) {
            row.height = row.height.filter((value, index, array) => array.indexOf(value) === index);
          }
          if(row.pitch.length > 1) {
            row.pitch = row.pitch.filter((value, index, array) => array.indexOf(value) === index);
          }
          return row
        })
      }
      columnData = [
        {
          headerName: 'Type',
          field: 'type',
          hide: true,
          rowGroup: true
        },
        {
          headerName: 'Material',
          field: 'material',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Height',
          field: 'height',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: 'Pitch',
          field: 'pitch',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          width: 650
        },
        {
          headerName: '$/Square',
          field: 'costPerArea',
          maxWidth: 120,
          resizable: true
        }
      ]
    }
  }

  return {
    Parameter: parameter ? helpers.getListParameter(state, parameter.ParameterValue) : null,
    TableName: tableName ? tableName.ParameterValue : null,
    ColumnData: columnData,
    RowData: grouped.length > 0 ? grouped : null,
    TableData: state[dataKey],
    DataKey: dataKey,
    SWID: swid,
    SaveId: state.dbo_SaveId,
    CancelId: state.dbo_CancelId,
    DropdownBuckets: state.met_DropdownBuckets,
    ParameterRequired: parameterRequired ? helpers.stringToBool(parameterRequired.ParameterValue) : false,
    RefreshBucketsOnSave: refreshBucketsOnSave ? helpers.stringToBool(refreshBucketsOnSave.ParameterValue) : false,
    BucketRefreshId: state.BucketRefreshId
  };
}

let _saveId = null;

export class AgTable extends React.PureComponent {
  state = {
    showGrid: false,
    defaultColDef: {
      flex: 1,
      // cellDataType: false,
      editable: false,
      filter: false,
      floatingFilterComponent: bhFloatingFilter,
      filter: bhFilter,
      floatingFilter: false,
      menuTabs: [],
      suppressKeyboardEvent: (params) => {
        return this.suppressNavigation(params);
      }
    },
    groupRowRendererParams: {
      innerRenderer: (data) => {
        let coldef = data.columnApi.columnModel.columnDefs.find(x => x.field = data.node.field && x.rowGroup);
        if (coldef && coldef.valMap) {
          return coldef.valMap[data.value] || null;
        }
        return data.value || null;
      }
    },
    gridOptions: {
      rowClassRules: {
        "deleted-row": (params) => {
          return params.data && params.data['*IsDeleted'];
        }
      },
      debounceVerticalScrollbar: true,
      showLoadingOverlay: false,
      rowHeight: 40,
      rowBuffer: 20,
      suppressMenuHide: true,
      headerHeight: 45,
      // floatingFiltersHeight: 25,
      editType: 'fullRow',
      deltaRowDataMode: true,
      getRowId: (e) => {
        return e.data.agId;
      },
      getRowNodeId: (e) => {
        return e.data.agId;
      }
    }
  }

  componentDidMount() {
    if (_saveId !== null && _saveId !== this.props.SaveId && this.props.RefreshBucketsOnSave) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'RefreshDropDownBuckets',
        Value: true
      }));
    }

    _saveId = this.props.SaveId;

    if (this.props.TableData) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.DataKey,
        Value: null
      }))
    } else {
      this.getData();
    }
  }

  componentDidUpdate(prev) {
    if (this.props.RefreshBucketsOnSave && prev.SaveId !== this.props.SaveId) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'RefreshDropDownBuckets',
        Value: true
      }));
    }

    if (prev && this.props.BucketRefreshId !== prev.BucketRefreshId) {
      if (this.gridApi) {
        this.gridApi.refreshCells({ force: true });
      }
    }

    if (!this.props.TableData && !this.state.showGrid) {
      this.getData();
    }
    else if (prev && (prev.SaveId !== this.props.SaveId || prev.CancelId !== this.props.CancelId)) {
      this.getData();
    }
    else if (prev && prev.Parameter && JSON.stringify(prev.Parameter) !== JSON.stringify(this.props.Parameter)) {
      this.getData();
    }
  }

  resetData = () => {
    if (!this.props.TableData)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.DataKey,
      Value: { ...this.props.TableData, Rows: [] }
    }))
  }

  getData = () => {
    if (this.props.ParameterRequired && !this.props.Parameter)
      return;

    let body = {
      Name: this.props.TableName,
      Parameter: JSON.stringify(this.props.Parameter || {})
    }

    actions.ApiRequest('List/AgTableData', body, (result) => {
      if (!result)
        return;

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.DataKey,
        Value: this.parseResponse(result)
      }))
      setTimeout(() => {
        if (this.gridApi)
          this.gridApi.refreshCells({ force: true });

        this.setState({ showGrid: true });
      }, 0);
    });
  }

  parseResponse = (data) => {
    return {
      Rows: data.Rows.map((x, idx) => { return { ...x, agId: idx } }),
      ColumnDefs: this.props.TableData ? this.props.TableData.ColumnDefs : this.parseColumnDefs(data),
      ColumnMetadata: data.Columns,
      SaveQueue: data.SaveQueue,
    };
  }

  parseColumnDefs = (data) => {
    let colWidths = data.ColumnWidths ? data.ColumnWidths.split(' ').map(x => x.trim()) : [];
    let colIdx = 0;
    return data.Columns.map((col) => {
      let coldef = {
        field: col.Field,
        editable: false,
        ControlType: col.ControlType,
        LookupSetId: col.LookupSetId,
        Table: col.SourceTable,
        Column: col.SourceColumn,
        headerName: col.ColumnName,
        cellEditorPopup: false,
        TableNoSchema: col.TableName,
        SaveQueue: data.SaveQueue,
        flex: 1
      }

      if (col.IsGrouped) {
        coldef.rowGroup = true;
        coldef.hide = true;
        colIdx--;
      }

      if (col.IsEditable) {
        coldef.cellRenderer = FormControlCell;
      }

      if (col.ControlType === 'BucketDropDown') {
        let bucket = this.props.DropdownBuckets.find(x => x.Id == coldef.LookupSetId);
        if (bucket) {
          coldef.valMap = bucket.BucketData.reduce((prev, next) => {
            prev[next.Key] = next.Value;
            return prev;
          }, {});
        }
      }

      if (col.ControlType === 'DropDown') {
        coldef.valMap = helpers.getLookupItems(coldef.LookupSetId).reduce((prev, next) => {
          prev[next.LookupItemId] = next.Name1;
          return prev;
        }, {});
      }

      if (col.ControlType === 'IsDeleted') {
        coldef.width = 50;
        delete coldef.flex;
      }

      let width = colWidths[colIdx];
      if (width) {
        if (width.includes('fr')) {
          coldef.flex = Number(width.replace('fr', ''));
        }
        if (width.includes('px')) {
          coldef.width = Number(width.replace('px', ''));
          delete coldef.flex;
        }
      }

      colIdx++;
      return coldef;
    });
  }

  suppressNavigation = (params) => {
    var KEY_TAB = 'Tab';
    var KEY_DOWN = 'ArrowDown';
    var KEY_UP = 'ArrowUp';

    var event = params.event;
    var key = event.key;

    var keysToSuppress = [
      KEY_TAB,
      KEY_UP,
      KEY_DOWN
    ];

    var suppress = keysToSuppress.some(function (suppressedKey) {
      return suppressedKey === key || key.toUpperCase() === suppressedKey;
    });

    return suppress;
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  render() {
    return (
      !this.props.TableData || !this.state.showGrid ? null :
        <div className="ag-table-widget ag-theme-alpine">
          <AgGridReact
            columnDefs={this.props.ColumnData}
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}
            rowData={this.props.RowData}
            gridOptions={this.state.gridOptions}
            groupDisplayType={'groupRows'}
            groupRowRendererParams={this.state.groupRowRendererParams}
            rememberGroupStateWhenNewData={true}
          />
        </div>
    );
  }
}

export default connect(mapStateToProps)(AgTable);





