import React from 'react';
import { connect } from 'react-redux';
import * as actions from './../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    topRow: state.qry_Queries[ownProps.queryId].topRow,
    pinnedRows: state.qry_Queries[ownProps.queryId].pinnedRows || [],
    refreshPage: state.qry_RefreshCurrentPage
  };
};



export class RowNumbers extends React.PureComponent {
  state = {
    rowCount: 0
  }

  componentDidMount() {
    this.getRowCount();
    window.addEventListener('resize', this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  windowResize = () => {
    console.log('window resize');
    this.getRowCount();
  }

  getRowCount = () => {
    let gridBody = document.getElementsByClassName('query-grid')[0];
    let rowCount = Math.floor(gridBody.offsetHeight / 30);

    this.props.dispatch(actions.UpdateProp({
      Key: 'qry_VisibleRows',
      Value: rowCount
    }));

    this.setState({ rowCount: rowCount });
    setTimeout(() => {
      this.refreshPage();
    }, 150);
  }

  refreshPage = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'qry_RefreshCurrentPage',
      Value: this.props.refreshPage + 1
    }));
  }

  togglePin = (rowId) => {
    this.props.dispatch(actions.ToggleRowPin(rowId));
    this.props.dispatch(actions.SetHighlightRow(null));
  }

  render() {
    return (
      <div className='row-numbers'>
        {this.props.pinnedRows.map((x, idx) => (
          <div key={idx} className="row-num pinned" onClick={() => { this.togglePin(x) }}>
            <div>
              {x + 1}
            </div>
          </div>
        ))}
        {[...Array(this.state.rowCount)].map((x, idx) => (
          !this.props.pinnedRows.includes(this.props.topRow + idx) &&
          <div key={idx} className="row-num" onClick={() => { this.togglePin(this.props.topRow + idx) }}>
            <div>
              {this.props.topRow + idx + 1}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default connect(mapStateToProps)(RowNumbers);