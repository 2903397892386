import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state) => {
  return {
    SelectedEvent: state.ent_SelectedEvent
  };
}

export class EventDialog extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.keyPress);
  }

  closeDialog = () => {
    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    })
  }

  keyPress = (e) => {
    if (e.keyCode === 27) { //esc
      this.closeDialog();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
  }

  render() {
    return (
      <div className="event-dialog">
        <div className="close-x" onClick={this.closeDialog}>✖</div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(EventDialog);