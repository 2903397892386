import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import Draw from 'ol/interaction/Draw';
import { Vector as LayerVector } from 'ol/layer'
import { Vector as SourceVector } from 'ol/source';
import { WKT, GeoJSON } from 'ol/format';
import Turf from '@turf/intersect';

const mapStateToProps = (state, ownProps) => {
  return {
    DrawBtn: state.btn_FreeformDraw,
    GeoFilter: state.qry_GeoFilter
  };
}

export class FreehandSelection extends React.PureComponent {

  componentDidUpdate(prev) {
    let map = this.props.map;
    let oldId = prev && prev.DrawBtn ? prev.DrawBtn.ClickId : 0;
    let curId = this.props.DrawBtn ? this.props.DrawBtn.ClickId : 0;
    if (oldId != curId) {
      if (map.drawInteraction) {
        this.stopDrawing();
      } else {
        this.startDrawing();
      }
    }
  }

  stopDrawing = () => {
    let map = this.props.map;
    if (!map || !map.drawInteraction)
      return;
    
    map.removeInteraction(map.drawInteraction);
    map.drawInteraction = null;
  }

  startDrawing = () => {
    let map = this.props.map;
    if (!map)
      return;

    if (map.drawInteraction) {
      map.removeInteraction(map.drawInteraction);
    }

    let source = this.getFreehandVector();
    let draw = new Draw({
      source: source,
      type: 'Polygon',
      freehand: true
    });

    draw.on('drawend', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let wktFormat = new WKT();
      let geoJsonFormat = new GeoJSON();

      e.feature.setGeometry(e.feature.getGeometry().simplify(e.target.getMap().getView().getResolution() * 3));

      let invalid = false;
      if (this.props.GeoFilter) {
        let poly1 = this.props.GeoFilter.GeoJSON;
        let poly2 = geoJsonFormat.writeFeatureObject(e.feature);

        let intersect = Turf(poly1.geometry, poly2.geometry);

        if (intersect) {
          let olFet = geoJsonFormat.readFeatureFromObject(intersect);
          e.feature.setGeometry(olFet.getGeometry());
        } else {
          invalid = true;
        }
      }

      if (!invalid) {
        setTimeout(() => {
          let layer = map.getLayers().getArray().find(x => x.LayerId === 'Freehand');
          layer.getSource().clear();
          layer.getSource().addFeature(e.feature);

          this.props.dispatch(actions.UpdateProp({
            Key: 'qry_GeoFilter',
            Value: {
              WKT: wktFormat.writeGeometry(e.feature.getGeometry().clone().transform('EPSG:3857', map.projName)),
              GeoJSON: geoJsonFormat.writeFeatureObject(e.feature),
              olFet: e.feature,
              Id: helpers.getId()
            }
          }));
        }, 0);
      } else {
        setTimeout(() => {
          let layer = map.getLayers().getArray().find(x => x.LayerId === 'Freehand');
          layer.getSource().clear();
          layer.getSource().addFeature(this.props.GeoFilter.olFet);
        }, 0);
      }

      this.stopDrawing();
    });

    map.addInteraction(draw);
    map.drawInteraction = draw;
  }

  getFreehandVector = () => {
    let layers = this.props.map.getLayers().getArray();
    let drawLayer = layers.find(x => x.LayerId === 'Freehand');
    let vector;

    if (!drawLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: false,
        source: vector,
        updateWhileInteracting: true
      });

      layer.setZIndex(1111);
      layer.LayerId = 'Freehand';
      layer.Vector = vector;
      this.props.map.addLayer(layer);
    } else {
      vector = drawLayer.Vector;
    }

    return vector;
  }

  mouseDown = (e) => {
    let map = this.props.map;
    if (e.which === 3 && map && map.drawInteraction) {
      e.preventDefault();
      e.stopPropagation();
      this.stopDrawing();
      this.startDrawing();
    }
  }

  componentDidMount() {
    document.body.addEventListener('contextmenu', this.mouseDown)
  }

  componentWillUnmount() {
    document.body.removeEventListener('contextmenu', this.mouseDown)
  }

  render() {
    return (
      null
    )
  }
}

export default connect(mapStateToProps)(FreehandSelection);