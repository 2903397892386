import React from 'react';
import { batch } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';

const MySalesLeads = props => {

  let onClick = () => {
    props.dispatch(actions.UpdateProp({
      Key: 'ent_SelectedEvent',
      Value: {
        EntityId: props.card.ConsumerId,
        EntityTypeId: 2040001
      }
    }));
    props.dispatch(actions.UpdateProp({
      Key: 'SelectedActivity',
      Value: null
    }));

    batch(() => {
      props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene',
        Value: {
          Value: 'BudgetModal',
          Enabled: true
        }
      }));
      props.dispatch(actions.UpdateProp({
        Key: 'BudgetImage',
        Value: 'https://bhpublic.blob.core.windows.net/imagepicker/MapSnapshot/' + props.card.TenantId + '/' + props.card.ConsumerId + '/BudgetMap.jpg'
      }));
    });
  }

  return (
    <React.Fragment>
      <div className={"leads-card"} onClick={onClick}>
        <div className="color-bar" style={{ background: props.card.Color }}></div>
        <div className="leads-top-bar">
          <div className="estimate"><div>{props.card.Estimate}</div></div>
          <div className="budget-date"><div>{props.card.Date}</div></div>
        </div>
        <div className="consumer-name"><div>{(props.card.Name.trim() || ' ')}</div></div>
        <div className="leads-bottom-bar">
          <div className="budget-distance"><div>{props.card.Distance}</div></div>
          <div className="budget-status"><div>{props.card.Status}</div></div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MySalesLeads


