
import { Vector as LayerVector } from 'ol/layer'
import { Vector as SourceVector } from 'ol/source';
import VectorImage from 'ol/layer/VectorImage'
import Point from 'ol/geom/Point';
import { bbox } from 'ol/loadingstrategy';
import Tiles from '../MapData/Tiles';
import StyleEngine from '../Styles/StyleEngine';
import StyleFunction from './../Styles/FeatureStyle';
import * as helpers from './../../../../../Utils/Helpers';

let _library = null;

const getLayer = (options) => {
  if (!_library) {
    _library = StyleEngine.getStyleLibrary();
  }

  let vectorStyle = StyleFunction.ApplyStyle;
  if (_library && options.DefaultStyleId && (!options.IsUserQueryLayer || options.Map.queryMapUseStyleId)) {
    vectorStyle = _library[options.DefaultStyleId];
  }

  let layersVector = getLayersVector(options);
  let layer = new VectorImage({
    renderMode: 'image',
    visible: true,
    source: layersVector,
    updateWhileInteracting: false,
    style: vectorStyle,
    maxResolution: 39.2
  });

  layer.Name = 'Internal';
  return layer;
}

const getLayersVector = (options) => {
  let map = options.Map;
  let projection = options.Map.projection;
  let layerId = options.LayerId;

  let vectorSource = new SourceVector({
    strategy: bbox,
    loader: (extent, resolution) => {
      if (!extent || isNaN(extent[0]) || isNaN(extent[1]) || map.GroupingMap)
        return;

      let pt1 = new Point([extent[0], extent[1]]).transform('EPSG:3857', map.projName).getCoordinates();
      let pt2 = new Point([extent[2], extent[3]]).transform('EPSG:3857', map.projName).getCoordinates();
      let tileRequest = {
        vectorSource: vectorSource,
        resolution: resolution,
        layerId: layerId,
        boundingBox: [pt1[0], pt1[1], pt2[0], pt2[1]],
        projection: map.GroupingMap ? map.statProj : projection,
        displayOnly: options.DisplayOnly,
        isUserQueryLayer: options.IsUserQueryLayer,
        defaultStyleId: options.DefaultStyleId
      };

      Tiles.requestTilesFromServer(map, tileRequest);
    }
  });

  vectorSource.isBeehiveSource = true;

  if (map.GroupingMap) {
    let tileRequest = {
      vectorSource: vectorSource,
      resolution: 10,
      layerId: layerId,
      boundingBox: [0, 0, 0, 0],
      projection: map.statProj,
      displayOnly: options.DisplayOnly,
      isUserQueryLayer: options.IsUserQueryLayer,
      defaultStyleId: options.DefaultStyleId
    };

    map.refreshGrouping = () => { Tiles.requestTilesFromServer(map, tileRequest); }
    map.refreshGrouping();
  }


  return vectorSource;
}

export default {
  getLayer: getLayer
}
