import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';
import * as Azure from '@azure/storage-blob';

const mapStateToProps = (state, ownProps) => {
  return {
    BlobSASPublic: state.BlobSASPublic,
    SelectedTenant: state.Controls.MySalesmanContractors,
    SelectedContractor: state.ent_SelectedEvent
  };
};

export class MV_SuperAdminCopyCatalog extends React.PureComponent {

  componentDidMount() {
    this.copyCatalog();
  }

  copyCatalog = async () => {
    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify({ CopyFromTenant: this.props.SelectedTenant, CopyToTenant: this.props.SelectedContractor.EntityId })
    }
    //call sproc to copy xmv.Item and xmv.ItemFilter entries for new tenantId
    actions.ApiRequest('List/GetList', body, async (result) => {
      console.log(result);

      result.forEach(async item => {
        //create new image paths in blob storage at 2058/itemId/itemId
        let oldBlobPath = item.EntityTypeId + '/' + item.OldItemId + '/Item';
        let newBlobPath = item.EntityTypeId + '/' + item.NewItemId + '/Item';

        // get container client
        let containerClient = this.getBlobContainerClient();

        // get/make blob clients
        const sourceBlobClient = await containerClient.getBlobClient(oldBlobPath);
        const destinationBlobClient = await containerClient.getBlobClient(newBlobPath);

        // copy
        const copyPoller = await destinationBlobClient.beginCopyFromURL(sourceBlobClient.url);
      })

      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Success', Message: <p>Catalog has been successfully copied over to {this.props.SelectedContractor.PrimaryText}</p> }
      }));

    });
  }

  getBlobContainerClient = () => {
    let blobSaS = this.props.BlobSASPublic;
    let blobServiceClient = new Azure.BlobServiceClient(blobSaS.Key);
    let containerName = '';
    return blobServiceClient.getContainerClient(containerName);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_SuperAdminCopyCatalog);