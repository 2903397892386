import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CycleOperation: state.ent_CycleOperation
  };
};

export class UB_Cycle_Itron_Export extends React.PureComponent {

  componentDidMount() {

    let opsBtn = document.querySelector('.ci-btn-ITRON.Export');

    if (opsBtn.classList.contains('disabled'))
      return;

    opsBtn.classList.add('disabled');

    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify({
        ...this.props.CycleOperation,
        Action: 'ITRON EXPORT'
      })
    }

    actions.ApiRequest('Document/UBItronExport', body, () => {
      opsBtn.classList.remove('disabled');
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_Cycle_Itron_Export);
