import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import { Vector as LayerVector } from 'ol/layer'
import { Vector as SourceVector } from 'ol/source';
import { buffer } from 'ol/extent';
import StyleEngine from './../Styles/StyleEngine';
import StyleFunction from './../Styles/FeatureStyle';
import WKT from 'ol/format/WKT';
import * as helpers from './../../../../../Utils/Helpers';
import { Style, Circle, Fill, Stroke } from 'ol/style';

const mapStateToProps = (state, ownProps) => {
  let saveQueue = null;
  if (ownProps.map && ownProps.map.Layout && ownProps.map.Layout.SaveQueue) {
    saveQueue = ownProps.map.Layout.SaveQueue;
  }

  return {
    EntityMetadata: state.met_EntityMetadata,
    PanToCard: state.map_PanToCard,
    SaveQueue: saveQueue ? state[saveQueue] : undefined,
    CancelId: state.dbo_CancelId,
    SaveId: state.dbo_SaveId
  };
}

export class EntityZoom extends PureComponent {
  panning = false;
  state = {
    styles: null,
    hasZoomed: false,
    // defaultStyle: [new Style({
    //   fill: new Fill({
    //     color: 'rgba(255, 255, 255, 0.2)',
    //   }),
    //   stroke: new Stroke({
    //     color: 'rgba(46, 41, 0, 1)',
    //     width: 6,
    //   }),
    // }), new Style({
    //   type: 'LineString',
    //   stroke: new Stroke({
    //     color: 'rgba(255, 190, 10, 0.9)',
    //     width: 3,
    //   }),
    // }),]
  }

  componentDidUpdate(prevProps) {
    this.zoomIfNeeded(prevProps);
    this.panToCardIfNeeded();
  }

  componentDidMount() {
    this.zoomIfNeeded();
    this.panToCardIfNeeded();
  }

  zoomIfNeeded = (prevProps) => {
    if (!this.props.map)
      return;

    if (prevProps && (prevProps.CancelId !== this.props.CancelId)) {
      this.setState({ hasZoomed: false });
      this.props.map.entityLookupComplete = false;
      this.props.dispatch(actions.UpdateProp({
        Key: 'map_GeoReset',
        Value: { SkipSaveData: true }
      }));
      return;
    }

    let saveRefresh = this.props.RefreshOnSave && prevProps && prevProps.SaveId !== this.props.SaveId;

    let entity = this.props.Entity;
    if (!entity || (!saveRefresh && this.state.hasZoomed && prevProps && prevProps.Entity && entity.EntityId === prevProps.Entity.EntityId && entity.EntityTypeId === prevProps.Entity.EntityTypeId))
      return;

    if (!this.props.map || !this.props.map.ZoomToAsset)
      return;

    if (!this.state.styles) {
      this.setState({ styles: StyleEngine.getStyleLibrary() });
    }

    if (this.props.SaveQueue && this.props.SaveQueue.find(x => x.Column === 'Geometry'))
      return;

    this.resetSource();

    this.doLookupAndZoom(entity);
    this.setState({ hasZoomed: true });
  }

  doLookupAndZoom = (entity) => {
    this.getEntityGeometry(entity, (result) => {
      if (result && result.Geometry && result.Geometry !== '') {
        this.setEntityGeometry(result.Geometry);
        this.addEntityToMap(result.Geometry, entity);
        this.zoomToFeature(helpers.entId(entity));
      }

      this.props.map.entityLookupComplete = true;
      this.geometryRefresh();

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey + 'OnGeoLoad',
        Value: true
      }));
    });
  }

  geometryRefresh = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'map_GeometryRefresh',
      Value: helpers.getId()
    }));
  }

  getEntityGeometry = (entity, callback) => {
    if (entity.IsNew) {
      callback();
      return;
    }

    let body = {
      EntityId: entity.EntityId,
      EntityTypeId: entity.EntityTypeId,
    }

    actions.ApiRequest('map/GetEntity', body, callback);
  }

  addEntityToMap = (geometry, entity) => {
    let source = this.getCurrentEntitySource();
    this.resetSource();
    let feature = this.getFeatureFromWKT(geometry, entity);
    if (this.props.CurrentEntity) {
      this.props.CurrentEntity.CurrentType = feature.getGeometry().getType();
    }
    source.addFeature(feature);
  }

  getCurrentEntitySource = () => {
    let curEntityLayer = this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');

    if (!curEntityLayer) {
      curEntityLayer = this.createCurrentEntityLayer();
      this.props.map.addLayer(curEntityLayer);
    }

    return curEntityLayer.getSource();
  }

  createCurrentEntityLayer = () => {
    let layer = new LayerVector({
      source: new SourceVector({}),
      renderMode: 'vector',
      maxResolution: 30000,
      visible: true,
      renderBuffer: 600,
      updateWhileInteracting: false,
      style: StyleFunction.SelectedStyle
    });

    layer.setZIndex(1001);
    layer.id = 'CurrentEntityLayer';
    layer.Name = 'CurrentEntityLayer';

    return layer;
  }

  resetSource = () => {
    let src = this.getCurrentEntitySource();
    src.clear();
  }

  getFeatureFromWKT = (geometry, entity) => {
    let feature = new WKT().readFeature(geometry, {
      dataProjection: this.props.map.projection,
      featureProjection: 'EPSG:3857'
    });

    feature.geometry = geometry;
    feature.setId(helpers.entId(entity));
    let og = feature.getGeometry().clone();
    feature.originalGeo = og;
    return feature;
  }

  zoomToFeature = (id) => {
    let feature = this.getFeatureInLayers(id);
    if (!feature)
      return;

    this.selectFeature(feature);
    let olGeo = feature.getGeometry();
    if (olGeo && olGeo.geometry) {
      this.setEntityGeometry(olGeo.geometry);
    }

    //disabling zoom if ParentSWID is set
    //child maps copy parent position - this prevents overriding that
    if (!this.props.map.Layout.ParentSWID) {
      let extent = feature.getGeometry().getExtent();
      this.props.map.getView().fit(buffer(extent, 15), this.props.map.getSize());
      let zoom = this.props.map.getView().getZoom();
      if (zoom > 19) {
        this.props.map.getView().setZoom(19);
      }
    }
  }

  selectFeature = (feature) => {
    let selection = this.props.map.entitySelect;
    feature.doNotLoadEntity = true;
    selection.getFeatures().clear()
    selection.getFeatures().push(feature);
    let source = this.getCurrentEntitySource();
    this.resetSource();
    source.addFeature(feature);
  }

  getFeatureInLayers = (id) => {
    if (!id)
      return null;

    let feature = null;
    let layers = this.props.map.getLayers().getArray();

    layers.forEach(layer => {
      let source = layer.getSource();
      if (!source.getFeatures)
        return;

      let fet = source.getFeatureById(id);
      if (fet) {
        feature = fet;
      }
    });

    return feature;
  }

  panToCardIfNeeded = () => {
    if (this.panning)
      return;

    let card = this.props.PanToCard;
    if (card) {
      this.panning = true;

      this.getEntityGeometry(card, (result) => {
        if (result && result.Geometry && result.Geometry !== '') {
          let extentFet = this.getFeatureFromWKT(result.Geometry, card);
          this.props.map.getView().fit(extentFet.getGeometry().getExtent(), { size: this.props.map.getSize(), maxZoom: this.props.map.getView().getZoom() });
        }
        this.props.dispatch(actions.UpdateProp({
          Key: 'map_PanToCard',
          Value: null
        }));
        this.panning = false;
      });
    }
  }

  setEntityGeometry = (geo) => {
    if (!this.props.CurrentEntity)
      return;

    this.props.CurrentEntity.Geometry = geo;
  }

  render() {
    return null
  }
}

export default connect(mapStateToProps)(EntityZoom);


