import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state.ent_Current,
    NewItem: state.ent_NewItem,
    SWID: ownProps.SWID,
    ConsumerId: state.ms_ActiveConsumerId,
    TenantId: state.met_EntityMetadata.CurrentTenant
  };
};

export class MV_GetConsumer extends React.PureComponent {

  componentDidMount() {
    // this.props.dispatch(actions.UpdateProp({
    //   Key: 'sr_MV_GetCost',
    //   Value: true
    // }))
    if (window.IsMobile) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'MainTabContent',
        Value: { Value: 'Step3Panel', Enabled: true }
      }))
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'PanelContent',
        Value: { Value: 'Step3Panel', Enabled: true }
      }))
    }

    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify({ ConsumerId: this.props.ConsumerId, TenantId: this.props.TenantId })
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      result.forEach(x => {
        if (x.ImageCatalog) {
          x.CatalogOptions = JSON.parse(x.ImageCatalog);
          this.props.dispatch(actions.UpdateProp({
            Key: 'ms_CurrentCatalog',
            Value: x.ImageCatalog
          }))
        }
        if (x.CatalogSelected) {
          let catalogSelected = JSON.parse(x.CatalogSelected)[0];
          x.CatalogSelected = [catalogSelected, catalogSelected, catalogSelected, catalogSelected];
        }
      })
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_SelectedPolygons',
        Value: result
      }))
      this.props.dispatch(actions.UpdateProp({
        Key: 'sr_MV_GetCost',
        Value: true
      }))
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_ConsumerLoaded',
        Value: true
      }))
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_GetConsumer);