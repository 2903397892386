import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep'
import DesktopInterface from './Desktop/DesktopInterface';
import MobileInterface from './Mobile/MobileInterface';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { transformWithProjections } from 'ol/proj';

const mapStateToProps = (state, ownProps) => {
  let entKeyProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let entityKey = entKeyProp ? entKeyProp.ParameterValue : 'ent_CurrentEvent';

  let componentLid = 1102;
  let partLid = 1103;

  let alternateComponentLidTypes = ownProps.widget.Parameters.find(x => x.ParameterName === 'AlternateComponentLidTypes');
  let alternateComponentLid = ownProps.widget.Parameters.find(x => x.ParameterName === 'AlternateComponentLid');
  let alternatePartLid = ownProps.widget.Parameters.find(x => x.ParameterName === 'AlternatePartLid');

  if (alternateComponentLidTypes && alternateComponentLid) {
    let altTypes = alternateComponentLidTypes.ParameterValue.split(',').map(x => Number(x));
    if (altTypes.includes(state.ent_Current.EntityTypeId)) {
      componentLid = Number(alternateComponentLid.ParameterValue);
      partLid = Number(alternatePartLid.ParameterValue);
    }
  }

  let data = {
    EntityKey: entityKey,
    Time: {
      Label: 'Time',
      HeaderColor: '#7b354d',
      Fields: [
        {
          GobAutofill: entityKey + '.EntityId',
          Value: '',
          SaveData: {
            Table: 'ttr.TimeEntry',
            Column: 'EntityId'
          }
        },
        {
          GobAutofill: entityKey + '.EntityTypeId',
          Value: '',
          SaveData: {
            Table: 'ttr.TimeEntry',
            Column: 'EntityTypeId'
          }
        },
        {
          Key: 'Id',
          Value: '',
        },
        {
          Label: 'Date',
          Key: 'StartDate',
          ControlType: 'DatePicker',
          Width: '90px',
          Value: '',
          DefaultValue: 'now',
          SaveData: {
            Table: 'ttr.TimeEntry',
            Column: 'StartDate'
          }
        },
        {
          Label: 'User',
          Key: 'UserId',
          ControlType: 'UserList',
          Width: '140px',
          Value: '',
          LookupSetId: 'UserList',
          SaveData: {
            Table: 'ttr.TimeEntry',
            Column: 'UserId'
          }
        },
        {
          Label: 'Duration',
          Key: 'Duration',
          ControlType: 'NumberBox',
          Width: '85px',
          Value: '',
          MinuteToHours: true,
          SaveData: {
            Table: 'ttr.TimeEntry',
            Column: 'Duration'
          }
        },
        {
          Label: 'Note',
          Key: 'Note',
          ControlType: 'RichTextBox',
          Width: '1',
          Value: '',
          SaveData: {
            Table: 'ttr.TimeEntry',
            Column: 'Note'
          }
        }],
      Rows: []
    },
    Material: {
      Label: 'Material',
      HeaderColor: '#536142',
      Fields: [
        {
          Key: 'Id',
          Value: '',
        },
        {
          GobAutofill: entityKey + '.EntityId',
          Value: '',
          SaveData: {
            Table: 'mtl.EntityItem',
            Column: 'EntityId'
          }
        },
        {
          GobAutofill: entityKey + '.EntityTypeId',
          Value: '',
          SaveData: {
            Table: 'mtl.EntityItem',
            Column: 'EntityTypeId'
          }
        },
        {
          Label: 'Component',
          Key: 'ComponentId',
          ControlType: 'DropDown',
          Width: '120px',
          Value: '',
          LookupSetId: componentLid,
          SaveData: {
            Table: 'mtl.EntityItem',
            Column: 'ComponentId'
          }
        },
        {
          Label: 'Part',
          Key: 'ItemId',
          ControlType: 'DropDown',
          Width: '200px',
          Value: '',
          LookupSetId: partLid,
          SaveData: {
            Table: 'mtl.EntityItem',
            Column: 'ItemId'
          }
        },
        {
          Label: 'Qty',
          Key: 'Qty',
          ControlType: 'NumberBox',
          Width: '80px',
          Value: '',
          SaveData: {
            Table: 'mtl.EntityItem',
            Column: 'Qty'
          }
        },
        {
          Label: 'Cost',
          Key: 'Cost',
          ControlType: 'CostCalculation',
          Width: '80px',
          Value: ''
        },
        {
          Label: 'Note',
          Key: 'Comment',
          ControlType: 'RichTextBox',
          Width: '1',
          Value: '',
          SaveData: {
            Table: 'mtl.EntityItem',
            Column: 'Comment'
          }
        }],
      Rows: []
    },
    Equipment: {
      Label: 'Equipment',
      HeaderColor: '#344d6c',
      Fields: [
        {
          Key: 'Id',
          Value: '',
        },
        {
          Label: 'Type',
          Key: 'Type',
          ControlType: 'DropDown',
          Width: '120px',
          Value: '',
          LookupSetId: 1100
        },
        {
          Label: 'Equipment',
          Key: 'Equipment',
          ControlType: 'DropDown',
          Width: '200px',
          Value: '',
          LookupSetId: 1101
        },
        {
          Label: 'Duration',
          Key: 'Duration',
          MinuteToHours: true,
          ControlType: 'NumberBox',
          Width: '85px',
          Value: ''
        },
        {
          Label: 'Note',
          Key: 'Note',
          ControlType: 'RichTextBox',
          Width: '1',
          Value: ''
        }
      ],
      Rows: []
    }
  }

  let materialType = ownProps.widget.Parameters.find(x => x.ParameterName === 'MaterialType');
  let hideProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'HideFields');
  let isMobile = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsMobile');
  let primary = ownProps.widget.Parameters.find(x => x.ParameterName === 'PrimaryField');
  let showWageCode = ownProps.widget.Parameters.find(x => x.ParameterName === 'ShowWageCode');
  let setEntryOnAddition = ownProps.widget.Parameters.find(x => x.ParameterName === 'SetEntryOnAddition')

  let resources = state[entityKey] ? (state[entityKey].Resources || data) : data;

  if ((state[entityKey] && !state[entityKey].Resources) && state.ent_SavedResource && state.ent_SavedResource[state[entityKey].EntityId]) {
    resources = state.ent_SavedResource[state[entityKey].EntityId];
  }

  if (showWageCode && helpers.stringToBool(showWageCode.ParameterValue)) {
    let defaultValue = helpers.getLookupItems(1329).find(x => x.Name1.includes('Regular'));
    data.Time.Fields.splice(6, 0, {
      Label: 'Wage Code',
      Key: 'WageCodeId',
      ControlType: 'DropDown',
      Width: '215px',
      Value: '',
      DefaultValue: defaultValue ? defaultValue.LookupItemId : null,
      LookupSetId: 1329,
      SaveData: {
        Table: 'ttr.TimeEntry',
        Column: 'WageCodeId'
      }
    })
  }

  return {
    Resources: resources,
    Entity: state[entityKey],
    SaveId: state.dbo_SaveId,
    CancelId: state.dbo_CancelId,
    MaterialType: materialType ? materialType.ParameterValue : null,
    HideFields: hideProp ? hideProp.ParameterValue : null,
    IsMobile: isMobile ? helpers.stringToBool(isMobile.ParameterValue) : false,
    SetEntryOnAddition: setEntryOnAddition ? helpers.stringToBool(setEntryOnAddition.ParameterValue) : false,
    PrimaryField: primary ? primary.ParameterValue : null,
    CurrentUserId: state.CurrentUser ? state.CurrentUser.Id : null,
    SaveDataKey: entityKey === 'ent_CurrentEvent' ? 'dbo_EventSaveData' : 'dbo_SaveData',
    SelectedEntity: entityKey === 'ent_CurrentEvent' ? state['ent_SelectedEvent'] : state['ent_Selected']
  };
}

export class Resources extends React.PureComponent {
  state = {
    Entry: ''
  };

  componentDidMount() {
    if (this.props.Resources.RequestSent !== this.props.SaveId) {
      this.getMaterials();
    }
  }

  componentDidUpdate(prevProps) {
    let saved = helpers.propDidChange(this.props, prevProps, 'SaveId');
    let cancelled = helpers.propDidChange(this.props, prevProps, 'CancelId');
    if (saved || cancelled) {
      this.getMaterials();
    }
  }

  getMaterials = () => {
    if (!this.props.Entity || this.props.Entity.EntityId < 0)
      return;

    let body = {
      EntityId: this.props.SelectedEntity.EntityId,
      EntityTypeId: this.props.Entity.EntityTypeId
    }
    actions.ApiRequest('Entity/GetEntityMaterials', body, (result) => {
      let newResource = cloneDeep(this.props.Resources);
      newResource.RequestSent = this.props.SaveId;
      if (result && result.Time) {
        result.Time.forEach((x) => {
          x.Duration = Math.round((x.Duration / 60) * 100, 2) / 100;
        });
        newResource.Time.Rows = result.Time;
      }
      if (result && result.Material) {
        newResource.Material.Rows = result.Material;
      }
      this.props.dispatch(actions.SetEventResources(newResource));
    });
  }

  setEntryType = (val) => {
    if (!val)
      return;

    this.setState({ Entry: val });
    let newResource = cloneDeep(this.props.Resources);
    this.clearActive(newResource);
    newResource[val].Fields.forEach(x => {
      x.Value = x.DefaultValue || '';
      if (x.ControlType === 'UserList') {
        x.Value = this.props.CurrentUserId || '';
      }
    });
    this.props.dispatch(actions.SetEventResources(newResource));
  }

  addRow = (item, skip_callback = false) => {
    // Ensure that `this.state.Entry` is defined before executing the rest of this function, for #169.
    if (this.props.SetEntryOnAddition && this.props.IsMobile && skip_callback === false) {
      this.setState({ Entry: item.Label }, () => this.addRow(item, true));
      return;
    }

    let resources = this.props.Resources;
    let rows = resources[this.state.Entry].Rows;

    let rowItem = this.createItemFromFields(item);
    let fields = this.resetFieldsArray(this.state.Entry);

    let newObj = {
      ...resources,
      [this.state.Entry]: {
        ...resources[this.state.Entry],
        Fields: fields,
        Rows: [...rows, rowItem]
      }
    };

    batch(() => {
      this.addSaveDataForFields(newObj[this.state.Entry].Fields, rowItem);
      this.props.dispatch(actions.SetEventResources(newObj));
    });
    this.setState({ Entry: '' });
  }

  updateRow = (item) => {
    let newResource = cloneDeep(this.props.Resources);
    let idx = newResource[this.state.Entry].Rows.findIndex(x => x.Active);
    let rowItem = this.createItemFromFields(item);
    rowItem.Dirty = true;
    newResource[this.state.Entry].Rows[idx] = rowItem;
    newResource[this.state.Entry].Rows[idx].Active = false;

    batch(() => {
      this.addSaveDataForFields(newResource[this.state.Entry].Fields, rowItem)
      this.props.dispatch(actions.SetEventResources(newResource));
    });
    this.setState({ Entry: '' });
  }

  addSaveDataForFields = (fields, item) => {
    let insertKey = this.props.Resources[this.state.Entry].UpdatingKey || helpers.getInsertKey();

    fields.forEach((field) => {
      if (!field.SaveData)
        return;

      let val = item[field.Key];
      if (field.ControlType === 'DatePicker' && val) {
        // let pieces = val.split('-');
        // alert(pieces[1]);
        // alert(pieces[2]);
        // alert(pieces[0]);
        // if (pieces.length === 3) {
        //   val = [pieces[1], pieces[2], pieces[0]].join('-');
        // }
      }

      if (field.MinuteToHours && val) {
        val = val * 60;
      }

      if (!item.Id && !item.insertKey)
        item.insertKey = insertKey;

      this.props.dispatch(actions.AddSaveData({
        Id: item.Id || null,
        GobAutofill: field.GobAutofill,
        InsertKey: item.Id ? null : insertKey.toString(),
        Table: field.SaveData.Table,
        Column: field.SaveData.Column,
        Value: val,
        SaveQueue: this.props.SaveDataKey,
        IsBaseTable: true
      }));
    });
  }

  clearEntry = () => {
    let fields = this.resetFieldsArray(this.state.Entry);
    let newObj = {
      ...this.props.Resources,
      [this.state.Entry]: {
        ...this.props.Resources[this.state.Entry],
        Fields: fields
      }
    };
    this.props.dispatch(actions.SetEventResources(newObj));
  }

  createItemFromFields = (item) => {
    return item.Fields.reduce((prev, next) => {
      if (next.ControlType === 'DatePicker') {
        prev[next.Key] = helpers.shortDate(next.Value);
      } else {
        prev[next.Key] = next.Value;
      }
      return prev;
    }, {});
  }

  resetFieldsArray = (entryType) => {
    let fields = [];
    this.props.Resources[entryType].Fields.forEach(field => {
      fields.push({
        ...field,
        Value: ''
      });
    });
    return fields;
  }

  setEntryItem = (entryType, item, idx) => {
    let newResource = cloneDeep(this.props.Resources);
    let isActive = newResource[entryType].Rows[idx].Active;
    this.clearActive(newResource);

    newResource[entryType].Rows[idx].Active = !isActive;
    newResource[entryType].Fields.forEach(field => {
      if (isActive) {
        field.Value = '';
      } else {
        field.Value = item[field.Key];
        if (field.ControlType === 'DatePicker') {
          field.Value = helpers.formatDate(field.Value + ' cst');
        }
      }
    });
    newResource[entryType].UpdatingKey = item.insertKey;

    this.setState({ Entry: entryType });
    this.props.dispatch(actions.SetEventResources(newResource));
  }

  removeRow = () => {
    let newResource = cloneDeep(this.props.Resources);
    let toDelete = newResource[this.state.Entry].Rows.find(x => x.Active);

    newResource.Time.Rows = newResource.Time.Rows.filter(x => !x.Active);
    newResource.Material.Rows = newResource.Material.Rows.filter(x => !x.Active);
    newResource.Equipment.Rows = newResource.Equipment.Rows.filter(x => !x.Active);
    newResource[this.state.Entry].Fields.forEach(field => {
      field.Value = '';
    });

    let fieldItem = newResource[this.state.Entry].Fields.find(x => x.SaveData && x.SaveData.Table);

    if (toDelete.Id) {
      this.props.dispatch(actions.AddSaveData({
        Id: toDelete.Id,
        GobAutofill: null,
        InsertKey: null,
        Table: fieldItem.SaveData.Table,
        Column: 'IsDeleted',
        Value: true,
        SaveQueue: this.props.SaveDataKey,
        IsBaseTable: true
      }));
    } else {
      this.props.dispatch(actions.ClearSaveDataForId({
        SaveData: this.props.SaveDataKey,
        Id: toDelete.insertKey
      }));
    }

    this.props.dispatch(actions.SetEventResources(newResource));
  }

  clearActive = (resource) => {
    resource.Time.Rows.forEach(x => x.Active = false);
    resource.Material.Rows.forEach(x => x.Active = false);
    resource.Equipment.Rows.forEach(x => x.Active = false);
    resource.Time.UpdatingKey = null;
    resource.Material.UpdatingKey = null;
    resource.Equipment.UpdatingKey = null;
  }

  isEditMode = () => {
    if (!this.state.Entry)
      return false;

    return this.props.Resources[this.state.Entry].Rows.filter(x => x.Active).length > 0;
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.IsMobile && <DesktopInterface parent={this} resource={this.props.Resources} entity={this.props.Entity} />}
        {this.props.IsMobile && <MobileInterface parent={this} resource={this.props.Resources} />}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Resources);