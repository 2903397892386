import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEvent: state.ent_CurrentEvent
  };
};

export class CashPaymentAmount extends React.PureComponent {

  componentDidMount() {
    if (!this.props.CurrentEvent || !this.props.Trigger.Amount)
      return;

    let amountProp = this.props.CurrentEvent.Properties.find(x => x.Name === 'Amount');

    let payAmnt = -1 * Math.abs(Number(this.props.Trigger.Amount));

    helpers.addSaveData(this.props.dispatch, { ...amountProp.SaveData, SaveQueue: 'dbo_EventSaveData', AutoGenerated: true, NewEntitySaveData: true }, 'Amount', payAmnt + '');
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(CashPaymentAmount);
