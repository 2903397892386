import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import { MagnifyingGlass, RefreshArrow } from '../../../../Icons/Icons';
import { isConsole } from 'react-device-detect';
import CardList from './Widgets/CardList';
import Form from './Widgets/Form/Form';

const mapStateToProps = (state, ownProps) => {
  return {
    EditingWidget: state.EditingWidget,
    EditorDetails: state.dbo_EditorDetails || {},
    EditSWID: state.EditingWidget ? state.EditingWidget.SceneWidgetId : null
  };
};

export class WidgetDetails extends React.PureComponent {

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  getDetailsComponent = () => {
    let map = {
      CardList: CardList,
      Form: Form
    }

    return map[this.props.EditingWidget ? this.props.EditingWidget.WidgetName : ''];
  }

  currentDetails = (type) => {
    try {
      console.log(this.props.EditorDetails[this.props.EditingWidget.WidgetName][this.props.EditSWID].Details[type]);
      return this.props.EditorDetails[this.props.EditingWidget.WidgetName][this.props.EditSWID].Details[type];
    } catch {
      return null;
    }
  }

  close = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'dbo_ShowWidgetDetails',
      Value: false
    }));
  }

  setProp = (value, widgetName, type, prop) => {
    let newDetails = cloneDeep(this.props.EditorDetails);

    if (!newDetails[widgetName])
      newDetails[widgetName] = {};

    if (!newDetails[widgetName][this.props.EditSWID])
      newDetails[widgetName][this.props.EditSWID] = {};

    if (!newDetails[widgetName][this.props.EditSWID][type])
      newDetails[widgetName][this.props.EditSWID][type] = {};

    newDetails[widgetName][this.props.EditSWID][type][prop] = value;

    this.props.dispatch(actions.UpdateProp({
      Key: 'dbo_EditorDetails',
      Value: newDetails
    }));
  }

  savePosition = (e, data) => {
    localStorage.setItem('widget-detail-position', JSON.stringify({ x: data.x, y: data.y }));
  }

  getPosition = () => {
    let result = { x: 300, y: 300 };
    let item = localStorage.getItem('widget-detail-position');
    if (item) {
      result = JSON.parse(item);
    }
    return result;
  }

  render() {
    let DetailsComponent = this.getDetailsComponent();
    let portalElement = document.getElementById('widget-detail-portal');

    return (
      ReactDOM.createPortal((
        <div className="draggable-parent">
          <Draggable
            handle=".drag-handle"
            onStop={(e, data) => { this.savePosition(e, data) }}
            defaultPosition={this.getPosition()}
          >
            <div className="widget-details">
              <div className="dtl-top-bar drag-handle">
                <div>Widget Details</div>
                <div onClick={this.close}>✖</div>
              </div>
              <div className="details-content">
                {DetailsComponent && <DetailsComponent WidgetName={this.props.EditingWidget.WidgetName} Parent={this} />}
              </div>
            </div>
          </Draggable>
        </div>
      ), portalElement)
    );
  }
}

export default connect(mapStateToProps)(WidgetDetails);
