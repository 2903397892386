import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import Inputmask from "inputmask";
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';
import { LoadingDotSpinner } from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
    SaveData: state.dbo_SaveData
  };
}

export class TextBox extends React.PureComponent {
  state = {
    hasFocus: false,
    hasChanged: false
  }

  initialLoad = true;

  componentDidUpdate() {
    this.parseBeehiveMask();
    if (this.props.control.DataMask && !this.props.control.maskInit) {
      this.maskSetup();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);

    this.parseBeehiveMask();
    this.maskSetup();
    this.forceUpdate();
  }

  getDataMaskOptions = () => {
    try{
      return eval(`(${this.props.control?.DataMaskOptions ?? '{}'})`);
    }catch(ex){
      console.log({Message: 'Unable to parse DataMaskOptions', Exception: ex})
    }
    return {};
  }

  maskSetup = () => {
    let control = this.props.control;
    if (control.DataMask) {
      try {
        this.props.control.maskInit = true;
        let options = control.DataMaskOptions ? eval('(' + control.DataMaskOptions + ')') : {};
        control._maskRaw = options;

        if (control.DataMask.toLowerCase() !== 'beehive') {
          // when we have multiple modals if there is 2 instances of the same form, getElementById only sees the first

          let selector = document.querySelector('#swid-' + this.props.parentProps.widget.SceneWidgetId + ' #control-' + control.Id);

          options = {
            ...options,
            undoOnEscape: false,
            onBeforeWrite: (e) => {
              //hack to fix bug where phone numbers don't trigger onchange if you select all + delete/backspace
              if (e.code && !this.state.hasChanged) {
                let input = selector /* document.getElementById('control-' + this.props.control.Id)*/;
                let val = input.inputmask.unmaskedvalue();
                if (!val) {
                  this.onChange({ target: { value: '' } });
                  this.setState({
                    hasChanged: true
                  });
                }
              }
            }
          }

          control._maskOptions = options;

          let im = new Inputmask(control.DataMask, options);
          // selectors.forEach(element => {
          //   im.mask(element);
          // })
          im.mask(selector);
        }

      } catch (e) {
        console.error('Datamask parsing failed:');
        console.error(e);
      }
    }
  }

  handleKeyDown = (event) => {
    //Keyboard navigation for typeahead dropdown
    if (!this.props.control.Typeahead) { return; }

    if (event.key === 'Enter') {
      event.preventDefault();
      if (this.props.control.Typeahead.find(x => x.Selected)) {
        this.selectTypeaheadItem(this.props.control.Typeahead.find(x => x.Selected));
      }
    }

    if (event.keyCode == 38 /*up*/ || event.keyCode == 40 /*down*/) {
      event.preventDefault();
      let selectedTypeahead = this.props.control.Typeahead.find(x => x.Selected);
      if (!selectedTypeahead) {
        this.props.control.Typeahead[0].Selected = true;
      } else {
        let index = this.props.control.Typeahead.indexOf(selectedTypeahead);
        if (event.keyCode == 38 && this.props.control.Typeahead[index - 1]) {
          this.props.control.Typeahead.forEach(x => x.Selected = false);
          this.props.control.Typeahead[index - 1].Selected = true;
        } else if (event.keyCode == 40 && this.props.control.Typeahead[index + 1]) {
          this.props.control.Typeahead.forEach(x => x.Selected = false);
          this.props.control.Typeahead[index + 1].Selected = true;
        }
      }

      this.forceUpdate();
      if (document.querySelector('.typeahead-selected')) {
        document.querySelector('.typeahead-selected').scrollIntoView({ block: "center" });
      }
    }
  }

  parseBeehiveMask = () => {
    let control = this.props.control;

    if (control.DataMask) {
      try {
        if (control.DataMask.toLowerCase() === 'beehive' && !control._internalmask) {
          let options = control.DataMaskOptions ? eval('(' + control.DataMaskOptions + ')') : {};

          control._internalmask = options;
          control._maskOptions = options;
          if (options.currencyInput && control.Value && !isNaN(Number(control.Value))) {
            control.Value = this.formatMoney(Number(control.Value).toFixed(2));
          }
          // datamasking in EMC value isn't loaded in this block when this is hit control.Value == ''
          // if (options.parenthesisNegative && control.Value && control.Value < 0) { 
          //   control.Value = '(' + control.Value + ')';
          // }
          this.forceUpdate();
        }
      } catch { }
    }
  }

  onChange = (e) => {
    let propVal = this.getValue(e);
    this.props.control.Value = e.target.value;
    this.props.control.UnmaskedValue = propVal;

    if (this.props.control.FailedValidate) {
      this.props.control.FailedValidate = false;
    }

    this.forceUpdate();

    if (this.props.refresh)
      this.props.refresh();

    if (this.props.control._internalmask && this.props.control._internalmask.currencyInput && !isNaN(Number(propVal))) {
      let moneyVal = this.formatMoney(propVal);
      propVal = moneyVal;
      this.props.control.Value = moneyVal;
    }

    if (this.props.control._maskOptions && this.props.control._maskOptions.forceNegative && !isNaN(Number(propVal)) && Number(propVal) > 0) {
      propVal = (Number(propVal) * -1).toString();
    }

    if (this.props.control._maskOptions && this.props.control._maskOptions.forcePositive && !isNaN(Number(propVal)) && Number(propVal) < 0) {
      propVal = (Number(propVal) * -1).toString();
    }

    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        ...this.props.control.SaveData,
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: propVal,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
  }

  formatMoney = (val) => {
    if (!val)
      return val;

    let result = val.toString();

    if (result.includes('.') && result.split('.')[1].length === 1) {
      result = result + '0';
    }

    result = result.replace(/\./g, '');
    if (result.length < 3) {
      result = '000'.slice(0, 3 - result.length) + result;
    }

    result = result.slice(0, result.length - 2) + '.' + result.slice(result.length - 2, result.length);
    result = Math.abs(Number(result)).toFixed(2);
    return result;
  }

  getValue = (e) => {
    let val = e.target.value;
    let input = {};

    if (this.props.parentProps) {
      input = document.querySelector('#swid-' + this.props.parentProps.widget.SceneWidgetId + ' #control-' + this.props.control.Id)
    } else {
      input = document.getElementById('control-' + this.props.control.Id);
    }

    let saveWithMask = this.props.control.DataMaskOptions ? eval('(' + this.props.control.DataMaskOptions + ')').SaveWithMask : false;
    if (input.inputmask && !saveWithMask) {
      val = input.inputmask.unmaskedvalue();
    }

    return val;
  }

  deselectTypeahead = () => {
    this.props.control.Typeahead.forEach(x => x.Selected = false);
    this.forceUpdate();
  }

  selectTypeaheadItem = (x) => {
    this.props.control.TypeaheadItem = cloneDeep(x);
    let searchedText = this.props.control.Value;
    this.props.control.Value = x.Text1;
    this.setState({ hasFocus: false });
    this.props.refresh();

    let houseAddress = null;
    let city = null;
    let state = null;
    let zip = null;

    if (x.Text1.split(', ').length > 1) {
      houseAddress = x.Text1.split(', ')[0];
      city = x.Text1.split(', ')[1];
      if (x.Text1.split(', ')[2]) {
        state = x.Text1.split(', ')[2].split(' ')[0];
        zip = x.Text1.split(', ')[2].split(' ')[1];
      }
    }

    //hack for boulder lcr form until we find better way
    const {storeLatitudeColumn, storeLongitudeColumn, storeAddressColumn} = this.getDataMaskOptions();
    if(storeLatitudeColumn) {
      this.props.dispatch(actions.AddSaveData({
        ...this.props.control.SaveData,
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: storeLatitudeColumn,
        Value: x.Latitude,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
    if(storeLongitudeColumn) {
      this.props.dispatch(actions.AddSaveData({
        ...this.props.control.SaveData,
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: storeLongitudeColumn,
        Value: x.Longitude,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
    if(storeAddressColumn) {
      this.props.dispatch(actions.AddSaveData({
        ...this.props.control.SaveData,
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: storeAddressColumn,
        Value: x.Text1,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }

    if (this.props.parentProps.MapSwid) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'map_MoveToLocation',
        Value: {
          [this.props.parentProps.MapSwid]: { Latitude: x.Latitude, Longitude: x.Longitude, Delay: 200, Zoom: x.Zoom, Address: x.Text1, HouseAddress: houseAddress, City: city, State: state, Zip: zip, SearchedText: searchedText }
        }
      }));
    }
  }

  blur = () => {
    setTimeout(() => {
      this.setState({ hasFocus: false });
    }, 250);
  }

  focus = () => {
    setTimeout(() => {
      this.setState({ hasFocus: true });
    }, 0);
  }

  onClick = (e) => {
    let opts = this.props.control._maskOptions;
    if (opts && opts.highlightOnClick) {
      e.target.select();
    }
  }

  getPlaceholder = (control) => {
    let result = control.Placeholder;
    if (!result) {
      try {
        let maskOpt = eval('(' + control.DataMaskOptions + ')');
        if (maskOpt && maskOpt.htmlPlaceholder) {
          result = maskOpt.htmlPlaceholder;
        }
      } catch { }
    }

    return result;
  }

  renderBeehiveMasking = (control) => {
    let inputType = this.props.NumberBox ? 'number' : (control.InputType || 'text');
    let mask = control._internalmask;
    inputType = mask.inputType || inputType;
    if (mask.displayPositive && !isNaN(Number(control.Value)) && Number(control.Value) < 0) {
      control.Value = Math.abs(Number(control.Value)).toString();
    }

    if (mask.parenthesisNegative && control.Value && control.Value < 0) {
      control.Value = '(' + Math.abs(Number(control.Value)).toFixed(2).toString() + ')';
    }

    // if (mask.currencyInput) {
    //   control.Value = this.formatMoney(control.Value);
    // }
  }

  render() {
    let control = this.props.control;
    if (!control.Id) {
      control.Id = helpers.getId(1000);
    }

    let autoFocus = false;
    let inputType = this.props.NumberBox ? 'number' : (control.InputType || 'text');

    if (control._internalmask) {
      this.renderBeehiveMasking(control);
    }

    if (this.initialLoad && this.props.control.ClassName && this.props.control.ClassName.toLowerCase().split(' ').includes('autofocus')) {
      this.initialLoad = false;
      autoFocus = true;
    }

    return (
      <div className="textbox-control">
        {control.Label && <div className="control-label">
          {control.Label}
          {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
        </div>}
        <div className={(control.FailedValidate ? "failed-validate" : "")}>
          <input
            className="chrome-hide-field"
            autoComplete={'off'}
            disabled={control.IsRO}
            maxLength={control.MaxValue ? control.MaxValue : null}
            id={'control2-' + control.Id}
            onBlur={this.blur}
            onFocus={this.focus}
            type={inputType}
            value={control.Value || ''}
            placeholder={this.getPlaceholder(control)}
            onChange={this.onChange}
            onClick={this.onClick}
          />
          <input
            autoComplete={'off'}
            disabled={control.IsRO}
            maxLength={control.MaxValue ? control.MaxValue : null}
            id={'control-' + control.Id}
            onBlur={this.blur}
            onFocus={this.focus}
            type={inputType}
            value={control.Value || ''}
            placeholder={this.getPlaceholder(control)}
            onChange={this.onChange}
            onClick={this.onClick}
            autoFocus={autoFocus}
            step={control._internalmask ? control._internalmask.step || null : null}
            required={this.props.control.IsRequired}
          // tabIndex={control.Location && control.Location.ControlOrder ? control.Location.ControlOrder : 0}
          />
        </div>
        {control.FailedValidate && control._maskRaw && control._maskRaw.ErrorMessage &&
          <div className="failed-validate-message">
            {control._maskRaw.ErrorMessage}
          </div>
        }
        {window.map_typeAheadLoading &&
          <div className="typeahead-wrapper map-search-load-bg">
            <div className="typeahead-container">
              <div className="typeahead-loader">
                <LoadingDotSpinner />
              </div>
            </div>
          </div>
        }
        {control.Typeahead && control.Typeahead.length > 0 && (control.Typeahead[0].AlwaysShow || this.state.hasFocus) &&
          <div className="typeahead-wrapper">
            <div className="typeahead-container">
              <div className="typeahead-dropdown">
                {control.Typeahead.map((x, idx) => (
                  <div key={idx} className={(x.Selected ? "typeahead-selected " : "") + "typeahead-item"} onClick={() => { this.selectTypeaheadItem(x) }} onMouseEnter={this.deselectTypeahead}>
                    <div className="text-1">
                      <div>{x.Text1}</div>
                    </div>
                    <div className="text-2">
                      <div>{x.Text2}</div>
                    </div>
                    <div className="text-3">
                      <div>{x.Text3}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(TextBox);