import React, { Component, createRef } from 'react';

export default class Filter extends React.PureComponent {
  state = {
    filterText: null,
    filterType: null
  };

  doesFilterPass(params) {
    if (!this.isFilterActive()) {
      return;
    }

    const { api, colDef, column, columnApi, context, valueGetter } = this.props;
    const { node } = params;

    let value = node.data[colDef.field];
    let cbox = this.state.filterText.checkbox;
    let lookups = this.state.filterText.lookups;

    if (cbox) {
      return (
        (cbox.true && value === true) ||
        (cbox.false && value === false) ||
        (cbox.blank && [null, undefined].includes(value))
      )
    } else if (lookups) {
      return lookups.includes(value) || (lookups.blanks && [null, undefined].includes(value));
    } else {
      if (value === null || value === undefined || value === '')
        return false;

      if (this.state.filterType === 'contains') {
        return (value + '').toLowerCase().includes(this.state.filterText);
      }
      if (this.state.filterType === 'startswith') {
        return (value + '').toLowerCase().startsWith(this.state.filterText);
      }
      if (this.state.filterType === 'endswith') {
        return (value + '').toLowerCase().endsWith(this.state.filterText);
      }

      if (this.state.filterType === 'equals') {
        return Number(value) === Number(this.state.filterText);
      }
      if (this.state.filterType === 'greaterthan') {
        return Number(value) > Number(this.state.filterText);
      }
      if (this.state.filterType === 'lessthan') {
        return Number(value) < Number(this.state.filterText);
      }


    }
  }

  isFilterActive() {
    return (
      this.state.filterText !== null &&
      this.state.filterText !== undefined &&
      this.state.filterText !== ''
    );
  }

  getModel() {
    return this.isFilterActive() ? this.state.filterText : null;
  }

  setModel(model) {
    this.setState({ filterText: model }, this.props.filterChangedCallback);
  }

  valueFromFloatingFilter(value) {
    this.setState({ filterText: value }, () => {
      this.props.filterChangedCallback();
    });
  }

  filterTypeChanged(value) {
    this.setState({ filterType: value }, () => {
      this.props.filterChangedCallback();
    });
  }

  initialType(value) {
    this.setState({ filterType: value });
  }

  render() {
    return (
      null
    );
  }
}