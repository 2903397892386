import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    SelectedReport: state.rpt_SelectedReport
  };
}

export class ReportLoader extends React.PureComponent {
  componentDidMount() {
    if (this.props.SelectedReport) {
      this.getReport();
    }
  }

  componentDidUpdate(prevProps) {
    let prevReport = prevProps.SelectedReport;

    let paramDiff = false;
    if (prevReport && this.props.SelectedReport) {
      (prevReport.Parameters || []).forEach(param => {
        let curVal = (this.props.SelectedReport.Parameters || []).find(x => x.Name === param.Name);
        if (!curVal || curVal.Value !== param.Value) {
          paramDiff = true;
        }
      });
    }

    if (this.props.SelectedReport && (paramDiff || !prevProps || !prevReport || prevReport.Id !== this.props.SelectedReport.Id)) {
      this.getReport();
    }
  }

  getReport = () => {
    let rpt = this.props.SelectedReport;
    let body = {
      ReportName: rpt.ReportName,
      FolderName: rpt.FolderName,
      Parameters: rpt.Parameters || []
    }

    actions.ApiRequest('Report/GetReport', body, (result) => {
      let frame = document.querySelector('#report-frame');
      if (frame) {
        frame.src = result.ReportSrc;
      }
    });
  }

  render() {
    return (
      !this.props.SelectedReport ? null :
        <div className="report-loader">
          <iframe id="report-frame"></iframe>
        </div>
    );
  }
}

export default connect(mapStateToProps)(ReportLoader);
