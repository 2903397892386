import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { selector } from '../../../../Stores/Reducers/reducer';
import WidgetLoader from '../../../WidgetLoader';

const mapStateToProps = (state, ownProps) => {
  let sceneName = ownProps.widget.Parameters.find(x => x.ParameterName === 'SceneName');
  let initialFocus = ownProps.widget.Parameters.find(x => x.ParameterName === 'InitialFocus');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');

  //if a key was specified and redux doesn't know about it, define it
  if (sceneName && !state[sceneName.ParameterValue]) {
    state[sceneName.ParameterValue] = {
      FocusId: initialFocus ? Number(initialFocus.ParameterValue) : null
    }
  }

  let scene = sceneName ? selector.SceneByName(state, (sceneName.ParameterValue)) : null;

  if (scene && scene.Widgets) {
    try {
      scene.Widgets.forEach(x => {
        let render = true;
        let renderCondition = x.Parameters.find(x => x.ParameterName === 'RenderCondition');

        if (renderCondition && renderCondition.ParameterValue) {
          try {
            render = eval(renderCondition.ParameterValue);
            x.WillRender = render;
          } catch (e) {
            console.error('error in RenderCondition: ' + renderCondition.ParameterValue)
            x.WillRender = render;
          }
        }
      });
    } catch (e) {
      console.log('error in render condition widget selector --v');
      console.error(e);
    }

  }


  return {
    Scene: scene,
    SceneName: sceneName ? sceneName.ParameterValue : null,
    FocusId: (sceneName && state[sceneName.ParameterValue]) ? state[sceneName.ParameterValue].FocusId : null,
    CurrentEntity: entityKey ? state[entityKey.ParameterValue] : null,
    InitialFocus: initialFocus ? Number(initialFocus.ParameterValue) : null
  };
};

export class WidgetSelector extends React.PureComponent {
  componentDidUpdate(prev) {
    if (prev && this.props.CurrentEntity && prev.CurrentEntity) {
      if (helpers.entId(this.props.CurrentEntity) !== helpers.entId(prev.CurrentEntity)) {
        this.updateSelection(null, this.props.InitialFocus);
      }
    }
  }
  updateSelection = (e, id) => {
    if (!this.props.SceneName)
      return;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.SceneName,
      Value: {
        FocusId: id
      }
    }));
  }

  getPreview = (widget) => {
    if (widget && widget.Parameters) {
      let preview = widget.Parameters.find(x => x.ParameterName === 'WidgetSelectorPreview');
      if (preview) {
        return (
          <div key={widget.SceneWidgetId} className="selector-item"
            onMouseDown={(e) => { this.updateSelection(e, widget.SceneWidgetId) }}>
            <img className="selector-preview" src={preview.ParameterValue} />
          </div>
        );
      }

      let previewText = widget.Parameters.find(x => x.ParameterName === 'WidgetSelectorPreviewText');
      if (previewText) {
        return (
          <div key={widget.SceneWidgetId} className="selector-item"
            onMouseDown={(e) => { this.updateSelection(e, widget.SceneWidgetId) }}>
            <div className="selector-preview-text">
              <div>{previewText.ParameterValue}</div>
            </div>
          </div>
        );
      }
    }
    return null;
  }

  render() {
    if (!this.props.Scene || !this.props.FocusId)
      return null;

    let widgets = this.props.Scene.Widgets.filter(x => x.WillRender !== false);
    let focus = this.props.Scene.Widgets.find(x => x.SceneWidgetId === this.props.FocusId);

    if (focus) {
      widgets = widgets.filter(x => x.SceneWidgetId !== focus.SceneWidgetId);
    }


    return (
      <div className="widget-selector">
        {focus &&
          <div className="focus-item">
            <WidgetLoader widget={focus} />
          </div>}
        {this.props.Scene &&
          <div className="widget-selector-items">
            {widgets.map((item) => (
              this.getPreview(item) ||
              <div key={item.SceneWidgetId} className="selector-item"
                onMouseDown={(e) => { this.updateSelection(e, item.SceneWidgetId) }}>
                <WidgetLoader widget={item} />
              </div>
            ))}
          </div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(WidgetSelector);