import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import cloneDeep from 'lodash/cloneDeep';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const mapStateToProps = (state, ownProps) => {
  let copyExistingSchedSWID = ownProps.widget.Parameters.find(x => x.ParameterName === 'CopyExistingSchedSWID');
  let recurrenceKey = '';
  if (copyExistingSchedSWID) {
    recurrenceKey = state['_recurrence' + copyExistingSchedSWID.ParameterValue];
  }

  let schedTypeLookupSet = ownProps.widget.Parameters.find(x => x.ParameterName === 'SchedTypeLookupSet');
  let fixedSchedLid = null;
  let floatingSchedLid = null;

  if (schedTypeLookupSet && schedTypeLookupSet.ParameterValue) {
    let lookupItems = state.met_EntityMetadata.LookupItems.find(x => x.LookupSet == schedTypeLookupSet.ParameterValue).LookupItems;
    fixedSchedLid = lookupItems.find(x => x.Name1 === 'Fixed') && lookupItems.find(x => x.Name1 === 'Fixed').LookupItemId;
    floatingSchedLid = lookupItems.find(x => x.Name1 === 'Floating') && lookupItems.find(x => x.Name1 === 'Floating').LookupItemId;
  }

  let isSpray = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsSpray');

  return {
    Recurrence: state.ent_RecurrenceData,
    SWID: ownProps.widget.SceneWidgetId,
    SelectedScheduleId: state.ent_SelectedScheduleId,
    NewSchedule: state.rcr_NewSchedule || false,
    CurrentEntity: state.ent_Current,
    SelectedEntity: state.ent_Selected,
    SelectedEvent: state.ent_SelectedEvent,
    SelectedRecurrenceEvent: state.ent_SelectedRecurrenceEvent, /* ent_SelectedEvent when being used as query action */
    RecurSchedulesList: state.AM_RecurSchedules,
    SaveId: state.dbo_SaveId,
    EventTemplates: state.rcr_BaseEventTemplates,
    CurrentEvent: state.ent_CurrentEvent,
    ModalFrame: state.blu_ModalFrame,
    ModalFrame2: state.blu_ModalFrame2,
    ModalScene2: state.blu_ModalScene2,
    ModalScene: state.blu_ModalScene,
    ActiveSchedule: state.ent_ActiveSchedule,
    RecurUseExistingBaseEventBtn: state.btn_RecurUseExistingBaseEvent,
    RecurUseExistingSchedule: state.btn_RecurUseExistingSchedule,
    RecurSelected: state.ent_RecurSelected,
    RecurUseNewEvent: state.btn_RecurUseNewEvent,
    EventSaveData: state.dbo_EventSaveData,
    CopyExistingRecurrenceSchedule: copyExistingSchedSWID ? recurrenceKey : undefined,
    FixedLid: fixedSchedLid,
    FloatingLid: floatingSchedLid,
    SprayNextButton: state.btn_SprayNext,
    SprayNextButtonGrouped: state.btn_SprayNextGrouped,
    IsSpray: isSpray ? helpers.stringToBool(isSpray.ParameterValue) : false,
    SelectedQuery: state.qry_SelectedQuery,
    ConfirmGroupSave: state.btn_ConfirmGroupSave,
    DiscardGroupSave: state.btn_DiscardGroupSave
  };
};

export class Recurrence extends React.PureComponent {
  state = {
    saveInProgress: false
  };

  componentDidMount() {
    if (!this.props.Recurrence) {
      this.newSchedule();
    } else if (this.props.IsSpray) {
      this.newSchedule();
    } else {
      if (this.props.Recurrence.EntId !== helpers.entId(this.props.CurrentEntity)) {
        this.newSchedule();
      }
    }
    // if (!this.props.EventTemplates) {
    //   this.getEventTemplates(); --copy existing
    // }
  }

  componentDidUpdate(prev) {
    this.checkForButtonClicks(prev);
    this.checkNewEventFrame();
    //check for entity change
    if (this.props.Recurrence.EntId !== helpers.entId(this.props.CurrentEntity)) {
      this.newSchedule();
    }
    //check for new active schedule
    if (this.props.ActiveSchedule && this.props.ActiveSchedule.EntityId != this.props.Recurrence.ScheduleEntityId) {
      let calendarDates = [];
      if (this.props.ActiveSchedule.NextDates) {
        this.props.ActiveSchedule.NextDates.forEach(x => {
          calendarDates.push(x.NextDate); //2021-11-19 to 11/19/2021
        })
      }
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_RecurrenceData',
        Value: {
          ...this.props.Recurrence,
          ScheduleName: this.props.ActiveSchedule.ScheduleName,
          Note: this.props.ActiveSchedule.Note,
          RecurringEvent: { EntityId: this.props.ActiveSchedule.BaseEventId, EntityTypeId: this.props.ActiveSchedule.BaseEventTypeId, EventType: this.props.ActiveSchedule.EventType, EventName: this.props.ActiveSchedule.EventName },
          CalendarDates: calendarDates,
          ScheduleEntityId: this.props.ActiveSchedule.EntityId,
          GroupedIds: this.props.ActiveSchedule.GroupedIds,
          Schedule: { ScheduleEntityId: this.props.ActiveSchedule.EntityId, ScheduleType: this.props.ActiveSchedule.ScheduleType, ScheduleTypeLid: this.props.ActiveSchedule.ScheduleTypeLid, OccurenceType: this.props.ActiveSchedule.OccurenceType, OccurenceNumber: this.props.ActiveSchedule.OccurenceNumber, FloatingDays: this.props.ActiveSchedule.FloatingDays, DayFrequency: this.props.ActiveSchedule.DayFrequency, DayExact: this.props.ActiveSchedule.DayExact, WeekFrequency: this.props.ActiveSchedule.WeekFrequency, DayInstance: this.props.ActiveSchedule.DayInstance, DayPatternDays: this.props.ActiveSchedule.DayPatternDays, MonthPatternDays: this.props.ActiveSchedule.MonthPatternDays, DayPattern: this.props.ActiveSchedule.DayPattern, MonthPattern: this.props.ActiveSchedule.MonthPattern, MonthFrequency: this.props.ActiveSchedule.MonthFrequency, YearlyFrequency: this.props.ActiveSchedule.YearlyFrequency, UnitTypeLid: this.props.ActiveSchedule.UnitTypeLid, UnitFrequency: this.props.ActiveSchedule.UnitFrequency, UnitStart: this.props.ActiveSchedule.UnitStart, MaxOccurrences: this.props.ActiveSchedule.MaxOccurrences, StartDate: this.props.ActiveSchedule.StartDate, EndDate: this.props.ActiveSchedule.EndDate, FixedSchedType: this.props.ActiveSchedule.FixedSchedType }
        }
      }));
    }
  }

  checkForButtonClicks = (prev) => {
    if (this.props.NewSchedule) {
      this.newSchedule();
    }
    if (this.props.RecurUseNewEvent) {
      this.useNewBaseEvent();
    }
    // if (this.props.RecurUseExistingBaseEventBtn) {
    //   this.useExistingBaseEvent();
    // } copy existing
    if (this.props.RecurUseExistingSchedule) {
      this.useExistingSchedule();
    }
    if (this.props.SprayNextButton) {
      this.prepSpray();
    }
    if (this.props.SprayNextButtonGrouped) {
      this.prepGroupSpray();
    }
    //group save buttons
    if (this.props.ConfirmGroupSave && this.props.ConfirmGroupSave.ClickId !== prev.ConfirmGroupSave.ClickId) {
      this.saveEdit();
    }
    if (this.props.DiscardGroupSave && this.props.DiscardGroupSave.ClickId !== prev.DiscardGroupSave.ClickId) {
      this.newSchedule();
    }
  }

  prepGroupSpray = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_SprayNextGrouped',
      Value: null
    }));

    let validationErrors = this.validateSave();
    validationErrors = validationErrors.map((elem) => { return <li>{elem}<br /></li> });
    validationErrors.pop();
    if (validationErrors.length > 0) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Error Submitting Recurrence Form', Message: <p><ul className='hide-list-markers'>{validationErrors}</ul> </p> }
      }));
      return;
    }

    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene',
      Value: { "Value": "ConfirmRecurrenceGroupSpray", "Enabled": true }
    }));
    let schedEntityInsertKey = helpers.getInsertKey();
    let saveData = [];
    let propmap = new Map();

    if (this.props.Recurrence.Schedule) { //existing schedule data
      if (this.props.Recurrence.Schedule.ScheduleTypeLid == this.props.FixedLid) {
        for (const property in this.props.Recurrence.Schedule) {
          if (property == 'ScheduleTypeLid' || property == 'DayFrequency' || property == 'DayExact' || property == 'WeekFrequency' || property == 'DayInstance' || property == 'MonthFrequency' || property == 'YearlyFrequency' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate' || property == 'FixedSchedType' || property == 'DayPattern' || property == 'MonthPattern') {
            propmap.set(property, this.props.Recurrence.Schedule[property]);
          } else if (property == 'DayPatternDays' && this.props.Recurrence.Schedule[property]) {
            propmap.set('DayPattern', helpers.dayPatternEncoder(this.props.Recurrence.Schedule[property]));
          } else if (property == 'MonthPatternDays' && this.props.Recurrence.Schedule[property]) {
            propmap.set('MonthPattern', helpers.monthPatternEncoder(this.props.Recurrence.Schedule[property]));
          }
        }
      }
      if (this.props.Recurrence.Schedule.ScheduleTypeLid == this.props.FloatingLid) {
        for (const property in this.props.Recurrence.Schedule) {
          if (property == 'FloatingDays' || property == 'ScheduleTypeLid' || property == 'UnitTypeLid' || property == 'UnitFrequency' || property == 'UnitStart' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate') {
            propmap.set(property, this.props.Recurrence.Schedule[property]);
          }
        }
      }
    } else { //new schedule savedata
      this.props.Recurrence.ScheduleSaveData.forEach((sd) => {
        sd.InsertKey = schedEntityInsertKey;
        saveData.push(sd);
      })
    }

    propmap.set('AssetId', 'xxx');
    propmap.set('AssetClassId', this.props.SelectedQuery.EntityTypeId);
    propmap.set('CurrentOccurrenceCount', 0);
    propmap.set('ScheduleName', this.props.Recurrence.ScheduleName);
    propmap.set('Note', this.props.Recurrence.Note);

    //Base Event Save Data
    if (this.props.Recurrence.RecurringEvent) {
      saveData.push({
        Id: schedEntityInsertKey,
        InsertKey: null,
        Table: 'rcr.ScheduleEntity',
        Column: 'BaseEventId',
        IsBaseTable: false,
        Value: this.props.Recurrence.RecurringEvent.EntityId
      });
      propmap.set('BaseEventTypeId', this.props.Recurrence.RecurringEvent.EntityTypeId);
      // propmap.set('BaseEventId', this.props.Recurrence.RecurringEvent.EntityId);
    } else {
      //unique evt table properties
      let evtInsertKey = this.props.Recurrence.EventSaveData.find(x => x.Table === 'xtn.EAsset').InsertKey;
      saveData.push({
        Id: null,
        InsertKey: evtInsertKey,
        Table: 'xtn.EAsset',
        Column: 'IsRecurringBase',
        IsBaseTable: true,
        Value: 1
      });
      saveData.push({
        Id: null,
        InsertKey: evtInsertKey,
        Table: 'xtn.EAsset',
        Column: 'ScheduleEntityId',
        IsBaseTable: true,
        Value: schedEntityInsertKey
      });
      this.props.Recurrence.EventSaveData.forEach((sd) => {
        saveData.push(sd);
      })
      //unique schedentity properties
      let baseEventId = evtInsertKey;
      let baseEventTypeId = this.props.Recurrence.EventSaveData.find(x => x.Column === 'EventTypeId' && x.Value !== 'ent_Selected').Value;
      saveData.push({
        Id: schedEntityInsertKey,
        InsertKey: null,
        Table: 'rcr.ScheduleEntity',
        Column: 'BaseEventId',
        IsBaseTable: false,
        Value: baseEventId
      });
      propmap.set('BaseEventTypeId', baseEventTypeId);
    }

    //Save call
    propmap.forEach((value, key) => {
      saveData.push({
        Id: null,
        InsertKey: schedEntityInsertKey,
        Table: 'rcr.ScheduleEntity',
        Column: key,
        IsBaseTable: true,
        Value: value
      })
    })

    //filter for weird EMC issue where default value is coming into save data with value of ent_Selected for EventTypeId
    saveData = saveData.filter(x => x.Value !== 'ent_Selected');

    this.props.dispatch(actions.UpdateProp({
      Key: 'dbo_EventSaveData',
      Value: saveData
    }));
  }

  prepSpray = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_SprayNext',
      Value: null
    }));

    let validationErrors = this.validateSave();
    validationErrors = validationErrors.map((elem) => { return <li>{elem}<br /></li> });
    validationErrors.pop();
    if (validationErrors.length > 0) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Error Submitting Recurrence Form', Message: <p><ul className='hide-list-markers'>{validationErrors}</ul> </p> }
      }));
      return;
    }

    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene',
      Value: { "Value": "ConfirmRecurrenceSpray", "Enabled": true }
    }));
    let schedEntityInsertKey = helpers.getInsertKey();
    let saveData = [];
    let propmap = new Map();

    if (this.props.Recurrence.Schedule) { //existing schedule data
      if (this.props.Recurrence.Schedule.ScheduleTypeLid == this.props.FixedLid) {
        for (const property in this.props.Recurrence.Schedule) {
          if (property == 'ScheduleTypeLid' || property == 'DayFrequency' || property == 'DayExact' || property == 'WeekFrequency' || property == 'DayInstance' || property == 'MonthFrequency' || property == 'YearlyFrequency' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate' || property == 'FixedSchedType' || property == 'DayPattern' || property == 'MonthPattern') {
            propmap.set(property, this.props.Recurrence.Schedule[property]);
          } else if (property == 'DayPatternDays' && this.props.Recurrence.Schedule[property]) {
            propmap.set('DayPattern', helpers.dayPatternEncoder(this.props.Recurrence.Schedule[property]));
          } else if (property == 'MonthPatternDays' && this.props.Recurrence.Schedule[property]) {
            propmap.set('MonthPattern', helpers.monthPatternEncoder(this.props.Recurrence.Schedule[property]));
          }
        }
      }
      if (this.props.Recurrence.Schedule.ScheduleTypeLid == this.props.FloatingLid) {
        for (const property in this.props.Recurrence.Schedule) {
          if (property == 'FloatingDays' || property == 'ScheduleTypeLid' || property == 'UnitTypeLid' || property == 'UnitFrequency' || property == 'UnitStart' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate') {
            propmap.set(property, this.props.Recurrence.Schedule[property]);
          }
        }
      }
    } else { //new schedule savedata
      this.props.Recurrence.ScheduleSaveData.forEach((sd) => {
        sd.InsertKey = schedEntityInsertKey;
        saveData.push(sd);
      })
    }

    propmap.set('AssetId', 'xxx');
    propmap.set('AssetClassId', this.props.SelectedQuery.EntityTypeId);
    propmap.set('CurrentOccurrenceCount', 0);
    propmap.set('ScheduleName', this.props.Recurrence.ScheduleName);
    propmap.set('Note', this.props.Recurrence.Note);

    //Base Event Save Data
    if (this.props.Recurrence.RecurringEvent) {
      saveData.push({
        Id: schedEntityInsertKey,
        InsertKey: null,
        Table: 'rcr.ScheduleEntity',
        Column: 'BaseEventId',
        IsBaseTable: false,
        Value: this.props.Recurrence.RecurringEvent.EntityId
      });
      propmap.set('BaseEventTypeId', this.props.Recurrence.RecurringEvent.EntityTypeId);
      // propmap.set('BaseEventId', this.props.Recurrence.RecurringEvent.EntityId);
    } else {
      //unique evt table properties
      let evtInsertKey = this.props.Recurrence.EventSaveData.find(x => x.Table === 'xtn.EAsset').InsertKey;
      saveData.push({
        Id: null,
        InsertKey: evtInsertKey,
        Table: 'xtn.EAsset',
        Column: 'IsRecurringBase',
        IsBaseTable: true,
        Value: 1
      });
      saveData.push({
        Id: null,
        InsertKey: evtInsertKey,
        Table: 'xtn.EAsset',
        Column: 'ScheduleEntityId',
        IsBaseTable: true,
        Value: schedEntityInsertKey
      });
      this.props.Recurrence.EventSaveData.forEach((sd) => {
        saveData.push(sd);
      })
      //unique schedentity properties
      let baseEventId = evtInsertKey;
      let baseEventTypeId = this.props.Recurrence.EventSaveData.find(x => x.Column === 'EventTypeId' && x.Value !== 'ent_Selected').Value;
      saveData.push({
        Id: schedEntityInsertKey,
        InsertKey: null,
        Table: 'rcr.ScheduleEntity',
        Column: 'BaseEventId',
        IsBaseTable: false,
        Value: baseEventId
      });
      propmap.set('BaseEventTypeId', baseEventTypeId);
    }

    //Save call
    propmap.forEach((value, key) => {
      saveData.push({
        Id: null,
        InsertKey: schedEntityInsertKey,
        Table: 'rcr.ScheduleEntity',
        Column: key,
        IsBaseTable: true,
        Value: value
      })
    })

    //filter for weird EMC issue where default value is coming into save data with value of ent_Selected for EventTypeId
    saveData = saveData.filter(x => x.Value !== 'ent_Selected');

    this.props.dispatch(actions.UpdateProp({
      Key: 'dbo_EventSaveData',
      Value: saveData
    }));
  }

  // getEventTemplates = () => {
  //   let body = {
  //     SWID: this.props.SWID,
  //     Parameter: this.props.SelectedEntity.EntityTypeId
  //   }

  //   actions.ApiRequest('List/GetList', body, (result) => {
  //     this.props.dispatch(actions.UpdateProp({
  //       Key: 'rcr_BaseEventTemplates',
  //       Value: result
  //     }));
  //   });
  // }

  useNewBaseEvent = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_RecurUseNewEvent',
      Value: false
    }));
    let newEventId = this.props.EventSaveData.find(x => x.Column === 'EventTypeId') && this.props.EventSaveData.find(x => x.Column === 'EventTypeId').Value;
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_RecurrenceData',
      Value: { ...this.props.Recurrence, EventSaveData: this.props.EventSaveData, RecurringEvent: null, NewEventId: newEventId }
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'dbo_EventSaveData',
      Value: []
    }));
    // this.props.dispatch(actions.UpdateProp({
    //   Key: 'ent_CurrentEvent',
    //   Value: undefined
    // }));
    if (this.props.IsSpray) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene2',
        Value: undefined
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalFrame2',
        Value: undefined
      }));
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene',
        Value: undefined
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalFrame',
        Value: undefined
      }));
    }
  }

  useExistingSchedule = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_RecurrenceData',
      Value: { ...this.props.Recurrence, Schedule: this.props.CopyExistingRecurrenceSchedule, isDirty: true }
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_RecurSelected',
      Value: undefined
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_RecurUseExistingSchedule',
      Value: false
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene2',
      Value: undefined
    }));
    // if (this.props.IsSpray) {
    //   this.props.dispatch(actions.UpdateProp({
    //     Key: 'blu_ModalScene2',
    //     Value: undefined
    //   }));
    // } else {
    //   this.props.dispatch(actions.UpdateProp({
    //     Key: 'blu_ModalScene',
    //     Value: undefined
    //   }));
    // }
  }

  useExistingBaseEvent = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_RecurrenceData',
      Value: { ...this.props.Recurrence, RecurringEvent: { EntityId: this.props.SelectedEvent.EntityId, EntityTypeId: this.props.SelectedEvent.EntityTypeId, EventType: this.props.SelectedEvent.EventType, EventName: this.props.SelectedEvent.EventName } }
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_SelectedEvent',
      Value: undefined
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalFrame',
      Value: undefined
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_RecurUseExistingBaseEvent',
      Value: false
    }));
  }

  checkNewEventFrame = () => {
    if (this.props.IsSpray) {
      if (this.props.CurrentEvent && this.props.CurrentEvent.IsNew && this.props.ModalScene2 && this.props.ModalScene2.Value === 'AM_EventScene' && !this.props.ModalFrame2) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_ModalFrame2',
          Value: { Value: 'RecurrenceNewBaseEvent', Enabled: true, ModalClass: 'spray-event' }
        }));
      }
    } else {
      if (this.props.CurrentEvent && this.props.CurrentEvent.IsNew && this.props.ModalScene && this.props.ModalScene.Value === 'AM_EventScene' && !this.props.ModalFrame) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_ModalFrame',
          Value: { Value: 'RecurrenceNewBaseEvent', Enabled: true, ModalClass: 'spray-event' }
        }));
      }
    }
  }

  newSchedule = () => {
    let scheduleId = helpers.getInsertKey();

    let newRecurrence = {
      EntId: helpers.entId(this.props.CurrentEntity),
      ScheduleEntityId: scheduleId,
      ScheduleName: '',
      Note: '',
      RecurringEvent: null,
      EventSaveData: [],
      ScheduleSaveData: [],
      CalendarDate: helpers.formatDate(),
      ScheduledEvents: [],
      Schedule: null
    };

    this.setRecurrence(newRecurrence);

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_ActiveSchedule',
      Value: undefined
    }))

    this.props.dispatch(actions.UpdateProp({
      Key: 'rcr_NewSchedule',
      Value: false
    }));

    // this.getEventTemplates(); --copy existing
  }

  setRecurrence = (recurrenceObj) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_RecurrenceData',
      Value: recurrenceObj
    }));
  }

  setProp = (e, propName) => {
    let newObj = cloneDeep(this.props.Recurrence);
    newObj[propName] = e.target.value;
    if (propName === 'Note' || propName === 'ScheduleName') {
      newObj['isDirty'] = true;
    }
    this.setRecurrence(newObj);
  }

  validateSave = () => {
    let validationErrors = '';
    if (!this.props.Recurrence.ScheduleName) {
      validationErrors += 'Title field is required.'
    } /*else if (this.props.RecurSchedulesList.find(sched => sched.ScheduleName == this.props.Recurrence.Title)) {
      validationErrors += 'This Schedule Name already exists, please enter a different title.';
      validationMap.set('title', false);
    }*/

    /* maybe add above code to check for name only if it is a new entry */
    if (!this.props.Recurrence.Schedule && this.props.Recurrence.ScheduleSaveData.length === 0) {
      validationErrors += 'No schedule selected, select a schedule to save.';
    }
    if (!this.props.Recurrence.RecurringEvent && this.props.Recurrence.EventSaveData.length === 0) {
      validationErrors += 'No recurring event selected, select a recurring event to save.';
    }
    let validationArr = validationErrors.split('.');
    return validationArr;
  }

  save = () => {
    //Validation checks for base event selected, note, title, schedule selected *******
    if (this.state.saveInProgress)
      return
    let validationErrors = this.validateSave();
    validationErrors = validationErrors.map((elem) => { return <li>{elem}<br /></li> });
    validationErrors.pop();
    if (validationErrors.length > 0) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Error Submitting Recurrence Form', Message: <p><ul className='hide-list-markers'>{validationErrors}</ul> </p> }
      }));
      return;
    } else if (this.props.Recurrence.ScheduleEntityId < 0) {
      this.saveNew();
    } else {
      if (this.props.Recurrence.GroupedIds) {
        let groupTotal = this.props.Recurrence.GroupedIds.length;
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_Dialog',
          Value: {
            Title: 'Warning: Grouped Schedule',
            Message: <p style={{ fontSize: '1.1rem', margin: '10px' }}>This schedule is a part of a group, changing it will change this maintenance plan for <b>{groupTotal}</b> other assets using this schedule.</p>,
            Buttons: [{
              "Label": "Save",
              "Engines": ["btn_ConfirmGroupSave"],
              "Class": "button green-button",
              "Width": "185px"
            },
            {
              "Label": "Discard",
              "Engines": ["btn_DiscardGroupSave"],
              "Class": "button red-button",
              "Width": "155px",
              "PreventAction": true
            }]
          }
        }));
      } else {
        this.saveEdit();
      }
    }
  }

  saveEdit = () => {
    //Schedule Save Data
    this.setState({ saveInProgress: true });
    let schedEntityInsertKey = this.props.Recurrence.ScheduleEntityId;
    let saveData = [];
    let propmap = new Map();

    //build schedule map with all null values, then go through this logic and set values for what applies
    propmap.set('OccurenceType', null).set('OccurenceNumber', null).set('FloatingDays', null).set('DayPattern', null).set('DayFrequency', null).set('DayExact', null).set('WeekFrequency', null).set('DayInstance', null).set('MonthPattern', null).set('MonthFrequency', null).set('YearlyFrequency', null).set('UnitTypeLid', null).set('UnitFrequency', null).set('UnitStart', null).set('MaxOccurrences', null).set('StartDate', null).set('ScheduleTypeLid', null);
    if (this.props.Recurrence.Schedule) { //existing schedule data
      if (this.props.Recurrence.Schedule.ScheduleTypeLid == this.props.FixedLid) {
        for (const property in this.props.Recurrence.Schedule) {
          if (property == 'ScheduleTypeLid' || property == 'DayFrequency' || property == 'DayExact' || property == 'WeekFrequency' || property == 'DayInstance' || property == 'MonthFrequency' || property == 'YearlyFrequency' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate' || property == 'FixedSchedType' || (property == 'DayPattern' && this.props.Recurrence.Schedule['DayPatternDays'] == null) || (property == 'MonthPattern' && this.props.Recurrence.Schedule['MonthPatternDays'] == null)) {
            propmap.set(property, this.props.Recurrence.Schedule[property]);
          } else if (property == 'DayPatternDays' && this.props.Recurrence.Schedule[property]) {
            propmap.set('DayPattern', helpers.dayPatternEncoder(this.props.Recurrence.Schedule[property]));
          } else if (property == 'MonthPatternDays' && this.props.Recurrence.Schedule[property]) {
            propmap.set('MonthPattern', helpers.monthPatternEncoder(this.props.Recurrence.Schedule[property]));
          }
        }
      }
      if (this.props.Recurrence.Schedule.ScheduleTypeLid == this.props.FloatingLid) {
        for (const property in this.props.Recurrence.Schedule) {
          if (property == 'FloatingDays' || property == 'ScheduleTypeLid' || property == 'UnitTypeLid' || property == 'UnitFrequency' || property == 'UnitStart' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate') {
            propmap.set(property, this.props.Recurrence.Schedule[property]);
          }
        }
      }
    } else { //new schedule savedata
      this.props.Recurrence.ScheduleSaveData.forEach((sd) => {
        propmap.delete(sd.Column)
        sd.Id = schedEntityInsertKey;
        sd.InsertKey = null;
        saveData.push(sd);
      })
    }
    propmap.set('AssetId', this.props.SelectedEntity.EntityId);
    propmap.set('AssetClassId', this.props.SelectedEntity.EntityTypeId);
    propmap.set('ScheduleName', this.props.Recurrence.ScheduleName);
    propmap.set('Note', this.props.Recurrence.Note);

    //Existing Base Event Save Data
    if (this.props.Recurrence.RecurringEvent) {
      propmap.set('BaseEventTypeId', this.props.Recurrence.RecurringEvent.EntityTypeId);
      propmap.set('BaseEventId', this.props.Recurrence.RecurringEvent.EntityId);
    } else {
      //New Base Event Save Data
      let evtInsertKey = this.props.Recurrence.EventSaveData[0].InsertKey;
      saveData.push({
        Id: evtInsertKey,
        InsertKey: null,
        Table: 'xtn.EAsset',
        Column: 'IsRecurringBase',
        IsBaseTable: false,
        Value: 1
      });
      saveData.push({
        Id: evtInsertKey,
        InsertKey: null,
        Table: 'xtn.EAsset',
        Column: 'ScheduleEntityId',
        IsBaseTable: false,
        Value: schedEntityInsertKey
      });
      this.props.Recurrence.EventSaveData.forEach((sd) => {
        saveData.push(sd);
      })
      //SchedEntity properties for New Base Event
      let baseEventId = evtInsertKey;
      let baseEventTypeId = this.props.Recurrence.EventSaveData.find(x => x.Column === 'EventTypeId') ? this.props.Recurrence.EventSaveData.find(x => x.Column === 'EventTypeId').Value : null;
      if (baseEventId) {
        saveData.push({
          Id: schedEntityInsertKey,
          InsertKey: null,
          Table: 'rcr.ScheduleEntity',
          Column: 'BaseEventId',
          IsBaseTable: false,
          Value: baseEventId
        });
      }
      if (baseEventTypeId) {
        propmap.set('BaseEventTypeId', baseEventTypeId);
      }
    }

    propmap.forEach((value, key) => {
      saveData.push({
        Id: schedEntityInsertKey,
        InsertKey: null,
        Table: 'rcr.ScheduleEntity',
        Column: key,
        IsBaseTable: true,
        Value: value
      })
    })

    let groupIdArray = this.props.Recurrence.GroupedIds;

    if (groupIdArray) {
      // let recurrenceDataToCopy = saveData.filter(x => x.Table === 'rcr.ScheduleEntity');
      // let eventDataToCopy = saveData.filter(x => x.Table.includes('xtn.') || x.Table.includes('[xtn].'));
      let groupSaveDataToCopy = saveData.filter(x => ((x.Table === 'rcr.ScheduleEntity' && x.Column !== 'AssetId' && x.Column !== 'BaseEventId') || x.Table.includes('xtn') || x.Table.includes('mtl') || x.Table.includes('ttr'))); //Where Column not AssetId/BaseEventId

      groupSaveDataToCopy.forEach(sd => {
        groupIdArray.forEach(groupId => {
          if (sd.Table.includes('ScheduleEntity')) {
            saveData.push({ ...sd, Id: groupId.ScheduleEntityId })
          }
          if (sd.Table.includes('xtn.') || sd.Table.includes('[xtn].')) {
            saveData.push({ ...sd, Id: groupId.BaseEventId })
          }
          //if material/time do something
        })
      })
    }

    saveData = saveData.filter(x => x.Value !== 'ent_Selected');

    const existingStartDate = this.props.ActiveSchedule.StartDate;
    const saveDataStartDate = saveData
      .find(x => x.Column === "StartDate")
      ?.Value

    if (saveDataStartDate && saveDataStartDate !== existingStartDate) {
      saveData = [
        ...saveData,
        {
          Id: schedEntityInsertKey,
          Table: 'rcr.ScheduleEntity',
          IsBaseTable: true,
          InsertKey: null,
          Column: "CurrentOccurrenceCount",
          Value: 0
        }
      ];
    }

    actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'rcr_NewSchedule',
        Value: true
      }));
      this.setState({ saveInProgress: false });
    });
  }

  saveNew = () => {
    //Schedule Save Data
    this.setState({ saveInProgress: true });
    let schedEntityInsertKey = helpers.getInsertKey();
    let saveData = [];
    let propmap = new Map();

    if (this.props.Recurrence.Schedule) { //existing schedule data
      if (this.props.Recurrence.Schedule.ScheduleTypeLid == this.props.FixedLid) {
        for (const property in this.props.Recurrence.Schedule) {
          if (property == 'ScheduleTypeLid' || property == 'DayFrequency' || property == 'DayExact' || property == 'WeekFrequency' || property == 'DayInstance' || property == 'MonthFrequency' || property == 'YearlyFrequency' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate' || property == 'FixedSchedType' || property == 'DayPattern' || property == 'MonthPattern') {
            propmap.set(property, this.props.Recurrence.Schedule[property]);
          } else if (property == 'DayPatternDays' && this.props.Recurrence.Schedule[property]) {
            propmap.set('DayPattern', helpers.dayPatternEncoder(this.props.Recurrence.Schedule[property]));
          } else if (property == 'MonthPatternDays' && this.props.Recurrence.Schedule[property]) {
            propmap.set('MonthPattern', helpers.monthPatternEncoder(this.props.Recurrence.Schedule[property]));
          }
        }
      }
      if (this.props.Recurrence.Schedule.ScheduleTypeLid == this.props.FloatingLid) {
        for (const property in this.props.Recurrence.Schedule) {
          if (property == 'FloatingDays' || property == 'ScheduleTypeLid' || property == 'UnitTypeLid' || property == 'UnitFrequency' || property == 'UnitStart' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate') {
            propmap.set(property, this.props.Recurrence.Schedule[property]);
          }
        }
      }
    } else { //new schedule savedata
      this.props.Recurrence.ScheduleSaveData.forEach((sd) => {
        sd.InsertKey = schedEntityInsertKey;
        saveData.push(sd);
      })
    }

    propmap.set('AssetId', this.props.SelectedEntity.EntityId);
    propmap.set('AssetClassId', this.props.SelectedEntity.EntityTypeId);
    propmap.set('CurrentOccurrenceCount', 0);
    propmap.set('ScheduleName', this.props.Recurrence.ScheduleName);
    propmap.set('Note', this.props.Recurrence.Note);

    //Base Event Save Data
    if (this.props.Recurrence.RecurringEvent) {
      saveData.push({
        Id: schedEntityInsertKey,
        InsertKey: null,
        Table: 'rcr.ScheduleEntity',
        Column: 'BaseEventId',
        IsBaseTable: false,
        Value: this.props.Recurrence.RecurringEvent.EntityId
      });
      propmap.set('BaseEventTypeId', this.props.Recurrence.RecurringEvent.EntityTypeId);
      // propmap.set('BaseEventId', this.props.Recurrence.RecurringEvent.EntityId);
    } else {
      //unique evt table properties
      let evtInsertKey = this.props.Recurrence.EventSaveData.find(x => x.Table === 'xtn.EAsset').InsertKey;
      saveData.push({
        Id: null,
        InsertKey: evtInsertKey,
        Table: 'xtn.EAsset',
        Column: 'IsRecurringBase',
        IsBaseTable: true,
        Value: 1
      });
      saveData.push({
        Id: null,
        InsertKey: evtInsertKey,
        Table: 'xtn.EAsset',
        Column: 'ScheduleEntityId',
        IsBaseTable: true,
        Value: schedEntityInsertKey
      });
      this.props.Recurrence.EventSaveData.forEach((sd) => {
        saveData.push(sd);
      })
      //unique schedentity properties
      let baseEventId = evtInsertKey;
      let baseEventTypeId = this.props.Recurrence.EventSaveData.find(x => x.Column === 'EventTypeId' && x.Table.includes('EAsset')).Value;
      saveData.push({
        Id: schedEntityInsertKey,
        InsertKey: null,
        Table: 'rcr.ScheduleEntity',
        Column: 'BaseEventId',
        IsBaseTable: false,
        Value: baseEventId
      });
      propmap.set('BaseEventTypeId', baseEventTypeId);
    }

    //Save call
    propmap.forEach((value, key) => {
      saveData.push({
        Id: null,
        InsertKey: schedEntityInsertKey,
        Table: 'rcr.ScheduleEntity',
        Column: key,
        IsBaseTable: true,
        Value: value
      })
    })

    saveData = saveData.filter(x => x.Value !== 'ent_Selected');

    actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'rcr_NewSchedule',
        Value: true
      }));
      this.setState({ saveInProgress: false });
    });
  }

  cancel = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'rcr_NewSchedule',
      Value: true
    }));
  }

  delete = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'rcr_NewSchedule',
      Value: true
    }));

    let saveData = [];
    saveData.push({
      Id: this.props.ActiveSchedule.EntityId,
      InsertKey: null,
      Table: 'rcr.ScheduleEntity',
      Column: 'IsDeleted',
      IsBaseTable: true,
      Value: true
    })

    actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });
  }

  createNewEvt = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'dbo_EventSaveData',
      Value: []
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_CurrentEvent',
      Value: null
    }));

    if (this.props.IsSpray && this.props.SelectedQuery) {
      //Get createable events for queryGrid recurrence
      let eventList = helpers.GetCreatableEvents(this.props.SelectedQuery.EntityTypeId, {});
      console.log(eventList);
      batch(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ent_CreatableEvents',
          Value: eventList
        }));
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_ModalScene2',
          Value: { Value: 'EventPickerRecurrence', Enabled: true }
        }));
      });
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ShowEventPicker',
        Value: true
      }));
    }
  }

  copyExistingEvt = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_SelectedEvent',
      Value: { EntityId: this.props.EventTemplates[0].EventId, EntityTypeId: this.props.EventTemplates[0].EventTypeId }
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalFrame',
      Value: { Value: 'RecurrenceFrame', Enabled: true, ModalClass: 'spray-event' }
    }));
  }

  // createNewSchedule = () => {
  //   this.props.dispatch(actions.UpdateProp({
  //     Key: 'blu_ModalScene',
  //     Value: { Value: 'RecurSchedulesModalScene', Enabled: true }
  //   }));
  // }

  copyExistingSchedule = () => {
    if (this.props.Recurrence.ScheduleEntityId) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_RecurSelected',
        Value: { ...this.props.Recurrence.Schedule, ScheduleEntityId: this.props.Recurrence.ScheduleEntityId }
      }));
    }
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene2',
      Value: { Value: 'RecurSchedulesCopyExisting', Enabled: true }
    }));

  }

  viewBaseEvent = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_SelectedEvent',
      Value: { EntityId: this.props.Recurrence.RecurringEvent.EntityId, EntityTypeId: this.props.Recurrence.RecurringEvent.EntityTypeId }
    }));
    if (this.props.Recurrence.GroupedIds) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalFrame',
        Value: { Value: 'RecurrenceNewBaseEvent', Enabled: true, ModalClass: 'spray-event' }
      }));
    }
  }

  viewSchedule = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_RecurSelected',
      Value: { ...this.props.Recurrence.Schedule, ScheduleEntityId: this.props.Recurrence.ScheduleEntityId }
    }));
    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene',
        Value: { Value: 'RecurSchedulesModalScene', Enabled: true }
      }));
    }, 500);
  }

  checkForSaveData = (recurrence) => {
    if (!recurrence) { return false; }
    let isNew = recurrence.ScheduleEntityId && recurrence.ScheduleEntityId < 0 ? true : false;
    if (isNew && (recurrence.Note || recurrence.ScheduleName)) {
      return true;
    }
    if (recurrence.isDirty) {
      return true;
    }
    if (recurrence.EventSaveData && recurrence.EventSaveData.length > 0) {
      return true;
    }
  }

  render() {
    let recurrence = this.props.Recurrence;
    let hasSelected = false;
    // if (recurrence && (recurrence.EventSaveData.length > 0 || recurrence.ScheduleSaveData.length > 0 || recurrence.Schedule || recurrence.RecurringEvent || recurrence.Note || recurrence.ScheduleName)) {
    //   hasSaveData = true;
    // }
    if (recurrence && recurrence.ScheduleEntityId && recurrence.ScheduleEntityId > 0) {
      hasSelected = true;
    }
    let hasSaveData = this.checkForSaveData(recurrence);

    return (
      !recurrence ? <div></div> :
        <div className="recurrence">
          <div className="recurrence-title">
            <input type="text" value={recurrence.ScheduleName} placeholder={'Title'} onChange={(e) => { this.setProp(e, 'ScheduleName') }} />
          </div>
          <div className="recurrence-content">
            <div className="left-pane">
              <div className="rec-titles">Base Event Selection</div>
              <div className="base-event-selection">
                <div className={'rec-desc' + ((recurrence && recurrence.RecurringEvent && recurrence.RecurringEvent.EntityId) || (recurrence && recurrence.EventSaveData && recurrence.EventSaveData.length > 0) ? ' rec-selected' : ' rec-not-selected')}>{/*rec-selected and rec-not-selected used for left borders green/red*/}
                  {
                    (recurrence.RecurringEvent && recurrence.RecurringEvent.EntityId &&
                      <div>
                        <div className='rec-desc-details'>
                          {recurrence.RecurringEvent.EventName}<br />
                          {recurrence.RecurringEvent.EventType} Event<br />
                          <span className={'blue-link'} onClick={() => this.viewBaseEvent()}>View Event</span>
                        </div>
                      </div>)
                    || (recurrence.EventSaveData && recurrence.EventSaveData.length > 0 &&
                      <div>New Base Event Selected<br></br>
                        <div className='rec-desc-details'>
                          {recurrence.NewEventId && helpers.getEntityType(recurrence.NewEventId) && helpers.getEntityType(recurrence.NewEventId).Label} Event
                        </div>
                      </div>)
                    ||
                    <div>No Base Event Selected</div>
                  }
                </div>
                <div className="rcr-buttons rcr-top-buttons">
                  <div className="rcr-btn rcr-create-new-evt" onClick={this.createNewEvt}>
                    <div>Create New</div>
                  </div>
                  {/* <div className={"rcr-btn rcr-copy-existing-evt" + (this.props.EventTemplates && this.props.EventTemplates.length === 0 ? ' disabled' : '')} onClick={this.copyExistingEvt}>
                    <div>Copy Existing</div>
                  </div> copy existing*/}
                </div>
              </div>
              <div className="recurrence-note">
                <div className="label">
                  <div>Note</div>
                </div>
                <textarea type="text" value={recurrence.Note} onChange={(e) => { this.setProp(e, 'Note') }} />
              </div>
            </div>
            <div className="right-pane">
              <div className="rec-titles">Schedule Selection</div>
              <div className="schedule-selection">
                <div className={'rec-desc' + ((recurrence && recurrence.Schedule) ? ' rec-selected' : ' rec-not-selected')}>
                  {
                    (recurrence.Schedule &&
                      <div>
                        <div className='rec-desc-details'>
                          {recurrence.Schedule.ScheduleType} Schedule<br></br>
                          Start on {new Date(recurrence.Schedule.StartDate) && new Date(recurrence.Schedule.StartDate).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric', timeZone: "UTC" })},<br></br>
                          {
                            (recurrence.Schedule.EndDate && 'End on ' + new Date(recurrence.Schedule.EndDate).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric', timeZone: "UTC" }) + '.') || (recurrence.Schedule.MaxOccurrences && 'End after ' + recurrence.Schedule.MaxOccurrences + ' occurrences.') || 'No End Date.'
                          }
                          <br />
                          <span className={'blue-link'} onClick={() => this.viewSchedule()}>View Schedule</span>
                        </div>
                      </div>)
                    || (recurrence.ScheduleSaveData && recurrence.ScheduleSaveData.length > 0 &&
                      <div>New Schedule Selected</div>)
                    ||
                    <div>No Schedule Selected</div>
                  }
                </div>
                <div className="rcr-buttons rcr-top-buttons">
                  {/* <div className="rcr-btn rcr-create-new-evt" onClick={this.createNewSchedule}>
                    <div>Create Event</div>
                  </div> */}
                  <div className="rcr-btn rcr-copy-existing-evt" onClick={this.copyExistingSchedule}>
                    <div>Change Schedule</div>
                  </div>
                </div>
              </div>
              <div className="recurrence-calendar">
                <Calendar
                  value={null}
                  calendarType='US'
                  selectRange={false}
                  tileClassName={({ date, view }) => {
                    let formattedDate = helpers.formatDate(date);
                    let markedDays = new Date().toLocaleDateString();
                    if (recurrence && recurrence.CalendarDates) {
                      markedDays = recurrence.CalendarDates;
                      if (markedDays.find(x => x === (formattedDate))) {
                        if (markedDays[0] === formattedDate) {
                          return 'first-marked-day'
                        }
                        if (markedDays[markedDays.length - 1] === formattedDate) {
                          return 'last-marked-day'
                        }
                        return 'marked-day';
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {!this.props.IsSpray && <div className="rcr-button-bar">
            <div className="rcr-buttons">
              <div className={"rcr-btn rcr-save" + (hasSaveData ? '' : ' disabled')} onClick={this.save}>
                <div>Save</div>
              </div>
              <div className={"rcr-btn rcr-cancel" + (hasSelected || hasSaveData ? '' : ' disabled')} onClick={this.cancel}>
                <div>Cancel</div>
              </div>
              <div className={"rcr-btn rcr-delete" + (this.props.ActiveSchedule ? '' : ' disabled')} onClick={this.delete}>
                <div>Delete</div>
              </div>
            </div>
          </div>}
        </div>
    );
  }
}

export default connect(mapStateToProps)(Recurrence);