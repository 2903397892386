import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import { LoadingDotSpinner } from '../../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');

  return {
    SelectedPolygons: state.ms_SelectedPolygons,
    CurrentEntity: state.ent_Current,
    SaveData: state.dbo_SaveData,
    SaveId: state.dbo_SaveId,
    VerifyDialogInProgress: state.blu_Dialog
  };
};

export class MV_SendTwilioCode extends React.PureComponent {
  componentDidMount() {
    if (this.props.VerifyDialogInProgress) { return; }

    let phoneNumber = this.props.CurrentEntity.Properties.find(x => x.Name == 'PhoneNumber').Value;
    phoneNumber = '+1' + phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    console.log({ PhoneNumber: phoneNumber });
    //call function to verify that phone number is valid and then send it a code: if we already know it won't be valid show error given
    let validPhoneNumber = true;
    let body = {
      PhoneNumber: phoneNumber
    }

    actions.ApiRequest('List/TwilioSendCode', body, (result, reqBody) => {
      console.log(result);
    }, false);

    if (validPhoneNumber) { //valid phone number
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: {
          Title: 'Verify Phone Number',
          Message:
            <div>
              <p>You will receive a text message shortly with a verification code. Please enter the code to proceed: </p>
              <div className='form-control'>
                <input style={{ marginTop: '10px', marginBottom: '20px' }} id='twilio-code' type='text' placeholder='123456' />
              </div>
              <div className='btn-container'>
                <div className='btn-bar-btn polygon-btn' onClick={this.confirmTwilioCode}><div>Confirm</div></div>
              </div>
            </div>,
          Buttons: []
        }
      }));
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: {
          Title: 'Error',
          Message: <div style={{ marginTop: '25px' }}><b>This phone number is not valid. Please re-enter your phone number</b></div>
        }
      }));
    }
  }

  twilioSuccess = () => {
    this.props.dispatch(actions.UpdateProp({ Key: 'blu_Dialog', Value: null }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'sr_MySalesUserInfoSubmit',
      Value: true
    }));
  }

  confirmTwilioCode = () => {
    let code = document.getElementById('twilio-code') ? document.getElementById('twilio-code').value : null;

    let phoneNumber = this.props.CurrentEntity.Properties.find(x => x.Name == 'PhoneNumber').Value;
    phoneNumber = '+1' + phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

    let body = {
      Code: code,
      PhoneNumber: phoneNumber
    }

    actions.ApiRequest('List/TwilioValidateCode', body, (result, reqBody) => {
      if (result.Source && result.Source.status == 'approved') { //if successful
        setTimeout(() => {
          this.twilioSuccess();
        }, 1500)
        actions.ApiRequest('Save/Save', { SaveData: this.props.SaveData }, (result) => {
          this.props.dispatch(actions.UpdateProp({
            Key: 'dbo_SaveId',
            Value: this.props.SaveId + 1
          }));
        });
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_Dialog',
          Value: {
            Title: 'Success',
            Message:
              <div>
                <div style={{ marginTop: '10px', marginBottom: '20px' }}>Phone Number Successfully Verified! </div>
                <LoadingDotSpinner />
              </div>,
            Buttons: []
          }
        }));
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_Dialog',
          Value: {
            Title: 'Verify Phone Number',
            Message:
              <div>
                <p>You will receive a text message shortly with a verification code. Please enter the code to proceed: </p>
                <div className='form-control'>
                  <input style={{ marginTop: '10px', marginBottom: '20px' }} id='twilio-code' type='text' placeholder='123456' />
                </div>
                <div><p style={{ color: 'red', fontWeight: '500' }}>Error: This code is invalid<br /><span style={{ fontSize: '.8rem', textAlign: 'left', color: 'black' }}>Didn't receive a code? Please verify that you entered the correct phone number</span></p></div>
                <div className='btn-container'>
                  <div className='btn-bar-btn polygon-btn' onClick={this.confirmTwilioCode}><div>Confirm</div></div>
                </div>
              </div>,
            Buttons: []
          }
        }));
      }
    }, false);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_SendTwilioCode);