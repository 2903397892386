import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import FormControl from './../../Form/v001/FormControl';
import cloneDeep from 'lodash/cloneDeep';
import { batch } from 'react-redux';
import GridRow from './GridRow';
import Inputmask from "inputmask";
import { FilterDropdown, FilterX } from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let param = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let ubadmin = ownProps.widget.Parameters.find(x => x.ParameterName === 'UBAdminPermGrid')
  let gridName = ownProps.widget.Parameters.find(x => x.ParameterName === 'GridName');
  let refresh = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshKey');
  let refreshOnLoad = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshOnLoad');
  let newRowKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'NewRowKey');
  let inlineNewRow = ownProps.widget.Parameters.find(x => x.ParameterName === 'InlineNewRow');
  let preventParam = ownProps.widget.Parameters.find(x => x.ParameterName === 'PreventParameterRefresh');
  let resetData = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetDataOnRefresh');
  let pivotGrid = ownProps.widget.Parameters.find(x => x.ParameterName === 'PivotGrid');
  let preventEntityShellRefresh = ownProps.widget.Parameters.find(x => x.ParameterName === 'PreventEntityShellRefresh');
  let highlightKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'HighlightKey');
  let paramRequired = ownProps.widget.Parameters.find(x => x.ParameterName === 'ParameterRequired');
  let staticRows = ownProps.widget.Parameters.find(x => x.ParameterName === 'StaticRows');
  let unsortable = ownProps.widget.Parameters.find(x => x.ParameterName === 'Unsortable');
  let preventDuplicates = ownProps.widget.Parameters.find(x => x.ParameterName === 'PreventDuplicates');
  let negativesInParenthesis = ownProps.widget.Parameters.find(x => x.ParameterName === 'NegativesInParenthesis');
  let highlightColumnGobKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'HighlightColumnGobKey');
  let gridDataKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'GridDataKey');
  let noResultsReset = ownProps.widget.Parameters.find(x => x.ParameterName === 'NoResultsReset');
  let storeTotalKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'StoreTotalKey');
  let storeTotalCols = ownProps.widget.Parameters.find(x => x.ParameterName === 'StoreTotalCols');
  let ignoreBlur = ownProps.widget.Parameters.find(x => x.ParameterName === 'IgnoreBlur');
  let refreshOnSave = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshOnSave');

  let gridData = state['_grid' + ownProps.widget.SceneWidgetId];
  if (gridData) {
    helpers.updateGridFromSaveQueue(gridData, state[gridData.SaveQueue]);
  }
  return {
    SWID: ownProps.widget.SceneWidgetId,
    Parameter: param ? ubadmin ? helpers.getUBAdminParam(state, param.ParameterValue) : helpers.getListParameter(state, param.ParameterValue) : undefined,
    GridName: gridName ? gridName.ParameterValue : undefined,
    GridData: gridData,
    SaveQueue: gridData && gridData.SaveQueue ? state[gridData.SaveQueue] : undefined,
    RefreshId: refresh ? state[refresh.ParameterValue] : undefined,
    PropChangeId: param ? state.PropChange[param.ParameterValue] : undefined,
    RefreshOnLoad: refreshOnLoad ? helpers.stringToBool(refreshOnLoad.ParameterValue) : null,
    RefreshOnSave: refreshOnSave ? helpers.stringToBool(refreshOnSave.ParameterValue) : null,
    NewRow: newRowKey ? state[newRowKey.ParameterValue] : undefined,
    NewRowKey: newRowKey ? newRowKey.ParameterValue : undefined,
    SaveId: state.dbo_SaveId,
    CancelId: state.dbo_CancelId,
    InlineNewRow: inlineNewRow ? helpers.stringToBool(inlineNewRow.ParameterValue) : undefined,
    PreventParameterRefresh: highlightKey ? true : preventParam ? helpers.stringToBool(preventParam.ParameterValue) : false,
    ResetDataOnRefresh: resetData ? helpers.stringToBool(resetData.ParameterValue) : false,
    PivotGrid: pivotGrid ? helpers.stringToBool(pivotGrid.ParameterValue) : false,
    HighlightKey: highlightKey ? state[highlightKey.ParameterValue] : undefined,
    ParameterRequired: paramRequired ? helpers.stringToBool(paramRequired.ParameterValue) : false,
    SWID: ownProps.widget.SceneWidgetId,
    PreventEntityShellRefresh: preventEntityShellRefresh ? helpers.stringToBool(preventEntityShellRefresh.ParameterValue) : false,
    StaticRows: staticRows && !isNaN(Number(staticRows.ParameterValue)) ? Number(staticRows.ParameterValue) : null,
    Unsortable: unsortable ? helpers.stringToBool(unsortable.ParameterValue) : false,
    PreventDuplicates: preventDuplicates ? helpers.stringToBool(preventDuplicates.ParameterValue) : false,
    IsRO: state['_grid' + ownProps.widget.SceneWidgetId + 'RO'],
    DoBlink: state['_grid' + ownProps.widget.SceneWidgetId + 'Blink'],
    NegativesInParenthesis: negativesInParenthesis ? helpers.stringToBool(negativesInParenthesis.ParameterValue) : false,
    HighlightColumn: highlightColumnGobKey ? state[highlightColumnGobKey.ParameterValue] : undefined,
    HighlightColumnGobKey: highlightColumnGobKey ? highlightColumnGobKey.ParameterValue : undefined,
    GridDataKey: gridDataKey ? gridDataKey.ParameterValue : undefined,
    NoResultsReset: noResultsReset ? noResultsReset.ParameterValue : undefined,
    StoreTotalKey: storeTotalKey ? storeTotalKey.ParameterValue : null,
    StoreTotalCols: storeTotalCols ? storeTotalCols.ParameterValue : null,
    ignoreBlur: ignoreBlur ? ignoreBlur.ParameterValue : false
  };
};

let _pcid = {};
let _refreshId = {};
let _saveId = {};
let _cancelId = {};
let _shellWaiting = {};
let _apiRequestId = {};

export class Grid extends React.PureComponent {
  unmounted = false;
  scrollIntoViewPending = false;

  state = {
    blink: false,
    filter: null
  }

  componentDidMount() {
    window.addEventListener('click', this.click);

    if (!this.props.GridName)
      return;

    this.initPcid();
    this.initRefreshId();
    this.saveQueueCheck();
    this.scrollIntoView();

    let fetch = false;
    let oldPcid = _pcid[this.props.SWID];
    let oldRefreshId = _refreshId[this.props.SWID];
    if (oldPcid !== this.props.PropChangeId || oldRefreshId !== this.props.RefreshId) {
      fetch = true;
    }
    if (!this.props.GridData) {
      fetch = true;
    }
    if (this.props.RefreshOnLoad) {
      fetch = true;
    }

    let oldSave = _saveId[this.props.SWID];
    let oldCancel = _cancelId[this.props.SWID];

    if ((oldSave && this.props.SaveId !== oldSave) || (oldCancel && this.props.CancelId !== oldCancel)) {
      this.clearUnsaved();
    }

    if (fetch) {
      if (this.props.GridData) {
        this.props.GridData.init = true;
      }
      this.getData();
    }
    this.checkScrollbar();
    this.checkInlineNewRow();
  }

  componentDidUpdate(prev) {
    if (!this.props.GridName)
      return;

    if (this.props.StoreTotalKey && this.props.StoreTotalCols) {
      this.storeTotals();
    }

    if (this.props.DoBlink || (prev && this.props.IsRO !== prev.IsRO)) {
      this.setState({ blink: true }, () => {
        this.setState({ blink: false });
      })
    }

    setTimeout(() => {
      if (this.unmounted) {
        console.log('prevented unmounted update');
        return;
      }

      this.saveQueueCheck();
      let entityChange = this.hasEntityChanged(prev);

      if (prev && (prev.SaveId !== this.props.SaveId || prev.CancelId !== this.props.CancelId)) {
        this.clearUnsaved();
        entityChange = true;
      }

      let getDataCalled = false;
      if (prev && prev.NewRow !== this.props.NewRow) {
        this.checkNewRow();
      } else if (!this.props.PreventParameterRefresh) {
        if (entityChange) {
          this.clearUnsaved();
        }
        this.getData();
        getDataCalled = true;
      } else if (entityChange) {
        this.clearUnsaved();
        this.getData(true);
        getDataCalled = true;
      }

      if (!getDataCalled && prev && prev.RefreshId && prev.RefreshId !== this.props.RefreshId) {
        this.getData(true);
      }

      if (this.props.RefreshOnSave && this.props.SaveId !== prev.SaveId) {
        this.getData(true);
      }

      this.checkScrollbar();
      this.checkInlineNewRow();

      if (this.scrollIntoViewPending) {
        this.scrollIntoView();
      }

      _pcid[this.props.SWID] = this.props.PropChangeId;
      _refreshId[this.props.SWID] = this.props.RefreshId;
    }, 0);
  }

  storeTotals = () => {
    let gd = this.props.GridData;
    if (!gd)
      return;

    let totals = {};
    let keys = this.props.StoreTotalCols.split(',').map(x => x.trim());
    keys.forEach(key => {
      totals[key] = 0;
      gd.Rows.forEach(row => {
        if (row[key] && !isNaN(Number(row[key].Value))) {
          totals[key] += Number(row[key].Value);
        }
      });
    });
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.StoreTotalKey,
      Value: totals
    }));
  }

  click = (e) => {
    console.log('grid click hit');
    let element = e.target;

    while (element) {
      if (element.classList.contains('grid-filter-list')) {
        return;
      }
      element = element.parentElement;
    }
    this.setState({ filter: null });

  }

  hasEntityChanged = (prev) => {
    if (!this.props.Parameter)
      return false;

    let entityChange = this.props.Parameter.EntityId && ((prev && !prev.Parameter) || (prev && prev.Parameter && prev.Parameter.EntityId !== this.props.Parameter.EntityId)) ||
      this.props.Parameter.RefreshId && ((prev && !prev.Parameter) || (prev && prev.Parameter && prev.Parameter.RefreshId !== this.props.Parameter.RefreshId));

    if (this.props.PreventEntityShellRefresh && this.props.Parameter.IsShell && entityChange) {
      entityChange = false;
      _shellWaiting[this.props.SWID] = true;
    }

    if (this.props.PreventEntityShellRefresh && !this.props.Parameter.IsShell && _shellWaiting[this.props.SWID]) {
      entityChange = true;
      _shellWaiting[this.props.SWID] = false;
    }

    return entityChange;
  }

  saveQueueCheck = () => {
    if (!this.props.GridData || !this.props.GridData.Rows || !this.props.GridData.SaveQueue || !this.props.SaveQueue || this.props.StaticRows !== null)
      return;

    this.props.GridData.Rows = this.props.GridData.Rows.filter((x) => {
      if (!x.unsaved || !x.SaveDataIds)
        return true;

      let missingItem = false;

      x.SaveDataIds.forEach(sdId => {
        if (!this.props.SaveQueue.find(s => s.SaveDataId === sdId)) {
          missingItem = true;
        }
      });

      return !missingItem;
    });
  }

  checkInlineNewRow = () => {
    if (!this.props.GridData || !this.props.GridData.NewRowProps || !this.props.InlineNewRow)
      return;

    let inlineRow = this.props.GridData.Rows.find(x => x.InlineNewRow);
    let staticRows = this.props.StaticRows;

    if (!inlineRow && (!staticRows || this.props.GridData.Rows.length < staticRows)) {
      let rowCount = 1;
      if (staticRows) {
        rowCount = staticRows - this.props.GridData.Rows.length;
      }

      for (var i = 0; i < rowCount; i++) {
        this.addNewRow(true);
      }
    }
  }

  clearUnsaved = () => {
    if (this.props.GridData) {
      this.props.GridData.Rows = this.props.GridData.Rows.filter(x => !x.unsaved);
    }
  }

  getData = (force) => {
    if (!force && !this.requestValid())
      return;

    let firstLoad = false;
    if (!this.props.GridData) {
      firstLoad = true;
    }

    if (this.props.ParameterRequired && (!this.props.Parameter || (typeof this.props.Parameter === 'object' && Object.keys(this.props.Parameter).length === 0))) {
      if (this.props.ResetDataOnRefresh && this.props.GridData) {
        this.clearData();
      }
      return;
    }

    let swid = this.props.SWID;
    _apiRequestId[swid] = (_apiRequestId[swid] || 0) + 1;

    let body = {
      GridName: this.props.GridName,
      Parameter: this.props.Parameter ? JSON.stringify(this.props.Parameter) : '',
      SceneWidgetId: swid,
      ApiRequestId: _apiRequestId[swid]
    }

    if (this.props.ResetDataOnRefresh && this.props.GridData) {
      this.clearData();
    }

    this.setLoading(true);

    actions.ApiRequest('List/GetGridResults', body, (result, req) => {
      this.setLoading(false);
      if (!result.Rows || req.ApiRequestId !== _apiRequestId[swid])
        return;

      if (result.Rows.length === 0) {
        this.noResultsReset();
      }

      let unsavedRows = this.props.GridData ? this.props.GridData.Rows.filter(x => x.unsaved) : [];
      let hiddenRows = this.props.GridData ? this.props.GridData.Rows.filter(x => x.IsHidden) : [];

      result.Rows = [...result.Rows.filter(x => !hiddenRows.find(h => h.Id && h.Id === x.Id)), ...unsavedRows];
      result.Rows = result.Rows.filter(x => !x.InlineNewRow);

      result.Rows.forEach(row => {
        if (row.AddAllPropSaveData) {
          this.addSaveDataRows(row, result);
          if (row.InsertOnLoad) {
            row.SaveDataRows.forEach(sd => {
              this.props.dispatch(actions.AddSaveData(sd));
            });
          }
        }
      });

      let onLoadRows = result.Rows.filter(x => x.OnLoadKey);
      onLoadRows.forEach(x => {
        x.OnLoadValue = JSON.parse(x.OnLoadValue);
        if (x.AutoLoad) {
          this.props.dispatch(actions.AutoLoadProp({
            Key: x.OnLoadKey,
            Value: x.OnLoadValue
          }));
        }
      });

      result.Columns.forEach(col => {
        if (col.Control && col.Control.DataMask && col.Control.DataMaskOptions) {
          let maskObj = eval('(' + col.Control.DataMaskOptions + ')');
          maskObj.alias = col.Control.DataMask;
          col._DataMaskOptions = maskObj;
        }
        if (col.SortType && (col.SortType === 'money' || col.Sort === 'number') && this.props.NegativesInParenthesis) {
          this.setNegativesToParenthesis(col, result);
        }
        if (col.IsFilterable) {
          let uniqueValues = [];
          let rows = JSON.parse(JSON.stringify(result.Rows));
          col.Filter = rows.filter(item => {
            if (!uniqueValues.includes(item[col.Name])) {
              uniqueValues.push(item[col.Name]);
              item.Active = true;
              return true;
            }
            return false;
          });;
        }
      });

      if (firstLoad) {
        this.scrollIntoViewPending = true;
      }

      batch(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: '_grid' + swid,
          Value: result
        }));
        if (this.props.GridDataKey) {
          this.props.dispatch(actions.UpdateProp({
            Key: this.props.GridDataKey,
            Value: result
          }));
        }
      })
    });
  }

  setNegativesToParenthesis = (col, result) => {
    result.Rows.forEach(row => {
      if (row[col.Name]) {
        let numRes = row[col.Name].toString().replace(/,/g, '');
        if (Number(numRes) < 0) {
          row[col.Name] = '(' + Math.abs(numRes).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ')';
        }
      }
    })
  }

  scrollIntoView = () => {
    this.scrollIntoViewPending = false;
    setTimeout(() => {
      let viewRow = document.querySelector('#swid-' + this.props.SWID + ' .scroll-into-view');
      if (viewRow) {
        viewRow.scrollIntoView({ block: "center" });
      }
    }, 0);
  }

  setLoading = (isLoading) => {
    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: '_grid' + this.props.SWID + 'Loading',
        Value: isLoading
      }));
    }, 0);
  }

  clearData = () => {
    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: '_grid' + this.props.SWID,
        Value: { ...this.props.GridData, Rows: [], init: false }
      }));
      if (this.props.GridDataKey) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GridDataKey,
          Value: { ...this.props.GridData, Rows: [], init: false }
        }));
      }
    });
  }

  checkNewRow = () => {
    if (this.props.NewRow && this.props.GridData && this.props.GridData.Rows && this.props.GridData.NewRowProps) {
      this.addNewRow(false);
    }
  }

  addNewRow = (blankRow) => {
    let newItem = blankRow ? { InlineNewRow: true } : cloneDeep(this.props.NewRow);
    newItem.unsaved = true;

    if (this.props.PreventDuplicates && !blankRow) {
      let isDupe = this.props.GridData.Rows.find(x => x.EntityId && x.EntityTypeId && x.EntityId === newItem.EntityId && x.EntityTypeId === newItem.EntityTypeId);
      if (isDupe) {
        return;
      }
    }

    this.addSaveDataRows(newItem, this.props.GridData);

    if (this.props.GridData.NewRowProps) {
      let rowProps = this.props.GridData.NewRowProps.find(x => x.RowProps);
      if (rowProps) {
        try {
          let rowPropJson = JSON.stringify(rowProps);
          Object.keys(newItem).forEach(key => {
            var replace = '"row.' + key + '"';
            var reg = new RegExp(replace, "g");
            rowPropJson = rowPropJson.replace(reg, newItem[key]);
          });

          rowProps = JSON.parse(rowPropJson);

          newItem = {
            ...newItem,
            ...rowProps
          }
        } catch { }
      }
    }

    this.props.GridData.Rows.push(newItem);
    if (!blankRow) {
      batch(() => {
        newItem.SaveDataRows.forEach(sd => {
          this.props.dispatch(actions.AddSaveData(sd));
        });

        this.props.dispatch(actions.UpdateProp({
          Key: this.props.NewRowKey,
          Value: undefined
        }));
      });
    }
    this.refresh(null, true);
  }

  addSaveDataRows = (rowItem, gridData) => {
    rowItem.SaveDataRows = [];

    if (!gridData.NewRowProps)
      return;

    gridData.Columns.forEach((col) => {
      if (col.Control && col.Control.ControlType && col.Control.SaveData) {
        let saveData = { ...col.Control.SaveData };

        if (rowItem[col.Name] && rowItem[col.Name].SaveData) {
          saveData = { ...saveData, ...rowItem[col.Name].SaveData };
        }

        let controlProps = {};
        let newRowItem = gridData.NewRowProps.find(x => x.Column === saveData.Column && x.Table === saveData.Table) || {};

        if (newRowItem && newRowItem.ControlProps) {
          controlProps = newRowItem.ControlProps;
        }

        rowItem[col.Name] = {
          ...controlProps,
          ...rowItem[col.Name],
          ...cloneDeep(col.Control)
        };

        rowItem[col.Name].SaveData = saveData;
      }
    });

    let insertKeys = {};

    gridData.NewRowProps.filter(x => !x.RowProps).forEach((x) => {
      let objVal = null;
      Object.keys(rowItem).forEach(key => {
        if (rowItem[key] !== undefined && rowItem[key] !== null && rowItem[key].SaveData && rowItem[key].SaveData.Column === x.Column) {
          objVal = rowItem[key];
        }
      });

      if (!objVal) {
        objVal = rowItem[x.Column] || null;
      }

      let val = objVal !== null && typeof objVal === 'object' ? objVal.Value : objVal;

      let insertKey = insertKeys[x.Table];
      if (!insertKey) {
        insertKey = helpers.getInsertKey();
        insertKeys[x.Table] = insertKey;
      }
      if (objVal && objVal.SaveData) {
        objVal.SaveData.InsertKey = insertKey;
      }

      let newRowVal = x.Value !== null && x.Value !== undefined ? x.Value : x.DefaultValue;

      let sdItemBase = objVal ? objVal.SaveData || {} : {};
      let sdItem = {
        ...sdItemBase,
        IsBaseTable: 1,
        ...x,
        InsertKey: insertKey,
        Value: (val !== null && val !== undefined) ? val : newRowVal,
        Id: null,
        SaveQueue: gridData.SaveQueue
      };

      delete sdItem.ControlProps;

      if (x.Reversed) {
        sdItem.Value = !sdItem.Value;
      }

      rowItem.SaveDataRows.push(sdItem);
    });

    rowItem.SaveDataRows.forEach(sd => {
      if (sd.Value && typeof sd.Value === 'string' && sd.Value.startsWith('InsertKey.')) {
        let keyVal = insertKeys[sd.Value.replace('InsertKey.', '')];
        sd.Value = keyVal + '';
      }
    });
  }

  checkScrollbar = () => {
    let hasScroll = false;
    let swid = this.props.SWID;
    let gridBody = document.querySelector('#swid-' + swid + ' .gw-body');
    let gridHeader = document.querySelector('#swid-' + swid + ' .grid-header');

    if (!gridBody || !gridHeader)
      return;

    hasScroll = gridBody.scrollHeight > gridBody.clientHeight;

    if (hasScroll) {
      gridHeader.classList.add('scroll-buffer');
    } else {
      gridHeader.classList.remove('scroll-buffer');
    }
  }

  requestValid = () => {
    if (!this.props.GridName)
      return false;

    if (this.props.GridData && !this.props.GridData.init) {
      this.props.GridData.init = true;
      return false;
    }

    return true;
  }

  getWidth = (val) => {
    if (val.includes('px'))
      val = '0 ' + val;

    val = val.replace('fr', '');
    return val;
  }

  handleClick = (onClickJson, row, e, colName) => {
    try {
      if (row.DisableClick || (row[colName] && row[colName].DisableClick))
        return;

      if (helpers.elementOrAncestorHasClass(e.target, 'inner-clickable') && !helpers.elementOrAncestorHasClass(e.target, 'click-target'))
        return;

      if (row.OnLoadKey) {
        if (e && e.target && !helpers.elementOrAncestorHasClass(e.target, 'col-IsDeleted')) {
          this.props.dispatch(actions.AutoLoadProp({
            Key: row.OnLoadKey,
            Value: row.OnLoadValue
          }));
        }
      }
      if (onClickJson) {
        Object.keys(row).forEach(key => {
          onClickJson = onClickJson.replace(new RegExp('@' + key, 'g'), row[key]);
        });

        if (row[colName] !== null && row[colName] !== undefined) {
          Object.keys(row[colName]).forEach(key => {
            onClickJson = onClickJson.replace(new RegExp('@cell.' + key, 'g'), row[colName][key]);
          });
        }

        let onClickObj = JSON.parse(onClickJson);
        if (this.props.HighlightColumnGobKey) {
          onClickObj.HighlightCol = colName;
        }
        if (Array.isArray(onClickObj)) {
          let onClickArray = onClickObj.map((x) => {
            let obj = { Key: x.key, Value: x.value };
            if (x.value && onClickObj.HighlightCol) {
              obj.Value.HighlightCol = onClickObj.HighlightCol;
            }
            return obj;
          });

          this.props.dispatch(actions.UpdateMultiple(onClickArray));
        } else {
          this.updateFromObject(onClickObj);
        }
      }
    } catch {
      console.error('Grid OnClick parse failed');
    }
  }

  updateFromObject = (obj) => {
    this.props.dispatch(actions.UpdateProp({
      Key: obj.key,
      Value: obj.value
    }));
  }

  getControl = (row, col) => {
    let forceUpdate = col.Name === 'IsDeleted' || row.InlineNewRow;
    row[col.Name].myControl = row[col.Name].myControl ||
      (<FormControl
        control={{
          IsRO: this.props.IsRO,
          ...row[col.Name],
          ...col.Control,
          SaveData: {
            ...col.Control.SaveData,
            ...row[col.Name].SaveData
          }
        }}
        trackChanges={true}
        isEventSave={false}
        ignoreBlur={this.props.ignoreBlur}
        refresh={() => { this.refresh(row, forceUpdate) }} />);
    try {
      row[col.Name].Value = row[col.Name].myControl.props.control.Value;
      //fix where values of 0 are displayed as null instead of 0 in all control boxes
      if (row[col.Name].myControl.props.control.Value === 0) {
        row[col.Name].myControl.props.control.Value = '0';
      }

      if (this.props.IsRO) {
        row[col.Name].myControl.props.control.IsRO = this.props.IsRO;
      }

    } catch { }
    return row[col.Name].myControl;
  }

  refresh = (row, forceUpdate) => {
    if (row && row.AddAllPropSaveData) {
      row.AddAllPropSaveData = false;
      batch(() => {
        row.SaveDataRows.forEach(sd => {
          this.props.dispatch(actions.AddSaveData(sd));
        });
      });
    }
    if (row && row.InlineNewRow) {
      row.InlineNewRow = false;
      row.SaveDataIds = [];
      batch(() => {
        row.SaveDataRows.forEach(sd => {
          let sdId = helpers.getId();
          row.SaveDataIds.push(sdId);
          sd.SaveDataId = sdId;
          this.props.dispatch(actions.AddSaveData(sd));
        });
      });
    }
    if (!this.unmounted && forceUpdate) {
      this.props.GridData.init = false;
      this.forceUpdate();
    }
  }

  initPcid = () => {
    if (!_pcid[this.props.SWID]) {
      _pcid[this.props.SWID] = this.props.PropChangeId;
    }
  }

  initRefreshId = () => {
    if (!_refreshId[this.props.SWID]) {
      _refreshId[this.props.SWID] = this.props.RefreshId;
    }
  }

  rowClassList = (idx, row) => {
    let classes = "grid-row" + (idx % 2 === 0 ? ' even' : ' odd');
    try {
      if (row.IsDeleted && row.IsDeleted.myControl && row.IsDeleted.myControl.props.control.Value === true) {
        let col = this.props.GridData.Columns.find(x => x.Name === 'IsDeleted');
        if (col && col.Control.ControlType === 'IsDeleted') {
          classes += ' deleted grid-row-blocker';
        }
      } else if (row.IsDeleted && !row.IsDeleted.myControl && row.IsDeleted.Value === true) {
        classes += ' deleted grid-row-blocker';
      }

      let highlight = this.props.HighlightKey;
      if (row.OnLoadValue && highlight) {
        let rowVal = row.OnLoadValue.EntityId || row.OnLoadValue.Id;
        let highlightVal = highlight.EntityId || highlight.Id;
        if (rowVal && highlightVal && rowVal === highlightVal) {
          classes += ' selected';
        }
      }
    } catch {
      console.error('error navigating isdeleted for grid row');
    }

    classes += row.ClassList ? ' ' + row.ClassList : ''

    return classes;
  }

  noResultsReset = () => {
    if (!this.props.NoResultsReset)
      return;

    let resetProp = this.props.NoResultsReset;
    let resetArr = resetProp.split(',').map(x => x.trim());
    resetArr.forEach(key => {
      this.props.dispatch(actions.UpdateProp({
        Key: key,
        Value: null
      }));
    });
  }

  sortColumn = (col) => {
    if (this.props.Unsortable)
      return;

    let prev = this.props.GridData.Sort;
    this.props.GridData.Sort = {
      Name: col.Name,
      Direction: prev && prev.Name === col.Name ? prev.Direction * -1 : 1
    }

    let direction = this.props.GridData.Sort.Direction;

    if (col.SortType === 'number' || col.SortType === 'money') {
      this.props.GridData.Rows.sort((a, b) => { return this.numberSort(a, b, col, direction) });
    } else if (col.SortType === 'date') {
      this.props.GridData.Rows.sort((a, b) => { return this.dateSort(a, b, col, direction) });
    } else {
      this.props.GridData.Rows.sort((a, b) => { return this.stringSort(a, b, col, direction) });
    }

    this.refresh(null, true);
  }

  numberSort = (a, b, col, dir) => {
    let aVal = a[col.Name] === null || a[col.Name] === undefined ? Number.MIN_SAFE_INTEGER : Number(a[col.Name]);
    let bVal = b[col.Name] === null || b[col.Name] === undefined ? Number.MIN_SAFE_INTEGER : Number(b[col.Name]);
    if (isNaN(aVal))
      aVal = Number.MIN_SAFE_INTEGER + 1;

    if (isNaN(bVal))
      bVal = Number.MIN_SAFE_INTEGER + 1;

    return aVal > bVal ? 1 * dir : bVal > aVal ? -1 * dir : 0;
  }

  dateSort = (a, b, col, dir) => {
    let aVal = new Date(a[col.Name]);
    let bVal = new Date(b[col.Name]);
    return aVal > bVal ? 1 * dir : bVal > aVal ? -1 * dir : 0;
  }

  stringSort = (a, b, col, dir) => {
    let aVal = ('' + (a[col.Name] || '')).toLowerCase();
    let bVal = ('' + (b[col.Name] || '')).toLowerCase();
    return aVal > bVal ? 1 * dir : bVal > aVal ? -1 * dir : 0;
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.click);

    if (this.props.GridData) {
      this.props.GridData.Rows.forEach(row => {
        for (let key in row) {
          if (row[key] && row[key].myControl) {
            row[key].myControl = null;
          }
        }
      })
    }

    _saveId[this.props.SWID] = this.props.SaveId;
    _cancelId[this.props.SWID] = this.props.CancelId;

    this.unmounted = true;
  }

  getRowValue = (val, col) => {
    if (val && typeof val === 'object') {
      return val.Value;
    }
    if (val && col.Control._DataMaskOptions) {
      return Inputmask.format(val, col.Control._DataMaskOptions);
    }
    return val;
  }

  toggleRowExpand = (row) => {
    row.showChildren = !row.showChildren;
    this.refresh(null, true);
  }

  filterChecked = (event, row, column) => {

    column.Filter.find(y => y[column.Name] == row[column.Name]).Active = event.target.checked;
    let otherFilters = this.props.GridData.Columns.filter(x => x.Name != column.Name && x.Filter && x.Filter.some(y => !y.Active));

    if (event.target.checked) { //on check
      this.props.GridData.Rows.forEach(row => {

        if (column.Filter.some(filter => filter.Active && filter[column.Name] === row[column.Name])) {
          row.FilterHidden = false;
          otherFilters.forEach(col => {
            if (col.Filter.find(filt => !filt.Active && filt[col.Name] === row[col.Name])) {
              row.FilterHidden = true;
            }
          })
        }

        // if (!otherFilters.find(col => {
        //   if (col.Filter.find(filt => !filt.Active && filt[col.Name] === row[col.Name])) {
        //     row.FilterHidden = true;
        //     return true;
        //   }
        // })) {
        //   if (row.FilterHidden && column.Filter.some(filter => filter.Active && filter[column.Name] === row[column.Name])) {
        //     row.FilterHidden = false;
        //   }
        // }



      })
    } else { //on uncheck
      this.props.GridData.Rows.forEach(row => {
        if (row.FilterHidden) { return; }
        if (column.Filter.some(filter => !filter.Active && filter[column.Name] === row[column.Name])) {
          row.FilterHidden = true;
        }
        // row.FilterHidden = column.Filter.some(filter => !filter.Active && filter[column.Name] === row[column.Name]);
      })
    }

    // UNCOMMENT FOR FILTERED OUT FILTERS (MAX 2 COLS FOR ISFILTERABLE)
    // let rows = JSON.parse(JSON.stringify(this.props.GridData.Rows.filter(x => !x.FilterHidden)));
    // this.props.GridData.Columns.forEach(col => {
    //   if (!col.Filter) { return; }
    //   if (col.Name === column.Name) { return; }
    //   if (col.Filter.find(x => !x.Active && !x.HiddenFilter)) { console.log({ HasInActive: col.Name, Filters: col.Filter }); return; }
    //   // if (col.Filter.some(x => !x.Active)) { console.log({ SomeActive: col }); return; }
    //   let uniqueValues = [];
    //   col.Filter = rows.filter(item => {
    //     if (!uniqueValues.includes(item[col.Name])) {
    //       uniqueValues.push(item[col.Name]);
    //       item.Active = true;
    //       return true;
    //     }
    //     item.Active = true;
    //     return false;
    //   });
    // })
    this.forceUpdate();
  }

  clearFilter = (e, column) => {
    e.stopPropagation();
    e.preventDefault();
    let showValues = [];
    this.props.GridData.Columns.find(x => x.Name == column.Name).Filter.forEach(y => {
      if (!y.Active) {
        y.Active = true;
        showValues.push(y[column.Name]);
      }
    })

    this.props.GridData.Rows.forEach(x => {
      if (x.FilterHidden && showValues.includes(x[column.Name])) {
        x.FilterHidden = false;
      }
    })

    // UNCOMMENT FOR FILTERED OUT FILTERS (MAX 2 COLS FOR ISFILTERABLE)
    // let uniqueValues = [];
    // let rows = JSON.parse(JSON.stringify(this.props.GridData.Rows.filter(x => !x.FilterHidden)));
    // this.props.GridData.Columns.forEach(col => {
    //   if (!col.Filter) { return; }
    //   if (col.Name === column.Name) { return; }
    //   if (col.Filter.some(x => !x.Active)) { return; }
    //   col.Filter = rows.filter(item => {
    //     if (!uniqueValues.includes(item[col.Name])) {
    //       uniqueValues.push(item[col.Name]);
    //       item.Active = true;
    //       return true;
    //     }
    //     return false;
    //   });
    // })

    this.forceUpdate();
  }

  showFilter = (e, column) => {
    e.stopPropagation();
    e.preventDefault();

    if (!this.state.filter) {
      this.setState({
        filter: <div className='grid-filter-list'>
          {column.Filter.map(x => (
            <div><label><input type='checkbox' defaultChecked={x.Active} onChange={(event) => this.filterChecked(event, x, column)} /> {x[column.Name]}</label></div>
          ))}
        </div>
      });
    } else {
      this.setState({ filter: null });
    }
  }

  render() {
    if (this.state.blink) {
      return (null);
    }

    let childRows = {};
    let displayRows = [];
    let hasChildRows = false;
    let sortCol = null;

    if (this.props.GridData && this.props.GridData.Rows && !this.props.PivotGrid) {

      this.props.GridData.Rows.forEach((x) => {
        if (x.ParentId && x.RowId && x.ParentId !== x.RowId) {
          childRows[x.ParentId] = [...(childRows[x.ParentId] || []), x];
          x.hiddenChildRow = true;
          hasChildRows = true;
        }
        if (x.showChildren === undefined) {
          x.showChildren = !!x.ExpandByDefault;
        }
      });
      displayRows = this.props.GridData.Rows.filter(x => !x.hiddenChildRow);
    } else if (this.props.GridData) {
      displayRows = this.props.GridData.Rows;
    }

    if (this.props.GridData) {
      sortCol = this.props.GridData.Sort;
    }

    displayRows = displayRows.filter(x => !x.IsHidden && !x.FilterHidden);

    return (
      !this.props.GridData ? null :
        this.props.PivotGrid ?
          <div className="grid-widget pivot-grid">
            <div className="gw-body">
              {
                displayRows.map((row, rowIdx) => (
                  <div key={rowIdx} className={this.rowClassList(rowIdx, row)}>
                    {this.props.GridData.Columns.map((col, colIdx) => (
                      <div key={rowIdx + '-' + colIdx}
                        className={"row-cell" + (col.OnClick ? ' clickable' : '') + (col.SortType ? ' sort-' + col.SortType : '')}
                        onClick={(e) => { if (col.OnClick) { this.handleClick(col.OnClick, row, e, col.Name) } }}
                        style={{ flex: this.getWidth(col.Width) }}>
                        {(!col.Control || !col.Control.ControlType) &&
                          <React.Fragment>
                            <div className="pivot-label">{col.Label}</div>
                            <div title={this.getRowValue(row[col.Name]) || undefined}>{this.getRowValue(row[col.Name])}</div>
                            {(!row[col.Name] || !row[col.Name].Warning) ? null :
                              <div className="grid-warning-icon">
                                <div>!</div>
                                <div className={"grid-warning-details" + (row[col.Name].Hover ? ' hover' : '')}>
                                  <div className="warning-details-wrapper">
                                    <div className="notch"></div>
                                    {row[col.Name].Warning}
                                  </div>
                                </div>
                              </div>
                            }
                          </React.Fragment>
                        }
                        {col.Control && col.Control.ControlType && <div className={"control-cell " + (col.Name + row[col.Name].SaveData.Id)}>
                          <div className="pivot-label">{col.Label}</div>
                          {this.getControl(row, col)}
                        </div>}
                      </div>
                    ))}
                  </div>
                ))
              }
            </div>
          </div>
          :
          <div className="grid-widget">
            <div className="grid-header">
              {this.props.GridData.Columns.map((x, idx) => (
                <>
                  <div key={idx}
                    className={'header-col' + ' header-' + x.Name}
                    style={{ flex: this.getWidth(x.Width) }}
                    onClick={() => { this.sortColumn(x) }}>
                    {x.Filter && <div id='grid-filter-dropdown' onClick={(e) => { this.showFilter(e, x) }} style={{ margin: '5px', height: '20px' }}><FilterDropdown /></div>}
                    <div>
                      {x.Filter && x.Filter.find(filt => !filt.Active) && <div id='grid-filter-x' onClick={(e) => { this.clearFilter(e, x) }} ><FilterX /></div>}
                      {x.Label}
                      {sortCol && sortCol.Name === x.Name && <div className={sortCol.Direction > 0 ? ' header-sort-asc' : ' header-sort-desc'}></div>}
                    </div>
                  </div>
                  {this.state.filter}
                </>
              ))}
            </div>
            <div className="gw-body">
              {
                displayRows.map((row, rowIdx) => (
                  <>
                    < GridRow key={rowIdx} row={row} rowIdx={rowIdx} parent={this} childRows={childRows} depth={0} hasChildRows={hasChildRows} highlightColumn={this.props.HighlightColumn} />
                  </>
                ))
              }
            </div>
          </div>
    );
  }
}

export default connect(mapStateToProps)(Grid);