import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class InfoCardHeader extends React.PureComponent {
  render() {
    let props = this.props.entProps;
    return (
      <div className="info-card-wrapper">
        <div className={"info-card " + this.props.cardForm}>
          {this.props.parent.props.IconSource &&
            <div className="info-card-icon">
              <img className="info-card-img" src={this.props.parent.entityIcon()}></img>
            </div>
          }
          <div className="info-card-text">
            {props[0] &&
              <div className="text-1">
                <div>{helpers.propDisplayValue(props[0], this.props.entity)}</div>
              </div>
            }
            {props[1] &&
              <div className="text-2">
                <div>{helpers.propDisplayValue(props[1], this.props.entity)}</div>
              </div>
            }
            {props[2] &&
              <div className="text-3">
                <div>{helpers.propDisplayValue(props[2], this.props.entity)}</div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(InfoCardHeader);