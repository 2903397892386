import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';


export default class LookupFilter extends React.PureComponent {
  // onButtonWasPressed() {
  //   this.props.filterChangedCallback();
  // }
  getLookups = () => {
    let allItems = helpers.getLookupItems(this.props.colDef.lookupSetId).map((x) => { return { ...x, active: true } });

    //dedupe lookups by name, keep track of all ids under that name in FilterIds array on each item
    let distinct = allItems.reduce((prev, next) => {
      if (!prev.Names[next.Name1]) {
        prev.Names[next.Name1] = true;
        prev.Items.push({ ...next, FilterIds: [next.LookupItemId] });
      } else {
        let nameMatchItem = prev.Items.find(x => x.Name1 === next.Name1);
        nameMatchItem.FilterIds.push(next.LookupItemId);
      }
      return prev;
    }, { Names: {}, Items: [] });

    return distinct.Items;
  }

  state = {
    lookupItems: this.getLookups(),
    allToggle: true,
    filterType: 'Lookup'
  }

  isFilterActive() {
    let isActive = !!this.state.lookupItems.find(x => !x.active);
    return isActive;
  }

  componentDidMount() {
    console.log(this.props);
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  toggle = (lookup) => {
    lookup.active = !lookup.active;
    let items = cloneDeep(this.state.lookupItems);
    this.setState({ lookupItems: items },
      () => { this.props.filterChangedCallback() });
  }

  toggleAll = () => {
    this.state.lookupItems.forEach(x => {
      x.active = !this.state.allToggle;
    });

    this.setState({ lookupItems: cloneDeep(this.state.lookupItems), allToggle: !this.state.allToggle },
      () => { this.props.filterChangedCallback() });
  }

  getModel() {
    let filterItems = this.state.lookupItems.filter(x => x.active);

    if (filterItems.length === this.state.lookupItems.length)
      return { value: '', remove: true };

    let filterIds = filterItems.reduce((prev, next) => {
      return [...prev, ...next.FilterIds];
    }, []);

    let filterStr = ' IN (' + filterIds.join(', ') + ')';

    if (filterItems.length === 0) {
      filterStr = ' IN (-1)';
    }

    let includeNull = !!filterItems.find(x => x.Name1 === '');
    console.log({ filterString: filterStr });
    return { value: filterStr, filterType: this.state.filterType, includeNull: includeNull };
  }

  setModel(model) {
    if (!model || !model.value) {
      this.state.lookupItems.forEach(x => x.active = true);
      this.setState({ lookupItems: cloneDeep(this.state.lookupItems) });
      return;
    }

    let includes = model.value.replace(' IN (', '').replace(')', '').split(', ').map(x => Number(x));
    let lookups = cloneDeep(this.state.lookupItems);
    lookups.forEach(x => x.active = false);
    includes.forEach(id => {
      let lookup = lookups.find(x => x.LookupItemId === id);
      if (lookup) {
        lookup.active = true;
      }
    });
    this.setState({ lookupItems: lookups });
  }

  doesFilterPass(params) {
    console.log({ doesFilterPass: params });
  }

  render() {
    return (
      <div className="ag-lookup-filter">
        <div className="ag-filter-item" onClick={this.toggleAll}>
          <input type="checkbox" checked={this.state.allToggle} onChange={() => { }} />
          <div>(Toggle All)</div>
        </div>
        {
          this.state.lookupItems.map((x, idx) => (
            <div key={idx} className="ag-filter-item" onClick={() => { this.toggle(x) }}>
              <input type="checkbox" checked={x.active} onChange={() => { }} />
              <div>{x.Name1 || '(Empty)'}</div>
            </div>
          ))
        }
      </div>
    )
  }
}

// export default connect(mapStateToProps)(LookupFilter);