import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../../Utils/Helpers';
import { MagnifyingGlass, RefreshArrow } from '../../../../../../Icons/Icons';
import { isConsole } from 'react-device-detect';
import DetailsInput from '../../Helpers/WidgetDetailsInput';
import GetDetails from '../../Helpers/GetDetails';
import Tabs from '../../Helpers/Tabs';
import Tab from '../../Helpers/Tab';

const mapStateToProps = (state, ownProps) => {

  let entProp = state.EditingWidget.Parameters.find(x => x.ParameterName === 'EntityOutput');
  let formProp = state.EditingWidget.Parameters.find(x => x.ParameterName === 'FormName');
  let formColumns = state.EditingWidget.Parameters.find(x => x.ParameterName === 'FormColumns');
  let gridRows = state.EditingWidget.Parameters.find(x => x.ParameterName === 'GridRows');
  let formColumnWidth = state.EditingWidget.Parameters.find(x => x.ParameterName === 'FormColumnWidth');


  let formEMCData = null;
  try {
    formEMCData = state.dbo_EditorDetails[ownProps.WidgetName][ownProps.Parent.props.EditSWID].Details.FormEMC;
  } catch { }


  return {
    Entity: entProp ? state[entProp.ParameterValue] : null,
    FormName: formProp ? formProp.ParameterValue : null,
    FormColumns: formColumns ? formColumns.ParameterValue : null,
    GridRows: gridRows ? gridRows.ParameterValue : null,
    FormColumnWidth: formColumnWidth ? formColumnWidth.ParameterValue : null,
    FormEMC: formEMCData,
    EntityMetadata: state.met_EntityMetadata
  };
};

export class Form extends React.PureComponent {
  state = {
    stretching: null,
    dragging: null,
    propLocationSave: []
  }

  rowHover = null;
  colHover = null;

  componentDidMount() {
    window.addEventListener('mouseup', this.mouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.mouseUp);
  }

  componentDidUpdate() {

  }

  getColumns = () => {
    let val = this.props.FormColumns || this.props.Entity.GridColumns;

    let colWidth = this.props.FormColumnWidth || '1fr';

    return 'repeat(' + val + ', ' + colWidth + ')';
  }

  getRows = () => {
    let fromEntity = this.props.Entity.GridRows;
    let val = this.props.GridRows || fromEntity;
    return val;
  }

  getDims = () => {
    return {
      cols: Number(this.props.FormColumns || this.props.Entity.GridColumns || 0),
      rows: 30
    }
  }

  saveDetails = (type) => {
    let body = {
      Type: type,
      Parameter: JSON.stringify({
        SWID: this.props.EditSWID,
        ...this.props.Parent.currentDetails(type)
      })
    }
    actions.ApiRequest('Editor/SaveDetails', body, () => {
      this.state.RefreshDetails[type]();
    });
  }

  getProps = () => {
    // new Array(dims.cols).fill('', 0, dims.cols)
    let typeId = this.props.Entity.EntityTypeId;
    let entType = this.props.EntityMetadata.EntityTypes.find(x => x.TypeId === typeId);
    return entType.Properties.filter(x => x.FormData).map((x) => {
      return {
        ...x,
        Forms: x.FormData.reduce((prev, next) => {
          let cells = [];
          let rowPieces = next.FormRow.split('/').map(x => Number(x));
          let colPieces = next.FormColumn.split('/').map(x => Number(x));
          for (var rId = rowPieces[0]; rId <= (rowPieces[rowPieces.length - 1] - (rowPieces.length > 1 ? 1 : 0)); rId++) {
            for (var cId = colPieces[0]; cId <= (colPieces[colPieces.length - 1] - (colPieces.length > 1 ? 1 : 0)); cId++) {
              cells.push(rId + '-' + cId);
            }
          }
          return {
            ...prev,
            [next.FormName]: {
              row: next.FormRow,
              col: next.FormColumn,
              cells: cells
            }
          }
        }, {})
      }
    });
  }

  stretchClick = (e, prop) => {
    this.setState({ stretching: prop, dragging: null });
  }

  dragClickCheck = (e, row, col) => {
    e.preventDefault();

    let entProps = this.getProps();
    let clickedProp = entProps.filter(x => x.Forms[this.props.FormName]).find(x => x.Forms[this.props.FormName].cells.includes(row + '-' + col));
    if (clickedProp) {
      this.dragClick(clickedProp);
    }
  }

  dragClick = (prop) => {
    this.setState({
      dragging: {
        prop: prop,
        startRow: this.rowHover,
        startCol: this.colHover
      },
      stretching: null
    });
  }

  mouseOver = (row, col) => {
    this.rowHover = row;
    this.colHover = col;
    if (this.state.stretching) {
      this.stretchProp(row, col);
    }

    if (this.state.dragging) {
      this.dragProp(row, col);
    }
  }

  dragProp = (row, col) => {
    let entType = this.props.EntityMetadata.EntityTypes.find(x => x.TypeId === this.props.Entity.EntityTypeId);
    let prop = entType.Properties.find(x => x.Id === this.state.dragging.prop.Id);
    let formData = prop.FormData.find(x => x.FormName === this.props.FormName);

    let colPieces = formData.FormColumn.split('/').map(x => x.trim());
    let rowPieces = formData.FormRow.split('/').map(x => x.trim());

    let dims = {
      colMin: Number(colPieces[0]),
      colMax: Number(colPieces[colPieces.length - 1]),
      rowMin: Number(rowPieces[0]),
      rowMax: Number(rowPieces[rowPieces.length - 1]),
      rCalcMax: Number(rowPieces[rowPieces.length - 1]) - (rowPieces.length > 1 ? 1 : 0),
      cCalcMax: Number(colPieces[colPieces.length - 1]) - (colPieces.length > 1 ? 1 : 0)
    }

    let rowDelta = row - this.state.dragging.startRow;
    let colDelta = col - this.state.dragging.startCol;

    let formDims = this.getDims();
    let changed = false;

    if (dims.rowMin + rowDelta > 0 && dims.rCalcMax + rowDelta <= formDims.rows) {
      dims.rowMin += rowDelta;
      dims.rowMax += rowDelta;
      changed = true;
    }

    if (dims.colMin + colDelta > 0 && dims.cCalcMax + colDelta <= formDims.cols) {
      dims.colMin += colDelta;
      dims.colMax += colDelta;
      changed = true;
    }

    if (changed) {
      this.updatePropLocation(dims, formData, prop);
      this.state.dragging.startRow = row;
      this.state.dragging.startCol = col;
    }
  }

  stretchProp = (row, col) => {
    let entType = this.props.EntityMetadata.EntityTypes.find(x => x.TypeId === this.props.Entity.EntityTypeId);
    let prop = entType.Properties.find(x => x.Id === this.state.stretching.Id);
    let formData = prop.FormData.find(x => x.FormName === this.props.FormName);

    let colPieces = formData.FormColumn.split('/').map(x => x.trim());
    let rowPieces = formData.FormRow.split('/').map(x => x.trim());

    let dims = {
      colMin: Number(colPieces[0]),
      colMax: Number(colPieces[colPieces.length - 1]),
      rowMin: Number(rowPieces[0]),
      rowMax: Number(rowPieces[rowPieces.length - 1]),
      rCalcMax: Number(rowPieces[rowPieces.length - 1]) - (rowPieces.length > 1 ? 1 : 0),
      cCalcMax: Number(colPieces[colPieces.length - 1]) - (colPieces.length > 1 ? 1 : 0)
    }

    let changed = false;
    if (dims.cCalcMax > col && dims.colMin <= col || dims.cCalcMax < col) {
      dims.colMax = col + 1;
      changed = true;
    }

    if (dims.rCalcMax > row && dims.rowMin <= row || dims.rCalcMax < row) {
      dims.rowMax = row + 1;
      changed = true;
    }

    if (changed) {
      this.updatePropLocation(dims, formData, prop);
    }
  }

  updatePropLocation = (dims, formData, prop) => {
    let newCol = dims.colMax - dims.colMin <= 1 ? dims.colMin + '' : dims.colMin + ' / ' + dims.colMax;
    let newRow = dims.rowMax - dims.rowMin <= 1 ? dims.rowMin + '' : dims.rowMin + ' / ' + dims.rowMax;
    formData.FormRow = newRow;
    formData.FormColumn = newCol;

    let saveData = this.state.propLocationSave.filter(x => x.EMCId !== prop.Id)

    saveData = [...saveData, {
      EMCId: prop.Id,
      FormName: this.props.FormName,
      Row: newRow,
      Col: newCol
    }];

    this.setState({
      propLocationSave: saveData
    });

    this.forceUpdate();
  }

  savePropLocation = () => {
    let body = {
      Type: 'FormPropLocation',
      Parameter: JSON.stringify({
        Rows: this.state.propLocationSave
      })
    }

    actions.ApiRequest('Editor/SaveDetails', body, () => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'RefreshEM',
        Value: true
      }));
    });

    this.setState({ propLocationSave: [] });
  }

  mouseUp = () => {
    if (!this.state.stretching && !this.state.dragging)
      return;

    this.setState({ stretching: null, dragging: null });
  }

  saveDetails = (type) => {
    let body = {
      Type: type,
      Parameter: JSON.stringify({
        SWID: this.props.Parent.props.EditSWID,
        EntityTypeId: this.props.Entity.EntityTypeId,
        Rows: this.props.Parent.currentDetails(type)
      })
    }
    actions.ApiRequest('Editor/SaveDetails', body, () => {
      this.state.RefreshDetails[type]();
      this.props.dispatch(actions.UpdateProp({
        Key: 'RefreshEM',
        Value: true
      }));
    });
  }


  render() {
    let dims = this.getDims();
    let entProps = this.getProps();
    return (
      <>
        <GetDetails Type={'FormEMC'} Parameter={{ EntityTypeId: this.props.Entity.EntityTypeId }} Parent={this} DataOnly={true} />
        <div className="form-details">
          <div className="form-header">
            {
              <div>
                <GetDetails Type={'FormEM'} Parameter={{ EntityTypeId: this.props.Entity.EntityTypeId }} Parent={this} Editable={true} />
                <div className="dtl-save-btn" onClick={() => { this.saveDetails('FormEM') }}><div>Save</div></div>
              </div>
            }
          </div>
          <div className="editor-form-grid" style={{ gridAutoRows: this.getRows() + 'px', gridTemplateColumns: this.getColumns() }}>
            {
              entProps.filter(x => x.Forms[this.props.FormName]).map((x, idx) => (
                <div key={idx} className={"form-detail-prop" +
                  (this.state.stretching ? ' stretching' : '') +
                  (this.state.dragging ? ' dragging' : '') +
                  (this.state.dragging && this.state.dragging.prop.Name === x.Name ? ' drag-target' : '') +
                  (this.state.stretching && this.state.stretching.Name === x.Name ? ' stretch-target' : '')
                }
                  style={{ gridColumn: x.Forms[this.props.FormName].col, gridRow: x.Forms[this.props.FormName].row }}>
                  <div className="prop-container">
                    <div className="prop-label">{x.Label}</div>
                  </div>
                  <div className="stretch-container">
                    <div className="stretch-handle" onMouseDown={(e) => { this.stretchClick(e, x) }}></div>
                  </div>
                </div>
              ))
            }
            {
              new Array(dims.rows).fill('', 0, dims.rows).map((rId, rIdx) => (
                new Array(dims.cols).fill('', 0, dims.cols).map((cId, cIdx) => (
                  <div key={rIdx + '-' + cIdx} className={"form-cell " + ('col-' + cIdx + 1) + (' row-' + rIdx + 1)} style={{ gridColumn: cIdx + 1, gridRow: rIdx + 1 }}
                    onMouseEnter={() => { this.mouseOver(rIdx + 1, cIdx + 1) }}
                    onMouseDown={(e) => { this.dragClickCheck(e, rIdx + 1, cIdx + 1) }}
                  >
                  </div>
                ))
              ))
            }
          </div>
          <div className="form-right-pane">
            {/* {this.props.FormEMC && JSON.stringify(this.props.FormEMC)} */}
            <div className="dtl-save-btn" onClick={this.savePropLocation}>
              <div>Save</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(Form);
