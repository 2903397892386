import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep'
import * as actions from './../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';
import ResourceGrid from './ResourceGrid';
import ResourceEntry from './ResourceEntry';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class DesktopInterface extends React.PureComponent {
  render() {
    let parent = this.props.parent;
    let isEditMode = parent.isEditMode();
    return (
      <div className="resources">
        <div className="resources-grid-container">
          {this.props.entity.HasTime &&
            <ResourceGrid
              Resource={parent.props.Resources.Time}
              setEntryType={parent.setEntryType}
              setEntryItem={parent.setEntryItem} />
          }
          {this.props.entity.HasMaterial &&
            <ResourceGrid
              Storages={parent.props.Resources.Storages}
              Resource={parent.props.Resources.Material}
              Components={parent.props.Resources.Components}
              setEntryType={parent.setEntryType}
              setEntryItem={parent.setEntryItem} />
          }
          {this.props.entity.HasEquipment &&
            <ResourceGrid
              Resource={parent.props.Resources.Equipment}
              setEntryType={parent.setEntryType}
              setEntryItem={parent.setEntryItem} />
          }
        </div>
        <div className="entry">
          {this.props.entity.HasTime && parent.state.Entry === 'Time' ?
            <ResourceEntry
              Resource={parent.props.Resources.Time}
              addRow={parent.addRow}
              updateRow={parent.updateRow}
              clearEntry={parent.clearEntry}
              removeRow={parent.removeRow}
              isEditMode={isEditMode} /> : null}
          {this.props.entity.HasMaterial && parent.state.Entry === 'Material' ?
            <ResourceEntry
              Resource={parent.props.Resources.Material}
              Components={parent.props.Resources.Components}
              Storages={parent.props.Resources.Storages}
              addRow={parent.addRow}
              updateRow={parent.updateRow}
              clearEntry={parent.clearEntry}
              removeRow={parent.removeRow}
              isEditMode={isEditMode} /> : null}
          {this.props.entity.HasEquipment && parent.state.Entry === 'Equipment' ?
            <ResourceEntry
              Resource={parent.props.Resources.Equipment}
              addRow={parent.addRow}
              updateRow={parent.updateRow}
              clearEntry={parent.clearEntry}
              removeRow={parent.removeRow}
              isEditMode={isEditMode} /> : null}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DesktopInterface);


