import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import Board from 'react-trello';
import * as hub from '../../../../Utils/PubSub';


const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class KanbanCard extends React.PureComponent {

  componentDidMount() {
  }

  onClick = (col) => {
    if (!col.OnClick)
      return;

    let onClick = col.OnClick;
    let keys = Object.keys(this.props.card);

    keys.forEach(key => {
      onClick = onClick.replace(new RegExp('@' + key, 'g'), this.props.card[key]);
    });

    let onClickObj = JSON.parse(onClick);

    if (Array.isArray(onClickObj)) {
      let onClickArray = onClickObj.map((x) => {
        let obj = { Key: x.key, Value: x.value };
        return obj;
      });

      this.props.dispatch(actions.UpdateMultiple(onClickArray));
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: onClickObj.key,
        Value: onClickObj.value
      }));
    }

  }

  render() {
    let card = this.props.card;
    return (
      <div className="kanban-card">
        <div className="card-wrapper" style={{ display: 'grid', gridAutoColumns: '1fr', gridAutoRows: '1fr' }}>
          {this.props.columns.map((col, idx) => (
            <div key={idx} style={col.styleObj} onClick={() => { this.onClick(col) }} >
              {col.Name && <div>{card[col.Name]}</div>}
              {col.Icon && <div className={"svg-container" + (col.OnClick ? ' clickable' : '')}><col.Icon /></div>}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(KanbanCard);


