import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state) => {
  return {
    CurrentUser: state.met_EntityMetadata.CurrentUser
  };
}

export class NewDocument extends PureComponent {
  newDocument = () => {
    this.props.dirtySaveQueue();

    this.props.dispatch(actions.NewDocument({
      DocumentId: 'NewDocument' + helpers.getId(),
      DocumentsKey: this.props.DocumentsKey,
      AssociatedWith: this.props.CurrentUser,
      Comment: '',
      DocDate: new Date().toISOString().slice(0, 10),
      DocTypeId: null,
      IsDeleted: false,
      IsDisplay: false,
      IsGroup: false,
      IsPublic: false,
      IsNew: true,
      Active: true,
      Dirty: true
    }));
  }

  render() {
    if (!this.props.CurrentEntity)
      return null;

    let disabled = false;
    if (this.props.Documents && this.props.Documents.Items && !this.props.SaveQueue) {
      disabled = this.props.Documents.Items.filter(x => typeof x.DocumentId === 'string' && x.DocumentId.startsWith('NewDocument')).length > 0;
    }

    if (Object.keys(this.props.CurrentEntity).length === 0) {
      disabled = true;
    }

    return (
      <div className="new-document">
        <div className={"new-doc " + (disabled ? 'disabled' : '')} onClick={() => { if (!disabled) { this.newDocument() } }}>
          <div>{this.props.NewText || 'New Document'}</div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(NewDocument);
