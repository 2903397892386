import React, { PureComponent } from 'react'
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
const mapStateToProps = (state, ownProps) => {
  let titleProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'Title');
  let isEventModal = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsEventModal');
  return {
    Title: titleProp ? titleProp.ParameterValue : '',
    IsEventModal: isEventModal ? helpers.stringToBool(isEventModal.ParameterValue) : false
  };
}
export class ModalHeader extends PureComponent {
  close = () => {
    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_NewEventParent',
        Value: undefined
      }));
    });
  }
  render() {
    return (
      <div className="modal-frame">
        <div className="modal-title">{this.props.Title}</div>
        <div className="modal-close-btn" onClick={this.close}>✖</div>
      </div>
    )
  }
}
export default connect(mapStateToProps)(ModalHeader);
