import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import 'ol/ol.css';
import { Draw, Modify, Snap } from 'ol/interaction';
import { Point, MultiPoint, MultiLineString, MultiPolygon } from 'ol/geom';
import { WKT } from 'ol/format';
import * as helpers from './../../../../../Utils/Helpers';
import MobileEditControls from './MobileEditControls';
import EditControls from './EditControls';
import { Vector as LayerVector } from 'ol/layer';
import { Vector as SourceVector } from 'ol/source';
import { Style, Circle, Fill, Stroke } from 'ol/style';
import clickZoom from 'ol/interaction/DoubleClickZoom';
import { buffer } from 'ol/extent';
import cloneDeep from 'lodash/cloneDeep';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
import Collection from 'ol/Collection';


const mapStateToProps = (state, ownProps) => {
  let geoReset = state.map_GeoReset;
  let needsReset = false;
  let skipSaveData = false;
  if (geoReset && ownProps.map && !geoReset[ownProps.map.swid]) {
    state.map_GeoReset[ownProps.map.swid] = true;
    if (state.map_GeoReset.SkipSaveData) {
      skipSaveData = true;
    }
    needsReset = true;
  }

  let firstLoad = false
  if(!state.FirstMapLoad) {
    firstLoad = true
  }

  return {
    CurrentEntity: ownProps.currentEntity,
    GeoReset: needsReset,
    SkipSaveData: skipSaveData,
    GeometryRefresh: state.map_GeometryRefresh,
    SaveId: state.dbo_SaveId,
    CancelId: state.dbo_CancelId,
    ShowUserLocation: ownProps.showUserLocation,
    UserLocation: state.UserLocation,
    SWID: ownProps.map ? ownProps.map.swid : 0,
    FirstMapLoad: firstLoad ? true : false,
    ShowMobileControls: state.ShowMobileControls ? state.ShowMobileControls : false,
  };
}

export class EntityGeometry extends PureComponent {

  componentDidUpdate(prevProps) {
    if (this.props.GeoReset) {
      this.reset(this.props.SkipSaveData);
    }
    this.setup(prevProps);
  }

  reset = (skipSaveData) => {
    if (!this.props.map)
      return;

    this.removeEditInteractions(this.props.map);
    let editLayer = this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');
    if (editLayer) {
      let fets = editLayer.getSource().getFeatures();
      fets.forEach(x => editLayer.getSource().removeFeature(x));
    }
    if (!skipSaveData) {
      this.clearGeo();
    }
  }

  setup = (prevProps) => {
    if (this.props.ShowUserLocation) {
      this.addUserLocationMarker();
    }

    if (!this.props.CurrentEntity)
      return;

    let map = this.props.map;
    if (!map)
      return;

    let isEdit = map.editMode;
    let editLayer = map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');
    let hasEditInteraction = false;
    if (editLayer) {
      hasEditInteraction = editLayer.getSource().getFeatures().length > 0 && this.props.map.geoEditInteractions;
    }

    if (isEdit) {
      this.addEditControls();
    }

    if (isEdit && prevProps && prevProps.GeometryRefresh !== this.props.GeometryRefresh) {
      this.addEditInteractions(map, editLayer);
    } else if (isEdit && !hasEditInteraction) {
      if(this.props.SWID === 965) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'FirstMapLoad',
          Value: true
        }))
      }

      if(this.props.SWID === 965 && this.props.FirstMapLoad) {
        this.addEditInteractions(map, editLayer);
      }

      if(this.props.SWID !== 965) {
        this.addEditInteractions(map, editLayer);
      }
    }

    if (!isEdit && hasEditInteraction) {
      this.removeEditInteractions(map);
    }
  }

  addUserLocationMarker = () => {
    setInterval(() => {
      this.setMarkerPosition();
    }, 3000);
  }

  setMarkerPosition = () => {
    if (!this.props.UserLocation)
      return;

    let layers = this.props.map.getLayers().getArray();
    let userLocationLayer = layers.find(x => x.LayerId === 'UserLocation');
    let ogCoords = fromLonLat([this.props.UserLocation.Longitude, this.props.UserLocation.Latitude]);

    if (!userLocationLayer) {
      let layer = new LayerVector({
        style: () => {
          return new Style({
            image: new Circle({
              radius: 6,
              fill: new Fill({ color: '#0D5485' }),
              stroke: new Stroke({ color: '#B0D9FF', width: 3 })
            })
          });
        },
        source: new SourceVector({
          features: [
            new Feature({
              geometry: new Point(ogCoords)
            }),
          ]
        })
      })
      layer.setZIndex(1111);
      layer.LayerId = 'UserLocation';
      this.props.map.addLayer(layer);

      this.props.map.once('movestart', function (e) {
        let flash = (feature) => {
          let start = new Date().getTime();
          let animate = (event) => {
            let duration = 4000;
            let vectorContext = getVectorContext(event);
            let frameState = event.frameState;
            let flashGeom = feature.getGeometry().clone();
            let elapsed = frameState.time - start;
            let elapsedRatio = elapsed / duration;
            let radius = easeOut(elapsedRatio) * 15 + 5;
            let opacity = easeOut(1 - (elapsedRatio * 2));
            let style = new Style({
              image: new Circle({
                radius: radius,
                stroke: new Stroke({
                  color: 'rgba(208, 229, 251, ' + opacity + ')',
                  width: 3 + opacity,
                }),
              }),
            });
            vectorContext.setStyle(style);
            vectorContext.drawGeometry(flashGeom);
            if (elapsed > duration) {
              unByKey(listenerKey);
              return;
            }
            e.map.render();
          }
          let listenerKey = e.map.getLayers().getArray().find(x => x.LayerId === 'UserLocation').on('postrender', animate);
        }
        let userLocationLayer = e.map.getLayers().getArray().find(x => x.LayerId === 'UserLocation');
        setInterval(() => {
          flash(userLocationLayer.getSource().getFeatures()[0]);
        }, 3500);
      });
    }
  }

  addEditInteractions = (map, editLayer, typeOverride = null) => {
    if (!editLayer)
      return;

    this.removeEditInteractions(this.props.map);
    let modify = new Modify({ source: editLayer.getSource() });
    let snapCollection = new Collection([], {
      unique: true
    });
    if (this.props.map.Layout.EditControls && this.props.map.Layout.EditControls.isSnap) {
      map.getLayers().forEach((layer) => {
        if (layer.LayerId) {
          if (layer.getSource().getFeatures) {
            layer.getSource().getFeatures().forEach((feat) => {
              snapCollection.push(feat);
            })
          }
        }
      });
    }
    let snap = new Snap({ features: snapCollection });

    modify.on('modifystart', (e) => {
      this.setGlobalEdit(editLayer);
    });

    modify.on('modifyend', (e) => {
      this.saveGeoEdit(editLayer);
    });

    let interactions = [modify, snap];
    if (this.props.map.entityLookupComplete && editLayer.getSource().getFeatures().length === 0) {
      let theType = typeOverride || this.getGeoType();

      if (!theType) {
        console.log('Geometry type not found');
        return
      }

      let draw = new Draw({
        source: editLayer.getSource(),
        type: theType
      });

      draw.on('drawend', (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.feature.geometry = draw.type_.toUpperCase();
        e.feature.setId(helpers.entId(this.props.CurrentEntity));
        map.removeInteraction(draw);
        map.geoEditInteractions = [modify, snap];
        this.doubleClickZoomEnabled(false);
        setTimeout(() => { this.doubleClickZoomEnabled(true); }, 251);
        setTimeout(() => { this.saveGeoEdit(editLayer); }, 0);
      });

      map.addInteraction(draw);
      interactions.push(draw);
    }


    map.addInteraction(modify);
    map.addInteraction(snap);

    map.geoEditInteractions = interactions;
    this.addEditControls(snap);
  }

  updateGeoType = (type) => {
    this.clearGeo(type);
  }

  doubleClickZoomEnabled = (isActive) => {
    var interactions = this.props.map.getInteractions().getArray();
    interactions.forEach(x => {
      if (x instanceof clickZoom) {
        x.setActive(isActive);
      }
    });
  }

  addEditControls = (snap) => {
    let controls = this.props.map.Layout.EditControls;
    if (!controls || (!snap && !controls.snap))
      return;

    if (controls && controls.SWID) {
      controls.snap = snap || controls.snap;
      let container = document.querySelector('#swid-' + controls.SWID + ' .form');
      if (container) {
        ReactDOM.render(<EditControls map={this.props.map} controls={controls} useManualLatLong={() => { this.useManualLatLong() }} useMyLocation={() => { this.useMyLocation() }} addGeo={() => { this.addGeo() }} clearLast={() => { this.clearLast() }} clear={() => { this.clearGeo() }} CurrentEntity={this.props.CurrentEntity} updateGeoType={this.updateGeoType} />, container);
      }
    }
  }

  clearGeo = (typeOverride = null) => {
    if (!this.props.CurrentEntity)
      return;

    //helpers.globalGeoDelete(helpers.entId(this.props.CurrentEntity));
    this.reset(true);
    let editLayer = this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');
    this.addEditInteractions(this.props.map, editLayer, typeOverride);

    this.props.dispatch(actions.AddSaveData({
      Id: this.props.CurrentEntity.EntityId,
      Table: this.props.CurrentEntity.BaseTable,
      Column: 'Geometry',
      Value: 'null',
      IsEventData: false,
      SaveQueue: this.props.map.Layout.SaveQueue || undefined
    }));
  }

  useManualLatLong = () => {
    let lat = document.getElementById('manual-lat').value;
    let long = document.getElementById('manual-long').value;
    if (isNaN(lat) || isNaN(long) || lat == 0 || long == 0) {
      return;
    }
    setTimeout(() => {
      let editLayer = this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');
      let zoom = this.props.map.getView().getZoom();
      this.props.map.getView().animate({
        center: fromLonLat([long, lat]),
        duration: 800,
        zoom: zoom < 19 ? 19 : zoom
      });

      let source = editLayer.getSource();
      let fet = source.getFeatureById(helpers.entId(this.props.CurrentEntity));
      let projLoc = fromLonLat([long, lat]);
      let point = new Point(projLoc);

      if (fet) {
        fet.setGeometry(point);
        this.saveGeoEdit(editLayer);
      } else {
        this.removeEditInteractions(this.props.map);
        let latLongPoint = point.transform('EPSG:3857', 'EPSG:4326');

        let format = new WKT();
        let latLongPointWKT = format.writeGeometry(latLongPoint);

        let newFet = new WKT().readFeature(latLongPointWKT, {
          dataProjection: this.props.map.projection,
          featureProjection: 'EPSG:3857'
        });

        newFet.geometry = latLongPointWKT;
        newFet.setId(helpers.entId(this.props.CurrentEntity));
        source.addFeature(newFet);

        this.saveGeoEdit(editLayer);
      }
    }, 0);
  }

  useMyLocation = () => {
    setTimeout(() => {
      let editLayer = this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');
      let zoom = this.props.map.getView().getZoom();
      this.props.map.getView().animate({
        center: fromLonLat([this.props.UserLocation.Longitude, this.props.UserLocation.Latitude]),
        duration: 800,
        zoom: zoom < 19 ? 19 : zoom
      });

      let source = editLayer.getSource();
      let fet = source.getFeatureById(helpers.entId(this.props.CurrentEntity));
      let projLoc = fromLonLat([this.props.UserLocation.Longitude, this.props.UserLocation.Latitude]);
      let point = new Point(projLoc);

      if (fet) {
        fet.setGeometry(point);
        this.saveGeoEdit(editLayer);
      } else {
        this.removeEditInteractions(this.props.map);
        let latLongPoint = point.transform('EPSG:3857', 'EPSG:4326');

        let format = new WKT();
        let latLongPointWKT = format.writeGeometry(latLongPoint);

        let newFet = new WKT().readFeature(latLongPointWKT, {
          dataProjection: this.props.map.projection,
          featureProjection: 'EPSG:3857'
        });

        newFet.geometry = latLongPointWKT;
        newFet.setId(helpers.entId(this.props.CurrentEntity));
        source.addFeature(newFet);

        this.saveGeoEdit(editLayer);
      }
    }, 0);
  }

  addGeo = (typeOverride = null) => {
    let editLayer = this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');
    let map = this.props.map;
    let modify = new Modify({ source: editLayer.getSource() });
    let snap = new Snap({ source: editLayer.getSource() });
    let interactions = [modify, snap];
    let theType = typeOverride || this.getGeoType();

    modify.on('modifystart', (e) => {
      this.setGlobalEdit(editLayer);
    });

    modify.on('modifyend', (e) => {
      this.saveGeoEdit(editLayer);
    });

    let draw = new Draw({
      source: editLayer.getSource(),
      type: theType
    });

    map.addInteraction(draw);
    interactions.push(draw);
    if (this.props.map.Layout.EditControls && this.props.map.Layout.EditControls.isSnap) {
      map.addInteraction(snap);
      interactions.push(snap);
    }

    draw.on('drawend', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.doubleClickZoomEnabled(false);
      setTimeout(() => { this.doubleClickZoomEnabled(true); }, 251);
      map.removeInteraction(draw);
      this.saveGeoEdit(editLayer);
    })
  }

  clearLast = () => {
    let editLayer = this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');
    let features = editLayer.getSource().getFeatures();
    let format = new WKT();
    let geoType = this.getGeoType();
    let wkt;
    let coords = [];
    let multiGeo;

    features.forEach(fet => {
      let coord = fet.getGeometry().getCoordinates();
      if (Array.isArray(coord[0])) {
        coord.forEach(c => {
          coords.push(c);
        });
      } else {
        coords.push(coord);
      }
    });
    if (coords.length > 1) {
      coords.pop();
    }

    if (geoType === 'MultiPoint') {
      multiGeo = new MultiPoint(coords);
    }
    if (geoType === 'MultiLineString') {
      multiGeo = new MultiLineString(coords);
    }
    if (geoType === 'MultiPolygon') {
      multiGeo = new MultiPolygon(coords);
    }

    editLayer.getSource().getFeatures()[0].getGeometry().setCoordinates(coords);

    wkt = format.writeGeometry(multiGeo.transform('EPSG:3857', this.props.map.projName));
    this.addGeoSaveData(wkt);
  }

  saveGeoEdit = (editLayer) => {
    if (!this.props.CurrentEntity) {
      console.log('could not find entity - make sure "CurrentEntity" is set in the map layout');
      return;
    }

    let geoType = this.getGeoType();
    let format = new WKT();

    let wkt;

    if (['MultiPoint', 'MultiLineString', 'MultiPolygon'].includes(geoType)) {
      setTimeout(() => {
        let multiGeo;
        let coords = [];
        let features = editLayer.getSource().getFeatures();

        features.forEach(fet => {
          let coord = fet.getGeometry().getCoordinates();
          if (Array.isArray(coord[0])) {
            coord.forEach(c => {
              coords.push(c);
            });
          } else {
            coords.push(coord);
          }
        });

        if (geoType === 'MultiPoint') {
          multiGeo = new MultiPoint(coords);
        }
        if (geoType === 'MultiLineString') {
          //checking if original geo is LineString and converting it to MultiLineString
          if (!Array.isArray(coords[0][0])) {
            let singleLineStringCoords = cloneDeep(coords);
            singleLineStringCoords.pop();
            let convertedMultiLine = [singleLineStringCoords, coords[coords.length - 1]];
            coords = convertedMultiLine;
            editLayer.getSource().getFeatures()[0].setGeometryName('MULTILINESTRING');
          }
          multiGeo = new MultiLineString(coords);
        }
        if (geoType === 'MultiPolygon') {
          //checking if original geo is Polygon and converting it to MultiPolygon
          if (coords[0][0].length === 2) {
            let singlePolygonCoords = cloneDeep(coords);
            singlePolygonCoords.pop();
            let convertedMultiPolygon = [singlePolygonCoords, coords[coords.length - 1]];
            coords = convertedMultiPolygon;
            editLayer.getSource().getFeatures()[0].setGeometryName('MULTIPOLYGON');
          }
          multiGeo = new MultiPolygon(coords);
        }

        editLayer.getSource().getFeatures().forEach((x, idx) => {
          if (idx > 0) {
            editLayer.getSource().removeFeature(x);
          }
        });

        if (editLayer.getSource().getFeatures()[0].getGeometry()) {
          editLayer.getSource().getFeatures()[0].getGeometry().setCoordinates(coords);
        }

        wkt = format.writeGeometry(multiGeo.transform('EPSG:3857', this.props.map.projName));
        this.addGeoSaveData(wkt);
      }, 0);
    } else {
      var feature = editLayer.getSource().getFeatures()[0];
      let cloneGeo = feature.getGeometry().clone();
      wkt = format.writeGeometry(cloneGeo.transform('EPSG:3857', this.props.map.projName));

      if (this.props.parentSwid) {
        let parent = window.MapInstance[this.props.parentSwid];
        if (parent) {
          let parentEditLayer = parent.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');
          if (parentEditLayer) {
            parentEditLayer.getSource().clear();
            parentEditLayer.getSource().addFeature(feature);
          }

          let extent = feature.getGeometry().getExtent();
          parent.getView().fit(buffer(extent, 15), parent.getSize());
          let zoom = parent.getView().getZoom();
          if (zoom > 19) {
            parent.getView().setZoom(19);
          }
        }
      }

      this.addGeoSaveData(wkt);
    }
  }

  addGeoSaveData = (wkt) => {
    this.props.CurrentEntity.Geometry = wkt;

    this.props.dispatch(actions.AddSaveData({
      Id: this.props.CurrentEntity.EntityId,
      Table: this.props.CurrentEntity.BaseTable,
      Column: 'Geometry',
      Value: wkt,
      IsEventData: false,
      SaveQueue: this.props.map.Layout.SaveQueue || undefined
    }));
  }

  getGeoType = () => {
    if (!this.props.CurrentEntity || !this.props.CurrentEntity.GeometryType) {
      console.log('geo edit could not find entity or entity geo type is not set');
      return;
    }

    let types = {
      1: 'Point',
      2: 'LineString',
      3: 'Polygon',
      4: this.props.CurrentEntity.CurrentType || 'Point',        //this will show a dropdown for user to select type, default to Point
      5: 'MultiPoint',
      6: 'MultiLineString',
      7: 'MultiPolygon'
    }

    return types[this.props.CurrentEntity.GeometryType];
  }

  setGlobalEdit = (editLayer) => {
    if (!editLayer)
      return;

    let fet = editLayer.getSource().getFeatures()[0];
    if (fet) {
      helpers.globalGeoUpdate(fet.getId(), fet.getGeometry());
    }
  }

  removeEditInteractions = (map) => {
    if (map.geoEditInteractions) {
      map.geoEditInteractions.forEach(i => {
        map.removeInteraction(i);
      });

      map.geoEditInteractions = undefined;
    }
  }

  render() {
    return (
      this.props.mobileEditMode
      ?
      <MobileEditControls
        map={this.props.map} 
        controls={this.props.map?.Layout?.EditControls} 
        useManualLatLong={() => { this.useManualLatLong() }} 
        useMyLocation={() => { this.useMyLocation() }} 
        addGeo={() => { this.addGeo() }} 
        clearLast={() => { this.clearLast() }} 
        clear={() => { this.clearGeo() }} 
        CurrentEntity={this.props.CurrentEntity} 
        updateGeoType={this.updateGeoType}
        showControls={this.props.ShowMobileControls}
      />
      :
      null
    )
  }
}

export default connect(mapStateToProps)(EntityGeometry);