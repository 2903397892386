import React, { PureComponent } from 'react'
import { connect, batch } from 'react-redux';
import Select from 'ol/interaction/Select';
import StyleEngine from './../Styles/StyleEngine';
import StyleFunction from './../Styles/FeatureStyle';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state[ownProps.EntityKey],
    Modal: state.blu_ModalScene,
    CurrentTenant: state.ent_ContractorSelected,
  };
}

export class EntitySelect extends PureComponent {
  state = {
    styles: null,
    hitTolerance: this.props.CardlistSelect ? 7 : 0,
    resetSelected: false
  }
  componentDidMount() {
    this.setState({ resetSelected: true });
  }

  componentDidUpdate(prev) {
    if (this.props.map && !this.props.map.entitySelect) {
      this.createSelectListener();
    }

    if (prev && this.props.ResetSelectedOnModalClose && prev.Modal && (!this.props.Modal || this.props.Modal.Value === 'null')) {
      this.resetSelected();
    }

    if (this.state.resetSelected && this.props.map && this.props.map.Layout && this.props.map.Layout.ResetSelectedOnLoad) {
      this.setState({ resetSelected: false });
      this.resetSelected();
    }
  }

  resetSelected = () => {
    let map = this.props.map;
    if (map && map.entitySelect && map.entitySelect.getFeatures()) {
      map.entitySelect.getFeatures().clear();
    }
  }

  createSelectListener = () => {
    if (!this.props.map || (!this.props.map.ZoomToAsset && !this.props.map.Layout.EntitySelectScene && !this.props.map.Layout.ClickActionJson))
      return;

    let cardList = [];

    let select = new Select({
      multi: this.props.CardlistSelect,
      hitTolerance: this.state.hitTolerance,
      style: (feature, p1, e2) => {
        console.log({ fet: feature, p1: p1, e2: e2 });

        if (feature.displayOnly) {
          feature.setStyle(null);
          return;
        }

        if (!this.props.map.Layout || !this.props.map.Layout.KeepStyleOnSelect) {
          feature.setStyle(null);
        } else {
          feature.setStyle(feature.style);
        }

        if (feature.doNotLoadEntity) {
          feature.doNotLoadEntity = false;
          return null;
        }

        if (this.props.CardlistSelect) {
          if (select.getFeatures().array_.length === 1) {
            this.loadEntity(feature);
          } else {
            cardList = []
            select.getFeatures().forEach(feat => {
              let idArr = feat.id_.split('_');
              cardList.push({ EntityId: idArr[1], EntityTypeId: idArr[0] });
            });
            batch(() => {
              this.props.dispatch(actions.UpdateProp({
                Key: 'cardlist_Items',
                Value: cardList
              }));
              this.props.dispatch(actions.UpdateProp({
                Key: 'blu_ModalScene',
                Value: { Value: 'MapSelectorModal', Enabled: true }
              }));
            })
          }
        } else {
          this.loadEntity(feature);
        }

        return null;
      },
      toggleCondition: (feature) => {
        console.log(this.props.map.entitySelect);
        return false;
      },
      removeCondition: (e) => {
        console.log('removecondition');
        return false;
      },
      layers: (l) => {
        if (this.props.map && this.props.map.editMode)
          return false;

        return !l.id || l.Name !== 'CurrentEntityLayer';
      },
    });

    this.props.map.addInteraction(select);
    this.props.map.entitySelect = select;
  }

  loadEntity = (entity) => {
    let id = entity.getId();
    if (!id)
      return;

    let ids = id.split('_');
    if (ids.length !== 2)
      return;

    let pIds = null;
    if (entity.parentId) {
      pIds = entity.parentId.split('_').map(x => Number(x));
    }

    let overrideScene = null;
    let override = this.props.map.Layout.EntitySelectOverride;
    if (this.props.map.Layout.EntitySelectOverride) {
      overrideScene = override[ids[0]];
      if (!overrideScene && pIds) {
        overrideScene = override[pIds[1]];
      }
    }

    if (this.props.map.Layout.ClickActionJson) {
      this.doActionJson(entity);
      return;
    }

    let setScene = overrideScene || this.props.map.Layout.EntitySelectScene;
    if (setScene) {
      this.props.dispatch(actions.UpdateProp({
        Key: setScene.Key,
        Value: {
          Value: setScene.Value,
          Label: setScene.Label || '',
          Id: setScene.Id,
          Enabled: true
        }
      }));
    }

    setTimeout(() => {
      if (pIds) {
        let entityKey = 'ent_Selected';
        if (overrideScene && overrideScene.SelectedKey) {
          entityKey = overrideScene.SelectedKey;
        }
        this.props.dispatch(actions.UpdateProp({
          Key: entityKey,
          Value: {
            EntityId: pIds[0],
            EntityTypeId: pIds[1],
            Id: pIds[0] + '-' + pIds[1]
          }
        }));
      }
      let entKey = this.props.EntityKey;
      if (this.props.map.Layout.EntityKeyOverride && this.props.map.Layout.EntityKeyOverride[ids[0]]) {
        entKey = this.props.map.Layout.EntityKeyOverride[ids[0]];
      }
      this.props.dispatch(actions.UpdateProp({
        Key: entKey,
        Value: {
          EntityId: Number(ids[1]),
          EntityTypeId: Number(ids[0]),
          Id: ids[0] + '-' + ids[1]
        },
        OnInterrupt: () => {
          this.clearSelection();
        }
      }));
    }, 0);
  }

  doActionJson = (feature) => {
    console.log(feature);
    let aj = this.props.map.Layout.ClickActionJson;
    let jsonstr = aj[feature.isQueryLayer ? 'Query' : feature.layerId];

    // let jsonstr = this.props.map.Layout.ClickActionJson;
    jsonstr = jsonstr.replace(/@eId/g, feature.eId);
    jsonstr = jsonstr.replace(/@etId/g, feature.etId);

    if(this.props.CurrentTenant && this.props.CurrentTenant.EntityId) {
      jsonstr = jsonstr.replace(/@tId/g, this.props.CurrentTenant.EntityId)
    }

    try {
      let json = JSON.parse(jsonstr);
      helpers.actionJson(json);
      setTimeout(() => {
        this.props.map.entitySelect.getFeatures().clear();
      }, 0);
    } catch (e) {
      console.error(e);
    }
  }

  clearSelection = () => {
    if (!this.props.map || !this.props.map.entitySelect)
      return;

    this.props.map.entitySelect.getFeatures().clear();
  }

  getStyles = () => {
    let styles = StyleEngine.getStyleLibrary();
    this.setState({ styles: styles });
    return styles;
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(EntitySelect);