import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    AuditEntity: state[ownProps.Trigger]
  };
};

export class AuditRecordModal extends React.PureComponent {

  componentDidMount() {
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene2',
      Value: { Value: 'AuditRecordModal' }
    }));

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_AuditRecord',
      Value: this.props.AuditEntity
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(AuditRecordModal);
