import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state.ent_Current,
    NewItem: state.ent_NewItem
  };
};

export class MySalesmanLibrarySelected extends React.PureComponent {

  componentDidMount() {
    let selectedCard = this.props.Trigger;
    let newItem = this.props.NewItem.slice();
    newItem[0].FormControls.Name.Value = selectedCard.Name1;
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_NewItem',
      Value: newItem
    }));

    let oldImagePath = selectedCard.ImagePath;
    let photoUploader = document.querySelector('.new-cat-card .photo-upload-control');

    this.props.dispatch(actions.UpdateProp({
      Key: photoUploader.id,
      Value: oldImagePath
    }));

    this.props.dispatch(actions.AddSaveData({
      InsertKey: -300000,
      SaveQueue: 'dbo_CatalogSaveData',
      Table: 'xmv.Item',
      Column: 'Name1',
      EntityTypeId: newItem[0].FormEntity.EntityTypeId,
      Value: selectedCard.Name1
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MySalesmanLibrarySelected);