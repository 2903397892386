
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import PublicApp from './PublicApp';
import * as serviceWorker from "./serviceWorker";
import { unregister } from './serviceWorker';
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./Utils/history";
import { LicenseManager } from "ag-grid-enterprise";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

LicenseManager.setLicenseKey('CompanyName=Beehive Industries, LLC,LicensedApplication=BeehiveBetty,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-032362,SupportServicesEnd=21_October_2023_[v2]_MTY5Nzg0MjgwMDAwMA==784eccff4de6917965ae9dd482da6d34');

window.publicCodes = ['e311'];
window.salemanPublicCodes = ['lcr']

let href = window.location.href.toLowerCase();

window.isSalesmanPublic = href.indexOf('app=') === -1 && href.indexOf('qualify.mysalesman') !== -1;

if (window.isSalesmanPublic && href.split('.com')[1].length < 3) { //mysalesman redirect if no code provided
  console.log('redirect hit');
  window.location.href = 'https://www.mysalesman.com/';
}

window.isSalesmanPublic |= !!window.salemanPublicCodes.find(x => href.includes(x) && !href.includes('dbname'));

if (window.isSalesmanPublic || window.publicCodes.find(x => href.includes(x))) {
  ReactDOM.render(<PublicApp />, document.getElementById("root"));
} else {
  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = appState => {
    console.log(appState);
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  ReactDOM.render(
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.href}
      audience={window.location.href.includes("qualify.mysalesman.com") ? 'https://qualify.mysalesman.com' : config.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>,
    document.getElementById("root")
  );
}

// serviceWorker.register();
unregister();

let keysPressed = {};

document.addEventListener('keydown', event => {
  keysPressed[event.key] = true;

  if (keysPressed['Control'] && event.key === 'q') {
    window._logout({
      returnTo: window.location.href,
      federated: true
    });
  }
});

document.addEventListener('keyup', event => {
  keysPressed[event.key] = false;
});

var newManifest = {
  "short_name": "Beehive",
  "name": "Beehive",
  "icons": [{
    "src": "https://beehivetilcache.blob.core.windows.net/staticassets/logo.png",
    "sizes": "512x512",
    "type": "image/png"
  }],
  "start_url": window.location.href,
  "display": "standalone",
  "theme_color": "#ffa500",
  "background_color": "#000000",
  "scope": window.location.href
};

// window.location.href.split('/?')[1]

const newManifestStr = JSON.stringify(newManifest);
const blob = new Blob([newManifestStr], { type: 'application/json' });
const manifestURL = URL.createObjectURL(blob);
document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);

//General BH2 GA Tag
if(!window.location.href.includes("qualify.mysalesman.com")) {
  ReactGA.initialize("G-LCGKF7Q5SK");
}

if(!window.location.href.includes('localhost')) {
  Sentry.init({
    dsn: "https://62129652fb528a3ec34d856414e7ff95@o4506950623690752.ingest.us.sentry.io/4507107732750336",
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    sampleRate: 0.10,
  });
}
