import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  let selectedQuery = state.qry_SelectedQuery;
  let parentQuery = selectedQuery ? state.qry_QueryList.find(x => x.QueryId === selectedQuery.ParentId) : undefined;

  return {
    Query: selectedQuery,
    ParentQuery: parentQuery,
    Queries: state.qry_Queries,
    RowCount: selectedQuery ? state.qry_Queries[selectedQuery.QueryId].rowCount : undefined,
    Data: selectedQuery ? state.qry_Queries[selectedQuery.QueryId].data : undefined
  };
};

export class TitleBar extends React.PureComponent {
  state = {
    requests: 1
  }
  componentDidUpdate(prevProps) {
    if (this.props.PageRequests && this.props.PageRequests.length < (prevProps.PageRequests ? prevProps.PageRequests.length : 0)) {
      this.setState({ requests: this.state.requests + 1 });
    }
  }

  render() {
    let parent = this.props.ParentQuery;
    let data = this.props.Data;

    return (
      <div className="title-bar">
        {this.props.Query && <div className="grid-label">{(parent ? parent.Text1 + ' > ' : '') + this.props.Query.Text1}</div>}
        {this.props.Query && <div className="grid-rowcount">{(data ? (data.rowCount || 0).toLocaleString() : 0)} Rows</div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(TitleBar);