import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { Menu } from './../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class ColumnPicker extends React.PureComponent {

  state = {
    showMenu: false,
    toggleAllChecked: true,
    queryId: null
  }

  componentDidUpdate() {
    if (this.state.queryId !== this.props.queryId) {
      this.setState({
        queryId: this.props.queryId,
        toggleAllChecked: true
      });
    }
  }

  onClick = (e) => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('mouseup', this.mouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.removeEventListener('mouseup', this.mouseUp);
  }

  mouseUp = (e) => {
    if (this.state.showMenu && !helpers.elementOrAncestorHasClass(e.target, 'picker-container')) {
      this.setState({ showMenu: false });
    }
  }

  keyPress = (e) => {
    if (e.key === 'Escape') {
      this.setState({ showMenu: false });
    }
  }

  toggleColumn = (col, displayedCols) => {

    if (col.field === 'toggle-all') {
      this.props.columns.filter(x => !x.LinkType && x.headerName[0] !== '!').forEach(x => {
        window.agGridApi.columnApi.setColumnVisible(x.field, !this.state.toggleAllChecked);
      });
      this.setState({ toggleAllChecked: !this.state.toggleAllChecked });
    } else {
      window.agGridApi.columnApi.setColumnVisible(col.field, !displayedCols[col.field]);
    }

    setTimeout(() => {
      this.forceUpdate();
    }, 0);
  }

  render() {
    let displayedCols = {};
    if (window.agGridApi && window.agGridApi.columnApi) {
      try {
        displayedCols = window.agGridApi.columnApi.columnModel.displayedColumns.reduce((prev, next) => {
          prev[next.colId] = true;
          return prev;
        }, {});
      } catch { }
    }

    let cols = this.props.columns.filter(x => !x.LinkType);

    displayedCols['toggle-all'] = this.state.toggleAllChecked;

    cols = cols.sort((a, b) => {
      return a.headerName > b.headerName ? 1 : b.headerName > a.headerName ? -1 : 0;
    });

    cols.unshift({ headerName: '(Toggle All)', field: 'toggle-all' });

    return (
      <div className="picker-container" >
        <div title="Show/Hide Columns" onClick={this.onClick}>
          <Menu />
        </div>
        {this.state.showMenu &&
          <div className="column-picker-items">
            {cols.map((x, idx) => (
              <div key={idx} className="column-picker-item" onClick={() => { this.toggleColumn(x, displayedCols) }} >
                <input type="checkbox" checked={!!displayedCols[x.field]} />
                <div>{x.headerName}</div>
              </div>
            ))}
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(ColumnPicker);
