import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';

const mapStateToProps = (state, ownProps) => {
  return {
    SaveData: state.dbo_SaveData
  };
}

export class RichTextBox extends React.PureComponent {
  onChange = (e) => {
    this.props.control.Value = e.target.value;
    this.forceUpdate();

    if (this.props.refresh)
      this.props.refresh();

    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: e.target.value,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
  }

  render() {
    let control = this.props.control;
    return (
      <div className={"richtextbox-control" + (control.FailedValidate ? " failed-validate" : "")}>
        <div className="control-label">
          {control.Label}
          {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
        </div>
        <textarea
          // tabIndex={control.Location && control.Location.ControlOrder ? control.Location.ControlOrder : 0}
          disabled={control.IsRO} type="text" value={control.Value || ''}
          maxLength={control.MaxValue ? control.MaxValue : null}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(RichTextBox);