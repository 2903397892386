import React from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import { fromLonLat } from 'ol/proj'
import Point from 'ol/geom/Point';
import * as helpers from '../../../../../Utils/Helpers';
import { defaults as defaultControls, Control } from 'ol/control';
import { PlusButton, MinusButton, PanTool, FindMe, MiniMap } from './../../../../Icons/Icons';
import { Measurement } from './../Drawing/Measurement';

const mapStateToProps = (state) => {
  return {
    UserLoc: state.UserLocation
  };
}

export class ControlPanel extends React.PureComponent {
  zoom = (delta) => {
    this.props.map.getView().animate({
      zoom: this.props.map.getView().getZoom() + delta,
      duration: 300
    });
  }

  findMe = () => {
    if (!this.props.UserLoc || !this.props.UserLoc.Latitude)
      return;

    this.props.map.getView().animate({
      center: fromLonLat([this.props.UserLoc.Longitude, this.props.UserLoc.Latitude]),
      duration: 800
    });
  }

  resetZoom = () => {
    this.props.resetZoom()
  }

  render() {
    return (
      <div className="control-panel">
        <div className="top-controls">
          <div className="map-control">
            <div onClick={() => { this.zoom(1) }}>
              <PlusButton />
            </div>
          </div>
          <div className="map-control">
            <div onClick={() => { this.zoom(-1) }}>
              <MinusButton />
            </div>
          </div>
          <div className="map-control"><PanTool /></div>
          <div><Measurement map={this.props.map} /></div>
        </div>
        <div className="bottom-controls">
          <div className="map-control">
            <div onClick={this.findMe}>
              <FindMe />
            </div>
          </div>
          <div className="map-control">
            <div onClick={this.resetZoom}>
              <MiniMap />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ControlPanel);




