import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    GroupingEntity: state.ent_GroupEntitySelected,
    Metadata: state.met_EntityMetadata
  };
};

export class GroupingNewEntity extends React.PureComponent {

  componentDidMount() {
    let ent = this.props.GroupingEntity;
    let type = this.props.Metadata.EntityTypes.find(x => x.TypeId === ent.EntityTypeId);

    if (ent.GroupingRecordAdded || !ent.IsNew)
      return;

    ent.GroupingRecordAdded = true;
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_AddToGroup',
      Value: {
        TargetEntityId: ent.EntityId,
        TargetEntityTypeId: ent.EntityTypeId,
        Name: '[New Asset]',
        Type: type.Label
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(GroupingNewEntity);