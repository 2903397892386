import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import Point from 'ol/geom/Point'
import * as actions from '../../../../../Stores/Actions/actions';
import { Vector as LayerVector } from 'ol/layer'
import { Vector as SourceVector } from 'ol/source';
import Feature from 'ol/Feature';
import StyleEngine from './../Styles/StyleEngine';
import { WKT } from 'ol/format';

const mapStateToProps = (state, ownProps) => {
  return {
    MapFeatures: state.map_Features ? state.map_Features[ownProps.swid] : []
  };
}

export class Drawing extends PureComponent {
  componentDidMount() {
    this.drawFeatures();
  }

  componentDidUpdate() {
    this.drawFeatures();
  }

  drawFeatures = () => {
    if (!this.props.MapFeatures || !this.props.map)
      return;

    this.clearCenterPoint();
    this.props.MapFeatures.forEach(fet => {
      if (fet === 'Centerpoint')
        this.drawCenterPoint();
    });
  }

  drawCenterPoint = () => {
    this.clearCenterPoint();
    let vector = this.getDrawingVector();
    let center = this.props.map.getView().getCenter();
    let format = new WKT();
    var pointFet = new Feature({
      name: 'CenterPoint',
      geometry: new Point([center[0], center[1]])
    });

    pointFet.setId('CenterPoint');
    vector.addFeature(pointFet);

    let rawPt = new Point([center[0], center[1]]).transform('EPSG:3857', this.props.map.projection);

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_CenterPointGeo',
      Value: format.writeGeometry(rawPt)
    }));
  }

  clearCenterPoint = () => {
    let vector = this.getDrawingVector();
    let oldCenter = vector.getFeatureById('CenterPoint');
    if (oldCenter) {
      vector.removeFeature(oldCenter);
    }
  }

  getDrawingVector = () => {
    let layers = this.props.map.getLayers().getArray();
    let drawLayer = layers.find(x => x.LayerId === 'Drawing');
    let vector;

    if (!drawLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,

      });
      layer.setZIndex(1111);
      layer.LayerId = 'Drawing';
      layer.Vector = vector;
      this.props.map.addLayer(layer);
    } else {
      vector = drawLayer.Vector;
    }
    return vector;
  }

  render() {
    return null
  }
}

export default connect(mapStateToProps)(Drawing);


