import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';

const mapStateToProps = (state, ownProps) => {
  return {
    SaveData: state.dbo_SaveData
  };
}

export class TimePicker extends React.PureComponent {
  onChange = (e) => {
    this.props.control.Value = e.target.value;
    if (this.props.control.FailedValidate) {
      this.props.control.FailedValidate = false;
    }
    this.forceUpdate();

    if (this.props.refresh)
      this.props.refresh();

    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: e.target.value,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey
      }));
    }
  }

  render() {
    let control = this.props.control;
    let val = '';
    if (control && control.Value) {
      let pieces = control.Value.split(':');
      val = pieces[0] + ':' + pieces[1];
    }
    return (
      <div className={"textbox-control" + (control.FailedValidate ? " failed-validate" : "")}>
        <div className="control-label">
          {control.Label}
          {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
        </div>
        <input disabled={control.IsRO} type="time" value={val || ''} onChange={this.onChange} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(TimePicker);


