import React from 'react';
import { connect } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from './../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';
import * as icons from '../../../Icons/Icons';
import * as Azure from '@azure/storage-blob';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'TextSource');
  let staticText = ownProps.widget.Parameters.find(x => x.ParameterName === 'Text');
  let clickSceneKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClickSceneKey');
  let clickSceneValue = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClickSceneValue');
  let clickKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClickKey');
  let clickValue = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClickValue');
  let documentKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'DocumentKey');
  let enableCondition = ownProps.widget.Parameters.find(x => x.ParameterName === 'EnableCondition');
  let icon = ownProps.widget.Parameters.find(x => x.ParameterName === 'Icon');
  let modalCloseButton = ownProps.widget.Parameters.find(x => x.ParameterName === 'ModalCloseButton');
  let clickColumnExpand = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClickColumnExpand');
  let scriptRunner = ownProps.widget.Parameters.find(x => x.ParameterName === 'ScriptRunner');

  //some evals use this, declare _helpers so bundler knows we need it
  let _helpers = helpers;
  let _actions = actions;

  let enabled = true;
  if (enableCondition && enableCondition.ParameterValue) {
    try {
      enabled = eval(enableCondition.ParameterValue);
    } catch (e) {
      console.error('error in EnableCondition: ' + enableCondition.ParameterValue)
    }
  }

  let text = '';
  if (staticText) {
    text = staticText.ParameterValue;
  }

  if (source) {
    try {
      text = eval(source.ParameterValue);
    } catch (e) {
      console.log('eval failed for: ' + source.ParameterValue);
      console.log(e);
    }
  }

  let cVal = clickValue ? clickValue.ParameterValue : undefined;
  if (cVal && cVal.indexOf('state.') === 0) {
    cVal = eval(cVal);
  }

  let documentUrl = null;

  if (documentKey && state[documentKey.ParameterValue]) {
    if (typeof state[documentKey.ParameterValue] === 'object') {
      documentUrl = state[documentKey.ParameterValue].Value;
    } else {
      documentUrl = state[documentKey.ParameterValue];
    }
  }

  let docContent = null
  if (documentUrl && state._DocumentContent && state._DocumentContent[documentUrl]) {
    docContent = state._DocumentContent[documentUrl];
  }

  let colExpand = null;
  let scenes = null;
  let myScene = null;
  if (clickColumnExpand) {
    try {
      colExpand = JSON.parse(clickColumnExpand.ParameterValue)
      scenes = state.blu_Scene;
      myScene = state.blu_Scene.find(x => x.Name === ownProps.widget.SceneName);
    } catch (e) { }
  }

  return {
    Text: text,
    ClickSceneKey: clickSceneKey ? clickSceneKey.ParameterValue : undefined,
    ClickSceneValue: clickSceneValue ? clickSceneValue.ParameterValue : undefined,
    ClickKey: clickKey ? clickKey.ParameterValue : undefined,
    ClickValue: cVal,
    Icon: icon ? icons[icon.ParameterValue] : undefined,
    ModalCloseButton: modalCloseButton ? helpers.stringToBool(modalCloseButton.ParameterValue) : false,
    DocumentUrl: documentUrl,
    DocumentContent: state._DocumentContent || {},
    BlobSAS: state.BlobSAS,
    DocContent: docContent,
    ClickColumnExpand: colExpand,
    Scenes: scenes,
    MyScene: myScene,
    OwnProps: ownProps,
    Enabled: enabled,
    ScriptRunner: scriptRunner ? scriptRunner.ParameterValue : null,
  };
};

export class Text extends React.PureComponent {
  componentDidMount() {
    this.DocumentContentCheck();
  }

  componentDidUpdate() {
    this.DocumentContentCheck();
  }

  DocumentContentCheck = () => {
    if (this.props.DocumentUrl && !this.props.DocumentContent[this.props.DocumentUrl]) {
      this.GetDocumentContent();
    }
  }

  getBlobContainerClient = () => {
    let blobServiceClient = new Azure.BlobServiceClient(this.props.BlobSAS.Key);
    let containerName = this.props.BlobSAS.Database.replace(/_/g, '').toLowerCase();
    return blobServiceClient.getContainerClient(containerName);
  }

  GetDocumentContent = async () => {
    let containerClient = this.getBlobContainerClient();
    let blobClient = containerClient.getBlobClient(this.props.DocumentUrl);

    const downloadBlockBlobResponse = await blobClient.download();
    const blobSrc = await downloadBlockBlobResponse.blobBody;

    this.props.dispatch(actions.UpdateProp({
      Key: '_DocumentContent',
      Value: {
        ...this.props.DocumentContent,
        [this.props.DocumentUrl]: window.URL.createObjectURL(blobSrc)
      }
    }));

    // actions.ApiGet('betty/' + this.props.DocumentUrl, this.props.DocumentUrl, (result) => {
    //   if (!result) {
    //     if (!retry) {
    //       setTimeout(() => { this.GetDocumentContent(true); }, 500);
    //     } else {
    //       return;
    //     }
    //   }

    //   result.text().then(txt => {
    //     this.props.dispatch(actions.UpdateProp({
    //       Key: '_DocumentContent',
    //       Value: {
    //         ...this.props.DocumentContent,
    //         [this.props.DocumentUrl]: txt
    //       }
    //     }))
    //   })
    // });
  }

  onClick = () => {
    if(!this.props.Enabled){
      return;
    }

    if (this.props.ModalCloseButton) {
      this.closeModal()
      return;
    }
    if (this.props.ScriptRunner) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.ScriptRunner,
        Value: true
      }));
    }

    if (this.props.ClickSceneKey && this.props.ClickSceneValue) {
      this.updateScene();
    } else if (this.props.ClickKey && this.props.ClickValue) {
      this.parseClick();
    }
    if (this.props.ClickColumnExpand) {
      this.columnExpand();
    }
  }

  columnExpand = () => {
    let expand = this.props.ClickColumnExpand;
    console.log(this.props.OwnProps);

    let myScene = this.props.Scenes.find(x => x.Name === this.props.MyScene.Name);

    let cols = myScene.Columns.split(' ');
    cols[expand.column - 1] = cols[expand.column - 1] === expand.width ? '0px' : expand.width

    myScene.Columns = cols.join(' ');
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_Scene',
      Value: cloneDeep(this.props.Scenes)
    }));
  }

  parseClick = () => {
    let val = null;
    try {
      try {
        val = JSON.parse(this.props.ClickValue);
      } catch {
        val = this.props.ClickValue;
      }

      if (Array.isArray(val) && this.props.ClickKey.includes(',')) {
        this.updateMultiple(val);
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.ClickKey,
          Value: val
        }));
      }

    } catch (e) {
      console.log(e);
    }
  }

  updateMultiple = (updateVals) => {
    let keys = this.props.ClickKey.split(',').map(x => x.trim());
    for (let i = 0; i < updateVals.length; i++) {
      this.props.dispatch(actions.UpdateProp({
        Key: keys[i],
        Value: updateVals[i]
      }));
    }
  }

  updateScene = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ClickSceneKey,
      Value: {
        Enabled: true,
        Value: this.props.ClickSceneValue
      }
    }));
  }

  closeModal = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_TryCloseEvent',
      Value: true
    }));
    return;
  }

  render() {
    let Icon = this.props.Icon;
    return (
      <div className={`text-widget${this.props.Enabled ? '' : ' disabled'}`} onClick={this.onClick}>
        {!this.props.DocContent && this.props.Icon && <div><Icon /></div>}
        {!this.props.DocContent && !this.props.Icon && <div>{this.props.Text ? parser(this.props.Text) : ''}</div>}
        {this.props.DocContent &&
          <object data={this.props.DocContent} type="application/pdf">
            <div>No online PDF viewer installed</div>
          </object>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(Text);