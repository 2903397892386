import { BingMaps } from 'ol/source';
import { Tile as LayerTile } from 'ol/layer'

const bingMapsLayer = (bingKey, imagerySet) => {
  return new LayerTile({
    source: new BingMaps({
      key: bingKey,
      imagerySet: imagerySet
    })
  });
}
// Aerial     
// AerialWithLabelsOnDemand
// RoadOnDemand       
// CanvasDark      
// OrdnanceSurvey  

export default {
  bingMapsLayer: bingMapsLayer
}
