
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { batch } from 'react-redux';

export const saveLayout = (userId, layout, queryId, settings, callback) => {

  if (!layout.Id && !layout.InsertKey) {
    layout.InsertKey = layout.InsertKey || helpers.getInsertKey();
  }

  let baseObj = {
    Id: layout.Id || null,
    InsertKey: layout.InsertKey || null,
    Table: 'qry.UserLayout',
    IsBaseTable: true
  }

  let saveData = [
    helpers.saveDataItem(baseObj, 'QueryId', queryId),
    helpers.saveDataItem(baseObj, 'UserId', userId),
    helpers.saveDataItem(baseObj, 'Name', settings.Name),
    helpers.saveDataItem(baseObj, 'Note', settings.Note),
    helpers.saveDataItem(baseObj, 'IsPublic', settings.IsPublic),
    helpers.saveDataItem(baseObj, 'IsDefault', settings.IsDefault),
    helpers.saveDataItem(baseObj, 'IsDeleted', settings.IsDeleted || false),
    helpers.saveDataItem(baseObj, 'LayoutJSON', JSON.stringify(layout.Layout)),
    helpers.saveDataItem(baseObj, 'ShareWith', settings.ShareWith)
  ];

  actions.ApiRequest('Save/Save', { SaveData: saveData }, () => {
    if (callback) {
      setTimeout(() => {
        callback();
      }, 750);
    }
  });
}
