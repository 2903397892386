import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {
    CurrentEntity: state.ent_Current,
    EntitySelected: state.ent_EventMoveSelected,
    CurrentEvent: state.ent_CurrentEvent,
    SaveId: state.dbo_SaveId
  };
};

export class MoveEvent extends React.PureComponent {
  EventTable = 'xtn.EAsset';

  componentDidMount() {
    if (this.waiting)
      return;

    // Update AssetId column in xtn.EAsset with the asset id stored in ent_EventMoveSelected

    let newSelected = {
      EntityId: this.props.EntitySelected.EntityId,
      EntityTypeId: this.props.EntitySelected.EntityTypeId
    };

    let baseObj = {
      Id: this.props.CurrentEvent.EntityId,
      InsertKey: null,
      Table: this.EventTable,
      IsEventData: true,
      IsBaseTable: true
    }

    let saveData = [
      helpers.saveDataItem(baseObj, 'AssetId', this.props.EntitySelected.EntityId),
      helpers.saveDataItem(baseObj, 'AssetClassId', this.props.EntitySelected.EntityTypeId)
    ];

    this.waiting = true;
    actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
      this.waiting = false;
      this.props.dispatch(actions.ClearSaveData(this.props.SaveDataKey));
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_Selected',
        Value: newSelected
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MoveEvent);