import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class TimeBankCards extends React.PureComponent {
  render() {
    return (
      <div className="time-bank-cards">
        <div>Time Banks</div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(TimeBankCards);