import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../Stores/Actions/actions';
import WidgetLoader from './WidgetLoader';
import { selector } from '../Stores/Reducers/reducer';
import PersistedWidgets from './PersistedWidgets';
import { LandingSpinner } from './Icons/Icons';
import DebugInfo from './Engines/DebugInfo/DebugInfo';
import Keybinds from './Engines/Keybinds/v001/Keybinds';
import SignalR from './Engines/SignalR/v001/SignalR';
import * as helpers from './../Utils/Helpers';
import Scripts from './Engines/ScriptRunner/v001/ScriptMap';
import ScriptRunner from './Engines/ScriptRunner/v001/ScriptRunner';
import HubSubscriptions from './Engines/HubSubscriptions/HubSubscriptions';

const mapStateToProps = state => ({
  ActiveWidgets: selector.ActiveWidgets(state),
  ActiveScene: selector.ActiveScene(state),
  EditMode: state.EditMode,
  FindWidget: state.FindWidget,
  EntityMetadata: state.met_EntityMetadata,
  ShowDebug: state.sys_showDebugInfo,
  ApplicationId: state.blu_ApplicationId,
  FullEditMode: state.FullEditMode,
  HaltSiteInit: state.HaltSiteInit
});

export class SceneLayout extends React.PureComponent {
  state = {
    siteInit: false
  };
  constructor(props) {
    super(props);
    if (this.props.token) {
      actions.setToken(this.props.token);
    }
    this.getScene();
    this.loadCss();
    helpers.siteZoomListenerStart();
    helpers.resizeListenerStart();
    window._dispatch = this.props.dispatch;
  }

  getScene = () => {
    this.props.dispatch(actions.GetScene());
  }

  formatRowCols = (val) => {
    let result = val;
    if (!isNaN(Number(result)) && Number(result).toString() === result.toString()) {
      result = 'repeat(' + result + ', 1fr)';
    }

    return result;
  }

  resizeWidget = (widget, delta) => {
    try {
      let oldSceneCol = this.props.ActiveScene.Columns;
      let widgetCol = Number(widget.Columns);
      if (widgetCol && (widgetCol + '') === widget.Columns) {
        let sceneColParts = oldSceneCol.split(' ');
        let toReplace = sceneColParts[widgetCol - 1];
        let curNumeric = Number(toReplace.replace('px', ''));
        let newVal = (curNumeric + delta) + 'px';

        sceneColParts[widgetCol - 1] = newVal;
        let finalVal = sceneColParts.join(' ');
        this.props.ActiveScene.Columns = finalVal;
        this.forceUpdate();
      }
    } catch (e) {
      console.error('Resizable only works for static pixel columns.  Repeats or minmax in the container scene\'s column config might also mess it up.')
    }
  }

  loadCss = async () => {
    let pieces = window.location.href.split('=');
    if (pieces.length > 1) {
      let argPieces = pieces[1].split('&');
      if(argPieces[0].toLowerCase() === 'am' && window.location.href.toLowerCase().includes("app.elarawater.com")) {
        try {
          import("./../Styles/ub.css").then(x => { });
        } catch { }
      } else {
        try {
          import("./../Styles/" + argPieces[0].toLowerCase() + ".css").then(x => { });
        } catch { }
      }
    }
  }

  render() {
    if (this.props.ApplicationId && this.state.siteInit === false) {
      helpers.siteInit(this.props.ApplicationId);
      this.setState({ siteInit: true });
    }
    let activeScene = this.props.ActiveScene;
    let rows = activeScene ? this.formatRowCols(activeScene.Rows) : null;
    let cols = activeScene ? this.formatRowCols(activeScene.Columns) : null;

    return (
      <div className={"site-scene" + (this.props.FullEditMode ? ' full-edit-mode' : '') + (this.props.EditMode ? ' edit-mode' : '') + (this.props.FindWidget ? ' find-widget' : '')}>
        <div id="loading-spinner" className={(!this.props.EntityMetadata || Object.keys(this.props.EntityMetadata).length === 0 || this.props.HaltSiteInit) ? '' : ' load-complete'}>
          <div className="spin-svg">
            <div className="loading-label"><div>Loading</div></div>
            <div className=" landing-spin"><LandingSpinner /></div>
          </div>
        </div>
        {activeScene &&
          <div className="grid" style={{ gridTemplateRows: rows, gridTemplateColumns: cols }}>
            <PersistedWidgets />
            {this.props.ActiveWidgets.map((item) => (
              <WidgetLoader key={item.SceneWidgetId} widget={item} resizeWidget={this.resizeWidget} />
            ))}
          </div>}
        {
          this.props.ShowDebug && <DebugInfo />
        }
        {
          Scripts.AutoListen.filter(x => x.ApplicationId === this.props.ApplicationId).map((x, idx) => (
            <ScriptRunner key={idx} widget={{
              Parameters: [{
                ParameterName: 'Type',
                ParameterValue: x.Type
              },
              {
                ParameterName: 'TriggerKey',
                ParameterValue: x.TriggerKey
              }]
            }} />
          ))
        }
        <Keybinds />
        <SignalR />
        <HubSubscriptions />
      </div>
    );
  }
}

export default connect(mapStateToProps)(SceneLayout);