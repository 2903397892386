
import React from 'react';
import ReactDOM from 'react-dom';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';


export const doEventSpray = (actions, agRequest, saveData, typeId) => {
  saveData.forEach((x) => {
    if (x.Column === 'AssetId') {
      x.Value = 'REPLACED_FROM_QUERY';
    }
    if (x.Column === 'AssetClassId') {
      x.Value = typeId;
    }
  });

  let body = {
    SaveData: JSON.stringify(saveData),
    GridRequest: agRequest
  }

  actions.ApiRequest('DataQueue/EventSpray', body, () => { });
}