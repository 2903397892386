import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import CardItem from './CardItem';
import PayPeriodTotals from './CardTypes/PayPeriodTotals';
import IconTab from './CardTypes/IconTab';
import NewLine from './CardTypes/NewLine';
import UB_AcctAging from './CardTypes/UB_AcctAging';
import UB_AcctStatus from './CardTypes/UB_AcctStatus';
import HTML from './CardTypes/HTML';
import FourTextCard from './CardTypes/FourTextCard';

const mapStateToProps = (state, ownProps) => {
  let sourceParam = ownProps.widget.Parameters.find(x => x.ParameterName === 'SourceParameter');
  let itemsKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ListItemsKey') || { ParameterValue: '_cardList' + ownProps.widget.SceneWidgetId };
  let computedKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ComputedKey');
  let selectedCard = ownProps.widget.Parameters.find(x => x.ParameterName === 'SelectedCard');
  let saveRefresh = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshOnSave');
  let loadRefresh = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshOnLoad');
  let silentRefresh = ownProps.widget.Parameters.find(x => x.ParameterName === 'SilentRefresh');
  let loadFirstItem = ownProps.widget.Parameters.find(x => x.ParameterName === 'LoadFirstItem');
  let clickableParent = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClickableParent');
  let cardType = ownProps.widget.Parameters.find(x => x.ParameterName === 'CardType');
  let resetSelectedOnLoad = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetSelectedOnLoad');
  let preventSelectedRefresh = ownProps.widget.Parameters.find(x => x.ParameterName === 'PreventSelectedRefresh');
  let sproc = ownProps.widget.Parameters.find(x => x.ParameterName === 'Sproc');
  let pivotList = ownProps.widget.Parameters.find(x => x.ParameterName === 'PivotList');
  let noResultsMessage = ownProps.widget.Parameters.find(x => x.ParameterName === 'NoResultsMessage');
  let loadSingleResult = ownProps.widget.Parameters.find(x => x.ParameterName === 'LoadSingleResult');
  let isEventTabList = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsEventTabList');
  let preventReselect = ownProps.widget.Parameters.find(x => x.ParameterName === 'PreventReselect');
  let persistedLegend = ownProps.widget.Parameters.find(x => x.ParameterName === 'PersistedLegend');

  const clickableLegendParent = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClickableLegendParent');

  const createAsset = ownProps.widget.Parameters.find(x => x.ParameterName === "CreateAsset");
  const mobileCreateAsset = ownProps.widget.Parameters.find(x => x.ParameterName === 'MobileCreateAsset');

  const refreshMobileContentPane = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshMobileContentPane');

  if (!sourceParam && itemsKey && state[itemsKey.ParameterValue] === undefined) {
    state[itemsKey.ParameterValue] = 'autoload';
  }

  let computedKeyResult;
  if (computedKey) {
    try {
      computedKeyResult = eval(computedKey.ParameterValue);
    } catch (e) {
      console.log('Cardlist computed key eval error:');
      console.log(e);
    }
  }

  let multiSource = null;
  if (sourceParam && sourceParam.ParameterValue.includes(',')) {
    multiSource = sourceParam.ParameterValue.split(',');
  }

  return {
    SourceParameter: sourceParam ? (multiSource ? state[multiSource[0]] : state[sourceParam.ParameterValue]) : undefined,
    AltParameter: multiSource ? state[multiSource[1]] : undefined,
    AutoLoad: !sourceParam,
    Items: itemsKey ? state[itemsKey.ParameterValue] || [] : [],
    ItemsKey: itemsKey ? itemsKey.ParameterValue : undefined,
    ComputedKey: computedKey ? computedKeyResult : undefined,
    SelectedCard: selectedCard ? state[selectedCard.ParameterValue] : undefined,
    SelectedCardKey: selectedCard ? selectedCard.ParameterValue : '',
    RefreshOnSave: saveRefresh ? helpers.stringToBool(saveRefresh.ParameterValue) : false,
    RefreshOnLoad: loadRefresh ? helpers.stringToBool(loadRefresh.ParameterValue) : false,
    SilentRefresh: silentRefresh ? helpers.stringToBool(silentRefresh.ParameterValue) : false,
    SaveId: state.dbo_SaveId,
    LoadFirstItem: loadFirstItem ? helpers.stringToBool(loadFirstItem.ParameterValue) : false,
    ClickableParent: clickableParent ? helpers.stringToBool(clickableParent.ParameterValue) : false,
    CardType: cardType ? cardType.ParameterValue : undefined,
    ResetSelectedOnLoad: resetSelectedOnLoad ? helpers.stringToBool(resetSelectedOnLoad.ParameterValue) : false,
    PreventSelectedRefresh: preventSelectedRefresh ? helpers.stringToBool(preventSelectedRefresh.ParameterValue) : false,
    ApplicationId: state.blu_ApplicationId,
    IncludeAppId: sproc ? sproc.ParameterValue === 'lst.GetTabList' : false,
    PivotList: pivotList ? helpers.stringToBool(pivotList.ParameterValue) : false,
    NoResultsMessage: noResultsMessage ? noResultsMessage.ParameterValue : undefined,
    LoadSingleResult: loadSingleResult ? helpers.stringToBool(loadSingleResult.ParameterValue) : false,
    IsEventTabList: isEventTabList ? helpers.stringToBool(isEventTabList.ParameterValue) : false,
    PreventReselect: preventReselect ? helpers.stringToBool(preventReselect.ParameterValue) : false,
    CurrentEntity: state.ent_Current,
    CurrentEvent: state.ent_CurrentEvent,
    SWID: ownProps.widget.SceneWidgetId,
    PersistedLegend: persistedLegend ? helpers.stringToBool(persistedLegend.ParameterValue) : false,
    RefreshMobileContentPane: refreshMobileContentPane ? helpers.stringToBool(refreshMobileContentPane.ParameterValue) : false,
    ClickableLegendParent: clickableLegendParent ? helpers.stringToBool(clickableLegendParent.ParameterValue) : false,
    CreateAsset: createAsset ? helpers.stringToBool(createAsset.ParameterValue) : false,
    MobileCreateAsset: mobileCreateAsset ? helpers.stringToBool(mobileCreateAsset.ParameterValue) : false,
  };
};

let SaveIdOnLoad = {};
let _initPersistedLegend = false;

export class CardList extends React.PureComponent {
  state = {
    params: helpers.paramsToObject(this.props.widget),
    showNoResults: false
  }

  constructor(props) {
    super(props);

    if (this.props.IncludeAppId && !this.props.ApplicationId)
      return;

    if (this.props.Items === 'autoload') {
      this.getCardList();
      return;
    }
    if (this.props.ComputedKey) {
      let needsRefresh = this.props.Items.length === 0 || helpers.stringToBool(this.state.params.ForceRefresh);
      if (needsRefresh) {
        this.getCardList(true);
        return;
      }
    }

    if (this.props.RefreshOnSave && SaveIdOnLoad[this.props.widget.SceneWidgetId] !== this.props.SaveId) {
      this.getCardList(true);
    } else if (this.props.RefreshOnLoad) {
      this.getCardList(true);
    }

    if (this.props.ResetSelectedOnLoad) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.SelectedCardKey,
        Value: undefined
      }));
    }

    if (this.props.IsEventTabList && this.props.Items) {
      this.onClick(this.props.Items[0]);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.IncludeAppId && !this.props.ApplicationId)
      return;

    if (!this.props.AutoLoad && prevProps.SourceParameter !== this.props.SourceParameter) {
      this.getCardList();
    }

    if (!this.props.AutoLoad && prevProps.AltParameter !== this.props.AltParameter) {
      this.getCardList(false, this.props.AltParameter);
    }

    if (this.props.RefreshOnSave && this.props.SaveId !== prevProps.SaveId) {
      setTimeout(() => {
        this.getCardList(true);
      }, 0);
    }

    if (!this.props.SelectedCard && prevProps.SelectedCard && !this.props.PreventSelectedRefresh) {
      this.getCardList(true);
    }

    if (this.props.PersistedLegend && !_initPersistedLegend && this.props.Items) {
      this.usePersistedLegend();
      _initPersistedLegend = true;
    }
  }

  getCardList = (reset = false, searchOverride = '') => {

    SaveIdOnLoad[this.props.widget.SceneWidgetId] = this.props.SaveId;

    let body = {
      SearchText: this.props.ComputedKey || searchOverride || this.props.SourceParameter,
      SceneWidgetId: this.props.widget.SceneWidgetId,
      FormWidgetId: this.props.widget.FormWidgetId,
      ApplicationId: this.props.IncludeAppId ? this.props.ApplicationId : null
    }

    if (reset && !this.props.SilentRefresh) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.ItemsKey,
        Value: []
      }));
    }

    this.setState({ showNoResults: false });

    actions.ApiRequest('List/GetListResults', body, (result) => {
      if (Array.isArray(result)) {
        let listItems = this.parseItems(result);
        if (this.props.LoadSingleResult && listItems.length === 1) {
          this.props.dispatch(actions.UpdateProp({
            Key: this.props.SelectedCardKey,
            Value: listItems[0]
          }));
        }
        if (this.props.LoadFirstItem && listItems && listItems.length > 0) {
          this.onClick(listItems[0]);
        } else if (listItems && (listItems.length === 0)) {
          this.setState({ showNoResults: true });
        }
        if (this.props.PersistedLegend && !_initPersistedLegend && listItems) {
          this.usePersistedLegend(listItems);
          _initPersistedLegend = true;
          return;
        }
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.ItemsKey,
          Value: listItems
        }));
      }
    });
  }

  usePersistedLegend = (items) => {
    if (!localStorage.getItem('PersistedLegend')) {
      return;
    }
    //update list items with localstorage values
    let persistedLegend = JSON.parse(localStorage.getItem('PersistedLegend'));
    let listItems = items || this.props.Items;

    listItems['allItems'].forEach(x => {
      if (persistedLegend.hasOwnProperty(x.Id)) {
        x.Active = persistedLegend[x.Id];
      }
    })

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ItemsKey,
      Value: listItems
    }));
  }

  onClick = (card) => {
    if (this.props.PreventReselect && card && card.Id && this.props.SelectedCard && card.Id === this.props.SelectedCard.Id)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: card.GobKey || this.state.params.SelectedCard,
      Value: card
    }));

    if (card && card.ResultType && card.Id && card.ResultType === 'LoadEventFromId') {
      this.loadEventFromId(card.Id);
    }

    this.checkUpdateParameters();
    this.checkAdditionalCards(card);

    if(this.props.CreateAsset) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene',
        Value: {Value: 'AdvancedMap', Enabled: true}
      }))
    }

    if(this.props.MobileCreateAsset) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'MobileContentPane',
        Value: { "Enabled": true, "Value": "MobileAsset", "Id": 1055, "Label": "Asset" }
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'MobileAssetContent',
        Value: { "Enabled": true, "Value": "MobileAssetDetails", "Label": "Details", "Id": 1345 }
      }));

      setTimeout(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'MobileAssetContent',
          Value: { "Enabled": true, "Value": "MobileAssetGeo", "Label": "Geo", "Id": 1370 }
        }));
      }, 200);
    }
  }

  loadEventFromId = (id) => {
    let parts = id.split('-');
    if (parts && parts.length === 2) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_SelectedEvent',
        Value: {
          EntityTypeId: Number(parts[0]),
          EntityId: Number(parts[1])
        }
      }))

      if (this.props.RefreshMobileContentPane) {
        this.props.dispatch(actions.UpdateProp({
          Key: "MobileContentPane",
          Value: {
            Label: "Asset",
            Value: "MobileAsset",
            Enabled: true
          }
        }));
      }
    }
  }

  checkUpdateParameters = () => {
    let updateParams = this.props.widget.Parameters.filter(x => x.Category === 'GobUpdateOnClick');
    updateParams.forEach(param => {
      this.props.dispatch(actions.UpdateProp({
        Key: param.ParameterName,
        Value: this.parseValue(param.ParameterValue)
      }));
    })
  }

  parseValue = (val) => {
    try {
      let response = JSON.parse(val);
      return response;
    } catch {
      return val;
    }
  }

  checkAdditionalCards = (card) => {
    if (!card.AdditionalCards)
      return;

    try {
      let cards = Array.isArray(card.AdditionalCards) ? card.AdditionalCards : JSON.parse(card.AdditionalCards);

      cards.forEach(c => {
        let val = {
          Key: c.CardKey,
          Value: c
        }
        if (c.JsonValue) {
          val.Value = JSON.parse(c.JsonValue);
        }
        this.props.dispatch(actions.UpdateProp(val));
      });
    } catch { }
  }

  parseItems = (items) => {
    if (!items || !Array.isArray(items))
      return [];

    let itemMap = items.reduce((prev, next) => {
      prev[next.Id] = next;
      return prev;
    }, {});

    let children = items.filter(x => x.ParentId !== null && x.ParentId !== undefined).reduce((prev, next) => {
      prev[next.ParentId] = [...(prev[next.ParentId] || []), next];
      return prev;
    }, {});

    let result = items.map(x => {
      x.IsParent = children[x.Id] ? true : false;
      x.Children = children[x.Id] || [];
      x.TopLevel = (x.ParentId === null || x.ParentId === undefined);
      x.Expand = x.ExpandByDefault ? true : false;
      if (x.ParentId !== null && x.ParentId !== undefined) {
        x.Parent = itemMap[x.ParentId];
      }
      return x;
    });
    let parents = result.filter(x => x.TopLevel);
    parents.allItems = result;
    return parents;
  }

  refreshItems = (multiselect) => {
    this.setState({ state: this.state });

    if (multiselect) {
      let newVal = [...this.props.Items];
      newVal.allItems = this.props.Items.allItems;
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.ItemsKey,
        Value: newVal
      }));
    }
  }

  panToCard = (e, card) => {
    if (!card.IconClickToPan)
      return;

    e.stopPropagation();
    this.props.dispatch(actions.UpdateProp({
      Key: 'map_PanToCard',
      Value: card
    }));
  }

  render() {
    let cards = {
      PayPeriodTotals: PayPeriodTotals,
      IconTab: IconTab,
      NewLine: NewLine,
      UB_AcctAging: UB_AcctAging,
      UB_AcctStatus: UB_AcctStatus,
      HTML: HTML,
      FourTextCard: FourTextCard
    }
    return (
      <div className={"card-list" + (this.props.PivotList ? " pivot-list" : "")}>
        {
          (this.props.NoResultsMessage && this.state.showNoResults) && <div class="no-results-cardlist">{this.props.NoResultsMessage}</div>
        }
        {Array.isArray(this.props.Items) && this.props.Items.filter(x => x.TopLevel === undefined || x.TopLevel).map((x, idx) => {
          let Card = cards[x.CardType] || cards[this.props.CardType];
          if (Card) {
            return (
              <Card key={idx}
                card={x}
                parentClick={this.onClick}
                refresh={this.refreshItems}
                selected={this.props.SelectedCard}
                multiselect={helpers.stringToBool(this.state.params.Multiselect)}
                persistedLegend={this.props.PersistedLegend}
                panToCard={this.panToCard} />
            )
          } else {
            return (
              <CardItem key={idx}
                card={x}
                parentClick={this.onClick}
                refresh={this.refreshItems}
                selected={this.props.SelectedCard}
                level={0}
                multiselect={helpers.stringToBool(this.state.params.Multiselect)}
                persistedLegend={this.props.PersistedLegend}
                panToCard={this.panToCard}
                clickableParent={this.props.ClickableParent}
                curEntity={this.props.CurrentEntity}
                curEvent={this.props.CurrentEvent}
                SWID={this.props.SWID}
                ClickableLegendParent={this.props.ClickableLegendParent}
              />   
            )
          }
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps)(CardList);