import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import PhotoUploader from './../PhotoUploader/PhotoUploader';

const mapStateToProps = (state, ownProps) => {
  return {}
}

export class NumberBox extends React.PureComponent {
  render() {
    return (
      <PhotoUploader {...this.props} IsPublic={true} />
    );
  }
}

export default connect(mapStateToProps)(NumberBox);