import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from './../../../../Stores/Actions/actions';
import * as helpers from './../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CreditGrid: state._grid3103,
    DebitGrid: state._grid3102,
    CreditLoading: state._grid3103Loading,
    DebitLoading: state._grid3102Loading,
    SelectedEvent: state.ent_SelectedEvent,
    CurrentEvent: state.ent_CurrentEvent,
    Modal: state.blu_ModalScene,
    SaveId: state.dbo_BeforeSaveId
  };
};

export class UBRegisterDials extends React.PureComponent {

  state = {
    LoadWatch: false,
    LoadInitCredit: false,
    LoadInitDebit: false,
    WatchForClose: false
  }

  componentDidMount() {
  }

  componentDidUpdate(prev) {
    if (prev && (this.props.SaveId !== prev.SaveId)) {
      this.setState({ LoadWatch: true, LoadInitCredit: false, LoadInitDebit: false });
    }

    if (prev && !prev.SelectedEvent && this.props.SelectedEvent && this.props.SelectedEvent.EntityTypeId === 2023001 && !this.state.LoadWatch) {
      this.setState({ LoadWatch: true });
      this.setBusy(true);
    }

    if (this.state.LoadWatch && !this.state.LoadInitCredit && this.props.CreditLoading) {
      this.setState({ LoadInitCredit: true });
    }
    if (this.state.LoadWatch && !this.state.LoadInitDebit && this.props.DebitLoading) {
      this.setState({ LoadInitDebit: true });
    }

    if (this.state.WatchForClose && !this.props.Modal) {
      this.setState({ WatchForClose: false });
      this.resetReadonly();
    }

    if (this.state.LoadWatch && this.state.LoadInitDebit && this.state.LoadInitCredit && this.props.CreditLoading === false && this.props.DebitLoading === false && this.props.CurrentEvent && this.props.CurrentEvent.EntityId === this.props.SelectedEvent.EntityId) {
      this.setState({ WatchForClose: true });
      setTimeout(() => {
        this.doROCheck();
        this.updateBalance();
        this.resetWatch();
        setTimeout(() => {
          this.setBusy(false);
        }, 0);
      }, 0);
    }
  }

  setBusy = (isBusy) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ub_JournalManagerBusy',
      Value: isBusy
    }));
  }

  updateBalance = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'UB_UpdateJournalBalance',
      Value: true
    }));
  }

  doROCheck = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'sr_JournalReadOnlyCheck',
      Value: true
    }));
  }

  resetWatch = () => {
    this.setState({
      LoadInitCredit: false,
      LoadInitDebit: false,
      LoadWatch: false
    });
  }

  resetReadonly = () => {
    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: '_grid3103RO',
        Value: false
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: '_grid3102RO',
        Value: false
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UBRegisterDials);

