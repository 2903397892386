import React from 'react';
import { connect } from 'react-redux';
import Honeycomb from "@beehive-development/honeycomb";

const mapStateToProps = (state, ownProps) => {
  return {
    selectedPermissionGroup: state.ent_SelectedPermissionGroup ? state.ent_SelectedPermissionGroup : undefined,
    selectedPermissionEntity: state.ent_SelectedPermissionEntity ? state.ent_SelectedPermissionEntity : undefined,
    grid: state._grid3977 ? state._grid3977 : undefined,
    databaseName: state.BlobSAS.Database
  };
};

export class UB_Admin_GroupSave extends React.PureComponent {

  componentDidMount = async () => {
    const {selectedPermissionEntity,selectedPermissionGroup,grid,databaseName} = this.props;
    if(selectedPermissionEntity && selectedPermissionGroup && grid) {
      const groupPermId = selectedPermissionGroup.EntityId;
      const entityTypeId = selectedPermissionEntity.EntityTypeId;
      const perms = {
        create:grid.Rows.find(x => x.Permission === "Create").IsEnabled.Value,
        read:grid.Rows.find(x => x.Permission === "Read").IsEnabled.Value,
        update:grid.Rows.find(x => x.Permission === "Update").IsEnabled.Value,
        delete:grid.Rows.find(x => x.Permission === "Delete").IsEnabled.Value,
      }

      const body = {
        databaseName: databaseName,
        GroupPermId: groupPermId,
        EntityTypeId: entityTypeId,
        Perms: perms
      }
      const honeycomb = new Honeycomb({
        requestHeaders: {
            "href-origin": window.location.href,
        }
      });
      
      await honeycomb.mySalesman.setGroupPermissions(body).then(response => response.json());
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_Admin_GroupSave);