import React from 'react';
import { connect, batch } from 'react-redux';
import { Trashcan } from '../../../../Icons/Icons';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state) => {
  return {
    UserLoc: state.UserLocation,
    StartDrawing: state.ms_StartDrawing,
    DrawingActive: state.ms_DrawingActive,
    ModifyActive: state.ms_ModifyActive,
    SelectedPolygons: state.ms_SelectedPolygons,
    DeleteFeature: state.ms_DeleteFeature,
    CurrentMeasure: state.ms_currentMeasure,
    ActiveModifyId: state.ms_ActiveModifyId,
    ModalActive: state.ms_ModalActive
  };
}

export class MySalesSelectedGeo extends React.PureComponent {
  state = {
    deletionModal: false
  }

  deleteFeature = () => {
    this.setState({ deletionModal: false });
    let displayedValue = this.props.card.Measurement ? this.props.card.Measurement.toLocaleString("en-US") : 'NONE';
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_DeleteFeature',
      Value: { openlayersId: this.props.card.ol_uid }
    }));
  }

  changeRemoval = () => {
    if(this.props.card.Type === 'fence_Removal') {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_EditFenceRemoval',
        Value: this.props.card.ol_uid
      }));
    } else if(this.props.card.Type === 'deck_Railing_Stain') {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_EditRailingStain',
        Value: this.props.card.ol_uid
      }))
    }
  }

  featureModifySwitch = () => {
    if (this.props.ModifyActive || this.props.DrawingActive)
      return;

    // if (this.props.card.Type === 'Gate') {
    //   this.props.dispatch(actions.UpdateProp({
    //     Key: 'ms_ModifyActive',
    //     Value: { Id: 'Gate', Active: true }
    //   }));
    // } else {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_OriginalModifyId',
      Value: this.props.card.Id
    }))
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_currentMeasure',
      Value: this.props.card.Text2
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_ModifyActive',
      Value: { Id: this.props.card.ol_uid, Active: true }
    }));

    if (window.IsMobile) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'MainTabContent',
        Value: { Value: 'MySalesmanMapping', Enabled: true }
      }))
    }
  }

  startImageGrouping = () => {
    if (this.props.ModifyActive || this.props.DrawingActive)
      return;

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_ActiveModifyId',
        Value: this.props.card.ol_uid
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'MainTabContent',
        Value: { Value: 'MySalesmanImageGrouping', Enabled: true }
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_CurrentCatalog',
        Value: this.props.card.CatalogOptions
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_CatalogSelected',
        Value: this.props.card.CatalogSelected
      }));
    });
  }

  mouseEnter = () => {
    if (this.props.DrawingActive || this.props.ModifyActive || window.IsMobile) {
      return;
    }
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_PolygonHovered',
      Value: this.props.card
    }))
  }

  mouseLeave = () => {
    if (window.IsMobile) {
      return;
    }
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_PolygonHoverExit',
      Value: this.props.card
    }))
  }

  openDeleteModal = () => {
    this.setState({ deletionModal: true });
    setTimeout(() => {
      if (document.querySelector('.mysales-cardlist-delete-card')) {
        document.querySelector('.mysales-cardlist-delete-card .polygon-btn-neg').scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }, 100);
  }

  getClasses = () => {
    let result = '';

    if (this.props.selected && this.props.selected.Id === this.props.card.Id && this.props.card.Id !== undefined) {
      result += ' selected';
    } else if (this.props.card.Id && this.props.card.GobId && this.props.card.Id === this.props.card.GobId) {
      result += ' selected';
    }

    return result;
  }

  render() {
    return (
      <div className={"mysales-cardlist-container" + this.getClasses()} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <div className="mysales-cardlist-numbers"><h3 className="step-circle">{this.props.card.Id}</h3></div>
        <div className="mysales-cardlist-content">
          <h3>{!this.props.card.ol_uid || this.props.card.ol_uid == this.props.ActiveModifyId ? this.props.CurrentMeasure : this.props.card.Text2}</h3>
          <p className='mysales-cardlist-price'>{this.props.card.Amount}</p>
          <div style={{ position: 'relative', bottom: '22px' }}>
            <span className='grey-text'>{this.props.card.Text1}</span>
            <div className="mysales-cardlist-button-container">
              {this.props.card.Type !== 'fence_Gate' && this.props.card.Type !== 'fence_Remove' && this.props.card.Type !== 'deck_Railing_Stain' && this.props.card.Type !== 'deck_Stair' && this.props.card.Type !== 'deck_Stair_Stain' && !this.state.deletionModal && <button className={(this.props.ModifyActive || this.props.DrawingActive || this.props.ModalActive) ? "polygon-btn-disabled" : "polygon-btn-neg"} onClick={this.featureModifySwitch}>Edit Drawing</button>}
              {this.props.card.ol_uid && (this.props.card.Type === 'fence_Remove' || this.props.card.Type === 'deck_Railing_Stain') && <button className={(this.props.ModifyActive || this.props.DrawingActive || this.props.ModalActive) ? "polygon-btn-disabled" : "polygon-btn-neg"} onClick={this.changeRemoval}>Edit Amount</button>}
              {this.props.card.CatalogOptions && !this.state.deletionModal && <button className={(this.props.ModifyActive || this.props.DrawingActive || this.props.ModalActive) ? "polygon-btn-disabled" : "polygon-btn-neg"} onClick={this.startImageGrouping}>Change Style</button>}
            </div>
          </div>
        </div>
        <div style={{ width: '24px', marginLeft: '10px', marginRight: '10px' }}>
          {!this.props.ModifyActive && !this.props.DrawingActive && !this.props.ModalActive && <>
            {this.state.deletionModal ?
              <div className='mysales-cardlist-delete-card'>
                <div>Delete?</div>
                <button className='polygon-btn-neg' onClick={this.deleteFeature}>Yes, delete it</button>
                <button className='polygon-btn-pos' onClick={() => this.setState({ deletionModal: false })}>No, keep it</button>
              </div> :
              <div className='mysales-cardlist-trash' onClick={this.openDeleteModal}><Trashcan /></div>
            }
          </>
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(MySalesSelectedGeo);