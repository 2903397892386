import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import {parseJwt} from '../../../../Utils/Helpers';
import TopBar from './TopBar/TopBar';
import SideBar from './SideBar/SideBar';
import NewWorkflow from './Center/Workflow/Workflow';
import { stubFalse } from 'lodash';
import WidgetDetails from './WidgetDetails/WidgetDetails';

const mapStateToProps = (state, ownProps) => {
  return {
    Metadata: state.met_EntityMetadata,
    EditMode: state.EditMode,
    ShowWidgetDetails: state.dbo_ShowWidgetDetails
  };
};

export class Editor extends React.PureComponent {
  state = {
    showCenterEditor: false
  }

  componentDidMount() {
    if(window._getToken) {
      window._getToken().then(result => {
        let jwt = parseJwt(result)
        if(jwt["https://beehive.azure-api.net/role"] === "Support") this.createEditModeListener();
      });
    }
  }

  createEditModeListener = () => {
    document.addEventListener('keydown', (e) => {
      if (e.altKey) {
        e.preventDefault();
        this.props.dispatch(actions.UpdateProp({
          Key: 'AltPress',
          Value: true
        }));
      }
      if (e.altKey && e.keyCode === 69) { // alt + e
        e.preventDefault();
        e.stopPropagation();
        this.props.dispatch(actions.ToggleEditMode());
      }
    });
    document.addEventListener('keyup', (e) => {
      // console.log('keyup');
      if (!e.altKey) {
        e.preventDefault();
        this.props.dispatch(actions.UpdateProp({
          Key: 'AltPress',
          Value: false
        }));
      }
    });
  }

  displayCenter = (val) => {
    this.setState({ showCenterEditor: val });
  }

  render() {
    return !this.props.EditMode ? null : (
      <>
        {this.props.ShowWidgetDetails && <WidgetDetails />}
        <div className={"editor-container" + (this.state.showCenterEditor ? ' center-edit' : '')}>
          <TopBar />
          <SideBar displayCenter={this.displayCenter} />
          {this.state.showCenterEditor &&
            <div className="center-editor">
              <NewWorkflow />
            </div>}
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps)(Editor);