import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import Honeycomb from "@beehive-development/honeycomb";

const mapStateToProps = (state, ownProps) => {
  return {
    SaveId: state.dbo_SaveId,
    Control: state.Controls ? state.Controls : null,
    BlobSAS: state.BlobSAS ? state.BlobSAS : null,
    CurrentUser: state.CurrentUser ? state.CurrentUser : null,
  };
};

export class UB_BillingAction extends React.PureComponent {

  componentDidMount() {
    const control = this.props.Control;
    const blobSAS = this.props.BlobSAS;
    const currentUser = this.props.CurrentUser;
    if(control && control.SelectedBillingDate && control.SelectedBillingCycle && control.SelectedBillingCycle !== "58" && blobSAS && blobSAS.Database && currentUser && currentUser.Id) {
      this.props.dispatch(
          actions.UpdateProp({
            Key: "blu_Dialog",
            Value: {
              Title: "Create Billing Action",
              Message: (
                <>
                  <p style={{ fontSize: "1.1rem" }}>
                      Are you sure you want to add this billing action?
                  </p>
                  <div style={{ paddingLeft: "10px", marginTop: "10px" }}>
                    <div className="btn-container" style={{ flex: "0 1 140px" }}>
                      <div className="btn-bar-btn polygon-btn" onClick={() => this.confirm()}>
                          <div>
                              Add
                          </div>
                      </div>
                    </div>
                    <div className="btn-container" style={{ flex: "0 1 140px" }}>
                        <div className="btn-bar-btn polygon-btn-neg" onClick={this.cancelButton}>
                          <div>
                              Cancel
                          </div>
                        </div>
                    </div>
                  </div>
                </>
              ),
              Buttons: [],
            },
          })
      );
    }
  }

  confirm = async () => {
    const control = this.props.Control;
    const blobSAS = this.props.BlobSAS;
    const currentUser = this.props.CurrentUser;
    if(control && control.SelectedBillingDate && control.SelectedBillingCycle && control.SelectedBillingCycle !== "58" && blobSAS && blobSAS.Database && currentUser && currentUser.Id) {
      const body = {
        date: control.SelectedBillingDate,
        cycleId: control.SelectedBillingCycle,
        userId: currentUser.Id,
        databaseName: blobSAS.Database
      }

      const honeycomb = new Honeycomb({
        requestHeaders: {
          "href-origin": window.location.href,
        }
      });

      await honeycomb.mySalesman.billingEvent(body);

      this.props.dispatch(
        actions.UpdateProp({
          Key: "blu_Dialog",
          Value: null,
        })
      );
    }
  }

  cancelButton = () => {
    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: null,
      })
    );
  };


  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_BillingAction);
