import React from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import Point from 'ol/geom/Point';
import * as helpers from '../../../../../Utils/Helpers';
import { defaults as defaultControls, Control } from 'ol/control';
import { LegendIcon } from './../../../../Icons/Icons';

const mapStateToProps = (state) => {
  return {
  };
}

export class Footer extends React.PureComponent {
  latlon = false;
  zoom = false;

  state = {
    zoom: 0,
    lat: '',
    long: ''
  }

  componentDidMount() {
    let layout = this.props.map.Layout.FooterBar;
    this.zoom = layout.ZoomLevel;
    this.latlon = layout.LatLong;

    if (this.zoom) {
      this.setZoom();
      this.props.map.getView().on('change:resolution', this.setZoom);
    }

    if (this.latlon) {
      this.setLatLon();
      this.props.map.getView().on('change:center', this.setLatLon);
    }
  }

  componentWillUnmount() {
    if (this.zoom) {
      this.props.map.getView().un('change:resolution', this.setZoom);
    }

    if (this.latlon) {
      this.props.map.getView().un('change:center', this.setLatLon);
    }
  }

  setZoom = () => {
    if (this.props.map && this.props.map.getView().getZoom()) {
      let zoom = this.props.map.getView().getZoom().toFixed(1);
      this.setState({ zoom: Number(zoom) });
    }
  }

  setLatLon = () => {
    if (!this.props.map)
      return;

    let center = this.props.map.getView().getCenter();

    if (!center)
      return;

    let latLon = new Point([center[0], center[1]]).transform('EPSG:3857', 'EPSG:4326').getCoordinates();
    this.setState({ lat: 'Lat: ' + latLon[1].toFixed(4) });
    this.setState({ long: 'Long: ' + latLon[0].toFixed(4) });
  }

  render() {
    return (
      <div className="footer-bar">
        <div>{this.state.lat}</div>
        <div>{this.state.long}</div>
        <div>{'Scale: ' + this.state.zoom}</div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Footer);




