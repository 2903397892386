import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    IssueInfo: state.ent_IssueInfo,
    Location: state.ent_CenterPointGeo,
    SaveButton: state.btn_SaveIssue || { ClickId: 0, Enabled: true }
  };
};

export class E311_RequiredProps extends React.PureComponent {

  componentDidMount() {
    let keys = this.props.ParameterKeys
    let issueInfo = this.props.IssueInfo;
    let valid = true;
    let errors = 'INFORMATION NEEDED: ';
    let shareInfoErrors = '';

    if (keys.includes('location')) {
      if (!this.props.Location) {
        valid = false;
        errors += 'Must select a location. '
      }
    }

    if (keys.includes('form')) {
      if (issueInfo && issueInfo.Properties) {
        let missingReqProps = [];
        issueInfo.Properties.forEach(x => {
          if (x.IsRequired && !x.Value && x.Name !== 'AssignedToEid' && x.Name !== 'Name') { //Request ID and AssignedToEid are set in DB as Required, but are not controls on the form
            valid = false;
            x.FailedValidate = true;
            missingReqProps.push(x);
          }
        })

        missingReqProps.forEach(x => {
          shareInfoErrors += x.Label + ', ';
        })

        if (shareInfoErrors) {
          errors += shareInfoErrors.substring(0, shareInfoErrors.length - 2);
        }
      } else {
        valid = false;
        errors += ' Please fill out the "Share Info" section'
      }
    }

    if (valid) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_MasterScene',
        Value: {
          Value: 'ReportIssueComplete',
          Enabled: true
        }
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'btn_SaveIssue',
        Value: {
          Enabled: true,
          ClickId: this.props.SaveButton.ClickId + 1
        }
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'e311_Error',
        Value: null
      }));
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'e311_Error',
        Value: errors
      }));
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(E311_RequiredProps);