import React from 'react';
import { batch, connect } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from '../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';
import * as icons from '../../../Icons/Icons';
import * as Azure from '@azure/storage-blob';
import cloneDeep from 'lodash.clonedeep';
import loader from "@monaco-editor/loader";
import Handlebars from 'handlebars';

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let templateName = ownProps.widget.Parameters.find(x => x.ParameterName === 'TemplateName');
  let scriptRunnerOnLoad = ownProps.widget.Parameters.find(x => x.ParameterName === 'ScriptRunnerOnLoad');

  let param = null;
  if (parameter) {
    param = helpers.getListParameter(state, parameter.ParameterValue);
  }

  let swid = ownProps.widget.SceneWidgetId;
  let templatesKey = '_templates' + swid;
  let selectedKey = '_templateSelected' + swid;
  let editKey = '_templateEdit' + swid;

  let isDirty = state[selectedKey] && state[editKey] && (state[selectedKey].Markup !== state[editKey].Markup || state[selectedKey].TestData !== state[editKey].TestData)

  return {
    SWID: swid,
    Parameter: param ? JSON.stringify(param) : null,
    Template: state[selectedKey],
    EditTemplate: state[editKey],
    Templates: state[templatesKey],
    TemplatesKey: templatesKey,
    SelectedKey: selectedKey,
    EditKey: editKey,
    IsDirty: isDirty,
    TargetDB: state.TargetDB
  };
};

export class TemplateEditor extends React.PureComponent {
  lastPreview = null;

  componentDidMount() {
    this.getTemplates();
    if (this.props.EditTemplate) {
      setTimeout(() => { this.selectTemplate(this.props.EditTemplate, true, true); }, 0);
    }
  }

  componentDidUpdate() {

  }

  getTemplates = (force) => {
    if (this.props.Templates && !force)
      return;

    let body = {
      SWID: this.props.SWID,
      Parameter: this.props.Parameter
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.TemplatesKey,
        Value: result
      }));
    });
  }

  selectTemplate = (template, force, editOnly) => {
    if (this.props.IsDirty && !force) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Pending Changes', Message: 'Save or Cancel changes' }
      }));
      return;
    }
    this.editor.setValue(template.Markup);
    this.testData.setValue(template.TestData);

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EditKey,
        Value: template
      }));
      if (!editOnly) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.SelectedKey,
          Value: template
        }));
      }
    })
  }

  markupChange = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.EditKey,
      Value: { ...this.props.EditTemplate, Markup: this.editor.getValue() }
    }));
  }

  testDataChange = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.EditKey,
      Value: { ...this.props.EditTemplate, TestData: this.testData.getValue() }
    }));
  }

  setEditorRef = (node) => {
    loader.init().then((monaco) => {
      this.editor = monaco.editor.create(node, {
        value: "",
        language: "html",
        lineDecorationsWidth: 5,
        lineNumbersMinChars: 0,
        glyphMargin: false,
        folding: false,
        minimap: {
          enabled: false
        }
      });
      this.editor.getModel().onDidChangeContent(this.markupChange);
    });
  }

  setTestDataRef = (node) => {
    loader.init().then((monaco) => {
      this.testData = monaco.editor.create(node, {
        value: "",
        language: "json",
        lineDecorationsWidth: 5,
        lineNumbersMinChars: 1,
        glyphMargin: false,
        folding: false,
        minimap: {
          enabled: false
        }
      });
      this.testData.getModel().onDidChangeContent(this.testDataChange);
    });
  }

  getPreview = () => {
    try {
      let hbMarkup = Handlebars.compile(this.props.EditTemplate.Markup);
      let testDataObj = JSON.parse(this.props.EditTemplate.TestData);
      this.lastPreview = parser(hbMarkup(testDataObj))
      return this.lastPreview;
    } catch {
      return this.lastPreview || parser(this.props.EditTemplate.Markup);
    }
  }

  save = () => {
    let markObj = { Table: 'met.Markup', Id: this.props.Template.MarkupId };
    let tmplObj = { Table: 'met.Template', Id: this.props.Template.TemplateId };
    let saveData = [
      helpers.saveDataItem(markObj, 'Template', this.props.EditTemplate.Markup),
      helpers.saveDataItem(tmplObj, 'TestData', this.props.EditTemplate.TestData),
    ]
    actions.ApiRequest('Save/Save', { SaveData: saveData }, () => {
      this.getTemplates(true);
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.SelectedKey,
        Value: this.props.EditTemplate
      }));
    });
  }

  cancel = () => {
    this.selectTemplate(this.props.Template, true);
  }

  sendTest = () => {
    let body = {
      Mode: 'EmailTest',
      EmailTestMarkup: this.props.EditTemplate.Markup,
      EmailTestData: this.props.EditTemplate.TestData,
      EmailTo: [this.props.EditTemplate.EmailTo]
    }
    actions.ApiRequest('Document/GetTemplate', body, () => { });
  }

  setEmail = (e) => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.EditKey,
      Value: { ...this.props.EditTemplate, EmailTo: e.target.value }
    }))
  }

  render() {
    let template = this.props.Template;
    let selectedId = template ? template.TemplateId : null;
    return (
      <div className="template-editor-widget">
        <div className="edit-template-sidebar">
          <div className="tmpl-edit-lbl">Templates</div>
          <div className="edit-templates-list">
            {this.props.Templates && this.props.Templates.map((x, idx) => (
              <div key={idx}
                className={"edit-templates-item" + (selectedId === x.TemplateId ? ' selected' : '')}
                onClick={() => { this.selectTemplate(x) }}>
                <div className="tmpl-name">{x.TemplateName}</div>
              </div>
            ))}
          </div>
          <div className="templates-test-data">
            <div className="tmpl-edit-lbl">Test Data</div>
            <div className="test-data-container" ref={this.setTestDataRef}></div>
          </div>
        </div>
        <div className="markup-editor" ref={this.setEditorRef}></div>
        <div className="markup-preview">
          {this.props.EditTemplate && this.props.EditTemplate.Markup && this.getPreview()}
        </div>
        <div className="editor-top-bar">
          <div className="editor-email">
            <div className="e-to">Email To</div>
            <input type="text" onChange={this.setEmail} value={this.props.EditTemplate ? this.props.EditTemplate.EmailTo : ''} />
            <div className="send-test" onClick={this.sendTest}>Send</div>
          </div>
          <div className={"editor-save" + (this.props.IsDirty ? ' active' : '')} onClick={this.save}><div>Save</div></div>
          <div className={"editor-cancel" + (this.props.IsDirty ? ' active' : '')} onClick={this.cancel}><div>Cancel</div></div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(TemplateEditor);