import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    SelectedEvent: state.ent_SelectedEvent,
    CurrentEvent: state.ent_CurrentEvent,
    SelectedService: state.ent_SelectedService
  };
};

export class UB_ServicePrevRead extends React.PureComponent {

  componentDidMount() {
    if (!this.props.SelectedEvent || this.props.SelectedEvent.EntityTypeId !== 2020105)
      return;

    let body = {
      Parameter: JSON.stringify({
        ServiceId: this.props.SelectedEvent.IsNew ? this.props.SelectedService.EntityId : null,
        EventId: this.props.SelectedEvent.EntityId
      }),
      SWID: this.props.SWID
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      if (result && result.Reading) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ent_CurrentEvent',
          Value: {
            PrevRead: result.Reading + '',
            ...this.props.CurrentEvent
          }
        }))
      }
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_ServicePrevRead);



