
import React from 'react';

const PayPeriodTotal = props => {
  let onClick = (e) => {
    props.parentClick(props.card);
  }
  let selected = props.selected && props.selected.Id === props.card.Id && props.card.Id !== undefined;
  return (
    <React.Fragment>
      <div className={"pay-period-total" + (selected ? ' selected' : '')} onClick={onClick}>
        <div className="sub-title">{props.card.Text2}</div>
        <div className="title">{props.card.Text1}</div>
        <div className="pending-totals">
          <div className="users">
            <div>Users:</div>
            <div className="qty">{props.card.PendingUsers}</div>
          </div>
          <div className="hours">
            <div>Hours:</div>
            <div className="qty">{props.card.PendingHours.toFixed(2)}</div>
          </div>
        </div>
        <div className="complete-totals">
          <div className="users">
            <div>Users:</div>
            <div className="qty">{props.card.CompleteUsers}</div>
          </div>
          <div className="hours">
            <div>Hours:</div>
            <div className="qty">{props.card.CompleteHours.toFixed(2)}</div>
          </div>
          {props.card.IsLocked && props.card.CardType === "PayPeriodTotals" && <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z" /></svg></div>}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PayPeriodTotal