import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../Utils/Helpers';
import { MagnifyingGlass } from '../../../../../Icons/Icons';
import ActionEditor from './ActionEditor';
import TriggerEditor from './TriggerEditor';
import ActionCard from './Cards/ActionCard';
import TriggerCard from './Cards/TriggerCard';
import Formula from './Cards/Formula';

const mapStateToProps = (state, ownProps) => {
  return {
    EditWorkflow: state.wkf_EditWorkflow
  };
};

export class Workflow extends React.PureComponent {
  state = {
    Triggers: [],
    Actions: [],
    WorkflowId: -1,
    WorkflowName: '',
    WorkflowDescription: '',
    TriggerEditor: false,
    ActionEditor: false,
    EditTriggerId: null,
    EditActionId: null
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (prevProps && prevProps.EditWorkflow) {
      let prevWf = prevProps.EditWorkflow;
      let curWf = this.props.EditWorkflow;
      if (curWf === 'new' && prevWf !== 'new') {
        this.setup();
      } else if (curWf.WorkflowId !== prevWf.WorkflowId) {
        this.setup();
      }
    }

    if (!prevProps) {
      this.setup();
    }
  }

  setup = () => {
    if (!this.props.EditWorkflow)
      return;

    if (this.props.EditWorkflow === 'new') {
      this.setState({
        Triggers: [],
        Actions: [],
        WorkflowName: '',
        WorkflowDescription: '',
        WorkflowId: -1
      });
    } else {
      let wf = this.props.EditWorkflow;
      this.setState({
        Triggers: wf.Triggers,
        Actions: wf.Actions,
        WorkflowName: wf.Name,
        WorkflowDescription: wf.Description,
        WorkflowId: wf.WorkflowId
      });
    }
  }

  newTrigger = () => {
    this.setState({ TriggerEditor: true, EditTriggerId: null });
  }

  newAction = () => {
    this.setState({ ActionEditor: true, EditActionId: null });
  }

  addTrigger = (item) => {
    if (!item.ItemId) {
      item.ItemId = this.state.Triggers.length + 1;
      this.setState({ Triggers: [...this.state.Triggers, item] });
    } else {
      let oldItemIdx = this.state.Triggers.findIndex(x => x.ItemId === item.ItemId);
      this.state.Triggers[oldItemIdx] = cloneDeep(item);
      this.setState({ Triggers: [...this.state.Triggers] });
    }

    this.setState({ TriggerEditor: false });
  }

  addAction = (item) => {
    if (!item.ItemId) {
      item.ItemId = this.state.Actions.length + 1;
      this.setState({ Actions: [...this.state.Actions, item] });
    } else {
      let oldItemIdx = this.state.Actions.findIndex(x => x.ItemId === item.ItemId);
      this.state.Actions[oldItemIdx] = cloneDeep(item);
      this.setState({ Actions: [...this.state.Actions] });
    }

    this.setState({ ActionEditor: false });
  }

  cancelTrigger = () => {
    this.setState({ TriggerEditor: false });
  }

  cancelAction = () => {
    this.setState({ ActionEditor: false });
  }

  editTrigger = (id) => {
    this.setState({ EditTriggerId: id });
    this.setState({ TriggerEditor: false });
    setTimeout(() => {
      this.setState({ TriggerEditor: true });
    }, 0);
  }

  editAction = (id) => {
    this.setState({ EditActionId: id });
    this.setState({ ActionEditor: false });
    setTimeout(() => {
      this.setState({ ActionEditor: true });
    }, 0);
  }

  saveWorkflow = () => {
    let body = {
      WorkflowId: this.state.WorkflowId,
      Name: this.state.WorkflowName,
      Description: this.state.WorkflowDescription,
      Triggers: this.state.Triggers,
      Actions: this.state.Actions
    }

    actions.ApiRequest('Editor/SaveWorkflow', { SaveData: JSON.stringify(body) }, () => {
      actions.ApiRequest('Editor/GetWorkflows', {}, (result) => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'wkf_Workflows',
          Value: result
        }));
      });
    });
  }

  setProp = (e, name) => {
    this.setState({ [name]: e.target.value });
  }

  render() {
    let editTrigger = this.state.EditTriggerId ? this.state.Triggers.find(x => x.ItemId === this.state.EditTriggerId) : null;
    let editAction = this.state.EditActionId ? this.state.Actions.find(x => x.ItemId === this.state.EditActionId) : null;
    return (
      <div className="new-workflow">
        <div className="wf-title-pane">
          <div className="side-bar-prop">
            <div>Name:</div>
            <input type="text" value={this.state.WorkflowName} onChange={(e) => { this.setProp(e, 'WorkflowName') }} />
          </div>
          <div className="side-bar-prop">
            <div>Description:</div>
            <input type="text" value={this.state.WorkflowDescription} onChange={(e) => { this.setProp(e, 'WorkflowDescription') }} />
          </div>
          <div className="wf-btn" onClick={this.saveWorkflow}>
            <div>Save Workflow</div>
          </div>
        </div>
        <div className="top-pane">
          <div className="triggers-pane">
            <div className="wf-title-bar">
              <div className="wf-title">Triggers</div>
              <div className="wf-add-btn" onClick={this.newTrigger}>
                <div>Add</div>
              </div>
            </div>
            <div className="wf-items-container">
              {this.state.Triggers.map((x, idx) => (
                <TriggerCard key={idx} item={x} idx={idx} editThisItem={this.editTrigger} />
              ))}
              {this.state.Triggers.length > 0 &&
                <Formula items={this.state.Triggers} />
              }
            </div>
          </div>
          <div className="actions-pane">
            <div className="wf-title-bar">
              <div className="wf-title">Actions</div>
              <div className="wf-add-btn" onClick={this.newAction}>
                <div>Add</div>
              </div>
            </div>
            <div className="wf-items-container">
              {this.state.Actions.map((x, idx) => (
                <ActionCard key={idx} item={x} idx={idx} editThisItem={this.editAction} />
              ))}
            </div>
          </div>
        </div>
        <div className="bottom-pane">
          <div className="trigger-form">
            {this.state.TriggerEditor && <TriggerEditor addTrigger={this.addTrigger} cancelTrigger={this.cancelTrigger} editItem={editTrigger} />}
          </div>
          <div className="action-form">
            {this.state.ActionEditor && <ActionEditor addAction={this.addAction} cancelAction={this.cancelAction} editItem={editAction} />}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Workflow);