
import React from 'react';
import parser from 'html-react-parser';

const HTML = props => {
  console.log('html card is born');
  return (
    <React.Fragment>
      <div className="html-card">
        {props.card.Markup && parser(props.card.Markup, {
          replace: domNode => {
            console.log(domNode);
            if (domNode.name === 'updateprop') {
              return (
                <div onClick={() => { console.log('you clicked it') }}>
                  click me
                </div>
              )
            }
          }
        })}
      </div>
    </React.Fragment>
  );
}

export default HTML;