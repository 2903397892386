
import Bing from './../CustomLayers/Bing';
import XYZ from '../CustomLayers/XYZ';
import WMS from '../CustomLayers/WMS';
import TileArcGISRest from '../CustomLayers/TileArcGISRest';
import imgLayer from '../CustomLayers/Image';
import Layers from './Layers'
import Azure from '../CustomLayers/AzureMaps/AzureLayer';
import Google from '../CustomLayers/Google';

export default {
  2: (options) => { return Layers.getLayer(options); },
  3: (options) => { return XYZ.xyzLayer(options.Path) },
  4: (options) => { return WMS.wmsLayer(options.Path, JSON.parse(options.Parameters)) },
  5: (options) => { return TileArcGISRest.tileArcGISRestLayer(options.Path, JSON.parse(options.Parameters)) },
  6: (options) => { return imgLayer.staticLayer(options.Path, options) },
  7: (options) => { return Azure.azureLayer(options) },
  8: (options) => { return XYZ.xyzLayer(options.Path, 'cluster') },
  10: (options) => { return Bing.bingMapsLayer(options.BingKey, 'RoadOnDemand'); },
  11: (options) => { return Bing.bingMapsLayer(options.BingKey, 'AerialWithLabelsOnDemand'); },
  12: (options) => { return Bing.bingMapsLayer(options.BingKey, 'CanvasDark'); },
  13: (options) => { return Google.googleMapsLayer(options); }
}

// Bing types:
//  Aerial
//  AerialWithLabelsOnDemand
//  RoadOnDemand
//  CanvasDark
//  OrdnanceSurvey
