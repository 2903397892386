import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  let d = state.dbo_EventSaveData.find(x => {
    return x.Column=="TransferDate"
  })
  return {SWID: state.sr_ActiveSWID,EntityId: state.ent_Selected.EntityId,SelectedDate: d?d.Value:null};
};

export class UB_CheckTotalFinalsForDate extends React.PureComponent {

  componentDidMount() {
    //call cost calculation
    let body = {
      SWID: this.props.SWID,
      Parameter: `{"EntityId":${this.props.EntityId}}`
    }
    actions.ApiRequest('List/GetList', body, (result) => {
      console.log(result)
      let dateDisabled = false
      if(this.props.SelectedDate) {
        result.forEach(r => {
          if(r.CDate == this.props.SelectedDate) dateDisabled = true
        })
      }
      console.log("dateDisabled: ",dateDisabled)
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_CanSave',
        Value: !dateDisabled
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_CheckTotalFinalsForDate);