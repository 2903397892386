import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state.ent_Current,
    SaveQueue: state.dbo_ContactMgtSaveData,
    NewContact: state.ent_NewContactSelected
  };
};

export class NewContactSaveData extends React.PureComponent {
  RelTable = 'xub.AcctContact';
  SaveQueue = 'dbo_ContactMgtSaveData';

  componentDidMount() {
    if (!this.props.NewContact || this.props.NewContact.SRComplete)
      return;

    this.props.NewContact.SRComplete = true;

    let baseObj = {
      Id: null,
      InsertKey: helpers.getInsertKey(),
      Table: this.RelTable,
      IsEventData: false,
      SaveQueue: this.SaveQueue,
      IsBaseTable: false
    }

    let saveData = [
      helpers.saveDataItem(baseObj, 'AcctId', this.props.CurrentEntity.EntityId),
      helpers.saveDataItem(baseObj, 'ContactId', this.props.NewContact.EntityId),
      helpers.saveDataItem(baseObj, 'IsDeleted', false),
      helpers.saveDataItem(baseObj, 'Bit1', false),
    ];

    batch(() => {
      saveData.forEach(sd => {
        this.props.dispatch(actions.AddSaveData(sd));
      });
    })
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(NewContactSaveData);