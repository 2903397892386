import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSource');
  let saveData = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveData');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let entitySource = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntitySource');
  let sceneKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'SceneKey');
  let resetKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetKey');
  let newEntityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'NewEntityKey');
  let newEntityResetScene = ownProps.widget.Parameters.find(x => x.ParameterName === 'NewEntityResetScene');

  return {
    Button: source ? state[source.ParameterValue] : undefined,
    ButtonSource: source ? source.ParameterValue : undefined,
    SaveData: saveData ? state[saveData.ParameterValue] : undefined,
    EntityKey: entityKey ? entityKey.ParameterValue : undefined,
    EntitySource: entitySource ? entitySource.ParameterValue : undefined,
    SaveDataKey: saveData ? saveData.ParameterValue : undefined,
    CancelId: state.dbo_CancelId,
    CurrentEntity: entityKey ? state[entityKey.ParameterValue] : undefined,
    SceneKey: sceneKey ? sceneKey.ParameterValue : undefined,
    ResetKey: resetKey ? resetKey.ParameterValue : undefined,
    NewEntityKey: newEntityKey ? newEntityKey.ParameterValue : undefined,
    NewEntity: newEntityKey ? state[newEntityKey.ParameterValue] : undefined,
    NewEntityResetScene: newEntityResetScene ? newEntityResetScene.ParameterValue : undefined,
    BluModalScene: state.blu_ModalScene,
    BluDialog: state.blu_Dialog,
    BlobSAS: state.BlobSAS ? state.BlobSAS : undefined
  };
};

export class Cancel extends React.PureComponent {
  waiting = false;
  state = {
    CachedNewEntity: null
  }
  constructor(props) {
    super(props);
    this.updateButton(0, false);
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.Button)
      return;

    this.checkEnabled(prevProps);
    this.handleClick(prevProps);
    this.newEntityCheck();
  }

  newEntityCheck = () => {
    if (this.props.NewEntity) {
      let newEntId = helpers.entId(this.props.NewEntity);
      let cachedId = helpers.entId(this.state.CachedNewEntity);
      if (newEntId !== cachedId) {
        this.setState({ CachedNewEntity: cloneDeep(this.props.NewEntity) });
      }
    }
  }

  checkEnabled = () => {
    if (!this.props.SaveData || !this.props.Button)
      return;

    let clearNewEntityProps = !(this.props.EntityKey || '').toLowerCase().includes('event');
    let isEnabled = (this.props.SaveData || []).filter(x => clearNewEntityProps || !x.NewEntitySaveData).length > 0;

    if (this.props.Button.Enabled !== isEnabled) {
      this.updateButton(this.props.Button.ClickId, isEnabled);
    }
  }

  handleClick = (prevProps) => {
    if (!prevProps.Button || !this.props.Button)
      return;

    if (prevProps.Button.ClickId !== this.props.Button.ClickId) {
      this.doCancel();
    }
  }

  doCancel = () => {
    let ent = this.props.CurrentEntity;

    if (ent) {
      helpers.globalGeoReset(ent.EntityTypeId + '_' + ent.EntityId);
    }

    batch(() => {
      if (ent && ent.IsNew && this.props.SceneKey) {
        this.props.dispatch(actions.SetCurrentEntity(null, this.props.EntityKey));
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.SceneKey,
          Value: undefined
        }));
        if (this.props.EntitySource) {
          this.props.dispatch(actions.UpdateProp({
            Key: this.props.EntitySource,
            Value: undefined
          }));
        }
      } else if (this.props.EntityKey) {
        this.props.dispatch(actions.ResetCurrentEntity(this.props.EntityKey));
      }

      this.props.SaveData.forEach(item => {
        if (item.OnCancel) {
          item.OnCancel();
        }
      });

      this.props.dispatch(actions.CancelSaveData({
        key: this.props.SaveDataKey,
        clearNewProps: !(this.props.EntityKey || '').toLowerCase().includes('event')
      }));

      if (this.props.ResetKey) {
        let keys = this.props.ResetKey.split(',').map(x => x.trim());
        keys.forEach(x => {
          this.props.dispatch(actions.UpdateProp({
            Key: x,
            Value: undefined
          }));
        });
      }

      if (ent && ent.IsNew && this.props.NewEntityResetScene) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.NewEntityResetScene,
          Value: undefined
        }));
      } else if (ent && ent.IsNew && this.props.NewEntityKey && this.state.CachedNewEntity) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.NewEntityKey,
          Value: this.state.CachedNewEntity
        }));
      }

      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_CancelId',
        Value: this.props.CancelId + 1
      }));

      if (
        this.props.BlobSAS
        && this.props.BlobSAS.Database.includes("NE_LincolnParks")
        && this.props.ButtonSource === "btn_EventCancel"
        && this.props.EntityKey === "ent_CurrentEvent"
        && this.props.BluDialog?.SaveQueue === "dbo_EventSaveData"
        && this.props.BluDialog?.Title === "Pending Changes"
        && this.props.BluModalScene?.Name === "AM_EventScene"
        && this.props.BluModalScene?.Value === "AM_EventScene"
      ) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ent_TryCloseEvent',
          Value: true
        }));
      }
    });
  }

  updateButton = (clickId, isEnabled) => {
    if (!this.props.ButtonSource)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        ClickId: clickId,
        Enabled: isEnabled
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Cancel);