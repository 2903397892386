import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let searchProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'SearchText');
  let prefix = ownProps.widget.Parameters.find(x => x.ParameterName === 'Prefix');
  let initial = ownProps.widget.Parameters.find(x => x.ParameterName === 'InitialText');
  let searchOnLoad = ownProps.widget.Parameters.find(x => x.ParameterName === 'SearchOnLoad');
  let resetOnLoad = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetOnLoad');
  let searchOnKeystroke = ownProps.widget.Parameters.find(x => x.ParameterName === 'SearchOnKeystroke');

  return {
    SearchText: searchProp ? state[searchProp.ParameterValue] : undefined,
    SearchTextKey: searchProp ? searchProp.ParameterValue : undefined,
    Prefix: prefix ? prefix.ParameterValue : '',
    Initial: initial ? initial.ParameterValue : undefined,
    SearchOnLoad: searchOnLoad ? helpers.stringToBool(searchOnLoad.ParameterValue) : false,
    ResetOnLoad: resetOnLoad ? helpers.stringToBool(resetOnLoad.ParameterValue) : false,
    SearchOnKeystroke: searchOnKeystroke ? helpers.stringToBool(searchOnKeystroke.ParameterValue) : false
  };
}

export class SearchBar extends React.PureComponent {
  constructor(props) {
    super(props);
    let searchText = this.props.SearchText || this.props.Initial || '';
    if (this.props.Prefix) {
      searchText = searchText.replace(this.props.Prefix, '');
    }
    this.state = {
      searchText: searchText,
      params: helpers.paramsToObject(this.props.widget)
    }
  }

  componentDidMount = () => {
    if (this.props.ResetOnLoad) {
      this.setState({ searchText: '' })
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.SearchTextKey,
        Value: ''
      }));
    }
    if (this.props.SearchOnLoad) {
      this.doSearch();
    }
  }

  handleChange = (e) => {
    this.setState({ searchText: e.target.value });
    if (this.props.SearchOnKeystroke) {
      setTimeout(() => {
        this.doSearch();
      }, 0)
    }
  }

  keyPress = (e) => {
    if (e.key === 'Enter') {
      this.doSearch();
      e.preventDefault();
    }
  }

  doSearch = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.SearchTextKey,
      Value: (this.props.Prefix || '') + this.state.searchText
    }));
  }

  render() {
    let buttonColorParam = this.state.params.ButtonColor;
    let backgroundColorParam = this.state.params.BackgroundColor;
    let helpTextParam = this.state.params.HelpText;

    return (
      <div className="search-bar" style={backgroundColorParam ? { backgroundColor: backgroundColorParam } : {}}>
        <div className="center-content">
          <input type="text"
            value={this.state.searchText}
            onChange={this.handleChange}
            onKeyPress={this.keyPress}
            placeholder={helpTextParam || ''} />
          <div className="div-btn" onClick={this.doSearch}
            style={buttonColorParam ? { backgroundColor: buttonColorParam } : {}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 30 30" space="preserve" fill="#1A1A1A">
              <g>
                <path d="m 16.069954,0.8723014 c -4.939093,0 -8.9564871,4.0159906 -8.9564871,8.9536837 0,1.7971799 0.5392936,3.4738939 1.4567945,4.8788609 l -7.082263,7.121484 c -0.63454591,0.635947 -0.49026716,1.808387 0.3193742,2.615226 0.8096417,0.808242 1.9806804,0.948317 2.6110243,0.312371 l 7.1579061,-7.195724 c 1.322321,0.770419 2.853356,1.220064 4.493651,1.220064 4.93629,0 8.950881,-4.014589 8.950881,-8.9522819 0,-4.9376931 -4.014591,-8.9536837 -8.950881,-8.9536837 z m 0,14.6631976 c -3.148917,0 -5.712316,-2.559195 -5.712316,-5.7095139 0,-3.1475166 2.563399,-5.7081133 5.712316,-5.7081133 3.146114,0 5.708111,2.5605967 5.708111,5.7081133 0,3.1503189 -2.561997,5.7095139 -5.708111,5.7095139 z"></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SearchBar);