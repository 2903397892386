import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';
import { EmptyCheck, OrangeUnchecked, OrangeCheck, Checked, IndeterminateCheck } from './../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let control = ownProps.control;
  return {
    SaveData: state.dbo_SaveData,
    Radio: control && control.SaveData && control.SaveData.Radio ? state['radio_' + control.SaveData.Radio] : undefined
  };
}

export class Checkbox extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.keyPress);
  }

  componentDidUpdate(prev) {
    let prevId = prev && prev.Radio ? prev.Radio.UpdateId : null;
    let curId = this.props.Radio ? this.props.Radio.UpdateId : null;

    if (curId && (!prevId || prevId !== curId)) {
      let savedata = this.props.control.SaveData;
      let isEnabled = (savedata.Id || savedata.InsertKey) === this.props.Radio.Id;
      if ((savedata.Id || savedata.InsertKey) && this.props.control.Value !== null && isEnabled !== this.props.control.Value) {
        this.onChange(isEnabled);
      }
    }
  }

  onChange = (checked) => {
    if (this.props.control.IsRO)
      return;

    console.log({ cntrl: this.props.control, val: checked });

    this.props.control.Value = checked;
    this.forceUpdate();

    if (this.props.refresh)
      this.props.refresh();

    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: this.props.control.Reversed ? !checked : checked,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        Radio: this.props.control.SaveData.Radio,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
  }

  render() {
    let control = this.props.control;
    let hasValue = control.Value !== null && control.Value !== undefined && control.Value !== '';
    let value = helpers.stringToBool(control.Value);
    let isOrange = this.props.control.ClassName && this.props.control.ClassName.includes('orange-check');
    let isYesNo = this.props.control.ClassName && this.props.control.ClassName.includes('yes-no-check');

    return (
      <div className="checkbox-control">
        <div className="control-label">
          {control.Label}
          {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
        </div>
        <div
          tabIndex={0}
          // tabIndex={control.Location && control.Location.ControlOrder ? control.Location.ControlOrder : 0} 
          onKeyPress={(e) => { e.preventDefault(); this.onChange(!this.props.control.Value) }}>
          {value && !isYesNo &&
            <div className={"cbox check-enabled" + (control.IsRO ? " disabled-check" : "")} onClick={() => { this.onChange(false) }} >
              {!isOrange && <Checked />}
              {isOrange && <OrangeCheck />}
            </div>
          }
          {!value && !isYesNo &&
            <div className={"cbox" + (control.IsRO ? " disabled-check" : "")} onClick={() => { this.onChange(true) }}>
              {!isOrange && <EmptyCheck />}
              {isOrange && <OrangeUnchecked />}
            </div>
          }
          {isYesNo && <div className="radio-lookup-items">
            <div onClick={() => { this.onChange(true) }} className={(hasValue && value ? "selected " : "") + "radio-lookup-item"}>Yes</div>
            <div onClick={() => { this.onChange(false) }} className={(hasValue && !value ? "selected " : "") + "radio-lookup-item"}>No</div>
          </div>}
        </div>

      </div>
    );
  }
}

export default connect(mapStateToProps)(Checkbox);