import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');

  return {
    SelectedEntity: state.ent_Selected,
    CurrentEntity: state.ent_Current,
    Metadata: state.met_EntityMetadata,
    CreateableAssets: state.ent_CreatableAssets
  };
};

let _entFrom = null;

export class CopyProject extends React.PureComponent {
  /*RelTable = 'rel.SignSupport';
  SupportTable = 'xtn.A1088';*/

  RelTableGrants = 'rel.Ohio2';
  SupportTableGrants = 'rel.xtn.A1507';

  RelTableGoals = 'rel.Ohio1';
  SupportTableGoals = 'xtn.A1505';

  //On first run, set _entFrom to CurrentEntity, call itself a second time
  //On second run copy project, goals, (grants?)
  componentDidMount() {
    if (_entFrom == null) {
      this.triggerNewEntity();
    } else {
      this.copyProject(_entFrom, this.props.CurrentEntity);
      //this.copyGoals();
      _entFrom = null;
    }
  }

  triggerNewEntity() {
    _entFrom = this.props.CurrentEntity;
    let ent_SelectedNew = this.props.CreateableAssets.find(x => x.Text1 === 'Project');
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_SelectedNew',
      Value: ent_SelectedNew
    }));
    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'sr_CreateCopyFromCurrent',
        Value: true
      }));
    }, 500);
  }

  propNameLabel = (prop) => {
    return prop ? prop.Name + '-' + prop.Label : '';
  }

  copyProject = (fromEntity, toEntity) => {
    let propMap = fromEntity.Properties.reduce((prev, next) => {

      if (this.propNameLabel(next) !== 'Name-Name') {
        prev[next.Name] = next.Value;
      }
      return prev;
    }, {});

    let saveData = [];

    toEntity.Properties.forEach(prop => {
      if (this.propNameLabel(prop) !== 'Name-Name') {
        prop.Value = propMap[prop.Name];
      }

      saveData.push({
        Id: null,
        InsertKey: prop.SaveData.InsertKey,
        Table: prop.SaveData.Table,
        Column: prop.SaveData.Column,
        Value: prop.Value,
        GobAutofill: prop.GobAutofill,
        IsBaseTable: prop.IsBaseTable,
        AutoGenerated: true,
        SaveQueue: 'dbo_SaveData'
      })
    })

    batch(() => {
      saveData.forEach(sd => {
        this.props.dispatch(actions.AddSaveData(sd));
      });
    });
  }

  //******************* Ohio does not want goals copied now, but may possibly in the future ******************* code past here not used currently

  copyGoals = () => {
    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify(_entFrom)
    }

    //let currentEntity = cloneDeep(this.props.CurrentEntity);

    actions.ApiRequest('List/GetList', body, (result) => {
      batch(() => {
        result.Goals.forEach(goal => {
          this.addRelGoalRecord(this.props.SelectedEntity, goal);
        });
        if (result.GrantId) {
          let baseObj = {
            Id: null,
            InsertKey: this.props.SelectedEntity.InsertKeys['xtn.A1506'],
            Table: 'xtn.A1506',
            IsBaseTable: true,
            EntityTypeId: 1506,
            SaveQueue: 'dbo_SaveData'
          };
          this.props.dispatch(actions.AddSaveData(helpers.saveDataItem(baseObj, 'GrantId', result.GrantId)));
        }
      });
    });
  }

  addRelGoalRecord = (project, goal) => {
    let baseObj = {
      Id: null,
      InsertKey: helpers.getInsertKey(),
      Table: this.RelTableGoals,
      IsBaseTable: false,
      EntityTypeId: goal.EntityTypeId,
      SaveQueue: 'dbo_SaveData'
    };

    let saveData = [
      helpers.saveDataItem(baseObj, 'AssetId', project.EntityId),
      helpers.saveDataItem(baseObj, 'EntityTypeId', goal.EntityTypeId),
      helpers.saveDataItem(baseObj, 'EntityId', goal.EntityId),
      helpers.saveDataItem(baseObj, 'IsDeleted', false)
    ];

    saveData.forEach(sd => {
      this.props.dispatch(actions.AddSaveData(sd));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(CopyProject);