import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state.ent_Current,
    Selected: state.ent_Selected,
    CurrentEvent: state.ent_CurrentEvent,
    TransferDate: state.SelectedTimeSlot,
    EventSave: state.btn_EventSave,
    UserId: state.CurrentUser ? state.CurrentUser.Id : null
  };
};

export class WG_LoadContactOrg extends React.PureComponent {

  componentDidMount() {

    let orgIdProp = this.props.CurrentEntity.Properties.find(x => x.Name === 'OrganizationId');

    if (!orgIdProp)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_Selected',
      Value: {
        EntityTypeId: 2001,
        EntityId: Number(orgIdProp.Value)
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(WG_LoadContactOrg);