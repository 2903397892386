import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {
    CurrentEntity: state.ent_Current,
    EntitySelected: state.ent_EventMoveSelected,
    CurrentEvent: state.ent_CurrentEvent,
    SaveId: state.dbo_SaveId,
    SelectedCard: state.ent_CopyEventSelected,
    RefreshKey: state.dbo_CopyEventRefresh || 0
  };
};

export class Ohio_CopyEvent extends React.PureComponent {

  componentDidMount() {
    if (this.waiting)
      return;
    let eventParentType = this.props.CurrentEntity.SecondaryText;
    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify({ oldEventId: this.props.CurrentEvent.EntityId, newAssetId: this.props.SelectedCard.EntityId, eventParent: eventParentType })
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_CopyEventRefresh',
        Value: this.props.RefreshKey + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Success!', Message: <p style={{ marginTop: '20px', fontSize: '1.1rem' }}>A copy of this event has been added to <b>{this.props.SelectedCard.Text1}</b></p> }
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Ohio_CopyEvent);