import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSource');

  return {
    Button: source ? state[source.ParameterValue] : undefined,
    ButtonSource: source ? source.ParameterValue : undefined,
    Timesheet: state.ttr_Timesheet,
    RefreshTimesheet: state.ttr_RefreshTimesheet || 0,
    SaveId: state.dbo_SaveId,
    TimeTrackingRefresh: state.ttr_TimeTrackingRefresh
  };
};

export class SubmitTimesheet extends React.PureComponent {
  waiting = false;

  constructor(props) {
    super(props);
    this.updateButton(0, false);
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.Button)
      return;

    this.checkEnabled(prevProps);
    this.handleClick(prevProps);
  }

  checkEnabled = () => {
    let enabled = false;
    let sheet = this.props.Timesheet;

    if (sheet) {
      enabled = !sheet.UserPayperiod.Status || sheet.UserPayperiod.Status === 1;
    }

    if (this.props.Button.Enabled !== enabled) {
      this.updateButton(this.props.Button.ClickId, enabled);
    }
  }

  handleClick = (prevProps) => {
    if (!prevProps.Button)
      return;

    if (prevProps.Button.ClickId !== this.props.Button.ClickId) {
      this.doSubmit();
    }
  }

  doSubmit = () => {
    if (this.waiting)
      return;

    let sheet = this.props.Timesheet;
    let insertKey = null;
    let id = null;

    if (sheet.UserPayperiod.Id) {
      id = sheet.UserPayperiod.Id;
    } else {
      insertKey = helpers.getInsertKey();
    }

    let saveData = [{
      Id: id,
      InsertKey: insertKey,
      Table: 'ttr.UserPayperiod',
      Column: 'UserId',
      Value: sheet.UserId,
      IsBaseTable: true,
      EntityTypeId: 100
    }, {
      Id: id,
      InsertKey: insertKey,
      Table: 'ttr.UserPayperiod',
      Column: 'PayPeriodId',
      Value: sheet.PayPeriodId,
      IsBaseTable: true,
      EntityTypeId: 100
    }, {
      Id: id,
      InsertKey: insertKey,
      Table: 'ttr.UserPayperiod',
      Column: 'PayperiodStatus',
      Value: 2,
      IsBaseTable: true,
      EntityTypeId: 100
    }, {
      Id: id,
      InsertKey: insertKey,
      Table: 'ttr.UserPayperiod',
      Column: 'IsDeleted',
      Value: false,
      IsBaseTable: true,
      EntityTypeId: 100
    }];

    this.waiting = true;

    actions.ApiRequest('Save/Save', { SaveData: saveData }, () => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_RefreshTimesheet',
        Value: this.props.RefreshTimesheet + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_TimeTrackingRefresh',
        Value: (this.props.TimeTrackingRefresh || 0) + 1
      }));
      setTimeout(() => { this.waiting = false; }, 1000);
    });
  }

  updateButton = (clickId, isEnabled) => {
    if (!this.props.ButtonSource)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        ClickId: clickId,
        Enabled: isEnabled
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(SubmitTimesheet);