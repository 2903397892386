import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../../Utils/Helpers';
import { MagnifyingGlass } from '../../../../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class ButtonState extends React.PureComponent {
  state = {
    btnEnabled: 'true'
  }

  componentDidMount() {
    let mockEvt = { target: { value: this.state.btnEnabled } };
    this.props.setProp(mockEvt, 'Text2')
  }

  setButtonState = (e) => {
    this.setState({ btnEnabled: e.target.value });
    this.props.setProp(e, 'Text2');
  }

  render() {
    return (
      <React.Fragment>
        <div className="trigger-specific-props"></div>
        <div className="side-bar-prop">
          <div>Button Source:</div>
          <input type="text" value={this.props.state.Text1 || ''} onChange={(e) => { this.props.setProp(e, 'Text1') }} />
        </div>
        <div className="side-bar-prop">
          <div>Make button:</div>
          <select value={this.state.btnEnabled} onChange={this.setButtonState}>
            <option value={'true'}>Enabled</option>
            <option value={'false'}>Disabled</option>
          </select>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(ButtonState);