import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { BackArrow } from './../../../Icons/Icons'
import cloneDeep from 'lodash/cloneDeep';
import * as tbData from './tbData';

const mapStateToProps = (state, ownProps) => {
  let userKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'UserKey');
  let isAdmin = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsAdmin');

  return {
    User: userKey ? state[userKey.ParameterValue] : undefined,
    IsAdmin: isAdmin ? helpers.stringToBool(isAdmin.ParameterValue) : false,
    TimeBanks: state.ttr_TimeBanks,
    TimeTrackingRefresh: state.ttr_TimeTrackingRefresh,
    Cancel: state.ttr_LeaveTabCancel,
    Save: state.ttr_LeaveTabSave,
    SaveId: state.dbo_SaveId
  };
};

let _lastSaveId = null

export class TimeBanks extends React.PureComponent {

  componentDidMount() {
    this.refreshIfNeeded();
  }

  componentDidUpdate(prev) {
    this.refreshIfNeeded(prev);
  }

  refreshIfNeeded(prev) {
    let refresh = false;

    if (!this.props.User)
      return;

    if (!this.props.TimeBanks || this.props.TimeBanks.UserId !== this.props.User.Id) {
      refresh = true;
    }

    if (prev && prev.TimeTrackingRefresh !== this.props.TimeTrackingRefresh) {
      refresh = true;
    }

    if (prev && prev.Cancel.ClickId !== this.props.Cancel.ClickId) {
      refresh = true;
    }

    if (_lastSaveId !== this.props.SaveId) {
      refresh = true;
      _lastSaveId = this.props.SaveId;
    }

    _lastSaveId = this.props.SaveId;

    if (refresh) {
      this.getTimeBanks();
    }
  }

  getTimeBanks = () => {
    if (!this.props.User)
      return;

    let body = {
      UserId: this.props.User.Id
    }

    actions.ApiRequest('TimeTracking/GetTimeBanks', body, (result) => {
      if (!result || !result.Banks) {
        console.error(result);
        return;
      }

      result.Banks.forEach((x) => {
        x.Mode = 'Display';
        x.Settings = JSON.parse(x.Settings);
        x.Adjustment = {
          PayPeriodId: -1,
          Adjustment: 0,
          Note: '',
          NewBalance: x.Balance
        }
      });

      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_TimeBanks',
        Value: result
      }));
    });
  }

  setMode = (bank, mode) => {
    bank.Mode = mode;
    this.forceUpdate();
  }

  updateProp = (e, bank, obj, propName, typeId) => {
    obj[propName] = e.target.value;
    tbData.addSaveData(this.props.dispatch, this.props.TimeBanks.UserId, bank, propName, obj, typeId);
    this.forceUpdate();
  }

  setAdjustmentProp = (e, bank, propName) => {
    if (propName === 'NewBalance') {
      let val = e.target.valueAsNumber;
      bank.Adjustment.NewBalance = isNaN(val) ? 0 : val;
      bank.Adjustment.Adjustment = bank.Adjustment.NewBalance - bank.Balance;
    } else {
      bank.Adjustment[propName] = e.target.value;
    }
    this.forceUpdate();
  }

  saveAdjustment = (bank) => {
    tbData.saveAdjustment(this.props.TimeBanks, bank);
    bank.Balance = bank.Adjustment.NewBalance;
    bank.Mode = 'Display';
    bank.Adjustment = {
      PayPeriodId: -1,
      Adjustment: 0,
      Note: '',
      NewBalance: bank.Balance
    };
    this.forceUpdate();
  }

  adjustmentInvalid = (bank) => {
    let invalidNote = !bank.Adjustment.Note;
    let invalidPeriod = (!bank.Adjustment.PayPeriodId || bank.Adjustment.PayPeriodId <= 0);
    return invalidNote || invalidPeriod;
  }

  render() {
    return (
      !this.props.TimeBanks ? null :
        <div className="timebanks-widget">
          {
            this.props.TimeBanks.Banks.map((bank, idx) => (
              <div key={idx} className="time-bank">
                {
                  bank.Mode === 'Display' &&
                  <div className="bank-label">
                    {this.props.IsAdmin &&
                      <div className="bank-controls">
                        <div onClick={() => { this.setMode(bank, 'Accrual') }}>Accrual Settings</div>
                        <div onClick={() => { this.setMode(bank, 'Adjustment') }}>Manual Adjustment</div>
                      </div>
                    }
                    <div className="bank-name">
                      <div>{bank.TimeBankName}</div>
                    </div>
                    <div className="bank-balance">
                      <div>{bank.Balance} Hours</div>
                    </div>
                  </div>
                }
                {
                  bank.Mode === 'Accrual' &&
                  <div className="accrual-settings">
                    <div className="back-button" onClick={() => { this.setMode(bank, 'Display') }}>
                      <BackArrow />
                    </div>
                    <div className="bank-header">
                      <div>{bank.TimeBankName} Settings</div>
                    </div>
                    <div className="settings-container">
                      <div className="line-item">
                        <div className="label">Max Balance:</div>
                        <input type="number" value={bank.Settings.MaxAccrual} onChange={(e) => { this.updateProp(e, bank, bank.Settings, 'MaxAccrual', 1) }} />
                      </div>
                      <div className="line-item">
                        <div className="label">Added Yearly:</div>
                        <input type="number" value={bank.Settings.YearlyAccrual || ''} onChange={(e) => { this.updateProp(e, bank, bank.Settings, 'YearlyAccrual', 1) }} />
                      </div>
                      <div className="line-item">
                        <div className="label">Added Each Period:</div>
                        <input type="number" value={bank.Settings.PayPeriodAccrual || ''} onChange={(e) => { this.updateProp(e, bank, bank.Settings, 'PayPeriodAccrual', 1) }} />
                      </div>
                      <div className="hourly-rates">
                        <div className="hourly-header">
                          <div>Wage Code:</div>
                          <div>Hourly Rate:</div>
                        </div>
                        {
                          bank.WageCodes.map((wc, wcIdx) => (
                            <div key={wcIdx} className="wage-code-rate">
                              <div className="line-item">
                                <div className="label">{wc.WageCodeName}</div>
                                <input type="number" disabled={!wc.IsEnabled} value={wc.AccrualRate || ''} onChange={(e) => { this.updateProp(e, bank, wc, 'AccrualRate', 2) }} />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                }
                {
                  bank.Mode === 'Adjustment' &&
                  <div className="manual-adjustment">
                    <div className="back-button" onClick={() => { this.setMode(bank, 'Display') }}>
                      <BackArrow />
                    </div>
                    <div className="bank-header">
                      <div>{bank.TimeBankName} Adjustment</div>
                    </div>
                    <div className="line-item">
                      <div className="label">Current Balance:</div>
                      <div className="static-value">{bank.Balance} Hours</div>
                    </div>
                    <div className="line-item">
                      <div className="label">New Balance:</div>
                      <div className="item-value">
                        <input type="number" value={bank.Adjustment.NewBalance} onChange={(e) => { this.setAdjustmentProp(e, bank, 'NewBalance') }} />
                        <div>({bank.Adjustment.NewBalance - bank.Balance >= 0 ? '+' : '-'}{Number(Math.abs(bank.Adjustment.NewBalance - bank.Balance).toFixed(2))})</div>
                      </div>
                    </div>
                    <div className="line-item">
                      <div className="label">Pay Period:</div>
                      <select value={bank.Adjustment.PayPeriodId || -1} onChange={(e) => { this.setAdjustmentProp(e, bank, 'PayPeriodId') }}>
                        <option value={-1}></option>
                        {this.props.TimeBanks.PayPeriods.map((pp, ppIdx) => (
                          <option key={ppIdx} value={pp.Id}>{pp.Num}</option>
                        ))}
                      </select>
                    </div>
                    <div className="line-item">
                      <div className="label">Note:</div>
                      <textarea type="text" value={bank.Adjustment.Note} onChange={(e) => { this.setAdjustmentProp(e, bank, 'Note') }} />
                    </div>
                    <div className="button-container">
                      <button type="button" disabled={this.adjustmentInvalid(bank)} onClick={() => { this.saveAdjustment(bank) }}>Apply</button>
                    </div>
                  </div>
                }
              </div>
            ))
          }
        </div>
    );
  }
}

export default connect(mapStateToProps)(TimeBanks);
