import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let entKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let sceneKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'SceneKey');
  let resetKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetKey');
  let resetValue = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetValue');
  let parentKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ModalParentSceneKey');
  let loadCountKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'LoadCountKey');

  return {
    Entity: entKey ? state[entKey.ParameterValue] : undefined,
    SceneKey: sceneKey ? sceneKey.ParameterValue : undefined,
    Scene: sceneKey ? state[sceneKey.ParameterValue] : undefined,
    EntityMetadata: state.met_EntityMetadata,
    Scenes: state.blu_Scene,
    MasterScene: state.blu_MasterScene,
    ResetKey: resetKey ? resetKey.ParameterValue : undefined,
    ResetValue: resetValue ? resetValue.ParameterValue : undefined,
    Refresh: state.blu_SceneSwitcherRefresh || false,
    ParentSceneKey: parentKey ? parentKey.ParameterValue : undefined,
    ParentScene: parentKey ? state[parentKey.ParameterValue] : undefined,
    CounterKey: loadCountKey ? loadCountKey.ParameterValue : undefined,
    CounterValue: loadCountKey ? state[loadCountKey.ParameterValue] || 0 : undefined
  };
};

export class EntitySceneSwitcher extends React.PureComponent {
  componentDidUpdate(prev) {
    this.loadEntityScene(prev);
  }

  loadEntityScene = (prev) => {
    let curType = this.props.Entity ? this.props.Entity.EntityTypeId : null;
    let curId = this.props.Entity ? this.props.Entity.RefreshId || this.props.Entity.Id || this.props.Entity.EntityId : null;
    let prevId = null;
    let prevType = null;
    if (prev) {
      prevType = prev.Entity ? prev.Entity.EntityTypeId : null;
      prevId = prev.Entity ? prev.Entity.RefreshId || prev.Entity.Id || prev.Entity.EntityId : null;
    }

    if (this.props.Entity && this.props.Entity.PreventSceneSwitch)
      return;

    let prevPreventedSwitch = false;
    if (curId && curType && prev && prev.Entity && prev.Entity.PreventSceneSwitch) {
      prevPreventedSwitch = true;
    }

    let entityChange = prevPreventedSwitch || ((curId && curId !== prevId) || (curType && curType !== prevType))
    if (entityChange && this.props.EntityMetadata && this.props.EntityMetadata.EntityTypes) {
      this.setScene();
    }

    let sceneKeyEntity = this.props.Entity || prev.Entity;
    if (!curId && prevId) {
      this.props.dispatch(actions.UpdateProp({
        Key: sceneKeyEntity && sceneKeyEntity.SceneKeyOverride ? sceneKeyEntity.SceneKeyOverride : this.props.SceneKey,
        Value: null
      }));
    }

    if (this.props.Refresh) {
      this.setScene();
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_SceneSwitcherRefresh',
        Value: false
      }));
    }
  }

  setScene = () => {
    let type = this.props.EntityMetadata.EntityTypes.find(x => x.TypeId === this.props.Entity.EntityTypeId);
    if (type && type.SceneId) {
      let scene = this.props.Scenes.find(x => x.SceneId === type.SceneId);
      if (scene) {
        let sceneObj = {
          Name: scene.Name,
          Value: scene.Name,
          Enabled: true
        }

        if (this.props.SceneKey === 'blu_ModalScene') {
          sceneObj.MasterScene = this.props.MasterScene.Value;
        }

        if (this.props.SceneKey === 'blu_ModalScene' && this.props.ParentSceneKey && this.props.ParentScene) {
          sceneObj.ParentKey = this.props.ParentSceneKey;
          sceneObj.ParentValue = this.props.ParentScene.Value;
        }

        //ContainsEvent is used in reducer when closing event to know if it needs to null out ent_SelectedEvent
        if (this.props.Entity && this.props.Entity.SceneKeyOverride && this.props.Entity.SceneKeyOverride === 'blu_ModalScene2') {
          sceneObj.ContainsEvent = true;
        }

        batch(() => {
          if (this.props.ResetKey) {
            this.props.dispatch(actions.UpdateProp({
              Key: this.props.ResetKey,
              Value: { Value: this.props.ResetValue, Enabled: true }
            }));
          }

          if (this.props.CounterKey) {
            this.props.dispatch(actions.UpdateProp({
              Key: this.props.CounterKey,
              Value: this.props.CounterValue + 1
            }));
          }

          this.props.dispatch(actions.UpdateProp({
            Key: this.props.Entity.SceneKeyOverride || this.props.SceneKey,
            Value: sceneObj
          }));
        });
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(EntitySceneSwitcher);
