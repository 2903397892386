import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class InfoCardEventHeader extends React.PureComponent {
  render() {
    let props = this.props.entProps;
    let entLabel = this.props.parent.entityLabel();
    let icon = this.props.parent.entityIcon();
    return (
      <div className="info-card-wrapper">
        <div className={"info-card " + this.props.cardForm}>
          <div className="info-card-text">
            {props[0] &&
              <div className="text-1">
                {this.props.parent.props.IconSource && icon &&
                  <div className="info-card-icon">
                    <img className="info-card-img" src={icon}></img>
                  </div>
                }
                <div className={"entity-label" + (!icon ? ' no-icon-label' : '')}>
                  <div>{entLabel}</div>
                </div>
                <div className="entity-name">
                  <div>{helpers.propDisplayValue(props[0], this.props.entity)}</div>
                </div>
              </div>
            }
            {
              props[2] && helpers.propDisplayValue(props[2], this.props.entity) &&
              <div className="date-range">
                <div className="flex-center-contents">
                  <div>{(props[1] ? helpers.propDisplayValue(props[1], this.props.entity) : '') + ' > '}</div>
                </div>
                <div className="flex-center-contents">
                  <div style={{ marginLeft: '3px' }}>{props[2] ? ' ' + helpers.propDisplayValue(props[2], this.props.entity) : ''}</div>
                </div>
              </div>
            }
            {props[3] &&
              <div className="text-3">
                <div>{helpers.propDisplayValue(props[3], this.props.entity)}</div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(InfoCardEventHeader);