import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';

const mapStateToProps = (state, ownProps) => {

  let param;
  if (ownProps.control.AutoFillParams === 'ThisControl') {
    param = ownProps.control;
  } else {
    if (ownProps.overrideParameter && typeof ownProps.overrideParameter !== 'object') {
      let pieces = ownProps.overrideParameter.split('=');
      if (pieces.length === 2) {
        let toReplace = pieces[0].trim();
        ownProps.control.AutoFillParams = ownProps.control.AutoFillParams.replace(new RegExp(toReplace, 'g'), pieces[1].trim());
      }
    }

    if (ownProps.overrideParameter && typeof ownProps.overrideParameter === 'object') {
      param = ownProps.overrideParameter;
    } else {
      param = helpers.getListParameter(state, ownProps.control.AutoFillParams, ownProps.control);
    }
  }

  return {
    SaveData: state.dbo_SaveData,
    Parameter: param,
    ignoreBlur: ownProps.ignoreBlur
  };
}

export class AutoSearch extends React.PureComponent {
  /** @type {undefined | string} */
  defaultValue = undefined;

  lastSearch = '';
  state = {
    hoverIdx: -1
  }

  componentDidMount() {
    this.createKeyNav();
    this.initialQuery();
    this.populateDefaultValue();
  }

  componentWillUnmount() {
    this.destroyKeyNav();
  }

  createKeyNav = () => {
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('mousedown', this.mouseDown);
  }

  destroyKeyNav = () => {
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('mousedown', this.mouseDown);
  }

  mouseDown = (e) => {
    if (this.state.hasFocus && !helpers.elementOrAncestorHasClass(e.target, 'auto-drop-down-items')) {
      this.setState({ hoverIdx: 0 });
      this.selectHighlighted();
    }
    if (this.closeNextClick && !helpers.elementOrAncestorHasClass(e.target, 'auto-drop-down-items')) {
      this.blur();
      this.closeNextClick = false;
    }
  }

  keyDown = (e) => {
    if (!this.state.hasFocus || !this.props.control.DropdownItems || this.props.control.DropdownItems.length === 0)
      return;

    if (e.which === 38) { // up         
      this.updateHover(-1);
      this.scrollIntoView();
      this.selectHighlighted(true);
    }
    if (e.which === 40) { // down      
      this.updateHover(1);
      this.scrollIntoView();
      this.selectHighlighted(true);
    }
    if (e.which === 13 || e.which === 9) { // enter or tab
      this.selectHighlighted();
      this.setState({ hasFocus: false });
    }
  }

  scrollIntoView = () => {
    let element = document.querySelector('.auto-drop-down-item.hover');
    if (element) {
      let parentTop = element.parentNode.scrollTop;
      let parentHeight = element.parentNode.clientHeight;

      if ((parentTop + parentHeight - element.clientHeight) < element.offsetTop) {
        element.parentNode.scrollTop = element.offsetTop - parentHeight + element.clientHeight;
      }
      if (parentTop > element.offsetTop) {
        element.parentNode.scrollTop = element.offsetTop;
      }
    }
  }

  onChange = (e) => {
    this.setState({ hasFocus: true });

    // let isSubset = (this.props.control.Value || '').length > 0 && e.target.value.startsWith(this.props.control.Value);
    this.props.control.Value = null;
    this.props.control.SearchText = e.target.value;
    this.setAutoItem();
    this.forceUpdate();

    // if (isSubset && this.searchComplete && !this.props.control.AlwaysSearch && this.props.control.DropdownItems) {
    //   this.props.control.DropdownItems = this.props.control.DropdownItems.filter(x => !x.Value || x.Value.toLowerCase().startsWith(this.props.control.Value.toLowerCase()));
    //   this.setAutoItem();
    //   this.forceUpdate();
    // } else {
    this.queryItems();
    // }
  }

  initialQuery = () => {
    if (!this.props.control.DoInitialQuery)
      return;

    let body = {
      EMCID: this.props.control.Id,
      Parameter: JSON.stringify({ SearchText: this.props.control.Value, Parameter: this.props.Parameter }),
      SearchText: this.props.control.Value
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      this.searchComplete = true;
      this.props.control.DropdownItems = result;
      if (result.length > 1) {
        result[1].NewEntitySaveData = true;
        this.itemSelected(result[1]);
      }
      if (this.props.Parameter && this.props.Parameter.InitialQueryCallback) {
        this.props.Parameter.InitialQueryCallback();
      }
    });
  }

  queryItems = (fromBtn) => {
    if (this.props.control.IsRO) {
      return;
    }

    if (this.state.hasFocus && fromBtn) {
      this.ignoreNextBlur = true;
    }

    if (!this.state.hasFocus && fromBtn) {
      this.setState({ hasFocus: true });
      this.closeNextClick = true;
    }

    let body = {
      EMCID: this.props.control.Id,
      Parameter: JSON.stringify({ SearchText: this.props.control.SearchText, Parameter: this.props.Parameter }),
      SearchText: this.props.control.SearchText
    }

    this.lastSearch = this.props.control.SearchText;
    this.searchComplete = false;

    actions.ApiRequest('List/GetList', body, (result) => {
      this.searchComplete = true;
      if (body.SearchText === this.lastSearch) {
        this.props.control.DropdownItems = result;
        this.forceUpdate();
        this.setAutoItem();
      }
    });
  }

  setAutoItem = (callback) => {
    let val = (this.props.control.SearchText || '').toLowerCase();
    let items = this.props.control.DropdownItems;

    if (!items) {
      this.setState({ fillText: '', hiddenFill: '', hoverIdx: 0 });
      return;
    }

    let firstValidIdx = items.findIndex(x => {
      return typeof x.Value === 'string' && x.Value.toLowerCase().startsWith(val)
    });
    if (firstValidIdx >= 0) {
      let firstValid = items[firstValidIdx];
      let regex = new RegExp(val, 'i');
      let fillText = firstValid.Value.replace(regex, '');
      fillText = fillText.split('NEWLINE')[0];
      this.setState({ fillText: fillText, hiddenFill: val.split('NEWLINE')[0], hoverIdx: firstValidIdx }, () => { callback && callback() });
    } else {
      this.setState({ fillText: '', hiddenFill: '', hoverIdx: 0 }, () => { callback && callback() });
    }
  }

  itemSelected = (item, keepOpen) => {
    this.props.control.Value = item.Value;
    this.props.control.SearchText = item.Value.replaceAll('NEWLINE', ' ');

    this.setAutoItem();

    if (this.props.control.FailedValidate) {
      this.props.control.FailedValidate = false;
    }

    if (this.props.refresh && !item.RefreshProps)
      this.props.refresh();

    if (!keepOpen) {
      this.setState({ hasFocus: false, fillText: '', hiddenFill: '' });
    }

    if (this.props.trackChanges) {
      batch(() => {
        item.SaveData.forEach((x) => {
          let key = this.props.control.SaveData.InsertKey;
          if (key === 'NEWKEY') {
            key = helpers.getInsertKey();
          }
          if (item.NewEntitySaveData) {
            x.NewEntitySaveData = true;
          }
          let sdItem = {
            Id: this.props.control.SaveData.Id,
            InsertKey: key,
            IsEventData: this.props.isEventSave,
            SaveQueue: this.props.control.SaveData.SaveQueue,
            ...x
          };
          this.props.dispatch(actions.AddSaveData(sdItem));
        })
      });
    }

    if (item.ClearAfterSelect) {
      this.props.control.Value = '';
      this.forceUpdate();
    }

    if (item.RefreshProps) {
      setTimeout(() => {
        this.props.refresh(true);
      }, 0);
      this.ignoreNextBlur = true;
    }

    if (item.UpdateOnClick) {
      this.gobUpdate(item.UpdateOnClick);
    }

    if (item.ResetValueOnSelect && this.props.control.Value) {
      this.itemSelected({ Value: '', SaveData: [] }, false);
    }
  }

  gobUpdate = (updateOnClick) => {
    let items = Array.isArray(updateOnClick) ? updateOnClick : JSON.parse(updateOnClick);
    items.forEach(item => {
      this.props.dispatch(actions.UpdateProp({
        Key: item.Key,
        Value: item.Value
      }));
    });
  }

  blur = () => {
    if (this.ignoreNextBlur) {
      this.ignoreNextBlur = false;
      return;
    }
    setTimeout(() => {
      this.setState({ hasFocus: false, fillText: '', hiddenFill: '' });
      this.setAutoItem(() => {
        if(!this.props.ignoreBlur) this.selectHighlighted();
      })
    }, 0);
  }

  focus = () => {

  }

  populateDefaultValue = async () => {
    /**
     * @type {undefined | string}
     */
    const controlDefaultValueId = this.props.control.DefaultValue;

    if (controlDefaultValueId) {
      const dropDownItems = await new Promise((resolve) => {
        const body = {
          EMCID: this.props.control.Id,
          Parameter: JSON.stringify({
            Parameter: this.props.Parameter,
            SearchText: "%",
          }),
        };
  
        actions.ApiRequest('List/GetList', body, (result) => {
          resolve(result);
        });
      });

      this.defaultValue = dropDownItems
        ?.find(
          (x) => {
            const saveDataValue = x.SaveData?.at(0)?.Value?.toString();
            return saveDataValue === controlDefaultValueId;
          }
        )?.Value;

      if (this.defaultValue)
          this.forceUpdate();
    }
  }

  getPlaceholder = (control) => {
    let result = control.Placeholder;
    if (!result) {
      try {
        let maskOpt = eval('(' + control.DataMaskOptions + ')');
        if (maskOpt && maskOpt.htmlPlaceholder) {
          result = maskOpt.htmlPlaceholder;
        }
      } catch { }
    }

    return result;
  }

  hover = (idx) => {
    this.setState({ hoverIdx: idx });
  }

  updateHover = (delta) => {
    let newIdx = this.state.hoverIdx + delta;
    if (newIdx < 0)
      newIdx = 0;

    if (newIdx >= this.props.control.DropdownItems.length)
      newIdx = this.props.control.DropdownItems.length - 1;

    this.setState({ hoverIdx: newIdx });
  }

  selectHighlighted = (keepOpen) => {
    if (!this.props.control.DropdownItems)
      return;

    let item = this.props.control.DropdownItems[this.state.hoverIdx];
    if (!item)
      return;

    this.itemSelected(item, keepOpen);
  }

  clickFocus = () => {
    if (!this.state.hasFocus) {
      this.focus();
    }
  }

  render() {
    let control = this.props.control;
    let controlVal = null;
    if (control) {
      controlVal = control.Value;
      if (control.Value && control.Value.indexOf('NEWLINE') != -1) {
        controlVal = control.Value.split('NEWLINE')[0];
      }
    }

    return (
      <div className="autodropdown-control">
        <div className="control-label">
          {control.Label}
          {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
        </div>
        <div className={"input-container" + (control.FailedValidate ? " failed-validate" : "")}>
          <input
            className="chrome-hide-field"
            autoComplete="off"
            disabled={control.IsRO}
            maxLength={control.MaxValue ? control.MaxValue : null}
            id={'control2-' + control.Id}
            onClick={this.clickFocus}
            onBlur={this.blur}
            onFocus={this.focus}
            type={'text'}
            value={control.Value || ''}
            placeholder={this.getPlaceholder(control)}
            onChange={this.onChange}
          />
          <input
            autoComplete="off"
            disabled={control.IsRO}
            maxLength={control.MaxValue ? control.MaxValue : null}
            id={'control-' + control.Id}
            onClick={this.clickFocus}
            onBlur={this.blur}
            onFocus={this.focus}
            type={'text'}
            value={controlVal || control.SearchText || this.defaultValue || ''}
            placeholder={this.getPlaceholder(control)}
            onChange={this.onChange}
          // tabIndex={control.Location && control.Location.ControlOrder ? control.Location.ControlOrder : 0}
          />
          <div className="fill-text">
            <div className="hidden-fill">{this.state.hiddenFill}</div>
            <div className="show-fill">{this.state.fillText}</div>
          </div>
          {this.props.ShowChevron && <div className="auto-dropdown-chevron" onClick={() => { this.queryItems(true) }}>
            <div>›</div>
          </div>}
        </div>
        {this.state.hasFocus && this.props.control.DropdownItems &&
          <div className="auto-drop-down-items">
            {this.props.control.DropdownItems.map((x, idx) => (
              <div key={idx} className={"auto-drop-down-item" + (idx === this.state.hoverIdx ? ' hover' : '') + (x.Value && x.Value.includes('NEWLINE') ? ' multiline' : '')} onMouseDown={() => { this.itemSelected(x) }} onMouseEnter={() => { this.hover(idx) }} >
                {
                  x.Value.split('NEWLINE').map((line, lineIdx) => (
                    <div key={lineIdx}>{line}</div>
                  ))
                }
              </div>
            ))}
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(AutoSearch);