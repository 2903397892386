import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { selector } from '../../../../Stores/Reducers/reducer';
import WidgetLoader from '../../../WidgetLoader';
import GuideLines from './../../FullEditModeWidget/GuideLines';
import PersistedWidgets from './../../../PersistedWidgets';

const mapStateToProps = (state, ownProps) => {
  let initialScene = ownProps.widget.Parameters.find(x => x.ParameterName === 'InitialScene');
  let sceneKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'SceneKey');
  let sceneLabel = ownProps.widget.Parameters.find(x => x.ParameterName === 'SceneLabel');

  //if a key was specified and redux doesn't know about it, define it
  if (sceneKey && initialScene && !state[sceneKey.ParameterValue]) {
    state[sceneKey.ParameterValue] = {
      Label: sceneLabel ? sceneLabel.ParameterValue : '',
      Value: initialScene.ParameterValue,
      Enabled: true
    }
  }

  let scene = ((sceneKey && sceneKey.ParameterValue) || (initialScene && initialScene.ParameterValue)) ? selector.SceneByName(state, (sceneKey && state[sceneKey.ParameterValue] && state[sceneKey.ParameterValue].Value) || (initialScene && initialScene.ParameterValue)) : undefined;

  if (scene && sceneKey && state[sceneKey.ParameterValue]) {
    state[sceneKey.ParameterValue].Id = scene.SceneId;
  }

  return {
    Scene: scene,
    FullEditSubScene: state.FullEditMode && state.FullEditSubScene && state.FullEditSubScene.SWID === ownProps.widget.SceneWidgetId
  };
};

export class SubScene extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }
  formatRowCols = (val) => {
    let result = val;
    if (!isNaN(Number(result)) && Number(result).toString() === result.toString()) {
      result = 'repeat(' + result + ', 1fr)';
    }

    return result;
  }

  render() {
    let scene = this.props.Scene;
    let rows = scene ? this.formatRowCols(scene.Rows) : null;
    let cols = scene ? this.formatRowCols(scene.Columns) : null;
    return (
      <div className={"sub-scene " + (this.props.FullEditSubScene ? 'full-edit-subscene' : '')}>
        {this.props.Scene &&
          <div ref={this.container} className={scene.Rows === 1 && scene.Columns === 1 ? 'sub-scene-flex' : 'grid'}
            style={{ gridTemplateRows: rows, gridTemplateColumns: cols }}>
            {/* {this.props.ActiveWidgets && <PersistedWidgets mySwids={this.props.Scene.Widgets.map(x => x.SceneWidgetId)} />} */}
            {this.props.Scene.Widgets.map((item) => (
              <WidgetLoader key={item.SceneWidgetId} widget={item} container={this.container.current} showSubSceneHandle={this.props.FullEditSubScene} />
            ))}
            {this.props.FullEditSubScene && rows.split(' ').map((rval, rIdx) => (
              <>
                {cols.split(' ').map((cval, cIdx) => (
                  <GuideLines key={rIdx + '-' + cIdx} rowName={rval} colName={cval} row={rIdx} col={cIdx} />
                ))}
              </>
            ))}
          </div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(SubScene);