import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { batch } from 'react-redux';

export const getLayoutObject = () => {
  let layout = {
    filters: window.agGridApi.gridApi.getFilterModel(),
    columns: window.agGridApi.columnApi.getColumnState(),
    columnDefs: window.agGridApi.columnDefs
  }
  console.log(layout);
  return layout;
}
