import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../../Utils/Helpers';
import { MagnifyingGlass } from '../../../../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class ApiCall extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="trigger-specific-props"></div>
        <div className="side-bar-prop">
          <div>Endpoint:</div>
          <input type="text" value={this.props.state.Text1 || ''} onChange={(e) => { this.props.setProp(e, 'Text1') }} />
        </div>
        <div className="side-bar-prop">
          <div>Parameter Gob Key:</div>
          <input type="text" value={this.props.state.Text2 || ''} onChange={(e) => { this.props.setProp(e, 'Text2') }} />
        </div>
        <div className="side-bar-prop">
          <div>Results Gob Key:</div>
          <input type="text" value={this.props.state.Text3 || ''} onChange={(e) => { this.props.setProp(e, 'Text3') }} />
        </div>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(ApiCall);