import React, { PureComponent } from 'react'
import ModeSelector from '../ModeSelector/ModeSelector';
import { Clear, Done } from '../../../../../../Icons/Icons';
import * as helpers from './../../../../../../../Utils/Helpers';

export default class LookupField extends PureComponent {
  toggleLookup = (idx) => {
    this.props.parent.toggleLookupItem(idx);
  }

  getLookupItems = () => {
    let parent = this.props.parent;

    if (parent.props.Filter.LookupItems)
      return parent.props.Filter.LookupItems;

    let lookupItems = helpers.getLookupItems(parent.props.settings.LookupSetId).map(x => {
      if (x.Name1 === '') {
        return { ...x, Name1: '(Empty)', Enabled: true, Empty: true }
      }

      return { ...x, Enabled: true };
    });

    let options = [{ Name1: '(Toggle All)', ToggleAll: true, Enabled: true }, ...lookupItems];
    parent.setLookupItems(options);

    return options;
  }

  render() {
    let parent = this.props.parent;
    let myOptions = this.props.myOptions;
    let mode = this.props.mode;
    let dataType = this.props.dataType;
    let options = this.getLookupItems();
    let lookupQty = options.filter(x => !x.ToggleAll && !x.Empty).length;
    let enabledQty = options.filter(x => !x.ToggleAll && !x.Empty && x.Enabled).length;

    return (
      <div className="lookup-fields-container">
        {
          <div className={'type-filter text-filter filter-col-' + parent.props.settings.ColumnId}>
            <div className="flex-box">
              <div className="filter-helper-text" onClick={parent.showFilterToggle}>
                <div>
                  {enabledQty + ' of ' + lookupQty}
                </div>
              </div>
            </div>
            {
              parent.state.ShowFilters &&
              <div className="lookup-filter-container">
                <div className="scrollbox">
                  {options.map((x, idx) => (
                    <div className="lookup-filter-option" key={idx}>
                      <input type="checkbox" checked={x.Enabled} onChange={() => { this.toggleLookup(idx) }} />
                      <div className="lookup-filter-label">{x.Name1}</div>
                    </div>
                  ))}
                </div>
              </div>
            }
            {
              parent.isDirty() && this.props.parent.state.Editing &&
              <div className="filter-done" onClick={parent.confirmFilter}>
                <Done />
              </div>
            }
            <div className="filter-clear" onClick={parent.clearFilter}>
              <Clear />
            </div>
          </div>
        }
      </div>
    )
  }
}


