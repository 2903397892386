import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import FormControl from './../../Form/v001/FormControl';

const mapStateToProps = (state, ownProps) => {
  let controlType = ownProps.widget.Parameters.find(x => x.ParameterName === 'ControlType');
  let defaultValue = ownProps.widget.Parameters.find(x => x.ParameterName === 'DefaultValue');
  let controlName = ownProps.widget.Parameters.find(x => x.ParameterName === 'ControlName');
  let controlProps = ownProps.widget.Parameters.find(x => x.ParameterName === 'ControlProps');
  let label = ownProps.widget.Parameters.find(x => x.ParameterName === 'Label');

  let dft = defaultValue ? defaultValue.ParameterValue : '';
  let _helper = helpers;

  if (defaultValue && defaultValue.ParameterValue.toLowerCase().startsWith('compute(')) {
    let toCompute = dft.trim().replace('compute(', '').slice(0, -1);
    try {
      dft = eval(toCompute);
    } catch (e) {
      console.log({ ComputeError: e });
    }
  }

  let ctrlProps = {};
  if (controlProps && controlProps.ParameterValue) {
    try {
      ctrlProps = JSON.parse(controlProps.ParameterValue);
    } catch (e) {
      console.log({ ControlPropsError: e });
    }
  }

  return {
    ControlType: controlType ? controlType.ParameterValue : null,
    DefaultValue: dft,
    ControlName: controlName ? controlName.ParameterValue : null,
    Label: label ? label.ParameterValue : '',
    Controls: state.Controls || {},
    SWID: ownProps.widget.SceneWidgetId,
    ControlProps: ctrlProps
  };
}

let _controlWidgets = {}

export class Control extends React.PureComponent {


  componentDidMount() {
    if (!_controlWidgets[this.props.SWID]) {
      _controlWidgets[this.props.SWID] = {
        Value: this.props.DefaultValue || '',
        Label: this.props.Label,
        ControlType: this.props.ControlType,
        SaveData: {}
      }
      this.updateValue();
    }
  }

  refresh = () => {
    this.updateValue();
  }

  updateValue = () => {
    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'Controls',
        Value: {
          ...this.props.Controls,
          [this.props.ControlName]: _controlWidgets[this.props.SWID].Value
        }
      }));
    }, 0);
  }

  render() {
    if (!this.props.ControlType || !this.props.ControlName || !_controlWidgets[this.props.SWID])
      return null;

    _controlWidgets[this.props.SWID] = {
      ..._controlWidgets[this.props.SWID],
      ...this.props.ControlProps
    };

    return (
      <div className={"control-widget"}>
        <FormControl
          control={_controlWidgets[this.props.SWID]}
          trackChanges={false}
          isEventSave={false}
          refresh={this.refresh} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(Control);