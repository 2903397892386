import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash/cloneDeep';
import { Clear } from './../../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let gc = state.qry_GroupedColumns;
  return {
    Hover: state.qry_GroupByHover,
    GroupedColumns: gc ? gc[ownProps.QueryId] : undefined,
    SelectedQuery: state.qry_SelectedQuery,
    ResetTop: state.qry_ResetTop || 1,
    Query: state.qry_Queries[ownProps.QueryId],
    RefreshCurrentPage: state.qry_RefreshCurrentPage,
    AllGroups: state.qry_GroupedColumns
  };
};

export class GroupByHeader extends React.PureComponent {
  componentDidUpdate(prev) {
    if (!this.props.GroupedColumns)
      return;

    let groupedCols = this.props.GroupedColumns;

    if (helpers.propDidChange(this.props, prev, 'RefreshCurrentPage')) {
      groupedCols.forEach((x) => {
        x.GroupedResults = undefined;
        x.RowSourceItems = undefined;
      });
      this.props.Query.rows = this.props.Query.rows.filter(x => !x.GroupByRow);
    }

    if (groupedCols && groupedCols.length > 0) {
      let needsGrouping = groupedCols.filter(x => !x.GroupedResults);

      if (needsGrouping.length > 0) {
        this.props.Query.allIds.forEach(x => {
          this.props.Query[x].IsGrouped = needsGrouping.map(y => y.ColumnId).includes(this.props.Query[x].ColumnId);
        });

        let body = {
          QueryId: this.props.QueryId,
          Columns: this.props.Query.allIds.map((x, idx) => {
            let queryObj = this.props.Query[x];
            let queryFilter = helpers.parseFilter(queryObj);
            return {
              ColumnName: queryObj.ColumnName,
              TableName: queryObj.TableName,
              IsVisible: queryObj.IsVisible,
              DisplayOrder: queryObj.DisplayOrder,
              Filter: queryFilter,
              IsGrouped: queryObj.IsGrouped,
              Aggregate: {}
            };
          })
        }

        needsGrouping.forEach(x => x.GroupedResults = []);

        actions.ApiRequest('Query/GetGrouping', body, (result) => {
          helpers.alignGridHeaders();
          needsGrouping[0].GroupedResults = result.Results.map(x => {
            x.Expand = false;
            return x;
          });
          let rowCount = 0
          result.Results.forEach(x => rowCount += Number(x[0]));

          let newQuery = cloneDeep(this.props.SelectedQuery);
          newQuery.IsGrouped = true;
          batch(() => {
            this.props.dispatch(actions.SetRowCount({
              queryId: this.props.QueryId,
              rowCount: rowCount
            }));
            this.props.dispatch(actions.ResetRows());
            this.props.dispatch(actions.SetTopRow({
              topRow: 0
            }));
            this.props.dispatch(actions.UpdateProp({
              Key: 'qry_SelectedQuery',
              Value: newQuery
            }));
            this.props.dispatch(actions.UpdateProp({
              Key: 'qry_ResetTop',
              Value: this.props.ResetTop + 1
            }));
          });
        });
      }
    }
  }

  removeGroup = () => {
    let existing = this.props.AllGroups;

    this.props.Query.allIds.map((x) => {
      this.props.Query[x].GroupedResults = undefined;
      this.props.Query[x].RowSourceItems = undefined;
    });

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_GroupedColumns',
        Value: {
          ...existing,
          [this.props.QueryId]: []
        }
      }));
      this.props.dispatch(actions.ResetRows());
      this.props.dispatch(actions.SetTopRow({
        topRow: 0
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_ResetTop',
        Value: this.props.ResetTop + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_GroupByHover',
        Value: false
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_RefreshCurrentPage',
        Value: this.props.RefreshCurrentPage + 1
      }));
    });
  }

  render() {
    let gc = this.props.GroupedColumns
    let expand = !!(this.props.Hover || (gc && gc.length > 0));
    let columnText = gc && gc[0] ? gc[0].Label : '';
    return (
      <div className={"group-by header-width" + (expand ? ' expand' : '')}>
        {gc && gc.length > 0 && <div onClick={this.removeGroup} className="clear-group-by"><Clear /></div>}
        {gc && gc.length > 0 && <div>{'Group By: ' + columnText}</div>}
      </div>
    )
  }
}

export default connect(mapStateToProps)(GroupByHeader);