import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as hub from './../../../../Utils/PubSub';
import bhFilter from './Filter';
import bhFloatingFilter from './FloatingFilter';
import DropDownEditor from './Editors/DropDown';
import FormControlCell from './Editors/FormControlCell';

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let tableName = ownProps.widget.Parameters.find(x => x.ParameterName === 'TableName');
  let parameterRequired = ownProps.widget.Parameters.find(x => x.ParameterName === 'ParameterRequired');
  let refreshBucketsOnSave = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshBucketsOnSave');


  let swid = ownProps.widget.SceneWidgetId;
  let dataKey = '_agTable' + swid;
  return {
    Parameter: parameter ? helpers.getListParameter(state, parameter.ParameterValue) : null,
    TableName: tableName ? tableName.ParameterValue : null,
    TableData: state[dataKey],
    DataKey: dataKey,
    SWID: swid,
    SaveId: state.dbo_SaveId,
    CancelId: state.dbo_CancelId,
    DropdownBuckets: state.met_DropdownBuckets,
    ParameterRequired: parameterRequired ? helpers.stringToBool(parameterRequired.ParameterValue) : false,
    RefreshBucketsOnSave: refreshBucketsOnSave ? helpers.stringToBool(refreshBucketsOnSave.ParameterValue) : false,
    BucketRefreshId: state.BucketRefreshId
  };
}

let _saveId = null;

export class AgTable extends React.PureComponent {
  state = {
    showGrid: false,
    defaultColDef: {
      editable: false,
      filter: false,
      floatingFilterComponent: bhFloatingFilter,
      filter: bhFilter,
      floatingFilter: false,
      menuTabs: [],
      suppressKeyboardEvent: (params) => {
        return this.suppressNavigation(params);
      }
    },
    groupRowRendererParams: {
      innerRenderer: (data) => {
        let coldef = data.columnApi.columnModel.columnDefs.find(x => x.field = data.node.field && x.rowGroup);
        if (coldef && coldef.valMap) {
          return coldef.valMap[data.value] || null;
        }
        return data.value || null;
      }
    },
    gridOptions: {
      rowClassRules: {
        "deleted-row": (params) => {
          return params.data && params.data['*IsDeleted'];
        }
      },
      debounceVerticalScrollbar: true,
      showLoadingOverlay: false,
      rowHeight: 40,
      rowBuffer: 20,
      suppressMenuHide: true,
      headerHeight: 45,
      // floatingFiltersHeight: 25,
      editType: 'fullRow',
      deltaRowDataMode: true,
      getRowId: (e) => {
        return e.data.agId;
      },
      getRowNodeId: (e) => {
        return e.data.agId;
      }
    }
  }

  componentDidMount() {
    if (_saveId !== null && _saveId !== this.props.SaveId && this.props.RefreshBucketsOnSave) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'RefreshDropDownBuckets',
        Value: true
      }));
    }

    _saveId = this.props.SaveId;

    if (this.props.TableData) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.DataKey,
        Value: null
      }))
    } else {
      this.getData();
    }
  }

  componentDidUpdate(prev) {
    if (this.props.RefreshBucketsOnSave && prev.SaveId !== this.props.SaveId) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'RefreshDropDownBuckets',
        Value: true
      }));
    }

    if (prev && this.props.BucketRefreshId !== prev.BucketRefreshId) {
      if (this.gridApi) {
        this.gridApi.refreshCells({ force: true });
      }
    }

    if (!this.props.TableData && !this.state.showGrid) {
      this.getData();
    }
    else if (prev && (prev.SaveId !== this.props.SaveId || prev.CancelId !== this.props.CancelId)) {
      this.getData();
    }
    else if (prev && prev.Parameter && JSON.stringify(prev.Parameter) !== JSON.stringify(this.props.Parameter)) {
      this.getData();
    }
  }

  resetData = () => {
    if (!this.props.TableData)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.DataKey,
      Value: { ...this.props.TableData, Rows: [] }
    }))
  }

  getData = () => {
    if (this.props.ParameterRequired && !this.props.Parameter)
      return;

    let body = {
      Name: this.props.TableName,
      Parameter: JSON.stringify(this.props.Parameter || {})
    }

    actions.ApiRequest('List/AgTableData', body, (result) => {
      if (!result)
        return;

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.DataKey,
        Value: this.parseResponse(result)
      }))
      setTimeout(() => {
        if (this.gridApi)
          this.gridApi.refreshCells({ force: true });

        this.setState({ showGrid: true });
      }, 0);
    });
  }

  parseResponse = (data) => {
    return {
      Rows: data.Rows.map((x, idx) => { return { ...x, agId: idx } }),
      ColumnDefs: this.props.TableData ? this.props.TableData.ColumnDefs : this.parseColumnDefs(data),
      ColumnMetadata: data.Columns,
      SaveQueue: data.SaveQueue,
    };
  }

  parseColumnDefs = (data) => {
    let colWidths = data.ColumnWidths ? data.ColumnWidths.split(' ').map(x => x.trim()) : [];
    let colIdx = 0;
    return data.Columns.map((col) => {
      let coldef = {
        field: col.Field,
        editable: false,
        ControlType: col.ControlType,
        LookupSetId: col.LookupSetId,
        Table: col.SourceTable,
        Column: col.SourceColumn,
        headerName: col.ColumnName,
        cellEditorPopup: false,
        TableNoSchema: col.TableName,
        SaveQueue: data.SaveQueue,
        flex: 1
      }

      if (col.IsGrouped) {
        coldef.rowGroup = true;
        coldef.hide = true;
        colIdx--;
      }

      if (col.IsEditable) {
        coldef.cellRenderer = FormControlCell;
      }

      if (col.ControlType === 'BucketDropDown') {
        let bucket = this.props.DropdownBuckets.find(x => x.Id == coldef.LookupSetId);
        if (bucket) {
          coldef.valMap = bucket.BucketData.reduce((prev, next) => {
            prev[next.Key] = next.Value;
            return prev;
          }, {});
        }
      }

      if (col.ControlType === 'DropDown') {
        coldef.valMap = helpers.getLookupItems(coldef.LookupSetId).reduce((prev, next) => {
          prev[next.LookupItemId] = next.Name1;
          return prev;
        }, {});
      }

      if (col.ControlType === 'IsDeleted') {
        coldef.width = 50;
        delete coldef.flex;
      }

      let width = colWidths[colIdx];
      if (width) {
        if (width.includes('fr')) {
          coldef.flex = Number(width.replace('fr', ''));
        }
        if (width.includes('px')) {
          coldef.width = Number(width.replace('px', ''));
          delete coldef.flex;
        }
      }

      colIdx++;
      return coldef;
    });
  }

  suppressNavigation = (params) => {
    var KEY_TAB = 'Tab';
    var KEY_DOWN = 'ArrowDown';
    var KEY_UP = 'ArrowUp';

    var event = params.event;
    var key = event.key;

    var keysToSuppress = [
      KEY_TAB,
      KEY_UP,
      KEY_DOWN
    ];

    var suppress = keysToSuppress.some(function (suppressedKey) {
      return suppressedKey === key || key.toUpperCase() === suppressedKey;
    });

    return suppress;
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  render() {
    return (
      !this.props.TableData || !this.state.showGrid ? null :
        <div className="ag-table-widget ag-theme-alpine">
          <AgGridReact
            columnDefs={this.props.TableData.ColumnDefs}
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}
            rowData={this.props.TableData.Rows}
            gridOptions={this.state.gridOptions}
            groupDisplayType={'groupRows'}
            groupRowRendererParams={this.state.groupRowRendererParams}
            rememberGroupStateWhenNewData={true}
          />
        </div>
    );
  }
}

export default connect(mapStateToProps)(AgTable);





