import React from 'react';
import { connect } from 'react-redux';
import { PushPin } from '../../../../Icons/Icons';
import * as actions from './../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class ColumnLabel extends React.PureComponent {
  initialX = null;
  widthOnClick = null;

  togglePin = (e) => {
    e.stopPropagation();
    this.props.dispatch(actions.ToggleColumnPin(this.props.settings.ColumnId));
  }

  startResize = (e) => {
    e.preventDefault();
    this.initialX = e.screenX;
    this.widthOnClick = this.props.settings.Width;
    window.addEventListener('mousemove', this.dragMove);
    window.addEventListener('mouseup', this.mouseUp);
  }

  dragMove = (e) => {
    let offset = e.screenX - this.initialX;
    this.props.setWidth(this.widthOnClick + offset);
  }

  mouseUp = (e) => {
    window.removeEventListener('mousemove', this.dragMove);
    window.removeEventListener('mouseup', this.mouseUp);
  }

  setSortBy = (e) => {
    this.props.setSortBy(e);
  }

  render() {
    let settings = this.props.settings;
    return (
      <div className={'column-label ' + (settings.hoverBlade ? 'blade ' + settings.hoverBlade : '')}>
        <div className="label-text" onClick={this.setSortBy}>{settings.Label}</div>
        {this.props.sorted && <div className="triangle"></div>}
        <div className={"col-pin " + (settings.IsPinned ? 'pinned' : '')} onMouseDown={this.togglePin}>
          <PushPin />
        </div>
        <div className='resize-handle' onMouseDown={this.startResize}></div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ColumnLabel);