import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { CircleX, TriangleLeft, TriangleRight } from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let type = ownProps.widget.Parameters.find(x => x.ParameterName === 'Type'); //Options: 'MySalesman', finishImageGrouping() checks this for what to do with the selected images

  return {
    SWID: ownProps.widget.SceneWidgetId,
    Type: type ? type.ParameterValue : null,
    CurrentEvent: state.ent_CurrentEvent,
    EventSave: state.btn_EventSave,
    SelectedPolygons: state.ms_SelectedPolygons,
    ActiveModifyId: state.ms_ActiveModifyId,
    CurrentCatalog: state.ms_CurrentCatalog,
    CatalogSelected: state.ms_CatalogSelected,
    ActiveConsumerId: state.ms_ActiveConsumerId,
    NextButton: state.btn_ImageCatalogNext,
    SaveId: state.dbo_SaveId,
    EditModMeasure: state.ms_EditModMeasure
  };
};

export class ImageCatalog extends React.PureComponent {
  //this.state.FilteredData: current state of image grouping
  //this.state.Data: image grouping with no selectedRows active
  //this.state.SelectedRows: array with length of rows, null values meaning none selected
  state = {
    FilteredData: [[]],
    Completed: false
  }

  componentDidMount() {
    let inputData = [[], [], [], []];
    let labels = [];
    let type = this.props.CurrentCatalog[0].Type;
    switch (type) {
      case 'fence_Fence':
        labels[0] = 'Type';
        labels[1] = 'Style';
        labels[2] = 'Color';
        labels[3] = 'Height';
        break;
      case 'powerwash_Area':
        labels[0] = 'Service';
        labels[1] = 'Type';
        labels[2] = 'Wash';
        break;
      case 'powerwash_Vertical':
        labels[0] = 'Service';
        labels[1] = 'Type';
        labels[2] = 'Height';
        labels[3] = 'Wash';
        break;
      case 'powerwash_Roof':
        labels[0] = 'Type';
        labels[1] = 'Wash';
        break;
      case 'deck_Deck':
        labels[0] = 'Type';
        labels[1] = 'Style';
        labels[2] = 'Color';
        labels[3] = 'Height';
        break;
      case 'deck_Stain':
        labels[0] = 'Condition';
        labels[1] = 'Color';
        labels[2] = 'Height';
        break;
      case 'roof_Roof':
        labels[0] = 'Type';
        labels[1] = 'Material';
        labels[2] = 'Height';
        labels[3] = 'Pitch';
        break;
    }

    let catalog = this.props.CurrentCatalog.slice();
    catalog[0].Item1Label = labels[0];
    catalog[0].Item2Label = labels[1];
    catalog[0].Item3Label = labels[2];
    catalog[0].Item4Label = labels[3];
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_CurrentCatalog',
      Value: catalog
    }));

    this.props.CurrentCatalog.map(x => {
      if(!x.isDeleted) {
        if (x.ItemId1) {
          inputData[0].push({ ...x, Name: x.Item1Name, Column: 'ItemId1', Title: labels[0], Image: 'https://bhpublic.blob.core.windows.net/imagepicker/' + x.EntityTypeId1 + '/' + x.ItemId1 + '/Item' });
        }
        if (x.ItemId2) {
          inputData[1].push({ ...x, Name: x.Item2Name, Column: 'ItemId2', Title: labels[1], Image: 'https://bhpublic.blob.core.windows.net/imagepicker/' + x.EntityTypeId1 + '/' + x.ItemId2 + '/Item' });
        }
        if (x.ItemId3) {
          inputData[2].push({ ...x, Name: x.Item3Name, Column: 'ItemId3', Title: labels[2], Image: 'https://bhpublic.blob.core.windows.net/imagepicker/' + x.EntityTypeId1 + '/' + x.ItemId3 + '/Item' });
        }
        if (x.ItemId4) {
          inputData[3].push({ ...x, Name: x.Item4Name, Column: 'ItemId4', Title: labels[3], Image: 'https://bhpublic.blob.core.windows.net/imagepicker/' + x.EntityTypeId1 + '/' + x.ItemId4 + '/Item' });
        }
      }
    })

    let selectedRows = [null, null, null, null];
    if (this.props.CatalogSelected) { //edit existing selection
      selectedRows[0] = this.props.CatalogSelected[0];
      selectedRows[1] = { ...this.props.CatalogSelected[0], Name: this.props.CatalogSelected[0].Item2Name, Column: 'ItemId2', Title: 'Style', Image: 'https://beehivetilcache.blob.core.windows.net/staticassets/Maintenance/coming_soon2.png' };
      selectedRows[2] = { ...this.props.CatalogSelected[0], Name: this.props.CatalogSelected[0].Item3Name, Column: 'ItemId3', Title: 'Color', Image: 'https://beehivetilcache.blob.core.windows.net/staticassets/Maintenance/coming_soon2.png' };
      selectedRows[3] = { ...this.props.CatalogSelected[0], Name: this.props.CatalogSelected[0].Item4Name, Column: 'ItemId4', Title: 'Height', Image: 'https://beehivetilcache.blob.core.windows.net/staticassets/Maintenance/coming_soon2.png' };
    }
    //load rows in based on tiered structures for fence
    this.setState({ Data: inputData, FilteredData: inputData, SelectedRows: Array.apply(null, Array(inputData.length)) }, () => this.groupingFilter(selectedRows, null));
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_ImageCatalogComplete',
      Value: false
    }));
  }

  componentDidUpdate(prev) {
    this.checkCompleted();
    this.setControlArrows();
    if (this.props.NextButton && this.state.Completed) {
      this.finishImageGroup();
      this.props.dispatch(actions.UpdateProp({
        Key: 'btn_ImageCatalogNext',
        Value: false
      }));
    }
  }

  checkCompleted = () => {
    if (this.state.FilteredData) {
      let completed = true;
      this.state.FilteredData.forEach(x => {
        if (x.length > 1) {
          completed = false;
        }
      })
      this.setState({ Completed: completed });

      if (completed) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_ImageCatalogComplete',
          Value: true
        }));
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_ImageCatalogComplete',
          Value: false
        }));
      }

    }
  }

  setControlArrows = () => {
    let rows = document.getElementsByClassName('image-grouping-row-picker');
    for (let x of rows) {
      if (x.offsetWidth !== x.scrollWidth) {
        x.nextElementSibling.style.zIndex = 2;
        x.previousElementSibling.style.zIndex = 2;
      } else {
        x.nextElementSibling.style.zIndex = -1;
        x.previousElementSibling.style.zIndex = -1;
      }
    }
  }

  ItemClicked = (row, item) => {
    //make a check to any other filters, if they were to be removed would the list size change? If no : remove it
    let items = this.state.FilteredData.slice();
    let rowIndex = items.indexOf(row);
    let selectedRows = this.state.SelectedRows.slice();
    selectedRows[rowIndex] = item;
    this.groupingFilter(selectedRows);
  }

  clearSelection = (row) => {
    let filteredData = this.state.FilteredData.slice();
    let selectedRows = this.state.SelectedRows;
    let rowIndex = filteredData.indexOf(row);
    selectedRows[rowIndex] = undefined;
    this.groupingFilter(selectedRows, true);
  }

  groupingFilter = (selectedRows, isClear = false) => {
    let ogData = this.state.Data.slice();
    let filters = [];

    selectedRows.forEach(x => {
      if (x) {
        switch (selectedRows.indexOf(x)) {
          case 0:
            filters.push(x.ItemId1);
            break;
          case 1:
            filters.push(x.ItemId2);
            break;
          case 2:
            filters.push(x.ItemId3);
            break;
          case 3:
            filters.push(x.ItemId4);
            break;
        }
      } else {
        filters.push(undefined);
      }
    })

    let filteredData = ogData.map(dat => {
      return dat.filter(x => (!x.IsDeleted && (x.ItemId1 === filters[0] || !filters[0]) && (x.ItemId2 === filters[1] || !filters[1]) && (x.ItemId3 === filters[2] || !filters[2]) && (x.ItemId4 === filters[3] || !filters[3])))
    });

    //animations
    if (isClear) {
      this.setState({ SelectedRows: selectedRows, FilteredData: filteredData });
      document.querySelectorAll(".image-grouping-row-picker").forEach(x => x.classList.add('image-catalog-deselect'));
      setTimeout(() => {
        document.querySelectorAll(".image-grouping-row-picker").forEach(x => x.classList.remove('image-catalog-deselect'));
      }, 300)
    } else if (isClear == null) {
      this.setState({ SelectedRows: selectedRows, FilteredData: filteredData });
    } else {
      document.querySelectorAll(".image-grouping-cell-container").forEach(x => x.classList.add('image-catalog-select'));
      setTimeout(() => {
        document.querySelectorAll(".image-grouping-cell-container").forEach(x => x.classList.remove('image-catalog-select'));
        this.setState({ SelectedRows: selectedRows, FilteredData: filteredData });
      }, 250)
    }
  }

  clearAllSelection = () => {
    let newSelectedRows = Array.apply(null, Array(this.state.SelectedRows.length));
    this.groupingFilter(newSelectedRows, true);
  }

  leftArrow = (e) => {
    if (e.target && e.target.nextElementSibling) {
      e.target.nextElementSibling.scrollTo({ left: e.target.nextElementSibling.scrollLeft - 300, behavior: 'smooth' });
    }
  }

  rightArrow = (e) => {
    if (e.target && e.target.previousElementSibling) {
      e.target.previousElementSibling.scrollTo({ left: e.target.previousElementSibling.scrollLeft + 300, behavior: 'smooth' });
    }
  }

  finishMySalesmanImageGroup = () => {
    const search = window.location.search
    let selectedPolygons = this.props.SelectedPolygons.slice();
    let imageGroup = [];
    this.state.FilteredData.forEach(x => {
      imageGroup.push(x[0]);
    })
    let selectedPolygon = selectedPolygons.find(x => x.ol_uid === this.props.ActiveModifyId);
    if(search.includes("&consumerId=") && !selectedPolygon && this.props.EditModMeasure) {
      selectedPolygon = selectedPolygons.find(x => x.Measurement === this.props.EditModMeasure);
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_EditModMeasure',
        Value: null
      }));
    }
    if (!selectedPolygon) { selectedPolygon = []; }
    // let selectedPolygon = selectedPolygons[this.props.ActiveModifyId - 1];

    selectedPolygon.CatalogSelected = imageGroup;
    selectedPolygon.CatalogOptions = this.props.CurrentCatalog;

    if (selectedPolygon.Type) {
      let text1 = selectedPolygon.Type.split("_").pop() + ': ' + imageGroup[0].Item1Name;
      if (imageGroup[0].Item2Name) {
        text1 += ', ' + imageGroup[0].Item2Name;
      }
      if (imageGroup[0].Item3Name) {
        text1 += ', ' + imageGroup[0].Item3Name;
      }
      if (imageGroup[0].Item4Name) {
        text1 += ', ' + imageGroup[0].Item4Name;
      }
      selectedPolygon.Text1 = text1;
    }

    if(selectedPolygon.planId) {
      //disable finish button while saving
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_Saving',
        Value: true
      }))

      selectedPolygon = this.savePlanItems(selectedPolygon);

      // this.props.dispatch(actions.UpdateProp({
      //   Key: 'ms_SelectedPolygons',
      //   Value: selectedPolygons
      // }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_ActiveModifyId',
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_CatalogSelected',
        Value: null
      }));
  
      if (!window.IsMobile) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'MainTabContent',
          Value: { Value: 'TabOneScene', Enabled: true }
        }));
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: 'MainTabContent',
          Value: { Value: 'Step3Panel', Enabled: true }
        }));
      }
    }
  }

  savePlanItems = (item) => {
    const search = window.location.search
    const origModifyId = search.split("&consumerId=")
    let saveData = [];
    if(search.includes("&consumerId=") && (parseInt(origModifyId[1]) === parseInt(this.props.ActiveConsumerId))) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_CatalogPoly',
        Value: item
      }))
    } else {
      let firstSave = item.planItemId ? false : true;

      let planItemBaseObj = {
        Id: item.planItemId,
        Table: 'xmv.PlanItem',
        EntityTypeId: 2098,
        IsBaseTable: true
      }
      if (firstSave) {
        planItemBaseObj.Id = null;
        planItemBaseObj.InsertKey = helpers.getInsertKey();
        saveData.push(helpers.saveDataItem(planItemBaseObj, 'PlanId', item.planId));
        saveData.push(helpers.saveDataItem(planItemBaseObj, 'IsDeleted', 0));
        //catalog submitted
        let progBaseObj = {
          InsertKey: helpers.getInsertKey(),
          Table: 'xmv.Progress',
          IsBaseTable: false
        };
        saveData.push(helpers.saveDataItem(progBaseObj, 'ConsumerId', this.props.ActiveConsumerId));
        saveData.push(helpers.saveDataItem(progBaseObj, 'Step', 'Catalog'));
      }
      saveData.push(helpers.saveDataItem(planItemBaseObj, 'ItemId1', item.CatalogSelected[0].ItemId1));
      saveData.push(helpers.saveDataItem(planItemBaseObj, 'ItemId2', item.CatalogSelected[0].ItemId2));
      saveData.push(helpers.saveDataItem(planItemBaseObj, 'ItemId3', item.CatalogSelected[0].ItemId3));
      saveData.push(helpers.saveDataItem(planItemBaseObj, 'ItemId4', item.CatalogSelected[0].ItemId4));
  
      actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
        if (result.Id) {
          item.planItemId = result.Id;
        }
        this.props.dispatch(actions.UpdateProp({
          Key: 'dbo_SaveId',
          Value: this.props.SaveId + 1
        }));
        //get cost calc 
        this.props.dispatch(actions.UpdateProp({
          Key: 'sr_MV_GetCost',
          Value: true
        }))

        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_Saving',
          Value: true
        }))

        setTimeout(() => {
          this.props.dispatch(actions.UpdateProp({
            Key: 'ms_ActiveStep',
            Value: 'Step3Panel'
          }));
          this.props.dispatch(actions.UpdateProp({
            Key: 'sr_PrintableEmail',
            Value: true
          }));
        }, 500)
      });
      return item;
    }
  }

  finishImageGroup = () => {
    if (!this.state.Completed)
      return;

    switch (this.props.Type) {
      case 'MySalesman':
        this.finishMySalesmanImageGroup();
    }

  }

  clearFilterCheck = (selectedRows, index) => {
    let lastIndex
    if(selectedRows && selectedRows.length > 0) {
      lastIndex = selectedRows.findLastIndex((value) => value !== null);
    }

    if (lastIndex === index) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    let filteredData = this.state.FilteredData;
    let picSize = '100px';
    if (window.IsMobile) {
      picSize = '65px';
    }

    return (
      <div className='image-grouping-container'>
        {filteredData.map((row, index) => {
          if (row && row.length > 0) {
            return <div key={index} className='image-grouping-row'>
              <div className='image-grouping-row-header'>{row[0] ? row[0].Title : ''}</div>
              <div className='image-grouping-row-container'>
                <div className='image-grouping-arrows' onClick={(e) => this.leftArrow(e)}><TriangleLeft /></div>
                <div className='image-grouping-row-picker'>
                  {filteredData[filteredData.indexOf(row)].sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index, self) => {
                    if (self.findIndex(v => v.Name === item.Name) === index) {
                      return <div key={index} style={{ maxWidth: picSize }} onClick={() => this.ItemClicked(row, item)} className={index === 0 ? 'first-image-cell image-grouping-cell-container' : 'image-grouping-cell-container'}>
                        <img src={item.Image} height={picSize} width={picSize} className='image-grouping-cell' />
                        <div style={{ fontWeight: '500', userSelect: 'none', textAlign: 'center', fontSize: '.85rem', height: '55px', overflow: 'hidden', maxWidth: '100px' }}>{item.Name}</div>
                      </div>
                    }
                  })}
                  {this.state.SelectedRows &&
                    this.state.SelectedRows[filteredData.indexOf(row)] &&
                    this.clearFilterCheck(this.state.SelectedRows, filteredData.indexOf(row)) ?
                    // (!this.getLastNotNullIndex(this.state.SelectedRows) || filteredData.indexOf(row) >= this.getLastNotNullIndex(this.state.SelectedRows)) ?
                    // (row.length != row.filter(x => x['ItemId' + (filteredData.indexOf(row) + 1)] == this.state.SelectedRows[filteredData.indexOf(row)]).length) ?
                    <div className='image-grouping-delete' onClick={() => this.clearSelection(row)}><CircleX />&nbsp;Clear Selection</div> : <></>}
                </div>
                <div className='image-grouping-arrows' onClick={(e) => this.rightArrow(e)}><TriangleRight /></div>
              </div>
            </div>
          }
        })}
        {/* <button className={"polygon-next-img" + ((this.state.Completed) ? " polygon-btn-pos" : " polygon-btn-disabled")} onClick={this.finishImageGroup} >Next</button> */}
        <button className={"clear-all-img" + ((this.state.SelectedRows && this.state.SelectedRows.some(x => !!x) ? " polygon-btn-pos" : " polygon-btn-disabled"))} onClick={this.clearAllSelection} >Clear All Selections</button>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ImageCatalog);