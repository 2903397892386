import React from 'react';
import { connect } from 'react-redux';
import * as actions from './../../../../../Stores/Actions/actions';
import GridRow from './GridRow';
import GroupRow from './GroupRow';
import * as helpers from './../../../../../Utils/Helpers';
import { listen } from 'ol/events';

const mapStateToProps = (state, ownProps) => {
  return {
    Query: state.qry_Queries[ownProps.queryId],
    topRow: state.qry_Queries[ownProps.queryId].topRow,
    pinnedRows: state.qry_Queries[ownProps.queryId].pinnedRows || [],
    PageRequests: state.qry_Queries[ownProps.queryId].pageRequests,
    visibleRows: state.qry_VisibleRows,
    GroupedColumns: state.qry_GroupedColumns ? state.qry_GroupedColumns[ownProps.queryId] : undefined
  };
};

export class GridBody extends React.PureComponent {
  visibleRows = () => {
    let p = this.props;

    let rowSource = p.Query.rows;
    if (!rowSource)
      return [];

    let isGrouped = false;

    if (p.GroupedColumns && p.GroupedColumns.length > 0) {
      let gCol = p.GroupedColumns[0];
      let groupedCols = gCol.GroupedResults;
      if (groupedCols && groupedCols.length > 0 && (!gCol.RowSourceItems || !rowSource.find(x => x.GroupByRow))) {
        let isLookup = gCol.DataType === 'Lookup' || gCol.ControlType === 'UserList';
        let userList = gCol.ControlType === 'UserList';

        groupedCols.sort((a, b) => {
          let aVal = isLookup ? helpers.getLookupName(userList ? 'userlist' : null, a[1]) : a[1];
          let bVal = isLookup ? helpers.getLookupName(userList ? 'userlist' : null, b[1]) : b[1];
          return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
        });

        let groups = groupedCols.map((x, idx) => {
          let isNumeric = helpers.isNumericData(gCol.DataType);
          return {
            Qty: Number(x[0]),
            GroupItem: x,
            GroupByRow: true,
            IsLookup: isLookup,
            IsUserList: gCol.Label === 'Assigned To',
            Id: groupedCols.length - idx,
            Filter: `ISNULL(${gCol.TableName}.${gCol.ColumnName}, '') = ${isNumeric ? (x[1] === '' ? "''" : x[1]) : "'" + x[1] + "'"}`
          }
        });
        let skip = 0;
        groups.forEach(x => {
          x.Index = skip;
          rowSource.splice(skip, 0, x);
          rowSource.slice(skip + 1, skip + 1 + x.Qty).forEach((r, idx) => {
            r.GroupRowNumber = idx;
            r.GroupIdx = x.Index;
          });
          skip += x.Qty + 1;
        });
        gCol.RowSourceItems = groups;
        actions.UpdatePageRequests({ requests: [] });
      }
      if (gCol.RowSourceItems) {
        isGrouped = true;
      }
    }

    p.Query.rows = rowSource;
    let rows = rowSource;

    if (isGrouped) {
      rows.forEach(x => x.Skip = false);
      let groupItems = p.GroupedColumns[0].RowSourceItems;
      let skip = 0;
      let hiddenQty = 0;
      groupItems.forEach(g => {
        skip++;
        g.HiddenQty = hiddenQty;
        if (!g.GroupItem.Expand) {
          rows.slice(skip, skip + g.Qty).forEach(x => x.Skip = true);
          hiddenQty += g.Qty;
        }
        skip += g.Qty;
      });
      rows = rows.filter(x => !x.Skip);
    }



    rows = rows.slice(p.topRow, p.topRow + p.visibleRows);
    rows = rows.filter((x, idx) => !p.pinnedRows.includes(p.topRow + idx));

    return rows;
  }

  pinnedRows = () => {
    return this.props.pinnedRows.map(x => this.props.Query.rows[x]);
  }

  render() {
    return (
      this.props.cols.length === 0 || !this.props.Query.rows ? null :
        <div className="grid-body">
          {this.pinnedRows().map((x, idx) => (
            <GridRow
              key={idx}
              columns={this.props.cols}
              row={x}
              rowNum={idx}
              queryId={this.props.queryId}
              params={this.props.params}
              lastPin={idx === (this.props.pinnedRows.length - 1)} />
          ))}
          {this.visibleRows().map((x, idx) => (
            (x && x.GroupByRow) ?
              <GroupRow key={idx} row={x} queryId={this.props.queryId} pinned={this.props.pinned} /> :
              <GridRow
                key={idx}
                columns={this.props.cols}
                row={x}
                rowNum={this.props.pinnedRows.length + this.props.topRow + idx}
                queryId={this.props.queryId}
                params={this.props.params} />
          ))}
        </div>
    );
  }
}

export default connect(mapStateToProps)(GridBody);