import { getIntersection } from 'ol/extent';
import React from 'react';
import * as helpers from '../../../../Utils/Helpers';
import * as icons from '../../../Icons/Icons';
import parser from 'html-react-parser';
import Inputmask from "inputmask";
import { RecurrenceIcon, MapLayer } from '../../../Icons/Icons';

const GridRow = props => {

  let row = props.row;
  let rowIdx = props.rowIdx;
  let parent = props.parent;
  let childRows = props.childRows;
  let childClass = '';
  let nestedChildRows = false;

  if (props.depth > 0) {
    childClass += ' child-row';
    childClass += props.depth % 3 == 1 ? ' child-primary' : '';
    childClass += props.depth % 3 == 2 ? ' child-secondary' : '';
    childClass += props.firstChild ? ' first-child' : '';
    childClass += props.lastChild ? ' last-child' : '';
    childClass += ' depth-' + props.depth;
  }

  if (childRows && childRows[row.RowId] && childRows[row.RowId].find(x => childRows[x.RowId] && childRows[x.RowId].length > 0)) {
    nestedChildRows = true;
  }

  let getIcon = (col, value) => {
    let Icon = icons[col.Icon];
    //Only show these icons when column is true
    if (col.Icon === 'RecurrenceIcon') {
      if (value) {
        return <Icon />
      } else {
        return null
      }
    }
    if (Icon) {
      return <Icon />;
    }
  }


  let getRowStyle = (col, row) => {
    let result = {
      flex: parent.getWidth(col.Width)
    }

    if (row[col.Name] && row[col.Name].BackgroundColor) {
      result["backgroundColor"] = row[col.Name].BackgroundColor;
    }

    //if row[col.Name] && row[col.Name].BackgroundColor add a backgroundColor prop to style object

    return result;
  }

  let getCellClassNames = (col) => {
    let cellClasses = '';
    if (col.Icon) {
      cellClasses += ' icon-text-sibling';
    }
    if (col.Control && col.Control.DataMask && (col.Control.DataMask === 'number' || col.Control.DataMask === 'decimal' || col.Control.DataMask === 'currency')) {
      cellClasses += ' right-justify';
    }
    if (col.Control && col.Control.DataMask && (col.Control.DataMask === 'datetime' || col.Control.DataMask === 'date')) {
      cellClasses += ' center-justify';
    }

    return cellClasses;
  }

  let cellData = {};
  parent.props.GridData.Columns.map((col) => {
    let cellObj = {};
    let val = row[col.Name];

    //Display value
    if (col.Control && col.Control._DataMaskOptions && val) {
      cellObj.Value = Inputmask.format(val, col.Control._DataMaskOptions);
    } else if (val && typeof val === 'object') {
      cellObj.Value = val.Value;
    } else {
      cellObj.Value = val;
    }

    if (col.SortType === 'money') {
      if (cellObj.Value !== undefined && cellObj.Value !== null && !isNaN(Number(cellObj.Value || 0))) {
        cellObj.Value = Number(cellObj.Value || 0).toFixed(2);
      }
    }

    //ClassName
    cellObj.OuterClass = val && val.Class ? val.Class : '';
    cellObj.InnerClass = getCellClassNames(col);

    //Style
    cellObj.CellStyle = val && val.Style ? helpers.stringToStyleObject(val.Style) : {};
    cellObj.RowStyle = {
      flex: (col.Width && col.Width.includes('px') ? '0 ' + col.Width : col.Width).replace('fr', ''),
      backgroundColor: val && val.BackgroundColor ? val.BackgroundColor : undefined
    }

    cellData[col.Name] = cellObj;
  });

  return (
    <React.Fragment>
      {<div key={rowIdx} style={{ marginLeft: (props.depth * 10) + 'px' }} className={parent.rowClassList(rowIdx, row) + childClass + (row.showChildren ? ' open' : '')}>
        {row.RowId && childRows[row.RowId] && <div className={"grid-row-chevron" + (row.showChildren ? ' expand' : '')} onClick={() => { parent.toggleRowExpand(row) }}><div>›</div></div>}
        {parent.props.GridData.Columns.map((col, colIdx) => (
          <div key={rowIdx + '-' + colIdx}
            className={cellData[col.Name].OuterClass + " row-cell" + (col.OnClick && (!row[col.Name] || !row[col.Name].DisableClick) ? ' clickable' : '') + (col.SortType ? ' sort-' + col.SortType : '') + (props.hasChildRows && colIdx == 0 ? ' chevron-pad' : '') + ' col-' + col.Name + (props.highlightColumn && props.highlightColumn.HighlightCol === col.Name ? ' active-column' : '')}
            onClick={(e) => { if (col.OnClick || row.OnLoadKey) { parent.handleClick(col.OnClick, row, e, col.Name) } }}
            style={cellData[col.Name].RowStyle}>
            {(!col.Control || !col.Control.ControlType) &&
              <React.Fragment>
                {(!row[col.Name] || !(typeof row[col.Name] === 'string' && row[col.Name].indexOf('<div') === 0)) && <div className={cellData[col.Name].InnerClass} style={cellData[col.Name].CellStyle} title={cellData[col.Name].Value}>{cellData[col.Name].Value}</div>}
                {!!(row[col.Name] && typeof row[col.Name] === 'string' && row[col.Name].indexOf('<div') === 0) && <div className={"html-cell"}>{parser(row[col.Name])}</div>}
                {col.Icon && <div className="grid-cell-icon">
                  {getIcon(col, row[col.Name])}
                </div>}
                {(!row[col.Name] || !row[col.Name].Warning) ? null :
                  <div className="grid-warning-icon">
                    <div>!</div>
                    <div className={"grid-warning-details" + (row[col.Name].Hover ? ' hover' : '')}>
                      <div className="warning-details-wrapper">
                        <div className="notch"></div>
                        {row[col.Name].Warning}
                      </div>
                    </div>
                  </div>
                }
              </React.Fragment>
            }
            {col.Control && col.Control.ControlType && col.Control.ControlType !== 'RowNumber' && (!row[col.Name] ? console.log(col.Name) || true : true) && <div className={"control-cell " + (col.Name + row[col.Name].SaveData.Id)}>
              {parent.getControl(row, col, rowIdx)}
            </div>}
            {col.Control && col.Control.ControlType && col.Control.ControlType === 'RowNumber' && <div className="grid-row-num">
              <div>{rowIdx + 1}</div>
            </div>}
          </div>
        ))}
      </div>}
      {row.showChildren && row.RowId && childRows[row.RowId] && childRows[row.RowId].map((cRow, cRowIdx) => (
        <GridRow
          key={cRowIdx}
          row={cRow}
          rowIdx={cRowIdx}
          parent={parent}
          childRows={childRows}
          depth={props.depth + 1}
          firstChild={cRowIdx === 0}
          lastChild={cRowIdx === childRows[row.RowId].length - 1}
          hasChildRows={nestedChildRows}
        />
      ))}
    </React.Fragment>
  );
}

export default GridRow;