import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';
import Honeycomb from '@beehive-development/honeycomb';

const mapStateToProps = (state, ownProps) => {
  return {
    CycleOperation: state.ent_CycleOperation
  };
};

export class UB_Cycle_Mail extends React.PureComponent {

  async componentDidMount() {
    let opsBtn = document.querySelector('.ci-btn-MAIL');

    if (opsBtn.classList.contains('disabled'))
      return;

    opsBtn.classList.add('disabled');

    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify({
        ...this.props.CycleOperation,
        Action: 'MAIL'
      })
    }

    actions.ApiRequest('Document/UBPrintFile', body, () => { });

    const jwtToken = await window._getToken;

    const honeycomb = new Honeycomb({
      baseUrls: {
        beehiveApimBaseUrl: "https://bbqautopayinvoice.azurewebsites.net/api"
      },
      requestHeaders: {
          Authorization: `Bearer ${jwtToken}`,
          "href-origin": window.location.href,
      },
    });

    const urlParams = new URLSearchParams(window.location.search);
    const dbName = urlParams.get("dbname") ?? "NE_Lincoln";

    await honeycomb.beeBeeQueue.postAutopayInvoiceScheduler({
      SchedulerMode: {
        Type: "FromDatabase",
        EmailFrom: "lwsbilling@lincoln.ne.gov",
        ActionGroupId: this.props.CycleOperation.ActionGroupId,
        DatabaseName: dbName,
      }
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_Cycle_Mail);
