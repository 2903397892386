import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    Metadata: state.met_EntityMetadata
  };
};

export class MV_SiteLoad extends React.PureComponent {

  componentDidMount() {
    hub.subscribe('SiteLoad', (met) => {
      if (met.CurrentTenant) {
        batch(() => {
          this.props.dispatch(actions.UpdateProp({
            Key: 'ent_ContractorSelected',
            Value: {
              EntityId: met.CurrentTenant,
              EntityTypeId: 2041
            }
          }));
          this.props.dispatch(actions.UpdateProp({
            Key: 'ent_TenantSelected',
            Value: {
              EntityId: met.CurrentTenant,
              EntityTypeId: 2043
            }
          }));
          this.props.dispatch(actions.UpdateProp({
            Key: 'dbo_NewContractorId',
            Value: 1
          }));
        });
      }
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_SiteLoad);

