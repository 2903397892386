import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import { AgGridReact } from 'ag-grid-react';
import * as hub from '../../../../../Utils/PubSub';
import FormControl from '../../../Form/v001/FormControl';

export default class FormControlCell extends Component {

  constructor(props) {
    super(props);
    this.spanRef = React.createRef();
  }

  changed = () => {
    this.spanRef.current.classList.add('changed');

    if (this.myControl.ControlType === 'IsDeleted') {
      this.props.setValue(this.myControl.Value);
      this.props.api.redrawRows();
    }
  }

  refresh() {
    this.spanRef.current.classList.remove('changed');
  }

  render() {
    let col = this.props.colDef;
    let control = {
      ControlType: col.ControlType,
      ClassName: col.ControlType === 'CheckBox' ? 'orange-check' : '',
      Id: this.props.data.agId,
      Label: '',
      Name: col.field,
      LookupSetId: col.LookupSetId,
      SaveData: {
        Column: col.Column,
        Table: col.Table,
        Id: this.props.data['pkey' + col.TableNoSchema],
        InsertKey: null,
        SaveQueue: col.SaveQueue
      },
      Value: this.props.value
    }
    this.myControl = control;
    return (
      <span ref={this.spanRef}>
        <FormControl
          control={control}
          trackChanges={true}
          refresh={this.changed}
        />
      </span>
    );
  }
};

// formControl: {
//   ControlType: col.ControlType,
//   SaveData: {
//     Column: col.SourceColumn,
//     Table: col.SourceTable
//   }
// }


// if (coldef.ControlType === 'DropDown') {
//   coldef.valMap = helpers.getLookupItems(coldef.LookupSetId).reduce((prev, next) => {
//     prev[next.LookupItemId] = next.Name1;
//     return prev;
//   }, {});


//   // coldef.cellEditor = DropDownEditor;
//   // coldef.cellEditorPopup = false;
// }