import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as actions from './../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let groupedCols = undefined;
  if (state.qry_GroupedColumns && state.qry_GroupedColumns[ownProps.queryId]) {
    groupedCols = state.qry_GroupedColumns[ownProps.queryId];
  }

  let rows = state.qry_Queries[ownProps.queryId].rows;
  let noSkipRows = rows ? rows.filter(x => !x.Skip) : undefined;

  return {
    Data: state.qry_Queries[ownProps.queryId].data,
    PageRequests: state.qry_Queries[ownProps.queryId].pageRequests,
    Rows: rows,
    NoSkipRows: noSkipRows,
    TopRow: state.qry_Queries[ownProps.queryId].topRow,
    RefreshCurrentPage: state.qry_RefreshCurrentPage,
    VisibleRows: state.qry_VisibleRows,
    GroupedColumns: groupedCols ? groupedCols[0] : undefined,
    SelectedQuery: state.qry_SelectedQuery,
    ResetTop: state.qry_ResetTop || 1
  };
};

export class Scrollbar extends React.PureComponent {
  scrollbarElement;
  containerElement;
  bodyElement;
  dragging = false;
  initialY;
  topRow;

  state = {
    offsetTop: 0
  }

  componentDidUpdate(prevProps) {
    let prev = prevProps.Data;
    let current = this.props.Data;
    if (current && (!prev || current.scrollbarHeight === undefined || current.rowCount !== prev.rowCount)) {
      this.setScrollbarHeight();
    }

    if (helpers.propDidChange(this.props, prevProps, 'RefreshCurrentPage')) {
      this.setScrollbarHeight();
      this.setScrollbarPosition();
      if (this.props.TopRow === 0) {
        this.setState({ offsetTop: 0 });
        this.initialY = 0;
        this.topRow = 0;
        this.dragging = false;
      }
    }

    if (this.props.queryId !== prevProps.queryId || helpers.propDidChange(this.props, prevProps, 'ResetTop')) {
      this.setState({ offsetTop: 0 });
      this.initialY = 0;
      this.topRow = 0;
      this.dragging = false;
    }

    let curNoSkip = this.props.NoSkipRows;
    let prevNoSkip = prevProps ? prevProps.NoSkipRows : null;
    if (curNoSkip && prevNoSkip && curNoSkip.length !== prevNoSkip.length) {
      this.setScrollbarHeight();
      this.setScrollbarPosition();
    }
  }

  componentDidMount() {
    if (this.props.Data && this.props.Data.offsetTop) {
      this.setState({ offsetTop: this.props.Data.offsetTop });
      this.topRow = this.props.Data.topRow;
    }

    this.scrollbarElement = ReactDOM.findDOMNode(this).getElementsByClassName('grid-scrollbar')[0];
    this.containerElement = ReactDOM.findDOMNode(this).getElementsByClassName('scrollbar-container')[0];
    window.addEventListener("wheel", this.mouseWheel);
  }

  componentWillUnmount() {
    this.props.Data.offsetTop = this.state.offsetTop;
    this.props.Data.topRow = this.topRow;
    window.removeEventListener("wheel", this.mouseWheel);
  }

  mouseWheel = (e) => {
    if (helpers.elementOrAncestorHasClass(e.target, 'grid-body')) {
      const delta = Math.sign(e.deltaY);
      this.wheelScroll(delta);
    }
  }

  wheelScroll = (direction) => {
    let offset = this.state.offsetTop;
    let maxOffset = this.containerElement.offsetHeight - this.props.Data.scrollbarHeight - 3;
    let newOffset = offset + ((maxOffset / this.props.NoSkipRows.length) * 4 * direction);

    if (newOffset > maxOffset)
      newOffset = maxOffset;

    if (newOffset < 0)
      newOffset = 0;

    if (newOffset !== this.state.offsetTop) {
      this.initialY = newOffset;
      this.setState({ offsetTop: newOffset });
      this.requestEmptyPages(newOffset, maxOffset);
      this.setTopRow((this.topRow || 0) + (4 * direction));
    }
  }

  setScrollbarPosition = () => {
    if (!this.props.NoSkipRows)
      return;

    let maxOffset = this.containerElement.offsetHeight - this.props.Data.scrollbarHeight - 3;
    let topRow = this.topRow;
    let rowCount = this.props.NoSkipRows.length;
    this.setState({ offsetTop: maxOffset * (topRow / rowCount) });
  }

  scrollbarDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.dragging = true;
    this.initialY = e.screenY;
    document.addEventListener('mousemove', this.scrollbarMove);
    document.addEventListener('mouseup', this.scrollbarUp);
  }

  scrollbarUp = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.dragging = false;
    document.removeEventListener('mouseup', this.scrollbarUp);
    document.removeEventListener('mousemove', this.scrollbarMove);
  }

  scrollbarMove = (e) => {
    if (!this.dragging)
      return;

    e.stopPropagation();
    e.preventDefault();

    let diff = e.screenY - this.initialY;
    let newOffset = this.state.offsetTop + diff;
    let maxOffset = this.containerElement.offsetHeight - this.props.Data.scrollbarHeight - 3;

    if (newOffset > maxOffset)
      newOffset = maxOffset;

    if (newOffset < 0)
      newOffset = 0;

    if (newOffset !== this.state.offsetTop) {
      this.setState({ offsetTop: newOffset });

      this.initialY = e.screenY;
      let rowCount = this.props.NoSkipRows.length;
      let topRow = Math.floor((newOffset / maxOffset) * rowCount);

      if (topRow !== this.topRow) {
        this.setTopRow(topRow);
      }

      helpers.debounce(() => {
        this.requestEmptyPages(newOffset, maxOffset);
      }, 150);
    }
  }

  requestEmptyPages = (offset, maxOffset) => {
    let rowCount = this.props.NoSkipRows.length;
    let topRow = Math.floor((offset / maxOffset) * rowCount);

    if (this.props.Rows.length !== this.props.NoSkipRows.length) {
      let i = 0;
      let skips = 0;
      let noSkip = 0;
      let rows = this.props.Rows;
      while (noSkip < topRow) {
        if (rows[i].Skip) {
          skips++;
        } else {
          noSkip++;
        }
        i++;
      }
      topRow += skips;
    }

    let currentPage = Math.floor(topRow / this.props.Data.pageSize);
    let requests = [];

    if (this.pageIsNeeded(currentPage)) {
      requests.push(currentPage);
    }

    let closeToNext = ((this.props.Data.pageSize * (currentPage + 1)) - topRow) < 50;
    if (closeToNext && this.pageIsNeeded(currentPage + 1)) {
      requests.push(currentPage + 1);
    }

    let closeToPrev = (topRow - (this.props.Data.pageSize * currentPage)) < 30;
    if (closeToPrev && this.pageIsNeeded(currentPage - 1)) {
      requests.push(currentPage - 1);
    }

    if (requests.length > 0) {
      this.props.dispatch(actions.UpdatePageRequests({
        queryId: this.props.queryId,
        requests: [...(this.props.PageRequests || []), ...requests]
      }));
    }
  }

  pageIsNeeded = (pageNumber) => {
    let inBounds = pageNumber >= 0;

    if (!inBounds)
      return false;

    let pageIdx = pageNumber * this.props.Data.pageSize;
    let dataRows = this.props.Rows;
    if (!dataRows[pageIdx] || dataRows[pageIdx].GroupByRow)
      return false;

    let noExistingData = dataRows[pageIdx].length === 0;
    let notRequested = !this.props.PageRequests.find(x => x === pageNumber);

    return noExistingData && notRequested;
  }

  setTopRow = (topRow) => {
    let rowCount = this.props.Rows.length;

    if (topRow < 0 || rowCount === 0)
      topRow = 0;

    if (rowCount > 0 && topRow > (rowCount - this.props.VisibleRows + 3)) {
      topRow = rowCount - this.props.VisibleRows + 3;
    }

    this.props.dispatch(actions.SetTopRow({
      queryId: this.props.queryId,
      topRow: topRow
    }));
    this.topRow = topRow;
  }

  setScrollbarHeight = () => {
    if (!this.props.NoSkipRows)
      return;

    console.log('setscrollbarheight');

    let containerHeight = this.containerElement.offsetHeight;
    let rowCount = this.props.NoSkipRows.length || 0;

    // if (this.props.GroupedColumns && this.props.GroupedColumns.GroupedResults && this.props.GroupedColumns.GroupedResults.length > 0) {
    //   rowCount = this.props.GroupedColumns.RowSourceItems.reduce((prev, next) => {
    //     if (next.GroupItem.Expand) {
    //       prev += next.Qty;
    //     }
    //     return prev;
    //   }, 0);

    //   rowCount += this.props.GroupedColumns.RowSourceItems.length;
    // }

    if (rowCount === 0)
      return;

    let height = Math.ceil((containerHeight / rowCount) * 30);
    height = Math.max(height, 70);
    height = Math.min(height, containerHeight - 3);

    if (height === this.props.Data.scrollbarHeight)
      return;

    this.props.dispatch(actions.UpdateQueryData({
      queryId: this.props.queryId,
      data: {
        ...this.props.Data,
        scrollbarHeight: height
      },
      dataKey: 'data'
    }));
  }

  render() {
    let data = this.props.Data;
    return (
      <div>
        <div className='scrollbar-container'>
          <div className='grid-scrollbar'
            onMouseDown={this.scrollbarDown}
            style={{
              height: data && data.scrollbarHeight ? data.scrollbarHeight : 70,
              top: this.state.offsetTop + 'px'
            }}></div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Scrollbar);