import React, { PureComponent } from 'react'
import { batch, connect } from 'react-redux';
import * as actions from './../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  let engineMap = {};

  if (state.blu_Dialog && state.blu_Dialog.Buttons) {
    state.blu_Dialog.Buttons.forEach(btn => {
      if (btn.Engines) {
        btn.Engines.forEach(eng => {
          engineMap[eng] = state[eng];
        });
      }
    });
  }

  return {
    Dialog: state.blu_Dialog,
    ApplicationId: state.blu_ApplicationId,
    Dialogs: state.wkf_Dialogs,
    EngineMap: engineMap
  };
}

let _init = false;

export class DialogLayer extends PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.keyPress);
  }

  componentDidUpdate() {
    if (this.props.ApplicationId && !_init) {
      let body = {
        ApplicationId: this.props.ApplicationId
      };
      actions.ApiRequest('Editor/GetDialogs', body, (result) => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'wkf_Dialogs',
          Value: result
        }));
      });
      _init = true;
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
  }

  keyPress = (e) => {
    if (e.key === 'Escape') {
      this.close();
    }
  }

  close = () => {
    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: undefined
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_PendingAction',
        Value: undefined
      }));
    })

  }

  btnClick = (btn) => {
    if (btn.Engines && btn.Engines.length > 0) {
      this.callEngines(btn.Engines);
    }

    if (!btn.PreventAction) {
      setTimeout(() => {
        this.props.dispatch(actions.ResumePendingAction());
        setTimeout(() => { this.close(); }, 0);
      }, 750);
    } else {
      this.close();
    }
  }

  callEngines = (engines) => {
    engines.forEach(x => {
      let btn = this.props.EngineMap[x];
      console.log(btn);
      this.props.dispatch(actions.UpdateProp({
        Key: x,
        Value: {
          ...btn,
          ClickId: (btn.ClickId || 0) + 1
        }
      }));
    });
  }

  getWidth = (val) => {
    if (!val)
      return '1';

    if (val.includes('px'))
      val = '0 ' + val;

    val = val.replace('fr', '');

    return val;
  }

  render() {
    let dialog = this.props.Dialog;
    return (
      !dialog ? null :
        <div className="dialog-layer">
          <div className="dialog">
            <div className="title-bar">
              <div className="title">
                <div>{dialog.Title}</div>
              </div>
              <div className="close-btn" onClick={this.close}>
                <div>✕</div>
              </div>
            </div>
            <div className="message-box">
              <div className="message">
                <div>{dialog.Message}</div>
              </div>
            </div>
            {dialog.Buttons ?
              <div className="flex-btn-bar">
                {dialog.Buttons.map((x, idx) => (
                  <div key={idx} className={"btn-container"} style={{ flex: this.getWidth(x.Width) }} >
                    <div key={idx} className={"btn-bar-btn " + (x.Class || '')} onClick={() => { this.btnClick(x) }}>
                      <div>{x.Label}</div>
                    </div>
                  </div>
                ))}
              </div>
              :
              <div className="btn-bar">
                <div className="ok-btn" onClick={this.close}>
                  <div>Ok</div>
                </div>
              </div>
            }
          </div>
        </div>
    )
  }
}

export default connect(mapStateToProps)(DialogLayer);