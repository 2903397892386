import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    FundSaveData: state.dbo_FundSaveData ? state.dbo_FundSaveData : null,
    SourceRows: state._grid1813 ? state._grid1813.Rows : null,
    Buckets: state.met_DropdownBuckets ? state.met_DropdownBuckets : null,
    SaveId: state.dbo_SaveId ? state.dbo_SaveId : null
  };
};

export class OH_Fund_Validation extends React.PureComponent {

  componentDidMount() {
    let saveData = this.props.FundSaveData
    const rows = this.props.SourceRows
    const bucket = this.props.Buckets.find(e => e.Id === 2)
    if(saveData && saveData.length > 0 && rows && rows.length > 0 && bucket) {
        let valid = true
        let length = parseInt(saveData.length / 9)
        for(let i=0; i < length; i++) {
            const fundSource = saveData.find(e => e.Column === "FundId")
            const deductionAmount = saveData.find(e => e.Column === "Amount" && fundSource.InsertKey === e.InsertKey)
            const date = saveData.find(e => e.Column === "TranDate" && fundSource.InsertKey === e.InsertKey)
            const transferEnt = saveData.find(e => e.Column === "TEntityId" && fundSource.InsertKey === e.InsertKey)

            if((fundSource && fundSource.Value === "") ||
               (deductionAmount && deductionAmount.Value === "") ||
               (date && date.Value === "") ||
               (transferEnt && transferEnt === "")
            ) {
                this.props.dispatch(actions.UpdateProp({
                    Key: 'blu_Dialog',
                    Value: { 
                        Title: 'Missing Values Entered', 
                        Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>
                            Please verify all fields have been filled out.
                        </p> 
                    }
                }));
                return;
            }

            const fundDBucket = bucket.BucketData.find(e => e.Key === fundSource.Value)
            const row = rows.find(e => e.Grant === fundDBucket.Value)
            const startingAmount = row['Current Balance']
            if(startingAmount && deductionAmount && deductionAmount.Value) {
                if(startingAmount < deductionAmount.Value) {
                    valid = false
                }
            }
            if(!valid) {
                this.props.dispatch(actions.UpdateProp({
                    Key: 'blu_Dialog',
                    Value: { 
                        Title: 'Invalid Amount Entered', 
                        Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>
                            You are transferring more funds than what is available. Please verify your entries.
                        </p> 
                    }
                }));
                return;
            } else {
                saveData = saveData.filter(e => e.InsertKey !== fundSource.InsertKey)
            }
        }
        //do save
        let newSaveData = this.props.FundSaveData
        actions.ApiRequest('Save/Save', { SaveData: newSaveData }, (result) => {
            this.props.dispatch(actions.UpdateProp({
                Key: 'dbo_FundSaveData',
                Value: []
            }));
            this.props.dispatch(actions.UpdateProp({
                Key: 'dbo_SaveId',
                Value: this.props.SaveId + 1
            }));
        });
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(OH_Fund_Validation);