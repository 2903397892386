import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {
    CurrentEntity: state.ent_Current,
    SaveQueue: state.dbo_SignMgtSaveData,
    NewSign: state.ent_NewSignSelected
  };
};

export class NewSignSaveData extends React.PureComponent {
  SignRelTable = 'rel.SignSupport';
  SaveQueue = 'dbo_SignMgtSaveData';

  componentDidMount() {
    if (!this.props.NewSign || this.props.NewSign.SRComplete)
      return;

    this.props.NewSign.SRComplete = true;

    let baseObj = {
      Id: null,
      InsertKey: helpers.getInsertKey(),
      Table: this.SignRelTable,
      IsEventData: false,
      SaveQueue: this.SaveQueue,
      IsBaseTable: false
    }

    let saveData = [
      helpers.saveDataItem(baseObj, 'ParentId', this.props.CurrentEntity.EntityId),
      helpers.saveDataItem(baseObj, 'ParentTypeId', 1088),
      helpers.saveDataItem(baseObj, 'ChildId', this.props.NewSign.EntityId),
      helpers.saveDataItem(baseObj, 'ChildTypeId', 1087),
      helpers.saveDataItem(baseObj, 'DisplayOrder', null),
      helpers.saveDataItem(baseObj, 'IsDeleted', false)
    ];

    batch(() => {
      saveData.forEach(sd => {
        this.props.dispatch(actions.AddSaveData(sd));
      });
    })
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(NewSignSaveData);