import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../../Utils/Helpers';
import { MagnifyingGlass } from '../../../../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class EntityProp extends React.PureComponent {
  state = {
    Conditions: [{
      ConditionId: 0,
      Label: 'Is Not Null',
      NeedsField: false,
      Value: '!== [null]'
    },
    {
      ConditionId: 1,
      Label: 'Is Null',
      NeedsField: false,
      Value: '=== [null]'
    },
    {
      ConditionId: 2,
      Label: 'Equals',
      NeedsField: true,
      FieldLabel: '=',
      Value: '=== {value}'
    },
    {
      ConditionId: 3,
      Label: 'Not Equal',
      NeedsField: true,
      FieldLabel: '!=',
      Value: '!== {value}'
    },
    {
      ConditionId: 4,
      Label: 'Changes',
      NeedsField: false,
      Value: 'CHANGED'
    }],
    ConditionId: 0,
    FieldText: ''
  }

  componentDidMount() {
    this.updateText3();
  }

  setCondition = (e) => {
    this.setState({ FieldText: '' });
    this.setState({ ConditionId: Number(e.target.value) });
    this.updateText3();
  }

  setFieldText = (e) => {
    this.setState({ FieldText: e.target.value });
    this.updateText3();
  }

  updateText3 = () => {
    setTimeout(() => {
      let condition = this.getCondition();
      let textVal = condition.Value.replace('{value}', '"' + this.state.FieldText + '"');
      let mockEvent = { target: { value: textVal } };
      this.props.setProp(mockEvent, 'Text3');
    }, 0);
  }

  getCondition = () => {
    return this.state.Conditions.find(x => x.ConditionId === this.state.ConditionId);
  }

  render() {
    let condition = this.getCondition();
    return (
      <React.Fragment>
        <div className="trigger-specific-props"></div>
        <div className="side-bar-prop">
          <div>Entity Key:</div>
          <input type="text" value={this.props.state.Text1 || ''} onChange={(e) => { this.props.setProp(e, 'Text1') }} />
        </div>
        <div className="side-bar-prop">
          <div>Property Name:</div>
          <input type="text" value={this.props.state.Text2 || ''} onChange={(e) => { this.props.setProp(e, 'Text2') }} />
        </div>
        <div className="horizontal-props">
          <div className="side-bar-prop">
            <div>Value:</div>
            <select value={this.state.ConditionId} onChange={this.setCondition}>
              {this.state.Conditions.map((x, idx) => (
                <option key={idx} value={x.ConditionId}>{x.Label}</option>
              ))}
            </select>
          </div>
          {condition && condition.NeedsField &&
            <div className="side-bar-prop">
              <div>{condition.FieldLabel}</div>
              <input type="text" value={this.state.FieldText} onChange={this.setFieldText} />
            </div>}
        </div>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(EntityProp);