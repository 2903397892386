
import React from 'react';
import * as icons from './../../../../Icons/Icons';

const IconTab = props => {
  let onClick = (e) => {
    props.parentClick(props.card);
  }
  let selected = props.selected && props.selected.Id === props.card.Id && props.card.Id !== undefined;
  let CardIcon = icons[props.card.Icon];
  return (
    <React.Fragment>
      <div className={"card-item icon-tab-card" + (selected ? ' selected' : '')} onClick={onClick}>
        <div className="icon">
          {/* <icons.props.card.Icon /> */}
          {CardIcon && <CardIcon />}
        </div>
        <div className="label">{props.card.Label}</div>
        {/* <div className="sub-title">{props.card.Text2}</div>
        <div className="title">{props.card.Text1}</div>
        <div className="pending-totals">
          <div className="users">
            <div>Users:</div>
            <div className="qty">{props.card.PendingUsers}</div>
          </div>
          <div className="hours">
            <div>Hours:</div>
            <div className="qty">{props.card.PendingHours.toFixed(2)}</div>
          </div>
        </div>
        <div className="complete-totals">
          <div className="users">
            <div>Users:</div>
            <div className="qty">{props.card.CompleteUsers}</div>
          </div>
          <div className="hours">
            <div>Hours:</div>
            <div className="qty">{props.card.CompleteHours.toFixed(2)}</div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}

export default IconTab