import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let sourceProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntitySource');
  let outProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityOutput');
  let resetOnEntityLoad = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetOnEntityLoad');
  let saveAssetHistory = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveAssetHistory');

  return {
    EntitySource: sourceProp ? state[sourceProp.ParameterValue] : undefined,
    EntitySourceKey: sourceProp ? sourceProp.ParameterValue : undefined,
    CurrentEntity: outProp ? state[outProp.ParameterValue] : undefined,
    CurrentEntityKey: outProp ? outProp.ParameterValue : undefined,
    EntityMetadata: state.met_EntityMetadata,
    SWID: ownProps.widget.SceneWidgetId,
    RefreshProps: state.ent_RefreshProps,
    ResetOnEntityLoad: resetOnEntityLoad ? resetOnEntityLoad.ParameterValue : undefined,
    SaveAssetHistory: saveAssetHistory ? helpers.stringToBool(saveAssetHistory.ParameterValue) : false,
    AssetHistory: state.AssetHistory
  };
}

let _entityLoaded = {};

export class Entity extends React.PureComponent {

  componentDidMount = () => {
    if (this.props.SaveAssetHistory) {
      let history = JSON.parse(localStorage.getItem('AssetHistory'));
      this.props.dispatch(actions.UpdateProp({
        Key: 'AssetHistory',
        Value: history
      }));
    }
  }

  componentDidUpdate = (prev) => {
    if (prev && this.props.EntitySource) {
      if (helpers.entId(this.props.EntitySource) !== helpers.entId(prev.EntitySource) || (prev && prev.EntitySource && prev.EntitySource.PreventSceneSwitch && !this.props.EntitySource.PreventSceneSwitch)) {
        this.getEntity();
      } else {
        if (this.props.RefreshProps && !this.props.RefreshProps[this.props.SWID]) {
          _entityLoaded[this.props.CurrentEntityKey] = null;
          this.getEntity();
          this.props.dispatch(actions.UpdateProp({
            Key: 'ent_RefreshProps',
            Value: { ...this.props.RefreshProps, [this.props.SWID]: true }
          }));
        }
      }
    }
  }

  getEntity = () => {
    let source = this.props.EntitySource;
    let entKey = this.props.CurrentEntityKey;

    let body = {
      EntityId: source.EntityId,
      EntityTypeId: source.EntityTypeId
    }

    let emd = this.props.EntityMetadata.EntityTypes.find(x => x.TypeId === source.EntityTypeId);
    if (!emd)
      return;

    this.loadEntityShell(source, emd);

    if (source.ShellOnly || source.EntityId < 0) {
      return;
    }

    this.resetOnLoad();
    _entityLoaded[entKey] = helpers.entId(source);

    actions.ApiRequest('Entity/GetEntity', body, (result) => {
      if (helpers.entId(source) !== _entityLoaded[entKey]) {
        console.log('outdated engine entity results blocked');
        return;
      }

      if (!result || !result.Properties || !this.props.CurrentEntity.Properties) {
        return;
      }

      let primaryText = result.PrimaryText;
      let secondaryText = result.SecondaryText;
      let keyRing = helpers.idValueArrayToObject(result.Properties);

      this.props.CurrentEntity.Properties = this.props.CurrentEntity.Properties.sort((a, b) => {
        let aVal = keyRing[a.Id] && keyRing[a.Id].Key ? Number(keyRing[a.Id].Key) === source.EntityId ? 0 : 1 : 1;
        let bVal = keyRing[b.Id] && keyRing[b.Id].Key ? Number(keyRing[b.Id].Key) === source.EntityId ? 0 : 1 : 1;
        return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
      });

      this.props.CurrentEntity.Properties.forEach(x => {
        let formData = keyRing[x.Id];
        if (formData) {
          x.Value = formData.Value;
          let displayVal = helpers.entPropValue(x);

          primaryText = primaryText.replace(new RegExp('\\[' + x.Name + '\\]', 'g'), displayVal || '-');
          secondaryText = secondaryText.replace(new RegExp('\\[' + x.Name + '\\]', 'g'), displayVal || '-');

          if (x.SaveData) {
            x.SaveData.Id = formData.Key;
          }
        }
      });

      this.props.CurrentEntity.IsShell = false;
      this.props.CurrentEntity.EntityLabel = result.EntityLabel;
      this.props.CurrentEntity.PrimaryImage = result.PrimaryImage;
      this.props.CurrentEntity.HasTime = emd.HasTime;
      this.props.CurrentEntity.HasMaterial = emd.HasMaterial;
      this.props.CurrentEntity.HasEquipment = emd.HasEquipment;
      this.props.CurrentEntity.PrimaryText = primaryText;
      this.props.CurrentEntity.SecondaryText = secondaryText;
      this.props.CurrentEntity.ShowPrintReport = result.ShowPrintReport;
      this.props.CurrentEntity.AgencyId = result.AgencyId;
      this.props.CurrentEntity.DocumentCount = result.DocumentCount;

      if (result.TenantId) {
        this.props.CurrentEntity.TenantId = result.TenantId;
      }

      if (result.Geometry) {
        this.props.CurrentEntity.Geometry = result.Geometry;
      }

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntitySourceKey + 'OnLoad',
        Value: true
      }));

      this.props.dispatch(actions.SetCurrentEntity(this.props.CurrentEntity, this.props.CurrentEntityKey));

      if (this.props.SaveAssetHistory) {
        let newItem = {
          IconSource: 'https://beehivetilcache.blob.core.windows.net/staticassets/FeatureClassIcons/' + this.props.CurrentEntity.EntityTypeId + '.png',
          Text1: this.props.CurrentEntity.SecondaryText,
          Text2: this.props.CurrentEntity.PrimaryText,
          EntityId: this.props.CurrentEntity.EntityId,
          EntityTypeId: this.props.CurrentEntity.EntityTypeId,
          Id: this.props.CurrentEntity.EntityTypeId + '-' + this.props.CurrentEntity.EntityId
        }

        let history = this.props.AssetHistory ? this.props.AssetHistory.slice() : null;
        if (!history) {
          history = [newItem];
        } else {
          let assetExists = history.find(x => x.EntityId == this.props.EntitySource.EntityId && x.EntityTypeId == this.props.EntitySource.EntityTypeId);
          if (assetExists) {
            history = history.filter(x => x.EntityId != this.props.EntitySource.EntityId && x.EntityTypeId != this.props.EntitySource.EntityTypeId);
          }
          history.unshift(newItem);
        }
        this.props.dispatch(actions.UpdateProp({
          Key: 'AssetHistory',
          Value: history
        }));
        localStorage.setItem('AssetHistory', JSON.stringify(history));
      }
    });
  }

  resetOnLoad = () => {
    if (!this.props.ResetOnEntityLoad)
      return;

    let resetProp = this.props.ResetOnEntityLoad;
    let resetArr = resetProp.split(',').map(x => x.trim());
    resetArr.forEach(key => {
      this.props.dispatch(actions.UpdateProp({
        Key: key,
        Value: null
      }));
    });
  }

  loadEntityShell = (source, emd) => {
    console.log('load shell');
    if (!this.props.EntityMetadata)
      return;

    let shell = {
      IsShell: true,
      ...emd,
      PrimaryText: '',
      SecondaryText: '',
      EntityId: source.EntityId,
      EntityTypeId: source.EntityTypeId,
      IsNew: source.IsNew || false,
      Properties: emd.Properties.map((x) => {
        if (source.IsNew) {
          x.SaveData.Id = null;
          if (x.SaveData.Table) {
            x.SaveData.InsertKey = source.InsertKeys[x.SaveData.Table];
          }
          if (!x.DefaultSet) {
            x.Value = x.DefaultValue || '';
          }
        } else {
          x.SaveData.Id = source.EntityId;
          x.SaveData.InsertKey = null;
          x.Value = '';
        }

        return x;
      })
    }
    if (source.TenantId) {
      shell.TenantId = source.TenantId;
    }
    if (source.GeometryType) {
      shell.GeometryType = source.GeometryType;
    }
    this.props.dispatch(actions.SetCurrentEntity(shell, this.props.CurrentEntityKey));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Entity);