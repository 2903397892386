import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import AutoSearch from './../AutoSearch/AutoSearch';

const mapStateToProps = (state, ownProps) => {
  return { ignoreBlur: ownProps.ignoreBlur && ownProps.ignoreBlur === "true" }
}

export class AutoDropDown extends React.PureComponent {
  render() {
    return (
      <AutoSearch {...this.props} ShowChevron={true} />
    );
  }
}

export default connect(mapStateToProps)(AutoDropDown);