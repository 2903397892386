import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    GridData: state.RouteFieldStaff
  };
};

export class CalculateRoutes extends React.PureComponent {

  componentDidMount() {
    if (!this.props.GridData || !this.props.GridData.Rows)
      return;

    let agents = this.getFieldStaff();
    let body = {
      Agents: agents,
      AgentList: '[' + agents.map(x => x.UserId).join(',') + ']'
    }
    this.props.dispatch(actions.UpdateProp({
      Key: 'LoadingRoutes',
      Value: true
    }));
    actions.ApiRequest('Map/FieldRoutes', body, () => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'LoadingRoutes',
        Value: false
      }));
      helpers.refreshMapLayers(3580);
    });
  }

  getFieldStaff = () => {
    return this.props.GridData.Rows.map(x => {
      return {
        Enabled: x.Enabled.myControl.props.control.Value,
        UserId: x.UserId,
        Name: x.DisplayName,
        StartTime: x.StartTime.myControl.props.control.Value,
        EndTime: x.EndTime.myControl.props.control.Value
      }
    }).filter(x => x.Enabled);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(CalculateRoutes);
