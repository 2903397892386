import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {};
};

export class MoveSign extends React.PureComponent {
  SignRelTable = 'rel.SignSupport';
  UpdateColumn = 'ParentId';
  DisplayOrderColumn = 'DisplayOrder';

  //_grid2322,_grid2323,sr_MoveSignId,dbo_SaveData,ent_Current,ent_MoveSignSelected

  componentDidMount() {
    let params = this.props.Parameter;
    let keys = this.props.ParameterKeys;

    this.sourceGrid = params[0];
    this.destGrid = params[1];
    this.rowId = params[2];
    this.saveQueue = params[3];
    this.sourceEntity = params[4];
    this.destEntity = params[5];

    this.sourceGridKey = keys[0];
    this.destGridKey = keys[1]
    this.saveQueueKey = keys[3];

    if (params.findIndex(x => x === undefined) !== -1)
      return;

    if (this.sourceEntity.EntityId === this.destEntity.EntityId)
      return;

    if (!this.isValid())
      return;

    this.moveSign();
  }

  moveSign = () => {
    this.addClickedRowToDestination();
    this.removeClickedRow();
    this.addParentIdSaveData();
  }

  isValid = () => {
    let sourceItem = this.sourceGrid.Rows.find(x => x.Id === this.rowId);

    if (this.destGrid.Rows.find(x => x.Id === sourceItem.Id))
      return false;

    return true;
  }

  removeClickedRow = () => {
    let sourceGridCopy = cloneDeep(this.sourceGrid);
    sourceGridCopy.Rows = sourceGridCopy.Rows.filter(x => x.Id !== this.rowId);

    this.props.dispatch(actions.UpdateProp({
      Key: this.sourceGridKey,
      Value: sourceGridCopy
    }));
  }

  addClickedRowToDestination = () => {
    let destGridCopy = cloneDeep(this.destGrid);
    let sourceItem = cloneDeep(this.sourceGrid.Rows.find(x => x.Id === this.rowId));

    sourceItem.unsaved = true;
    destGridCopy.Rows.push(sourceItem);

    this.setDisplayOrder(destGridCopy.Rows);

    this.props.dispatch(actions.UpdateProp({
      Key: this.destGridKey,
      Value: destGridCopy
    }));
  }


  addParentIdSaveData = () => {
    this.props.dispatch(actions.AddSaveData({
      Id: this.rowId,
      Table: this.SignRelTable,
      Column: this.UpdateColumn,
      Value: this.destEntity.EntityId,
      IsEventData: false,
      SaveQueue: this.saveQueueKey,
      InsertKey: null,
      IsBaseTable: true
    }));
  }

  setDisplayOrder = (rows) => {
    batch(() => {
      rows.forEach((x, idx) => {
        this.props.dispatch(actions.AddSaveData({
          Id: x.Id,
          Table: this.SignRelTable,
          Column: this.DisplayOrderColumn,
          Value: idx,
          IsEventData: false,
          SaveQueue: this.saveQueueKey,
          InsertKey: null,
          IsBaseTable: true
        }));
      });
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MoveSign);