
import olImage from 'ol/layer/Image';
import Static from "ol/source/ImageStatic";
import Projection from 'ol/proj/Projection';
import { Map, View } from 'ol';
import { getCenter } from 'ol/extent';

const staticLayer = (path, options) => {
  if (options.map.GroupingMap) {
    options.map.groupImageLayerRefresh = () => { staticLayer(path, options) };
    if (!options.map.groupImg) {
      let currentLayers = options.map.getLayers().array_;
      let layerIndex = currentLayers.findIndex(x => x.isGroupingImage);
      if (layerIndex > -1) {
        options.map.removeLayer(currentLayers[layerIndex]);
      }
      return;
    }


    path = options.map.groupImg;
  }


  getDims(path, options, (dims) => {
    const extent = [0, 0, dims.width, dims.height];
    const projection = new Projection({
      units: 'pixels',
      extent: extent,
    });

    let statImage = new Static({
      url: path,
      projection: projection,
      imageExtent: extent
    })
    let mapView = new View({
      projection: projection,
      center: getCenter(extent),
      resolution: 1,
    })

    options.map.setView(mapView);
    options.map.getView().fit(extent, options.map.getSize());

    let statLayer = new olImage({
      source: statImage
    });

    statLayer.isGroupingImage = true;

    let currentLayers = options.map.getLayers().array_;
    let layerIndex = currentLayers.findIndex(x => x.isGroupingImage);
    options.map.removeLayer(currentLayers[layerIndex]);

    options.callback(statLayer);
  })
}

const getDims = (url, options, callback) => {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    callback({
      height: img.height,
      width: img.width
    });
    img.onerror = function (e) {
      console.log("Not ok", e);
    }
  };
  // document.body.appendChild(img);
}

export default {
  staticLayer: staticLayer
}
