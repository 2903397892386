import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let panelSceneKey = 'PanelContent';
  if (window.IsMobile) {
    panelSceneKey = 'MainTabContent';
  }

  return {
    SelectedPolygons: state.ms_SelectedPolygons,
    HomeSelected: state.map_MoveToLocation,
    SaveButton: state.btn_Save,
    SaveData: state.dbo_SaveData,
    StepPanelSceneKey: panelSceneKey,
    StepPanelSceneName: state[panelSceneKey] ? state[panelSceneKey].Value : null,
    ActiveConsumerId: state.ms_ActiveConsumerId,
    AddressSelected: state.ms_AddressSelected,
    SaveId: state.dbo_SaveId,
    EntityMetadata: state.met_EntityMetadata,
    ContactLast: state.ms_ContactLast,
    RequirePhone: state.ms_RequirePhone,
    ConsumerValues: state.ent_Current.Properties ? state.ent_Current.Properties : null
  };
};

export class MySalesUserInfoSubmit extends React.PureComponent {
  componentDidMount() {
    if (!this.props.StepPanelSceneName)
      return;

    if (this.props.StepPanelSceneName === 'RequirePhonePanel') {
      if (this.props.ContactLast) {
        this.setSummaryPanel();
      } else {
        this.setStep2Panel();
      }
    }

    if (this.props.StepPanelSceneName === 'Step1Panel') { // User details form -> home / location search selection
      if(this.props.SaveData && this.props.SaveData.length > 0) {
        let fName = this.props.SaveData.find(x => x.Column === 'FirstName')?.Value
        let lName = this.props.SaveData.find(x => x.Column === 'LastName')?.Value
        let phone = this.props.SaveData.find(x => x.Column === 'PhoneNumber')?.Value
        let email = this.props.SaveData.find(x => x.Column === 'EMail')?.Value

        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_ContactInfo', 
          Value: { 
            Properties: { 
              fName: fName, 
              lName: lName, 
              phone: phone, 
              email: email 
            } 
          }
        }))

        let saveData = this.props.SaveData.slice();
        //Save progres in xmv.Progress
        let progBaseObj = {
          InsertKey: helpers.getInsertKey(),
          Table: 'xmv.Progress',
          IsBaseTable: false
        };
        saveData.push(helpers.saveDataItem(progBaseObj, 'ConsumerId', saveData[0].InsertKey));
        saveData.push(helpers.saveDataItem(progBaseObj, 'Step', 'Contact'));

        if (this.props.RequirePhone) {
          console.log('phone required bit hit');
          saveData[0].AfterSave = this.setRequirePhoneScene;
          this.props.dispatch(actions.UpdateProp({
            Key: 'ms_RequirePhone',
            Value: false
          }))
        } else if (this.props.ContactLast) {

          saveData[0].AfterSave = this.setSummaryPanel;
        } else {
          saveData[0].AfterSave = this.setStep2PanelPrint;
        }
        //Add save data item marking completion of Step1Panel (User info form)
        this.props.dispatch(actions.UpdateProp({
          Key: 'dbo_SaveData',
          Value: saveData
        }))
        this.props.dispatch(actions.UpdateProp({
          Key: 'btn_Save',
          Value: { ClickId: (this.props.SaveButton.ClickId + 1), Enabled: true }
        }))
      }
    }

    if (this.props.StepPanelSceneName === 'Step2Panel' && this.props.HomeSelected) { //step 2 -> step 3 panel

      let consumerId = this.props.ActiveConsumerId || helpers.getInsertKey();
      let saveData = [];
      let baseObj = {
        Id: consumerId,
        Table: 'xmv.Consumer',
        IsBaseTable: true,
        EntityTypeId: 2040
      };
      if (!this.props.ActiveConsumerId) {
        baseObj.Id = null;
        baseObj.InsertKey = consumerId;
        saveData.push(helpers.saveDataItem(baseObj, 'TenantId', this.props.EntityMetadata.CurrentTenant));
        this.props.dispatch(actions.UpdateProp({ Key: 'dbo_SaveData', Value: [] }));
      }
      saveData.push(helpers.saveDataItem(baseObj, 'LocationSearch', this.props.AddressSelected.SearchedText));
      saveData.push(helpers.saveDataItem(baseObj, 'Geometry', this.props.AddressSelected.CenterPoint));
      saveData.push(helpers.saveDataItem(baseObj, 'Address', this.props.AddressSelected.HouseAddress));
      saveData.push(helpers.saveDataItem(baseObj, 'State', this.props.AddressSelected.State));
      saveData.push(helpers.saveDataItem(baseObj, 'City', this.props.AddressSelected.City));
      saveData.push(helpers.saveDataItem(baseObj, 'Zip', this.props.AddressSelected.Zip));

      let progBaseObj = {
        InsertKey: helpers.getInsertKey(),
        Table: 'xmv.Progress',
        IsBaseTable: false
      };
      saveData.push(helpers.saveDataItem(progBaseObj, 'ConsumerId', consumerId));
      saveData.push(helpers.saveDataItem(progBaseObj, 'Step', 'Location'));

      //Add save data item marking completion of Step2Panel (Address selected from typeahead)
      actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'dbo_SaveId',
          Value: this.props.SaveId + 1
        }));
        if (!this.props.ActiveConsumerId) {
          this.props.dispatch(actions.UpdateProp({
            Key: 'ms_ActiveConsumerId',
            Value: result.Id
          }));
          // this.props.dispatch(actions.UpdateProp({
          //   Key: 'ent_Selected',
          //   Value: { EntityId: parseInt(result.Id), EntityTypeId: 2040 }
          // }))
        }
        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_ActiveStep',
          Value: 'Step2Panel'
        }))

        this.props.dispatch(actions.UpdateProp({
          Key: 'sr_PrintableEmail',
          Value: true
        }))
      });
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.StepPanelSceneKey,
        Value: { Value: 'Step3Panel', Enabled: true }
      }))
    }

    if(this.props.StepPanelSceneName === 'Step3Panel') {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_ActiveStep',
        Value: 'Step3Panel'
      }))
      this.props.dispatch(actions.UpdateProp({
        Key: 'sr_PrintableEmail',
        Value: true
      }))
    }
  }

  setRequirePhoneScene = (result) => {
    if (result && result.new) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_ActiveConsumerId',
        Value: result.new.EntityId
      }));
    }
    if (window.IsMobile) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'MainTabContent',
        Value: { Value: 'RequirePhonePanel', Enabled: true }
      }))
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'PanelContent',
        Value: { Value: 'RequirePhonePanel', Enabled: true }
      }))
    }
  }

  setSummaryPanel = (result) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_SendToSummary',
      Value: true
    }))
  }

  setStep2Panel = (result) => {
    if (result && result.new) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_ActiveConsumerId',
        Value: result.new.EntityId
      }));
    }
    if (window.IsMobile) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'MainTabContent',
        Value: { Value: 'Step2Panel', Enabled: true }
      }))
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'PanelContent',
        Value: { Value: 'Step2Panel', Enabled: true }
      }))
    }
  }

  setStep2PanelPrint = (result) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_ActiveStep',
      Value: 'Step1Panel'
    }))
    if (result && result.new) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_ActiveConsumerId',
        Value: result.new.EntityId
      }));
    }
    if (window.IsMobile) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'MainTabContent',
        Value: { Value: 'Step2Panel', Enabled: true }
      }))
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'PanelContent',
        Value: { Value: 'Step2Panel', Enabled: true }
      }))
    }
    this.props.dispatch(actions.UpdateProp({
      Key: 'sr_PrintableEmail',
      Value: true
    }))
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MySalesUserInfoSubmit);