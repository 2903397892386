import { DriverLocationRow, ForagerClient } from "@beehive-development/honeycomb";
import Honeycomb from "@beehive-development/honeycomb";
import "./DriverLocations.css";

import { Vector as SourceVector } from "ol/source";
import { Vector as LayerVector } from "ol/layer";
import { Feature, Map, Overlay } from "ol";
import { Geometry, Point } from "ol/geom";
import { Icon, Style } from "ol/style";
import { fromLonLat } from "ol/proj";

import { connect } from "react-redux";
import React from "react";

// @ts-expect-error
// See `config-override.js`, files with the `embedded` extension are treated as strings.
import embeddedUserIcon from "./UserIcon.svg.embedded";

type GeometryLayerVector = LayerVector<SourceVector<Geometry>>;
type StringKeysObject = { [key: string]: any };
type SynchronizeMode = "Upsert" | "Get";

type DriverLocationMetadata = {
    lastPinged?: Date | string;
    displayName?: string;
};

const activeStyle = new Style({
    image: new Icon({
        src: "data:image/svg+xml;base64," + window.btoa(embeddedUserIcon),
        opacity: 1,
        scale: 0.5,
    }),
});

const mapStateToProps = (state: StringKeysObject) => {
    return {
        userLocation: state.UserLocation as {
            Latitude: number,
            Longitude: number,
        } & StringKeysObject,
        userId: state.CurrentUser.Id as number,
        database: state.BlobSAS.Database as string,
        userList: state.met_EntityMetadata.UserList as {
            UserId: number;
            Name: string;
        }[],
    };
}

type DriverLocationsProps = ReturnType<typeof mapStateToProps> & {
    synchronizeMode: SynchronizeMode;
    map: Map;
};

type DriverLocationsState = {
    selectedDriver: DriverLocationMetadata | null;
}

export class DriverLocations extends React.PureComponent<DriverLocationsProps, DriverLocationsState> {
    // @ts-expect-error
    // Initialized in the `componentDidMount` method.
    foragerClient: ForagerClient<DriverLocationMetadata>;

    constructor(props: DriverLocationsProps) {
        super(props);

        this.state = { selectedDriver: null };
    }

    async componentDidMount() {
        // TODO: This should be parsed from the identity token Auth0 gives us, see the "Append User Metadata" action in Auth0 for details.
        // Convert `database` identifier to kebab case and use it as the `foragerTenant`
        const foragerTenant = this.props.database.toLowerCase()
            .replace("_", "-")
            .replace(" ", "-");

        const honeycombClient = new Honeycomb({});

        // Negotiate access to Forager and attempt to receive a `ForagerClient`
        this.foragerClient = await honeycombClient.forager.getForagerClient<DriverLocationMetadata>(this.props.userId, foragerTenant, {});

        const displayName = this.props.userList.find(x => x.UserId === this.props.userId);
        this.foragerClient.metadata!.displayName = displayName ? displayName.Name : undefined;
        this.setupMapWithMetadataPopup();

        if (this.props.synchronizeMode === "Upsert") {
            // Update the `DriverLocations` map layer on responses from sending the `UpsertDriverLocation` event
            this.foragerClient.onEventResponse("UpsertDriverLocationEventResponse", (event) => {
                this.updateMapWithDriverLocations(event.DriverLocations, "Upsert");
            });

            // Send a `UpsertDriverLocation` event every five seconds
            setInterval(() => {
                // TODO: This should be dynamically set based on the `foragerTenant`
                this.foragerClient.metadata!.lastPinged = new Date();

                this.foragerClient.sendUpsertDriverLocationEvent(
                    this.props.userLocation.Latitude, this.props.userLocation.Longitude
                );
            }, 5000);
        }

        if (this.props.synchronizeMode === "Get") {
            // Update the `DriverLocations` map layer on responses from sending the `GetDriverLocations` event
            this.foragerClient.onEventResponse("GetDriverLocationsEventResponse", (event) => {
                this.updateMapWithDriverLocations(event.DriverLocations, "Get");
            });

            // Send a `GetDriverLocations` event every five seconds
            setInterval(() => {
                this.foragerClient.sendGetDriverLocationsEvent();
            }, 5000);
        }

        // Start the `ForagerClient` to start listening for messages
        this.foragerClient.startService();
    }

    createDriverLocationsLayer() {
        const geometryLayer = new LayerVector({
            style: activeStyle,
        });

        geometryLayer.setZIndex(123456);

        geometryLayer.set("LayerId", "DriverLocations");
        this.props.map.addLayer(geometryLayer);

        return geometryLayer;
    }

    updateMapWithDriverLocations(driverLocations: DriverLocationRow[], mode: SynchronizeMode) {
        const driverLocationsLayer = this.props.map.getLayers().getArray()
            .find(x => x.get("LayerId") === "DriverLocations") as GeometryLayerVector
            ?? this.createDriverLocationsLayer();
        
        const aDayAgo = Date.now() - 1000 * 60 * 60 * 24;

        const driverLocationsInTheLast24Hours = driverLocations.filter((x) => {
            const locationDate = x.Timestamp
                ? new Date(x.Timestamp)
                : undefined;

            return locationDate ? locationDate.getTime() > aDayAgo : false;
        });

        const maybeFilteredDriverLocations = mode === "Upsert"
            ? driverLocationsInTheLast24Hours.filter(x => x.UserId !== this.props.userId)
            : driverLocationsInTheLast24Hours;

        const driverLocationsAsFeatures = maybeFilteredDriverLocations
            .map(driverLocation => {
                const coordinates = fromLonLat([driverLocation.Longitude, driverLocation.Latitude]);
                const feature = new Feature({ geometry: new Point(coordinates) });

                const metadataAsJson: DriverLocationMetadata = driverLocation.Metadata
                    ? JSON.parse(driverLocation.Metadata)
                    : undefined;
                feature.set("metadata", metadataAsJson);

                return feature;
            });

        const layerSource = new SourceVector({
            features: driverLocationsAsFeatures,

            // @ts-expect-error
            // TypeScript is not able to figure out that `SourceVector` extends from `Source`
            interpolate: false
        });

        driverLocationsLayer.setSource(layerSource);
        this.props.map.render();
    }

    setupMapWithMetadataPopup() {
        const layerFilter = (x: { get: (property: string) => any; }) => x.get("LayerId") === "DriverLocations";
        const popupElement = document.getElementById("driver-locations-metadata-overlay")!;

        const popupOverlay = new Overlay({
            positioning: "top-center",
            element: popupElement,
            stopEvent: false,
        });

        // Display the `popupOverlay` when clicking on a driver location
        this.props.map.on("click", (event) => {
            const feature = this.props.map.forEachFeatureAtPixel(event.pixel, x => x, { layerFilter });

            if (feature) {
                this.setState(oldState => {
                    return { ...oldState, selectedDriver: feature.get("metadata") };
                });

                popupOverlay.setPosition(event.coordinate);
                this.props.map.addOverlay(popupOverlay);
            } else {
                this.setState(oldState => {
                    return { ...oldState, selectedDriver: null };
                });
            }
        });

        // Display a pointer cursor when clicking on a driver location
        this.props.map.on("pointermove", (event) => {
            const eventPixel = this.props.map.getEventPixel(event.originalEvent);
            const isOnFeature = this.props.map.hasFeatureAtPixel(eventPixel, { layerFilter });
            
            (this.props.map.getTarget()! as HTMLElement).style.cursor = isOnFeature ? "pointer" : "";
        })

        // Disable the `popupOverlay` when moving on the map
        this.props.map.on("movestart", () => {
            this.setState(oldState => {
                return { ...oldState, selectedDriver: null };
            });
        });
    }

    render() {
        const selectedDriver = this.state.selectedDriver;

        if (selectedDriver === null) {
            return (
                <>
                    <div id="driver-locations-metadata-overlay" className="disabled" />
                </>
            );
        }

        return (
            <>
                <div id="driver-locations-metadata-overlay">
                    <span>
                        <strong>Name:</strong> { selectedDriver.displayName ?? "Unknown" }
                    </span>
                    <br />
                    <span>
                        <strong>Last Pinged:</strong> { selectedDriver.lastPinged ? new Date(selectedDriver.lastPinged).toLocaleTimeString("en-US") : "Unknown" }
                    </span>
                </div>
            </>
        );
    };
}

export default connect(mapStateToProps)(DriverLocations);