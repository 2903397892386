import cloneDeep from 'lodash.clonedeep';
import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from './../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    Settings: state.qry_Queries[ownProps.queryId],
    HighlightRow: state.qry_Queries[ownProps.queryId].highlightRow,
    ColumnSettings: state.qry_Queries[ownProps.queryId].allIds.map(x => state.qry_Queries[ownProps.queryId][x])
  };
}

let doubleClickWaiting = false;
const rowClick = (props) => {
  props.dispatch(actions.SetHighlightRow(props.rowNum));
  if (doubleClickWaiting) {
    loadEntity(props);
  }

  doubleClickWaiting = true;
  setTimeout(() => {
    doubleClickWaiting = false;
  }, 400);
}

const loadEntity = (props) => {
  let key = props.params.find(x => x.ParameterName === 'EntitySceneKey');
  let value = props.params.find(x => x.ParameterName === 'EntitySceneValue');
  let entitySource = props.params.find(x => x.ParameterName === 'EntitySource');

  let idIndex = props.ColumnSettings.find(x => x.Label === '!EntityId').ColumnId;
  let typeIndex = props.ColumnSettings.find(x => x.Label === '!EntityTypeId').ColumnId;

  let body = {
    EntityId: Number(props.row[idIndex]),
    EntityTypeId: Number(props.row[typeIndex])
  };

  props.dispatch(actions.UpdateProp({
    Key: key.ParameterValue,
    Value: {
      Value: value.ParameterValue,
      Enabled: true
    }
  }));

  actions.ApiRequest('Entity/GetEntity', body, (result) => {
    batch(() => {
      let paramVal = cloneDeep(body);

      if (result && result.Parent && result.Parent.EntityId) {
        paramVal = {
          EntityId: result.Parent.EntityId,
          EntityTypeId: result.Parent.EntityTypeId
        }

        props.dispatch(actions.UpdateProp({
          Key: 'ent_SelectedEvent',
          Value: cloneDeep(body)
        }));
      }

      props.dispatch(actions.UpdateProp({
        Key: entitySource.ParameterValue,
        Value: paramVal
      }));
    });
  });
}

const GridRow = props => {
  return (
    <div className={"grid-row " +
      (props.rowNum % 2 === 0 ? 'even' : 'odd') +
      (props.lastPin ? ' last-pin' : '') +
      (props.rowNum === props.HighlightRow ? ' highlight' : '')}
      onClick={() => { rowClick(props) }}>
      {props.columns.filter(x => props.Settings[x].Label[0] !== '!').map((x, idx) => (
        <div key={idx} className={"grid-cell" + (props.Settings[x].Filter ? ' filtered' : '')} style={{ minWidth: props.Settings[x].Width, maxWidth: props.Settings[x].Width }}>
          <span>{
            props.row ? props.Settings[x].DataType === 'Lookup' || props.Settings[x].ControlType === 'UserList' ?
              helpers.getLookupName(props.Settings[x].ControlType === 'UserList' ? props.Settings[x].ControlType : props.Settings[x].LookupSetId, props.row[x])
              : props.row[x] : ''}</span>
        </div>
      ))}
    </div>
  );
}

export default connect(mapStateToProps)(GridRow);