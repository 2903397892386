
import RegularShape from 'ol/style/RegularShape'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import Icon from 'ol/style/Icon';
import Circle from 'ol/style/Circle'

const styleClasses = {
  RegularShape: RegularShape,
  Fill: Fill,
  Stroke: Stroke,
  Text: Text,
  Icon: Icon,
  Circle: Circle
};

let library = {};

const buildStyleLibrary = (result, dynamicStyle = false) => {
  let styles;

  let multiStyles = [];
  styles = result.map(x => {
    try {
      let style = JSON.parse(x.StyleJSON) || [];
      if (Array.isArray(style[0])) {
        style.forEach((item, idx) => {
          multiStyles.push({
            Name: x.Name,
            StyleId: x.StyleId + '.' + (idx + 1),
            Style: item,
            StyleString: x.StyleJSON
          })
        });
        return null;
      } else {
        return {
          Name: x.Name,
          StyleId: x.StyleId,
          Style: style,
          StyleString: x.StyleJSON
        }
      }

    } catch (e) {
      console.log('Error while parsing style object JSON:');
      console.log(x);
      console.error(e);
      return null;
    }
  });

  styles = [...styles, ...multiStyles];

  styles = styles.filter(x => x !== null);

  let styleMap = getInstructions(styles);

  if (!dynamicStyle) {
    library = createStyles(styleMap);

    styles.forEach(style => {
      library[style.StyleId].Name = style.Name;
      library[style.StyleId].StyleId = style.StyleId;
      library[style.StyleId].StyleString = style.StyleString;
    });

    console.log('.:!Style Object!:.');
    console.log(library);
    console.log('.:!Style Object!:.');
  } else {
    let tempStyle = createStyles(styleMap);
    styles.forEach(style => {
      tempStyle[style.StyleId].Name = style.Name;
      tempStyle[style.StyleId].StyleId = style.StyleId;
      tempStyle[style.StyleId].StyleString = style.StyleString;
    });
    return tempStyle;
  }
}

const getInstructions = (arr) => {
  let styleMap = arr.map(item => {
    return {
      styleId: item.StyleId,
      instructions: item.Style.map((x) => {
        return {
          key: x.slice(0, x.indexOf(':')),
          val: parsedValue(x.slice(x.indexOf(':') + 1, x.length).trim())
        }
      })
    }
  });

  styleMap.forEach(style => {
    style.objects = style.instructions.filter(x => typeof x.val === 'string' && x.val.indexOf('new ol.') !== -1);
    style.objects.forEach(obj => {
      obj.props = style.instructions
        .filter(x =>
          x.key.indexOf(obj.key) === 0 &&
          x.key.replace(obj.key + '.', '').split('.').length === 1 &&
          (typeof x.val !== 'string' || x.val.indexOf('new ol.') === -1))
        .map(x => {
          return {
            key: x.key.replace(obj.key + '.', ''),
            val: x.val
          }
        });
    });
  });

  return styleMap;
}

const createStyles = (styleMap) => {
  styleMap.forEach(style => {
    let styleArray = [];
    let setStyleFuncs = [];
    style.objects.sort(sortByDepth).forEach(obj => {
      let name = obj.val.slice(obj.val.lastIndexOf('.') + 1);

      let styleProps = style.objects
        .filter(x =>
          x.key.indexOf(obj.key) === 0 &&
          x.key.replace(obj.key + '.', '').split('.').length === 1)
        .map(x => {
          return {
            val: x.val,
            key: x.key.slice(x.key.lastIndexOf('.') + 1)
          }
        });

      let arg = [...obj.props, ...styleProps].reduce((prev, next) => {
        prev[next.key] = next.val;
        return prev;
      }, {});

      obj.val = new styleClasses[name](arg);

      if (arg.color === '[c1]' || arg.color === '[c2]' || arg.color === '[c]') {
        let bhSetColor = (fet) => {
          if (fet.c1 && arg.color === '[c1]') {
            obj.val.setColor(fet.c1);
          } else if (fet.c2 && arg.color === '[c2]') {
            obj.val.setColor(fet.c2);
          } else if (fet.c && arg.color === '[c]') {
            obj.val.setColor(fet.c);
          }
        }
        setStyleFuncs.push(bhSetColor);
      }

      if (arg.text === '[l1]' || arg.text === '[l2]') {
        let bhSetText = (fet) => {
          if (fet.l1 && arg.text === '[l1]') {
            obj.val.setText(fet.l1);
          } else if (fet.l2 && arg.text === '[l2]') {
            obj.val.setText(fet.l2);
          }
        }
        setStyleFuncs.push(bhSetText);
      }

      if (arg.width === '[w1]' || arg.width === '[w2]' || arg.width === '[w]') {
        let bhSetWidth = (fet) => {
          if (fet.w1 && arg.width === '[w1]') {
            obj.val.setWidth(fet.w1);
          } else if (fet.w2 && arg.width === '[w2]') {
            obj.val.setWidth(fet.w2);
          } else if (fet.w && arg.width === '[w]') {
            obj.val.setWidth(fet.w);
          }
        }
        setStyleFuncs.push(bhSetWidth);
      }

      if (arg.linedash === '[d1]' || arg.linedash === '[d2]' || arg.linedash === '[d]') {
        let bhSetLineDash = (fet) => {
          if (fet.d1 && arg.linedash === '[d1]') {
            obj.val.setLineDash(fet.d1);
          } else if (fet.d2 && arg.linedash === '[d2]') {
            obj.val.setWidth(fet.d2);
          } else if (fet.d && arg.linedash === '[d]') {
            obj.val.setWidth(fet.d);
          }
        }
        setStyleFuncs.push(bhSetLineDash);
      }

    });

    style.objects = style.objects.filter(x => x.key.split('.').length === 2);

    let styleGroups = style.objects.reduce((prev, next) => {
      let idx = next.key[0];
      if (!prev[idx])
        prev[idx] = [];

      next.key = next.key.slice(next.key.lastIndexOf('.') + 1);
      prev[idx].push(next);
      return prev;
    }, []).filter(x => x !== undefined);

    styleGroups.forEach(group => {
      let arg = group.reduce((prev, next) => {
        prev[next.key] = next.val;
        return prev;
      }, {});

      styleArray.push(new Style(arg));
    });

    style.olStyle = styleArray;
    style.setStyleFuncs = setStyleFuncs;
  });

  return styleMap.reduce((prev, next) => {
    prev[next.styleId] = next.olStyle;
    prev[next.styleId].instructions = next.instructions;
    prev[next.styleId].bhSetStyle = (arg) => {
      next.setStyleFuncs.forEach(fn => fn(arg));
      next.olStyle.forEach(olSt => {
        if (olSt.getImage) {
          let olImg = olSt.getImage();
          if (olImg && olImg.getRadius && olImg.setRadius) {
            olImg.setRadius(olImg.getRadius());
          }
        }
      })
    }
    return prev;
  }, {});
}

const parsedValue = (val) => {
  let parsed;

  try {
    parsed = JSON.parse(val);
    parsed = JSON.stringify(val);
  } catch (e) { }

  if (parsed !== undefined && val === parsed.replace(/\"/g, ""))
    return JSON.parse(val);

  return val;
}

const sortByDepth = (a, b) => {
  let aVal = a.key.split('.').length;
  let bVal = b.key.split('.').length;
  return aVal > bVal ? -1 : bVal > aVal ? 1 : 0;
}

const getStyleLibrary = () => {
  return library;
}

export default {
  getStyleLibrary: getStyleLibrary,
  buildStyleLibrary: buildStyleLibrary
}


