import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEvent: state.ent_CurrentEvent,
    EventSave: state.btn_EventSave,
    CurrentUser: state.CurrentUser
  };
};

export class SubmitJournal extends React.PureComponent {

  componentDidMount() {
    if (!this.props.CurrentEvent || this.props.CurrentEvent.sr_SubmitJournalComplete === true)
      return;

    let statusProp = this.props.CurrentEvent.Properties.find(x => x.Name === 'StatusLId');
    let supervisorProp = this.props.CurrentEvent.Properties.find(x => x.Name === 'SupervisorEId');

    if (statusProp) {
      let lookupItems = helpers.getLookupItems(statusProp.LookupSetId);
      if (lookupItems) {
        let submitStatus = lookupItems.find(x => x.Name1 === 'Pending Approval' && x.EntityTypeId === this.props.CurrentEvent.EntityTypeId);
        let approvedStatus = lookupItems.find(x => x.Name1 === 'Complete' && x.EntityTypeId === this.props.CurrentEvent.EntityTypeId);
        let rejectedStatus = lookupItems.find(x => x.Name1 === 'Rejected' && x.EntityTypeId === this.props.CurrentEvent.EntityTypeId);

        if (approvedStatus && submitStatus && rejectedStatus) {
          if (Number(statusProp.Value) === approvedStatus.LookupItemId)
            return;

          this.props.CurrentEvent.sr_SubmitJournalComplete = true;

          let baseObj = {
            ...statusProp.SaveData,
            IsEventData: true,
            OnFailedValidate: () => {
              this.props.CurrentEvent.sr_SubmitJournalComplete = false;
            }
          }

          let val = '';

          if (this.props.Trigger === 'reject') {
            val = rejectedStatus.LookupItemId;
          } else if (this.props.Trigger === 'approve') {
            val = approvedStatus.LookupItemId;
          } else {
            val = submitStatus.LookupItemId;
          }

          helpers.addSaveData(this.props.dispatch, baseObj, statusProp.SaveData.Column, val);

          if (this.props.Trigger === 'approve') {
            helpers.addSaveData(this.props.dispatch, baseObj, supervisorProp.SaveData.Column, this.props.CurrentUser.Id);
            let body = {
              SWID: this.props.SWID,
              Parameter: this.props.CurrentEvent.EntityId //Ops event id
            }

            actions.ApiRequest('List/GetList', body, (result) => {
              console.log(result);
            });
          }

          this.props.dispatch(actions.UpdateProp({
            Key: 'btn_EventSave',
            Value: {
              ...this.props.EventSave,
              ClickId: this.props.EventSave.ClickId + 1
            }
          }));
        }
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(SubmitJournal);