import { BlobServiceClient } from "@azure/storage-blob";
import { getEmailDetailsAsHtml } from "./EmailDetails";
import { connect } from "react-redux";
import React from "react";

type StringKeysObject = { [key: string]: any };

const mapStateToProps = (state: StringKeysObject, ownProps: StringKeysObject) => {
    return {
        TenantId: state.met_EntityMetadata.CurrentTenant,
        BlobStoragePublicSAS: state.BlobSASPublic,
        ConsumerId: state.ms_ActiveConsumerId,
        MainTabContent: state.MainTabContent ? state.MainTabContent.Value : null,
        Step: state.ms_ActiveStep ? state.ms_ActiveStep : null,
    };
}

export class MV_PrintableEmail extends React.PureComponent<ReturnType<typeof mapStateToProps>> {
    async componentDidMount() {
        await this.generatePrintableEmail();
    }

    async generatePrintableEmail() {
        const emailDetailsHtml = await getEmailDetailsAsHtml(this.props.ConsumerId,this.props.Step);
    
        const lastModifiedTime = new Date().getTime();
        const fileExtension = "html";
        const fileType = "text/html";

        const emailDetailsFile = new File([emailDetailsHtml], `EmailDetails.${fileExtension}`, { type: fileType, lastModified: lastModifiedTime });
        const filePath = `PrintableEmails/${this.props.TenantId}/${this.props.ConsumerId}/EmailDetails.${fileExtension}`;
        await this.uploadToBlobStorage(emailDetailsFile, filePath, fileType);
    }

    async uploadToBlobStorage(file: File, filePath: string, fileType: string) {
        const blobServiceClient = new BlobServiceClient(this.props.BlobStoragePublicSAS.Key);
        const blobContainerClient = blobServiceClient.getContainerClient("");

        const blockBlobClient = blobContainerClient.getBlockBlobClient(filePath);
    
        await blockBlobClient.uploadData(file, {
            blobHTTPHeaders: { blobContentType: fileType }
        });
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps)(MV_PrintableEmail);