
import React from 'react';

const PayPeriodTotal = props => {
  let onClick = (e) => {
    props.parentClick(props.card);
  }
  let selected = props.selected && props.selected.Id === props.card.Id && props.card.Id !== undefined;
  return (
    <React.Fragment>
      <div className={"pay-period-total" + (selected ? ' selected' : '')} onClick={onClick}>
        <div className="sub-title">{props.card.Text2}</div>
        <div className="title">{props.card.Text1}</div>
        <div className="pending-totals">
          <div className="users">
            <div>Users:</div>
            <div className="qty">{props.card.PendingUsers}</div>
          </div>
          <div className="hours">
            <div>Hours:</div>
            <div className="qty">{props.card.PendingHours.toFixed(2)}</div>
          </div>
        </div>
        <div className="complete-totals">
          <div className="users">
            <div>Users:</div>
            <div className="qty">{props.card.CompleteUsers}</div>
          </div>
          <div className="hours">
            <div>Hours:</div>
            <div className="qty">{props.card.CompleteHours.toFixed(2)}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PayPeriodTotal