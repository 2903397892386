import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    GroupingEntity: state.ent_GroupEntitySelected,
    Metadata: state.met_EntityMetadata
  };
};

export class GroupingSave extends React.PureComponent {
  componentDidMount() {
    if (this.props.GroupingEntity && this.props.GroupingEntity.IsNew) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_GroupEntitySelected',
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_GroupEntity',
        Value: null
      }));
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(GroupingSave);