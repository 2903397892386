import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return null;
};

export class ReloadPage extends React.PureComponent {

  componentDidMount() {
    window.location.reload()
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(ReloadPage);