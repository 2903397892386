import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../Utils/Helpers';
import { MagnifyingGlass } from '../../../../../Icons/Icons';
import EntityProps from './TriggerTypes/EntityProp';
import ButtonClick from './TriggerTypes/ButtonClick';
import SceneLoad from './TriggerTypes/SceneLoad';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class TriggerEditor extends React.PureComponent {
  state = {
    Types: [{
      TypeId: 0,
      Label: '',
      OtherProps: null
    },
    {
      TypeId: 1,
      Label: 'Entity Property',
      OtherProps: EntityProps
    },
    {
      TypeId: 2,
      Label: 'Button Click',
      OtherProps: ButtonClick
    },
    {
      TypeId: 3,
      Label: 'Scene Load',
      OtherProps: SceneLoad
    },
    {
      TypeId: 4,
      Label: 'Before Save',
      OtherProps: null
    }],
    TypeId: 0,
    GroupId: 1,
    IsAnd: true,
    Text1: '',
    Text2: '',
    Text3: '',
    ItemId: null
  }

  componentDidMount() {
    if (this.props.editItem) {
      let item = this.props.editItem;
      this.setState({
        TypeId: item.TypeId,
        GroupId: item.GroupId,
        IsAnd: item.IsAnd,
        Text1: item.Text1,
        Text2: item.Text2,
        Text3: item.Text3,
        ItemId: item.ItemId
      });
    }
  }

  setType = (e) => {
    let type = this.state.Types.find(x => x.TypeId === Number(e.target.value));
    this.setState({
      TypeId: type.TypeId,
      Text1: '',
      Text2: '',
      Text3: ''
    });
  }

  setGroup = (e) => {
    this.setState({ GroupId: e.target.valueAsNumber });
  }

  toggleAnd = () => {
    this.setState({ IsAnd: !this.state.IsAnd });
  }

  setProp = (e, name) => {
    console.log('setting ' + name + ': ' + e.target.value);
    this.setState({ [name]: e.target.value });
  }

  addTrigger = () => {
    this.props.addTrigger({
      TypeId: this.state.TypeId,
      GroupId: this.state.GroupId,
      IsAnd: this.state.IsAnd,
      Text1: this.state.Text1,
      Text2: this.state.Text2,
      Text3: this.state.Text3,
      ItemId: this.state.ItemId
    });
  }

  render() {
    let curType = this.state.Types.find(x => x.TypeId === this.state.TypeId);
    let OtherProps = curType ? curType.OtherProps : null;
    return (
      <div className="new-trigger">
        <div className="wf-title">
          {!this.state.ItemId && <div>New Trigger</div>}
          {this.state.ItemId && <div>{'Editing Trigger ' + this.state.ItemId}</div>}
        </div>
        <div className="side-bar-prop">
          <div>Type:</div>
          <select value={this.state.TypeId} onChange={this.setType}>
            {this.state.Types.map((x, idx) => (
              <option key={idx} value={x.TypeId}>{x.Label}</option>
            ))}
          </select>
        </div>
        <div className="horizontal-props">
          <div className="side-bar-prop">
            <div>Group:</div>
            <input type="number" value={this.state.GroupId} onChange={this.setGroup} />
          </div>
          <div className="side-bar-prop">
            <div>Condition:</div>
            <div className="bool-toggle" onClick={this.toggleAnd}>
              {this.state.IsAnd && <div>And</div>}
              {!this.state.IsAnd && <div>Or</div>}
            </div>
          </div>
        </div>
        {OtherProps && <OtherProps setProp={this.setProp} state={this.state} />}
        {(curType && curType.TypeId !== 0) &&
          <div className="wf-btn-bar">
            <div className="wf-btn" onClick={this.props.cancelTrigger}>
              <div>Cancel</div>
            </div>
            <div className="wf-btn" onClick={this.addTrigger}>
              <div>Done</div>
            </div>
          </div>}
      </div>
    )
  }
}

export default connect(mapStateToProps)(TriggerEditor);