import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep'
import DesktopInterface from './Desktop/DesktopInterface';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let entKeyProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let storage = ownProps.widget.Parameters.find(x => x.ParameterName === 'InvWarehousing');
  let entityKey = entKeyProp ? entKeyProp.ParameterValue : 'ent_CurrentEvent';
  let data
  if(storage && storage.ParameterValue) {
    data = {
      EntityKey: entityKey,
      Time: {
        Label: 'Time',
        HeaderColor: '#7b354d',
        Fields: [
          {
            GobAutofill: entityKey + '.EntityId',
            Value: '',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'EntityId'
            }
          },
          {
            GobAutofill: entityKey + '.EntityTypeId',
            Value: '',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'EntityTypeId'
            }
          },
          {
            Key: 'Id',
            Value: '',
          },
          {
            Label: 'Date',
            Key: 'StartDate',
            ControlType: 'DatePicker',
            Width: '90px',
            Value: '',
            DefaultValue: 'now',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'StartDate'
            }
          },
          {
            Label: 'User',
            Key: 'UserId',
            ControlType: 'UserList',
            Width: '140px',
            Value: '',
            LookupSetId: 'UserList',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'UserId'
            }
          },
          {
            Label: 'Duration',
            Key: 'Duration',
            ControlType: 'NumberBox',
            Width: '85px',
            Value: '',
            MinuteToHours: true,
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'Duration'
            }
          },
          {
            Label: 'Note',
            Key: 'Note',
            ControlType: 'RichTextBox',
            Width: '1',
            Value: '',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'Note'
            }
          }],
        Rows: []
      },
      Material: {
        Label: 'Material',
        HeaderColor: '#536142',
        Fields: [
          {
            Key: 'Id',
            Value: '',
          },
          {
            GobAutofill: entityKey + '.EntityId',
            Value: '',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'EntityId'
            }
          },
          {
            GobAutofill: entityKey + '.EntityTypeId',
            Value: '',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'EntityTypeId'
            }
          },
          {
            ControlType: 'StorageDropdown',
            Label: 'Storage',
            Key: 'Storage',
            Width: '120px',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'StorageId'
            },
            Value: null
          },
          {
            ControlType: 'ComponentDropdown',
            Label: 'Component',
            Key: 'Component',
            Width: '120px',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'ItemId'
            },
            Value: null
          },
          {
            ControlType: 'PartDropdown',
            Label: 'Part',
            Key: 'Part',
            Width: '200px',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'ItemId'
            },
            Value: null
          },
          {
            Label: 'Unit Cost',
            Key: 'UnitCost',
            ControlType: 'NumberBox',
            Width: '80px',
            Value: '',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'UnitCost'
            }
          },
          {
            Label: 'Qty',
            Key: 'Qnty',
            ControlType: 'NumberBox',
            Width: '80px',
            Value: '',
            IsRequired: true,
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'Qnty'
            }
          },
          {
            Label: 'Cost',
            Key: 'Cost',
            ControlType: 'CostCalculation',
            Width: '80px',
            Value: ''
          },
          {
            Key: 'ReceivedId',
            Value: null
          },
          {
            Label: 'Note',
            Key: 'Note',
            ControlType: 'RichTextBox',
            Width: '1',
            Value: '',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'Note'
            }
          }],
        Rows: []
      },
      Equipment: {
        Label: 'Equipment',
        HeaderColor: '#344d6c',
        Fields: [
          {
            Key: 'Id',
            Value: '',
          },
          {
            Label: 'Type',
            Key: 'Type',
            ControlType: 'DropDown',
            Width: '120px',
            Value: '',
            LookupSetId: 1100
          },
          {
            Label: 'Equipment',
            Key: 'Equipment',
            ControlType: 'DropDown',
            Width: '200px',
            Value: '',
            LookupSetId: 1101
          },
          {
            Label: 'Duration',
            Key: 'Duration',
            MinuteToHours: true,
            ControlType: 'NumberBox',
            Width: '85px',
            Value: ''
          },
          {
            Label: 'Note',
            Key: 'Note',
            ControlType: 'RichTextBox',
            Width: '1',
            Value: ''
          }
        ],
        Rows: []
      }
    }
  } else {
    data = {
      EntityKey: entityKey,
      Time: {
        Label: 'Time',
        HeaderColor: '#7b354d',
        Fields: [
          {
            GobAutofill: entityKey + '.EntityId',
            Value: '',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'EntityId'
            }
          },
          {
            GobAutofill: entityKey + '.EntityTypeId',
            Value: '',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'EntityTypeId'
            }
          },
          {
            Key: 'Id',
            Value: '',
          },
          {
            Label: 'Date',
            Key: 'StartDate',
            ControlType: 'DatePicker',
            Width: '90px',
            Value: '',
            DefaultValue: 'now',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'StartDate'
            }
          },
          {
            Label: 'User',
            Key: 'UserId',
            ControlType: 'UserList',
            Width: '140px',
            Value: '',
            LookupSetId: 'UserList',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'UserId'
            }
          },
          {
            Label: 'Duration',
            Key: 'Duration',
            ControlType: 'NumberBox',
            Width: '85px',
            Value: '',
            MinuteToHours: true,
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'Duration'
            }
          },
          {
            Label: 'Note',
            Key: 'Note',
            ControlType: 'RichTextBox',
            Width: '1',
            Value: '',
            SaveData: {
              Table: 'ttr.TimeEntry',
              Column: 'Note'
            }
          }],
        Rows: []
      },
      Material: {
        Label: 'Material',
        HeaderColor: '#536142',
        Fields: [
          {
            Key: 'Id',
            Value: '',
          },
          {
            GobAutofill: entityKey + '.EntityId',
            Value: '',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'EntityId'
            }
          },
          {
            GobAutofill: entityKey + '.EntityTypeId',
            Value: '',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'EntityTypeId'
            }
          },
          {
            ControlType: 'ComponentDropdown',
            Label: 'Component',
            Key: 'Component',
            Width: '120px',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'ItemId'
            },
            Value: null
          },
          {
            ControlType: 'PartDropdown',
            Label: 'Part',
            Key: 'Part',
            Width: '200px',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'ItemId'
            },
            Value: null
          },
          {
            Label: 'Unit Cost',
            Key: 'UnitCost',
            ControlType: 'NumberBox',
            Width: '80px',
            Value: '',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'UnitCost'
            }
          },
          {
            Label: 'Qty',
            Key: 'Qnty',
            ControlType: 'NumberBox',
            Width: '80px',
            Value: '',
            IsRequired: true,
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'Qnty'
            }
          },
          {
            Label: 'Cost',
            Key: 'Cost',
            ControlType: 'CostCalculation',
            Width: '80px',
            Value: ''
          },
          {
            Key: 'ReceivedId',
            Value: null
          },
          {
            Label: 'Note',
            Key: 'Note',
            ControlType: 'RichTextBox',
            Width: '1',
            Value: '',
            SaveData: {
              Table: 'inv.EntityItem',
              Column: 'Note'
            }
          }],
        Rows: []
      },
      Equipment: {
        Label: 'Equipment',
        HeaderColor: '#344d6c',
        Fields: [
          {
            Key: 'Id',
            Value: '',
          },
          {
            Label: 'Type',
            Key: 'Type',
            ControlType: 'DropDown',
            Width: '120px',
            Value: '',
            LookupSetId: 1100
          },
          {
            Label: 'Equipment',
            Key: 'Equipment',
            ControlType: 'DropDown',
            Width: '200px',
            Value: '',
            LookupSetId: 1101
          },
          {
            Label: 'Duration',
            Key: 'Duration',
            MinuteToHours: true,
            ControlType: 'NumberBox',
            Width: '85px',
            Value: ''
          },
          {
            Label: 'Note',
            Key: 'Note',
            ControlType: 'RichTextBox',
            Width: '1',
            Value: ''
          }
        ],
        Rows: []
      }
    }
  }
  

  let materialType = ownProps.widget.Parameters.find(x => x.ParameterName === 'MaterialType');
  let hideProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'HideFields');
  let isMobile = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsMobile');
  let primary = ownProps.widget.Parameters.find(x => x.ParameterName === 'PrimaryField');
  let showWageCode = ownProps.widget.Parameters.find(x => x.ParameterName === 'ShowWageCode');

  let resources = state[entityKey] ? (state[entityKey].Resources || data) : data;

  if ((state[entityKey] && !state[entityKey].Resources) && state.ent_SavedResource && state.ent_SavedResource[state[entityKey].EntityId]) {
    resources = state.ent_SavedResource[state[entityKey].EntityId];
  }

  if (showWageCode && helpers.stringToBool(showWageCode.ParameterValue)) {
    let defaultValue = helpers.getLookupItems(1329).find(x => x.Name1.includes('Regular'));
    data.Time.Fields.splice(6, 0, {
      Label: 'Wage Code',
      Key: 'WageCodeId',
      ControlType: 'DropDown',
      Width: '215px',
      Value: '',
      DefaultValue: defaultValue ? defaultValue.LookupItemId : null,
      LookupSetId: 1329,
      SaveData: {
        Table: 'ttr.TimeEntry',
        Column: 'WageCodeId'
      }
    })
  }

  return {
    Resources: resources,
    Entity: state[entityKey],
    Asset: state.ent_Current,
    SaveId: state.dbo_SaveId,
    CancelId: state.dbo_CancelId,
    MaterialType: materialType ? materialType.ParameterValue : null,
    HideFields: hideProp ? hideProp.ParameterValue : null,
    IsMobile: isMobile ? helpers.stringToBool(isMobile.ParameterValue) : false,
    PrimaryField: primary ? primary.ParameterValue : null,
    CurrentUserId: state.CurrentUser ? state.CurrentUser.Id : null,
    SaveDataKey: entityKey === 'ent_CurrentEvent' ? 'dbo_EventSaveData' : 'dbo_SaveData',
    EntityMetadata: state.met_EntityMetadata,
    ActiveComponent: state.inv_ActiveComponent,
    ActiveStorage: state.inv_ActiveStorage,
    SelectedEntity: entityKey === 'ent_CurrentEvent' ? state['ent_SelectedEvent'] : state['ent_Selected']
  };
}

let _shellWaiting = false;

export class Resources extends React.PureComponent {
  state = {
    Entry: ''
  };

  componentDidMount() {
    if (this.props.Resources.RequestSent !== this.props.SaveId) {
      this.getMaterials();
    }
  }

  componentDidUpdate(prevProps) {
    let saved = helpers.propDidChange(this.props, prevProps, 'SaveId');
    let cancelled = helpers.propDidChange(this.props, prevProps, 'CancelId');
    if (saved || cancelled) {
      this.getMaterials();
    } else {
      if (_shellWaiting && this.props.Entity && !this.props.Entity.IsShell) {
        _shellWaiting = false;
        this.getMaterials();
      }
    }
  }

  getMaterials = () => {
    if (!this.props.Entity)
      return;

    if (this.props.Entity.IsShell) {
      _shellWaiting = true;
      return;
    }

    let body = {
      AgencyId: this.props.Entity.AgencyId || this.props.Asset.AgencyId,
      AssetTypeId: this.props.Asset.EntityTypeId,
      EventId: this.props.SelectedEntity.EntityId,
      EventTypeId: this.props.Entity.EntityTypeId
    }

    actions.ApiRequest('Entity/GetEntityMaterials', body, (result) => {
      let newResource = cloneDeep(this.props.Resources);
      newResource.RequestSent = this.props.SaveId;
      if (result && result.Time) {
        result.Time.forEach((x) => {
          x.Duration = Math.round((x.Duration / 60) * 100, 2) / 100;
        });
        newResource.Time.Rows = result.Time;
      }
      if (result && result.Material) {
        newResource.Material.Rows = result.Material;
      }
      if (result && result.Components) {
        newResource.Components = result.Components;
      }
      if(result && result.Storages) {
        newResource.Storages = result.Storages;
      }
      this.props.dispatch(actions.SetEventResources(newResource));
    });
  }

  setEntryType = (val) => {
    if (!val)
      return;

    this.setState({ Entry: val });
    let newResource = cloneDeep(this.props.Resources);
    this.clearActive(newResource);
    newResource[val].Fields.forEach(x => {
      x.FailedValidate = false;
      x.Value = x.DefaultValue || '';
      if (x.ControlType === 'UserList') {
        x.Value = this.props.CurrentUserId || '';
      }
    });

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'inv_ActiveComponent',
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'inv_ActivePart',
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'inv_ActiveStorage',
        Value: null
      }))
      this.props.dispatch(actions.SetEventResources(newResource));
    });
  }

  addRow = (item, refresh) => {
    let resources = this.props.Resources;
    let rows = resources[this.state.Entry].Rows;

    let rowItem = this.createItemFromFields(item);
    if (rowItem.FailedValidate) {
      refresh();
      return;
    }

    let fields = this.resetFieldsArray(this.state.Entry);

    let newObj = {
      ...resources,
      [this.state.Entry]: {
        ...resources[this.state.Entry],
        Fields: fields,
        Rows: [...rows, rowItem]
      }
    };

    batch(() => {
      this.addSaveDataForFields(newObj[this.state.Entry].Fields, rowItem);
      this.props.dispatch(actions.SetEventResources(newObj));
    });
    this.setState({ Entry: '' });
  }

  updateRow = (item, refresh) => {
    let newResource = cloneDeep(this.props.Resources);
    let idx = newResource[this.state.Entry].Rows.findIndex(x => x.Active);
    let rowItem = this.createItemFromFields(item);
    if (rowItem.FailedValidate) {
      refresh();
      return;
    }

    rowItem.Dirty = true;
    newResource[this.state.Entry].Rows[idx] = rowItem;
    newResource[this.state.Entry].Rows[idx].Active = false;

    batch(() => {
      this.addSaveDataForFields(newResource[this.state.Entry].Fields, rowItem)
      this.props.dispatch(actions.SetEventResources(newResource));
    });
    this.setState({ Entry: '' });
  }

  // addReceivedInventorySaveData = (item, receivedId) => {

  //   let receivedBaseObj = {
  //     Id: receivedId,
  //     InsertKey: receivedId || helpers.getInsertKey(),
  //     Table: 'inv.Received',
  //     SaveQueue: 'dbo_EventSaveData',
  //     IsBaseTable: false
  //   };
  //   let receivedSaveData = [
  //     helpers.saveDataItem(receivedBaseObj, 'EventId', this.props.Entity.EntityId), //currentEvent.EntityId
  //     helpers.saveDataItem(receivedBaseObj, 'ItemId', item.Part), //item.ItemId etc
  //     helpers.saveDataItem(receivedBaseObj, 'Qnty', (Number(item.Qnty) * -1)), //item.Qnty
  //     helpers.saveDataItem(receivedBaseObj, 'Note', item.Note),
  //     helpers.saveDataItem(receivedBaseObj, 'IsDeleted', false),
  //     helpers.saveDataItem(receivedBaseObj, 'EntityItemId', item.Id)
  //   ];
  //   receivedSaveData.forEach(sd => {
  //     this.props.dispatch(actions.AddSaveData(sd));
  //   });
  // }

  addSaveDataForFields = (fields, item) => {
    let insertKey = this.props.Resources[this.state.Entry].UpdatingKey || helpers.getInsertKey();

    // if (this.state.Entry === 'Material') {
    //   this.addReceivedInventorySaveData(item, receivedId);
    // }

    fields.forEach((field) => {
      if (!field.SaveData)
        return;

      let val = item[field.Key];
      if (field.ControlType === 'DatePicker' && val) {
        // let pieces = val.split('-');
        // if (pieces.length === 3) {
        //   let dateObj = new Date();
        //   let month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        //   let day = ('0' + dateObj.getDate()).slice(-2);
        //   let year = dateObj.getUTCFullYear();
        //   val = year + '-' + month + '-' + day;
        // }
      }

      if (field.MinuteToHours && val) {
        val = val * 60;
      }

      if (!item.Id && !item.insertKey)
        item.insertKey = insertKey;

      if (this.state.Entry === 'Material' && field.Key === 'Qnty') {
        val = val * -1;
      }

      this.props.dispatch(actions.AddSaveData({
        Id: item.Id || null,
        GobAutofill: field.GobAutofill,
        InsertKey: item.Id ? null : insertKey.toString(),
        Table: field.SaveData.Table,
        Column: field.SaveData.Column,
        Value: val,
        SaveQueue: this.props.SaveDataKey,
        IsBaseTable: true
      }));
    });
  }

  clearEntry = () => {
    let fields = this.resetFieldsArray(this.state.Entry);
    let newObj = {
      ...this.props.Resources,
      [this.state.Entry]: {
        ...this.props.Resources[this.state.Entry],
        Fields: fields
      }
    };
    this.props.dispatch(actions.SetEventResources(newObj));
  }

  createItemFromFields = (item) => {
    return item.Fields.reduce((prev, next) => {
      if (next.IsRequired && !next.Value && next.Value !== 0) {
        next.FailedValidate = true;
        prev.FailedValidate = true;
      }
      if (next.ControlType === 'DatePicker') {
        prev[next.Key] = helpers.shortDate(next.Value);
      } else {
        if (next.Key == 'Component' && this.props.ActiveComponent && !next.Value) {
          prev[next.Key] = this.props.ActiveComponent.Id;
        } else if(next.Key === 'Storage' && this.props.ActiveStorage && !next.Value) { 
          prev[next.Key] = this.props.ActiveStorage.Id;
        } else {
          prev[next.Key] = next.Value;
        }
      }
      return prev;
    }, {});
  }

  resetFieldsArray = (entryType) => {
    let fields = [];
    this.props.Resources[entryType].Fields.forEach(field => {
      fields.push({
        ...field,
        Value: ''
      });
    });
    return fields;
  }

  setEntryItem = (entryType, item, idx) => {
    let newResource = cloneDeep(this.props.Resources);
    let isActive = newResource[entryType].Rows[idx].Active;
    this.clearActive(newResource);

    newResource[entryType].Rows[idx].Active = !isActive;
    newResource[entryType].Fields.forEach(field => {
      if (isActive) {
        field.Value = '';
      } else {
        field.Value = item[field.Key];
        if (field.ControlType === 'DatePicker') {
          field.Value = helpers.formatDate(field.Value + ' cst');
        }
      }
      if (field.ControlType === 'PartDropdown') {
        let part = null;
        this.props.Resources.Components.forEach(x => {
          if (x.Parts && x.Parts.find(y => y.Id == field.Value)) {
            part = x.Parts.find(y => y.Id == field.Value)
          }
        })

        this.props.dispatch(actions.UpdateProp({
          Key: 'inv_ActivePart',
          Value: part
        }));
      }
      if (field.ControlType === 'ComponentDropdown') {
        let component = this.props.Resources.Components.find(x => x.Id == field.Value);
        this.props.dispatch(actions.UpdateProp({
          Key: 'inv_ActiveComponent',
          Value: component
        }));
      }
      if (field.ControlType === 'StorageDropdown') {
        let storage = this.props.Resources.Storages.find(x => x.Id == field.Value);
        this.props.dispatch(actions.UpdateProp({
          Key: 'inv_ActiveStorage',
          Value: storage
        }));
      }
    });

    newResource[entryType].UpdatingKey = item.insertKey;
    this.setState({ Entry: entryType });
    this.props.dispatch(actions.SetEventResources(newResource));
  }

  removeRow = () => {
    let newResource = cloneDeep(this.props.Resources);
    let toDelete = newResource[this.state.Entry].Rows.find(x => x.Active);

    newResource.Time.Rows = newResource.Time.Rows.filter(x => !x.Active);
    newResource.Material.Rows = newResource.Material.Rows.filter(x => !x.Active);
    newResource.Equipment.Rows = newResource.Equipment.Rows.filter(x => !x.Active);
    newResource[this.state.Entry].Fields.forEach(field => {
      field.Value = '';
    });

    let fieldItem = newResource[this.state.Entry].Fields.find(x => x.SaveData && x.SaveData.Table);

    if (toDelete.Id) {
      this.props.dispatch(actions.AddSaveData({
        Id: toDelete.Id,
        GobAutofill: null,
        InsertKey: null,
        Table: fieldItem.SaveData.Table,
        Column: 'IsDeleted',
        Value: true,
        SaveQueue: this.props.SaveDataKey,
        IsBaseTable: true
      }));
      if (this.state.Entry == 'Material') {
        this.props.dispatch(actions.AddSaveData({
          Id: toDelete.ReceivedId,
          Table: 'inv.Received',
          Column: 'IsDeleted',
          Value: true,
          SaveQueue: this.props.SaveDataKey,
          IsBaseTable: true
        }));
      }
    } else {
      this.props.dispatch(actions.ClearSaveDataForId({
        SaveData: this.props.SaveDataKey,
        Id: toDelete.insertKey
      }));
    }

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'inv_ActiveComponent',
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'inv_ActivePart',
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'inv_ActiveStorage',
        Value: null
      }))
      this.props.dispatch(actions.SetEventResources(newResource));
    });
  }

  clearActive = (resource) => {
    resource.Time.Rows.forEach(x => x.Active = false);
    resource.Material.Rows.forEach(x => x.Active = false);
    resource.Equipment.Rows.forEach(x => x.Active = false);
    resource.Time.UpdatingKey = null;
    resource.Material.UpdatingKey = null;
    resource.Equipment.UpdatingKey = null;
  }

  isEditMode = () => {
    if (!this.state.Entry)
      return false;

    return this.props.Resources[this.state.Entry].Rows.filter(x => x.Active).length > 0;
  }

  render() {
    return (
      <React.Fragment>
        <DesktopInterface parent={this} resource={this.props.Resources} entity={this.props.Entity} />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Resources);