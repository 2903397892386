import React from 'react';
import { connect } from 'react-redux';
import Honeycomb, { Auth0EditUserParams, Auth0NewUserParams } from "@beehive-development/honeycomb";

type StringKeysObject = { [key: string]: any };

const mapStateToProps = (state: StringKeysObject, ownProps: StringKeysObject) => {
  return {
    ContactData: state.ent_CurrentEvent,
    SelectedEvent: state.ent_SelectedEvent,
    CurrentUser: state.CurrentUser.Id
  };
};

export class MV_NewContact extends React.PureComponent<ReturnType<typeof mapStateToProps>> {

  async componentDidMount() {
    await this.createContactUser();
  }

  async createContactUser() {
    // @ts-expect-error
    // TypeScript can't assert `_getToken` exists due to it being placed in the `window` object at runtime in vanilla JS.
    const jwtToken = window.isSalesmanPublic ? await window._getToken : await window._getToken();
    
    const contactData = this.props.ContactData;
    const properties = contactData.Properties;

    if(this.props.SelectedEvent.EntityId < 0) {
      const name = properties.find((e: {Name: string}) => e.Name === 'DisplayName').Value;
      const email = properties.find((e: {Name: string}) => e.Name === 'Email').Value;
      const admin = properties.find((e: {Name: string}) => e.Name === "IsAdmin").Value;
      const sales = properties.find((e: {Name: string}) => e.Name === "IsSales").Value;
      var role;

      if (sales && sales !== "") {
        role = "sales";
      }
      if (admin && admin !== "") {
        role = "admin";
      }

      if(role !== undefined) {
        const honeycomb = new Honeycomb({
          requestHeaders: {
            Authorization: `Bearer ${jwtToken}`,
            "href-origin": window.location.href,
          }
        });
  
        const auth0NewUserParams: Auth0NewUserParams = {
          email: email.trim(),
          name: name,
          userId: this.props.CurrentUser,
          role: role
        };

        await honeycomb.mySalesman.auth0NewUser(auth0NewUserParams);
      }
    } else {
      const name = properties.find((e: {Name: string}) => e.Name === 'DisplayName').Value;
      const email = properties.find((e: {Name: string}) => e.Name === 'Email').Value;
      var admin = properties.find((e: {Name: string}) => e.Name === "IsAdmin").Value;
      var sales = properties.find((e: {Name: string}) => e.Name === "IsSales").Value;

      if (sales && sales !== "") {
        sales = true;
      } else {
        sales = false;
      }

      if (admin && admin !== "") {
        admin = true;
      } else {
        admin = false;
      }

      const honeycomb = new Honeycomb({
        requestHeaders: {
          Authorization: `Bearer ${jwtToken}`,
          "href-origin": window.location.href,
        }
      });

      const auth0EditUserParams: Auth0EditUserParams = {
        email: email.trim(),
        name: name,
        entityId: this.props.SelectedEvent.EntityId,
        isAdmin: admin,
        isSales: sales
      };

      await honeycomb.mySalesman.auth0EditUser(auth0EditUserParams);
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_NewContact);