import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class AutoCompleteCard extends React.PureComponent {
  cardClicked = () => {
    if (this.props.preventClick)
      return;

    let props = this.props.entProps.filter(x => (x.Label === 'EntityId' || x.Label === 'EntityTypeId') && x.DefaultValue && x.Value);

    if (props.length === 0)
      return;

    let updateObj = props.reduce((prev, next) => {
      prev[next.DefaultValue] = { ...(prev[next.DefaultValue] || {}), [next.Label]: Number(next.Value) };
      return prev;
    }, {});

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_TryCloseEvent',
      Value: true
    }));

    setTimeout(() => {
      batch(() => {
        for (let key in updateObj) {
          this.props.dispatch(actions.UpdateProp({
            Key: key,
            Value: updateObj[key]
          }));
        }
      });
    }, 0);
  }

  render() {
    let props = this.props.entProps;
    let displayProps = props.filter(x => !x.FormData || x.FormData.find(x => x.FormName === this.props.cardForm).ControlOrder !== null);

    return (
      <div className="info-card-wrapper" onClick={this.cardClicked}>
        <div tabIndex={this.props.Tabbable ? "0" : null} id={this.props.CardId ? 'infocard' + this.props.CardId : null} className={"info-card " + this.props.cardForm}>
          {this.props.parent.props.IconSource &&
            <div className="info-card-icon">
              <img className="info-card-img" src={this.props.parent.entityIcon()}></img>
            </div>
          }
          <div className="info-card-text">
            {displayProps[0] &&
              <div className="text-1">
                <div>{helpers.propDisplayValue(displayProps[0], this.props.entity)}</div>
              </div>
            }
            {displayProps[1] &&
              <div className="text-2">
                <div>{helpers.propDisplayValue(displayProps[1], this.props.entity)}</div>
              </div>
            }
            {displayProps[2] &&
              <div className="text-3">
                <div>{helpers.propDisplayValue(displayProps[2], this.props.entity)}</div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AutoCompleteCard);