import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import FormControl from '../../../Form/v001/FormControl';

const mapStateToProps = (state, ownProps) => {
  return {
    Metadata: state.met_EntityMetadata
  };
}

export class ResourceGrid extends React.PureComponent {
  getFlexWidth = (width) => {
    return width.includes('px') ? '0 0 ' + width : width;
  }

  getLookupText = (lookupSet, lookupId) => {
    let text = '';
    if (lookupSet === 'UserList') {
      let set = this.props.Metadata.UserList;
      let item = set ? set.find(x => x.UserId === lookupId || (x.UserId + '') === lookupId) : null;
      text = item ? item.Name : '';
    } else {
      let set = this.props.Metadata.LookupItems.find(x => x.LookupSet === lookupSet);

      let item = set ? set.LookupItems.find(x => x.LookupItemId === lookupId || (x.LookupItemId + '') === lookupId) : null;
      text = item ? item.Name1 : '';
    }

    return text;
  }

  getDisplayValue = (field, row) => {
    if (field.ControlType === 'DropDown' || field.ControlType === 'UserList') {
      return this.getLookupText(field.LookupSetId, row[field.Key]);
    }

    if ((field.Label === 'Cost' || field.Label === 'Unit Cost') && row[field.Key] && row[field.Key][0] !== '$' && row[field.Key][0] !== '(') {
      return '$' + Number(row[field.Key]).toFixed(2);
    }

    if (field.MinuteToHours && row[field.Key] && !isNaN(Number(row[field.Key]))) {
      return Number(row[field.Key]).toFixed(2);
    }

    if (field.Label === 'Component' && this.props.Components) {
      let name = null;
      this.props.Components.forEach(x => {
        if (x.Id == row[field.Key]) {
          name = x.Name;
        }
      })
      return name;
    }

    if (field.Label === 'Storage' && this.props.Storages) {
      let name = null;
      this.props.Storages.forEach(x => {
        if (x.Id == row[field.Key]) {
          name = x.Name;
        }
      })
      return name
    }

    if (field.Label === 'Part' && this.props.Components) {
      let part = null;
      this.props.Components.forEach(x => {
        if (x.Parts && x.Parts.find(y => y.Id == row[field.Key])) {
          part = x.Parts.find(y => y.Id == row[field.Key])
        }
      })
      if (part) {
        return part.Name;
      }
    }

    return row[field.Key];
  }

  render() {
    return (
      <div className={"resource-grid" + (" resource-" + this.props.Resource.Label)}>
        <div
          className="resource-title"
          style={{ backgroundColor: this.props.Resource.HeaderColor }}>
          <div>{this.props.Resource.Label}</div>
          <div className="resource-add-row" onClick={() => { this.props.setEntryType(this.props.Resource.Label) }}>
            <div>New</div>
          </div>
        </div>
        <div className="resource-header">
          <div className="resource-cell" style={{ flex: '0 0 40px' }}>
            <div>#</div>
          </div>
          {(this.props.Resource.Fields.filter(x => x.Label && !x.HideInGrid).map((field, idx) => (
            <div key={idx} className="resource-cell" style={{ flex: this.getFlexWidth(field.Width) }}>
              <div>{field.Label}</div>
            </div>
          )))}
        </div>
        <div className="resource-body">
          {(this.props.Resource.Rows.map((row, rowIdx) => (
            <div key={rowIdx} className={"resource-row " + (rowIdx % 2 === 0 ? '' : 'even ') + (row.Active ? 'active' : '') + (row.Id && !row.Dirty ? '' : ' resource-dirty')} onClick={() => { this.props.setEntryItem(this.props.Resource.Label, row, rowIdx) }} >
              <div className="resource-cell" style={{ flex: '0 0 40px' }}>
                <div>{rowIdx + 1}</div>
              </div>
              {(this.props.Resource.Fields.filter(x => x.Label && !x.HideInGrid).map((field, fieldIdx) => (
                <div key={fieldIdx} className="resource-cell" style={{ flex: this.getFlexWidth(field.Width) }}>
                  <div title={this.getDisplayValue(field, row)}>{this.getDisplayValue(field, row)}</div>
                </div>
              )))}
            </div>
          )))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ResourceGrid);