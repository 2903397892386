import React from 'react';
import { connect, batch } from 'react-redux';
import { Trashcan } from '../../../../Icons/Icons';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state) => {
  return {
    UserLoc: state.UserLocation,
    IsAdmin: state.blu_ApplicationId == 32 ? true : false
  };
}

export class MySalesSalesTeam extends React.PureComponent {
  state = {
    deletionModal: false
  }

  onClick = () => {
    this.props.parentClick(this.props.card);
  }

  render() {

    return (
      <React.Fragment>
        <div className='mysales-sales-team-card' onClick={this.onClick}>
          <img style={{ marginRight: "10px", paddingTop: "4px" }} height="64px" width="60px" src={this.props.card.IconSource + '?' + performance.now()} onError={(e) => { e.target.src = 'https://bhpublic.blob.core.windows.net/imagepicker/no-image.png'; e.target.onerror = null; }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontWeight: "500" }}>{this.props.card.Text1}</p>
            {this.props.IsAdmin ?
              <a style={{ marginRight: "15px" }} className="mySales-email">{this.props.card.Text2}</a> :
              <a style={{ marginRight: "15px" }} href={"mailto:" + this.props.card.Text2} className="mySales-email">{this.props.card.Text2}</a>
            }
            <p style={{ color: "#787878" }}>{this.props.card.Text3}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(MySalesSalesTeam);