import React from 'react';
import ReactDOM from 'react-dom';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import FormControl from './../../../../Widgets/Form/v001/FormControl';
import cloneDeep from 'lodash.clonedeep';
import store from '../../../../../Stores/Store';
import { Provider } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {
    Template: state.ent_SelectedTemplate,
    Metadata: state.met_EntityMetadata,
    TemplateSaveBtn: state.btn_TemplateSave,
    CurrentUser: state.CurrentUser
  };
};

export class SaveTemplateEvents extends React.PureComponent {
  //ent_Current

  componentDidMount() {
    if (!this.props.Parameter)
      return;

    let params = this.props.Parameter;
    let keys = this.props.ParameterKeys;

    if (!Array.isArray(params)) {
      params = [params];
    }

    this.currentEntity = params[0];

    if (params.findIndex(x => x === undefined) !== -1)
      return;

    this.getTemplateEvents();
    this.closeModal();
  }

  getTemplateEvents = () => {
    let body = {
      Parameter: JSON.stringify({ TemplateId: this.props.Template.Id }),
      SWID: this.props.SWID
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      if (result && result.TemplateEvents) {
        this.createTemplateEvents(result.TemplateEvents);
      }
    });
  }

  createTemplateEvents = (events) => {
    let insertKeyMap = {};
    let saveData = [];
    let pendingAutoSearch = 0;
    batch(() => {
      events.forEach((template) => {
        let insertKey = helpers.getInsertKey();
        insertKeyMap[template.EntityId] = insertKey;

        let type = cloneDeep(this.props.Metadata.EntityTypes.find(x => x.TypeId === template.EntityTypeId));

        if (!type)
          return;

        let tableKeyMap = type.Properties.reduce((prev, next) => {
          if (next.SaveData.Table && !prev[next.SaveData.Table]) {
            prev[next.SaveData.Table] = next.IsBaseTable ? insertKey : helpers.getInsertKey();
          }
          return prev;
        }, {});

        let distinctProps = type.Properties.filter(helpers.autoInsertProps).reduce((prev, next) => {
          if (!prev.hasBeenAdded[next.Name] && (!next.SaveData || !(next.SaveData.Table || '').includes('rel.'))) {
            prev.hasBeenAdded[next.SaveData.Table + '_' + next.Name] = 1;
            prev.props.push(next);
          }
          return prev;
        }, { props: [], hasBeenAdded: {} }).props;

        distinctProps = distinctProps.filter(x => x.SaveData.Table !== 'usr.Users');

        distinctProps.forEach(prop => {
          if (prop.Name !== 'Geometry') {
            saveData.push({
              Id: null,
              InsertKey: prop.SaveData.Table ? tableKeyMap[prop.SaveData.Table] : insertKey.toString(),
              Table: prop.SaveData.Table,
              Column: prop.SaveData.Column,
              Value: helpers.formatDefaultValue(prop, this.props.CurrentUser) || '',
              GobAutofill: prop.GobAutofill,
              IsBaseTable: prop.IsBaseTable,
              AutoGenerated: true,
              SaveQueue: 'TemplateSaveQueue'
            });
          }
        });

        let keyRing = helpers.idValueArrayToObject(template.Properties);
        type.Properties.forEach((prop) => {
          let formData = keyRing[prop.Id];

          if (prop.SaveData && prop.SaveData.Column === 'AssetId') {
            prop.Value = this.currentEntity.EntityId;
          } else if (prop.SaveData && prop.SaveData.Column === 'AssetClassId') {
            prop.Value = this.currentEntity.EntityTypeId;
          } else if (prop.SaveData && prop.GobAutofill === 'ent_NewEventKey') {
            prop.Value = insertKey;
          } else if (formData) {
            prop.Value = formData.Value;
          }
        });

        type.Properties.forEach((prop) => {
          if (prop.ControlType === 'AutoDropDown' || prop.ControlType === 'AutoSearch') {
            pendingAutoSearch++;
            prop.DoInitialQuery = true;
            prop.SaveData.SaveQueue = 'TemplateSaveQueue';
            ReactDOM.render(
              <Provider store={store}>
                <FormControl
                  control={prop}
                  trackChanges={true}
                  isEventSave={true}
                  allProps={type.Properties}
                  entityProps={type.Properties}
                  overrideParameter={{
                    ...template,
                    EntityId: insertKey,
                    InitialQueryCallback: () => {
                      pendingAutoSearch--;
                      if (pendingAutoSearch === 0) {
                        this.doSave();
                      }
                    }
                  }}
                />
              </Provider>,
              document.createElement('div')
            );
          } else if (prop.SaveData.Table && prop.Value !== null && prop.Value !== undefined) {
            saveData.push({
              Id: null,
              InsertKey: tableKeyMap[prop.SaveData.Table],
              Table: prop.SaveData.Table,
              Column: prop.SaveData.Column,
              Value: prop.Value || helpers.formatDefaultValue(prop, this.props.CurrentUser) || '',
              GobAutofill: prop.GobAutofill,
              IsBaseTable: prop.IsBaseTable,
              AutoGenerated: true,
              SaveQueue: 'TemplateSaveQueue'
            });
          }
        });
      });

      events.forEach((template) => {
        let metType = this.props.Metadata.EntityTypes.find(x => x.TypeId === template.EntityTypeId);
        if (insertKeyMap[template.ParentEventId] && metType) {
          saveData.push({
            Id: insertKeyMap[template.EntityId],
            InsertKey: null,
            Table: metType.BaseTable,
            Column: 'ParentId',
            Value: insertKeyMap[template.ParentEventId],
            GobAutofill: null,
            IsBaseTable: true,
            AutoGenerated: true,
            SaveQueue: 'TemplateSaveQueue'
          });
        }
      });

      saveData = saveData.filter(x => x.Value !== '' || x.DefaultValue);


      saveData.forEach(sd => {
        this.props.dispatch(actions.AddSaveData(sd));
      });
    });

    if (pendingAutoSearch === 0) {
      this.doSave();
    }
  }

  doSave = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_TemplateSave',
      Value: { ...this.props.TemplateSaveBtn, ClickId: this.props.TemplateSaveBtn.ClickId + 1 }
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_SelectedTemplate',
      Value: null
    }));
  }

  closeModal = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene',
      Value: undefined
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(SaveTemplateEvents);