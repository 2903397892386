import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from './../../../../Stores/Actions/actions';
import * as helpers from './../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    ItemCatalog: state.ItemCatalog,
    TenantId: state.met_EntityMetadata.CurrentTenant
  };
};

export class MV_NewItemSaveData extends React.PureComponent {

  componentDidMount() {
    hub.subscribe('BeforeSave', (saveData) => {
      let catalogItem = saveData.find(x => x.InsertKey === -300000);
      if (catalogItem) {
        saveData.push({
          ...catalogItem,
          Column: 'IsDeleted',
          Value: false
        });
        saveData.push({
          ...catalogItem,
          Column: 'ItemTypeLid',
          Value: this.props.ItemCatalog.Id
        });
        saveData.push({
          ...catalogItem,
          Column: 'TenantId',
          Value: this.props.TenantId
        });
        saveData.push({
          ...catalogItem,
          Column: 'EntityTypeId',
          Value: catalogItem.EntityTypeId
        });
      }
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_NewItemSaveData);

