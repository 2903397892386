import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import { fromLonLat } from 'ol/proj';
import { Vector as LayerVector } from 'ol/layer'
import { Vector as SourceVector } from 'ol/source';
import StyleEngine from '../Styles/StyleEngine';
import WKT from 'ol/format/WKT';

const mapStateToProps = (state) => {
  return {
    MoveTo: state.map_MoveToLocation
  };
}

export class MoveToLocation extends PureComponent {
  componentDidMount() {
    this.checkMove();
  }
  componentDidUpdate() {
    this.checkMove();
  }

  checkMove = () => {
    if (!this.props.map || !this.props.MoveTo)
      return;

    let myMove = this.props.MoveTo[this.props.swid];
    if (!myMove || myMove.Processed)
      return;


    let moveTo = fromLonLat([myMove.Longitude, myMove.Latitude]);
    this.props.MoveTo[this.props.swid].Processed = true;
    let view = this.props.map.getView();
    let zoom = view.getZoom();

    let zoomLevel = 17;
    if (this.props.map.Layout && this.props.map.Layout.MapSearchZoomLevel) {
      zoomLevel = this.props.map.Layout.MapSearchZoomLevel;
    }

    view.animate({
      center: moveTo,
      zoom: Math.max(zoom, (myMove.Zoom || zoomLevel)),
      duration: myMove.Delay || 0
    });
  }

  render() {
    return null
  }
}

export default connect(mapStateToProps)(MoveToLocation);


