import React from 'react';
import { connect, batch } from 'react-redux';
import Point from 'ol/geom/Point';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let swid = ownProps.widget.Parameters.find(x => x.ParameterName === 'MapSWID');
  let pointSource = ownProps.widget.Parameters.find(x => x.ParameterName === 'PointSource');
  let triggerKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'TriggerKey');

  let gobPoint = null;
  if (pointSource) {
    try {
      let geo = pointSource.ParameterValue.split('.').reduce((prev, next) => { return prev[next]; }, state);
      if (geo && typeof geo === 'string') {
        let pieces = geo.replace('POINT (', '').replace(')', '').split(' ');
        gobPoint = {
          Lat: pieces[0],
          Lon: pieces[1]
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  return {
    GobPoint: gobPoint,
    Directions: triggerKey ? state[triggerKey.ParameterValue] : state.goo_Directions,
    StreetView: state.goo_StreetView,
    MapSWID: swid ? Number(swid.ParameterValue) : null
  };
};

export class Delete extends React.PureComponent {

  componentDidUpdate(prev) {
    if (this.didChange(prev, 'Directions')) {
      this.openDirections();
    }
    if (this.didChange(prev, 'StreetView')) {
      this.openStreetView();
    }
  }

  openStreetView = () => {
    let url = '';

    if (this.props.GobPoint) {
      url = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${this.props.GobPoint.Lon},${this.props.GobPoint.Lat}&heading=0&pitch=-10&fov=80`;
    } else {
      let view = this.getMapView();
      if (!view)
        return;

      let center = view.getCenter();
      let latLon = new Point([center[0], center[1]]).transform('EPSG:3857', 'EPSG:4326').getCoordinates();
      url = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latLon[1]},${latLon[0]}&heading=0&pitch=-10&fov=80`;
    }

    console.log(url);

    let tab = window.open(url, '_blank');
    if (tab) {
      tab.focus();
    } else {
      console.error('Browser blocked google street view');
    }
  }

  openDirections = () => {
    let url = '';

    if (this.props.GobPoint) {
      url = `https://www.google.com/maps/dir/?api=1&destination=${this.props.GobPoint.Lon},${this.props.GobPoint.Lat}`;
    } else {
      let view = this.getMapView();
      if (!view)
        return;

      let center = view.getCenter();
      let latLon = new Point([center[0], center[1]]).transform('EPSG:3857', 'EPSG:4326').getCoordinates();
      url = `https://www.google.com/maps/dir/?api=1&destination=${latLon[1]},${latLon[0]}`;
    }

    console.log(url);

    let tab = window.open(url, '_blank');
    if (tab) {
      tab.focus();
    } else {
      console.error('Browser blocked google directions');
    }
  }

  didChange = (prev, key) => {
    let old = prev && prev[key] ? prev[key] : null;
    let cur = this.props[key] || null;
    return (!old && cur) || cur && old && cur.ClickId !== old.ClickId;
  }

  getMapView = () => {
    let map = window.MapInstance[this.props.MapSWID];
    if (!map)
      return null;

    return map.getView();
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Delete);