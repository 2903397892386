import React from 'react';
import { batch, connect } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from '../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';
import * as icons from '../../../Icons/Icons';
import Monaco from '../../../../Utils/Monaco';
import MonacoDiff from './../../../../Utils/MonacoDiff';
import cloneDeep from 'lodash.clonedeep';
import { AgGridReact } from 'ag-grid-react';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class SchemaTab extends React.PureComponent {
  componentDidMount() {
    this.getSchema();
    //window.addEventListener('error', this.ignoreError);
  }

  componentWillUnmount() {
    // window.removeEventListener('error', this.ignoreError);    
  }

  getSchema = () => {
    let dbs = this.props.dbm.DBs.filter(x => x.Active).map(x => x.Text1);
    if (!dbs || dbs.length === 0)
      return;

    let body = {
      Sql: '',
      Databases: this.props.dbm.DBs.filter(x => x.Active).map(x => x.Text1),
      Script: 'AllSchema'
    };

    actions.ApiRequest('Logging/GlobalSQL', body, (result) => {
      this.props.parent.setTabData(this.parseSchema(result), 'AllSchema');
    });
  }

  parseSchema = (schema) => {
    let map = {};

    schema.forEach(dbItem => {
      dbItem.Value.reduce((prev, next) => {
        if (!prev[next.Name]) {
          prev[next.Name] = {
            ...next,
            DBs: [dbItem.DbName]
          }
        } else {
          prev[next.Name].DBs.push(dbItem.DbName);
        }

        return prev;
      }, map);
    });

    let result = Object.keys(map).map(x => map[x]);

    result.sort((a, b) => {
      let aval = a.DBs.length;
      let bval = b.DBs.length;
      return aval > bval ? -1 : bval > aval ? 1 : 0;
    })

    result = result.reduce((prev, next) => {
      let item = prev.find(x => x.Category === next.Category);
      if (!item) {
        item = { Category: next.Category, Expand: false, Items: [] };
        prev.push(item);
      }
      item.Items.push(next);
      return prev;
    }, [])

    console.log(result);
    return result;
  }

  toggleExpandSchema = (schema) => {
    schema.Expand = !schema.Expand;
    this.props.parent.setData();
  }

  selectSchemaItem = (item) => {
    let body = {
      Sql: "exec utl.whatis '" + item.Name + "'",
      Databases: item.DBs
    };

    actions.ApiRequest('Logging/GlobalSQL', body, (result) => {
      this.props.parent.setTabData(result, 'SchemaCompare');
    });
  }

  setCompareActive = (item, box) => {
    let key = 'Active' + box;
    let items = this.props.parent.getTabData('SchemaCompare');
    items.forEach(x => x[key] = false);
    item[key] = true;
    this.props.parent.setData();
  }

  getCompare = (box) => {

    let key = 'Active' + box;
    let items = this.props.parent.getTabData('SchemaCompare');
    let item = items.find(x => x[key]);
    return item ? item.Value[0].Definition || '' : '';
  }

  compareChange = (box) => {
    //want to allow edit?
  }

  render() {
    let parent = this.props.parent;
    let schema = parent.getTabData('AllSchema');
    let compare = parent.getTabData('SchemaCompare');

    return (
      <div className="db-schema-tab">
        <div className="schema-container">
          {schema && schema.map((sc, scIdx) => (
            <div key={scIdx} className="category-item" >
              <div className="category-name" onClick={() => { this.toggleExpandSchema(sc) }}>
                <div className="category-exp">{sc.Expand ? '-' : '+'}</div>
                <div className="category-lbl">{sc.Category}</div>
              </div>
              {sc.Expand && sc.Items.map((item, itemIdx) => (
                <div key={itemIdx} className="schema-item" onClick={() => { this.selectSchemaItem(item) }}>
                  <div className="schema-lbl">{item.Name}</div>
                  <div className="schema-qty">{item.DBs.length}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="schema-compare">
          {compare &&
            <div className="compare-container">
              <div className="a-b-tabs">
                <div className="db-tabs">
                  {compare.map((x, idx) => (
                    <div key={idx} className={"dbm-tab" + (x.ActiveA ? ' active' : '')} onClick={() => { this.setCompareActive(x, 'A') }}>
                      <div className="dbm-run-name">{x.DbName}</div>
                    </div>
                  ))}
                </div>
                <div className="db-tabs">
                  {compare.map((x, idx) => (
                    <div key={idx} className={"dbm-tab" + (x.ActiveB ? ' active' : '')} onClick={() => { this.setCompareActive(x, 'B') }}>
                      <div className="dbm-run-name">{x.DbName}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="editor-monaco-box">
                <MonacoDiff valA={this.getCompare('A')} valB={this.getCompare('B')} language={'sql'} />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SchemaTab);