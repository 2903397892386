import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../Stores/Actions/actions';
import Honeycomb from "@beehive-development/honeycomb";

const mapStateToProps = (state, ownProps) => {
  return {
    databaseName: state.BlobSAS.Database,
    saveId: state.dbo_SaveId?state.dbo_SaveId:null
  };
};

export class UBAdminGroupEdit extends React.PureComponent {

  state = {
    newGroupName: '',
  }

  onChange = (val) => {
    this.setState({ newGroupName: val });
  }

  onSave = async () => {
    if(this.state.newGroupName && this.props.databaseName) {
      const body = {
        databaseName: this.props.databaseName,
        name: this.state.newGroupName,
        description: ''
      }
      const honeycomb = new Honeycomb({
        requestHeaders: {
            "href-origin": window.location.href,
        }
      });
      
      await honeycomb.mySalesman.addPermissionGroup(body).then(response => response.json());

      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_SelectedGroupNew',
        Value: undefined
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.saveId+1
      }));
    }
  }

  onCancel = () => {
    this.props.dispatch(actions.UpdateProp({
        Key: 'ent_SelectedGroupNew',
        Value: undefined
    }));
  }

  render() {
    return (
      <div>
        <br />
        <div>
          <div>
            <label style={{marginLeft:"12px",width:"75px",paddingTop:"4px",paddingBottom:"4px"}}>New Group Name:</label>
            <input style={{marginLeft:"52px",marginRight:"12px",width:"260px",padding:"4px"}} onChange={(e) => this.onChange(e.target.value)} type="text"></input>
          </div>
        </div>

        <div style={{display:"flex", margin:"16px", width:"100%", justifyContent:"center", gap:"16px"}}>
            <div onClick={() => this.onCancel()} style={{
                textAlign:"center",
                width:"100px",
                height:"30px",
                backgroundColor: "#dd7c0e",
                color: "white",
                padding:"2px 10px",
                borderRadius:"100px",
                cursor:"pointer",
                userSelect: "none"
            }}>
                Cancel
            </div>
            <div onClick={() => this.onSave()} style={{
                textAlign:"center",
                width:"100px",
                height:"30px",
                backgroundColor: "#4AB84A",
                color: "white",
                padding:"2px 10px",
                borderRadius:"100px",
                cursor:"pointer",
                userSelect: "none"
            }}>
                Save
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(UBAdminGroupEdit);