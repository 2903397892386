import React from 'react';
import { connect } from 'react-redux';
import GridHeader from '../Header/GridHeader';
import GridBody from '../Body/GridBody';
import ColumnPicker from '../Header/ColumnPicker';
import RowNumbers from '../Body/RowNumbers';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    Data: state.qry_Queries[ownProps.queryId].data,
    PageRequests: state.qry_Queries[ownProps.queryId].pageRequests
  };
};

export class TitleBar extends React.PureComponent {
  state = {
    requests: 1
  }
  componentDidUpdate(prevProps) {
    if (this.props.PageRequests && this.props.PageRequests.length < (prevProps.PageRequests ? prevProps.PageRequests.length : 0)) {
      this.setState({ requests: this.state.requests + 1 });
    }
  }

  render() {
    let data = this.props.Data;
    return (
      <div className="title-bar">
        <div className="grid-label">{this.props.label}</div>
        <div className="grid-rowcount">{this.state.requests + ' Requests, ' + (data ? data.rowCount || 0 : 0)} Rows</div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(TitleBar);