import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep'
import * as actions from './../../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class TimeDisplay extends React.PureComponent {
  getPrimaryText = (row) => {
    if (this.props.PrimaryField === 'User') {
      return this.props.getLookupText('UserList', row.UserId);
    } else {
      return this.props.getLookupText(1099, row.WageCode);
    }
  }

  displayDate = (date) => {
    if (!date)
      return '';

    let val = '';
    let parts = date.split('-');
    if (parts.length === 3) {
      val = [Number(parts[1]), Number(parts[2]), Number(parts[0])].join('-');
    } else {
      val = date.replace(/\//g, '-');
    }
    return val;
  }

  render() {
    let row = this.props.row;
    return (
      <div className="resource-card">
        <div className="top-line">
          <div className="resource-user">{}</div>
          <div className="resource-date">{this.displayDate(row.StartDate)}</div>
        </div>
        <div className="middle-line">
          <div className="resource-wagecode">{this.getPrimaryText(row)}</div>
          <div className="resource-duration">
            <div>{row.Duration}</div>
            <div>{row.Duration == 1 ? 'hr.' : 'hrs.'}</div>
          </div>
        </div>
        <div className="bottom-line">
          <div className="resource-note">{row.Note}</div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(TimeDisplay);


