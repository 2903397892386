import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { selector } from '../../../../Stores/Reducers/reducer';
import WidgetLoader from '../../../WidgetLoader';

const mapStateToProps = (state, ownProps) => {
  let sceneName = ownProps.widget.Parameters.find(x => x.ParameterName === 'SceneName');
  let initialFocus = ownProps.widget.Parameters.find(x => x.ParameterName === 'InitialFocus');

  //if a key was specified and redux doesn't know about it, define it
  if (sceneName && !state[sceneName.ParameterValue]) {
    state[sceneName.ParameterValue] = {
      FocusId: initialFocus ? Number(initialFocus.ParameterValue) : null
    }
  }

  return {
    Scene: sceneName ? selector.SceneByName(state, (sceneName.ParameterValue)) : null,
    SceneName: sceneName ? sceneName.ParameterValue : null,
    FocusId: (sceneName && state[sceneName.ParameterValue]) ? state[sceneName.ParameterValue].FocusId : null
  };
};

export class Carousel extends React.PureComponent {
  nextWidget = (diff) => {
    if (!this.props.SceneName)
      return;

    let widgets = this.props.Scene.Widgets;
    let focusIdx = widgets.findIndex(x => x.SceneWidgetId === this.props.FocusId);

    if (focusIdx === -1)
      return;

    let newIdx = (widgets.length + focusIdx + diff) % widgets.length;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.SceneName,
      Value: {
        FocusId: widgets[newIdx].SceneWidgetId
      }
    }));
  }

  render() {
    if (!this.props.Scene || !this.props.FocusId)
      return null;

    let widgets = this.props.Scene.Widgets;
    let focus = widgets.find(x => x.SceneWidgetId === this.props.FocusId);

    return (
      <div className="widget-carousel">
        <div className="carousel-buttons">
          <div className="carousel-btn carousel-right" onClick={() => { this.nextWidget(1) }} >
            <div>›</div>
          </div>
          <div className="carousel-btn carousel-left" onClick={() => { this.nextWidget(-1) }}>
            <div>›</div>
          </div>
        </div>
        <div className="carousel-nav">
          <div className="pip-container">
            {widgets.map((x, idx) => (
              <div key={idx} className={"nav-pip" + (this.props.FocusId === x.SceneWidgetId ? ' active' : '')}></div>
            ))}
          </div>
        </div>
        {focus &&
          <div className="widget-carousel-items">
            <WidgetLoader widget={focus} />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(Carousel);