import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import Inputmask from "inputmask";
import HoverInfo from '../../HoverInfo/v001/HoverInfo';
import { LoadingDotSpinner } from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
    SaveData: state.dbo_SaveData
  };
}

export class ColorPicker extends React.PureComponent {
  state = {
    hasFocus: false,
    hasChanged: false
  }

  initialLoad = true;

  componentDidUpdate() {
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    this.forceUpdate();
  }

  handleKeyDown = (event) => {

  }

  onChange = (e) => {
    let propVal = e.target.value;
    this.props.control.Value = e.target.value;
    this.props.control.UnmaskedValue = propVal;

    if (this.props.control.FailedValidate) {
      this.props.control.FailedValidate = false;
    }

    this.forceUpdate();

    if (this.props.refresh)
      this.props.refresh();

    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        ...this.props.control.SaveData,
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: propVal,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
  }

  deselectTypeahead = () => {
    this.props.control.Typeahead.forEach(x => x.Selected = false);
    this.forceUpdate();
  }

  blur = () => {
    setTimeout(() => {
      this.setState({ hasFocus: false });
    }, 250);
  }

  focus = () => {
    setTimeout(() => {
      this.setState({ hasFocus: true });
    }, 0);
  }

  onClick = (e) => {
    let opts = this.props.control._maskOptions;
    if (opts && opts.highlightOnClick) {
      e.target.select();
    }
  }

  getPlaceholder = (control) => {
    let result = control.Placeholder;
    if (!result) {
      try {
        let maskOpt = eval('(' + control.DataMaskOptions + ')');
        if (maskOpt && maskOpt.htmlPlaceholder) {
          result = maskOpt.htmlPlaceholder;
        }
      } catch { }
    }

    return result;
  }

  render() {
    let control = this.props.control;
    if (!control.Id) {
      control.Id = helpers.getId(1000);
    }

    let autoFocus = false;
    let inputType = "color";

    if (this.initialLoad && this.props.control.ClassName && this.props.control.ClassName.toLowerCase().split(' ').includes('autofocus')) {
      this.initialLoad = false;
      autoFocus = true;
    }

    return (
      <div className="textbox-control color-picker">
        {control.Label && <div className="control-label">
          {control.Label}
          {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
        </div>}
        <div className={(control.FailedValidate ? "failed-validate" : "")}>
          <input
            className="chrome-hide-field"
            autoComplete={'off'}
            disabled={control.IsRO}
            maxLength={control.MaxValue ? control.MaxValue : null}
            id={'control2-' + control.Id}
            onBlur={this.blur}
            onFocus={this.focus}
            type={inputType}
            value={control.Value || ''}
            placeholder={this.getPlaceholder(control)}
            onChange={this.onChange}
            onClick={this.onClick}
          />
          <input
            autoComplete={'off'}
            disabled={control.IsRO}
            maxLength={control.MaxValue ? control.MaxValue : null}
            id={'control-' + control.Id}
            onBlur={this.blur}
            onFocus={this.focus}
            type={inputType}
            value={control.Value || ''}
            placeholder={this.getPlaceholder(control)}
            onChange={this.onChange}
            onClick={this.onClick}
            autoFocus={autoFocus}
            step={control._internalmask ? control._internalmask.step || null : null}
          // tabIndex={control.Location && control.Location.ControlOrder ? control.Location.ControlOrder : 0}
          />
        </div>
        {window.map_typeAheadLoading &&
          <div className="typeahead-wrapper map-search-load-bg">
            <div className="typeahead-container">
              <div className="typeahead-loader">
                <LoadingDotSpinner />
              </div>
            </div>
          </div>
        }
        {control.Typeahead && control.Typeahead.length > 0 && (control.Typeahead[0].AlwaysShow || this.state.hasFocus) &&
          <div className="typeahead-wrapper">
            <div className="typeahead-container">
              <div className="typeahead-dropdown">
                {control.Typeahead.map((x, idx) => (
                  <div key={idx} className={(x.Selected ? "typeahead-selected " : "") + "typeahead-item"} onClick={() => { this.selectTypeaheadItem(x) }} onMouseEnter={this.deselectTypeahead}>
                    <div className="text-1">
                      <div>{x.Text1}</div>
                    </div>
                    <div className="text-2">
                      <div>{x.Text2}</div>
                    </div>
                    <div className="text-3">
                      <div>{x.Text3}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(ColorPicker);