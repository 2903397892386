import { findMinMax } from 'ag-charts-community';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import FormControl from '../../../Form/v001/FormControl';

const mapStateToProps = (state, ownProps) => {
  return {
    ActiveComponent: state.inv_ActiveComponent,
    ActivePart: state.inv_ActivePart,
    ActiveStorage: state.inv_ActiveStorage
  };
}

export class ResourceEntry extends React.PureComponent {

  state = {
    refreshId: 0
  }
  refresh = (label) => {
    this.setState({ refreshId: this.state.refreshId + 1 });
  }

  componentWillUnmount() {
    this.resetPartsAndComponents();
  }

  resetPartsAndComponents = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'inv_ActiveComponent',
      Value: null
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'inv_ActivePart',
      Value: null
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'inv_ActiveStorage',
      Value: null
    }))
  }

  getCostText = (fields) => {
    let result = '';
    let part = fields.find(x => x.Label === 'Part');
    let qty = fields.find(x => x.Label === 'Qty');
    let cost = fields.find(x => x.Label === 'Unit Cost');
    let costLabel = fields.find(x => x.Label === 'Cost');

    if (this.props.ActivePart && this.props.ActivePart.Id && qty && qty.Value) {
      part.Value = this.props.ActivePart.Id;
      let costNumber = Number((Number(cost.Value) * Number(qty.Value)).toFixed(2));
      costLabel.Value = '($' + (Number(cost.Value) * Number(qty.Value)).toFixed(2) + ')';
      result = `$${Number(cost.Value).toFixed(2)} * ${qty.Value} = $${Number(costNumber).toFixed(2)}`;
    }

    return result;
  }

  storageChange = (e) => {
    let storage = this.props.Storages.find(x => x.Id == e.target.value);

    this.props.dispatch(actions.UpdateProp({
      Key: 'inv_ActiveStorage',
      Value: storage
    }));

    this.refresh();
  }

  componentChange = (e) => {
    let component = this.props.Components.find(x => x.Id == e.target.value);
    let cost = this.props.Resource.Fields.find(x => x.Label === 'Unit Cost');
    cost.Value = '';

    this.props.dispatch(actions.UpdateProp({
      Key: 'inv_ActiveComponent',
      Value: component
    }));

    this.refresh();
  }

  partChange = (e) => {
    if (!this.props.ActiveComponent)
      return;

    let part = this.props.ActiveComponent.Parts.find(x => x.Id == e.target.value);

    let cost = this.props.Resource.Fields.find(x => x.Label === 'Unit Cost');
    cost.Value = part.Cost;

    this.props.dispatch(actions.UpdateProp({
      Key: 'inv_ActivePart',
      Value: part
    }));

    this.refresh();
  }

  render() {
    let fields = this.props.Resource.Fields;
    if (fields.find(x => x.ControlType === 'DatePicker') && fields.find(x => x.ControlType === 'DatePicker').Value === 'now') {
      let dateObj = new Date();
      let month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
      let day = ('0' + dateObj.getDate()).slice(-2);
      let year = dateObj.getUTCFullYear();
      fields.find(x => x.ControlType === 'DatePicker').Value = year + '-' + month + '-' + day;
    }

    return (
      <div className="resource-entry">
        <div className="entry-label" style={{ backgroundColor: this.props.Resource.HeaderColor }}>
          <div>{this.props.Resource.Label + ' Entry'}</div>
        </div>
        <div className="form scrollable-y" style={{ height: "100%" }}>
          <div className="form-grid" style={{ gridAutoRows: "min-content" }}>
            {this.props.Resource.Fields.filter(x => x.Label).map((x, idx) => (
              <React.Fragment key={idx}>
                {x.ControlType === 'CostCalculation' ?
                  <div className="resource-cost">
                    <div>{this.getCostText(this.props.Resource.Fields)}</div>
                  </div> :
                  x.ControlType === 'ComponentDropdown' ?
                    <div className="control-wrapper DropDown">
                      <div className="form-control">
                        <div className="dropdown-control">
                          <div className="control-label">Component</div>
                          <select value={this.props.ActiveComponent ? this.props.ActiveComponent.Id : ''} onChange={(e) => this.componentChange(e)}>
                            <option value={''}></option>
                            {this.props.Components && this.props.Components.map(x => {
                              return <option value={x.Id} key={x.Id}>{x.Name}</option>
                            })}
                          </select>
                        </div>
                      </div>
                    </div> :
                    x.ControlType === 'PartDropdown' ?
                      <div className="control-wrapper DropDown">
                        <div className="form-control">
                          <div className="dropdown-control">
                            <div className="control-label">Part</div>
                            <select value={this.props.ActivePart ? this.props.ActivePart.Id : ''} onChange={(e) => this.partChange(e)}>
                              <option value={''}></option>
                              {this.props.ActiveComponent && this.props.ActiveComponent.Parts && this.props.ActiveComponent.Parts.filter(x => !x.IsDeleted).map(x => {
                                return <option value={x.Id} key={x.Id}>{x.Name}</option>
                              })}
                            </select>
                          </div>
                        </div>
                      </div> :
                    x.ControlType === 'StorageDropdown' ?
                    <div className="control-wrapper DropDown">
                      <div className="form-control">
                        <div className="dropdown-control">
                          <div className="control-label">Storage</div>
                          <select value={this.props.ActiveStorage ? this.props.ActiveStorage.Id : ''} onChange={(e) => this.storageChange(e)}>
                            <option value={''}></option>
                            {this.props.Storages && this.props.Storages.map(x => {
                              return <option value={x.Id} key={x.Id}>{x.Name}</option>
                            })}
                          </select>
                        </div>
                      </div>
                    </div> :
                    <FormControl control={x} trackChanges={false} allProps={this.props.Resource.Fields} refresh={this.refresh} forceRefreshId={this.state.refreshId} />
                }
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="entry-button-container">
          <div className="entry-button entry-add" onClick={() => { this.props.isEditMode ? this.props.updateRow(this.props.Resource, this.refresh) : this.props.addRow(this.props.Resource, this.refresh) }}>
            <div>{this.props.isEditMode ? 'Update' : 'Add'}</div>
          </div>
          {!this.props.isEditMode ? null :
            <div className="entry-button entry-remove" onClick={this.props.removeRow}>
              <div>Remove</div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ResourceEntry);