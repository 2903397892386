
import React from 'react';

const UB_AcctAging = props => {
  let style = props.card.Color ? { color: props.card.Color } : null;
  return (
    <React.Fragment>
      <div className="ub-acct-aging">
        <div className="aging-lbl">
          <div>{props.card.Label}</div>
        </div>
        <div className="aging-amt">
          <div style={style}>{props.card.Text1}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UB_AcctAging