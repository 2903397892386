import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { BlueAutoFill } from './../../../Icons/Icons';


const mapStateToProps = (state, ownProps) => {
  return {
    SaveData: state.dbo_SaveData,
    State: state
  };
}

export class AutoFillButton extends React.PureComponent {
  executeAutofill = () => {
    let body = {
      EMCId: this.props.control.Id,
      EntityId: this.props.entity.EntityId,
      EntityTypeId: this.props.entity.EntityTypeId,
      Properties: helpers.ParseAutoFillProps(this.props)
    }
    actions.ApiRequest('List/AutoFill', body, (result) => {
      console.log(result);
      this.parseAutoFillResponse(result);
    });
  }

  parseAutoFillResponse = (result) => {
    if (!result || !Array.isArray(result))
      return;

    result.forEach(item => {
      if (!item.Property)
        return;

      let props = this.props.entity.Properties.filter(x => x.Name === item.Property);
      if (props.length > 0) {
        props.forEach(p => p.Value = item.Value);
        let keys = this.props.entitySource ? (this.props.entitySource.InsertKeys || {}) : {};
        let prop = props[0];
        this.props.dispatch(actions.AddSaveData({
          Id: prop.SaveData.Id,
          InsertKey: keys[prop.SaveData.Table],
          Table: prop.SaveData.Table,
          Column: prop.SaveData.Column,
          Value: item.Value,
          IsEventData: this.props.isEventSave
        }));
      }
    });
    this.props.refresh();
  }

  render() {
    return (
      <div className="auto-fill-button">
        <div onClick={this.executeAutofill}>
          <BlueAutoFill />
          {this.props.control.ControlType === 'AutoFillButton' &&
            <div className="auto-fill-label">
              <div>{this.props.control.Label || ''}</div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AutoFillButton);