import React from "react";
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import Honeycomb from "@beehive-development/honeycomb";

const mapStateToProps = (state, ownProps) => {
    return {
        EntityMetadata: state.met_EntityMetadata,
        Integrations: state.ent_Integrations ? state.end_Integrations : null
    }
}

export class Integration extends React.PureComponent {
    constructor(props) {
        super(props);
        this.checkIntegrations();
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.Integrations && this.props.Integrations.length > 0)
            return;
        
        await this.checkIntegrations();
    }    

    checkIntegrations = async () => {
        const currentTenant = this.props.EntityMetadata.CurrentTenant
        if(currentTenant) {
            const body = {
                EntityId: currentTenant,
                DatabaseName: "mySalesman01"
            }
    
            const honeycomb = new Honeycomb({
                requestHeaders: {
                    "href-origin": window.location.href,
                }
            });
            
            const respBody = await honeycomb.mySalesman.getIntegrations(body).then(response => response.json());
    
            this.props.dispatch(actions.UpdateProp({
                Key: 'ent_Integrations',
                Value: respBody
            }));
        }
    }

    render() {
        return null;
    }
}
export default connect(mapStateToProps)(Integration);
