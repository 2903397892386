import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import * as icons from '../../../Icons/Icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const mapStateToProps = (state, ownProps) => {
  let dateKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'SelectedDateKey');
  let sourceParam = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let futureOnlyOffset = ownProps.widget.Parameters.find(x => x.ParameterName === 'FutureOnlyOffset');
  let defaultToday = ownProps.widget.Parameters.find(x => x.ParameterName === 'DefaultToday');

  let param = null;
  if (sourceParam) {
    param = helpers.getListParameter(state, sourceParam.ParameterValue);
  }

  return {
    DateKey: dateKey ? dateKey.ParameterValue : null,
    DateVal: dateKey && state[dateKey.ParameterValue] ? state[dateKey.ParameterValue] : null,
    Parameter: param ? JSON.stringify(param) : null,
    FutureOnlyOffset: futureOnlyOffset ? Number(futureOnlyOffset.ParameterValue) : null,
    DefaultToday: defaultToday ? helpers.stringToBool(defaultToday.ParameterValue) : true,
    SWID: ownProps.widget.SceneWidgetId,
    DisabledDays: state['_dateDisabled' + ownProps.widget.SceneWidgetId] || {}
  }
}

export class DatePicker extends React.PureComponent {

  componentDidMount() {
    if (!this.props.DateVal && this.props.DefaultToday) {
      this.onChange(new Date());
    }
    this.getDisabled();
  }

  componentDidUpdate(prev) {
    if (this.props.Parameter && (!prev || prev.Parameter !== this.props.Parameter)) {
      this.getDisabled();
    }
  }

  getDisabled = () => {
    let body = {
      SWID: this.props.SWID,
      Parameter: this.props.Parameter
    }
    actions.ApiRequest('List/GetList', body, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: '_dateDisabled' + this.props.SWID,
        Value: this.parseDisabled(result)
      }))
    });
  }

  parseDisabled = (result) => {
    return result.reduce((prev, next) => {
      prev[next.CDate] = next.IsDisabled;
      return prev;
    }, {});
  }

  getClassName = (arg, dateValTime) => {
    if (arg.view !== 'month')
      return '';

    return Math.floor(arg.date.getTime() / 86400000) === dateValTime ? 'picked-date' : '';
  }

  isDisabled = (arg, dateValTime) => {
    let dayOfWeek = arg.date.getDay();
    if (dayOfWeek === 6 || dayOfWeek === 0)
      return true;

    if (this.props.FutureOnlyOffset !== null) {
      let dateVal = Math.floor(arg.date.getTime() / 86400000);
      if (dateVal < (dateValTime + this.props.FutureOnlyOffset + 1)) {
        return true;
      }
    }

    if (this.props.DisabledDays[helpers.formatDate(arg.date)]) {
      return true;
    }

    return false;
  }

  onChange = (e) => {
    let val = helpers.formatDate(e);
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.DateKey,
      Value: { SelectedDate: val, RefreshId: helpers.getId(), jsDate: e }
    }))
  }

  render() {
    let dftVal = this.props.DefaultToday ? new Date() : null;
    let dateVal = this.props.DateVal ? this.props.DateVal.jsDate : dftVal;
    let dateValTime = dateVal ? Math.floor(dateVal.getTime() / 86400000) : 0;
    let todayTime = Math.floor(new Date().getTime() / 86400000);

    return (
      <div className="DatePicker-widget">
        <Calendar
          onChange={this.onChange}
          calendarType='US'
          selectRange={false}
          value={dateVal}
          tileDisabled={(arg) => { return this.isDisabled(arg, todayTime) }}
          tileClassName={(arg) => { return this.getClassName(arg, dateValTime) }}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(DatePicker);


