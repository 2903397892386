import React from 'react';
import { connect, batch } from 'react-redux';
import parser from 'html-react-parser';
import * as actions from '../../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../../Utils/Helpers';
import { MagnifyingGlass } from '../../../../../../Icons/Icons';


const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class TriggerCard extends React.PureComponent {
  masks = [
    { TypeId: 1, Mask: 'Entity {Text1} property {Text2} is {Text3}' },
    { TypeId: 2, Mask: 'Button {Text1} clicked' },
    { TypeId: 3, Mask: 'Scene {Text1} = {Text2}' },
    { TypeId: 4, Mask: 'Before Save' }
  ]

  getText = () => {
    let item = this.props.item;
    let maskItem = this.masks.find(x => x.TypeId === item.TypeId);
    return maskItem.Mask
      .replace('{Text1}', '<div class="wk-highlight">' + item.Text1 + '</div>')
      .replace('{Text2}', '<div class="wk-highlight">' + item.Text2 + '</div>')
      .replace('{Text3}', '<div class="wk-highlight">' + item.Text3 + '</div>');
  }

  edit = () => {
    this.props.editThisItem(this.props.item.ItemId);
  }

  render() {
    return (
      <div className="wf-item" onClick={this.edit}>
        <div className="wf-summary-text">
          {(this.props.item.ItemId) + '. '}
          {parser(this.getText())}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(TriggerCard);