import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';
import Scripts from './ScriptMap';

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let triggerKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'TriggerKey');
  let type = ownProps.widget.Parameters.find(x => x.ParameterName === 'Type');
  let fireDelay = ownProps.widget.Parameters.find(x => x.ParameterName === 'FireDelay');

  let param = null;
  if (parameter) {
    param = helpers.getListParameter(state, parameter.ParameterValue);
  }

  let trigger = triggerKey ? state[triggerKey.ParameterValue] : null;
  if (triggerKey && state[triggerKey.ParameterValue] !== undefined && ['dbo_SaveId', 'dbo_CancelId', 'dbo_BeforeSaveId'].includes(triggerKey.ParameterValue)) {
    trigger = { ClickId: state[triggerKey.ParameterValue] };
  }

  return {
    Parameter: param,
    ParameterKeys: parameter ? parameter.ParameterValue.split(',').map(x => x.trim()) : null,
    TriggerKey: triggerKey ? triggerKey.ParameterValue : null,
    Trigger: trigger,
    Script: type ? Scripts[type.ParameterValue] : null,
    SWID: ownProps.widget.SceneWidgetId,
    FireDelay: fireDelay ? Number(fireDelay.ParameterValue) : null
  };
};

export class ScriptRunner extends React.PureComponent {
  waiting = false;
  prev = null;
  clickIdRan = null;

  state = {
    ready: false
  }
  componentDidUpdate(prev) {
    this.prev = prev;
    let triggerFired = this.checkTrigger(prev);
    if (this.props.FireDelay !== null && triggerFired && this.props.Script && !this.waiting) {
      this.waiting = true;
      this.resetTrigger();
      setTimeout(() => {
        this.setState({ ready: true }, () => {
          this.setState({ ready: false }, () => {
            this.waiting = false;
          });
        });
      }, this.props.FireDelay);
    }

    if (this.props.FireDelay === null && triggerFired && this.props.Script) {
      this.resetTrigger();
    }
  }

  checkTrigger = (isRunning) => {
    if (!this.props.Trigger || !this.prev)
      return false;

    if (this.props.Trigger.ClickId !== undefined && this.prev.Trigger && this.prev.Trigger.ClickId !== undefined && this.props.Trigger.ClickId === this.prev.Trigger.ClickId)
      return false;

    if (this.clickIdRan && this.props.Trigger.ClickId === this.clickIdRan)
      return false;

    if (isRunning) {
      this.clickIdRan = this.props.Trigger.ClickId;
    }
    return true;
  }

  resetTrigger = () => {
    if (this.props.Trigger && this.props.Trigger.ClickId !== undefined || this.props.TriggerKey === 'dbo_SaveId' || this.props.TriggerKey === 'dbo_CancelId' || this.props.TriggerKey === 'dbo_BeforeSaveId')
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.TriggerKey,
      Value: false
    }));
  }

  render() {
    let Script = this.props.Script;

    if (this.props.Trigger && this.props.Trigger.ClickId) {
      let triggerFired = this.checkTrigger(true);
      if (triggerFired) {
        return null;
      }
    }

    if (this.props.FireDelay === null && (!this.props.Trigger || !Script))
      return null;

    if (this.props.FireDelay !== null && !this.state.ready)
      return null;

    let params = this.props.Parameter;
    if (params && !Array.isArray(params)) {
      params = [params];
    }

    return (
      <Script Parameter={params} TriggerKey={this.props.TriggerKey} Trigger={this.props.Trigger} ParameterKeys={this.props.ParameterKeys} SWID={this.props.SWID} />
    );
  }
}

export default connect(mapStateToProps)(ScriptRunner);