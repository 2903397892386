
import Feature from 'ol/Feature.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text,
} from 'ol/style.js';
import { Cluster, Vector as VectorSource, XYZ } from 'ol/source.js';
import { LineString, Point, Polygon } from 'ol/geom.js';
import { Tile as Tile, Vector as VectorLayer } from 'ol/layer.js';
import { createEmpty, extend, getHeight, getWidth } from 'ol/extent.js';
import { fromLonLat } from 'ol/proj.js';

const xyzLayer = (path, type) => {
  if (type === 'cluster') {
    return cluster(path);
  }

  return new Tile({
    visible: true,
    preload: 0,
    source: new XYZ({
      url: path
    })
  });
}

let cluster = (path) => {
  // const clusterSource = new Cluster({
  //   distance: parseInt(distanceInput.value, 10),
  //   minDistance: parseInt(minDistanceInput.value, 10),
  //   source: new XYZ({
  //     url: path
  //   }),
  // });
}

export default {
  xyzLayer: xyzLayer
}
