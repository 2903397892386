
import React from 'react';

const NewLine = props => {
  return (
    <React.Fragment>
      <div className={"card-list-new-line"}></div>
    </React.Fragment>
  );
}

export default NewLine