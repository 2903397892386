
import WKT from 'ol/format/WKT';
import Modify from 'ol/interaction/Modify';
import Collection from 'ol/Collection';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';
import StyleFunction from './../Styles/FeatureStyle';
import { buffer } from 'ol/extent';
import store from '../../../../../Stores/Store';

const requestTilesFromServer = (map, options) => {
  var vectorSource = options.vectorSource;
  var state = store.getState();

  var params = {
    BoundingBox: options.boundingBox,
    ZoomLevel: map.getView().getZoom(),
    LayerId: options.layerId,
    IsUserQueryLayer: options.isUserQueryLayer,
    Parameter: map.Parameter
  };

  if(options.layerId === 131 && state.blu_ModalScene.Value === 'AM_SignManagerModal') {
    map.Parameter = JSON.stringify([{"parameter" : "xtn.A" + state.ent_Current.TypeId + "_LincolnParks"}])
  }

  if(options.layerId === 712 && state.blu_ModalScene.Value === 'AM_MarkingsManagerModal') {
    map.Parameter = JSON.stringify([{"parameter" : "xtn.A" + state.ent_Current.TypeId}])
  }

  actions.ApiRequest('Map/GetEntities', params, (result) => {
    var features = getFeaturesFromWKT(options, result, map);

    if (map.GroupingMap) {
      addDragInteraction(map, features);
      vectorSource.clear();
    }
    vectorSource.addFeatures(features);
  });
}

let _dragPending = false;
const addDragInteraction = (map, features) => {
  if (features.length == 0)
    return;

  if (map.oldInteraction) {
    map.oldInteraction.forEach(x => map.removeInteraction(x));
  }

  let dragInteractions = [];
  features.forEach(fet => {
    let dragInteraction = new Modify({
      features: new Collection([fet]),
      style: null,
    });

    dragInteraction.on('modifyend', (e) => {
      if (!_dragPending) {
        _dragPending = true;
        let dragFet = e.features.getArray()[0];
        let coords = dragFet.getGeometry().getCoordinates();
        let saveItem = {
          Table: 'rel.AM_Grouping',
          Column: 'Geometry',
          Id: dragFet.getId().split('_')[1],
          Value: `POINT(${coords[0]} ${coords[1]})`
        }
        actions.ApiRequest('Save/Save', { SaveData: [saveItem] }, () => { });
        setTimeout(() => {
          _dragPending = false;
        }, 100);
      }
    })

    map.addInteraction(dragInteraction);
    dragInteractions.push(dragInteraction);
  })

  map.oldInteraction = dragInteractions;
}

const getFeaturesFromWKT = (options, data, map) => {
  if (!data.FeatureCollection)
    return [];

  var featuresToAdd = [];
  var format = new WKT();

  data.FeatureCollection.forEach((feature) => {
    var f = format.readFeature(feature.g, { dataProjection: options.projection, featureProjection: 'EPSG:3857' });
    let fetId = feature.etId + '_' + feature.eId;
    let parentId = null;
    if (feature.aId && feature.atId) {
      parentId = feature.aId + '_' + feature.atId;
    }

    f.setId(fetId);

    f.layerId = options.layerId;
    f.isQueryLayer = options.isUserQueryLayer;
    f.etId = feature.etId;
    f.eId = feature.eId;
    f.styleId = feature.s || options.defaultStyleId;
    f.label = feature.l;
    f.geometry = feature.g;
    f.table = feature.t;
    f.column = feature.c;
    f.parentId = parentId;
    f.displayOnly = options.displayOnly;
    f.replacement = feature.rpl;
    f.c = feature.c;
    f.c1 = feature.c1;
    f.c2 = feature.c2;
    f.l1 = feature.l1;
    f.l2 = feature.l2;
    f.w = feature.w;
    f.w1 = feature.w1;
    f.w2 = feature.w2;
    f.d = feature.d ? JSON.parse(feature.d) : null;
    f.d1 = feature.d1 ? JSON.parse(feature.d1) : null;
    f.d2 = feature.d2 ? JSON.parse(feature.d2) : null;
    let og = f.getGeometry().clone();
    f.originalGeo = og;
    f.StyleIndex = feature.StyleIndex;

    if (feature.ZoomToFeature) {
      let extent = og.getExtent();
      map.getView().fit(buffer(extent, 150), map.getSize());
    }

    featuresToAdd.push(f);
  });

  return featuresToAdd;
}

export default {
  requestTilesFromServer: requestTilesFromServer
}






