import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as lrData from './lrData';

const mapStateToProps = (state, ownProps) => {
  let userKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'UserKey');

  return {
    LeaveRequest: state.ttr_LeaveRequest,
    User: userKey ? state[userKey.ParameterValue] : undefined,
    Cancel: state.ttr_LeaveTabCancel,
    Save: state.ttr_LeaveTabSave,
    SelectedUser: state.ttr_SelectedUser
  };
};

export class LeaveRequest extends React.PureComponent {
  componentDidMount() {
    if (!this.props.LeaveRequest) {
      this.newRequest();
    }
  }

  componentDidUpdate(prev) {
    this.resetIfNeeded(prev);
  }

  newRequest = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ttr_LeaveRequest',
      Value: {
        RequestTypeId: '',
        StartDate: '',
        EndDate: '',
        Hours: 0,
        Note: '',
        IsValid: false
      }
    }));
  }

  resetIfNeeded = (prev) => {
    let reset = false;
    if (prev && prev.Cancel.ClickId !== this.props.Cancel.ClickId) {
      reset = true;
    }

    if (prev && prev.Save.ClickId !== this.props.Save.ClickId) {
      reset = true;
    }

    if (reset) {
      this.newRequest();
    }
  }

  updateProp = (e, prop) => {
    let newVal = {
      ...this.props.LeaveRequest,
      [prop]: e.target.value
    };

    if (prop === 'EndDate' && this.props.LeaveRequest['StartDate']) {
      if (this.props.LeaveRequest['StartDate'] > e.target.value) {
        newVal.Error = 'The End Date must fall after the Start Date';
      }
    }

    if (prop === 'StartDate' && this.props.LeaveRequest['EndDate']) {
      if (this.props.LeaveRequest['EndDate'] < e.target.value) {
        newVal.Error = 'The End Date must fall after the Start Date';
      }
    }

    if (!newVal.Hours)
      newVal.Hours = 0;

    newVal.IsValid = this.checkValid(newVal);

    this.props.dispatch(actions.UpdateProp({
      Key: 'ttr_LeaveRequest',
      Value: newVal
    }));
  }

  checkValid = (req) => {
    if (req.Error) {
      if (req.StartDate <= req.EndDate) {
        req.Error = null;
      }
    }
    return req.RequestTypeId && req.StartDate && req.EndDate && !req.Error;
  }

  submitRequest = () => {
    let req = this.props.LeaveRequest;
    if (!req.IsValid)
      return;

    let userId = this.props.User.Id;

    if (this.props.SelectedUser && this.props.SelectedUser.Id) {
      userId = this.props.SelectedUser.Id;
    }

    lrData.saveRequest(userId, req);
    this.newRequest();
  }

  render() {
    let request = this.props.LeaveRequest;
    return (
      !this.props.LeaveRequest || !this.props.User ? null :
        <div className="leave-request">
          <div className="request-header">Request Type</div>
          <select value={request.RequestTypeId} onChange={(e) => { this.updateProp(e, 'RequestTypeId') }}>
            {helpers.getLookupItems(34).map((x, idx) => (
              <option key={idx} value={x.LookupItemId}>{x.Name1}</option>
            ))}
          </select>
          <div className="line-item">
            <div>Start Date</div>
            <input type="date" value={request.StartDate} onChange={(e) => { this.updateProp(e, 'StartDate') }} />
          </div>
          <div className="line-item">
            <div>End Date</div>
            <input type="date" value={request.EndDate} onChange={(e) => { this.updateProp(e, 'EndDate') }} />
          </div>
          <div className="line-item">
            <div>Hours</div>
            <input type="number" value={request.Hours} onChange={(e) => { this.updateProp(e, 'Hours') }} />
          </div>
          <div className="line-item">
            <div>Note</div>
            <textarea type="text" value={request.Note} onChange={(e) => { this.updateProp(e, 'Note') }} />
          </div>
          {request.Error && <div style={{ color: 'red' }}>
            {request.Error}
          </div>}
          <div className={"lr-submit" + (request.IsValid ? '' : ' disabled')} onClick={this.submitRequest}>
            <div>Submit</div>
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(LeaveRequest);