import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    SuperAdminFilter: state.SuperAdminFilter || {},
    CompanyGrid: state._grid5328
  };
};

export class SuperAdminFilter extends React.PureComponent {

  componentDidMount() {
    let filter = this.props.Trigger;
    let rows = this.props.CompanyGrid.Rows;

    if (typeof filter === 'string')
      this.nameFilter(filter, rows);

    if (typeof filter === 'object')
      this.statusFilter(filter, rows);

    this.props.dispatch(actions.UpdateProp({
      Key: '_grid5328',
      Value: { ...this.props.CompanyGrid, init: false }
    }));
  }

  nameFilter = (filter, rows) => {
    filter = filter.substring(1, filter.length);
    rows.forEach(x => {
      x.ClassList = (x.ClassList || '').replace('name-hide', '');
      if (!(x.Name || '').toLowerCase().includes(filter.toLowerCase())) {
        x.ClassList += 'name-hide';
      }
    });
  }

  statusFilter = (filter, rows) => {
    let fId = filter.StatusId;
    let saf = this.props.SuperAdminFilter;
    if (saf[fId] === undefined)
      saf[fId] = true;

    let showing = !saf[fId];

    if (!showing) {
      document.getElementById('bubble-' + fId).classList.add('empty');
    } else {
      document.getElementById('bubble-' + fId).classList.remove('empty');
    }

    rows.forEach(x => {
      if (x.StatusLId === fId) {
        x.ClassList = (x.ClassList || '').replace('status-hide', '');
        if (!showing) {
          x.ClassList += 'status-hide';
        }
      }
    });

    saf[fId] = showing;
    this.props.dispatch(actions.UpdateProp({
      Key: 'SuperAdminFilter',
      Value: saf
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(SuperAdminFilter);