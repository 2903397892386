import React from 'react';
import { batch, connect } from 'react-redux';
// import loader from "@monaco-editor/loader";
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';

const mapStateToProps = (state, ownProps) => {
  return {};
};

export class MonacoDiff extends React.PureComponent {

  valueChange = (value, e) => {
    this.props.onChange(value);
  }

  componentDidUpdate() {
    if (this.editor) {
      this.editor.getModel().original.setValue(this.props.valA);
      this.editor.getModel().modified.setValue(this.props.valB);
    }
  }

  editorDidMount = (editor) => {
    this.editor = editor;
    editor.getModel().original.setValue(this.props.valA);
    editor.getModel().modified.setValue(this.props.valB);
  }

  render() {
    let options = {
      value: this.props.value,
      language: this.props.language || "html",
      lineDecorationsWidth: 10,
      lineNumbersMinChars: 0,
      glyphMargin: false,
      folding: false,
      minimap: {
        enabled: false
      }
    };

    return (
      <div className="monaco-editor">
        <DiffEditor
          defaultLanguage={this.props.language || 'html'}
          options={options}
          original={this.props.valA}
          modified={this.props.valB}
          onMount={this.editorDidMount}
          onChange={this.valueChange}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(MonacoDiff);