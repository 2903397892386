import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import IndeterminateCheck from './IndeterminateCheck';

export class LegendItem extends React.PureComponent {
  state = {
    expand: false
  }

  componentDidMount() {
    this.setState({ expand: this.props.ExpandByDefault });
  }

  toggleChildren = () => {
    this.setState({ expand: !this.state.expand });
  }

  textClick = () => {
    if (this.props.Children.length === 0) {
      this.onChange();
    } else {
      this.setState({ expand: !this.state.expand });
    }
  }

  onChange = () => {
    if (this.props.IsRadio && this.props.Active)
      return;

    this.props.toggleLegendItem(this.props.LayerId);
  }

  render() {
    let children = this.props.Children;
    let activeChildren = children.filter(x => x.Active);
    let isIndeterminate = activeChildren.length > 0 && activeChildren.length !== children.length;

    return (
      <div className="map-legend-item">
        <div className="legend-item-options">
          {
            children.length > 0 ?
              <div className="expand-button" onClick={this.toggleChildren}>
                <div className={(this.state.expand ? 'open' : '')}></div>
              </div>
              :
              <div className="child-spacer"></div>
          }
          {children.filter(x => x.IsRadio).length === 0 ?
            this.props.IsRadio ?
              <input type="radio" name={this.props.ParentId + 'group'} checked={this.props.Active || false} onChange={this.onChange} /> :
              <IndeterminateCheck active={this.props.Active || false} onChange={this.onChange} indeterminate={isIndeterminate} items={this.props.items} /> : null
          }
          <div className="legend-item-name" onClick={this.textClick}>{this.props.Name}</div>
        </div>
        <div className="legend-item-children">
          {this.state.expand && children.map((x, idx) => (
            <LegendItem key={idx} toggleLegendItem={this.props.toggleLegendItem} items={this.props.items} {...x} />
          ))}
        </div>
      </div>
    );
  }
}

export default LegendItem;