
import React from 'react';
import parser, { domToReact } from 'html-react-parser';
import * as actions from '../../../../../Stores/Actions/actions';
import FormControl from './../../../Form/v001/FormControl';

const HTML = props => {
  let updatePropClick = () => {
    if (!props.card.UpdateProp || !props.card.UpdateProp.Key)
      return;

    props.dispatch(actions.UpdateProp({
      Key: props.card.UpdateProp.Key,
      Value: props.card.UpdateProp.Value
    }));
  }

  let classes = '';

  if (props.selected && props.selected.Id === props.card.Id && props.card.Id !== undefined) {
    classes += ' selected';
  } else if (props.card.Id && props.card.GobId && props.card.Id === props.card.GobId) {
    classes += ' selected';
  }

  if (props.card.DisableClick) {
    classes += ' disableclick';
  }

  if (props.card.ScrollIntoView) {
    setTimeout(() => {
      let viewCard = document.querySelector('#html-' + props.card.Id);
      if (viewCard) {
        viewCard.scrollIntoView({ block: "center" });
      }
    }, 0);
    props.card.ScrollIntoView = false;
  }

  let onClick = () => {
    props.parentClick(props.card);
  }

  let checkForDefaults = () => {
    Object.keys(props.card.FormControls).forEach(key => {
      let control = props.card.FormControls[key];

      if (control.DefaultIfMissing !== undefined) {
        console.log({ default: control.DefaultIfMissing });
        props.dispatch(actions.DefaultSaveDataIfMissing({
          ...control.SaveData,
          Id: control.SaveData.Id,
          Table: control.SaveData.Table,
          Column: control.SaveData.Column,
          SaveQueue: control.SaveData.SaveQueue,
          InsertKey: control.SaveData.InsertKey,
          IsBaseTable: control.IsBaseTable,
          DefaultValue: control.DefaultIfMissing
        }));
      }
    });
  }

  if (props.card.CheckDefaultOnLoad && !props.card._defaulted) {
    setTimeout(() => { checkForDefaults(); }, 0);
    props.card._defaulted = 1;
  }

  return (
    <div className={'html-card' + classes} onClick={onClick} id={(props.card.Id ? 'html-' + props.card.Id : null)}>
      {props.card.Markup && parser(props.card.Markup, {
        replace: domNode => {
          if (domNode.name === 'updateprop') {
            return (
              <div onClick={() => { updatePropClick() }}>
                {domToReact(domNode.children)}
              </div>
            )
          }
          if (domNode.name === 'formcontrol') {
            return (
              <FormControl
                control={props.card.FormControls[domNode.attribs.alias]}
                entity={props.card.FormEntity}
                trackChanges={true}
                refresh={checkForDefaults}
              />
            )
          }
        }
      })}
    </div>
  );
}

export default HTML;