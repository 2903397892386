import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../Stores/Actions/actions';
import widgets from './WidgetMap';
import NotFound from './NotFound';
import * as helpers from './../Utils/Helpers';
import FullEditModeWidget from './Widgets/FullEditModeWidget/FullEditModeWidget';

const mapStateToProps = (state, ownProps) => {
  let className = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClassName');
  let renderCondition = ownProps.widget.Parameters.find(x => x.ParameterName === 'RenderCondition');
  let entityTypePermission = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityTypePermission');
  let resize = ownProps.widget.Parameters.find(x => x.ParameterName === 'Resizable');

  let conditionalClassName = ownProps.widget.Parameters.find(x => x.ParameterName === 'ConditionalClassName');
  let classNameCondition = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClassNameCondition');

  let showConditionalClass = false;
  if (classNameCondition && classNameCondition.ParameterValue) {
    try {
      showConditionalClass = eval(classNameCondition.ParameterValue);
    } catch (e) {
      console.error('error in ClassNameCondition: ' + classNameCondition.ParameterValue)
    }
  }

  let _helpers = helpers;
  let render = true;
  if (renderCondition && renderCondition.ParameterValue) {
    try {
      render = eval(renderCondition.ParameterValue);
    } catch (e) {
      console.error('error in RenderCondition: ' + renderCondition.ParameterValue)
    }
  }

  if (render && entityTypePermission) {
    let permissionType = Number(entityTypePermission.ParameterValue);
    let entityType = state.met_EntityMetadata.EntityTypes.find(x => x.TypeId === permissionType);
    render = !!entityType;
  }

  return {
    ClassName: className ? className.ParameterValue : '',
    EditingWidget: state.EditingWidget || {},
    EditMode: state.EditMode,
    FindWidget: state.FindWidget,
    Resizable: resize ? helpers.stringToBool(resize.ParameterValue) : false,
    ActiveSWIDs: state.ActiveSWIDs,
    PersistedWidgets: state.PersistedWidgets,
    RenderCondition: render,
    ConditionalClass: showConditionalClass && conditionalClassName ? conditionalClassName.ParameterValue : '',
    FullEditMode: state.FullEditMode,
    FullEditSubSceneSwid: state.FullEditSubScene ? state.FullEditSubScene.SWID : null
  }
}

export class WidgetLoader extends React.PureComponent {
  componentDidMount() {
    this.setWidgetActive(true);
  }

  componentWillUnmount() {
    this.setWidgetActive(false);
  }

  setWidgetActive = (isActive) => {
    this.props.dispatch(actions.SetSwidActive({
      Key: this.props.widget.SceneWidgetId,
      Value: isActive
    }));
  }

  finderClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.dispatch(actions.UpdateProp({
      Key: 'FindWidget',
      Value: !this.props.FindWidget
    }));
    this.props.dispatch(actions.SetEditingWidgetBySwid(this.props.widget.SceneWidgetId));
  }

  startX = null;
  startResize = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.startX) {
      this.startX = e.clientX;
    }
    document.addEventListener('mouseup', this.mouseUp);
    document.addEventListener('mousemove', this.mouseMove);
  }

  mouseUp = (e) => {
    document.removeEventListener('mouseup', this.mouseUp);
    document.removeEventListener('mousemove', this.mouseMove);
  }

  mouseMove = (e) => {
    this.props.resizeWidget(this.props.widget, e.clientX - this.startX);
    this.startX = e.clientX;
  }


  render() {
    let MyWidget;
    let swid = this.props.widget.SceneWidgetId;

    if (this.props.widget.PersistWhenInactive) {
      if (!this.props.PersistedWidgets[swid]) {
        MyWidget = widgets[this.props.widget.WidgetName] ?
          widgets[this.props.widget.WidgetName][this.props.widget.WidgetVersion] : undefined;
        if (MyWidget) {
          this.props.dispatch(actions.UpdateProp({
            Key: 'PersistedWidgets',
            Value: { ...this.props.PersistedWidgets, [swid]: { Element: MyWidget, Widget: this.props.widget, Portal: this.props.container } }
          }));
        }
      } else {
        if (this.props.container) {
          this.props.PersistedWidgets[swid].Portal = this.props.container;
        }

      }
    } else {
      MyWidget = widgets[this.props.widget.WidgetName] ?
        widgets[this.props.widget.WidgetName][this.props.widget.WidgetVersion] : undefined;
    }

    let fullEdit = this.props.widget.WidgetName !== 'SubScene' && this.props.widget.SceneName !== 'Engines' && this.props.FullEditMode;
    let subSceneHandle = this.props.widget.WidgetName === 'SubScene' && this.props.widget.SceneName !== 'Engines' && this.props.showSubSceneHandle;

    return !this.props.RenderCondition ? null :
      this.props.widget.PersistWhenInactive ? null : (
        <div id={'swid-' + swid} className={!this.props.FullEditMode ? ("widget-loader " + this.props.ClassName
          + (this.props.EditMode && this.props.EditingWidget.SceneWidgetId === swid ? ' editing-this-widget' : '')
          + (this.props.widget.SceneName === 'Engines' ? ' engine-widget' : '')
          + (this.props.Resizable ? ' has-handle' : '')
          + (this.props.ConditionalClass ? ' ' + this.props.ConditionalClass : '')) : 'widget-loader'}
          style={{ gridColumn: this.props.widget.Columns, gridRow: this.props.widget.Rows }}
          onMouseDown={this.props.FindWidget ? (e) => { this.finderClick(e) } : undefined}>
          {!fullEdit && MyWidget && <React.Fragment>
            <MyWidget widget={this.props.widget} />
            {this.props.Resizable && <div className="resize-handle" onMouseDown={this.startResize}></div>}
          </React.Fragment>}
          {fullEdit && MyWidget && <React.Fragment>
            <FullEditModeWidget widget={this.props.widget} />
          </React.Fragment>}
          {subSceneHandle && MyWidget && <React.Fragment>
            <FullEditModeWidget widget={this.props.widget} subSceneHandle={true} />
          </React.Fragment>}
          {!MyWidget && <NotFound name={this.props.widget.WidgetName} version={this.props.widget.WidgetVersion} />}
        </div>
      );
  }
}

export default connect(mapStateToProps)(WidgetLoader);