import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import { Trashcan, EmptyCheck, EmptyRadio, FillRadio, Checked } from './../../../Icons/Icons';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as LayoutEngine from './LayoutEngine'
import ShareWith from './ShareWith';
import * as Save from './Save';
import { LoadingDotSpinner } from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let shareUserListSproc = ownProps.widget.Parameters.find(x => x.ParameterName === 'Sproc');
  let sprocParameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let queryId = null;
  let gc = state.qry_GroupedColumns;

  if (state.qry_SelectedQuery) {
    queryId = state.qry_SelectedQuery.QueryId;
  }

  return {
    SelectedQuery: state.qry_SelectedQuery,
    QueryId: queryId,
    Hover: state.qry_GroupByHover,
    GroupedColumns: gc && queryId ? gc[queryId] : undefined,
    SelectedQuery: state.qry_SelectedQuery,
    ResetTop: state.qry_ResetTop || 1,
    Queries: state.qry_Queries,
    RefreshCurrentPage: state.qry_RefreshCurrentPage,
    Metadata: state.met_EntityMetadata,
    WidgetState: state.widgetState[ownProps.widget.SceneWidgetId],
    SWID: ownProps.widget.SceneWidgetId,
    Layouts: state.qry_Layouts || [],
    RefreshLayouts: state.qry_RefreshLayouts,
    HasUserListSproc: shareUserListSproc ? true : false,
    SprocParameter: sprocParameter ? sprocParameter.ParameterValue : ''
  };
}

export class QueryLayout extends React.PureComponent {
  initialState = {
    Name: '',
    Note: '',
    IsPublic: false,
    IsDefault: false,
    IsDeleted: false,
    IsDirty: false,
    ShareWith: '',
    Id: 0
  }

  state = cloneDeep(this.initialState);

  constructor(props) {
    super(props);
    this.nameInput = React.createRef();
    this.focusName = this.focusName.bind(this);
  }

  focusName = () => {
    this.nameInput.current.focus();
  }

  componentDidMount() {
    if (this.props.WidgetState) {
      this.setState(this.props.WidgetState);
      if (this.props.WidgetState.QueryId !== this.props.QueryId) {
        this.resetState();
      }
    }
    if (this.props.Layouts.length === 0) {
      this.getLayouts();
    }
    this.checkRefresh();
  }

  componentDidUpdate() {
    this.checkRefresh();
  }

  checkRefresh = () => {
    if (this.props.RefreshLayouts) {
      this.getLayouts();
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_RefreshLayouts',
        Value: false
      }));
    }
  }

  getLayouts = () => {
    this.setState({ Loading: true }, () => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_Layouts',
        Value: []
      }));

      actions.ApiRequest('Query/GetLayouts', {}, (result) => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'qry_Layouts',
          Value: result
        }));
        this.setState({ Loading: false });
      })
    });
  }

  newLayout = () => {
    if (this.state.IsDirty)
      return;

    let newLayout = {
      Id: null,
      InsertKey: helpers.getInsertKey(),
      Name: '',
      Note: '',
      IsPublic: false,
      IsDefault: false,
      QueryId: this.props.QueryId,
      IsNew: true,
      Layout: null,
      UserId: this.props.Metadata ? this.props.Metadata.CurrentUser : null,
      ShareWith: ''
    }

    this.editLayout(newLayout);

    this.props.dispatch(actions.UpdateProp({
      Key: 'qry_Layouts',
      Value: [...this.props.Layouts, newLayout]
    }));

    this.setState({ IsDirty: true });
    this.focusName();
  }

  saveLayout = () => {
    if (!this.state.IsDirty)
      return;

    let layout = this.getCurrentLayout();
    if (!layout.Layout) {
      layout.Layout = LayoutEngine.getLayoutObject();
    }
    this.setState({ Loading: true }, () => {
      Save.saveLayout(this.props.Metadata.CurrentUser, layout, this.props.QueryId, this.state, () => {
        this.refresh();
      });
    });
  }

  onChange = (e, prop) => {
    let layout = this.getCurrentLayout();
    if (!layout)
      return;

    layout[prop] = e.target.value;
    this.setState({
      [prop]: e.target.value,
      IsDirty: true
    });
  }

  getCurrentLayout = () => {
    return this.props.Layouts.find(x => (x.Id || x.InsertKey) === (this.state.Id || this.state.InsertKey));
  }

  editLayout = (layout) => {
    this.setState({
      Name: layout.Name,
      Note: layout.Note,
      Id: layout.Id,
      IsPublic: layout.IsPublic,
      IsDefault: layout.IsDefault,
      InsertKey: layout.InsertKey,
      ShareWith: layout.ShareWith || '',
      IsDirty: layout.IsNew ? true : false,
      Loading: false
    });
  }

  layoutClick = () => {
    let layout = this.props.Layouts.find(x => (this.state.Id || this.state.InsertKey) === (x.Id || x.InsertKey));
    this.loadLayout(layout);
  }

  loadLayout = (layout) => {
    if (!layout)
      return;

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'agGridLoadLayout',
        Value: layout.Layout
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'agCurrentLayout',
        Value: { ...layout, Layout: '' }
      }));
    })
  }

  deleteLayout = () => {
    let layout = this.getCurrentLayout();
    let state = cloneDeep(this.state);
    state.IsDeleted = true;
    layout.IsDeleted = true;
    this.setState({ Loading: true }, () => {
      Save.saveLayout(this.props.Metadata.CurrentUser, layout, this.props.QueryId, state, () => {
        this.refresh();
      });
    });
  }

  cancelClick = () => {
    if (!this.state.IsDirty)
      return;

    this.refresh();
  }

  refresh = () => {
    this.getLayouts();
    this.resetState();
  }

  resetState = () => {
    let newState = cloneDeep(this.initialState);
    this.setState(newState);
  }

  componentWillUnmount() {
    this.setWidgetState(this.state);
  }

  setWidgetState = (state) => {
    this.props.dispatch(actions.SetWidgetState({
      swid: this.props.SWID,
      state: {
        ...state,
        QueryId: this.props.QueryId
      }
    }));
  }

  shareWith = (id) => {
    let ids = this.state.ShareWith.split(', ').filter(x => x !== '');
    if (ids.includes(id)) {
      ids = ids.filter(x => x !== id);
    } else {
      ids.push(id);
    }

    this.setState({ ShareWith: ids.join(', '), IsDirty: true });
  }

  isDisabled = () => {
    let result = true;
    let curLayout = this.getCurrentLayout();
    let userId = this.props.Metadata ? this.props.Metadata.CurrentUser : null;

    if (curLayout && (curLayout.IsNew || curLayout.UserId === userId)) {
      result = false;
    }

    return result;
  }

  render() {
    let curLayout = this.getCurrentLayout();
    let loadEnabled = curLayout && !curLayout.IsNew;
    let userId = this.props.Metadata ? this.props.Metadata.CurrentUser : null;

    let isDisabled = this.isDisabled();
    return (
      <div className="query-layout">
        {this.state.Loading && <div className="layout-loading">
          <div className="layout-spinner-container">
            <LoadingDotSpinner />
          </div>
        </div>}
        {this.props.Layouts &&
          <div className="layout-list">
            <div className="layout-list-header">My Layouts</div>
            {this.props.Layouts.filter(x => x.UserId === userId && !x.IsDeleted && x.QueryId === this.props.QueryId).map((layout, idx) => (
              <div key={idx} className={"layout-item" + ((this.state.Id || this.state.InsertKey) === (layout.Id || layout.InsertKey) ? ' active' : '')} onClick={() => { this.editLayout(layout) }} >
                <div className="layout-name">{layout.Name || ' '}</div>
                <div className="delete-layout"> </div>
                <div className="delete-icon" onClick={this.deleteLayout}><Trashcan /></div>
              </div>
            ))}
            <div className="layout-list-header">Shared Layouts</div>
            {this.props.Layouts.filter(x => x.UserId !== userId && !x.IsDeleted && x.QueryId === this.props.QueryId).map((layout, idx) => (
              <div key={idx} className={"layout-item" + ((this.state.Id || this.state.InsertKey) === (layout.Id || layout.InsertKey) ? ' active' : '')} onClick={() => { this.editLayout(layout) }} >
                <div className="layout-name">{layout.Name}</div>
              </div>
            ))}
          </div>}
        <div className="edit-layout">
          <div className={"layout-load-btn" + (loadEnabled ? ' active' : '')} onClick={() => { if (loadEnabled) { this.layoutClick() } }}>
            <div>Load Layout</div>
          </div>
          <div className="layout-control">
            <div>Name</div>
            <input type='text' disabled={isDisabled} ref={this.nameInput} value={this.state.Name} onChange={(e) => { this.onChange(e, 'Name') }} />
          </div>
          <div className="layout-control">
            <div>Note</div>
            <textarea type='text' disabled={isDisabled} value={this.state.Note} onChange={(e) => { this.onChange(e, 'Note') }} />
          </div>
          {!isDisabled &&
            <div className="checkbox-bar">
              <div className="layout-cbox default-cbox">
                {this.state.IsDefault &&
                  <div className="cbox" onClick={() => { this.onChange({ target: { value: false } }, 'IsDefault') }}>
                    <Checked />
                  </div>
                }
                {!this.state.IsDefault &&
                  <div className="cbox" onClick={() => { this.onChange({ target: { value: true } }, 'IsDefault') }}>
                    <EmptyCheck />
                  </div>
                }
                <div className="control-label">Default</div>
              </div>
              <ShareWith SprocParameter={this.props.SprocParameter} HasUserListSproc={this.props.HasUserListSproc} SWID={this.props.SWID} IsPublic={this.state.IsPublic} shareWith={this.state.ShareWith} shareClick={this.shareWith} onChange={this.onChange} />
            </div>
          }
          {isDisabled &&
            <div className="checkbox-bar">
            </div>
          }
          <div className="layout-buttons">
            <div className={"layout-btn" + (this.state.IsDirty ? '' : ' active')} onClick={this.newLayout}>
              <div>New</div>
            </div>
            <div className={"layout-btn" + (this.state.IsDirty ? ' active' : '')} onClick={this.saveLayout}>
              <div>Save</div>
            </div>
            <div className={"layout-btn" + (this.state.IsDirty ? ' active' : '')} onClick={this.cancelClick}>
              <div>Cancel</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(QueryLayout);