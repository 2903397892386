import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const CENTER_OF_USA = {lat:39.8283,long:-98.5795}

const mapStateToProps = (state, ownProps) => {
  let key = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let prop = ownProps.widget.Parameters.find(x => x.ParameterName === 'PropertyName');
  let type = ownProps.widget.Parameters.find(x => x.ParameterName === 'ApiType');
  let maxResults = ownProps.widget.Parameters.find(x => x.ParameterName === 'MaxResults');
  let minChars = ownProps.widget.Parameters.find(x => x.ParameterName === 'MinCharacters');
  let mapCenterpointSwid = ownProps.widget.Parameters.find(x => x.ParameterName === 'MapCenterpointSwid');
  let alwaysShow = ownProps.widget.Parameters.find(x => x.ParameterName === 'AlwaysShowResults');
  let prependResults = ownProps.widget.Parameters.find(x => x.ParameterName === 'PrependResults');

  let entityProp;
  if (prop && key && state[key.ParameterValue] && state[key.ParameterValue].Properties) {
    entityProp = state[key.ParameterValue].Properties.find(x => x.Name === prop.ParameterValue);
  }

  let cfgSetting = state.met_EntityMetadata ? state.met_EntityMetadata.ConfigurationSettings : null;
  let geoParam = '';
  if (cfgSetting) {
    let geoSetting = cfgSetting.find(x => x.Key === 'GeoLocateQueryParam');
    if (geoSetting) {
      geoParam = geoSetting.Value;
    }
  }

  let prepend = null;
  if (prependResults) {
    try {
      prepend = JSON.parse(prependResults.ParameterValue);
    } catch {
      console.error('your prepend parameter is not valid JSON');
    }
  }

  return {
    Entity: key ? state[key.ParameterValue] : null,
    EntityKey: key ? key.ParameterValue : null,
    EntityProp: entityProp,
    Type: type ? type.ParameterValue : null,
    MaxResults: maxResults ? maxResults.ParameterValue : null,
    MinChars: minChars ? Number(minChars.ParameterValue) : 1,
    UserLoc: state.UserLocation,
    Parameter: geoParam,
    CenterpointSwid: mapCenterpointSwid ? Number(mapCenterpointSwid.ParameterValue) : undefined,
    AlwaysShowResults: alwaysShow ? helpers.stringToBool(alwaysShow.ParameterValue) : false,
    Metadata: state.met_EntityMetadata,
    SWID: ownProps.widget.SceneWidgetId,
    PrependResults: prepend,
    ActiveConsumerId: state.ms_ActiveConsumerId ? state.ms_ActiveConsumerId : null,
    SaveId: state.dbo_SaveId,
    CurrentContractor: state.ent_CurrentContractor ? state.ent_CurrentContractor : null
  };
};

export class TypeaheadApi extends React.PureComponent {
  oldVal = '';
  lastSearch = '';
  componentDidUpdate() {
    if (this.inputChanged()) {
      this.apiQuery();
    }
    // if (this.props.Type === 'BingUSASearch' && this.inputChanged()) { //bug where UserLocation not being filled out, also would break mysalesman in the case they deny location being used
    //   this.apiQuery();
    // } else if (this.props.UserLoc && this.props.UserLoc.Latitude && this.inputChanged()) {
    //   this.apiQuery();
    // }
  }

  componentDidMount() {
  }

  inputChanged = () => {
    if (!this.props.EntityProp)
      return false;

    let prop = this.props.EntityProp;
    let result = this.oldVal !== prop.Value;

    if (!prop.Value)
      return;

    this.oldVal = prop.Value;
    let validLength = prop.Value.length >= this.props.MinChars;
    if (!validLength && prop.Typeahead && prop.Typeahead.length > 0) {
      this.props.EntityProp.Typeahead = [];
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: cloneDeep(this.props.Entity)
      }));
    }

    return result && validLength;
  }

  apiQuery = () => {
    if (this.props.Type === 'BingGeocode')
      this.bingGeoQuery();

    if (this.props.Type === 'Beehive')
      this.beehiveQuery();

    if (this.props.Type === 'BingUSASearch')
      this.bingUSASearch();

    if (this.props.Type === 'BingGeolocateNoBounds')
      this.bingGeolocateNoBounds();

    if (this.props.Type === 'MSBingSearch')
      this.msBingSearch();
  }


  beehiveQuery = () => {
    let searchText = this.props.EntityProp.Value;
    this.lastSearch = encodeURIComponent(searchText);

    let mapCenter = null;
    if (this.props.CenterpointSwid) {
      mapCenter = helpers.getMapCenter(this.props.CenterpointSwid);
    }

    let param = {
      SearchText: this.lastSearch,
      Lat: mapCenter ? mapCenter[1] : this.props.UserLoc.Latitude,
      Long: mapCenter ? mapCenter[0] : this.props.UserLoc.Longitude,
      MaxResults: this.props.MaxResults,
      QueryParameter: this.props.Parameter
    }

    let tenant = this.getTenant();
    if (tenant) {
      let parts = JSON.parse(tenant.Bounds);
      if (Array.isArray(parts)) {
        param.BottomRight = `${parts[1]},${parts[2]}`;
        param.TopLeft = `${parts[3]},${parts[0]}`;
      }
    }

    let body = {
      Parameter: JSON.stringify(param),
      SWID: this.props.SWID
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      this.props.EntityProp.Typeahead = result.map(x => {
        return {
          Text1: x.Text1,
          Text2: x.Text2,
          Text3: x.Text3,
          Latitude: x.Latitude,
          Longitude: x.Longitude,
          AlwaysShow: this.props.AlwaysShowResults,
          Zoom: x.Zoom
        }
      });

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: cloneDeep(this.props.Entity)
      }));
    });
  }

  bingGeolocateNoBounds = () => {
    let searchText = this.props.EntityProp.Value;
    this.lastSearch = encodeURIComponent(searchText);

    let mapCenter = [1, 1];
    if (this.props.CenterpointSwid) {
      mapCenter = helpers.getMapCenter(this.props.CenterpointSwid);
    }

    let body = {
      SearchText: this.lastSearch,
      MaxResults: this.props.MaxResults,
      Lat: this.props.UserLoc ? this.props.UserLoc.Latitude : mapCenter[1],
      Long: this.props.UserLoc ? this.props.UserLoc.Longitude : mapCenter[0],
      QueryParameter: this.props.Parameter,
      AddressOnly: true
    }

    window.map_typeAheadLoading = true;
    actions.ApiRequest('Public311/BingGeolocateUSAddress', body, (result, reqBody) => {
      window.map_typeAheadLoading = false;
      if (!result || !result.summary || this.lastSearch !== reqBody.SearchText) {
        console.log('prevent result');
        return;
      }

      let typeaheadItems = result.results.filter(x => x.address && x.position).map(x => {
        return {
          Text1: x.address.freeformAddress,
          Text2: x.poi ? x.poi.name : '',
          Latitude: x.position.lat,
          Longitude: x.position.lon,
          AlwaysShow: this.props.AlwaysShowResults
        }
      });

      this.props.EntityProp.Typeahead = typeaheadItems;
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: cloneDeep(this.props.Entity)
      }));
    }, false);
  }

  bingUSASearch = () => {
    // let prop = this.props.Entity.Properties.find(x => x.Name === this.props.PropertyName);    
    let searchText = this.props.EntityProp.Value;
    this.lastSearch = encodeURIComponent(searchText);

    let mapCenter = null;
    if (this.props.CenterpointSwid) {
      mapCenter = helpers.getMapCenter(this.props.CenterpointSwid);
    }

    let body = {
      SearchText: this.lastSearch,
      // Lat: mapCenter ? mapCenter[1] : this.props.UserLoc.Latitude,
      // Long: mapCenter ? mapCenter[0] : this.props.UserLoc.Longitude,
      MaxResults: this.props.MaxResults,
      QueryParameter: this.props.Parameter,
      AddressOnly: true
    }

    let tenant = this.getTenant();
    if (tenant && tenant.Bounds) {
      let parts = JSON.parse(tenant.Bounds);
      if (Array.isArray(parts)) {
        body.BottomRight = `${parts[1]},${parts[2]}`;
        body.TopLeft = `${parts[3]},${parts[0]}`;
      }
    }

    window.map_typeAheadLoading = true;
    actions.ApiRequest('Public311/BingGeolocateUSAddress', body, (result, reqBody) => {
      window.map_typeAheadLoading = false;
      if (!result || !result.summary || this.lastSearch !== reqBody.SearchText) {
        console.log('prevent result');
        return;
      }

      let typeaheadItems = result.results.filter(x => x.address && x.position).map(x => {
        return {
          Text1: x.address.freeformAddress,
          Text2: x.poi ? x.poi.name : '',
          Latitude: x.position.lat,
          Longitude: x.position.lon,
          AlwaysShow: this.props.AlwaysShowResults
        }
      });

      this.props.EntityProp.Typeahead = typeaheadItems;
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: cloneDeep(this.props.Entity)
      }));
    }, false);
  }

  msBingSearch = () => {
    let searchText = this.props.EntityProp.Value;
    this.lastSearch = encodeURIComponent(searchText);

    let body = {
      SearchText: this.lastSearch,
      MaxResults: this.props.MaxResults,
      QueryParameter: this.props.Parameter,
      AddressOnly: true
    }

    let tenant = this.getTenant();
    if (tenant && tenant.Bounds) {
      let parts = JSON.parse(tenant.Bounds);
      if (Array.isArray(parts)) {
        body.BottomRight = `${parts[1]},${parts[2]}`;
        body.TopLeft = `${parts[3]},${parts[0]}`;
      }
    }

    window.map_typeAheadLoading = true;
    actions.ApiRequest('Public311/BingGeolocateUSAddress', body, (result, reqBody) => {
      window.map_typeAheadLoading = false;
      if (!result || !result.summary || this.lastSearch !== reqBody.SearchText) {
        return;
      }

      if(result.results && result.results.length < 1) {
        if(this.props.ActiveConsumerId !== null) {
          let saveData = []
          const baseConsumerObj = {
            Id: this.props.ActiveConsumerId,
            Table: 'xmv.Consumer',
            IsBaseTable: true,
            EntityTypeId: 2040
          }
          
          saveData.push(helpers.saveDataItem(baseConsumerObj, 'LocationSearch', result.summary.query));
          saveData.push(helpers.saveDataItem(baseConsumerObj, 'Address', 'Out of service area'));
    
          actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
            this.props.dispatch(actions.UpdateProp({
              Key: 'dbo_SaveId',
              Value: this.props.SaveId + 1
            }));
            
            const phone_props = this.props.CurrentContractor.Properties.find(e => e.Name === "SalesPhone")
            if(phone_props && phone_props.Value !== '' && phone_props.Value !== null) {
              this.props.dispatch(actions.UpdateProp({
                Key: 'blu_Dialog',
                Value: { Title: 'Address outside of service area', Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>The Address entered is outside of our service area. Please call {phone_props.Value} for an estimate.</p> }
              }));
            } else {
              this.props.dispatch(actions.UpdateProp({
                Key: 'blu_Dialog',
                Value: { Title: 'Address outside of service area', Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>The Address entered is outside of our service area. Please call for an estimate.</p> }
              }));
            }
          });
        }
        return;
      }

      let typeaheadItems = result.results.filter(x => x.address && x.position).map(x => {
        return {
          Text1: x.address.freeformAddress,
          Text2: x.poi ? x.poi.name : '',
          Latitude: x.position.lat,
          Longitude: x.position.lon,
          AlwaysShow: this.props.AlwaysShowResults
        }
      });

      this.props.EntityProp.Typeahead = typeaheadItems;
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: cloneDeep(this.props.Entity)
      }));
    }, false);
  }

  bingGeoQuery = () => {
    // let prop = this.props.Entity.Properties.find(x => x.Name === this.props.PropertyName);    
    let searchText = this.props.EntityProp.Value;
    this.lastSearch = encodeURIComponent(searchText);

    let mapCenter = null;
    if (this.props.CenterpointSwid) {
      mapCenter = helpers.getMapCenter(this.props.CenterpointSwid);
    }

    let body = {
      SearchText: this.lastSearch,
      Lat: mapCenter ? mapCenter[1] : this.props.UserLoc ? this.props.UserLoc.Latitude : CENTER_OF_USA.lat,
      Long: mapCenter ? mapCenter[0] : this.props.UserLoc ? this.props.UserLoc.Longitude : CENTER_OF_USA.long,
      
      MaxResults: this.props.MaxResults,
      QueryParameter: this.props.Parameter,
      // AddressOnly: true
    }

    let tenant = this.getTenant();
    if (tenant && tenant.Bounds) {
      let parts = JSON.parse(tenant.Bounds);
      if (Array.isArray(parts)) {
        body.BottomRight = `${parts[1]},${parts[2]}`;
        body.TopLeft = `${parts[3]},${parts[0]}`;
      }
    }

    window.map_typeAheadLoading = true;
    actions.ApiRequest('Public311/BingGeolocate', body, (result, reqBody) => {
      window.map_typeAheadLoading = false;
      if (!result || !result.summary || this.lastSearch !== reqBody.SearchText) {
        console.log('prevent result');
        return;
      }

      let loc = [
        mapCenter ? mapCenter[1] : this.props.UserLoc ? this.props.UserLoc.Latitude : CENTER_OF_USA.lat,
        mapCenter ? mapCenter[0] : this.props.UserLoc ? this.props.UserLoc.Longitude : CENTER_OF_USA.long,
      ];

      let typeaheadItems = result.results.filter(x => x.address && x.position).map(x => {
        let dist = helpers.distance(loc[0], loc[1], x.position.lat, x.position.lon);
        return {
          Text1: x.address.freeformAddress,
          Text2: x.poi ? x.poi.name : '',
          Text3: dist.toFixed(2) + ' mi',
          Latitude: x.position.lat,
          Longitude: x.position.lon,
          AlwaysShow: this.props.AlwaysShowResults
        }
      });

      if (this.props.PrependResults) {
        let prependItems = this.props.PrependResults.filter(x => x.Name && x.Name.toLowerCase().startsWith(this.lastSearch.toLowerCase())).map(x => {
          let dist = helpers.distance(loc[0], loc[1], x.Latitude, x.Longitude);
          return {
            Text1: x.Name,
            Text2: 'Point of Interest',
            Text3: dist.toFixed(2) + ' mi',
            Latitude: x.Latitude,
            Longitude: x.Longitude,
            AlwaysShow: this.props.AlwaysShowResults
          }
        });
        typeaheadItems = [...prependItems, ...typeaheadItems];
      }

      this.props.EntityProp.Typeahead = typeaheadItems;
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: cloneDeep(this.props.Entity)
      }));
    }, false);
  }

  getTenant = () => {
    let ent = this.props.Entity;
    let tenantId = ent && ent.TenantId ? ent.TenantId : null;
    if (this.props.Metadata && this.props.Metadata.UserTenants) {
      if (tenantId) {
        return this.props.Metadata.UserTenants.find(x => x.Id === tenantId) || null;
      } else {
        return this.props.Metadata.UserTenants.find(x => x.IsPrimary) || null;
      }
    }
    return null;
  }

  clearTypeahead = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.EntityKey,
      Value: cloneDeep(this.props.Entity)
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(TypeaheadApi);