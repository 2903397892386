import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    PartConfigurations: state.inv_PartConfigurations,
    InventorySelected: state.ent_InventorySelected,
    SaveId: state.dbo_SaveId,
    InventorySaveData: state.dbo_InventorySaveData,
    IsActive: state.AM_PartConfigurationSave_Active,
    SaveId: state.dbo_SaveId
  };
};

export class AM_PartConfigurationSave extends React.PureComponent {
  componentDidMount() {
    if (this.props.IsActive)
      return;

    if (!this.props.PartConfigurations || !this.props.InventorySelected)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: 'AM_PartConfigurationSave_Active',
      Value: true
    }));

    let baseObj = {
      Id: null,
      InsertKey: helpers.getInsertKey(),
      Table: 'inv.ItemFilter',
      IsBaseTable: false
    };

    let saveData = [];
    let hasActiveChildren = false;

    //Tiered cardlist for parts permissioning
    this.props.PartConfigurations.forEach(agency => {

      hasActiveChildren = false;
      if (agency.Children) {
        if (agency.Children.filter(x => x.Active).length > 0) {
          hasActiveChildren = true;
        }
        //check grandchildren
        agency.Children.forEach(x => {
          if (x.Children && x.Children.filter(x => x.Active).length > 0) {
            hasActiveChildren = true;
          }
        })
      }

      if (!agency.Active && !hasActiveChildren) { //and 
        console.log('agency added');
        baseObj = { ...baseObj, InsertKey: helpers.getInsertKey() };
        saveData.push(helpers.saveDataItem(baseObj, 'EntityTypeId1', agency.Id));
        saveData.push(helpers.saveDataItem(baseObj, 'ItemId2', this.props.InventorySelected.EntityId));
        saveData.push(helpers.saveDataItem(baseObj, 'TargetItemId', this.props.InventorySelected.EntityId));
      } else {
        if (agency.Children) {
          agency.Children.forEach(assetClass => {

            hasActiveChildren = false;
            if (assetClass.Children && assetClass.Children.filter(x => x.Active).length > 0) {
              hasActiveChildren = true;
            }

            if (!assetClass.Active && !hasActiveChildren) {
              console.log('asset added');
              baseObj = { ...baseObj, InsertKey: helpers.getInsertKey() };
              saveData.push(helpers.saveDataItem(baseObj, 'EntityTypeId1', agency.Id));
              saveData.push(helpers.saveDataItem(baseObj, 'EntityTypeId2', assetClass.Id));
              saveData.push(helpers.saveDataItem(baseObj, 'ItemId2', this.props.InventorySelected.EntityId));
              saveData.push(helpers.saveDataItem(baseObj, 'TargetItemId', this.props.InventorySelected.EntityId));
            } else {
              if (assetClass.Children) {
                assetClass.Children.forEach(eventClass => {
                  if (!eventClass.Active) {
                    console.log('event added');
                    baseObj = { ...baseObj, InsertKey: helpers.getInsertKey() };
                    saveData.push(helpers.saveDataItem(baseObj, 'EntityTypeId1', agency.Id));
                    saveData.push(helpers.saveDataItem(baseObj, 'EntityTypeId2', assetClass.Id));
                    saveData.push(helpers.saveDataItem(baseObj, 'EntityTypeId3', eventClass.Id));
                    saveData.push(helpers.saveDataItem(baseObj, 'ItemId2', this.props.InventorySelected.EntityId));
                    saveData.push(helpers.saveDataItem(baseObj, 'TargetItemId', this.props.InventorySelected.EntityId));
                  }
                })
              }
            }
          })
        }
      }
    })

    let body = {
      SWID: this.props.SWID,
      Parameter: this.props.InventorySelected.EntityId
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
        let inventSelected = this.props.InventorySelected;
        batch(() => {
          this.waiting = false;
          this.props.dispatch(actions.UpdateProp({
            Key: 'AM_PartConfigurationSave_Active',
            Value: false
          }));
          this.props.dispatch(actions.UpdateProp({
            Key: 'RefreshDropDownBuckets',
            Value: true
          }));
          this.props.dispatch(actions.UpdateProp({
            Key: 'dbo_SaveId',
            Value: this.props.SaveId + 1
          }));
        });
        setTimeout(() => {
          if (inventSelected.EntityId > 0) {
            this.props.dispatch(actions.UpdateProp({
              Key: 'ent_InventorySelected',
              Value: inventSelected
            }));
          }
        }, 500);
      });
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(AM_PartConfigurationSave);