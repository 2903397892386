import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { AgChartsReact } from 'ag-charts-react';
// import 'ag-grid-enterprise';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let chartName = ownProps.widget.Parameters.find(x => x.ParameterName === 'ChartName');
  let nodeClickKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'NodeClickKey');
  let GChart = ownProps.widget.Parameters.find(x => x.ParameterName === 'GChart');

  let param = {};
  let missingParam = true;
  if (parameter) {
    param = helpers.getListParameter(state, parameter.ParameterValue);
    missingParam = !state[parameter.ParameterValue];
  }

  return {
    Chart: state._chart,
    Parameter: JSON.stringify({ ChartName: (chartName ? chartName.ParameterValue : null), ...param }),
    SWID: ownProps.widget.SceneWidgetId,
    ChartName: chartName ? chartName.ParameterValue : null,
    MissingParam: parameter && missingParam,
    LoadWithoutParam: !parameter,
    NodeClickKey: nodeClickKey ? nodeClickKey.ParameterValue : null,
    SWID: ownProps.widget.SceneWidgetId,
    GChart: GChart ? helpers.stringToBool(GChart.ParameterValue) : false
  };
}

let _lastRequest = {};
let _hasData = {};

export class AgChart extends React.PureComponent {

  state = {
    loading: false
  }

  componentDidMount() {
    if (!_hasData[this.props.SWID] || !this.props.LoadWithoutParam) {
      this.getDataIfNeeded();
      _hasData[this.props.SWID] = true;
    }
  }

  componentDidUpdate(prev) {
    if (!this.props.LoadWithoutParam) {
      this.getDataIfNeeded(prev);
    }
  }

  getDataIfNeeded = (prev) => {
    if (this.props.LoadWithoutParam) {
      this.getData();
    }
    else if (!this.props.MissingParam && _lastRequest[this.props.SWID] !== this.props.Parameter) {
      this.getData();
      _lastRequest[this.props.SWID] = this.props.Parameter;
    }
    if (this.props.MissingParam && this.props.Chart[this.props.SWID]) {
      this.props.dispatch(actions.UpdateProp({
        Key: '_chart',
        Value: {
          ...this.props.Chart,
          [this.props.SWID]: null
        }
      }));
    }
  }

  getData = () => {
    if (!this.props.ChartName)
      return;

    let body = {
      Parameter: this.props.Parameter,
      SWID: this.props.SWID
    }

    this.setState({ loading: true });

    actions.ApiRequest('List/GetList', body, (result) => {
      this.setState({ loading: false });
      console.log({ ChartResult: result });
      if (result.Chart) {
        this.parseResult(result);

        this.props.dispatch(actions.UpdateProp({
          Key: '_chart',
          Value: {
            ...this.props.Chart,
            [this.props.SWID]: result
          }
        }));
      }
    });
  }

  parseResult = (result) => {
    this.formatDates(result);
  }

  formatDates = (result) => {
    let dateKeys = this.getDateKeys(result);
    this.setTooltipRenderer(result, dateKeys);
    result.Data.forEach(x => {
      dateKeys.forEach(key => {
        x[key] = new Date(x[key]);
      });
    });
  }

  getDateKeys = (result) => {
    let keys = [];

    if (result.Chart.axes) {
      keys = result.Chart.axes.reduce((prev, next) => {
        if (next.type === 'time') {
          prev = [prev, ...(next.keys || ['all'])];
        }
        return prev;
      }, []);
    }

    if (keys.includes('all')) {
      keys = result.Chart.series.map(x => x.xKey);
    }

    return keys;
  }

  setTooltipRenderer = (result, keys) => {
    result.Chart.series.forEach(x => {
      if (keys.includes(x.xKey)) {
        x.tooltip = {
          renderer: (params) => {
            return {
              title: helpers.shortDate(params.xValue),
              content: params.yValue
            };
          }
        }
      }
    });
  }

  nodeClick = (e, series) => {
    if (this.props.NodeClickKey) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.NodeClickKey,
        Value: {
          EntityId: e.datum.EntityId,
          EntityTypeId: e.datum.EntityTypeId
        }
      }));
    }
  }

  render() {
    if (this.props.GChart)

      if (!this.props.Chart || this.state.loading)
        return (null);

    let options = null;
    let chart = this.props.Chart[this.props.SWID];

    if (chart) {
      options = chart.Chart;
      options.data = chart.Data;
    }

    if (options && options.series) {
      options.series.forEach(s => {
        s.listeners = {
          nodeClick: (e) => {
            this.nodeClick(e, s);
          }
        }
      });
    }

    console.log('chart options: ')
    console.log(options);
    return (
      !this.props.Chart[this.props.SWID] ? null :
        <div className="ag-chart-widget">
          <AgChartsReact options={options} />
        </div>
    );
  }
}

export default connect(mapStateToProps)(AgChart);