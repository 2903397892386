import React, { PureComponent } from 'react'
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let sourceProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntitySource');

  return {
    SelectedEntity: sourceProp ? state[sourceProp.ParameterValue] : null,
    EventGrid: sourceProp ? state[sourceProp.ParameterValue + 'Events'] : null,
    EventGridKey: sourceProp ? sourceProp.ParameterValue + 'Events' : null,
    Metadata: state.met_EntityMetadata,
    MasterScene: state.blu_MasterScene,
    CurrentEntity: state.ent_Current,
    SaveId: state.dbo_SaveId
  };
}

export class EventGrid extends PureComponent {
  componentDidMount() {
    if (this.props.SelectedEntity && !this.props.SelectedEntity.eventsLoaded) {
      this.resetEventList();
      this.getEventList();
    }
  }
  componentDidUpdate(prevProps) {
    let cur = this.props.SelectedEntity;
    let prev = prevProps ? prevProps.SelectedEntity : null;

    if (cur && (!prev || (cur.EntityId !== prev.EntityId || cur.EntityTypeId !== prev.EntityTypeId))) {
      this.resetEventList();
      this.getEventList();
    } else if (helpers.propDidChange(this.props, prevProps, 'SaveId')) {
      this.resetEventList();
      this.getEventList();
    }
  }

  rowClick = (row) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_SelectedEvent',
      Value: row
    }));
  }

  getEventList = () => {
    if (!this.props.SelectedEntity || this.props.SelectedEntity.IsNew)
      return;

    this.props.SelectedEntity.eventsLoaded = true;

    let body = {
      EntityId: this.props.SelectedEntity.EntityId,
      EntityTypeId: this.props.SelectedEntity.EntityTypeId
    }
    actions.ApiRequest('List/GetEventList', body, (result) => {
      this.props.dispatch(actions.UpdateEventGrid(result, this.props.EventGridKey));
    });
  }

  resetEventList = () => {
    this.props.dispatch(actions.UpdateEventGrid({
      Rows: [],
      Columns: []
    }, this.props.EventGridKey));
  }

  getFlexWidth = (width) => {
    return width.includes('px') ? '0 0 ' + width : width;
  }

  getLookupValue = (lookupSetId, lookupId) => {
    return helpers.getLookupName(lookupSetId, lookupId);
  }

  eventPickerDialog = () => {
    let metadata = this.props.Metadata;
    let assetType = this.props.SelectedEntity.EntityTypeId;
    let relationships = metadata.EntityRelationships.filter(x => x.TypeId === assetType && x.Relationship === 'Event');

    let eventList = relationships.map(rel => {
      let entity = metadata.EntityTypes.find(x => x.TypeId === rel.RelatedId);
      if (!entity)
        return null;

      if (rel.ConditionalProp && rel.ConditionalValue) {
        let condProp = this.props.CurrentEntity.Properties.find(x => x.Name === rel.ConditionalProp);
        if (condProp && condProp.Value != rel.ConditionalValue) {
          return null;
        }
      }

      return {
        Text1: entity.Label,
        EntityTypeId: entity.TypeId,
        ShellOnly: true
      }
    });

    eventList = eventList.filter(x => x !== null);

    eventList = eventList.sort((a, b) => {
      return a.Text1 > b.Text1 ? 1 : b.Text1 > a.Text1 ? -1 : 0;
    });

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_CreatableEvents',
        Value: eventList
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene',
        Value: { Value: 'EventPicker', Enabled: true, MasterScene: this.props.MasterScene.Value }
      }));
    });
  }

  render() {
    let grid = this.props.EventGrid;
    let rows = grid ? grid.Rows : null;
    let cols = grid ? grid.Columns : null;

    return (
      <div className="event-grid-widget">
        {!grid ? null :
          <div className="event-grid">
            <div className="event-grid-title">
              <div>Events</div>
              <div className="add-event-button" onClick={this.eventPickerDialog}>
                <div>Add Event</div>
              </div>
            </div>
            <div className="event-grid-header">
              {(cols.map((col, idx) => (
                <div key={idx} className="event-header-cell" style={{ flex: this.getFlexWidth(col.Width) }}>
                  <div>{col.Label}</div>
                </div>
              )))}
            </div>
            <div className="event-grid-body">
              {rows.map((row, rowIdx) => (
                <div
                  key={rowIdx}
                  className={"event-grid-row " + (rowIdx % 2 === 0 ? '' : 'odd')}
                  onClick={() => { this.rowClick(row) }}>
                  {(cols.map((col, idx) => (
                    <div className="event-grid-cell" key={idx}
                      style={{ flex: this.getFlexWidth(col.Width) }}>
                      <div>{col.LookupSetId ?
                        this.getLookupValue(col.LookupSetId, row[col.ColumnName]) :
                        (col.ControlType === 'DatePicker' && row[col.ColumnName]) ? (new Date(row[col.ColumnName]).toLocaleDateString()) : row[col.ColumnName]
                      }</div>
                    </div>
                  )))}
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(EventGrid);