import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { charsets, PasswordPolicy } from 'password-sheriff';
import Honeycomb from "@beehive-development/honeycomb";

const mapStateToProps = (state, ownProps) => {
  // let type = ownProps.widget.Parameters.find(x => x.ParameterName === 'Type'); //Options: 'MySalesman', finishImageGrouping() checks this for what to do with the selected images

  return {
  };
};

export class MSPasswordChange extends React.PureComponent {
  state = {
    newPass: '',
    confirmPass: ''
  }

  onNewChange = (val) => {
    this.setState({ newPass: val });
  }

  onConfirmChange = (val) => {
    this.setState({ confirmPass: val });
  }

  checkPass = () => {
    var policy = new PasswordPolicy({
      length: { minLength: 8 },
      containsAtLeast: {
        atLeast: 3,
        expressions: [charsets.upperCase, charsets.lowerCase, charsets.numbers, charsets.specialCharacters]
      }
    });

    try {
      policy.assert(this.state.newPass)
      return {
        message: 'Passed',
        valid: true
      }
    } catch {
      return {
        message: `Failed`,
        valid: false
      }
    }
  }

  onPasswordSave = async () => {
    if(this.state.newPass !== '' && this.state.confirmPass !== '') {
      if(this.state.newPass === this.state.confirmPass) {
        const check = this.checkPass()
        if(check.valid) {
          //do save
          const jwtToken = window.isSalesmanPublic ? await window._getToken : await window._getToken();
          const honeycomb = new Honeycomb({
            requestHeaders: {
              Authorization: `Bearer ${jwtToken}`,
              "href-origin": window.location.href,
            }
          });
    
          const auth0NewUserParams = {
            password: this.state.confirmPass
          }
          
          await honeycomb.mySalesman.auth0UpdatePassword(auth0NewUserParams);
          this.props.dispatch(actions.UpdateProp({
            Key: 'blu_ModalScene',
            Value: null
          }))
        } else {
          this.props.dispatch(actions.UpdateProp({
            Key: 'blu_Dialog',
            Value: { Title: 'Bad Password', Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>Please verify the password is at least 8 characters, and contain at least three of the following: lower case, upper case, number, special characters.</p> }
          }));
        }
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_Dialog',
          Value: { Title: 'Password Mismatch', Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>Please verify the passwords match.</p> }
        }));
      }
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Missing fields', Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>Please fill out all fields in the form.</p> }
      }));
    }
  }

  render() {
    return (
      <div>
        <br />
        <div>
          <div>
            <label style={{marginLeft:"12px",width:"75px",paddingTop:"4px",paddingBottom:"4px"}}>Enter New Password:</label>
            <input style={{marginLeft:"52px",marginRight:"12px",width:"150px",paddingTop:"4px",paddingBottom:"4px"}} onChange={(e) => this.onNewChange(e.target.value)} type="text"></input>
          </div>
      
          <br />

          <div>
            <label style={{margin:"auto 12px",width:"75px",paddingTop:"4px",paddingBottom:"4px"}}>Confirm New Password:</label>
            <input style={{marginLeft:"21px",width:"150px",paddingTop:"4px",paddingBottom:"4px"}} onChange={(e) => this.onConfirmChange(e.target.value)} type="text"></input>
          </div>
        </div>

        <br />

        <div style={{marginLeft:"12px",marginRight:"12px"}}>
          <p>Password must have at least 8 characters, not be a previously used password, and contain at least three of the following: lower case, upper case, number, special characters.</p>
        </div>

        <div onClick={() => this.onPasswordSave()} style={{
          textAlign:"center",
          margin:"20px auto auto auto",
          width:"100px",
          height:"30px",
          color:"black",
          padding:"2px",
          backgroundColor:"#faaf3e",
          paddingLeft:"10px",
          paddingRight:"10px",
          borderRadius:"100px",
          cursor:"default",
          display: "flex",
          userSelect: "none"
        }}>
          <div style={{marginLeft:"22px",marginTop:"2px"}}>
            Save
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(MSPasswordChange);