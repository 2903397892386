import React from 'react';
import { connect } from 'react-redux';
import * as helpers from './../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  let entKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let saveQueue = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveQueue');

  return {
    CurrentEntity: entKey ? state[entKey.ParameterValue] : undefined,
    SaveQueue: saveQueue ? saveQueue.ParameterValue : undefined,
    Tenants: state.met_EntityMetadata ? state.met_EntityMetadata.UserTenants : [],
    InsertKey: state.ent_NewAssetKey
  };
};

export class TenantDisplay extends React.PureComponent {
  getTenantName = () => {
    let result = '';
    let ent = this.props.CurrentEntity;
    let tenants = this.props.Tenants;
    if (ent && ent.TenantId && tenants) {
      let entTenant = tenants.find(x => x.Id === ent.TenantId);
      if (entTenant) {
        result = entTenant.Tenant;
      }
    }
    return result;
  }

  setTenant = (item) => {
    if (this.props.CurrentEntity && this.props.CurrentEntity.Properties) {
      let saveDataItem = this.props.CurrentEntity.Properties.find(x => x.IsBaseTable && x.SaveData);
      if (saveDataItem && this.props.InsertKey) {
        this.props.CurrentEntity.TenantId = Number(item.Id);
        this.props.dispatch(actions.AddSaveData({
          ...saveDataItem.SaveData,
          Id: null,
          InsertKey: this.props.InsertKey,
          Column: 'TenantId',
          SaveQueue: this.props.SaveQueue,
          Value: Number(item.Id),
          IsBaseTable: true
        }));
        this.forceUpdate();
      }
    }
  }

  onChange = (e) => {
    let tenantItem = this.props.Tenants.find(x => x.Id == e.target.value);
    if (tenantItem) {
      this.setTenant(tenantItem);
    }
  }

  render() {
    let tenantId = this.props.CurrentEntity ? this.props.CurrentEntity.TenantId : undefined;

    if (!tenantId && this.props.CurrentEntity && this.props.CurrentEntity.IsNew && this.props.Tenants) {
      tenantId = this.props.Tenants[0].Id;
      this.setTenant(this.props.Tenants[0]);
    }

    return (
      !this.props.CurrentEntity ? null :
        this.props.CurrentEntity.IsNew && this.props.Tenants ?
          <div className="tenant-picker">
            <select value={tenantId} onChange={this.onChange}>
              {this.props.Tenants.map((x) => (
                <option key={x.Id} value={x.Id}>{x.Tenant}</option>
              ))}
            </select>
          </div>
          :
          <div className="tenant-display">
            <div>{this.getTenantName()}</div>
          </div>
    );
  }
}

export default connect(mapStateToProps)(TenantDisplay);