import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    SaveData: state.ttr_LeaveSaveData,
    SaveDataKey: 'ttr_LeaveSaveData',
    TimeTrackingRefresh: state.ttr_TimeTrackingRefresh,
    EM: state.met_EntityMetadata,
    SaveId: state.dbo_SaveId
  };
};

export class WageCodes extends React.PureComponent {
  componentDidUpdate = () => {
    this.addPayRateColumns();
  }

  addPayRateColumns = () => {
    if (!this.props.SaveData)
      return;

    let payRateItems = this.props.SaveData.filter(x => x.Table === 'ttr.UserPayRate' && x.InsertKey !== null);
    let prGroups = payRateItems.reduce((prev, next) => {
      let group = prev.find(x => x[0].InsertKey === next.InsertKey);
      if (!group) {
        prev.push([next]);
      } else {
        group.push(next);
      }

      return prev;
    }, []);

    let dispatch = this.props.dispatch;
    prGroups.filter(x => x.length < 3).forEach(group => {
      group.forEach(x => x.IsBaseTable = true);
      let baseObj = { ...group[0] };
      baseObj.Radio = undefined;

      let hasEnabled = group.find(x => x.Column === 'IsEnabled');
      let hasDefault = group.find(x => x.Column === 'IsDefault');

      if (!hasEnabled) {
        helpers.addSaveData(dispatch, baseObj, 'IsEnabled', true);
      }
      if (!hasDefault) {
        helpers.addSaveData(dispatch, baseObj, 'IsDefault', false);
      }
      let keys = baseObj.InsertKey.split('-');
      helpers.addSaveData(dispatch, baseObj, 'UserId', Number(keys[0]));
      helpers.addSaveData(dispatch, baseObj, 'WageCodeId', Number(keys[1]));
      helpers.addSaveData(dispatch, baseObj, 'HourlyRate', 0);
      helpers.addSaveData(dispatch, baseObj, 'BillingRate', 0);
    });
  }

  saveAction = (statusId, isApproved) => {
    if (this.waiting)
      return;

    let baseObj = {
      Id: null,
      InsertKey: null,
      Table: 'ttr.LeaveRequest',
      IsBaseTable: true,
      EntityTypeId: 100
    };

    let saveData = [];
    let items = helpers.saveQueueObject(this.props.SaveData);

    for (let key in items) {
      baseObj.Id = key;

      saveData = [
        ...saveData,
        helpers.saveDataItem(baseObj, 'ManagerId', this.props.EM.CurrentUser),
        helpers.saveDataItem(baseObj, 'LeaveStatusId', statusId),
        helpers.saveDataItem(baseObj, 'ManagerNote', items[key].ManagerNote || ''),
      ];

      if (isApproved) {
        saveData.push(helpers.saveDataItem(baseObj, 'ApprovedDate', helpers.dateToInputFormat(new Date())));
      }
    }

    this.waiting = true;
    actions.ApiRequest('Save/Save', { SaveData: saveData }, () => {
      this.waiting = false;
      this.props.dispatch(actions.ClearSaveData(this.props.SaveDataKey));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_TimeTrackingRefresh',
        Value: (this.props.TimeTrackingRefresh || 0) + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(WageCodes);
