import React, { PureComponent } from 'react'
import ModeSelector from '../ModeSelector/ModeSelector';
import { Clear, Done } from '../../../../../../Icons/Icons';

export default class TextField extends PureComponent {
  render() {
    let parent = this.props.parent;
    let myOptions = this.props.myOptions;
    let mode = this.props.mode;
    let dataType = this.props.dataType;
    return (
      <div>
        {
          <div className={'type-filter text-filter filter-col-' + parent.props.settings.ColumnId}>
            {
              parent.state.ShowFilters &&
              <ModeSelector filterOptions={myOptions} setMode={parent.setMode} Filter={parent.props.Filter} />
            }
            {
              !mode.TwoValues &&
              <div className="flex-box">
                <div className="filter-mode-box" onClick={parent.showFilterToggle}>
                  <div>{mode.Value || ''}</div>
                </div>
                <input ref={parent.primaryInput} type={(dataType === "String" ? "text" : "number")} value={parent.props.Filter.Value || ''} onChange={(e) => { parent.onChange(e, 'Value') }} />
              </div>
            }
            {
              mode.TwoValues &&
              <div className="flex-box two-value">
                <input ref={parent.primaryInput} type={(dataType === "String" ? "text" : "number")} value={parent.props.Filter.Value || ''} onChange={(e) => { parent.onChange(e, 'Value') }} />
                <div className="filter-mode-box" onClick={parent.showFilterToggle}>
                  <div>{mode.Value || ''}</div>
                </div>
                <input type={(dataType === "String" ? "text" : "number")} value={parent.props.Filter.Value2 || ''} onChange={(e) => { parent.onChange(e, 'Value2') }} />
              </div>
            }
            {
              parent.isDirty() &&
              <div className="filter-done" onClick={parent.confirmFilter}>
                <Done />
              </div>
            }
            <div className="filter-clear" onClick={parent.clearFilter}>
              <Clear />
            </div>
          </div>
        }
      </div>
    )
  }
}


