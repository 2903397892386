import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import * as signalR from '@microsoft/signalr'

const mapStateToProps = (state, ownProps) => {
  return {
    SaveKey: state.key_Save
  };
};

var _init = false

export class SignalR extends React.PureComponent {
  waiting = false;

  componentDidMount() {
    if (!_init) {
      this.startConnection();
      _init = true;
    }
  }

  componentDidUpdate() {
  }

  startConnection = () => {
    if (!window._getToken || window.location.toString().toLowerCase().includes('qualify.mysalesman.com') || window.isSalesmanPublic)
      return;

    window._getToken().then(jwt => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl("https://bhsignalr.azurewebsites.net/bhHub?access_token=" + jwt)
        .build();

      async function start() {
        try {
          await connection.start();
          console.log("SignalR Connected.");
        } catch (err) {
          console.log(err);
          setTimeout(start, 5000);
        }
      };

      connection.onclose(async () => {
        await start();
      });

      connection.on('broadcastMessage', (p1, p2) => {
        console.log(p1 + ': ' + p2);
        hub.publish(p1, p2);
      });

      start();
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(SignalR);

