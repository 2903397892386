import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class DeviceInfo extends React.PureComponent {
  state = {
    GeoLocation: '',
    Transform: '',
    PositionInfo: '', //returns all properties given back by the navigator.geolocation call
    WatchId: '' //stores the id of the watchPosition method in order to stop polling for position once component is unmounted
  }

  constructor(props) {
    super(props);
    this.deviceOrientation();
  }

  componentDidMount = () => {
    this.geoLocation();
  }

  componentWillUnmount = () => {
    navigator.geolocation.clearWatch(this.state.WatchId);
  }

  geoLocation = () => {
    if (navigator.geolocation) {
      let counter = 1;
      let watchId = navigator.geolocation.watchPosition((result) => {
        let geoLoc = 'latitude: ' + result.coords.latitude + ', longitude: ' + result.coords.longitude;
        this.setState({ GeoLocation: geoLoc });
        this.setState({ PositionInfo: '\n Position ' + counter + ' Information: \n' + this.getObjectString(result) + '\n' + this.state.PositionInfo });
        counter++;
      }, null, { enableHighAccuracy: true });
      this.setState({ WatchId: watchId });
    }
  }

  deviceOrientation = () => {
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', (e) => {
        let a = Math.floor(e.alpha);
        let b = Math.floor(e.beta);
        let g = Math.floor(e.gamma);
        this.setState({ Transform: 'rotateZ(' + a + 'deg) rotateX(' + b + 'deg) rotateY(' + g + 'deg)' });
      });
    }
  }

  getNavigatorString = () => {
    let result = '';
    for (var key in navigator) {
      var propVal = navigator[key];
      if (typeof propVal !== 'object') {
        result += '\n' + key + ': ' + (typeof propVal === 'function' ? ' [Function]' : propVal.toString());
      }
      if (typeof propVal === 'object' && !Array.isArray(propVal)) {
        for (var key2 in propVal) {
          var propVal2 = navigator[key][key2];
          if (typeof propVal2 !== 'object') {
            result += '\n  ' + key + '.' + key2 + ': ' + (typeof propVal2 === 'function' ? ' [Function]' : propVal2.toString());
          }
          if (typeof propVal2 === 'object' && !Array.isArray(propVal2)) {
            for (var key3 in propVal2) {
              var propVal3 = navigator[key][key2][key3];
              if (typeof propVal3 !== 'object') {
                result += '\n    ' + key + '.' + key2 + '.' + key3 + ': ' + (typeof propVal3 === 'function' ? ' [Function]' : propVal3.toString());
              }
            }
          }
        }
      }
    }
    return result;
  }

  //nate: something like this to display results of location calls?
  getObjectString = (obj) => {
    let result = '';
    for (var key in obj) {
      var propVal = obj[key];
      if (typeof propVal !== 'object') {
        result += '\n' + key + ': ' + (typeof propVal === 'function' ? ' [Function]' : propVal.toString());
      }
      if (typeof propVal === 'object' && !Array.isArray(propVal)) {
        for (var key2 in propVal) {
          var propVal2 = obj[key][key2];
          if (typeof propVal2 !== 'object') {
            result += '\n  ' + key + '.' + key2 + ': ' + (typeof propVal2 === 'function' ? ' [Function]' : propVal2.toString());
          }
          if (typeof propVal2 === 'object' && !Array.isArray(propVal2)) {
            for (var key3 in propVal2) {
              var propVal3 = obj[key][key2][key3];
              if (typeof propVal3 !== 'object') {
                result += '\n    ' + key + '.' + key2 + '.' + key3 + ': ' + (typeof propVal3 === 'function' ? ' [Function]' : propVal3.toString());
              }
            }
          }
        }
      }
    }
    result += '\n';
    return result;
  }

  render() {
    return (
      <div className="device-info">
        {/*<img src="favicon.ico" alt="Smiley face"
          style={{ transform: this.state.Transform }}></img> */}
        <div className="device-details">
          <div>
            <div>
              <h2>Geo Location</h2>
              <div>{this.state.GeoLocation}</div>
            </div>
            {(navigator.battery || navigator.webkitBattery) &&
              <h4>Battery: {(navigator.battery || navigator.webkitBattery).level}</h4>}
            <textarea rows="10" readOnly value={this.state.PositionInfo || '\n     Looking for position...'}></textarea>
            <h3>Navigator Information: </h3>
            <textarea rows="15" readOnly value={this.getNavigatorString()}></textarea>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DeviceInfo);