import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../Utils/Helpers';


const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class Tabs extends React.PureComponent {

  componentDidMount() {
    if (!this.props.Parent.state.Tab) {
      this.selectTab(this.props.Parent.state.Tabs[0]);
    }
  }

  componentDidUpdate() {

  }

  selectTab = (tabName) => {
    this.props.Parent.setState({ Tab: tabName });
    this.forceUpdate();
  }

  render() {
    return (
      <div className="widget-detail-tabs">
        {this.props.Parent.state.Tabs.map((x, idx) => (
          <div key={idx} className={"widget-detail-tab" + (x === this.props.Parent.state.Tab ? ' selected' : '')} onClick={() => { this.selectTab(x) }}>
            <div>{x}</div>
          </div>
        ))}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Tabs);
