import React from 'react';
import { connect } from 'react-redux';
import * as actions from './../../../../Stores/Actions/actions';
import * as helpers from './../../../../Utils/Helpers';
import WidgetLoader from './../../../WidgetLoader';

const mapStateToProps = (state, ownProps) => {
  return {
    FormWidgetParameters: state.blu_FormWidgetParameters.filter(x => x.EMCId === ownProps.Control.Id)
  };
}

export class FormWidget extends React.PureComponent {
  render() {
    let widget = {
      SceneName: '',
      WidgetId: 'emc' + this.props.Control.Id,
      SceneWidgetId: this.props.Control.Id,
      FormWidgetId: this.props.Control.Id,
      WidgetName: this.props.Control.ControlType.split(':')[1],
      WidgetVersion: this.props.Control.ControlType.split(':')[2] || 'v001',
      Parameters: this.props.FormWidgetParameters
    }

    return (
      <div className="form-widget">
        <WidgetLoader widget={widget} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormWidget);