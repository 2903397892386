import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    SaveKey: state.key_Save
  };
};

export class Keybinds extends React.PureComponent {
  waiting = false;

  componentDidMount() {
    this.registerBindings();
  }

  componentDidUpdate() {
    if (this.props.SaveKey) {
      setTimeout(() => {
        this.setKey('key_Save', false);
      }, 0);
    }
  }

  registerBindings = () => {
    let keysPressed = {};

    document.addEventListener('keydown', e => {
      keysPressed[e.key] = true;

      if (e.ctrlKey && e.key === 's') {
        this.setKey('key_Save', true);
        e.stopPropagation();
        e.preventDefault();
      }
    });

    document.addEventListener('keyup', e => {
      keysPressed[e.key] = false;
    });
  }

  setKey = (key, val) => {
    this.props.dispatch(actions.UpdateProp({
      Key: key,
      Value: val
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Keybinds);