import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSource');
  let saveData = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveData');

  return {
    Button: source ? state[source.ParameterValue] : undefined,
    ButtonSource: source ? source.ParameterValue : undefined,
    SaveData: saveData ? state[saveData.ParameterValue] : undefined,
    SaveDataKey: saveData ? saveData.ParameterValue : undefined,
    TimeTrackingRefresh: state.ttr_TimeTrackingRefresh,
    SaveId: state.dbo_SaveId
  };
};

export class UnapproveTimesheet extends React.PureComponent {
  waiting = false;

  constructor(props) {
    super(props);
    this.updateButton(0, false);
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.Button)
      return;

    this.checkEnabled(prevProps);
    this.handleClick(prevProps);
  }

  checkEnabled = () => {
    let enabled = false;

    if (this.props.SaveData && this.props.SaveData.find(x => x.Value === true)) {
      enabled = true;
    }

    if (this.props.Button.Enabled !== enabled) {
      this.updateButton(this.props.Button.ClickId, enabled);
    }
  }

  handleClick = (prevProps) => {
    if (!prevProps.Button)
      return;

    if (prevProps.Button.ClickId !== this.props.Button.ClickId) {
      this.doUnapprove();
    }
  }

  doUnapprove = () => {
    if (this.waiting)
      return;

    let body = {
      Timesheets: this.props.SaveData.filter(x => x.Value === true).map((x) => {
        return {
          UserId: Number(x.Id.split('-')[0]),
          PayPeriodId: Number(x.Id.split('-')[1])
        }
      })
    };

    this.waiting = true;

    actions.ApiRequest('TimeTracking/UnapproveTimesheet', body, () => {
      this.waiting = false;
      this.props.dispatch(actions.ClearSaveData(this.props.SaveDataKey));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_TimeTrackingRefresh',
        Value: (this.props.TimeTrackingRefresh || 0) + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });
  }

  updateButton = (clickId, isEnabled) => {
    if (!this.props.ButtonSource)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        ClickId: clickId,
        Enabled: isEnabled
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UnapproveTimesheet);