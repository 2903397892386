import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { PowerButton } from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let entitySource = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntitySource');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');

  let curEnt = entityKey ? state[entityKey.ParameterValue] : state.ent_Current;

  let inactive = helpers.propValue(curEnt, 'Auth0Inactive');

  return {
    CurrentEntity: curEnt,
    AccountEnabled: !helpers.stringToBool(inactive),
    SelectedEntity: entitySource ? state[entitySource.ParameterValue] : state.ent_Selected,
    EntityKey: entityKey ? entityKey.ParameterValue : 'ent_Current'
  };
}

export class AccountToggle extends React.PureComponent {
  state = {
    busy: false
  }

  setActive = (isActive) => {
    if (this.state.busy)
      return;

    this.setState({ busy: true });

    let body = {
      IsActive: isActive,
      EmailAddress: helpers.propValue(this.props.CurrentEntity, 'EmailAddress'),
      UserId: helpers.propValue(this.props.CurrentEntity, 'UserId')
    }

    if (!body.EmailAddress) {
      let emailProp = this.props.CurrentEntity.Properties.find(x => x.Name === 'EmailAddress');
      emailProp.FailedValidate = true;

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: cloneDeep(this.props.CurrentEntity)
      }));
      return;
    }

    actions.ApiRequest('Save/AccountStatus', body, () => {
      this.setState({ busy: false });
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_RefreshProps',
        Value: {}
      }));
    });
  }

  render() {
    return (
      (this.props.CurrentEntity && this.props.CurrentEntity.IsNew) ? null :
        <div className="account-toggle-widget">
          <div className="account-toggle">
            <div className="login-label">Beehive Login:</div>
            {this.props.AccountEnabled && <div className="login-active">
              <div className="label">Active</div>
              <div className="svg-btn" title="Disable Login" onClick={() => { this.setActive(false) }}>
                <PowerButton />
              </div>
            </div>}
            {!this.props.AccountEnabled && <div className="login-inactive">
              <div className="label">Inactive</div>
              <div className="svg-btn" title="Enable Login" onClick={() => { this.setActive(true) }}>
                <PowerButton />
              </div>
            </div>}
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(AccountToggle);