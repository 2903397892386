import React from 'react';
import { connect } from 'react-redux';
import { Map, View } from 'ol';
import { defaults } from 'ol/control'
import * as helpers from '../../../../Utils/Helpers';
import { fromLonLat, get as getProj } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import Projection from 'ol/proj/Projection';
import { getCenter, buffer } from 'ol/extent';
import proj4 from 'proj4';
import * as actions from '../../../../Stores/Actions/actions';
import LegendControl from './Legend/Legend';
import ZoomMonitor from './ZoomMonitor/ZoomMonitor';
import LayersManager from './Layers/LayersManager';
import EntityZoom from './Navigation/EntityZoom';
import EntitySelect from './Navigation/EntitySelect';
import MoveToLocation from './Navigation/MoveToLocation';
import Drawing from './Drawing/Drawing';
import Footer from './Footer/Footer';
import CenterPoint from './Drawing/CenterPoint';
import ControlPanel from './ControlPanel/ControlPanel';
import { Crosshair } from './../../../Icons/Icons';
import { FullScreen } from 'ol/control';
import EntityGeometry from './Drawing/EntityGeometry';
import { transformExtent } from 'ol/proj';
import { defaults as olInteraction } from 'ol/interaction';
import FreehandSelection from './Drawing/FreehandSelection';
import PointSelection from './Drawing/PointSelection';
import MySalesmanMapController from './ControlPanel/MySalesmanMapController';
import * as Azure from '@azure/storage-blob';
import DriverLocations from './Drawing/DriverLocations/DriverLocations';
import { WKT } from 'ol/format';
import { inAndOut } from 'ol/easing';

const mapStateToProps = (state, ownProps) => {
  let mapId = ownProps.widget.Parameters.find(x => x.ParameterName === 'MapId');
  let zoomProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'ZoomToAsset');
  let legendKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'LegendKey');
  let legendIcon = ownProps.widget.Parameters.find(x => x.ParameterName === 'ShowLegendIcon');
  let entitySource = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntitySource');
  let crosshair = ownProps.widget.Parameters.find(x => x.ParameterName === 'ShowCrosshair');
  let layoutParam = ownProps.widget.Parameters.find(x => x.ParameterName === 'Layout');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let isQueryMap = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsQueryMap');
  let pointSelectionKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'PointSelectionKey');
  let enablePointSelect = ownProps.widget.Parameters.find(x => x.ParameterName === 'EnablePointSelect');
  let showUserLocation = ownProps.widget.Parameters.find(x => x.ParameterName === 'ShowUserLocation');
  let centerPointEnabled = ownProps.widget.Parameters.find(x => x.ParameterName === 'CenterPointDrawingEnabled');
  let disableZoom = ownProps.widget.Parameters.find(x => x.ParameterName === 'DisableEntityZoom');
  let centerOnUser = ownProps.widget.Parameters.find(x => x.ParameterName === 'CenterOnUser');
  let resetSelectedOnModalClose = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetSelectedOnModalClose');
  let cardlistSelect = ownProps.widget.Parameters.find(x => x.ParameterName === 'CardlistSelect');
  let forceMapCenter = ownProps.widget.Parameters.find(x => x.ParameterName === 'ForceMapCenter');
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let disableEntitySelect = ownProps.widget.Parameters.find(x => x.ParameterName === 'DisableEntitySelect');
  let refreshOnSave = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshOnSave');
  let groupingMap = ownProps.widget.Parameters.find(x => x.ParameterName === 'GroupingMap');
  let refreshOnParameterChange = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshOnParameterChange');
  let queryMapUseStyleId = ownProps.widget.Parameters.find(x => x.ParameterName === 'QueryMapUseStyleId');

  let synchronizeAndViewDriverLocations = ownProps.widget.Parameters.find(x => x.ParameterName === 'SynchronizeAndViewDriverLocations');
  let viewDriverLocations = ownProps.widget.Parameters.find(x => x.ParameterName === 'ViewDriverLocations');

  let createAssetButton = ownProps.widget.Parameters.find(x => x.ParameterName === "CreateAssetButton");
  const mobileCreateAssetButton = ownProps.widget.Parameters.find(x => x.ParameterName === "MobileCreateAssetButton");
  const mobileEditButton = ownProps.widget.Parameters.find(x => x.ParameterName === "MobileEditButton");

  let swid = ownProps.widget.SceneWidgetId;
  let layout = layoutParam ? helpers.parseJson(layoutParam.ParameterValue) : {};
  let isEditMode = false;
  if (layout.EditMode) {
    let editModeScene = state[layout.EditMode.SceneKey];
    isEditMode = editModeScene && editModeScene.Value === layout.EditMode.SceneValue;
  }

  if (MapInstance && MapInstance[swid]) {
    MapInstance[swid].editMode = isEditMode;
  }


  if (layout.AssetMapIsParent) {
    let assetMap = document.querySelector('.widget-selector .map');
    if (assetMap) {
      layout.ParentSWID = Number(assetMap.getAttribute('swid'));
    }
  }

  let parent = layout.ParentSWID;
  if (MapInstance && MapInstance[parent]) {
    MapInstance[parent].editMode = isEditMode;
  }

  return {
    MapId: mapId ? Number(mapId.ParameterValue) : null,
    ZoomToAsset: zoomProp ? helpers.stringToBool(zoomProp.ParameterValue) : true,
    SWID: ownProps.widget.SceneWidgetId,
    LegendKey: legendKey ? legendKey.ParameterValue : 'map_LegendItems',
    ShowLegendIcon: legendIcon ? helpers.stringToBool(legendIcon.ParameterValue) : true,
    Entity: entitySource ? state[entitySource.ParameterValue] : state.ent_Selected,
    EntityKey: entitySource ? entitySource.ParameterValue : 'ent_Selected',
    ShowCrosshair: crosshair ? helpers.stringToBool(crosshair.ParameterValue) : false,
    CurrentEntity: entityKey ? state[entityKey.ParameterValue] : null,
    Layout: layout,
    EditMode: isEditMode,
    Metadata: state.met_EntityMetadata,
    MapStyles: state.map_Styles,
    IsQueryMap: isQueryMap ? helpers.stringToBool(isQueryMap.ParameterValue) : false,
    PointSelectionKey: pointSelectionKey ? pointSelectionKey.ParameterValue : null,
    EnablePointSelect: enablePointSelect ? helpers.stringToBool(enablePointSelect.ParameterValue) : false,
    ShowUserLocation: showUserLocation ? helpers.stringToBool(showUserLocation.ParameterValue) : false,
    CenterPointEnabled: centerPointEnabled ? helpers.stringToBool(centerPointEnabled.ParameterValue) : false,
    DisableZoom: disableZoom ? helpers.stringToBool(disableZoom.ParameterValue) : false,
    CenterOnUser: centerOnUser ? helpers.stringToBool(centerOnUser.ParameterValue) : false,
    ResetSelectedOnModalClose: resetSelectedOnModalClose ? helpers.stringToBool(resetSelectedOnModalClose.ParameterValue) : false,
    CardlistSelect: cardlistSelect ? helpers.stringToBool(cardlistSelect.ParameterValue) : false,
    ForceMapCenter: forceMapCenter ? helpers.stringToBool(forceMapCenter.ParameterValue) : false,
    Parameter: parameter ? JSON.stringify(helpers.getListParameter(state, parameter.ParameterValue)) : null,
    DisableEntitySelect: disableEntitySelect ? helpers.stringToBool(disableEntitySelect.ParameterValue) : false,
    RefreshOnSave: refreshOnSave ? helpers.stringToBool(refreshOnSave.ParameterValue) : false,
    GroupingMap: groupingMap ? helpers.stringToBool(groupingMap.ParameterValue) : false,
    ViewDriverLocations: viewDriverLocations ? helpers.stringToBool(viewDriverLocations.ParameterValue) : false,
    SynchronizeAndViewDriverLocations: synchronizeAndViewDriverLocations ? helpers.stringToBool(synchronizeAndViewDriverLocations.ParameterValue) : false,
    SaveId: state.dbo_SaveId,
    BlobSAS: state.BlobSAS,
    RefreshOnParameterChange: refreshOnParameterChange ? helpers.stringToBool(refreshOnParameterChange.ParameterValue) : false,
    QueryMapUseStyleId: queryMapUseStyleId ? helpers.stringToBool(queryMapUseStyleId.ParameterValue) : false,
    CreateAssetButton: createAssetButton ? helpers.stringToBool(createAssetButton.ParameterValue) : false,
    CreateAssetMapDetails: state.CreateAssetMapDetails ? state.CreateAssetMapDetails : null,
    MobileCreateAssetButton: mobileCreateAssetButton ? helpers.stringToBool(mobileCreateAssetButton.ParameterValue) : false,
    MobileCreateAssetMapDetails: state.MobileCreateAssetMapDetails ? state.MobileCreateAssetMapDetails : null,
    MobileEditButton: mobileEditButton ? helpers.stringToBool(mobileEditButton.ParameterValue) : false,
    ShowMobileControls: state.ShowMobileControls ? state.ShowMobileControls : false,
  };
}

let MapInstance = {};

export class MapWidget extends React.PureComponent {
  initialized = false;
  state = {
    map: null
  }

  componentDidMount() {
    if (this.props.MapStyles && this.metadataLoaded(this.props)) {
      this.mapSetup();
    }
    let curEntity = this.props.Entity;
    if (this.props.GroupingMap && curEntity) {
      setTimeout(() => {
        this.getGroupImage(curEntity);
        this.saveUpdate();
      }, 0);
    }
  }

  componentDidUpdate(prev) {
    if (!this.initialized && this.props.MapStyles && this.metadataLoaded(this.props)) {
      this.mapSetup();
    }
    if (this.props.Parameter !== prev.Parameter) {
      MapInstance[this.props.SWID].Parameter = this.props.Parameter;
      this.setState({ map: MapInstance[this.props.SWID] });
      if (this.props.RefreshOnParameterChange) {
        helpers.refreshMapLayers(this.props.SWID);
      }
    }
    if (prev && prev.SaveId !== this.props.SaveId) {
      this.saveUpdate();
    }
    if (prev && this.props.GroupingMap && this.props.Entity && helpers.entId(this.props.Entity) !== helpers.entId(prev.Entity)) {
      this.getGroupImage(this.props.Entity);
      this.saveUpdate();
    }
    if(this.props.CreateAssetMapDetails && this.state.map) {
      this.state.map.getView().setCenter(this.props.CreateAssetMapDetails.center);
      this.state.map.getView().setZoom(this.props.CreateAssetMapDetails.zoom);

      this.props.dispatch(actions.UpdateProp({
        Key: "CreateAssetMapDetails",
        Value: null
      }));
    }
    if(this.props.MobileCreateAssetMapDetails && this.state.map && this.props.MobileEditButton) {
      this.state.map.getView().setCenter(this.props.MobileCreateAssetMapDetails.center);
      this.state.map.getView().setZoom(this.props.MobileCreateAssetMapDetails.zoom);

      this.props.dispatch(actions.UpdateProp({
        Key: "MobileCreateAssetMapDetails",
        Value: null
      }));
    }
  }

  saveUpdate = () => {
    if (this.props.GroupingMap && MapInstance[this.props.SWID] && MapInstance[this.props.SWID].refreshGrouping) {
      MapInstance[this.props.SWID].refreshGrouping();
    }
  }

  metadataLoaded = (props) => {
    return props && props.Metadata && props.Metadata.UserTenants;
  }

  mapSetup = () => {
    this.initialized = true;
    window.MapInstance = MapInstance;
    if (!MapInstance[this.props.SWID]) {
      this.getMaps();
    } else {
      MapInstance[this.props.SWID].setTarget(document.querySelector('#swid-' + this.props.SWID + ' #map'));

      if (this.props.Parameter !== MapInstance[this.props.SWID].Parameter) {
        MapInstance[this.props.SWID].Parameter = this.props.Parameter;
        helpers.refreshMapLayers(this.props.SWID);
      }

      this.setState({ map: MapInstance[this.props.SWID] });
      if (this.props.Layout.ParentSWID) {
        this.copyParentPosition();
      }
    }
  }

  copyParentPosition = () => {
    let map = MapInstance[this.props.SWID];
    let parent = MapInstance[this.props.Layout.ParentSWID];
    let mapView = map.getView();
    let parentView = parent.getView();
    mapView.setCenter(parentView.getCenter());
    mapView.setZoom(parentView.getZoom());
  }

  getMaps = () => {
    actions.ApiRequest('Map/GetMaps', {}, (result) => {
      console.log({ mapResult: result });
      let myMap = result.find(x => x.map.MapId === this.props.MapId);
      this.createMap(myMap);
      if (this.props.Layout.ParentSWID) {
        this.copyParentPosition();
      }
    });
  }

  createMap = (mapSettings) => {
    if (!mapSettings) {
      console.log('mapsettings missing');
      console.log(this.props.widget);
      return;
    }

    console.log('!!create map!!');
    this.registerProjection(mapSettings);
    let projection = getProj(mapSettings.ProjName);
    let controls = this.getControls();
    let extents = mapSettings.map.Extent.replace(/ /g, '').split(',').map(x => Number(x));
    let extent = transformExtent(extents, 'EPSG:4326', 'EPSG:3857');

    let center = this.mapInitialCenter(mapSettings.map.Center);

    let mapView = null;

    let statProj = null;
    if (this.props.GroupingMap) {
      const statExt = [0, 0, 1000, 1000];
      statProj = new Projection({
        units: 'pixels',
        extent: statExt,
      });

      mapView = new View({
        projection: statProj,
        center: getCenter(statExt),
        resolution: 1,
      })
    } else {
      mapView = new View({
        center: center,
        zoom: mapSettings.map.Scale,
        maxZoom: mapSettings.map.MaxZoom,
        minZoom: mapSettings.map.MinZoom,
        extent: extent
      })
    }

    let interactions = olInteraction({
      altShiftDragRotate: false,
      pinchRotate: false
    });

    if (this.props.GroupingMap) {
      interactions = olInteraction({
        doubleClickZoom: false,
        dragAndDrop: false,
        dragPan: false,
        keyboardPan: false,
        keyboardZoom: false,
        mouseWheelZoom: false,
        pointer: false,
        select: false
      });
    }

    MapInstance[this.props.SWID] = new Map({
      //target: 'swid-' + this.props.SWID,
      layers: [],
      controls: controls,
      interactions: interactions,
      view: mapView
    });

    let myMap = MapInstance[this.props.SWID];
    myMap.projName = mapSettings.ProjName;
    myMap.projection = projection;
    myMap.settings = mapSettings;
    myMap.MapId = this.props.MapId;
    myMap.ZoomToAsset = this.props.ZoomToAsset;
    myMap.Layout = this.props.Layout;
    myMap.swid = this.props.SWID;
    myMap.Parameter = this.props.Parameter;
    myMap.GroupingMap = this.props.GroupingMap;
    myMap.statProj = statProj;
    myMap.queryMapUseStyleId = this.props.QueryMapUseStyleId;
    myMap.setTarget(document.querySelector('#swid-' + this.props.SWID + ' #map'));

    this.setMapListeners();

    if (this.props.ShowLegendIcon) {
      MapInstance[this.props.SWID].addControl(new LegendControl(null, { MapId: this.props.MapId }));
    }

    if (!this.props.Layout.HideZoomMonitor) {
      MapInstance[this.props.SWID].addControl(new ZoomMonitor(null, { map: MapInstance[this.props.SWID] }));
    }

    this.props.dispatch(actions.SetEntityMap(MapInstance[this.props.SWID]));
    this.setState({ map: MapInstance[this.props.SWID] }, () => {
      this.resetZoom();
    });
  }


  mapInitialCenter = (mapCenter) => {
    let result = null;

    if (!this.props.ForceMapCenter && this.props.Metadata && this.props.Metadata.UserTenants) {
      let tenant = this.props.Metadata.UserTenants.find(x => x.IsPrimary);
      if (tenant && tenant.MapCenter) {
        result = fromLonLat(tenant.MapCenter.replace(/ /g, '').split(',').map(x => Number(x)));
      }
    }
    if (!result) {
      result = fromLonLat(mapCenter.replace(/ /g, '').split(',').map(x => Number(x)));
    }

    return result;
  }

  getControls = () => {
    let controls = defaults({
      attribution: false,
      zoom: this.props.Layout.HideZoom ? false : true
    });

    if (this.props.Layout.FullScreen) {
      controls.extend([new FullScreen()]);
    }

    return controls;
  }

  registerProjection = (mapSettings) => {
    proj4.defs(mapSettings.ProjName, mapSettings.ProjDefinition);
    register(proj4);
  }

  setMapListeners = () => {
    MapInstance[this.props.SWID].on('click', this.blurElement);
    MapInstance[this.props.SWID].on('movestart', this.blurElement);
  }

  blurElement = () => {
    document.activeElement.blur();
  }

  showAdvanced = () => {
    let action = this.state.map.Layout.FloatingAction;
    this.props.dispatch(actions.UpdateProp({
      Key: action.SceneKey,
      Value: { Value: action.SceneValue, Enabled: true }
    }));
  }

  resetZoom = () => {
    if (!this.state.map)
      return;

    let view = this.state.map.getView();

    if (this.props.CenterOnUser) {
      navigator.geolocation.getCurrentPosition((result) => {
        //view.setCenter(fromLonLat([-106.84069446593354, 39.199024299500167]));
        // view.setCenter(fromLonLat([result.coords.longitude, result.coords.latitude]));
      });
    } else {
      if (this.props.ForceMapCenter) {
        view.animate({
          center: this.mapInitialCenter(this.state.map.settings.map.Center),
          zoom: 14.5,
          duration: 200
        });
      } else if (this.props.CurrentEntity && this.props.CurrentEntity.Geometry) {
        if (typeof this.props.CurrentEntity.Geometry === 'string' || this.props.CurrentEntity.Geometry instanceof String) {
          const format = new WKT();
          const newFeat = format.readFeature(this.props.CurrentEntity.Geometry, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          const geometry = newFeat.getGeometry();
        
          const extent = geometry.getExtent();
          this.state.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
        }
      } else {
        let tenant = this.getTenant();

        if (!tenant || !tenant.Bounds)
          return;

        let bounds = JSON.parse(tenant.Bounds);

        if (!Array.isArray(bounds) || bounds.length !== 4)
          return;

        var coordMin = fromLonLat([bounds[0], bounds[1]], 'EPSG:3857');
        var coordMax = fromLonLat([bounds[2], bounds[3]], 'EPSG:3857');

        view.fit([coordMin[0], coordMin[1], coordMax[0], coordMax[1]], this.state.map.getSize());
      }
    }
  }

  getTenant = () => {
    let ent = this.props.CurrentEntity;
    let tenantId = ent && ent.TenantId ? ent.TenantId : null;
    if (this.props.Metadata && this.props.Metadata.UserTenants) {
      if (tenantId) {
        return this.props.Metadata.UserTenants.find(x => x.Id === tenantId) || null;
      } else {
        return this.props.Metadata.UserTenants.find(x => x.IsPrimary) || null;
      }
    }
    return null;
  }

  getGroupImage = (curEntity) => {
    let body = {
      EntityId: curEntity.EntityId,
      EntityTypeId: curEntity.EntityTypeId
    };

    actions.ApiRequest('Document/GetDocuments', body, async (result) => {
      if (body.EntityId !== this.props.Entity.EntityId) {
        return;
      }
      let groupDoc = result.find(x => x.IsGroup);
      if (groupDoc) {
        let isImage = groupDoc.FileType.includes('image');
        if (isImage) {
          let filename = groupDoc.DocumentId + '_l.' + groupDoc.Extension;
          let containerClient = this.getBlobContainerClient();
          let blobClient = containerClient.getBlobClient(filename);
          const downloadBlockBlobResponse = await blobClient.download();
          const blobSrc = await downloadBlockBlobResponse.blobBody;
          this.state.map.groupImg = window.URL.createObjectURL(blobSrc);
          if (this.state.map.groupImageLayerRefresh) {
            this.state.map.groupImageLayerRefresh();
          }
        }
      } else {
        if (this.state.map) {
          this.state.map.groupImg = null;
          if (this.state.map.groupImageLayerRefresh) {
            this.state.map.groupImageLayerRefresh();
          }
        }
      }
    });
  }

  createAssetClick = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_Current',
      Value: null
    }))

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_Selected',
      Value: null
    }))

    const mapData = { 
      zoom: this.state.map.getView().getZoom(), 
      center: this.state.map.getView().getCenter()
    };

    this.props.dispatch(actions.UpdateProp({
      Key: 'CreateAssetMapDetails',
      Value: mapData
    }));
    this.props.dispatch(actions.UpdateProp({
      Key:"AM_AssetCanvasScene",
      Value: {
        Enabled: true,
        Label: "",
        Value: ""
      }
    }))
    this.props.dispatch(actions.UpdateProp({
      Key: 'AM_CanvasScene',
      Value: {Id: 201, Value: "AM_AssetTab"}
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'AM_AssetBannerScene',
      Value: {Id: 203, Value: "AM_AssetCreate"}
    }));
  }

  createMobileAssetClick = () => {
    const mapData = { 
      zoom: this.state.map.getView().getZoom(), 
      center: this.state.map.getView().getCenter()
    };

    this.props.dispatch(actions.UpdateProp({
      Key: 'MobileCreateAssetMapDetails',
      Value: mapData
    }));

    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene',
      Value: {
        "Enabled": true, 
        "Value": "MobileAssetPicker" 
      }
    }));
  }

  mobileEditClick = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ShowMobileControls',
      Value: !this.props.ShowMobileControls
    }));
  }

  getBlobContainerClient = () => {
    let blobServiceClient = new Azure.BlobServiceClient(this.props.BlobSAS.Key);
    let containerName = this.props.BlobSAS.Database.replace(/_/g, '').toLowerCase();
    return blobServiceClient.getContainerClient(containerName);
  }

  render() {
    let footer = this.state.map ? this.state.map.Layout.FooterBar : null;
    let floatingBtn = this.state.map ? this.state.map.Layout.FloatingAction : null;
    let controlPanel = this.state.map ? this.state.map.Layout.ControlPanel : null;
    let mysalesPanel = this.state.map ? this.state.map.Layout.MySalesPanel : null;
    return (
      <div className="map" id="map" swid={this.props.SWID}>
        {this.props.MobileCreateAssetButton && <button onClick={this.createMobileAssetClick} style={{position:"absolute",bottom:"6px",right:"10px",zIndex:"200",backgroundColor:"#fdba30",padding:"4px",border:"2px black",borderRadius:"4px",fontWeight:"bold"}}>Create Asset</button>}
        {this.props.MobileEditButton && <button onClick={this.mobileEditClick} style={{position:"absolute",bottom:"6px",right:"10px",zIndex:"200",backgroundColor:"#fdba30",padding:"4px",border:"2px black",borderRadius:"4px",fontWeight:"bold"}}>Edit Controls</button>}
        {this.props.CreateAssetButton && <button onClick={this.createAssetClick} style={{position:"absolute",top:"6px",right:"10px",zIndex:"200",backgroundColor:"#fdba30",padding:"4px",border:"2px black",borderRadius:"4px",fontWeight:"bold"}}>Create Asset</button>}
        <LayersManager map={this.state.map} legendKey={this.props.LegendKey} currentEntity={this.props.CurrentEntity} IsQueryMap={this.props.IsQueryMap} />
        {!this.props.DisableZoom && <EntityZoom map={this.state.map} resetZoom={this.resetZoom} Entity={this.props.Entity} CurrentEntity={this.props.CurrentEntity} EntityKey={this.props.EntityKey} RefreshOnSave={this.props.RefreshOnSave} />}
        {this.props.DisableEntitySelect ? <></> : <EntitySelect ResetSelectedOnModalClose={this.props.ResetSelectedOnModalClose} map={this.state.map} CardlistSelect={this.props.CardlistSelect} EntityKey={this.props.EntityKey} editMode={this.props.EditMode} />}
        <Drawing map={this.state.map} swid={this.props.widget.SceneWidgetId} />
        <EntityGeometry map={this.state.map} editMode={this.props.EditMode} currentEntity={this.props.CurrentEntity} parentSwid={this.props.Layout.ParentSWID} showUserLocation={this.props.ShowUserLocation} mobileEditMode={this.props.MobileEditButton} />
        <MoveToLocation map={this.state.map} swid={this.props.widget.SceneWidgetId} />
        {this.props.CenterPointEnabled && <CenterPoint map={this.state.map} CurrentEntity={this.props.CurrentEntity} />}
        {this.props.EnablePointSelect && <PointSelection map={this.state.map} PointSelectionKey={this.props.PointSelectionKey} />}
        {this.props.IsQueryMap && this.state.map && <FreehandSelection map={this.state.map} swid={this.props.widget.SceneWidgetId} />}
        {this.props.SynchronizeAndViewDriverLocations && <DriverLocations map={this.state.map} synchronizeMode="Upsert" />}
        {this.props.ViewDriverLocations && <DriverLocations map={this.state.map} synchronizeMode="Get" />}
        {this.props.ShowCrosshair && <div className="map-crosshair"><Crosshair /></div>}
        {floatingBtn &&
          <div className="map-action-btn">
            <div className="wrapper">
              <div className="advanced-btn" onClick={this.showAdvanced}>
                <div>...</div>
              </div>
            </div>
          </div>}
        {footer &&
          <div className="map-footer-bar-container">
            <div className="wrapper">
              <Footer map={this.state.map} />
            </div>
          </div>}
        {controlPanel &&
          <div className="control-panel-wrapper">
            <ControlPanel map={this.state.map} resetZoom={this.resetZoom} />
          </div>}
        {mysalesPanel && <MySalesmanMapController map={this.state.map} />}
      </div>
    );
  }
}

export default connect(mapStateToProps)(MapWidget);