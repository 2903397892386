import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';

const mapStateToProps = state => ({
});

export class SearchBar extends React.PureComponent {
  render() {
    return (
      <div className="search-bar">
        search bar v002
      </div>
    );
  }
}

export default connect(mapStateToProps)(SearchBar);