import React from 'react';
import { connect } from 'react-redux';
import GridHeader from './Header/GridHeader';
import GridBody from './Body/GridBody';
import ColumnPicker from './Header/ColumnPicker';
import RowNumbers from './Body/RowNumbers';
import TitleBar from './Title/TitleBar';
import Scrollbar from './Scrollbar/Scrollbar';
import GridDataEngine from './GridDataEngine/GridDataEngine';
import * as actions from './../../../../Stores/Actions/actions';
import * as helpers from './../../../../Utils/Helpers';
import GroupByHeader from './GroupBy/GroupByHeader';

const mapStateToProps = (state, ownProps) => {
  let selectedQuery = state.qry_SelectedQuery;
  let parentQuery = selectedQuery ? state.qry_QueryList.find(x => x.QueryId === selectedQuery.ParentId) : undefined;

  return {
    Query: selectedQuery,
    ParentQuery: parentQuery,
    Queries: state.qry_Queries,
    QueryList: state.qry_QueryList,
    RowCount: selectedQuery ? state.qry_Queries[selectedQuery.QueryId].rowCount : undefined,
    Cols: selectedQuery ? state.qry_Queries[selectedQuery.QueryId].allIds : undefined,
    GroupByHover: state.qry_GroupByHover,
    GroupedColumns: state.qry_GroupedColumns
  };
};

export class QueryGrid extends React.PureComponent {
  state = {
    width: 0
  }

  componentDidMount() {
    this.setHeaderWidths();
  }

  componentDidUpdate(prevProps) {
    this.setHeaderWidths();

    if (this.props.Query && this.props.Query.QueryId !== (prevProps.Query ? prevProps.Query.QueryId : null)) {
      console.log('reset in querygrid.js');
      this.props.dispatch(actions.SetTopRow({
        queryId: this.props.Query.QueryId,
        topRow: 0
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_GroupByHover',
        Value: false
      }));
      document.getElementsByClassName('scroll-content')[0].scrollLeft = 0;
    }

    if (!this.props.Queries && Array.isArray(this.props.QueryList) && this.props.QueryList.length > 0) {
      let normalized = {};
      let children = [];
      this.props.QueryList.forEach(parentQuery => {
        children = children.concat(parentQuery.Children);
        while (children.length > 0) {
          let query = children.pop();
          if (query.Children) {
            children = children.concat(query.Children);
          }
          let columnObj = {};
          if (query.Layout && query.Layout.Columns) {
            query.Layout.Columns.forEach(col => {
              columnObj[col.ColumnId] = col;
              columnObj[col.ColumnId].PendingFilter = col.Filter;
            });

            columnObj.allIds = query.Layout.Columns.map(x => x.ColumnId);
            columnObj.widths = query.Layout.Columns.map(x => x.Width);
            columnObj.sortBy = query.Layout.SortBy;
          }
          normalized[query.QueryId] = columnObj;
        }
      });

      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_Queries',
        Value: normalized
      }));
    }
  }

  setHeaderWidths = () => {
    helpers.alignGridHeaders();
  }

  render() {
    let queryId = this.props.Query ? this.props.Query.QueryId : 0;
    let unpinCols;
    let pinCols;
    let columns;
    if (queryId) {
      let query = this.props.Queries[this.props.Query.QueryId];
      unpinCols = query.allIds.filter(x => query[x].IsVisible && !query[x].IsPinned);
      pinCols = query.allIds.filter(x => query[x].IsVisible && query[x].IsPinned);
      columns = query.allIds.map(x => query[x]);
    }
    let isGrouped = !!(this.props.GroupByHover || (this.props.GroupedColumns && this.props.GroupedColumns.length > 0));

    return (
      <div>
        {
          this.props.Query && (console.log(this.props.Query) || 1) && (console.log('!!big grid render!!') || 1) &&
          <div className="query-grid-container">
            <GridDataEngine queryId={queryId} />
            <div className={'query-grid' + (isGrouped ? ' grouped' : '')}>
              <GroupByHeader QueryId={queryId} Columns={columns} />
              <div className="grid-content">
                <div className="static-content">
                  <div className="static-grid">
                    <div className="pinned-grid">
                      {
                        <div className="grid-sidebar">
                          <ColumnPicker queryId={queryId} />
                          <RowNumbers queryId={queryId} />
                        </div>
                      }
                      <div className="pinned-grid-body">
                        <GridHeader pinned={true} />
                        <GridBody pinned={true} queryId={queryId} cols={pinCols} params={this.props.widget.Parameters} />
                      </div>
                    </div>
                  </div>
                  <div className="static-bumper"></div>
                </div>
                <div className={"scroll-content"}>
                  <GridHeader pinned={false} />
                  <GridBody pinned={false} queryId={queryId} cols={unpinCols} params={this.props.widget.Parameters} />
                </div>
                <Scrollbar queryId={queryId} />
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(QueryGrid);