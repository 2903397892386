import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSource');
  let saveData = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveData');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let saveKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveButtonKey');
  let submitLid = ownProps.widget.Parameters.find(x => x.ParameterName === 'SubmitLid');
  let buttonSwid = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSwid');

  return {
    Button: source ? state[source.ParameterValue] : null,
    ButtonSource: source ? source.ParameterValue : null,
    SaveData: saveData ? state[saveData.ParameterValue] : null,
    CurrentEntity: entityKey ? state[entityKey.ParameterValue] : null,
    SaveButton: saveKey ? state[saveKey.ParameterValue] : null,
    SaveButtonKey: saveKey ? saveKey.ParameterValue : null,
    SubmitLid: submitLid ? submitLid.ParameterValue : null,
    ButtonSwid: buttonSwid ? buttonSwid.ParameterValue : null
  };
};

export class Save extends React.PureComponent {
  waiting = false;

  constructor(props) {
    super(props);
    this.updateButton(0, false);
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.Button)
      return;

    this.checkEnabled(prevProps);
    this.handleClick(prevProps);
    this.hideIfAlreadySubmitted();

    if (prevProps && this.props.SaveId !== prevProps.SaveId && this.props.CurrentEntity) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_RefreshProps',
        Value: {}
      }));
    }
  }

  hideIfAlreadySubmitted = () => {
    if (!this.props.ButtonSwid)
      return;

    let statusProp = this.getProp('StatusLid');
    if (!statusProp)
      return;

    let swids = this.props.ButtonSwid.split(',').map(x => Number(x.trim()));
    let submitIds = this.props.SubmitLid.split(',').map(x => Number(x.trim()));

    for (var i = 0; i < swids.length; i++) {
      let btnSwid = swids[i];
      let submitBtn = document.getElementById('swid-' + btnSwid);

      if (statusProp && submitBtn && !this.props.CurrentEntity.IsShell) {
        if (submitIds.includes(Number(statusProp.Value))) {
          submitBtn.style.visibility = 'hidden';
        } else {
          submitBtn.style.visibility = 'visible';
        }
      }
    }
  }

  checkEnabled = () => {
    if (!this.props.SaveData)
      return;

    if (this.props.Button.Enabled !== true) {
      this.updateButton(this.props.Button.ClickId, true);
    }
  }

  handleClick = (prevProps) => {
    if (this.waiting)
      return;

    if (prevProps.Button.ClickId !== this.props.Button.ClickId) {
      this.doSubmit();
    }
  }

  getProp = (name) => {
    if (!this.props.SaveButton || !this.props.CurrentEntity || !this.props.CurrentEntity.Properties || !this.props.SubmitLid)
      return null;

    return this.props.CurrentEntity.Properties.find(x => x.Name === name);
  }

  doSubmit = () => {
    let statusProp = this.getProp('StatusLid');

    if (!statusProp)
      return;

    let lookupItems = helpers.getLookupItems(statusProp.LookupSetId);
    if (!Array.isArray(lookupItems))
      return;

    let submitLid = this.props.SubmitLid.split(',').map(x => Number(x.trim()));
    if (!submitLid || !submitLid[0])
      return;

    let lookupItem = lookupItems.find(x => x.LookupItemId == submitLid[0]);

    if (!lookupItem)
      return;

    batch(() => {
      this.props.dispatch(actions.AddSaveData({
        Id: statusProp.SaveData.Id,
        Table: statusProp.SaveData.Table,
        Column: statusProp.SaveData.Column,
        Value: lookupItem.LookupItemId,
        IsEventData: false,
        SaveQueue: statusProp.SaveData.SaveQueue,
        InsertKey: statusProp.SaveData.InsertKey,
        IsBaseTable: statusProp.IsBaseTable
      }));

      let subDate = this.getProp('SubmitDate');
      if (subDate) {
        this.props.dispatch(actions.AddSaveData({
          Id: subDate.SaveData.Id,
          Table: subDate.SaveData.Table,
          Column: subDate.SaveData.Column,
          Value: helpers.formatDate(),
          IsEventData: false,
          SaveQueue: subDate.SaveData.SaveQueue,
          InsertKey: subDate.SaveData.InsertKey,
          IsBaseTable: subDate.IsBaseTable
        }));
      }

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.SaveButtonKey,
        Value: { ...this.props.SaveButton, ClickId: (this.props.SaveButton.ClickId || 0) + 1 }
      }));
    })
  }

  updateButton = (clickId, isEnabled) => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        ClickId: clickId,
        Enabled: isEnabled
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Save);