import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';


export default class BucketFilter extends React.PureComponent {

  getBuckets = () => {
    console.log('getbuckets');
    let buckets = helpers.getQueryBuckets();
    if (!buckets)
      return [];

    let bucket = buckets[this.props.colDef.bucketName];
    let allItems = bucket.FilterData || bucket.Data;

    let items = Object.keys(allItems).map((key) => {
      return {
        key: key,
        active: true,
        Name1: allItems[key],
        isBlank: (allItems[key] === ' ' || !allItems[key])
      }
    });

    let distFilterItems = helpers.getDistinctFilter(this.props.column.userProvidedColDef.queryId, this.props.colDef.field);

    if (distFilterItems && distFilterItems.length > 0) {
      let distMap = distFilterItems.reduce((prev, next) => {
        prev[next] = 1;
        return prev;
      }, {});

      let addedBlank = false;
      items = items.filter((x) => {
        if (!addedBlank && distMap[""] && x.isBlank) {
          addedBlank = true;
          return true;
        }
        return distMap[x.key];
      });

    }

    console.log({ afterFilter: items });

    items.sort((a, b) => {
      let aVal = a.Name1 ? a.Name1.toLowerCase() : '';
      let bVal = b.Name1 ? b.Name1.toLowerCase() : '';
      return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
    });

    return items;
  }

  getBucketMetadata = () => {
    let buckets = helpers.getQueryBuckets();
    if (!buckets)
      return [];

    return buckets[this.props.colDef.bucketName];
  }

  state = {
    lookupItems: this.getBuckets(),
    allLookupItems: this.getBuckets(),
    allToggle: true,
    filterType: 'Bucket',
    bucketMetadata: this.getBucketMetadata()
  }

  isFilterActive() {
    let isActive = !!this.state.lookupItems.find(x => !x.active);
    return isActive;
  }

  componentDidMount() {
    console.log(this.props);
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  toggle = (lookup) => {
    this.state.lookupItems.forEach(x => {
      if (x.Name1 === lookup.Name1) {
        x.active = !x.active;
      }
    });
    let items = cloneDeep(this.state.lookupItems);
    this.setState({ lookupItems: items },
      () => { this.props.filterChangedCallback() });
  }

  toggleAll = () => {
    this.state.lookupItems.forEach(x => {
      x.active = !this.state.allToggle;
    });

    if (this.state.lookupItems.length === this.state.allLookupItems.length) {
      this.state.allLookupItems.forEach(x => {
        x.active = !this.state.allToggle;
      })
    }

    this.setState({ lookupItems: cloneDeep(this.state.lookupItems), allToggle: !this.state.allToggle, allLookupItems: cloneDeep(this.state.allLookupItems) },
      () => { this.props.filterChangedCallback() });
  }

  getModel() {
    let filterItems = this.state.lookupItems.filter(x => x.active);

    if (filterItems.length === this.state.lookupItems.length)
      return { value: '', remove: true };

    if (filterItems.find(x => x.isBlank)) {
      filterItems = [...filterItems, ...this.state.lookupItems.filter(x => x.isBlank)]
    }

    let filterIds = filterItems.map(x => "'" + x.key + "'");

    let filterStr = ' IN (' + filterIds.join(', ') + ')';

    if (filterItems.length === 0) {
      filterStr = ' IN (null)';
    }

    let includeNull = !!filterItems.find(x => x.Name1 === '');
    let metadata = this.state.bucketMetadata;

    return {
      value: filterStr,
      filterType: this.state.filterType,
      dataType: 'Bucket',
      includeNull: includeNull,
      joinColumn: metadata.JoinColumn,
      joinTable: metadata.JoinTable,
      sortColumn: metadata.SortColumn,
    };
  }

  lookupSearchChange = (e) => {
    let newLookup = this.state.allLookupItems.reduce((lookupArr, item) => {
      if(item.Name1 !== null) 
        if (item.Name1.toString().toUpperCase().includes(e.target.value.toUpperCase()))
          lookupArr.push(item);
      return lookupArr;
    }, []);
    this.setState({ lookupItems: newLookup })
  }

  lookupFilterSubmit = (e) => {
    e.preventDefault();
    let items = cloneDeep(this.state.lookupItems);
    let completeLookupList = [];

    this.state.allLookupItems.forEach(x => {
      let property = items.find(item => item['Name1'] === x['Name1']);
      if (property) {
        completeLookupList.push(property);
      } else {
        x.active = false;
        completeLookupList.push(x);
      }
    });

    let textbox = document.getElementById('filter-filter-text');
    textbox.value = '';

    this.setState({ lookupItems: completeLookupList },
      () => { this.props.filterChangedCallback() });
  }

  setModel(model) {
    if (!model || !model.value) {
      this.state.lookupItems.forEach(x => x.active = true);
      this.setState({ lookupItems: cloneDeep(this.state.lookupItems) });
      return;
    }

    let includes = model.value.replace(' IN (', '').replace(')', '').split(', ');
    let lookups = cloneDeep(this.state.lookupItems);
    lookups.forEach(x => x.active = false);

    includes.forEach(key => {
      let lookup = lookups.find(x => "'" + x.key + "'" === key);
      if (lookup) {
        lookup.active = true;
      }
    });
    this.setState({ lookupItems: lookups });
  }

  doesFilterPass(params) {
    console.log({ doesFilterPass: params });
  }

  render() {
    let distinctBuckets = this.state.lookupItems.reduce((prev, next) => {
      if (!prev.keys[next.Name1]) {
        prev.keys[next.Name1] = true;
        prev.items.push(next);
      }
      return prev;
    }, { keys: {}, items: [] });

    return (
      <div className="ag-lookup-filter">
        <form onSubmit={this.lookupFilterSubmit}>
          <input placeholder='Filter Options' id='filter-filter-text' type='text' onChange={this.lookupSearchChange} autoComplete='off' />
          <input className='filter-filter-submit' type='submit' value='Ok' />
        </form>
        <div className="ag-filter-item" onClick={this.toggleAll}>
          <input type="checkbox" checked={this.state.allToggle} onChange={() => { }} />
          <div>(Toggle All)</div>
        </div>
        {
          distinctBuckets.items.map((x, idx) => (
            <div key={idx} className="ag-filter-item" onClick={() => { this.toggle(x) }}>
              <input type="checkbox" checked={x.active} onChange={() => { }} />
              <div>{x.Name1 || '(Empty)'}</div>
            </div>
          ))
        }
      </div>
    )
  }
}

// export default connect(mapStateToProps)(LookupFilter);