import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSource');
  let saveData = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveData');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let entityScene = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityScene');
  let entitySource = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntitySource');

  return {
    Button: source ? state[source.ParameterValue] : null,
    ButtonSource: source ? source.ParameterValue : null,
    SaveData: saveData ? state[saveData.ParameterValue] : null,
    Entity: entityKey ? state[entityKey.ParameterValue] : null,
    EntityKey: entityKey ? entityKey.ParameterValue : null,
    SaveDataKey: saveData ? saveData.ParameterValue : null,
    SaveId: state.dbo_SaveId,
    EntityScene: entityScene ? entityScene.ParameterValue : null,
    EntitySource: entitySource ? entitySource.ParameterValue : null
  };
};

export class Delete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateButton(0, false);
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.Button)
      return;

    this.checkEnabled();
    this.handleClick(prevProps);
  }

  handleClick = (prevProps) => {
    if (!prevProps.Button)
      return;

    if (prevProps.Button.ClickId !== this.props.Button.ClickId) {
      this.doDelete();
    }
  }

  checkEnabled = () => {
    let isEnabled = this.props.Entity && Object.keys(this.props.Entity).length !== 0 ? true : false;

    if (this.props.Button.Enabled !== isEnabled) {
      this.updateButton(this.props.Button.ClickId, isEnabled);
    }
  }

  doDelete = () => {
    this.props.dispatch(actions.ClearSaveData(this.props.SaveDataKey));
    let deleteProp = this.props.Entity.Properties.find(x => x.Name === 'IsDeleted');
    let enabledProp = this.props.Entity.Properties.find(x => x.Name === 'IsEnabled');

    let isEventDelete = this.props.EntityKey === 'ent_CurrentEvent';
    this.removeEntityData(isEventDelete);

    let saveData = [];

    if (deleteProp) {
      saveData.push({
        ...deleteProp.SaveData,
        EntityTypeId: this.props.Entity.EntityTypeId,
        IsEventData: isEventDelete,
        Value: true
      });
    }

    if (enabledProp) {
      saveData.push({
        ...enabledProp.SaveData,
        EntityTypeId: this.props.Entity.EntityTypeId,
        IsEventData: isEventDelete,
        Value: false
      });
    }

    if (saveData.length === 0)
      return;

    actions.ApiRequest('Save/Save', { SaveData: saveData }, () => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });
  }

  removeEntityData = (isEventDelete) => {
    batch(() => {
      this.props.dispatch(actions.SetCurrentEntity(undefined, this.props.EntityKey));

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: undefined
      }));

      if (this.props.EntityScene) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.EntityScene,
          Value: undefined
        }));
      }

      if (this.props.EntitySource) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.EntitySource,
          Value: undefined
        }));
      }

      if (isEventDelete) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ent_TryCloseEvent',
          Value: true
        }));
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: 'dbo_EventGrid',
          Value: undefined
        }));
      }
    });
  }

  updateButton = (clickId, isEnabled) => {
    if (!this.props.ButtonSource)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        ClickId: clickId,
        Enabled: isEnabled
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Delete);