import React from 'react';
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';

export class TinyTitleLabel extends React.PureComponent {
  state = {
    Name: this.props.control.Name,
    Label: this.props.control.Label
  }

  render() {
    return (
      <div className="title-label tiny-title-label">
        <div>
          {this.state.Label}
          {this.props.control.HoverInfo && <HoverInfo Text={this.props.control.HoverInfo} />}
        </div>
      </div>
    );
  }
}

export default TinyTitleLabel;