import React from 'react';
import { batch, connect } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from '../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';
import * as icons from '../../../Icons/Icons';
import Monaco from '../../../../Utils/Monaco';
import cloneDeep from 'lodash.clonedeep';
import { AgGridReact } from 'ag-grid-react';
import SqlTab from './SqlTab';
import SchemaTab from './SchemaTab';

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');

  if (!state.DBManager && state.DBList) {
    state.DBManager = {
      DBs: state.DBList.map((x, idx) => { return { ...x, Id: idx, Active: false } }),
      Tabs: ['Sql', 'Schema', 'Install'].map((x, idx) => { return { Name: x, Active: idx === 0, Data: {} } })
    }
  }

  return {
    Manager: state.DBManager,
    ActiveTab: state.DBManager.Tabs.find(x => x.Active === true)
  };
};

export class DBManager extends React.PureComponent {

  componentDidUpdate(prev) {

  }

  componentDidMount(){
  }

  setData = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'DBManager',
      Value: cloneDeep(this.props.Manager)
    }));
  }

  toggleDB = (item) => {
    item.Active = !item.Active;
    this.setData();
  }

  setTab = (tab) => {
    if (tab.Active)
      return;

    this.props.Manager.Tabs.forEach(x => x.Active = false);
    tab.Active = true;
    this.setData();
  }

  setTabData = (val, key) => {
    let tab = this.props.ActiveTab;
    tab.Data = { ...tab.Data, [key]: val };
    this.setData();
  }

  getTabData = (key) => {
    return this.props.ActiveTab.Data[key];
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll();
  }

  autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    this.gridColumnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  render() {
    if (!this.props.Manager)
      return (null);

    let dbm = this.props.Manager;
    let tab = dbm.Tabs.find(x => x.Active);
    return (
      <div className="dbmanager-widget">
        <div className="db-list">
          {dbm.DBs.map((x, idx) => (
            <div key={idx} className="dbl-item" onClick={() => { this.toggleDB(x) }}>
              <div className="dbl-check"><input type="checkbox" checked={x.Active} /></div>
              <div className="dbl-name">{x.Text1}</div>
            </div>
          ))}
        </div>
        <div className="db-content">
          <div className="db-tabs">
            {dbm.Tabs.map((x, idx) => (
              <div key={idx} className={"dbm-tab" + (x.Active ? ' active' : '')} onClick={() => { this.setTab(x) }}>
                <div>{x.Name}</div>
              </div>
            ))}
          </div>
          <div className="db-content-pane">
            {tab.Name === 'Sql' && <SqlTab parent={this} dbm={dbm} />}
            {tab.Name === 'Schema' && <SchemaTab parent={this} dbm={dbm} />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DBManager);