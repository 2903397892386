import React from 'react';
import { batch, connect } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from '../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';
import * as icons from '../../../Icons/Icons';
import Monaco from '../../../../Utils/Monaco';
import cloneDeep from 'lodash.clonedeep';
import { AgGridReact } from 'ag-grid-react';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class SqlTab extends React.PureComponent {

  keyDown = (e) => {
    if (e.keyCode == 116 || (e.ctrlKey && e.keyCode === 69)) {
      this.runSql();
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyDown);
  }

  setResultActive = (item) => {
    let items = this.props.parent.getTabData('RunResult');
    items.forEach(x => x.Active = false);
    item.Active = true;
    this.props.parent.setData();
  }

  runSql = () => {
    let dbs = this.props.dbm.DBs.filter(x => x.Active).map(x => x.Text1);
    if (!dbs || dbs.length === 0)
      return;

    let body = {
      Sql: this.props.parent.getTabData('RunSql'),
      Databases: this.props.dbm.DBs.filter(x => x.Active).map(x => x.Text1)
    }

    actions.ApiRequest('Logging/GlobalSQL', body, (result) => {
      result = result.map((x, idx) => { return { ...x, Active: idx === 0 } });
      this.props.parent.setTabData(result, 'RunResult');
    });
  }

  render() {
    let parent = this.props.parent;

    return (
      <div className="db-sql-tab">
        <div className="dbm-label-btn">
          <div className="dbm-label">Run SQL</div>
          <div className="dbm-tri-btn" onClick={this.runSql}><div></div></div>
        </div>
        <div className="dbm-sql-box flex-1">
          <Monaco value={parent.getTabData('RunSql')} onChange={(e) => { parent.setTabData(e, 'RunSql') }} language={'sql'} />
        </div>
        <div className="dbm-label">Results</div>
        <div className="dbm-sql-box flex-1">
          <div className="dbm-run-result">
            <div className="db-tabs">
              {(parent.getTabData('RunResult') || []).map((x, idx) => (
                <div key={idx} className={"dbm-tab" + (x.Active ? ' active' : '')} onClick={() => { this.setResultActive(x) }}>
                  <div className="dbm-run-name">{x.DbName}</div>
                </div>
              ))}
            </div>
            {(parent.getTabData('RunResult') || []).map((x, idx) => (
              x.Active && x.Value.length > 0 &&
              <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                  rowData={x.Value}
                  columnDefs={Object.keys(x.Value[0]).map((key) => { return { field: key } })}
                  defaultColDef={{ sortable: false, minWidth: 60, filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'] }}
                  rowHeight={25}
                  headerHeight={25}
                  onGridReady={parent.onGridReady}
                ></AgGridReact>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SqlTab);