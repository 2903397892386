import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as Azure from '@azure/storage-blob';

const mapStateToProps = (state, ownProps) => {
  let sourceProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'ImageSource');
  let sourceKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ImageSourceKey');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let isDisplay = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsDisplay');
  let engine = ownProps.widget.Parameters.find(x => x.ParameterName === 'EngineKey');
  let noCache = ownProps.widget.Parameters.find(x => x.ParameterName === 'NoCache');
  let altText = ownProps.widget.Parameters.find(x => x.ParameterName === 'AltText');
  let adPosition = ownProps.widget.Parameters.find(x => x.ParameterName === 'AdPosition');

  let useAds
  if(state.ent_CurrentContractor != null && adPosition) {
    let properties = state.ent_CurrentContractor.Properties
    useAds = properties.find(x => x.Name === `UseAds${adPosition.ParameterValue}`)
  }

  return {
    ImageSource: sourceProp ? sourceProp.ParameterValue : '',
    ImageFromKey: sourceKey ? state[sourceKey.ParameterValue] : '',
    EntityKey: entityKey ? entityKey.ParameterValue : undefined,
    IsDisplay: isDisplay ? helpers.stringToBool(isDisplay.ParameterValue) : false,
    Entity: entityKey ? state[entityKey.ParameterValue] : undefined,
    EngineKey: engine ? engine.ParameterValue : undefined,
    Engine: engine ? state[engine.ParameterValue] : undefined,
    NoCache: noCache ? helpers.stringToBool(noCache.ParameterValue) : undefined,
    AltText: altText ? altText.ParameterValue : '',
    BlobSAS: state.BlobSAS,
    EntityMetadata: state.met_EntityMetadata,
    UseAds: (useAds && useAds.Value > 0) ? true : false,
    AdPosition: adPosition ? adPosition.ParameterValue : null
  };
}

export class ImageWidget extends React.PureComponent {
  state = {
    id: 'image-' + helpers.getId()
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate() {
    this.loadImage();
  }

  loadImage = () => {
    if (this.props.Entity && this.props.IsDisplay && this.props.Entity.PrimaryImage) {
      this.getImage();
    } else {
      this.clearImage();
    }
  }

  getImage = async (small = true) => {
    if (this.props.Entity.PrimaryImage.startsWith('hyperlink')) {
      let img = new Image();
      img.src = this.props.Entity.PrimaryImage.replace('hyperlink:', '');
      setTimeout(() => {
        helpers.showImagePreview(img, document.getElementById(this.state.id));
      }, 0);
    } else {
      let imgParts = this.props.Entity.PrimaryImage.split('.');
      let fileName = imgParts[0] + (small ? '_s' : '_l') + '.' + imgParts[1];

      let containerClient = this.getBlobContainerClient();
      let blobClient = containerClient.getBlobClient(fileName);
      const downloadBlockBlobResponse = await blobClient.download();
      const blobSrc = await downloadBlockBlobResponse.blobBody;
      let img = new Image();
      img.src = URL.createObjectURL(blobSrc);
      helpers.showImagePreview(img, document.getElementById(this.state.id));
    }
  }

  getBlobContainerClient = () => {
    let blobServiceClient = new Azure.BlobServiceClient(this.props.BlobSAS.Key);
    let containerName = this.props.BlobSAS.Database.replace(/_/g, '').toLowerCase();
    return blobServiceClient.getContainerClient(containerName);
  }

  onClick = () => {
    if (this.props.EngineKey) {
      let eng = this.props.Engine;

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EngineKey,
        Value: {
          Label: eng ? eng.Label || '' : '',
          ClickId: eng ? (eng.ClickId || 0) + 1 : 0,
          Enabled: true
        }
      }));
    }
  }

  clearImage = () => {
    if (!this.props.ImageSource && !this.props.ImageFromKey) {
      let img = document.querySelector('#' + this.state.id + ' img');
      if (img) {
        img.style.display = 'none';
      }
    }
  }

  getImageSource = () => {
    let result = this.props.ImageSource || this.props.ImageFromKey;

    if (this.props.Entity) {
      result = result.replace('[EntityTypeId]', this.props.Entity.EntityTypeId);
      result = result.replace('[ParentTypeId]', this.props.Entity.ParentTypeId);
    }

    if (this.props.EntityMetadata.CurrentTenant) {
      if(this.props.UseAds && this.props.AdPosition) {
        result = result.replace('[TenantId]', this.props.EntityMetadata.CurrentTenant);
      } else if(this.props.UseAds === false && this.props.AdPosition === null) {
        result = result.replace('[TenantId]', this.props.EntityMetadata.CurrentTenant);
      } else {
        result = result.replace('[TenantId]', 1);
      }
    }

    return result;
  }

  render() {
    let suffix = '';
    if (this.props.NoCache) {
      suffix = '?' + performance.now();
    }
    return (
      <div className="image-widget">
        {(!this.props.ImageSource && !this.props.ImageFromKey) ? null :
          <div id={this.state.id} className={"image-container"}>
            <img onError={(e) => { e.target.style.display = 'none'; }} onLoad={(e) => { e.target.style.display = ''; }} src={this.getImageSource() + suffix} onClick={this.onClick} alt={this.props.AltText}></img>
          </div>
        }
        {!this.props.IsDisplay ? null :
          <React.Fragment>
            {this.props.Entity && !this.props.Entity.PrimaryImage && <div className="selector-item-text">
              <div>No Primary Image</div>
            </div>}
            <div id={this.state.id} className={'display-image'}>
              <img src={this.props.ImageSource} onClick={this.onClick} alt={this.props.AltText}></img>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(ImageWidget);