import React from 'react';
import { connect, batch } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from './../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';
import Grid from './../../Grid/v001/Grid';
import WidgetLoader from './../../../WidgetLoader';
import { selector } from '../../../../Stores/Reducers/reducer';
import cloneDeep from 'lodash.clonedeep';
import * as hub from './../../../../Utils/PubSub';

const mapStateToProps = (state, ownProps) => {
  let creditGridSwid = ownProps.widget.Parameters.find(x => x.ParameterName === 'CreditGridSWID'); // 3170
  let debitGridSwid = ownProps.widget.Parameters.find(x => x.ParameterName === 'DebitGridSWID'); // 3169

  return {
    CreditGridSWID: creditGridSwid ? creditGridSwid.ParameterValue : null,
    DebitGridSWID: debitGridSwid ? debitGridSwid.ParameterValue : null,
    DebitGrid: debitGridSwid && state['_grid' + debitGridSwid.ParameterValue] ? state['_grid' + debitGridSwid.ParameterValue] : null,
    CreditGrid: creditGridSwid && state['_grid' + creditGridSwid.ParameterValue] ? state['_grid' + creditGridSwid.ParameterValue] : null,
    CurrentEvent: state.ent_CurrentEvent,
    BeforeSaveId: state.dbo_BeforeSaveId,
    SaveId: state.dbo_SaveId,
    SaveData: state.dbo_EventSaveData,
    DebitGridLoading: debitGridSwid && state['_grid' + debitGridSwid.ParameterValue] ? state['_grid' + debitGridSwid.ParameterValue + 'Loading'] : null,
    CreditGridLoading: creditGridSwid && state['_grid' + creditGridSwid.ParameterValue] ? state['_grid' + creditGridSwid.ParameterValue + 'Loading'] : null,
    SWID: ownProps.widget.SceneWidgetId
  };
};

export class UBJournalEntry extends React.PureComponent {

  componentDidMount() {

  }

  componentDidUpdate(prev) {
    if (this.props.BeforeSaveId !== prev.BeforeSaveId) {
      let status = helpers.getLookupItem(this.props.SaveData.find(x => x.Column === 'StatusLId').Value);
      if (!status || status && status.Name1.toLowerCase() !== 'rejected') { //allow malformed journal entries to pass required props check when being rejected
        this.checkRequiredProps();
      }
    }
  }

  checkRequiredProps = () => {
    if (!this.props.CreditGrid || !this.props.DebitGrid)
      return;

    let reqCreditRows = this.props.CreditGrid.Rows.filter(x => x.Amount && x.Amount.Value && x.Amount.myControl && (!x.AcctFund || !x.AcctFund.Value));
    let reqDebitRows = this.props.DebitGrid.Rows.filter(x => x.Amount && x.Amount.Value && x.Amount.myControl && (!x.AcctFund || !x.AcctFund.Value));

    let failValidateRows = [...reqDebitRows, ...reqCreditRows];

    failValidateRows.forEach(x => {
      if (x.AcctFund && x.AcctFund.myControl && x.AcctFund.myControl.props && x.AcctFund.myControl.props.control) {
        x.AcctFund.myControl.props.control.FailedValidate = true;
      }
    });

    if (failValidateRows.length > 0) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_StopSave',
        Value: true
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_CurrentEvent',
        Value: {
          ...cloneDeep(this.props.CurrentEvent),
          sr_SubmitJournalComplete: false
        }
      }));

      this.refreshGrids();
    }
  }

  refreshGrids = () => {
    setTimeout(() => {
      batch(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: '_grid' + this.props.CreditGridSWID + 'Blink',
          Value: true
        }));

        this.props.dispatch(actions.UpdateProp({
          Key: '_grid' + this.props.DebitGridSWID + 'Blink',
          Value: true
        }));
      });
    }, 0);
  }

  calculateBalance = () => {
    let balance = { Credit: 0, Debit: 0, Total: 0 }

    if (!this.props.CreditGrid || !this.props.DebitGrid)
      return balance;

    let creditRows = this.props.CreditGrid.Rows.filter(x => x.Amount && x.Amount.myControl && Number(x.Amount.myControl.props.control.Value || x.Amount.Value));
    let debitRows = this.props.DebitGrid.Rows.filter(x => x.Amount && x.Amount.myControl && Number(x.Amount.myControl.props.control.Value || x.Amount.Value));

    let creditTotal = creditRows.reduce((prev, next) => { return prev + Number(next.Amount.myControl.props.control.Value || next.Amount.Value); }, 0);
    let debitTotal = debitRows.reduce((prev, next) => { return prev + Number(next.Amount.myControl.props.control.Value || next.Amount.Value); }, 0);

    creditTotal = Number((creditTotal || 0).toFixed(2));
    debitTotal = Number((debitTotal || 0).toFixed(2));

    if (creditTotal > 0) {
      creditTotal = -creditTotal;
    }

    let validJournal = (creditTotal + debitTotal) === 0 && debitRows.length > 0 && creditRows.length > 0;

    if (this.props.CurrentEvent && !!this.props.CurrentEvent.ValidJournal !== validJournal) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_CurrentEvent',
        Value: {
          ...this.props.CurrentEvent,
          ValidJournal: validJournal
        }
      }))
    }

    balance = {
      Total: creditTotal + debitTotal,
      Credit: creditTotal,
      Debit: debitTotal
    }

    return balance;
  }

  render() {
    let balance = this.calculateBalance();
    let balanceClass = 'journal-balance' + (balance.Total === 0 ? '' : ' non-zero');
    return (
      <div className="ub-journal-entry">
        <div className="journal-totals-container">
          <div className="journal-totals">
            <div className="journal-total debit-total">
              <div className="label">
                <div>Debit</div>
              </div>
              <div className="amount">
                <div>{`${Math.abs(balance.Debit).toFixed(2)}`}</div>
              </div>
            </div>
            <div className={`journal-total ${balanceClass}`}>
              <div className="label">
                <div>Balance</div>
              </div>
              <div className="amount">
                <div>{`${balance.Total < 0 ? '(' + Math.abs(balance.Total).toFixed(2) + ')' : Math.abs(balance.Total).toFixed(2)}`}</div>
              </div>
            </div>
            <div className="journal-total credit-total">
              <div className="label">
                <div>Credit</div>
              </div>
              <div className="amount">
                <div>{`${Math.abs(balance.Credit).toFixed(2)}`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(UBJournalEntry);