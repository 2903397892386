import { Tile as LayerTile } from 'ol/layer';
import xyz from 'ol/source/XYZ';
import store from '../../../../../Stores/Store'; 

const googleMapsLayer = (options) => {
  const state = store.getState()
  
  if(state.GoogleToken) {
    return new LayerTile({
      source: new xyz({
        url: `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?session=${state.GoogleToken}&key=AIzaSyBm84LMWYf46FSbqObD9pm0dF71HZpmG0U`,
        crossOrigin: "Anonymous"
      })
    });
  }
}

export default {
    googleMapsLayer: googleMapsLayer
}
