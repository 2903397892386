import React from 'react';
import ReactDOM from 'react-dom';
import store from './../Stores/Store';
import Style from 'ol/style/Style';
import { batch } from 'react-redux';
import * as actions from './../Stores/Actions/actions';
import cloneDeep from 'lodash/cloneDeep';

export const apply = (value, options) => {
  if (options.DecimalPlaces) {
    value = decimalPlaces(value, options.DecimalPlaces);
  }

  if (options.NegativesInParenthesis) {
    value = negativesInParenthesis(value, options);
  }

  if (options.NoCommaNumber) {
    value = noCommaNumber(value);
  }

  if (options._class && options._class.includes('external-link')) {
    let pieces = value.split(',');
    let site = pieces[0];
    let label = pieces[0];
    if (pieces.length > 1) {
      label = pieces[0];
      site = pieces[1];
    }
    return `<a href="${site}" target="_blank">${label}</a>`;
  }


  return value;
}

const noCommaNumber = (value) => {
  if (value !== null) {
    if (typeof value === 'string') {
      value = value.replace(/,/g, '');
    }
  }

  return value;
}

const decimalPlaces = (value, decimalPlaces) => {
  if (value !== null) {
    if (typeof value === 'string') {
      value = value.replace(/,/g, '');
    }

    if (!isNaN(Number(value))) {
      value = Number(value).toLocaleString(undefined, { minimumFractionDigits: decimalPlaces });
    }
  }
  return value;
}

const negativesInParenthesis = (value, options) => {
  if (value !== null) {
    let numRes = value.replace(/,/g, '');
    if (!isNaN(Number(value)) && Number(numRes) < 0) {
      value = '(' + Math.abs(numRes).toLocaleString(undefined, { minimumFractionDigits: options.DecimalPlaces !== undefined ? options.DecimalPlaces : 2 }) + ')';
    }
  }

  return value;
}