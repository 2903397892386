import './App.css';
import './311.css';
import './Styles/ol.css';
import React from 'react';
import Store from './Stores/Store';
import SceneLayout from './Components/SceneLayout';
import ModalLayer from './Components/ModalLayer';
import DialogLayer from './Components/DialogLayer';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './Stores/Reducers/reducer';
import thunk from 'redux-thunk';
import { useAuth0 } from "./react-auth0-spa";

function PublicApp() {
  return (
    <Provider store={Store}>
      <div id="app">
        <DialogLayer />
        <ModalLayer />
        <SceneLayout token={null} />
      </div>
    </Provider>
  )
}

export default PublicApp;