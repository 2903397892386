import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep'
import * as actions from './../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';
import ResourceDisplay from './ResourceDisplay';
import ResourceEntry from './ResourceEntry';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class MobileInterface extends React.PureComponent {
  state = {
    Display: 'Rows'
  }

  addRow = (resource) => {
    console.log(resource);
    this.props.parent.addRow(resource);
    this.setDisplay('Rows');
  }

  updateRow = (resource) => {
    this.props.parent.updateRow(resource);
    setTimeout(() => { this.setState({ Display: 'Rows' }); }, 0);
    this.setDisplay('Rows');
  }

  setEntryItem = (label, row, idx) => {
    let parent = this.props.parent;
    parent.props.Resources[parent.props.MaterialType].Rows[idx].Active = false;
    parent.setEntryItem(label, row, idx);
    this.setDisplay('Entry');
  }

  cancel = () => {
    this.props.parent.clearActive(this.props.parent.props.Resources);
    this.setDisplay('Rows');
  }

  removeRow = () => {
    this.props.parent.removeRow();
    this.setDisplay('Rows');
  }

  setDisplay = (type) => {
    setTimeout(() => { this.setState({ Display: type }); }, 0);
  }

  render() {
    let parent = this.props.parent;
    let isEditMode = parent.isEditMode();
    return (
      <div className="resources mobile-interface">
        {this.state.Display === 'Rows' &&
          <div className="resources-item-container">
            {parent.props.MaterialType === 'Time' &&
              <ResourceDisplay
                PrimaryField={parent.props.PrimaryField}
                Resource={parent.props.Resources.Time}
                setEntryType={parent.setEntryType}
                setEntryItem={this.setEntryItem} />
            }
            {parent.props.MaterialType === 'Material' &&
              <ResourceDisplay
                PrimaryField={parent.props.PrimaryField}
                Resource={parent.props.Resources.Material}
                setEntryType={parent.setEntryType}
                setEntryItem={this.setEntryItem} />
            }
            {parent.props.MaterialType === 'Equipment' &&
              <ResourceDisplay
                PrimaryField={parent.props.PrimaryField}
                Resource={parent.props.Resources.Equipment}
                setEntryType={parent.setEntryType}
                setEntryItem={this.setEntryItem} />
            }
            <div className="plus-button" onClick={() => { this.setState({ Display: 'Entry' }) }}>
              <div className="cross-vertical"></div>
              <div className="cross-horizontal"></div>
            </div>
          </div>
        }
        {this.state.Display === 'Entry' &&
          <div className="entry">
            {parent.props.MaterialType === 'Time' &&
              <ResourceEntry
                Resource={parent.props.Resources.Time}
                addRow={this.addRow}
                updateRow={this.updateRow}
                cancel={this.cancel}
                removeRow={this.removeRow}
                isEditMode={isEditMode} />}
            {parent.props.MaterialType === 'Material' &&
              <ResourceEntry
                Resource={parent.props.Resources.Material}
                addRow={this.addRow}
                updateRow={this.updateRow}
                cancel={this.cancel}
                removeRow={this.removeRow}
                isEditMode={isEditMode} />}
            {parent.props.MaterialType === 'Equipment' &&
              <ResourceEntry
                Resource={parent.props.Resources.Equipment}
                addRow={this.addRow}
                updateRow={this.updateRow}
                cancel={this.cancel}
                removeRow={this.removeRow}
                isEditMode={isEditMode} />}
          </div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(MobileInterface);


