import React, { PureComponent } from 'react'
import ReactDom from 'react-dom'

export class IndeterminateCheck extends PureComponent {
  componentDidMount() {
    this.setIndeterminate();
  }

  componentDidUpdate() {
    this.setIndeterminate();
  }

  setIndeterminate = () => {
    ReactDom.findDOMNode(this).indeterminate = this.props.indeterminate;
  }

  render() {
    return (
      <input type="checkbox" onChange={this.props.onChange} checked={this.props.active || false} />
    )
  }
}

export default IndeterminateCheck;