import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../Stores/Actions/actions';
import * as helpers from '../../../Utils/Helpers';
import * as hub from './../../../Utils/PubSub';

const mapStateToProps = (state, ownProps) => {
  return {

  };
}

export class FullEditModeWidget extends React.PureComponent {
  componentDidMount() {

  }

  componentDidUpdate(prev) {

  }


  render() {
    return (
      <div className={"full-edit-widget" + (this.props.subSceneHandle ? ' sub-scene-handle' : '')}>
        <div className="widget-name">
          <div>{this.props.widget.WidgetName}</div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FullEditModeWidget);





