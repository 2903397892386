import cloneDeep from 'lodash/cloneDeep';
import * as helpers from './../../Utils/Helpers';

const initialState = {
  blu_Scene: [],
  dbo_SaveData: [],
  dbo_EventSaveData: [],
  ent_Current: {},
  qry_ColumnWidths: [],
  qry_RefreshCurrentPage: 1,
  dbo_SaveId: 1,
  dbo_CancelId: 1,
  dbo_CloseDialogId: 1,
  EditMode: false,
  MobileLog: [],
  PropChange: {},
  widgetState: {},
  PersistedWidgets: {},
  ActiveSWIDs: {},
  _chart: {},
  BuildNumber: 1.0,
  ms_FCatalogEdit: 1,
  ms_PWACatalogEdit: 1,
  ms_PWRCatalogEdit: 1,
  ms_PWVCatalogEdit: 1,
  ms_DeckCatalogEdit: 1,
  ms_DeckStainCatalogEdit: 1,
  ub_RefreshId: 1,
  ms_RoofCatalogEdit: 1,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  let val = action.val;

  if (action.type === 'ResumePendingAction' && newState.dbo_PendingAction) {
    action.type = 'UpdateMultiple';
    val = newState.dbo_PendingAction.map(x => x.val);
    val.IgnoreInterrupt = true;
    newState.dbo_PendingAction = undefined;
  }

  if (action.type === 'UpdateProp') {
    if (val.Key === 'OpenTab') {
      OpenTab(val);
    } else {
      let interrupt = false;
      if (newState.wkf_Dialogs && !val.IgnoreInterrupt) {
        interrupt = CheckDialogInterrupt(newState, val.Key);
      }

      if (!interrupt) {
        UpdateProperty(newState, val);
      } else {
        newState.dbo_PendingAction = [...(newState.dbo_PendingAction || []), action];
        if (val.OnInterrupt) {
          val.OnInterrupt();
        }
      }
    }
  }

  if (action.type === 'AutoLoadProp') {
    if (val.Key === 'OpenTab') {
      OpenTab(val);
    } else {

      if (newState.PreventAutoLoad) {
        newState.PreventAutoLoad = false;
      } else {
        let interrupt = false;
        if (newState.wkf_Dialogs && !val.IgnoreInterrupt) {
          interrupt = CheckDialogInterrupt(newState, val.Key);
        }

        if (!interrupt) {
          UpdateProperty(newState, val);
        } else {
          newState.dbo_PendingAction = [...(newState.dbo_PendingAction || []), action];
          if (val.OnInterrupt) {
            val.OnInterrupt();
          }
        }
      }
    }
  }

  if (action.type === 'UpdateMultiple') {
    let interrupt = false;
    if (newState.wkf_Dialogs) {
      val.forEach(x => {
        if (!interrupt) {
          interrupt = CheckDialogInterrupt(newState, x.Key);
        }
      });

      if (!interrupt || val.IgnoreInterrupt) {
        val.forEach(x => {
          if (x.Key === 'OpenTab') {
            OpenTab(x);
          } else {
            UpdateProperty(newState, x);
          }
        });
      } else {
        newState.dbo_PendingAction = [...(newState.dbo_PendingAction || []), action];
        if (val.OnInterrupt) {
          val.OnInterrupt();
        }
      }
    }
  }

  if (action.type === 'SetSwidActive') {
    newState.ActiveSWIDs[val.Key] = val.Value;
    newState.ActiveSWIDs = { ...newState.ActiveSWIDs };
  }

  if (action.type === 'SetWidgetState') {
    newState.widgetState = {
      ...newState.widgetState,
      [val.swid]: val.state
    }
  }

  if (action.type === 'MobileLog') {
    let mlog = cloneDeep(newState.MobileLog);
    mlog.push({ id: mlog.length, text: val });
    newState.MobileLog = mlog;
  }

  if (action.type === 'GlobalRefresh') {
    return cloneDeep(newState);
  }

  if (action.type === 'SetCurrentEntity') {
    newState[val.Key] = { ...val.Entity };
  }

  if (action.type === 'AddSaveData' || action.type === 'DefaultSaveDataIfMissing') {
    if (val.GobAutofill && !val.Value) {
      try {
        if (val.GobAutofill === 'NewInsertKey') {
          val.Value = helpers.getInsertKey();
        } else {
          let pieces = val.GobAutofill.split('.');
          let value = pieces.reduce((prev, next) => prev[next], newState);
          val.Value = value;
        }
      } catch { }
    }

    let saveDataKey = val.SaveQueue || (val.IsEventData ? 'dbo_EventSaveData' : 'dbo_SaveData');
    newState[saveDataKey] = newState[saveDataKey] || [];
    let saveData = newState[saveDataKey];
    let existing = saveData.find(x => x.Id == val.Id && x.Table === val.Table && x.Column === val.Column && x.InsertKey == val.InsertKey && x.EntityTypeId == val.EntityTypeId);
    saveData = saveData.filter(x => x.Id != val.Id || x.Table !== val.Table || x.Column !== val.Column || x.InsertKey != val.InsertKey);

    if (val.Radio && val.Value === true) {
      saveData.filter(x => x.Radio === val.Radio).forEach((x) => {
        x.Value = false;
      });
      newState['radio_' + val.Radio] = {
        Id: val.Id || val.InsertKey,
        UpdateId: helpers.getId()
      };
    }

    if (action.type === 'DefaultSaveDataIfMissing') {
      val.Value = val.DefaultValue;
      saveData = [...saveData, (existing ? { ...existing } : val)];
    }

    if (action.type === 'AddSaveData') {
      saveData = [...saveData, (existing ? { ...existing, Value: val.Value, AfterSave: val.AfterSave, NewEntitySaveData: false } : val)];
    }

    newState[saveDataKey] = [...saveData];
    console.log(newState[saveDataKey]);
  }

  if (action.type === 'ClearSaveData') {
    newState[val] = [];
  }

  if (action.type === 'CancelSaveData') {
    newState[val.key] = (newState[val.key] || []).filter(x => !val.clearNewProps && x.NewEntitySaveData);
  }

  if (action.type === 'GetScene') {
    let scenes = val.Scene;
    if (scenes.length > 0 && !newState.blu_MasterScene) {
      scenes.sort((a, b) => {
        return a.AlwaysOn ? -1 : b.AlwaysOn ? 1 : 0;
      });
      let defaultScene = scenes.find(x => x.IsDefault) || scenes[0];
      newState.blu_MasterScene = {
        Value: defaultScene.Name,
        Id: defaultScene.SceneId,
        Enabled: true
      }
    }

    newState.blu_Scene = scenes;
    newState.blu_WidgetList = val.WidgetList;
    newState.blu_FormWidgetParameters = val.FormWidgetParameter || [];
    newState.blu_ApplicationId = scenes[0].ApplicationId;
  }

  if (action.type === 'MoveGridColumn') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    let allIds = newState.qry_Queries[queryId].allIds;
    let fromId = allIds.findIndex(x => x === val.colId);
    let toId = allIds.findIndex(x => x === val.moveToId);
    allIds.map(x => newState.qry_Queries[queryId][x].hoverBlade = undefined);

    newState.qry_Queries[queryId].allIds = [...helpers.arrayMove(allIds, fromId, toId)];
    newState.qry_Queries[queryId] = { ...newState.qry_Queries[queryId] };
  }

  if (action.type === 'UpdateQueryData') {
    newState.qry_Queries[val.queryId].rowCount = val.data.rowCount;
    newState.qry_Queries[val.queryId][val.dataKey] = { ...val.data };
  }

  if (action.type === 'UpdateGridColumnProp') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    let toUpdate = newState.qry_Queries[queryId][val.colId];
    newState.qry_Queries[queryId][val.colId] = { ...toUpdate, [val.updateProp.key]: val.updateProp.val };
    if (val.renderAll) {
      newState.qry_Queries[queryId] = { ...newState.qry_Queries[queryId] };
      newState.qry_Queries[queryId].allIds = [...newState.qry_Queries[queryId].allIds];
    }
  }

  if (action.type === 'ToggleColumnPin') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    let queryObj = newState.qry_Queries[queryId];

    queryObj[val].IsPinned = !queryObj[val].IsPinned;
    let pinCount = queryObj.allIds.map(x => queryObj[x]).filter(x => x.IsPinned).length;
    let fromId = queryObj.allIds.findIndex(x => x === val);

    newState.qry_Queries[queryId].allIds = [...helpers.arrayMove(queryObj.allIds, fromId, queryObj[val].IsPinned ? pinCount - 1 : pinCount)];
    newState.qry_Queries[queryId] = { ...newState.qry_Queries[queryId] };
  }

  if (action.type === 'ResetRows') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    newState.qry_Queries[queryId].rows = [...Array(newState.qry_Queries[queryId].data.rowCount)].map(x => []);
  }

  if (action.type === 'SetTopRow') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    newState.qry_Queries[queryId].topRow = val.topRow;
  }

  if (action.type === 'UpdatePageRequests') {
    newState.qry_Queries[val.queryId].pageRequests = [...val.requests];
  }

  if (action.type === 'SetRowCount') {
    newState.qry_Queries[val.queryId].rowCount = val.rowCount;
    newState.qry_Queries[val.queryId].pageRequests = [];

    if (newState.qry_Queries[val.queryId].data) {
      newState.qry_Queries[val.queryId].data.rowCount = val.rowCount;
    }
  }

  if (action.type === 'SetHighlightRow') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    newState.qry_Queries[queryId].highlightRow = val;
  }

  if (action.type === 'UpdateColumnWidth') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    newState.qry_Queries[queryId][val.colId] = {
      ...newState.qry_Queries[queryId][val.colId],
      Width: val.width
    }
  }

  if (action.type === 'ToggleSortBy') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    let column = newState.qry_SelectedQuery.Layout.Columns.find(x => x.ColumnId === val);
    if (column) {
      console.log({ sortCol: column });
      newState.qry_Queries[queryId].rows = [...Array(newState.qry_Queries[queryId].data.rowCount)].map(x => []);
      let currentSort = newState.qry_Queries[queryId].sortBy;
      let newSort = column.TableName + '.' + column.ColumnName;
      if (currentSort === newSort) {
        newState.qry_Queries[queryId].sortBy = currentSort + ' DESC';
      } else if (currentSort === newSort + ' DESC') {
        newState.qry_Queries[queryId].sortBy = '';
      } else {
        newState.qry_Queries[queryId].sortBy = newSort;
      }
    }
  }

  if (action.type === 'SetPendingFilter') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    newState.qry_Queries[queryId][val.colId] = {
      ...newState.qry_Queries[queryId][val.colId],
      PendingFilter: val.filter
    }
  }

  if (action.type === 'ConfirmFilter') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    newState.qry_Queries[queryId].rows = [...Array(newState.qry_Queries[queryId].data.rowCount)].map(x => []);

    let pending = newState.qry_Queries[queryId][val].PendingFilter;
    if (!pending.Value && pending.Mode.NeedsValue) {
      newState.qry_Queries[queryId][val].PendingFilter = '';
    }

    newState.qry_Queries[queryId][val] = {
      ...newState.qry_Queries[queryId][val],
      Filter: newState.qry_Queries[queryId][val].PendingFilter,
      UpdateRowCount: true
    }
  }

  if (action.type === 'ClearFilter') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    if (newState.qry_Queries[queryId][val].Filter) {
      newState.qry_Queries[queryId].rows = [...Array(newState.qry_Queries[queryId].data.rowCount)].map(x => []);
    }
    newState.qry_Queries[queryId][val] = {
      ...newState.qry_Queries[queryId][val],
      Filter: '',
      PendingFilter: '',
      UpdateRowCount: true
    }
  }

  if (action.type === 'SetLookupItems') {
    let queryId = newState.qry_SelectedQuery.QueryId;

    newState.qry_Queries[queryId][val.colId] = {
      ...newState.qry_Queries[queryId][val.colId],
      PendingFilter: { ...newState.qry_Queries[queryId][val.colId].PendingFilter, LookupItems: [...val.items] }
    }
  }

  if (action.type === 'ToggleLookupItem') {
    let queryId = newState.qry_SelectedQuery.QueryId;
    let items = cloneDeep(newState.qry_Queries[queryId][val.colId].PendingFilter.LookupItems);

    if (items[val.lookupIdx].ToggleAll) {
      let isEnabled = !items[val.lookupIdx].Enabled;
      items.forEach(x => x.Enabled = isEnabled);
    } else {
      items[val.lookupIdx].Enabled = !items[val.lookupIdx].Enabled;
    }

    newState.qry_Queries[queryId][val.colId] = {
      ...newState.qry_Queries[queryId][val.colId],
      PendingFilter: { ...newState.qry_Queries[queryId][val.colId].PendingFilter, LookupItems: [...items] }
    }
  }

  if (action.type === 'ToggleRowPin') {
    let query = newState.qry_Queries[newState.qry_SelectedQuery.QueryId]
    let currentPins = (query.pinnedRows || []);

    if (currentPins.includes(val)) {
      currentPins = currentPins.filter(x => x !== val);
    } else {
      currentPins.push(val);
    }

    query.pinnedRows = [...currentPins];
  }

  if (action.type === 'AddGridRows') {
    if (!newState.qry_Queries[val.queryId][val.dataKey] || newState.qry_Queries[val.queryId][val.dataKey].length === 0) {
      newState.qry_Queries[val.queryId][val.dataKey] = [...Array(newState.qry_Queries[val.queryId].data.rowCount)].map(x => []);
    }
    let rows = newState.qry_Queries[val.queryId][val.dataKey];
    let start = val.rowNumber || newState.qry_Queries[val.queryId].data.pageSize * val.page;

    newState.qry_Queries[val.queryId][val.dataKey] = [
      ...rows.slice(0, start),
      ...val.rows,
      ...rows.slice(start + val.rows.length, rows.length)
    ];
  }

  if (action.type === 'SetLegendItems') {
    newState.map_LegendItems = [...val];
  }

  if (action.type === 'SetMapStyles') {
    newState.map_Styles = [...val];
  }

  if (action.type === 'ToggleMapLayer') {
    let layers = newState.map_LegendItems;
    let layer = layers.find(x => x.LayerId === val);
    if (layer) {
      layer.Active = !layer.Active;

      let legendChanges = { [val]: layer.Active }

      let children = [...layer.Children];
      let stack = [...layer.Children];
      while (stack.length > 0) {
        let item = stack.pop();
        stack = stack.concat(item.Children);
        children = children.concat(item.Children);
      }

      children.forEach((x) => {
        x.Active = layer.Active;
        legendChanges[x.LayerId] = layer.Active;
      });

      let parent = layers.find(x => x.LayerId === layer.ParentId);
      if (layer.IsRadio && parent) {
        let siblings = parent.Children.filter(x => x.IsRadio && x.LayerId !== val);
        siblings.forEach((x) => {
          x.Active = false;
          legendChanges[x.LayerId] = false;
        });
      }

      if (parent) {
        const active = parent.Children.filter(x => !x.Active).length === 0;
        parent.Active = active;
        legendChanges[parent.LayerId] = active;
      }

      const oldLegend = JSON.parse(localStorage.getItem('PersistedLegend'));
      legendChanges = { ...oldLegend, ...legendChanges };
      localStorage.setItem('PersistedLegend', JSON.stringify(legendChanges));

      newState.map_LegendItems = [...layers];
    }
  }

  if (action.type === 'SetEntityDocuments') {
    newState[val.DocumentsKey] = { ...val };
  }

  if (action.type === 'ClearSaveDataForId') {
    if (newState[val.SaveData]) {
      newState[val.SaveData] = newState[val.SaveData].filter(x => x.Id != val.Id && x.InsertKey != val.Id);
    }
  }

  if (action.type === 'NewDocument') {
    if (!newState[val.DocumentsKey])
      newState[val.DocumentsKey] = {};

    if (!newState[val.DocumentsKey].Items)
      newState[val.DocumentsKey].Items = [];

    let existing = newState[val.DocumentsKey].Items.map((x) => {
      x.Active = false;
      return { ...x };
    });
    newState[val.DocumentsKey] = {
      ...newState[val.DocumentsKey],
      Items: [...existing, val]
    }
  }

  if (action.type === 'UpdateDocument') {
    if (val.ResetActive) {
      delete val.resetActive;
      newState[val.DocumentsKey].Items = [...newState[val.DocumentsKey].Items.map((x) => {
        x.Active = false;
        return { ...x };
      })];
    }

    let docIdx = newState[val.DocumentsKey].Items.findIndex(x => x.DocumentId === val.DocumentId);
    newState[val.DocumentsKey].Items[docIdx] = { ...val };
    newState[val.DocumentsKey] = { ...newState[val.DocumentsKey] };
  }

  if (action.type === 'UpdateEventGrid') {
    newState[val.key] = { ...val.events };
  }

  if (action.type === 'SetEventResources') {
    let key = val.EntityKey;
    newState[key].Resources = { ...val };
  }

  if (action.type === 'ToggleEditMode') {
    newState.EditMode = !newState.EditMode;
  }

  if (action.type === 'SetEditingScene') {
    newState.EditingScene = val;
  }

  if (action.type === 'SetEditingWidget') {
    newState.EditingWidget = val;
  }

  if (action.type === 'UpdateScene') {
    let swid = newState.EditingWidget.SceneWidgetId;
    newState.blu_Scene = cloneDeep(newState.blu_Scene);
    newState.blu_Scene.forEach(scene => {
      let widgetIdx = scene.Widgets.findIndex(x => x.SceneWidgetId === swid);
      if (widgetIdx > -1) {
        newState.EditingWidget = scene.Widgets[widgetIdx];
        newState.EditingScene = scene;
      }
    });
  }

  if (action.type === 'SetEditingWidgetBySwid') {
    newState.blu_Scene = cloneDeep(newState.blu_Scene);
    newState.blu_Scene.forEach(scene => {
      let widgetIdx = scene.Widgets.findIndex(x => x.SceneWidgetId === val);
      if (widgetIdx > -1) {
        newState.EditingWidget = scene.Widgets[widgetIdx];
        newState.EditingScene = scene;
      }
    });
  }

  return newState;
}

export default reducer;

const ActiveWidgets = (state) => {
  let masterName = state.blu_MasterScene ? state.blu_MasterScene.Value : '';
  let widgets = state.blu_Scene.reduce((prev, next) => {
    if (next.Name === masterName || next.AlwaysOn) {
      prev = prev.concat(next.Widgets);
    }
    return prev;
  }, []);

  widgets.sort((a, b) => {
    return a.ZIndex > b.ZIndex ? 1 : a.ZIndex < b.ZIndex ? -1 : 0;
  });

  return widgets;
}

const ActiveScene = (state) => {
  if (state.blu_MasterScene) {
    let scene = state.blu_Scene.find(x => x.Name === state.blu_MasterScene.Value);
    let result = scene;
    if (result) {
      state.blu_MasterScene.Id = scene.SceneId;
    }
    return result;
  }
}

const CloseDialog = (newState, val) => {
  if (newState.blu_ModalScene2) {
    //ent_SelectedEvent cleared when modal2 has an event loaded on it
    if (newState.blu_ModalScene2.ContainsEvent) {
      newState.ent_SelectedEvent = null;
    }
    newState.blu_ModalFrame2 = undefined;
    newState.blu_ModalScene2 = undefined;
    return;
  }
  if (
    CheckDialogInterrupt(newState, 'blu_EventSubScene') ||
    CheckDialogInterrupt(newState, 'ent_SelectedEvent') ||
    CheckDialogInterrupt(newState, 'ent_CurrentEvent') ||
    CheckDialogInterrupt(newState, 'doc_EventDocuments') ||
    CheckDialogInterrupt(newState, 'blu_ModalScene') ||
    CheckDialogInterrupt(newState, 'blu_ModalFrame') ||
    CheckDialogInterrupt(newState, 'MobileEventContent') ||
    CheckDialogInterrupt(newState, 'dbo_EventSaveData')
  ) {
    if (val.OnInterrupt) {
      val.OnInterrupt();
    }
    return;
  }

  newState.blu_EventSubScene = {
    Label: '',
    Value: 'EventDetailsTab',
    Enabled: true
  };

  newState.ent_SelectedEvent = undefined;
  newState.ent_CurrentEvent = undefined;
  newState.doc_EventDocuments = undefined;
  newState.blu_ModalScene = undefined;
  newState.blu_ModalFrame = undefined;
  newState.MobileEventContent = undefined;
  newState.ent_NewEventParent = undefined;
  newState.ent_NewEventParentTypeId = undefined;
  newState.MoveEventSearchText = undefined;
  newState.ent_EventMoveSelected = undefined;
  newState.ent_SavedResource = undefined;
  newState.ent_RecurSelected = undefined;
  newState.ent_NewContact = undefined;

  if (newState.ent_Selected_old) {
    newState.ent_Selected = newState.ent_Selected_old;
    newState.ent_Selected_old = undefined;
  }

  if (newState.ent_Current_old) {
    newState.ent_Current = newState.ent_Current_old;
    newState.ent_Current_old = undefined;
  }

  newState.dbo_EventSaveData = [];
  newState.dbo_CloseDialogId++;
}

const CheckDialogInterrupt = (newState, key) => {
  let interrupt = false;
  if (!newState.wkf_Dialogs || !Array.isArray(newState.wkf_Dialogs))
    return interrupt;

  let dlgItem = newState.wkf_Dialogs.find(x => x.GobKey.split(',').map(g => g.trim()).includes(key));
  if (dlgItem && dlgItem.SaveQueue.split(',').map(g => g.trim()).find(sq => newState[sq] && newState[sq].filter(x => !x.AutoGenerated).length > 0)) {
    interrupt = true;
    newState.blu_Dialog = dlgItem;
  }

  return interrupt;
}

const UpdateProperty = (newState, val) => {
  newState[val.Key] = val.Value;
  if (val.Value !== null && typeof val.Value === 'object' && !Array.isArray(val.Value)) {
    newState[val.Key] = { ...val.Value };
  }
  newState.PropChange[val.Key] = (newState.PropChange[val.Key] || 0) + 1;
  if (val.Key === 'ent_TryCloseEvent') {
    CloseDialog(newState, val);
  }
}

const OpenTab = (val) => {
  if (val && val.Value && val.Value.Value) {
    window.open(val.Value.Value, '_blank');
  } else if (val && val.Value && typeof val.Value === 'string') {
    window.open(val.Value, '_blank');
  }
}

const SceneByName = (state, name) => {
  if (!name)
    return;
  return state.blu_Scene.find(x => x.Name === name);
}

export const selector = {
  ActiveScene: ActiveScene,
  ActiveWidgets: ActiveWidgets,
  SceneByName: SceneByName
}
