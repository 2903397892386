import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from './../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    QueryObject: state.qry_Queries[ownProps.queryId]
  };
};

let allToggle = {};

export class ColumnPicker extends React.PureComponent {
  state = {
    enabled: false
  }

  componentDidMount() {
    window.addEventListener('click', this.windowClick);
    document.addEventListener('keyup', this.keyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowClick);
    document.removeEventListener('keyup', this.keyPress);
  }

  windowClick = (e) => {
    if (this.state.enabled && !helpers.elementOrAncestorHasClass(e.target, 'column-selection-box')) {
      this.setState({ enabled: false });
    }
  }

  keyPress = (e) => {
    if (this.state.enabled && e.key === 'Escape') {
      this.setState({ enabled: false });
    }
  }

  onClick = (e) => {
    this.setState({ enabled: !this.state.enabled });
    e.stopPropagation();
  }

  onChange = (x) => {
    this.props.dispatch(actions.UpdateGridColumnProp({
      colId: x.ColumnId,
      updateProp: { key: 'IsVisible', val: !x.IsVisible },
      renderAll: true
    }));
  }

  toggleAll = () => {
    let isActive = allToggle[this.props.queryId] ? true : false;
    let queryObj = this.props.QueryObject;
    let allProps = queryObj.allIds.filter(x => queryObj[x].Label[0] !== '!');

    batch(() => {
      allProps.forEach(x => {
        let colObj = queryObj[x];
        this.props.dispatch(actions.UpdateGridColumnProp({
          colId: colObj.ColumnId,
          updateProp: { key: 'IsVisible', val: isActive },
          renderAll: true
        }));
      });
    });

    allToggle[this.props.queryId] = !isActive;
    this.forceUpdate();
  }

  render() {
    let queryObj = this.props.QueryObject;
    return (
      <div className='column-picker'>
        <div className="column-picker-btn" onClick={this.onClick}>=</div>
        {
          this.state.enabled && queryObj &&
          <div className="column-selection-box">
            <div className="column-selection-item all-props" onClick={this.toggleAll}>
              <input type="checkbox" checked={!allToggle[this.props.queryId]} onChange={() => { }} />
              <div className="selection-item-label">All</div>
            </div>
            {queryObj.allIds.filter(x => queryObj[x].Label[0] !== '!').map((x, idx) => (
              <div key={idx} className="column-selection-item" onClick={() => { this.onChange(queryObj[x]); }}>
                <input type="checkbox" checked={queryObj[x].IsVisible} onChange={() => { }} />
                <div className="selection-item-label">{queryObj[x].Label}</div>
              </div>
            ))}
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(ColumnPicker);