import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    SaveId: state.dbo_SaveId,
    CurrentBudget: state.ent_CurrentEvent ? state.ent_CurrentEvent : null
  };
};

export class MV_DeleteConsumer extends React.PureComponent {

  componentDidMount() {
    if(this.props.CurrentBudget && this.props.CurrentBudget.EntityId) {
        this.props.dispatch(
            actions.UpdateProp({
                Key: "blu_Dialog",
                Value: {
                Title: "Delete Budget",
                Message: (
                    <>
                    <p style={{ fontSize: "1.1rem" }}>
                        Are you sure you want to delete this budget?
                    </p>
                        <div className="flex-btn-bar" style={{ paddingLeft: "10px", marginTop: "10px" }}>
                            <div className="btn-container" style={{ flex: "0 1 140px" }}>
                                <div className="btn-bar-btn polygon-btn" onClick={() => this.confirmDelete()}>
                                    <div>
                                        Delete
                                    </div>
                                </div>
                            </div>
                            <div className="btn-container" style={{ flex: "0 1 140px" }}>
                                <div className="btn-bar-btn polygon-btn-neg" onClick={this.cancelButton}>
                                    <div>
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ),
                Buttons: [],
                },
            })
        );
    }
  }

  confirmDelete = () => {
    if(this.props.CurrentBudget && this.props.CurrentBudget.EntityId) {
        const baseConsumerObj = {
            Id: this.props.CurrentBudget.EntityId,
            Table: 'xmv.Consumer',
            IsBaseTable: true,
            EntityTypeId: 2040
        }
        const saveData = []
    
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'IsDeleted', 1));
    
        actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
            this.props.dispatch(actions.UpdateProp({
                Key: 'dbo_SaveId',
                Value: this.props.SaveId + 1
            }));

            this.props.dispatch(actions.UpdateProp({
                Key: 'ent_CurrentEvent',
                Value: undefined
            }));

            this.props.dispatch(actions.UpdateProp({
                Key: 'blu_ModalScene',
                Value: undefined
            }));

            this.props.dispatch(actions.UpdateProp({
                Key: 'sr_DeleteConsumer',
                Value: undefined
            }));

            this.props.dispatch(
                actions.UpdateProp({
                  Key: "blu_Dialog",
                  Value: null,
                })
            );
        });
    }
  }

  cancelButton = () => {
    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: null,
      })
    );
  };


  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_DeleteConsumer);
