import cloneDeep from 'lodash.clonedeep';
import React, { Component, Fragment, createRef } from 'react';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

export default class FloatingFilter extends React.PureComponent {
  state = {
    currentValue: null,
    checkbox: {
      true: true,
      false: true,
      blank: true
    },
    lookupItems: null,
    showItems: false,
    searchText: ''
  };

  componentDidMount() {
    this.inputRef = createRef();

    let controlType = this.props.column.colDef.ControlType;

    let initialType = null;
    if (["TextBox", "RichTextBox"].includes(controlType))
      initialType = 'contains';

    if (["NumberBox", "Number"].includes(controlType))
      initialType = 'equals';

    this.props.parentFilterInstance((instance) => {
      instance.initialType(
        initialType
      );
    });

    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('mouseup', this.mouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.removeEventListener('mouseup', this.mouseUp);
  }

  mouseUp = (e) => {
    if (this.state.showItems && !helpers.elementOrAncestorHasClass(e.target, 'ag-table-lookup-container')) {
      if (!helpers.elementOrAncestorHasClass(e.target, 'filter-options-link')) {
        this.setState({ showItems: false });
      }
    }
  }

  keyPress = (e) => {
    if (e.key === 'Escape' && this.state.showItems) {
      this.setState({ showItems: false });
    }
  }

  onParentModelChanged(parentModel) {
    if (!parentModel) {
      this.setState({ currentValue: null },
        () => {
          if (this.inputref && this.inputref.current) {
            this.inputRef.current.value = this.state.currentValue
          }
        }
      );
    } else {
      this.setState({ currentValue: parentModel },
        () => {
          if (this.inputref && this.inputref.current) {
            this.inputRef.current.value = this.state.currentValue
          }
        }
      );
    }
  }

  onChange = (event) => {
    this.setState({ currentValue: event.target.value }, () => {
      if (!this.state.currentValue) {
        // Remove the filter
        this.props.parentFilterInstance((instance) => {
          instance.valueFromFloatingFilter(null);
        });
        return;
      }

      this.props.parentFilterInstance((instance) => {
        instance.valueFromFloatingFilter(
          this.state.currentValue
        );
      });
    });
  };

  filterTypeChanged = (e) => {
    this.props.parentFilterInstance((instance) => {
      instance.filterTypeChanged(
        e.target.value
      );
    });
  }

  setCheck = (prop) => {
    this.setState({
      checkbox: {
        ...this.state.checkbox,
        [prop]: !this.state.checkbox[prop]
      }
    }, () => {
      this.props.parentFilterInstance((instance) => {
        instance.valueFromFloatingFilter({ checkbox: this.state.checkbox });
      });
    });
  }

  toggleItem = (lookupId) => {
    let items = this.getLookupItems();
    let item = items.find(x => x.LookupItemId === lookupId);
    item.Active = !item.Active;
    if (item.LookupItemId === -1) {
      items.forEach(x => x.Active = item.Active);
    }
    this.setState({ lookupItems: [...this.state.lookupItems] })
    this.props.parentFilterInstance((instance) => {
      let lookupIds = this.state.lookupItems.filter(x => x.Active).map(x => x.LookupItemId);
      let blank = this.state.lookupItems.find(x => x.Name1 === '');
      lookupIds.blanks = blank && blank.Active;

      instance.valueFromFloatingFilter({ lookups: lookupIds });
    });
  }

  getLookupItems = () => {
    let result = [];
    if (!this.state.lookupItems) {
      let items = helpers.getLookupItems(this.props.column.colDef.LookupSetId).map((x) => { return { ...x, Active: true } });
      items.unshift({ Name1: '(Toggle All)', Active: true, LookupItemId: -1 });
      this.setState({
        lookupItems: items
      });
      result = items;
    } else {
      result = this.state.lookupItems;
    }

    return result.filter(x => (!x.Name1 || x.Name1[0] === '(') || (x.Name1 || '').toLowerCase().startsWith(this.state.searchText.toLowerCase()))
  }

  getLookupSummaryText = () => {
    return this.state.showItems ? 'Hide Filter' : 'Show Filter';
  }

  toggleFilterDropdown = () => {
    this.setState({ showItems: !this.state.showItems });
  }

  searchChange = (e) => {
    this.setState({ searchText: e.target.value });
  }

  render() {
    let controlType = this.props.column.colDef.ControlType;

    return (
      <Fragment>
        {["TextBox", "RichTextBox"].includes(controlType) &&
          <Fragment>
            <select className="agtable-filter-type" onChange={this.filterTypeChanged}>
              <option value="contains">C  - Contains</option>
              <option value="startswith">SW - Starts With</option>
              <option value="endswith">EW - Ends With</option>
            </select>
            <input
              ref={this.inputRef}
              onInput={this.onChange}
              type="text"
            />
          </Fragment>
        }
        {["NumberBox", "Number"].includes(controlType) &&
          <Fragment>
            <select className="agtable-filter-type" onChange={this.filterTypeChanged}>
              <option value="equals">E  - Equals</option>
              <option value="greaterthan">GT - Greater Than</option>
              <option value="lessthan">LT - Less Than</option>
            </select>
            <input
              ref={this.inputRef}
              onInput={this.onChange}
              type="text"
            />
          </Fragment>
        }
        {/* {controlType === "CheckBox" &&
          <Fragment>
            <div className="ag-table-checkbox">
              <div className="ag-table-checkbox-item" onClick={(e) => { this.setCheck(e, 'true') }}>
                <div>true</div>
                <input type="checkbox" checked={this.state.checkbox.true} />
              </div>
              <div className="ag-table-checkbox-item" onClick={(e) => { this.setCheck(e, 'false') }}>
                <div>false</div>
                <input type="checkbox" checked={this.state.checkbox.false} />
              </div>
              <div className="ag-table-checkbox-item" onClick={(e) => { this.setCheck(e, 'blank') }}>
                <div>blank</div>
                <input type="checkbox" checked={this.state.checkbox.blank} />
              </div>
            </div>
          </Fragment>
        } */}
        {controlType === "CheckBox" &&
          <Fragment>
            <div className={"ag-table-lookup" + (this.state.showItems ? '' : ' hidden')}>
              <div className="filter-options-link" onClick={this.toggleFilterDropdown}>Filter Options</div>
              <div className="ag-table-lookup-container">
                <div className="ag-table-lookup-item" onClick={() => { this.setCheck('blank') }}>
                  <input type="checkbox" checked={this.state.checkbox.blank}></input>
                  <div>{'(Empty)'}</div>
                </div>
                <div className="ag-table-lookup-item" onClick={() => { this.setCheck('true') }}>
                  <input type="checkbox" checked={this.state.checkbox.true}></input>
                  <div>True</div>
                </div>
                <div className="ag-table-lookup-item" onClick={() => { this.setCheck('false') }}>
                  <input type="checkbox" checked={this.state.checkbox.false}></input>
                  <div>False</div>
                </div>
              </div>
            </div>
          </Fragment>
        }
        {
          controlType === "DropDown" &&
          <Fragment>
            <div className={"ag-table-lookup" + (this.state.showItems ? '' : ' hidden')}>
              {/* <div className="filter-options-link" onClick={this.toggleFilterDropdown}>Filter Options</div> */}
              <input type="text" value={this.state.searchText} onChange={this.searchChange} onClick={this.toggleFilterDropdown} placeholder="Option Filter" />
              <div className="ag-table-lookup-container">
                {this.getLookupItems().map((item, idx) => (
                  <div key={item.LookupItemId} className="ag-table-lookup-item" onClick={() => { this.toggleItem(item.LookupItemId) }}>
                    <input type="checkbox" checked={item.Active}></input>
                    <div>{item.Name1 || '(Empty)'}</div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        }
      </Fragment>
    );
  }
}