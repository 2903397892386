import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import * as icons from '../../../Icons/Icons';
import { Object } from 'ol';

const mapStateToProps = (state, ownProps) => {
  if (!state.FieldMenu) {
    state.FieldMenu = 'Home';
  }

  return {
    Menu: state.met_EntityMetadata ? state.met_EntityMetadata.FieldMenu : null,
    Scenes: state.blu_Scene,
    SceneKey: 'FieldContent',
    FieldMenu: state.FieldMenu,
    SelectedEntity: state.ent_Selected,
    SelectedEvent: state.ent_SelectedEvent
  };
}
//minmax(200px, 1fr) 6fr
export class FieldMenu extends React.PureComponent {
  state = {
    ParentId: null,
    ForwardId: null,
    Expand: false,
    MenuItems: {},
    FieldMenuId: null,
    Init: false
  }

  componentDidMount() {
  }

  componentDidUpdate(prev) {
    if (this.props.Menu && !this.state.Init) {
      this.setState({ Init: true }, () => {
        this.SetMenuOptions();
      });
    }

    if (prev && prev.FieldMenu !== this.props.FieldMenu) {
      this.SelectItem(null);
    }

    if (prev && helpers.entId(prev.SelectedEntity) !== helpers.entId(this.props.SelectedEntity)) {
      this.SetMenu('Asset');
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    }

    if (prev && this.props.SelectedEvent && helpers.entId(prev.SelectedEvent) !== helpers.entId(this.props.SelectedEvent)) {
      this.SetMenu('Event');
    }
  }

  SetMenuOptions = () => {
    let optMap = this.props.Menu.filter(x => !x.ParentId).reduce((prev, next) => {
      if (!prev[next.Menu]) {
        prev[next.Menu] = [];
      }

      prev[next.Menu].push(next);
      prev[next.Menu].push(...this.props.Menu.filter(x => x.ParentId === next.FieldMenuId));

      return prev;
    }, {});

    this.setState({ MenuItems: optMap }, () => { this.SelectItem(null) });
  }

  SelectItem = (menuId) => {
    let item = this.state.MenuItems[this.props.FieldMenu].find(x => (!menuId || x.FieldMenuId === menuId));
    let parentId = null;
    if (!item.SceneId) {
      let child = this.state.MenuItems[this.props.FieldMenu].find(x => x.ParentId === item.FieldMenuId);
      parentId = item.FieldMenuId;
      item = child;
    }

    if (item) {
      this.SetScene(item.SceneId);
      this.setState({
        FieldMenuId: item.FieldMenuId,
        ParentId: parentId
      });
    }
  }

  SetScene = (sceneId) => {
    let scene = this.props.Scenes.find(x => x.SceneId === sceneId);
    if (!scene)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.SceneKey,
      Value: {
        Value: scene.Name,
        Enabled: true
      }
    }));
  }

  SetMenu = (menu) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'FieldMenu',
      Value: menu
    }));
  }

  ToggleExpand = () => {
    this.setState({ Expand: !this.state.Expand }, () => {
      let siteScene = document.getElementsByClassName('site-scene')[0];
      siteScene.classList.remove('expand');
      if (this.state.Expand) {
        siteScene.classList.add('expand');
      }
    });
  }


  render() {
    let items = this.state.MenuItems[this.props.FieldMenu];
    if (!items)
      return (null);

    return (
      <div className="field-menu-widget">
        {
          <div className={"field-menu-options " + (this.state.Expand ? 'expand' : 'collapsed')}>
            {items.map((x) => (
              <div key={x.FieldMenuId}
                className={"field-menu-item" +
                  (x.ParentId ? ' child-item' : '') +
                  (this.state.FieldMenuId === x.FieldMenuId ? ' selected' : '') +
                  (this.state.ParentId === x.FieldMenuId ? ' child-selected' : '')}
                onClick={() => { this.SelectItem(x.FieldMenuId) }}>
                <div>{x.Name}</div>
              </div>
            ))}
          </div>
        }
        {
          <div className={"field-menu-handle " + (this.state.Expand ? 'expand' : 'collapsed')} onClick={this.ToggleExpand}>
            <div>{this.state.Expand ? '‹' : '›'} </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(FieldMenu);


