import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let mapSwid = ownProps.widget.Parameters.find(x => x.ParameterName === 'MapSWID');

  return {
    Metadata: state.met_EntityMetadata,
    CategoryEntity: state.ent_311Category,
    CategoryNextButton: state.btn_CategoryNext,
    Location: state.map_SetLocation,
    MapFeatures: state.map_Features || {},
    MapMove: state.map_MoveToLocation || {},
    Search: state.ent_Search,
    UserLoc: state.UserLocation,
    MapSwid: mapSwid ? mapSwid.ParameterValue : undefined,
    SiteNav: state.ent_SiteNav,
    SaveButton: state.btn_SaveIssue,
    PhotoData: state.ent_PhotoData,
    SaveData: state.dbo_SaveData,
    NewAssetKey: state.ent_NewAssetKey,
    CenterPoint: state.ent_CenterPointGeo
  };
};

export class E311 extends React.PureComponent {
  initialized = false;
  setLoc = false;
  componentDidMount() {
    // this.createButtons();
  }

  componentDidUpdate(prev) {
    if (!this.initialized && this.props.Metadata && Object.keys(this.props.Metadata).length > 0) {
      this.init();
      this.initialized = true;
    }

    if ((this.props.Location && (!prev || !prev.Location))
      || (this.props.Location && prev && prev.Location && this.props.Location.ClickId !== prev.Location.ClickId)) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'map_Features',
        Value: {
          ...cloneDeep(this.props.MapFeatures),
          [this.props.MapSwid ? Number(this.props.MapSwid) : 324]: ['Centerpoint']
        }
      }));
    }

    if (this.props.SiteNav) {
      let searchProp = this.props.SiteNav.Properties.find(x => x.Name === 'SearchText');
      if (searchProp && searchProp.TypeaheadItem && !searchProp.TypeaheadItem.Processed) {
        searchProp.TypeaheadItem.Processed = true;
        this.moveTo(searchProp.TypeaheadItem.Latitude, searchProp.TypeaheadItem.Longitude, 1000);
      }
    }

    if ((this.props.SaveButton && !prev.SaveButton) || (prev.SaveButton && this.props.SaveButton.ClickId !== prev.SaveButton.ClickId)) {
      this.saveIssue();
    }

    if (!this.props.CenterPoint && prev.CenterPoint) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'map_Features',
        Value: {
          ...cloneDeep(this.props.MapFeatures),
          [this.props.MapSwid ? Number(this.props.MapSwid) : 324]: []
        }
      }));
    }

    if (this.props.UserLoc && !this.setLoc) {
      this.setLoc = true;
      let loc = this.props.UserLoc;
      this.moveTo(loc.Latitude, loc.Longitude, 0);
    }
  }

  saveIssue = () => {
    let formData;
    let saveData = this.props.SaveData;
    let eiKey = saveData.find(x => x.Table === 'xtn.E311_IE');
    let id = eiKey ? eiKey.InsertKey : null;

    if (this.props.PhotoData && this.props.PhotoData.formData) {
      formData = this.props.PhotoData.formData;
      formData.append("HasDocument", 'true');
      saveData.push({
        Id: "!!NEWDOCID!!",
        Table: 'doc.Document',
        Column: 'EntityId',
        Value: id,
        InsertKey: null,
        IsBaseTable: 1
      });
    } else {
      formData = new FormData();
      formData.append("HasDocument", 'false');
    }

    saveData.push({
      Id: id,
      Table: 'xtn.E311_IE',
      Column: 'Name',
      Value: "!!HEXNAME!!",
      InsertKey: null,
      IsBaseTable: 1
    });

    if (this.props.CenterPoint) {
      saveData.push({
        Id: id,
        Table: 'xtn.E311_IE',
        Column: 'Geometry',
        Value: this.props.CenterPoint,
        InsertKey: null,
        IsBaseTable: 1
      });
    }

    formData.append("SaveData", JSON.stringify(saveData));

    actions.ApiSendData('Public311/SaveIssue', formData, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_IssueNumber',
        Value: result
      }));
    });
  }

  moveTo = (lat, lon, delay) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'map_MoveToLocation',
      Value: {
        ...cloneDeep(this.props.MapMove),
        [this.props.MapSwid ? Number(this.props.MapSwid) : 324]: { Latitude: lat, Longitude: lon, Delay: delay }
      }
    }));
  }

  init = () => {
    // this.createCategoryEntity();
    // this.createSearchEntity();
    // this.createFormEntity();
  }

  createButtons = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_CategoryNext',
      Value: {
        ClickId: 0,
        Enabled: false,
        Value: ''
      }
    }));
  }

  createCategoryEntity = () => {
    let ent_311Category = {
      GridColumns: '1',
      Properties: [
        {
          Name: 'MajorCategory',
          Label: 'Choose a Major Category...',
          ControlType: 'DropDownMobile',
          LookupSetId: 1109,
          Value: 0,
          DefaultValue: 0,
          FocusIndex: 1,
          FormData: [{
            FormName: '',
            FormColumn: '1',
            FormRow: '1'
          }]
        },
        {
          Name: 'SubCategory',
          Label: 'Choose a Sub Category...',
          ControlType: 'DropDownMobile',
          ParentLookup: 'MajorCategory',
          LookupSetId: 1110,
          Value: 0,
          DefaultValue: 0,
          FocusIndex: 2,
          FormData: [{
            FormName: '',
            FormColumn: '1',
            FormRow: '2'
          }]
        }
      ]
    };

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_311Category',
      Value: ent_311Category
    }));
  }

  createSearchEntity = () => {
    let ent_Search = {
      GridColumns: '1',
      Properties: [
        {
          Name: 'SearchText',
          Label: '',
          ControlType: 'TextBox',
          Placeholder: 'Map Search',
          LookupSetId: 1109,
          Value: '',
          DefaultValue: 0,
          FormData: [{
            FormName: '',
            FormColumn: '1',
            FormRow: '1'
          }]
        }
      ]
    };

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_Search',
      Value: ent_Search
    }));
  }

  createFormEntity = () => {
    let ent_ShareInfoForm = {
      GridColumns: '1',
      GridRows: '70',
      Properties: [
        {
          Name: 'Name',
          Label: '',
          ControlType: 'TextBox',
          Placeholder: 'Meter Name',
          LookupSetId: null,
          Value: '',
          DefaultValue: '',
          FormData: [{
            FormName: '',
            FormColumn: '1',
            FormRow: '1'
          }]
        },
        {
          Name: 'Endpoint',
          Label: '',
          ControlType: 'TextBox',
          Placeholder: 'Endpoint Number',
          LookupSetId: null,
          Value: '',
          DefaultValue: '',
          FormData: [{
            FormName: '',
            FormColumn: '1',
            FormRow: '2'
          }]
        },
        {
          Name: 'MeterRead',
          Label: '',
          ControlType: 'TextBox',
          Placeholder: 'Meter Read (optional)',
          LookupSetId: null,
          Value: '',
          DefaultValue: '',
          FormData: [{
            FormColumn: '1',
            FormRow: '3',
            FormName: ''
          }]
        },
        {
          Name: 'EndpointRead',
          Label: '',
          ControlType: 'TextBox',
          Placeholder: 'Endpoint Read (optional)',
          LookupSetId: null,
          Value: '',
          DefaultValue: '',
          FormData: [{
            FormName: '',
            FormColumn: '1',
            FormRow: '4'
          }]
        }
      ]
    };

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_ShareInfoForm',
      Value: ent_ShareInfoForm
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(E311);