//Widgets
import SearchBar001 from './Widgets/SearchBar/v001/SearchBar';
import SearchBar002 from './Widgets/SearchBar/v002/SearchBar';
import CardList001 from './Widgets/CardList/v001/CardList';
import CardList002 from './Widgets/CardList/v002/CardList';
import Form001 from './Widgets/Form/v001/Form';
import Button001 from './Widgets/Button/v001/Button';
import SubScene001 from './Widgets/SubScene/v001/SubScene';
import Skull001 from './Widgets/Skull/v001/Skull';
import DeviceInfo001 from './Widgets/DeviceInfo/v001/DeviceInfo';
import Text001 from './Widgets/Text/v001/Text';
import QueryGrid001 from './Widgets/QueryGrid/v001/QueryGrid'
import QueryGridTitle001 from './Widgets/QueryGridTitle/v001/QueryGridTitle';
import Map001 from './Widgets/Map/v001/Map';
import Document001 from './Widgets/Document/v001/Document';
import EventGrid001 from './Widgets/EventGrid/v001/EventGrid';
import EventDialog001 from './Widgets/EventDialog/v001/EventDialog';
import Resources001 from './Widgets/Resources/v001/Resources';
import ResourcesMobile001 from './Widgets/ResourcesMobile/v001/ResourcesMobile';
import Resources002 from './Widgets/Resources/v002/Resources';
import DateTimeBar001 from './Widgets/DateTimeBar/v001/DateTimeBar';
import WeatherBar001 from './Widgets/WeatherBar/v001/WeatherBar';
import UserInfo001 from './Widgets/UserInfo/v001/UserInfo';
import Image001 from './Widgets/Image/v001/Image';
import WidgetSelector001 from './Widgets/WidgetSelector/v001/WidgetSelector';
import ModalHeader001 from './Widgets/ModalHeader/v001/ModalHeader';
import Carousel001 from './Widgets/Carousel/v001/Carousel';
import PhotoPicker001 from './Widgets/PhotoPicker/v001/PhotoPicker';
import MobileLog001 from './Widgets/MobileLog/v001/MobileLog';
import InfoCard001 from './Widgets/InfoCard/v001/InfoCard';
import ReportLoader001 from './Widgets/ReportLoader/v001/ReportLoader';
import Timesheet001 from './Widgets/Timesheet/v001/Timesheet';
import TimeBankCards001 from './Widgets/TimeBankCards/v001/TimeBankCards';
import LeaveRequest001 from './Widgets/LeaveRequest/v001/LeaveRequest';
import Grid001 from './Widgets/Grid/v001/Grid';
import TimeBanks001 from './Widgets/TimeBanks/v001/TimeBanks'
import TenantDisplay001 from './Widgets/TenantDisplay/v001/TenantDisplay';
import QueryLayout001 from './Widgets/QueryLayout/v001/QueryLayout';
import AgGrid001 from './Widgets/AgGrid/v001/AgGrid';
import AgGrid002 from './Widgets/AgGrid/v002/AgGrid';
import AgGrid003 from './Widgets/AgGrid/v003/AgGrid';
import AccountToggle001 from './Widgets/AccountToggle/v001/AccountToggle';
import HoverInfo001 from './Widgets/HoverInfo/v001/HoverInfo';
import AgChart001 from './Widgets/AgChart/v001/AgChart';
import Control001 from './Widgets/Control/v001/Control';
import RecurScheduleForm001 from './Widgets/RecurScheduleForm/v001/RecurScheduleForm';
import RecurScheduleForm002 from './Widgets/RecurScheduleForm/v002/RecurScheduleForm';
import Recurrence001 from './Widgets/Recurrence/v001/Recurrence';
import Recurrence002 from './Widgets/Recurrence/v002/Recurrence';
import UserActivity001 from './Widgets/UserActivity/v001/UserActivity';
import KanbanBoard001 from './Widgets/KanbanBoard/v001/KanbanBoard';
import EmailDropper from './Widgets/EmailDropper/v001/EmailDropper';
import DatePicker001 from './Widgets/DatePicker/v001/DatePicker';
import Wiki001 from './Widgets/Wiki/v001/Wiki';
import ImageCatalog001 from './Widgets/ImageCatalog/v001/ImageCatalog';
import FieldMenu001 from './Widgets/FieldMenu/v001/FieldMenu';
import AgTable001 from './Widgets/AgTable/v001/AgTable';
import AgTable002 from './Widgets/AgTable/v002/AgTable';
import JsonForm001 from './Widgets/JsonForm/v001/JsonForm';
import Template001 from './Widgets/Template/v001/Template';
import TemplateEditor001 from './Widgets/TemplateEditor/v001/TemplateEditor';
import DBManager001 from './Widgets/DBManager/v001/DBManager';
import MSPasswordChange001 from './Widgets/MSPasswordChange/v001/MSPasswordChange';
import UBAdminGroupEdit from './Widgets/UBAdminGroupEdit/UBAdminGroupEdit';

//Engines
import Save001 from './Engines/Save/v001/Save';
import Cancel001 from './Engines/Cancel/v001/Cancel';
import New001 from './Engines/New/v001/New';
import Delete001 from './Engines/Delete/v001/Delete';
import EntityMetadata001 from './Engines/EntityMetadata/v001/EntityMetadata';
import Editor001 from './Engines/Editor/v001/Editor';
import E311001 from './Engines/E311/v001/E311';
import Workflow001 from './Engines/Workflow/v001/Workflow';
import TypeaheadApi001 from './Engines/TypeaheadApi/v001/TypeaheadApi';
import GooGoo001 from './Engines/GooGoo/v001/GooGoo';
import SubmitTimesheet001 from './Engines/TimeTracking/001/SubmitTimesheet';
import UnsubmitTimesheet001 from './Engines/TimeTracking/001/UnsubmitTimesheet';
import ApproveTimesheet001 from './Engines/TimeTracking/001/ApproveTimesheet';
import AdminUnsubmit001 from './Engines/TimeTracking/001/AdminUnsubmit';
import UnapproveTimesheet001 from './Engines/TimeTracking/001/UnapproveTimesheet';
import LeaveRequestAdmin001 from './Engines/TimeTracking/001/LeaveRequestAdmin';
import WageCodes001 from './Engines/TimeTracking/001/WageCodes';
import EntitySceneSwitcher001 from './Engines/EntitySceneSwitcher/v001/EntitySceneSwitcher';
import Submit001 from './Engines/Submit/v001/Submit';
import Entity001 from './Engines/Entity/v001/Entity'
import ScriptRunner001 from './Engines/ScriptRunner/v001/ScriptRunner';
import GetList001 from './Engines/GetList/v001/GetList';
import UBJournalEntry001 from './Widgets/UBJournalEntry/v001/UBJournalEntry';
import ADLMonitor001 from './Engines/ADLMonitor/v001/ADLMonitor';
import Integration001 from './Engines/Integration/v001/Integration';

export default {
  SearchBar: {
    v001: SearchBar001,
    v002: SearchBar002
  },
  CardList: {
    v001: CardList001,
    v002: CardList002
  },
  Form: {
    v001: Form001
  },
  Button: {
    v001: Button001
  },
  SubScene: {
    v001: SubScene001
  },
  Save: {
    v001: Save001
  },
  Cancel: {
    v001: Cancel001
  },
  New: {
    v001: New001
  },
  Delete: {
    v001: Delete001
  },
  Skull: {
    v001: Skull001
  },
  EntityMetadata: {
    v001: EntityMetadata001
  },
  Editor: {
    v001: Editor001
  },
  E311: {
    v001: E311001
  },
  Workflow: {
    v001: Workflow001
  },
  TypeaheadApi: {
    v001: TypeaheadApi001
  },
  DeviceInfo: {
    v001: DeviceInfo001
  },
  Text: {
    v001: Text001
  },
  QueryGrid: {
    v001: QueryGrid001
  },
  QueryGridTitle: {
    v001: QueryGridTitle001
  },
  Map: {
    v001: Map001
  },
  Document: {
    v001: Document001
  },
  EventGrid: {
    v001: EventGrid001
  },
  EventDialog: {
    v001: EventDialog001
  },
  Resources: {
    v001: Resources001,
    v002: Resources002
  },
  DateTimeBar: {
    v001: DateTimeBar001
  },
  WeatherBar: {
    v001: WeatherBar001
  },
  UserInfo: {
    v001: UserInfo001
  },
  Image: {
    v001: Image001
  },
  WidgetSelector: {
    v001: WidgetSelector001
  },
  ResourcesMobile: {
    v001: ResourcesMobile001
  },
  ModalHeader: {
    v001: ModalHeader001
  },
  Carousel: {
    v001: Carousel001
  },
  PhotoPicker: {
    v001: PhotoPicker001
  },
  MobileLog: {
    v001: MobileLog001
  },
  InfoCard: {
    v001: InfoCard001
  },
  ReportLoader: {
    v001: ReportLoader001
  },
  GooGoo: {
    v001: GooGoo001
  },
  Timesheet: {
    v001: Timesheet001
  },
  TimeBankCards: {
    v001: TimeBankCards001
  },
  LeaveRequest: {
    v001: LeaveRequest001
  },
  Grid: {
    v001: Grid001
  },
  SubmitTimesheet: {
    v001: SubmitTimesheet001
  },
  UnsubmitTimesheet: {
    v001: UnsubmitTimesheet001
  },
  ApproveTimesheet: {
    v001: ApproveTimesheet001
  },
  AdminUnsubmit: {
    v001: AdminUnsubmit001
  },
  UnapproveTimesheet: {
    v001: UnapproveTimesheet001
  },
  TimeBanks: {
    v001: TimeBanks001
  },
  LeaveRequestAdmin: {
    v001: LeaveRequestAdmin001
  },
  WageCodes: {
    v001: WageCodes001
  },
  EntitySceneSwitcher: {
    v001: EntitySceneSwitcher001
  },
  TenantDisplay: {
    v001: TenantDisplay001
  },
  QueryLayout: {
    v001: QueryLayout001
  },
  AgGrid: {
    v001: AgGrid001,
    v002: AgGrid002,
    v003: AgGrid003
  },
  AccountToggle: {
    v001: AccountToggle001
  },
  Submit: {
    v001: Submit001
  },
  Entity: {
    v001: Entity001
  },
  ScriptRunner: {
    v001: ScriptRunner001
  },
  GetList: {
    v001: GetList001
  },
  HoverInfo: {
    v001: HoverInfo001
  },
  AgChart: {
    v001: AgChart001
  },
  Control: {
    v001: Control001
  },
  RecurScheduleForm: {
    v001: RecurScheduleForm001,
    v002: RecurScheduleForm002
  },
  Recurrence: {
    v001: Recurrence001,
    v002: Recurrence002
  },
  UBJournalEntry: {
    v001: UBJournalEntry001
  },
  UserActivity: {
    v001: UserActivity001
  },
  KanbanBoard: {
    v001: KanbanBoard001
  },
  EmailDropper: {
    v001: EmailDropper
  },
  DatePicker: {
    v001: DatePicker001
  },
  Wiki: {
    v001: Wiki001
  },
  ImageCatalog: {
    v001: ImageCatalog001
  },
  FieldMenu: {
    v001: FieldMenu001
  },
  AgTable: {
    v001: AgTable001,
    v002: AgTable002
  },
  JsonForm: {
    v001: JsonForm001
  },
  ADLMonitor: {
    v001: ADLMonitor001
  },
  Template: {
    v001: Template001
  },
  TemplateEditor: {
    v001: TemplateEditor001
  },
  DBManager: {
    v001: DBManager001
  },
  MSPasswordChange: {
    v001: MSPasswordChange001
  },
  Integration: {
    v001: Integration001
  },
  UBAdminGroupEdit: {
    v001: UBAdminGroupEdit
  }
};
