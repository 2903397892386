import React from 'react';
import { connect, batch } from 'react-redux';
import ColumnLabel from './ColumnLabel';
import ColumnFilter from './ColumnFilter';
import ReactDOM from 'react-dom';
import * as actions from './../../../../../Stores/Actions/actions';


function mapStateToProps(state, ownProps) {
  return {
    settings: state.qry_Queries[ownProps.queryId][ownProps.colId],
    refreshPage: state.qry_RefreshCurrentPage,
    sortBy: state.qry_Queries[ownProps.queryId].sortBy,
    groupByHover: state.qry_GroupByHover || false,
    groupedColumns: state.qry_GroupedColumns
  };
}

export class Column extends React.PureComponent {
  isClicked = false;
  myElement = null;
  hoverMove;
  dragging = false;
  cancelSortBy = false;

  state = {
    offset: { x: 0, y: 0 }
  }

  componentDidMount() {
    this.myElement = ReactDOM.findDOMNode(this).getElementsByClassName('draggable')[0];
    this.props.settings.screenX = this.myElement.getBoundingClientRect().x;

    this.myElement.addEventListener('mousedown', this.clickDown);
    window.addEventListener('mouseup', this.clickUp);
  }

  clickDown = (e) => {
    this.isClicked = true;
    let classes = e.target.classList;
    if (!classes.contains('column-label') && !classes.contains('label-text'))
      return;

    this.dragging = true;
    this.props.settings.initialPos = { x: e.screenX, y: e.screenY };
    window.addEventListener('mousemove', this.dragMove);
  }

  clickUp = (e) => {
    if (this.isClicked) {
      this.isClicked = false;
      this.dragging = false;
      this.setOffset({ x: 0, y: 0 });
      this.setGroupByHover(e, true);
      window.removeEventListener('mousemove', this.dragMove);
      if (this.hoverMove !== undefined) {
        this.props.dispatch(actions.MoveGridColumn(this.hoverMove));
        this.hoverMove = undefined;
      }
    }
  }

  dragMove = (e) => {
    e.preventDefault();
    this.setGroupByHover(e);

    let offset = {
      x: e.screenX - this.props.settings.initialPos.x,
      y: e.screenY - this.props.settings.initialPos.y + (this.props.groupByHover ? -15 : 0),
    };

    this.setOffset(offset);

    if (offset.x > 5 || offset.y > 5) {
      this.cancelSortBy = true;
    }

    let settings = this.props.settings;
    let direction = offset.x > 0 ? 1 : -1;
    let halfWidth = Math.floor(this.props.settings.Width / 2);
    let xDif = offset.x - (halfWidth * direction);

    if ((xDif > 0 && direction < 0) || (xDif < 0 && direction > 0)) {
      xDif = 0;
    }

    let colId = this.props.setHoverColumn(settings.ColumnId, xDif, settings.IsPinned);
    if (colId !== undefined) {
      this.hoverMove = {
        colId: this.props.settings.ColumnId,
        moveToId: colId
      };
    } else {
      this.hoverMove = undefined;
    }
  }

  setGroupByHover = (e, addCol = false) => {
    let hoverElement = document.elementFromPoint(e.clientX, e.clientY)
    let groupByHover = false;

    if (hoverElement) {
      groupByHover = hoverElement.classList.contains('group-by');
    }

    if (this.props.groupByHover !== groupByHover) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_GroupByHover',
        Value: groupByHover
      }));
    }
    if (addCol && groupByHover) {
      let existing = this.props.groupedColumns || {};
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_GroupedColumns',
        Value: {
          ...existing,
          [this.props.queryId]: [this.props.settings]
        }
      }));
    }
  }

  setOffset = (offset) => {
    this.setState({ offset: offset });
    this.props.settings.offset = offset;
  }

  setWidth = (width) => {
    this.props.settings.Width = width;
    this.props.dispatch(actions.UpdateColumnWidth({
      colId: this.props.colId,
      width: width,
    }));
  }

  componentWillUnmount() {
    this.myElement.removeEventListener('mousedown', this.clickDown);
    this.myElement.removeEventListener('mouseup', this.clickUp);
    window.removeEventListener('mousemove', this.dragMove);
  }

  setSortBy = () => {
    console.log('setsortby called');
    if (this.cancelSortBy) {
      this.cancelSortBy = false;
      return;
    }

    batch(() => {
      this.props.dispatch(actions.ToggleSortBy(this.props.colId));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_RefreshCurrentPage',
        Value: this.props.refreshPage + 1
      }));
    });
  }

  render() {
    let setting = this.props.settings;
    let sorted = this.props.sortBy === setting.TableName + '.' + setting.ColumnName;
    let sortedDesc = this.props.sortBy === setting.TableName + '.' + setting.ColumnName + ' DESC';
    return (
      <div className={
        'header-column ' +
        (this.dragging ? ' dragging ' : '') +
        (sorted ? ' sorted ' : '') +
        (sortedDesc ? ' sorted-desc ' : '') +
        (!this.props.pinned ? ' unpinned-header-col ' : '')
      }
        style={{ minWidth: this.props.settings.Width, maxWidth: this.props.settings.Width }} >
        <div className='draggable' style={{ marginTop: this.state.offset.y, marginLeft: this.state.offset.x }}>
          <ColumnLabel settings={this.props.settings} setWidth={this.setWidth} setSortBy={this.setSortBy} sorted={sorted || sortedDesc} />
          <ColumnFilter settings={this.props.settings} />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Column);