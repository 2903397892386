

import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { batch } from 'react-redux';

export const fileAttached = (e, sproc) => {
  // if (!e.path || !e.path[0])
  //   return;

  // let file = e.path[0].files[0];
  // if (!file)
  //   return;

  let file = e.target.files[0];
  if (!file)
    return;

  let ext = file.name.split('.').pop();

  if (!['json', 'geojson'].includes(ext.toLowerCase()))
    return;

  let formData = new FormData();
  formData.append('geoJsonImport_' + file.name, file, file.name);
  formData.append('FileType', file.type);
  formData.append('Extension', ext);
  formData.append('DocumentId', 'NewDocument');
  formData.append("DocDate", new Date().toISOString().slice(0, 10));
  formData.append('Sproc', sproc);

  actions.ApiSendData('Document/GeoJsonImport', formData, (result) => {
    console.log({ GeoJsonImportResult: result });
  });
}
