
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import xyz from 'ol/source/XYZ';

const AzureLayer = (params) => {
  if (!params.Path) {
    console.log('Azure layer needs Path defined');
    return;
  }
  let key = params.AzureKey;
  let tilesetId = params.Path;
  let language = 'en-us';
  let view = 'Auto';

  let url = `https://atlas.microsoft.com/map/tile?subscription-key=${key}&api-version=2.0&tilesetId=${tilesetId}&zoom={z}&x={x}&y={y}&tileSize=256&language=${language}&view=${view}`;

  let pieces = params.Path.split('.');
  if (params.Path.startsWith('microsoft.traffic.flow')) {
    url = `https://atlas.microsoft.com/traffic/flow/tile/png?subscription-key=${key}&api-version=1.0&style=${pieces[pieces.length - 1]}&zoom={z}&x={x}&y={y}`;
  } else if (params.Path.startsWith('microsoft.traffic.incident')) {
    url = `https://atlas.microsoft.com/traffic/incident/tile/png?subscription-key=${key}&api-version=1.0&style=${pieces[pieces.length - 1]}&zoom={z}&x={x}&y={y}`;
  }


  return new TileLayer({
    source: new xyz({
      url: url,
      attributions: `© ${new Date().getFullYear()} TomTom, Microsoft`
    })
  })
}

export default {
  azureLayer: AzureLayer
}
