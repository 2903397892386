import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import { MagnifyingGlass, RefreshArrow } from '../../../../Icons/Icons';
import { isConsole } from 'react-device-detect';
import FullEditSidebar from './FullEditSidebar';

const mapStateToProps = (state, ownProps) => {
  return {
    EditingScene: state.EditingScene,
    EditingWidget: state.EditingWidget,
    Scenes: state.blu_Scene,
    WidgetList: state.blu_WidgetList,
    SceneRows: state.EditingScene ? state.EditingScene.Rows : 0,
    SceneCols: state.EditingScene ? state.EditingScene.Columns : 0,
    FindWidget: state.FindWidget,
    Workflows: state.wkf_Workflows || [],
    ShowWidgetDetails: state.dbo_ShowWidgetDetails,
    FullEditMode: state.FullEditMode
  };
};

export class SideBar extends React.PureComponent {
  saving = false;

  state = {
    btnText: 'Save',
    createMode: false,
    newWidget: {},
    copySwid: ''
  }

  setSceneRows = (e) => {
    this.props.EditingScene.Rows = e.target.value;
    this.props.dispatch(actions.UpdateScene());
  }

  setSceneColumns = (e) => {
    this.props.EditingScene.Columns = e.target.value;
    this.props.dispatch(actions.UpdateScene());
  }

  setWidgetRows = (e) => {
    this.props.EditingWidget.Rows = e.target.value;
    this.props.dispatch(actions.UpdateScene());
  }

  setWidgetColumns = (e) => {
    this.props.EditingWidget.Columns = e.target.value;
    this.props.dispatch(actions.UpdateScene());
  }

  setWidgetDescription = (e) => {
    this.props.EditingWidget.Description = e.target.value;
    this.props.dispatch(actions.UpdateScene());
  }

  setWidgetVersion = (e) => {
    this.props.EditingWidget.WidgetVersion = e.target.value;
    this.props.dispatch(actions.UpdateScene());
  }

  setParameterName = (e, param) => {
    param.ParameterName = e.target.value;
    this.props.dispatch(actions.UpdateScene());
  }

  setParameterValue = (e, param) => {
    param.ParameterValue = e.target.value;
    this.props.dispatch(actions.UpdateScene());
  }

  addParameter = () => {
    this.props.EditingWidget.Parameters = [
      ...this.props.EditingWidget.Parameters,
      {
        ParameterName: '',
        ParameterValue: '',
        Category: 'Misc',
        ParameterId: -1
      }
    ];
    this.props.dispatch(actions.UpdateScene());
  }

  save = () => {
    if (this.saving)
      return;

    this.saving = true;
    this.setState({ btnText: '...' });
    actions.ApiRequest('Editor/Save', { SaveData: JSON.stringify(this.props.Scenes) }, () => {
      this.props.dispatch(actions.GetScene(() => {
        this.saving = false;
        this.setState({ btnText: 'Save' });
        this.props.dispatch(actions.UpdateScene());
        this.props.dispatch(actions.GlobalRefresh());
      }));
    });
  }

  refresh = () => {
    this.props.dispatch(actions.GetScene(() => {
      this.props.dispatch(actions.UpdateScene());
      this.props.dispatch(actions.GlobalRefresh());
    }));
  }

  toggleFindWidget = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'FindWidget',
      Value: !this.props.FindWidget
    }));
  }

  newWidgetToggle = () => {
    this.setState({ createMode: !this.state.createMode })
  }

  widgetDetailToggle = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'dbo_ShowWidgetDetails',
      Value: !this.props.ShowWidgetDetails
    }));
  }

  newWidgetSceneId = (e) => {
    this.setState({ newWidget: { ...this.state.newWidget, SceneId: Number(e.target.value) } });
  }

  newWidgetWidgetId = (e) => {
    this.setState({ newWidget: { ...this.state.newWidget, WidgetId: Number(e.target.value) } });
  }

  newWidgetColumns = (e) => {
    this.setState({ newWidget: { ...this.state.newWidget, Columns: e.target.value } });
  }

  newWidgetRows = (e) => {
    this.setState({ newWidget: { ...this.state.newWidget, Rows: e.target.value } });
  }

  newWidgetWidgetVersion = (e) => {
    this.setState({ newWidget: { ...this.state.newWidget, WidgetVersion: e.target.value } });
  }

  getScenes = () => {
    let scenes = this.props.Scenes.map(x => {
      return {
        SceneId: x.SceneId,
        Name: x.Name
      }
    });

    scenes.sort((a, b) => {
      return a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0;
    });

    return [{ SceneId: -1, Name: '' }, ...scenes];
  }

  getWidgets = () => {
    let widgets = this.props.WidgetList.map(x => {
      return {
        WidgetId: x.WidgetId,
        Name: x.WidgetName
      }
    });

    return [{ WidgetId: -1, Name: '' }, ...widgets];
  }

  addNewWidget = () => {
    let widget = this.state.newWidget;
    let scene = this.props.Scenes.find(x => x.SceneId === widget.SceneId);
    if (!scene)
      return;

    let widgetItem = this.props.WidgetList.find(x => x.WidgetId === widget.WidgetId);
    if (!widgetItem)
      return;

    scene.Widgets = [...scene.Widgets, {
      WidgetId: widget.WidgetId,
      SceneWidgetId: -1,
      WidgetName: widgetItem.WidgetName,
      Columns: widget.Columns,
      Rows: widget.Rows,
      Description: '',
      WidgetVersion: widget.WidgetVersion || 'v001',
      Parameters: []
    }];

    this.setState({ newWidget: {} });
    this.newWidgetToggle();
    this.props.dispatch(actions.UpdateScene());
  }

  editWorkflow = (item) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'wkf_EditWorkflow',
      Value: cloneDeep(item)
    }));
    this.props.displayCenter(true);
  }

  newWorkflow = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'wkf_EditWorkflow',
      Value: 'new'
    }));
    this.props.displayCenter(true);
  }

  copyFromSwid = () => {
    try {
      let swid = Number(this.state.copySwid);
      let params = [];

      this.props.Scenes.forEach(scene => {
        scene.Widgets.forEach(x => {
          if (x.SceneWidgetId === swid) {
            params = x.Parameters;
          }
        });
      });

      params = params.map((x) => {
        return { ...x, ParameterId: -1 }
      });

      this.props.EditingWidget.Parameters = [
        ...this.props.EditingWidget.Parameters,
        ...params
      ];

      this.props.dispatch(actions.UpdateScene());
    } catch { }

    this.setState({ copySwid: '' });
  }

  setCopySwid = (e) => {
    this.setState({ copySwid: e.target.value });
  }

  render() {
    let scene = this.props.EditingScene;
    let sceneName = scene ? scene.Name : '';
    let widget = this.props.EditingWidget;
    let widgetName = widget ? widget.WidgetName : '';

    return (
      this.props.FullEditMode ? <FullEditSidebar /> :
        <div className="side-bar">
          {
            scene && !this.state.createMode &&
            <div className="side-bar-scene">
              <div className="side-bar-title">
                <div>Scene:</div>
                <div className="title-content">{sceneName}</div>
                <div className="new-widget-btn" onClick={this.newWidgetToggle}>
                  <div>§</div>
                </div>
                <div className={"widget-finder" + (this.props.FindWidget ? ' finding' : '')} onClick={this.toggleFindWidget} >
                  <MagnifyingGlass />
                </div>
              </div>
              <div className="side-bar-prop">
                <div>Rows:</div>
                <input type="text" value={scene.Rows || ''} onChange={this.setSceneRows} />
              </div>
              <div className="side-bar-prop">
                <div>Columns:</div>
                <input type="text" value={scene.Columns || ''} onChange={this.setSceneColumns} />
              </div>
            </div>
          }
          {
            widget && !this.state.createMode &&
            <div className="side-bar-widget">
              <div className="side-bar-title">
                <div>Widget:</div>
                <div className="title-content">{widgetName}</div>
              </div>
              <div className="side-bar-prop">
                <div>{'SWID: ' + widget.SceneWidgetId}</div>
              </div>
              <div className="widget-detail-btn" onClick={this.widgetDetailToggle}>
                <div>☼</div>
              </div>
              <div className="side-bar-prop">
                <div>Rows:</div>
                <input type="text" value={widget.Rows || ''} onChange={this.setWidgetRows} />
              </div>
              <div className="side-bar-prop">
                <div>Columns:</div>
                <input type="text" value={widget.Columns || ''} onChange={this.setWidgetColumns} />
              </div>
              <div className="side-bar-prop">
                <div>Widget Version:</div>
                <select name="widget-version" onChange={this.setWidgetVersion} value={widget.WidgetVersion || 'v001'}>
                  <option value="v001">v001</option>
                  <option value="v002">v002</option>
                  <option value="v003">v003</option>
                </select>
              </div>
              <div className="side-bar-prop">
                <div>Description:</div>
                <textarea type="text" value={widget.Description || ''} onChange={this.setWidgetDescription} />
              </div>
              {
                widget.WidgetName === 'Workflow' &&
                <div className="side-bar-parameters">
                  <div className="parameters-header">
                    <div className="parameters-title">Workflows</div>
                    <div className="workflow-editor-btn" onClick={this.newWorkflow}>
                      <div>New Workflow</div>
                    </div>
                  </div>
                  <div className="parameters-container">
                    {this.props.Workflows.map((x, idx) => (
                      <div key={idx} className="workflow-card" onClick={() => { this.editWorkflow(x) }}>
                        <div className="wf-card-name">{x.Name}</div>
                        <div className="wf-card-desc">{x.Description}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }
              {
                widget.WidgetName !== 'Workflow' &&
                <div className="side-bar-parameters">
                  <div className="parameters-header">
                    <div className="param-add-btn" onClick={this.addParameter}>
                      <div>Add</div>
                    </div>
                    <div className="parameters-title">Parameters</div>
                    <input className="swid-copy-input" type="text" placeholder="swid" value={this.state.copySwid} onChange={this.setCopySwid} />
                    <div className="param-add-btn swid-copy" onClick={this.copyFromSwid}>
                      <div>Copy</div>
                    </div>
                  </div>

                  <div className="parameters-container">
                    {widget.Parameters.map((x, idx) => (
                      <div key={idx} className="parameters-item">
                        <div className="param-input">
                          <div>Key:</div>
                          <input type="text" value={x.ParameterName} onChange={(e) => { this.setParameterName(e, x) }} />
                        </div>
                        <div className="param-input">
                          <div>Value:</div>
                          <input type="text" value={x.ParameterValue} onChange={(e) => { this.setParameterValue(e, x) }} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              }
              <div className="scene-save-refresh-container">
                <div className="scene-save-btn" onClick={this.save}>{this.state.btnText}</div>
                <div className="scene-refresh-btn" onClick={this.refresh}><RefreshArrow /></div>
              </div>
            </div>
          }
          {
            this.state.createMode &&
            <div className="create-widget-pane">
              <div className="create-widget-form">
                <div className="side-bar-prop">
                  <div>Scene:</div>
                  <select value={this.state.newWidget.SceneId} onChange={this.newWidgetSceneId}>
                    {this.getScenes().map((x, idx) => (
                      <option key={idx} value={x.SceneId}>{x.Name}</option>
                    ))}
                  </select>
                </div>
                <div className="side-bar-prop">
                  <div>Widget:</div>
                  <select value={this.state.newWidget.WidgetId} onChange={this.newWidgetWidgetId}>
                    {this.getWidgets().map((x, idx) => (
                      <option key={idx} value={x.WidgetId}>{x.Name}</option>
                    ))}
                  </select>
                </div>
                <div className="side-bar-prop">
                  <div>Rows:</div>
                  <input type="text" value={this.state.newWidget.Rows || ''} onChange={this.newWidgetRows} />
                </div>
                <div className="side-bar-prop">
                  <div>Columns:</div>
                  <input type="text" value={this.state.newWidget.Columns || ''} onChange={this.newWidgetColumns} />
                </div>
                <div className="side-bar-prop">
                  <div>Widget Version:</div>
                  <select name="widget-version" onChange={this.newWidgetWidgetVersion}>
                    <option value="v001">v001</option>
                    <option value="v002">v002</option>
                    <option value="v003">v003</option>
                  </select>
                </div>
              </div>
              <div className="create-widget-buttons">
                <div className="create-widget-btn" onClick={this.addNewWidget}>
                  <div>Create</div>
                </div>
                <div className="create-widget-btn" onClick={this.newWidgetToggle}>
                  <div>Cancel</div>
                </div>
              </div>
            </div>
          }
        </div>
    )
  }
}

export default connect(mapStateToProps)(SideBar);