import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
});

export class Skull extends React.PureComponent {
  render() {
    return (
      <div className="skull">
        <svg width="100%" height="100%" version="1.1" viewBox="0 0 25 25">
          <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path d="M17 18v.999A2 2 0 0 1 15.003 21H9.997C8.894 21 8 20.1 8 18.999V18l-1.085-.217C4.753 17.351 3 15.206 3 13v-1.993C3 6.033 7.03 2 12 2h1c4.97 0 9 4.02 9 9.007V13c0 2.21-1.749 4.35-3.915 4.783L17 18zm-9-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm9 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5.03-.152c.293-.468.77-.464 1.06 0l1.44 2.304c.293.468.086.848-.47.848h-3c-.552 0-.76-.384-.47-.848l1.44-2.304z"></path>
            <rect width="2" height="2" x="10" y="20" rx="1"></rect>
            <rect width="2" height="2" x="13" y="20" rx="1"></rect>
          </g>
        </svg>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Skull);