import React from 'react';
import { connect } from 'react-redux';
import { batch } from 'react-redux'
import Column from './Column';
import * as actions from './../../../../../Stores/Actions/actions';
import GroupByHeader from '../GroupBy/GroupByHeader';

const mapStateToProps = (state, ownProps) => {
  let selected = state.qry_SelectedQuery
  return {
    Columns: selected ? state.qry_Queries[selected.QueryId] : undefined,
    QueryId: selected ? selected.QueryId : undefined,
    RefreshPage: state.qry_RefreshCurrentPage
  };
};

export class GridHeader extends React.PureComponent {

  currentHoverColumn = undefined;

  setHoverColumn = (colId, xdif, isPinned) => {
    let direction = xdif > 0 ? 1 : -1;
    let isHovering = false;
    let cols = this.nextColumns(colId, direction);

    while (cols.length > 0 && (xdif * direction) > 0) {
      let halfC1 = cols[0].Width / 2;
      let halfC2 = cols[1] ? cols[1].Width / 2 : 0;
      // console.log((Math.abs(xdif) > halfC1) + ' first');
      // console.log((Math.abs(xdif) < cols[0].Width + halfC2) + ' second');
      if (Math.abs(xdif) > halfC1 && Math.abs(xdif) < cols[0].Width + halfC2) {
        isHovering = true;
        if (isPinned === cols[0].IsPinned && cols[0].ColumnId !== this.currentHoverColumn) {
          this.applyHover(cols[0], cols[0].ColumnId, direction);
        }
        break;
      }
      xdif -= (cols[0].Width * direction);
      cols = this.nextColumns(cols[0].ColumnId, direction);
    }

    if (!isHovering && this.currentHoverColumn !== undefined) {
      this.applyHover(undefined, undefined, undefined);
    }

    return this.currentHoverColumn;
  }

  applyHover = (hoverCol, hoverId, direction) => {
    let oldHover = this.currentHoverColumn;
    this.currentHoverColumn = hoverId;
    let blade = direction > 0 ? 'blade-right' : 'blade-left';

    batch(() => {
      if (hoverCol) {
        this.props.dispatch(actions.UpdateGridColumnProp({
          colId: hoverCol.ColumnId,
          updateProp: { key: 'hoverBlade', val: blade }
        }));
      }
      if (oldHover !== undefined) {
        this.props.dispatch(actions.UpdateGridColumnProp({
          colId: oldHover,
          updateProp: { key: 'hoverBlade', val: undefined }
        }));
      }
    });
  }

  nextColumns = (columnId, dir) => {
    let visibleIds = this.props.Columns.allIds.filter(x => this.props.Columns[x].IsVisible);
    let displayOrder = visibleIds.findIndex(x => x === columnId);
    let cols = visibleIds.map((x, idx) => {
      this.props.Columns[x].DisplayOrder = idx;
      return this.props.Columns[x];
    });
    cols = cols.sort((a, b) => {
      return a.DisplayOrder > b.DisplayOrder ? 1 : -1;
    });

    cols = dir > 0 ? cols : cols.reverse();
    let result = cols.filter(x => x.IsVisible && dir > 0 ? x.DisplayOrder > displayOrder : x.DisplayOrder < displayOrder).slice(0, 2);
    return result;
  }

  render() {
    let cols = this.props.Columns;
    let myCols = cols.allIds.filter(x => cols[x].Label[0] !== '!' && cols[x].IsVisible && cols[x].IsPinned === this.props.pinned);

    return (
      <div id='query-grid-header' className='grid-header'>
        <div className="header-cols">
          {myCols.map((x, idx) => (
            <Column key={idx} colId={x} queryId={this.props.QueryId} pinned={this.props.pinned} setHoverColumn={this.setHoverColumn} />
          ))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(GridHeader);