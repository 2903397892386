import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';
import * as Azure from '@azure/storage-blob';
import store from '../../../../Stores/Store';

const mapStateToProps = (state, ownProps) => {
  let entity = ownProps.entity || { EntityId: ownProps.control.EntityId, EntityTypeId: ownProps.control.EntityTypeId };

  let dbName = null;
  let mask = null;
  let useDelete = null;

  if (ownProps.control.DataMaskOptions) {
    try {
      let maskOpt = eval('(' + ownProps.control.DataMaskOptions + ')');
      mask = maskOpt;
      if (maskOpt.PathIncludesDB) {
        let dbKey = state.met_EntityMetadata.ConfigurationSettings.find(x => x.Key === "AzureDatabaseName");
        if (dbKey) {
          dbName = dbKey.Value;
        }
        else {
          console.log('PhotoUploader control could not find confuration setting for AzureDatabaseName');
        }
      }
    } catch {
      console.log('error parsing DataMaskOptions for PhotoUploader control');
    }
  }

  if(mask && mask.useDelete) {
    useDelete = true
  }

  let imgPath = (dbName ? dbName + '/' : '') + entity.EntityTypeId + '/' + entity.EntityId + '/' + (ownProps.control.Name || ownProps.control.Label);
  let previewId = 'photo-upload-image-preview' + helpers.getId();

  if (!ownProps.control.autoLoadId) {
    ownProps.control.autoLoadId = helpers.getId();
  }

  let autoLoad = state['autoLoad' + ownProps.control.autoLoadId];

  return {
    SaveData: state.dbo_SaveData,
    BlobSAS: state.BlobSAS,
    BlobSASPublic: state.BlobSASPublic,
    ImagePath: imgPath,
    PreviewId: previewId,
    AutoLoad: autoLoad,
    DocumentPickerId: 'DocumentPicker' + helpers.getId(),
    PublicPath: 'https://bhpublic.blob.core.windows.net/imagepicker/',
    MaskOptions: mask,
    AutoLoadId: ownProps.control.autoLoadId,
    Delete: useDelete,
    OwnProps: ownProps
  };
}

export class PhotoUploader extends React.PureComponent {
  state = {
    unsavedFile: false
  }
  componentDidMount() {
    this.getBlobImage();
  }

  componentDidUpdate(prev) {
    if (this.props.AutoLoad) {
      this.loadFromGob();
    }
    if (this.props.entity && this.props.entity.EntityId !== prev.entity.EntityId) {
      this.getBlobImage();
    }
  }

  loadFromGob = async () => {
    let fileUrl = this.props.AutoLoad;
    let fileInput = document.getElementById(this.props.DocumentPickerId);

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const file = new File([blob], 'Item.jpg', { type: blob.type });

      console.log({ file: file });

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      fileInput.files = dataTransfer.files;

      this.fileAttached({ dataTransfer: dataTransfer, target: fileInput });

      this.props.dispatch(actions.AddSaveData({
        IsPlaceholder: true,
        SaveQueue: this.props.control.SaveData.SaveQueue || this.getSaveQueue(this.props.control),
        Id: this.props.control.Id || this.props.control.SaveData.Id,
        AfterSave: (arg, result) => {
          console.log(fileUrl);
          let newPath = this.pathFromNewEntity(result);
          this.uploadFileToBlob(file, newPath);
          setTimeout(() => {
            this.setState({ unsavedFile: false });
            this.forceUpdate();
          }, 0)
        },
        OnCancel: () => {
          this.props.control.NewImage = false;
          let img = document.getElementById(this.props.PreviewId);
          if (img) {
            img.src = this.props.control.Value;
          }
          this.setState({ unsavedFile: false });
        },
      }));
    } catch (error) {
      console.error('Error fetching file:', error);
    }
    this.props.dispatch(actions.UpdateProp({
      Key: 'autoLoad' + this.props.AutoLoadId,
      Value: null
    }));
  }

  onChange = (e) => {
    if (this.props.control.IsRO)
      return;

    if (this.props.refresh)
      this.props.refresh();

    let file;
    if (e.target.files) {
      file = e.target.files[0];
    } else if (e.dataTransfer.files) {
      file = e.dataTransfer.files[0];
    }

    if (!file) { this.getBlobImage(); return; }

    if (!file.type.includes('image')) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Error adding image', Message: <p style={{ fontSize: '1.1rem' }}>This file is not an image, please select a valid image file</p> }
      }));
      return;
    }

    this.fileAttached(e);

    if (this.props.trackChanges) { 
      if (this.props.control.AutoSave) { 
        this.uploadFileToBlob(file);
      } else if (this.shouldSavePath(this.props.control)) { 
        let path = this.pathFromNewEntity({Id: crypto.randomUUID()})
        this.props.dispatch(actions.AddSaveData({
          Id: this.props.control.SaveData.Id,
          Table: this.props.control.SaveData.Table,
          Column: this.props.control.SaveData.Column,
          Value: path,
          IsEventData: this.props.isEventSave,
          SaveQueue: this.props.control.SaveData.SaveQueue,
          Radio: this.props.control.SaveData.Radio,
          InsertKey: this.props.control.SaveData.InsertKey,
          IsBaseTable: this.props.control.IsBaseTable,
          AfterSave: () => {
            this.uploadFileToBlob(file, path);
          }
        }));
      } else {
        this.props.dispatch(actions.AddSaveData({
          IsPlaceholder: true,
          SaveQueue: this.props.control.SaveData.SaveQueue || this.getSaveQueue(this.props.control),
          Id: this.props.control.Id || this.props.control.SaveData.Id,
          AfterSave: (arg, result) => {
            let newPath = this.pathFromNewEntity(result);
            this.uploadFileToBlob(file, newPath);
            setTimeout(() => {
              this.setState({ unsavedFile: false });
              this.forceUpdate();
            }, 0)
          },
          OnCancel: () => {
            this.props.control.NewImage = false;
            let img = document.getElementById(this.props.PreviewId);
            img.src = this.props.control.Value;
            this.setState({ unsavedFile: false });
          },
        }));
        // this.props.dispatch(actions.AddSaveData({
        //   Id: this.props.control.SaveData.Id,
        //   Table: this.props.control.SaveData.Table,
        //   Column: this.props.control.SaveData.Column,
        //   Value: this.props.ImagePath,
        //   IsEventData: this.props.isEventSave,
        //   SaveQueue: this.props.control.SaveData.SaveQueue,
        //   Radio: this.props.control.SaveData.Radio,
        //   InsertKey: this.props.control.SaveData.InsertKey,
        //   IsBaseTable: this.props.control.IsBaseTable,
        //   AfterSave: () => {
        //     this.uploadFileToBlob(file);
        //     this.getBlobImage();
        //   },
        //   OnCancel: () => {
        //     this.props.control.NewImage = false;
        //     let img = document.getElementById(this.props.PreviewId);
        //     img.src = this.props.control.Value;
        //   },
        // }));
      }
    }
  }

  pathFromNewEntity = (result) => {
    if (!result || !result.Id)
      return null;
    return this.props.entity.EntityTypeId + '/' + result.Id + '/' + (this.props.control.Name || this.props.control.Label);
  }

  getBlobImage = async () => {
    if (this.props.IsPublic)
      return;

    let filename = this.props.ImagePath;

    let containerClient = this.getBlobContainerClient();
    let blobClient = containerClient.getBlobClient(filename);
    let fileExists = await blobClient.exists();

    if (fileExists) {
      const downloadBlockBlobResponse = await blobClient.download();
      const blobSrc = await downloadBlockBlobResponse.blobBody;

      let img = document.getElementById(this.props.PreviewId);
      if (img) {
        img.src = URL.createObjectURL(blobSrc);
      }
    } else {
      // img.src = 'https://beehivetilcache.blob.core.windows.net/staticassets/FileIcons/unknown.svg';
    }
  }

  fileAttached = (e) => {
    let file;
    if (e.target.files) {
      file = e.target.files[0];
    } else if (e.dataTransfer.files) {
      file = e.dataTransfer.files[0];
    }

    if (!file) { return }

    this.setState({ unsavedFile: true });

    let img = document.getElementById(this.props.PreviewId);
    let fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = () => {
      img.src = fr.result;
      img.height = 80;
      img.width = 80;
      img.dirty = true;
    }

    // find structure to save file
    /*https://beehiveattachments.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2060-07-21T04:24:54Z&st=2022-07-20T20:24:54Z&spr=https&sig=jeICFW1H1PpoUPN1q6qcGamJMVUt%2FfXsGNh6lMK2Jjc%3D*/
    /* add save data, afterSave with blob storage upload ? */
  }

  uploadFileToBlob = (file, pathOverride) => {
    let containerClient = this.getBlobContainerClient();
    let oploadOptions = { blobHTTPHeaders: { blobContentType: 'image/jpeg' || 'application/octet-stream' } };
    // let oploadOptions = { blobHTTPHeaders: { blobContentType: file.type } };
    const blockBlobClient = containerClient.getBlockBlobClient(pathOverride || this.props.ImagePath);
    blockBlobClient.uploadBrowserData(file, oploadOptions);
  }

  dropFile = (e) => {
    e.preventDefault();
    e.currentTarget.style.boxShadow = 'none';
    this.onChange(e);
  }

  onDragOver = (e) => {
    e.currentTarget.style.boxShadow = 'inset 0 0 2em 1em orange, 0 0 0 2px rgb(0, 0, 0)';
    e.preventDefault();
  }

  onDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.style.boxShadow = 'none';
  }

  getBlobContainerClient = () => {
    let blobSaS = this.props.IsPublic ? this.props.BlobSASPublic : this.props.BlobSAS;
    let blobServiceClient = new Azure.BlobServiceClient(blobSaS.Key);
    let containerName = this.props.IsPublic ? '' : blobSaS.Database.replace(/_/g, '').toLowerCase();
    return blobServiceClient.getContainerClient(containerName);
  }

  getMaskOptions = (control) => {
    const state = store.getState();
    const ownProps = this.props.OwnProps
    try {
      return eval('(' + control.DataMaskOptions + ')');
    } catch(ex) { console.log({Message: `Error parsing mask options`, Error: ex}) }
  }

  getHelpText = (control) => {
    return this.getMaskOptions(control)?.helpText ?? 'Image Uploader';
  }

  getSaveQueue = (control) => {
    return this.getMaskOptions(control)?.saveQueue ?? 'dbo_SaveData';
  }

  shouldSavePath = (control) => {
    return helpers.stringToBool(this.getMaskOptions(control)?.savePath ?? 'false');
  }

  deleteAttachment = () => {
    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: {
          Title: "Delete Image",
          Message: (
            <>
            <p style={{ fontSize: "1.1rem" }}>
                Are you sure you want to delete this image?
            </p>
              <div className="flex-btn-bar" style={{ paddingLeft: "10px", marginTop: "10px" }}>
                <div className="btn-container" style={{ flex: "0 1 140px" }}>
                  <div className="btn-bar-btn polygon-btn" onClick={() => this.confirmDelete()}>
                      <div>
                          Delete
                      </div>
                  </div>
                </div>
                <div className="btn-container" style={{ flex: "0 1 140px" }}>
                  <div className="btn-bar-btn polygon-btn-neg" onClick={this.cancelButton}>
                      <div>
                          Cancel
                      </div>
                  </div>
                </div>
              </div>
            </>
          ),
          Buttons: [],
        },
      })
    );
  }

  confirmDelete = async () => {
    let blobSaS = this.props.IsPublic ? this.props.BlobSASPublic : this.props.BlobSAS;
    let blobServiceClient = new Azure.BlobServiceClient(blobSaS.Key);
    let containerName = this.props.IsPublic ? '' : blobSaS.Database.replace(/_/g, '').toLowerCase();
    const containerClient = blobServiceClient.getContainerClient(containerName)
    const options = {
      deleteSnapshots: 'include'
    }
  
    if(this.props.ImagePath) {
      const blockBlobClient = containerClient.getBlockBlobClient(this.props.ImagePath);
      await blockBlobClient.deleteIfExists(options);
    }

    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: null,
      })
    );
  }

  cancelButton = () => {
    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: null,
      })
    );
  };

  render() {
    let control = this.props.control;
    let publicImage = this.props.IsPublic ? this.props.PublicPath/* + this.props.ImagePath*/ : null;
    let useDelete = this.props.Delete

    if (!this.state.unsavedFile && !this.props.ImagePath.includes('undefined')) { //if there is an unsaved image, don't update image path
      publicImage += this.props.ImagePath;
      publicImage += '?' + performance.now();
    }

    return (
      <>
        {/* <div className="control-label">
          {control.Label}
          {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
        </div> */}
        {useDelete && <a style={{alignSelf:"end",color:"red",position:"absolute",marginRight:"6px",marginTop:"2px",cursor:"pointer"}} onClick={() => this.deleteAttachment()}>X</a>}
        <div className="photo-upload-control" id={('autoLoad' + this.props.AutoLoadId)} onDrop={this.dropFile} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave}>
          {/* {true && <div className="photo-upload-dragndrop">Drop file</div>} */}
          <img width="80px" height="80px" style={{ objectFit: 'contain' }} id={this.props.PreviewId} src={publicImage} onError={(e) => { e.target.src = 'https://bhpublic.blob.core.windows.net/imagepicker/no-image.png'; }} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", width: "100%", height: '80px', alignItems: 'center' }}>
            <p style={{ color: "#727272", alignSelf: "center", fontSize: "11px", userSelect: "none" }}>{this.getHelpText(control)}</p>
            <button className='photo-upload-control-button' onClick={() => { let fileInput = document.getElementById(this.props.DocumentPickerId); fileInput.value = null; fileInput.click(); }}>Browse & Upload</button>
            <input type="file" name="file" id={this.props.DocumentPickerId} style={{ display: 'none' }} onChange={this.onChange} />
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(PhotoUploader);