import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { WindGust } from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class WeatherBar extends React.PureComponent {
  state = {
    Weather: null,
    Interval: null
  }

  componentDidMount() {
    this.getWeather();
    let interval = setInterval(this.getWeather, 10 * 60 * 1000);
    this.setState({ Interval: interval });
  }

  componentWillUnmount() {
    if (this.state.Interval) {
      clearInterval(this.state.Interval);
    }
  }

  updateForecast = (forecast) => {
    this.setState({
      Weather: {
        Temp: forecast.temperature + '°',
        Label: forecast.shortForecast,
        WindSpeed: forecast.windSpeed
      }
    })
  }

  getWeather = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((result) => {
        let path = `https://api.weather.gov/points/${result.coords.latitude},${result.coords.longitude}`;
        actions.ExternalApiGet(path, (weather) => {
          console.log(weather);
          if (weather && weather.properties && weather.properties.forecast) {
            actions.ExternalApiGet(weather.properties.forecast, (forecast) => {
              if (forecast && forecast.properties && forecast.properties.periods && forecast.properties.periods.length > 0) {
                this.updateForecast(forecast.properties.periods[0]);
              }
            });
          }
        });
      });
    }
  }

  render() {
    return (
      <div className="weather-bar">
        {!this.state.Weather ? null :
          <div className="weather">
            <div className="weather-temp">{this.state.Weather.Temp}</div>
            <div className="weather-label">
              <div className="forecast">{this.state.Weather.Label}</div>
              <div className="wind-speed">
                <WindGust />
                <div>{this.state.Weather.WindSpeed}</div>
              </div>
            </div>
          </div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(WeatherBar);