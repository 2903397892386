import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { AddPhoto, Trashcan } from './../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let formKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'PhotoKey');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');

  return {
    Photo: state['PhotoPicker' + ownProps.widget.SceneWidgetId],
    PhotoKey: 'PhotoPicker' + ownProps.widget.SceneWidgetId,
    FormDataKey: formKey ? formKey.ParameterValue : undefined,
    PhotoData: formKey ? state[formKey.ParameterValue] : undefined,
    Entity: entityKey ? state[entityKey.ParameterValue] : undefined
  };
};

export class PhotoPicker extends React.PureComponent {

  componentDidMount() {
    if (this.props.PhotoData && this.props.Photo && this.props.Photo.Preview) {
      this.showImagePreview(this.props.Photo.Preview);
    } else {
      this.trashPhoto();
    }
  }

  updatePhoto = (obj) => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.PhotoKey,
      Value: {
        ...(this.props.Photo || {}),
        ...obj
      }
    }));
  }

  trashPhoto = () => {
    this.clearPreview();
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.PhotoKey,
      Value: undefined
    }));
  }

  openDialog = () => {
    document.getElementById('PhotoPicker').click();
  }

  imageChosen = (e) => {
    let file = e.target.files[0];
    if (!file)
      return;

    this.updatePhoto();
    let maxDims = { width: 350, height: 200 };
    this.getResizedImage(file, maxDims, false, (preview) => {
    });

    this.fileAttached(e);
  }

  getResizedImage = (file, maxDims, skipPreview, callback) => {
    let canvas = document.createElement('canvas');
    let fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = () => {
      let img = new Image();
      img.src = fr.result;
      img.onload = () => {
        file.width = img.width;
        file.height = img.height;
        let dims = this.resizeDimensions(img.width, img.height, maxDims.width, maxDims.height);
        canvas.width = dims.width;
        canvas.height = dims.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, dims.width, dims.height);
        canvas.toBlob(callback, file.type);
        if (!skipPreview) {
          this.showImagePreview(img);
        }
      };
    }
  }

  showImagePreview = (img) => {
    this.updatePhoto({ Preview: img });

    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext("2d");
    let preview = document.getElementsByClassName('image-picker-thumbnail')[0];

    this.clearPreview();
    ctx.drawImage(img, 0, 0);

    window.requestAnimationFrame(() => {
      let maxWidth = preview.clientWidth;
      let maxHeight = preview.clientHeight;
      preview.appendChild(img);

      let dims = this.resizeDimensions(img.width, img.height, maxWidth, maxHeight);
      img.width = canvas.width = dims.width;
      img.height = canvas.height = dims.height;

      ctx.drawImage(img, 0, 0);
    });
  }

  clearPreview = () => {
    console.log('clear preview');
    let preview = document.getElementsByClassName('image-picker-thumbnail')[0];
    if (preview) {
      preview.innerHTML = '';
    }
  }

  resizeDimensions = (width, height, maxWidth, maxHeight) => {
    var ratio = Math.min(maxWidth / width, maxHeight / height);
    return { width: width * ratio, height: height * ratio };
  }


  fileAttached = (e) => {
    let file = e.target.files[0];
    if (!file)
      return;

    if (file.type.includes('image')) {
      let previewImageDims = { width: 350, height: 200 };
      let maxDims = { width: 1920, height: 1080 };
      this.getResizedImage(file, previewImageDims, true, (preview) => {
        if (file.width > previewImageDims.width || file.height > previewImageDims.height) {
          this.getResizedImage(file, maxDims, true, (result) => {
            this.setFormData(file, [result, preview]);
          });
        } else {
          this.setFormData(file, [preview]);
        }
      });
    }
  }

  setFormData = (file, blob = null) => {
    let formData = new FormData();
    formData.append(file.name + '_l', blob ? blob[0] : file, file.name);
    formData.append("FileType", file.type);

    if (blob && blob[1]) {
      formData.append(file.name + '_s', blob ? blob[1] : file, file.name);
    }

    formData.append("DocumentId", 'NewDocument');
    formData.append("AssociatedWith", -1);
    formData.append("Extension", file.name.split('.').pop());
    formData.append("EntityTypeId", this.props.Entity ? this.props.Entity.EntityTypeId : -1);
    formData.append("EntityId", -1);
    formData.append("SaveTypeId", 1);
    formData.append("DocTypeId", 1);
    formData.append("DocDate", new Date().toISOString().slice(0, 10));
    formData.append("IsDisplay", false);
    formData.append("IsPublic", false);
    formData.append("IsGroup", false);
    formData.append("SourcePath", '');
    formData.append("Comment", '');
    formData.append("IsDeleted", false);

    console.log(formData);
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.FormDataKey,
      Value: {
        formData
      }
    }));
  }

  render() {
    return (
      !this.props.Photo ?
        <div className="photo-picker">
          <div className="picker-container" onClick={this.openDialog}>
            <div className="photo-picker-icon">
              <AddPhoto />
            </div>
            <div className="photo-picker-label">Add Photo</div>
          </div>
          <input id="PhotoPicker" type="file" accept="image/*" onChange={this.imageChosen} ></input>
        </div>
        :
        <div className="photo-picker">
          <div className="image-picker-thumbnail"></div>
          <div className="trash-photo">
            <div onClick={this.trashPhoto}>
              <Trashcan />
            </div>
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(PhotoPicker);