import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import Control from 'ol/control/Control';
import { isValid } from 'inputmask';

const mapStateToProps = (state, ownProps) => {
  return {
    ApproveButton: state.ttr_LeaveAdminApprove,
    DenyButton: state.ttr_LeaveAdminDeny,
    SaveData: state.ttr_LeaveRequestAdmin,
    ApproveSource: 'ttr_LeaveAdminApprove',
    DenySource: 'ttr_LeaveAdminDeny',
    SaveDataKey: 'ttr_LeaveRequestAdmin',
    TimeTrackingRefresh: state.ttr_TimeTrackingRefresh,
    EM: state.met_EntityMetadata,
    SaveId: state.dbo_SaveId
  };
};

export class LeaveRequestAdmin extends React.PureComponent {
  waiting = false;

  constructor(props) {
    super(props);
    this.updateButton(0, false, this.props.ApproveSource);
    this.updateButton(0, false, this.props.DenySource);
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.ApproveButton)
      return;

    this.checkEnabled();
    this.handleClick(prevProps);
  }

  checkEnabled = () => {
    let enabled = false;

    if (this.props.SaveData && this.props.SaveData.find(x => x.Column === 'IsChecked' && x.Value === true)) {
      enabled = true;
    }

    if (this.props.ApproveButton.Enabled !== enabled) {
      this.updateButton(this.props.ApproveButton.ClickId, enabled, this.props.ApproveSource);
      this.updateButton(this.props.DenyButton.ClickId, enabled, this.props.DenySource);
    }
  }

  handleClick = (prevProps) => {
    if (!prevProps.ApproveButton)
      return;

    if (prevProps.ApproveButton.ClickId !== this.props.ApproveButton.ClickId) {
      this.approveRequest();
    }
    if (prevProps.DenyButton.ClickId !== this.props.DenyButton.ClickId) {
      this.denyRequest();
    }
  }

  approveRequest = () => {
    this.saveAction(2, true);
  }

  denyRequest = () => {
    this.saveAction(3, false);
  }

  checkRequiredProps = () => {
    let isValid = true;
    let saveObj = helpers.saveQueueObject(this.props.SaveData);

    for (let key in saveObj) {
      let item = saveObj[key];
      if (item.IsChecked && !item.ManagerNote) {
        isValid = false;
        let element = document.querySelector('.ManagerNote' + key + ' input');
        if (element) {
          helpers.highlightRequiredField(element);
        }
      }
    }

    return isValid;
  }

  saveAction = (statusId, isApproved) => {
    let valid = this.checkRequiredProps();
    if (this.waiting || !valid)
      return;

    let baseObj = {
      Id: null,
      InsertKey: null,
      Table: 'ttr.LeaveRequest',
      IsBaseTable: true,
      EntityTypeId: 100
    };

    let saveData = [];
    let items = helpers.saveQueueObject(this.props.SaveData.filter(x => x.Column === 'IsChecked' && x.Value === true));

    for (let key in items) {
      baseObj.Id = key;

      saveData = [
        ...saveData,
        helpers.saveDataItem(baseObj, 'ManagerId', this.props.EM.CurrentUser),
        helpers.saveDataItem(baseObj, 'LeaveStatusId', statusId),
        helpers.saveDataItem(baseObj, 'ManagerNote', items[key].ManagerNote || ''),
      ];

      if (isApproved) {
        saveData.push(helpers.saveDataItem(baseObj, 'ApprovedDate', helpers.dateToInputFormat(new Date())));
      }
    }

    this.waiting = true;
    actions.ApiRequest('Save/Save', { SaveData: saveData }, () => {
      this.waiting = false;
      this.props.dispatch(actions.ClearSaveData(this.props.SaveDataKey));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_TimeTrackingRefresh',
        Value: (this.props.TimeTrackingRefresh || 0) + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });
  }

  updateButton = (clickId, isEnabled, sourceName) => {
    if (!sourceName)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: sourceName,
      Value: {
        ClickId: clickId,
        Enabled: isEnabled
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(LeaveRequestAdmin);
