import React from 'react';
import { connect } from 'react-redux';
import Layers from './../Layers/Layers';
import LayerTypes from './LayerTypes';
import * as actions from '../../../../../Stores/Actions/actions';
import HoneycombClient from '@beehive-development/honeycomb';
import Control from 'ol/control/Control.js';
import { transformExtent } from 'ol/proj';
 
const mapStateToProps = (state, ownProps) => {
  return {
    LegendItems: state[ownProps.legendKey],
    AzureKey: state.AzureMapsKey,
    GoogleLoaded: state.GoogleLoaded ? true : false,
    GoogleToken: state.GoogleToken ? state.GoogleToken : null
  };
};
 
export class LayersManager extends React.PureComponent {
  componentDidUpdate() {
    this.updateLayers();
  }
 
  updateLayers = async () => {
    if (!this.props.LegendItems || !Array.isArray(this.props.LegendItems) || !this.props.map)
      return;
 
    let currentLayers = this.props.map.getLayers().array_;
    let items = (this.props.LegendItems.allItems || this.props.LegendItems).filter(x => x.LayerType !== null && (x.MapId === this.props.map.MapId || (this.props.IsQueryMap && x.IsUserQueryLayer)));
    
    let google_layer = items.find(e => e.LayerType === 13)
    if (google_layer && google_layer.OnByDefault && !this.props.GoogleLoaded) {
      const honeycomb = new HoneycombClient({});

      var element = document.createElement('div');
      element.id = 'ol-google-icon'
      element.className = 'ol-google-icon';
      element.innerHTML=`<img src="https://bhpublic.blob.core.windows.net/static/google_on_non_white.png"/>`

      let googleIcon = new Control({
        element: element
      })

      this.props.map.addControl(googleIcon)

      this.props.map.on('moveend', function(e) {
        if(e.map.GoogleToken !== null) {
          let extent = e.map.getView().calculateExtent()
          let latLongExtent = transformExtent(extent, 'EPSG:3857','EPSG:4326');
          let zoom = Math.round(e.map.getView().getZoom())
          honeycomb.google.getGoogleViewportInformation(window.location.href, "AIzaSyBm84LMWYf46FSbqObD9pm0dF71HZpmG0U", e.map.GoogleToken, zoom, latLongExtent[3], latLongExtent[1], latLongExtent[2], latLongExtent[0])
            .then((response) => response.json())
            .then((result) => {
              e.map.getControls().forEach((x) => {
                if(x.element.id === 'ol-google-copyright') {
                  e.map.removeControl(x)
                }
              })

              var copyrightElement = document.createElement('div');
              copyrightElement.id = 'ol-google-copyright'
              copyrightElement.className = 'ol-google-copyright';
              copyrightElement.innerHTML=`<p>${result.copyright ? result.copyright : null}</p>`

              let googleCopyright = new Control({
                element: copyrightElement
              })

              e.map.addControl(googleCopyright)
            })
        }
      });

      honeycomb.google.getGoogleTileCredentials(window.location.href, "AIzaSyBm84LMWYf46FSbqObD9pm0dF71HZpmG0U")
        .then((response) => response.json())
        .then((result) => {
          this.props.dispatch(actions.UpdateProp({
            Key: 'GoogleLoaded',
            Value: true
          }));
          this.props.dispatch(actions.UpdateProp({
            Key: 'GoogleToken',
            Value: result.session
          }));
          this.props.map.GoogleToken = result.session
        });
    }

    let options = {
      ...this.props.map.settings,
      Map: this.props.map,
      CurrentEntity: this.props.currentEntity
    };
 
    items.forEach(item => {
      let itemOptions = { ...options, ...item, AzureKey: this.props.AzureKey };
 
      let layerIndex = currentLayers.findIndex(x => !!x.IsUserQueryLayer === !!item.IsUserQueryLayer && x.LayerId === item.LayerId);
 
      if (item.Active && layerIndex === -1) {
        let newLayer;
 
        if (item.LayerType === 6) {
          itemOptions = {
            ...itemOptions, map: this.props.map, callback: (result) => {
              newLayer = result;
              this.setLayerConfiguration(newLayer, item);
              newLayer.LayerId = item.LayerId;
              newLayer.IsUserQueryLayer = item.IsUserQueryLayer;
              this.props.map.addLayer(newLayer);
            }
          };
        }
 
        newLayer = LayerTypes[item.LayerType](itemOptions);
 
        if (item.LayerType !== 6) {
          this.setLayerConfiguration(newLayer, item);
          newLayer.LayerId = item.LayerId;
          newLayer.IsUserQueryLayer = item.IsUserQueryLayer;
          this.props.map.addLayer(newLayer);
 
          if (item.RefreshInterval) {
            if (newLayer._interval) {
              window.clearInterval(newLayer._interval);
            }
 
            newLayer._interval = window.setInterval(() => {
              newLayer.getSource().refresh();
            }, item.RefreshInterval * 1000);
          }
        }
      }
      if (!item.Active && layerIndex !== -1) {
        if (currentLayers[layerIndex]._interval) {
          window.clearInterval(currentLayers[layerIndex]._interval);
        }
        this.props.map.removeLayer(currentLayers[layerIndex]);
      }
    })
  }
 
  setLayerConfiguration = (layer, legendItem) => {
    layer.setZIndex(legendItem.ZIndex || 0);
 
    //openlayers flips these, setting min = max on purpose here.
    if (legendItem.MinZoom !== null)
      layer.setMaxResolution(Math.pow(2, Math.log2(156543.03390625) - legendItem.MinZoom));
 
    if (legendItem.MaxZoom !== null)
      layer.setMinResolution(Math.pow(2, Math.log2(156543.03390625) - legendItem.MaxZoom));
  }
 
  render() {
    return null
  }
}
 
export default connect(mapStateToProps)(LayersManager);