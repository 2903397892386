import { none } from 'ol/centerconstraint';
import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { OrangeRadio, OrangeRadioUnchecked, OrangeUnchecked, OrangeCheck, Checked, IndeterminateCheck } from '../../../Icons/Icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { defaultTextAlign } from 'ol/render/canvas';

const mapStateToProps = (state, ownProps) => {

  let copyExistingSchedule = ownProps.widget.Parameters.find(x => x.ParameterName === 'CopyExistingSchedule');
  let schedTypeLookupSet = ownProps.widget.Parameters.find(x => x.ParameterName === 'SchedTypeLookupSet');
  let readOnly = ownProps.widget.Parameters.find(x => x.ParameterName === 'ReadOnly');
  let fixedSchedLid = null;
  let floatingSchedLid = null;

  if (schedTypeLookupSet && schedTypeLookupSet.ParameterValue) {
    let lookupItems = state.met_EntityMetadata.LookupItems.find(x => x.LookupSet == schedTypeLookupSet.ParameterValue).LookupItems;
    fixedSchedLid = lookupItems.find(x => x.Name1 === 'Fixed') && lookupItems.find(x => x.Name1 === 'Fixed').LookupItemId;
    floatingSchedLid = lookupItems.find(x => x.Name1 === 'Floating') && lookupItems.find(x => x.Name1 === 'Floating').LookupItemId;
  }

  return {
    CurrentEntity: state.ent_Current,
    SelectedEntity: state.ent_Selected,
    SelectedEvent: state.ent_SelectedEvent,
    RecurSelected: state.ent_RecurSelected,
    GobKey: '_recurrence' + ownProps.widget.SceneWidgetId,
    RecurForm: state['_recurrence' + ownProps.widget.SceneWidgetId],
    SaveId: state.dbo_SaveId,
    RecurSchedulesList: state.AM_RecurSchedules,
    EventSaveData: state.dbo_EventSaveData,
    EventSaveButton: state.btn_EventSave,
    CopyExistingSchedule: copyExistingSchedule ? helpers.stringToBool(copyExistingSchedule.ParameterValue) : false,
    Recurrence: state.ent_RecurrenceData,
    SWID: ownProps.widget.SceneWidgetId,
    FixedLid: fixedSchedLid,
    FloatingLid: floatingSchedLid,
    btn_SelectSchedule: state.btn_SelectSchedule,
    CalendarDay: state.rcr_CalendarDay,
    ReadOnly: readOnly ? helpers.stringToBool(readOnly.ParameterValue) : false
  };
}

export class RecurScheduleForm extends React.PureComponent {
  state = {
    calendarDay: new Date()
  }
  componentDidMount() {
    this.setState({ calendarDay: new Date() });
    // this.props.dispatch(actions.UpdateProp({
    //   Key: 'rcr_CalendarDay',
    //   Value: new Date()
    // }))
    let validationMap = new Map();
    validationMap.set('startDate', true).set('endByDate', true).set('endAfterOcc', true).set('dayPatternChecks', true).set('dayExact', true).set('dayFrequency', true).set('weekFrequency', true).set('monthFrequency', true).set('monthPatternChecks', true).set('yearlyFrequency', true).set('floatingDays', true).set('unitStart', true).set('unitFrequency', true).set('unitTypeLid', true).set('FixedSchedType', true);
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.GobKey,
      Value: {
        ScheduleTypeLid: this.props.FixedLid,
        isNew: true,
        validation: validationMap,
        FixedSchedType: '1'
      }
    }));

    if (this.props.RecurSelected) {
      this.setUpRadioButtons();
    }
  }

  componentDidUpdate = () => {
    if (this.props.RecurSelected && this.props.RecurForm.ScheduleEntityId !== this.props.RecurSelected.ScheduleEntityId) {
      this.setUpRadioButtons();
    }

    if (this.props.btn_SelectSchedule) {
      this.selectSchedule();
    }
  }

  setUpRadioButtons = () => {
    let radioOptions = {};
    let validationMap = new Map();
    validationMap.set('startDate', true).set('endByDate', true).set('endAfterOcc', true).set('dayPatternChecks', true).set('dayExact', true).set('dayFrequency', true).set('weekFrequency', true).set('monthFrequency', true).set('monthPatternChecks', true).set('yearlyFrequency', true).set('floatingDays', true).set('unitStart', true).set('unitFrequency', true).set('unitTypeLid', true);
    //End Date Options
    if (this.props.RecurSelected.EndDate) {
      radioOptions.endByRadio = 'endByDate';
    } else if (this.props.RecurSelected.MaxOccurrences) {
      radioOptions.endByRadio = 'endAfterOcc';
    } else {
      radioOptions.endByRadio = 'noEndDate';
    }
    if (this.props.RecurSelected.ScheduleTypeLid == this.props.FixedLid) { //Fixed
      switch (this.props.RecurSelected.FixedSchedType) { //1,2,3,4 = daily,weekly,monthly,yearly
        case 1:
        case '1':
          radioOptions.FixedSchedType = 1;
          if (this.props.RecurSelected.DayPattern && this.props.RecurSelected.DayPattern == 31) {
            radioOptions.dailyRadio = 'daily1';
          } else if (this.props.RecurSelected.DayFrequency) {
            radioOptions.dailyRadio = 'daily2';
          }
          break;
        case 2:
        case '2':
          radioOptions.FixedSchedType = 2;
          break;
        case 3:
        case '3':
          radioOptions.FixedSchedType = 3;
          if (this.props.RecurSelected.DayExact && this.props.RecurSelected.MonthFrequency) {
            radioOptions.monthlyRadio = 'monthly1';
          } else if (this.props.RecurSelected.DayInstance && this.props.RecurSelected.DayPattern && this.props.RecurSelected.MonthFrequency) {
            radioOptions.monthlyRadio = 'monthly2';
          } else if (this.props.RecurSelected.DayInstance && this.props.RecurSelected.DayPattern && this.props.RecurSelected.MonthPattern) {
            radioOptions.monthlyRadio = 'monthly3';
          }
          break;
        case 4:
        case '4':
          radioOptions.FixedSchedType = 4;
          if (this.props.RecurSelected.MonthPattern && this.props.RecurSelected.DayExact) {
            radioOptions.yearlyRadio = 'yearly1';
          } else if (this.props.RecurSelected.DayInstance && this.props.RecurSelected.DayPattern && this.props.RecurSelected.MonthPattern) {
            radioOptions.yearlyRadio = 'yearly2';
          }
          break;
        default:
          radioOptions.FixedSchedType = null;
      }
      let markedDays = [];
      if (this.props.RecurSelected && this.props.RecurSelected.CalendarDates) {
        this.props.RecurSelected.CalendarDates.forEach(x => {
          markedDays.push(x.NextDate);
        })
        this.setState({ calendarDay: new Date(markedDays[0]) });
        // this.props.dispatch(actions.UpdateProp({
        //   Key: 'rcr_CalendarDay',
        //   Value: new Date(markedDays[0])
        // }))
      }
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.GobKey,
        Value: { ...this.props.RecurSelected, CalendarDates: markedDays, validation: validationMap, ['endByRadio']: radioOptions.endByRadio, ['dailyRadio']: radioOptions.dailyRadio, ['yearlyRadio']: radioOptions.yearlyRadio, ['monthlyRadio']: radioOptions.monthlyRadio, ['FixedSchedType']: radioOptions.FixedSchedType }
      }));
    }
    if (this.props.RecurSelected.ScheduleTypeLid == this.props.FloatingLid) { //Floating
      radioOptions.dailyIncluded = false;
      if (this.props.RecurSelected.UnitStart) {
        radioOptions.floatSelection = 'unit';
        if (this.props.RecurSelected.FloatingDays) {
          radioOptions.dailyIncluded = true;
        }
      } else {
        radioOptions.floatSelection = 'daily';
      }
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.GobKey,
        Value: { ...this.props.RecurSelected, validation: validationMap, ['endByRadio']: radioOptions.endByRadio, ['floatSelection']: radioOptions.floatSelection, ['dailyIncluded']: radioOptions.dailyIncluded }
      }));
    }


  }

  onFormChange = (e, propName) => {
    let recurForm = this.props.RecurForm;
    recurForm.CalendarDates = null;
    if (propName === 'endByRadio') {
      if (e.target.value === 'endByDate') {
        recurForm.MaxOccurrences = null;
      } else if (e.target.value === 'endAfterOcc') {
        recurForm.EndDate = null;
      }
    }

    if (propName === 'FixedSchedType') {
      recurForm.DayInstance = null;
      recurForm.DayPattern = null;
      recurForm.DayPatternDays = null;
      recurForm.DayExact = null;
      recurForm.DayFrequency = null;
      recurForm.WeekFrequency = null;
      recurForm.MonthFrequency = null;
      recurForm.MonthPattern = null;
      recurForm.MonthPatternDays = null;
      recurForm.YearlyFrequency = null;
      recurForm.dailyRadio = null;
      recurForm.weeklyRadio = null;
      recurForm.monthlyRadio = null;
      recurForm.yearlyRadio = null;
    }

    if (propName === 'dailyRadio') {
      recurForm.DayFrequency = null;
      recurForm.DayPattern = null;
      recurForm.DayPatternDays = null;
      if (e.target.value === 'daily1') {
        recurForm.DayPattern = 31;
      }
    }
    if (propName === 'monthlyRadio') {
      recurForm.DayInstance = null;
      recurForm.DayPattern = null;
      recurForm.DayPatternDays = null;
      recurForm.DayInstance = null;
      recurForm.MonthPattern = null;
      recurForm.MonthPatternDays = null;
      recurForm.MonthFrequency = null;
      recurForm.DayExact = null;
      if (e.target.value === 'monthly2') {
        recurForm.DayInstance = 1;
        recurForm.DayPattern = 1;
      }
      if (e.target.value === 'monthly3') {
        recurForm.DayInstance = 1;
        recurForm.DayPattern = 1;
      }
    }
    if (propName === 'yearlyRadio') {
      recurForm.MonthPattern = null;
      recurForm.MonthPatternDays = null;
      recurForm.DayExact = null;
      recurForm.DayInstance = null;
      recurForm.DayPattern = null;
      recurForm.DayPatternDays = null;
      if (e.target.value === 'yearly1') {
        recurForm.MonthPattern = 1;
      }
      if (e.target.value === 'yearly2') {
        recurForm.DayInstance = 1;
        recurForm.DayPattern = 1;
        recurForm.MonthPattern = 1;
      }
    }

    if (propName === 'floatSelection') {
      if (e.target.value === 'daily') {
        recurForm.UnitStart = null;
        recurForm.UnitFrequency = null;
        recurForm.UnitTypeLid = null;
      } else if (e.target.value === 'unit') {
        recurForm.dailyIncluded = false;
      }
    }

    if (propName === 'DayExact') {
      recurForm.WeekFrequency = null;
      recurForm.weeklyRadio = null;
    }

    if (propName === 'DayPattern') {
      recurForm.DayPatternDays = null;
    }

    if (propName === 'MonthPattern') {
      recurForm.MonthPatternDays = null;
    }

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.GobKey,
      Value: { ...recurForm, [propName]: e.target.value }
    }));
  }

  onCheckboxChange = (e, propName) => {
    if (propName === 'dailyIncluded') {
      if (!e.target.checked) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GobKey,
          Value: { ...this.props.RecurForm, [propName]: e.target.checked, ['FloatingDays']: 0 }
        }))
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GobKey,
          Value: { ...this.props.RecurForm, [propName]: e.target.checked }
        }))
      }
    } else if (propName === 'DayPatternDays') {
      if (this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays.includes(e.target.id)) {
        let replacedDayPatternDays = this.props.RecurForm.DayPatternDays.replace(e.target.id, '');
        let dayPattern = helpers.dayPatternEncoder(replacedDayPatternDays);
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GobKey,
          Value: { ...this.props.RecurForm, [propName]: replacedDayPatternDays, DayPattern: dayPattern }
        }))
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GobKey,
          Value: { ...this.props.RecurForm, [propName]: this.props.RecurForm.DayPatternDays + e.target.id }
        }))
      }
    } else if (propName === 'MonthPatternDays') {
      if (this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes(e.target.id)) {
        let replacedMonthPatternDays = this.props.RecurForm.MonthPatternDays.replace(e.target.id, '');
        let monthPattern = helpers.monthPatternEncoder(replacedMonthPatternDays);
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GobKey,
          Value: { ...this.props.RecurForm, [propName]: replacedMonthPatternDays, MonthPattern: monthPattern }
        }))
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GobKey,
          Value: { ...this.props.RecurForm, [propName]: this.props.RecurForm.MonthPatternDays + e.target.id }
        }))
      }
    }
  }

  createSchedule = () => {
    let validationErrors = this.validateSubmission();
    validationErrors = validationErrors.map((elem) => { return <li>{elem}<br /></li> });
    validationErrors.pop();
    if (validationErrors.length == 0) {
      this.uploadSchedule();
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Error Submitting Recurrence Form', Message: <p><ul className='hide-list-markers'>{validationErrors}</ul> </p> }
      }));
    }
  }

  selectSchedule = () => {
    let validationErrors = this.validateSubmission();
    validationErrors = validationErrors.map((elem) => { return <li>{elem}<br /></li> });
    validationErrors.pop();
    if (validationErrors.length == 0) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'btn_RecurUseExistingSchedule',
        Value: true
      }));
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Error Submitting Recurrence Form', Message: <p><ul className='hide-list-markers'>{validationErrors}</ul> </p> }
      }));
    }
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_SelectSchedule',
      Value: false
    }));
  }

  viewSchedule = () => {
    let validationErrors = this.validateSubmission();
    validationErrors = validationErrors.map((elem) => { return <li>{elem}<br /></li> });
    validationErrors.pop();
    if (validationErrors.length == 0) {
      let dayPattern = this.props.RecurForm.DayPattern;
      let monthPattern = this.props.RecurForm.MonthPattern;
      if (this.props.RecurForm.DayPatternDays) {
        dayPattern = helpers.dayPatternEncoder(this.props.RecurForm.DayPatternDays);
      }
      if (this.props.RecurForm.MonthPatternDays) {
        monthPattern = helpers.monthPatternEncoder(this.props.RecurForm.MonthPatternDays);
      }
      let body = {
        SWID: this.props.SWID,
        Parameter: JSON.stringify({ ...this.props.RecurForm, DayPattern: dayPattern, MonthPattern: monthPattern })
      }
      actions.ApiRequest('List/GetList', body, (result) => {
        let calendarDates = [];
        result.forEach(x => {
          calendarDates.push(x.nextDate);
        })
        this.setState({ calendarDay: new Date(calendarDates[0]) });
        // this.props.dispatch(actions.UpdateProp({
        //   Key: 'rcr_CalendarDay',
        //   Value: new Date(calendarDates[0])
        // }))
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GobKey,
          Value: { ...this.props.RecurForm, CalendarDates: calendarDates }
        }));
      });
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Error Submitting Recurrence Form', Message: <p><ul className='hide-list-markers'>{validationErrors}</ul> </p> }
      }));
    }
  }

  resetForm = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_RecurSelected',
      Value: undefined
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene',
      Value: undefined
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.GobKey,
      Value: { 'ScheduleTypeLid': this.props.FixedLid, 'YearlyFrequency': 1, 'weeklyRadio': null, 'DayInstance': 1 }
    }));
  }

  uploadSchedule = () => {
    let schedEntityInsertKey = helpers.getInsertKey();
    let additionalSaveData = [];
    let propmap = new Map();

    if (this.props.RecurForm.ScheduleTypeLid == this.props.FixedLid) {
      for (const property in this.props.RecurForm) {
        if (property === 'ScheduleTypeLid' || property === 'FixedSchedType' || property === 'DayFrequency' || property === 'DayExact' || property === 'WeekFrequency' || property === 'DayInstance' || property === 'MonthFrequency' || property === 'YearlyFrequency' || property === 'MaxOccurrences' || property === 'StartDate' || property === 'EndDate' || property === 'DayPattern') {
          propmap.set(property, this.props.RecurForm[property]);
        } else if (property === 'DayPatternDays' && this.props.RecurForm[property]) {
          propmap.set('DayPattern', helpers.dayPatternEncoder(this.props.RecurForm[property]));
        } else if (property === 'MonthPatternDays' && this.props.RecurForm[property]) {
          propmap.set('MonthPattern', helpers.monthPatternEncoder(this.props.RecurForm[property]));
        }
      }
    }
    if (this.props.RecurForm.ScheduleTypeLid == this.props.FloatingLid) {
      for (const property in this.props.RecurForm) {
        if (property == 'FloatingDays' || property == 'ScheduleTypeLid' || property == 'UnitTypeLid' || property == 'UnitFrequency' || property == 'UnitStart' || property == 'MaxOccurrences' || property == 'StartDate' || property == 'EndDate') {
          propmap.set(property, this.props.RecurForm[property]);
        }
      }
    }
    propmap.forEach((value, key) => {
      additionalSaveData.push({
        Id: null,
        InsertKey: schedEntityInsertKey,
        Table: 'rcr.ScheduleEntity',
        Column: key,
        IsBaseTable: true,
        Value: value
      })
    })

    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_RecurrenceData',
      Value: { ...this.props.Recurrence, ScheduleSaveData: additionalSaveData, Schedule: null }
    }))
    this.resetForm();
  }

  validateSubmission = () => {
    let validationErrors = '';
    let validationMap = this.props.RecurForm.validation;

    if (!this.props.RecurForm.StartDate) {
      validationErrors += 'Start date field is required. ';
      validationMap.set('startDate', false);
    } else {
      validationMap.set('startDate', true);
    }

    if (!this.props.RecurForm.endByRadio) {
      validationErrors += 'You must make an end date selection. ';
    } else if (this.props.RecurForm.endByRadio === 'endByDate') {
      if (!this.props.RecurForm.EndDate) {
        validationErrors += 'You must select an end date. ';
        validationMap.set('endByDate', false);
        validationMap.set('endAfterOcc', true);
      } else {
        validationMap.set('endByDate', true);
        validationMap.set('endAfterOcc', true);
      }
    } else if (this.props.RecurForm.endByRadio === 'endAfterOcc') {
      if (!this.props.RecurForm.MaxOccurrences) {
        validationErrors += 'You must enter the number of occurences to end the schedule after. ';
        validationMap.set('endAfterOcc', false);
        validationMap.set('endByDate', true);
      } else {
        if (this.props.RecurForm.MaxOccurrences <= 0) {
          validationErrors += 'You must enter a value of 1 or greater for number of Occurences. ';
          validationMap.set('endAfterOcc', false);
          validationMap.set('endByDate', true);
        } else {
          validationMap.set('endAfterOcc', true);
          validationMap.set('endByDate', true);
        }
      }
    }

    //fixed sched validation
    if (this.props.RecurForm.ScheduleTypeLid == this.props.FixedLid) {
      //day
      if (this.props.RecurForm.FixedSchedType === '1') {
        if (!this.props.RecurForm.dailyRadio) {
          validationErrors += 'You must select an option from the Daily column. ';
        } else {
          validationMap.set('dayFrequency', true);
          if (this.props.RecurForm.dailyRadio === 'daily2') {
            if (!this.props.RecurForm.DayFrequency) {
              validationErrors += 'You must enter a value for Every _ Days. ';
              validationMap.set('dayFrequency', false);
            } else if (this.props.RecurForm.DayFrequency < 1) {
              validationErrors += 'You must enter a value greater than 0 for Every _ Days. ';
              validationMap.set('dayFrequency', false);
            }
          }
        }
      }
      //week
      if (this.props.RecurForm.FixedSchedType === '2') {
        validationMap.set('weekFrequency', true);
        validationMap.set('dayPatternChecks', true);
        if (!this.props.RecurForm.WeekFrequency) {
          validationErrors += 'You must enter a value for Every _ Weeks. ';
          validationMap.set('weekFrequency', false);
        } else if (this.props.RecurForm.WeekFrequency < 1) {
          validationErrors += 'You must enter a value greater than 0 for Every _ Weeks. ';
          validationMap.set('weekFrequency', false);
        }
        let isDayChecked = false;
        if (this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays != 'undefined') {
          isDayChecked = true;
        }
        if (!isDayChecked) {
          validationErrors += 'You must check atleast 1 day of the week. ';
          validationMap.set('dayPatternChecks', false);
        }
      }
      //month
      if (this.props.RecurForm.FixedSchedType === '3') {
        if (!this.props.RecurForm.monthlyRadio) {
          validationErrors += 'You must select an option from the Monthly column. ';
        } else {
          validationMap.set('dayExact', true);
          validationMap.set('monthFrequency', true);
          validationMap.set('dayInstance', true);
          validationMap.set('monthPatternChecks', true);
          if (this.props.RecurForm.monthlyRadio === 'monthly1') {
            if (!this.props.RecurForm.DayExact) {
              validationErrors += 'You must enter the Specific Day of the Month. ';
              validationMap.set('dayExact', false);
            } else if (this.props.RecurForm.DayExact < 1) {
              validationErrors += 'You must enter a value greater than 0 for Specific Day of the Month. ';
              validationMap.set('dayExact', false);
            }
            if (!this.props.RecurForm.MonthFrequency) {
              validationErrors += 'You must enter a value for Every _ Month(s). ';
              validationMap.set('monthFrequency', false);
            } else if (this.props.RecurForm.MonthFrequency < 1) {
              validationErrors += 'You must enter a value greater than 0 for Every _ Month(s). ';
              validationMap.set('monthFrequency', false);
            }
          }
          if (this.props.RecurForm.monthlyRadio === 'monthly2') {
            if (!this.props.RecurForm.MonthFrequency) {
              validationErrors += 'You must enter a value for Every _ Month(s). ';
              validationMap.set('monthFrequency', false);
            } else if (this.props.RecurForm.MonthFrequency < 1) {
              validationErrors += 'You must enter a value greater than 0 for Every _ Month(s). ';
              validationMap.set('monthFrequency', false);
            }
          }
          if (this.props.RecurForm.monthlyRadio === 'monthly3') {
            let isMonthChecked = false;
            if (this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays != 'undefined') {
              isMonthChecked = true;
            }
            if (!isMonthChecked) {
              validationErrors += 'You must check atleast 1 month of the year. ';
              validationMap.set('monthPatternChecks', false);
            }
          }
        }
      }
      //yearly
      if (this.props.RecurForm.FixedSchedType === '4') {
        validationMap.set('yearlyFrequency', true);
        validationMap.set('dayExact', true);
        if (!this.props.RecurForm.YearlyFrequency) {
          validationErrors += 'You must enter a value for Every _ year(s). ';
          validationMap.set('yearlyFrequency', false);
        } else if (this.props.RecurForm.YearlyFrequency < 1) {
          validationErrors += 'You must enter a value greater than 0 for Every _ year(s). ';
          validationMap.set('yearlyFrequency', false);
        }
        if (!this.props.RecurForm.yearlyRadio) {
          validationErrors += 'You must select an option from the Yearly column. ';
        } else {
          if (this.props.RecurForm.yearlyRadio === 'yearly1') {
            if (!this.props.RecurForm.DayExact) {
              validationErrors += 'You must enter the Specific Day of the Month. ';
              validationMap.set('dayExact', false);
            }
          }
        }
      }
    }

    //float/unit sched validation
    if (this.props.RecurForm.ScheduleTypeLid == this.props.FloatingLid) {
      if (!this.props.RecurForm.floatSelection) {
        validationErrors += 'You must select the Daily or Unit radio button. ';
      } else if (this.props.RecurForm.floatSelection === 'daily') {
        if (!this.props.RecurForm.FloatingDays) {
          validationErrors += 'You must enter a value for Every _ Days. ';
          validationMap.set('floatingDays', false);
        } else if (this.props.RecurForm.FloatingDays < 1) {
          validationErrors += 'You must enter a value greater than 0 for Every _ Days. ';
          validationMap.set('floatingDays', false);
        } else {
          validationMap.set('floatingDays', true);
        }
      } else if (this.props.RecurForm.floatSelection === 'unit') {
        if (!this.props.RecurForm.UnitStart) {
          validationErrors += 'You must enter a value for Unit Start. ';
          validationMap.set('unitStart', false);
        } else if (this.props.RecurForm.UnitStart < 1) {
          validationErrors += 'You must enter a value greater than 0 for Unit Start. ';
          validationMap.set('unitStart', false);
        } else {
          validationMap.set('unitStart', true);
        }
        if (!this.props.RecurForm.UnitFrequency) {
          validationErrors += 'You must enter a value for Unit Frequency. ';
          validationMap.set('unitFrequency', false);
        } else if (this.props.RecurForm.UnitFrequency < 1) {
          validationErrors += 'You must enter a value greater than 0 for Unit Frequency. ';
          validationMap.set('unitFrequency', false);
        } else {
          validationMap.set('unitFrequency', true);
        }
        if (this.props.RecurForm.dailyIncluded) {
          if (!this.props.RecurForm.FloatingDays) {
            validationErrors += 'You must enter a value for Every _ Days. ';
            validationMap.set('floatingDays', false);
          } else if (this.props.RecurForm.FloatingDays < 1) {
            validationErrors += 'You must enter a value greater than 0 for Every _ Days. ';
            validationMap.set('floatingDays', false);
          } else {
            validationMap.set('floatingDays', true);
          }
        }
      }
    }
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.GobKey,
      Value: { ...this.props.RecurForm, validation: validationMap }
    }));
    let validationArr = validationErrors.split('.');
    return validationArr;
  }

  getUnitTypeLids = () => {
    let vals = helpers.getLookupItems(1699); //lookup set for unit type lid
    return vals;
  }

  changeCalendarDate = (e) => {
    this.setState({ calendarDay: e.activeStartDate });
    // this.props.dispatch(actions.UpdateProp({
    //   Key: 'rcr_CalendarDay',
    //   Value: e.activeStartDate
    // }))
  }

  render() {
    return (
      <div className='recurrence-widget'>
        <div>
          <div className='title-label recurrence-form-title'>
            <div><h3>Recurrence Form</h3></div>
          </div>
          <div className='recur-form'>
            <div className='recur-form-row'>
              <label>
                Schedule Type:
                <select disabled={this.props.ReadOnly} id='scheduleTypeDrop' value={this.props.RecurForm && this.props.RecurForm.ScheduleTypeLid == this.props.FloatingLid ? this.props.FloatingLid : this.props.FixedLid} onChange={(e) => { this.onFormChange(e, 'ScheduleTypeLid') }}>
                  <option value={this.props.FixedLid}>Fixed</option>
                  <option value={this.props.FloatingLid}>Floating</option>
                </select>
              </label>
            </div>
            <div className={'recur-form-row ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('startDate')) ? '' : 'valid-failed')}>
              <label>
                Start Date:
                <input disabled={this.props.ReadOnly} type='date' id='startDateDate' value={(this.props.RecurForm && this.props.RecurForm.StartDate ? this.props.RecurForm.StartDate : "")} onChange={(e) => { this.onFormChange(e, 'StartDate') }}></input>
              </label>
            </div>
            <div className='recur-form-row'>
              <div className={(this.props.RecurForm && ((this.props.RecurForm.endByRadio && this.props.RecurForm.endByRadio !== 'endByDate')) ? 'disabled-recur' : '')}>
                <label>
                  <input disabled={this.props.ReadOnly} type='radio' name='endBy' id='endByDate' value='endByDate' checked={this.props.RecurForm && this.props.RecurForm.endByRadio === 'endByDate'} onChange={(e) => { this.onFormChange(e, 'endByRadio') }}></input>
                  <span className='recur-radio recur-check'></span>
                  <div>End by<input disabled={this.props.ReadOnly} type='date' id='endByDateDate' value={(this.props.RecurForm && this.props.RecurForm.EndDate ? this.props.RecurForm.EndDate : "")} className={(this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('endByDate') ? '' : 'valid-failed')} onChange={(e) => { this.onFormChange(e, 'EndDate') }}></input></div>
                </label>
              </div>
              <div className={(this.props.RecurForm && ((this.props.RecurForm.endByRadio && this.props.RecurForm.endByRadio !== 'endAfterOcc')) ? 'disabled-recur' : '')}>
                <label>
                  <input disabled={this.props.ReadOnly} type='radio' name='endBy' id='endAfterOcc' value='endAfterOcc' checked={this.props.RecurForm && this.props.RecurForm.endByRadio === 'endAfterOcc'} onChange={(e) => { this.onFormChange(e, 'endByRadio') }}></input>
                  <span className='recur-radio recur-check'></span>
                  <div>End After<input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('endAfterOcc')) ? '' : 'valid-failed')} id="endAfterOccTxt" type="number" min="1" value={(this.props.RecurForm && this.props.RecurForm.MaxOccurrences ? this.props.RecurForm.MaxOccurrences : "")} onChange={(e) => { this.onFormChange(e, 'MaxOccurrences') }}></input> Occurences</div>
                </label>
              </div>
              <div className={(this.props.RecurForm && ((this.props.RecurForm.endByRadio && this.props.RecurForm.endByRadio !== 'noEndDate')) ? 'disabled-recur' : '')}>
                <label>
                  <input disabled={this.props.ReadOnly} type='radio' name='endBy' id='noEndDate' value='noEndDate' checked={this.props.RecurForm && this.props.RecurForm.endByRadio === 'noEndDate'} onChange={(e) => { this.onFormChange(e, 'endByRadio') }}></input>
                  <span className='recur-radio recur-check'></span>
                  <div>No End Date</div>
                </label>
              </div>
            </div>

          </div>
          {this.props.RecurForm && this.props.RecurForm.ScheduleTypeLid == this.props.FixedLid &&
            <div className='rec-container'>
              <div className='fixed-flex sched-type-selector'>
                <div value={(this.props.RecurForm && this.props.RecurForm.MaxOccurrences ? this.props.RecurForm.MaxOccurrences : "")} className={(this.props.RecurForm && ((this.props.RecurForm.FixedSchedType && this.props.RecurForm.FixedSchedType != '1')) ? 'disabled-recur' : '')}>
                  <label>
                    <input disabled={this.props.ReadOnly} type='radio' name='FixedSchedType' id='1' value='1' checked={this.props.RecurForm && this.props.RecurForm.FixedSchedType == '1'} onChange={(e) => { this.onFormChange(e, 'FixedSchedType') }}></input>
                    <span className='recur-radio recur-check'></span>
                    <div>Daily</div>
                  </label>
                </div>
                <div className={(this.props.RecurForm && ((this.props.RecurForm.FixedSchedType && this.props.RecurForm.FixedSchedType != '2')) ? 'disabled-recur' : '')}>
                  <label>
                    <input disabled={this.props.ReadOnly} type='radio' name='FixedSchedType' id='2' value='2' checked={this.props.RecurForm && this.props.RecurForm.FixedSchedType == '2'} onChange={(e) => { this.onFormChange(e, 'FixedSchedType') }}></input>
                    <span className='recur-radio recur-check'></span>
                    <div>Weekly</div>
                  </label>
                </div>
                <div className={(this.props.RecurForm && ((this.props.RecurForm.FixedSchedType && this.props.RecurForm.FixedSchedType != '3')) ? 'disabled-recur' : '')}>
                  <label>
                    <input disabled={this.props.ReadOnly} type='radio' name='FixedSchedType' id='3' value='3' checked={this.props.RecurForm && this.props.RecurForm.FixedSchedType == '3'} onChange={(e) => { this.onFormChange(e, 'FixedSchedType') }}></input>
                    <span className='recur-radio recur-check'></span>
                    <div>Monthly</div>
                  </label>
                </div>
                <div className={(this.props.RecurForm && ((this.props.RecurForm.FixedSchedType && this.props.RecurForm.FixedSchedType != '4')) ? 'disabled-recur' : '')}>
                  <label>
                    <input disabled={this.props.ReadOnly} type='radio' name='FixedSchedType' id='4' value='4' checked={this.props.RecurForm && this.props.RecurForm.FixedSchedType == '4'} onChange={(e) => { this.onFormChange(e, 'FixedSchedType') }}></input>
                    <span className='recur-radio recur-check'></span>
                    <div>Yearly</div>
                  </label>
                </div>
              </div>
              <div className='fixed-flex rec-form-options'>
                {this.props.RecurForm && this.props.RecurForm.FixedSchedType == '1' &&
                  <div className='rec-child'>
                    <h4>Daily</h4>
                    <div className={(this.props.RecurForm && ((this.props.RecurForm.dailyRadio && this.props.RecurForm.dailyRadio !== 'daily2')) ? 'disabled-recur' : '')}>
                      <label>
                        <input disabled={this.props.ReadOnly} type="radio" name='daily' value="daily2" id="daily2" checked={this.props.RecurForm.dailyRadio === 'daily2'} onChange={(e) => { this.onFormChange(e, 'dailyRadio') }} />
                        <span className='recur-radio recur-check'></span>
                        <div>Every <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayFrequency')) ? '' : 'valid-failed')} type="number" min="1" id="daily2dayFrequency" value={(this.props.RecurForm && this.props.RecurForm.DayFrequency ? this.props.RecurForm.DayFrequency : "")} onChange={(e) => { this.onFormChange(e, 'DayFrequency') }} ></input> Day(s)</div>
                      </label>
                    </div>
                    <div className={(this.props.RecurForm && ((this.props.RecurForm.dailyRadio && this.props.RecurForm.dailyRadio !== 'daily1')) ? 'disabled-recur' : '')}>
                      <label>
                        <input disabled={this.props.ReadOnly} type="radio" name='daily' value="daily1" id="daily1" checked={this.props.RecurForm.dailyRadio === 'daily1'} onChange={(e) => { this.onFormChange(e, 'dailyRadio') }} />
                        <span className='recur-radio recur-check'></span>
                        <div>Every weekday</div>
                      </label>
                    </div>
                  </div>}
                {this.props.RecurForm && this.props.RecurForm.FixedSchedType == '2' &&
                  <div className='rec-child'>
                    <h4>Weekly</h4>
                    <div>
                      <div>Recurs every <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('weekFrequency')) ? '' : 'valid-failed')} type="number" min="1" id="weekly1weekFrequency" value={(this.props.RecurForm && this.props.RecurForm.WeekFrequency ? this.props.RecurForm.WeekFrequency : "")} onChange={(e) => { this.onFormChange(e, 'WeekFrequency') }} /> week(s) on: </div>
                      <div>
                        <div className={'check-container ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayPatternChecks')) ? '' : 'valid-failed')}>
                          <label className="check-child">
                            <div>
                              <input disabled={this.props.ReadOnly} type="checkbox" id="Monday" checked={this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays.includes('Monday')} onChange={(e) => { this.onCheckboxChange(e, 'DayPatternDays') }} className='dayPatternChecks'></input>
                              <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayPatternChecks')) ? '' : ' valid-failed')}></span>
                              <div>Mon</div>
                            </div>
                          </label>
                          <label className="check-child">
                            <div>
                              <input disabled={this.props.ReadOnly} type="checkbox" id="Tuesday" checked={this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays.includes('Tuesday')} onChange={(e) => { this.onCheckboxChange(e, 'DayPatternDays') }} className='dayPatternChecks'></input>
                              <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayPatternChecks')) ? '' : ' valid-failed')}></span>
                              <div>Tue</div>
                            </div>
                          </label>
                          <label className="check-child">
                            <div>
                              <input disabled={this.props.ReadOnly} type="checkbox" id="Wednesday" checked={this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays.includes('Wednesday')} onChange={(e) => { this.onCheckboxChange(e, 'DayPatternDays') }} className='dayPatternChecks'></input>
                              <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayPatternChecks')) ? '' : ' valid-failed')}></span>
                              <div>Wed</div>
                            </div>
                          </label>
                          <label className="check-child">
                            <div>
                              <input disabled={this.props.ReadOnly} type="checkbox" id="Thursday" checked={this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays.includes('Thursday')} onChange={(e) => { this.onCheckboxChange(e, 'DayPatternDays') }} className='dayPatternChecks'></input>
                              <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayPatternChecks')) ? '' : ' valid-failed')}></span>
                              <div>Thu</div>
                            </div>
                          </label>
                          <label className="check-child">
                            <div>
                              <input disabled={this.props.ReadOnly} type="checkbox" id="Friday" checked={this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays.includes('Friday')} onChange={(e) => { this.onCheckboxChange(e, 'DayPatternDays') }} className='dayPatternChecks'></input>
                              <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayPatternChecks')) ? '' : ' valid-failed')}></span>
                              <div>Fri</div>
                            </div>
                          </label>
                          <label className="check-child">
                            <div>
                              <input disabled={this.props.ReadOnly} type="checkbox" id="Saturday" checked={this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays.includes('Saturday')} onChange={(e) => { this.onCheckboxChange(e, 'DayPatternDays') }} className='dayPatternChecks'></input>
                              <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayPatternChecks')) ? '' : ' valid-failed')}></span>
                              <div>Sat</div>
                            </div>
                          </label>
                          <label className="check-child">
                            <div>
                              <input disabled={this.props.ReadOnly} type="checkbox" id="Sunday" checked={this.props.RecurForm.DayPatternDays && this.props.RecurForm.DayPatternDays.includes('Sunday')} onChange={(e) => { this.onCheckboxChange(e, 'DayPatternDays') }} className='dayPatternChecks'></input>
                              <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayPatternChecks')) ? '' : ' valid-failed')}></span>
                              <div>Sun</div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>}
                {this.props.RecurForm && this.props.RecurForm.FixedSchedType == '3' &&
                  <div className='rec-child'>
                    <h4>Monthly</h4>
                    <div className={(this.props.RecurForm && ((this.props.RecurForm.monthlyRadio && this.props.RecurForm.monthlyRadio !== 'monthly1')) ? 'disabled-recur' : '')}>
                      <label>
                        <input disabled={this.props.ReadOnly} type="radio" name='monthly' value="monthly1" id="monthly1" checked={this.props.RecurForm.monthlyRadio === 'monthly1'} onChange={(e) => { this.onFormChange(e, 'monthlyRadio') }} />
                        <span className='recur-radio recur-check'></span>
                        <div>Day <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayExact')) ? '' : 'valid-failed')} type="number" min="1" id="monthly1dayExact" value={(this.props.RecurForm && this.props.RecurForm.DayExact ? this.props.RecurForm.DayExact : "")} onChange={(e) => { this.onFormChange(e, 'DayExact') }} /> of every <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthFrequency')) ? '' : 'valid-failed')} type="number" min="1" id="monthly1monthFrequency" value={(this.props.RecurForm && this.props.RecurForm.MonthFrequency ? this.props.RecurForm.MonthFrequency : "")} onChange={(e) => { this.onFormChange(e, 'MonthFrequency') }} /> Month(s)</div>
                      </label>
                    </div>
                    <div className={(this.props.RecurForm && ((this.props.RecurForm.monthlyRadio && this.props.RecurForm.monthlyRadio !== 'monthly2')) ? 'disabled-recur' : '')}>
                      <label>
                        <input disabled={this.props.ReadOnly} type="radio" name='monthly' value="monthly2" id="monthly2" checked={this.props.RecurForm.monthlyRadio === 'monthly2'} onChange={(e) => { this.onFormChange(e, 'monthlyRadio') }} />
                        <span className='recur-radio recur-check'></span>
                        <div>The <select disabled={this.props.ReadOnly} id="monthly2dayinstance" value={this.props.RecurForm.DayInstance} onChange={(e) => { this.onFormChange(e, 'DayInstance') }}><option value='1'>{'1st'}</option><option value='2'>{'2nd'}</option><option value='3'>{'3rd'}</option><option value='4'>{'4th'}</option><option value='5'>{'Last'}</option></select> <select disabled={this.props.ReadOnly} id="monthly2dayPattern" value={this.props.RecurForm.DayPattern} onChange={(e) => { this.onFormChange(e, 'DayPattern') }}><option value='1'>{'Monday'}</option><option value='2'>{'Tuesday'}</option><option value='4'>{'Wednesday'}</option><option value='8'>{'Thursday'}</option><option value='16'>{'Friday'}</option><option value='32'>{'Saturday'}</option><option value='64'>{'Sunday'}</option></select> of every <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthFrequency')) ? '' : 'valid-failed')} type="number" min="1" id="monthly2monthFrequency" value={(this.props.RecurForm && this.props.RecurForm.MonthFrequency ? this.props.RecurForm.MonthFrequency : "")} onChange={(e) => { this.onFormChange(e, 'MonthFrequency') }} /> month(s)</div>
                      </label>
                    </div>
                    <div className={(this.props.RecurForm && ((this.props.RecurForm.monthlyRadio && this.props.RecurForm.monthlyRadio !== 'monthly3')) ? 'disabled-recur' : '')}>
                      <label>
                        <input disabled={this.props.ReadOnly} type="radio" name='monthly' value="monthly3" id="monthly3" checked={this.props.RecurForm.monthlyRadio === 'monthly3'} onChange={(e) => { this.onFormChange(e, 'monthlyRadio') }} />
                        <span className='recur-radio recur-check'></span>
                        <div>On the <select disabled={this.props.ReadOnly} id="monthly3dayinstance" value={this.props.RecurForm.DayInstance} onChange={(e) => { this.onFormChange(e, 'DayInstance') }}><option value='1'>{'1st'}</option><option value='2'>{'2nd'}</option><option value='3'>{'3rd'}</option><option value='4'>{'4th'}</option><option value='5'>{'Last'}</option></select> <select id="monthly3dayPattern" disabled={this.props.ReadOnly} value={this.props.RecurForm.DayPattern} onChange={(e) => { this.onFormChange(e, 'DayPattern') }}><option value='1'>{'Monday'}</option><option value='2'>{'Tuesday'}</option><option value='4'>{'Wednesday'}</option><option value='8'>{'Thursday'}</option><option value='16'>{'Friday'}</option><option value='32'>{'Saturday'}</option><option value='64'>{'Sunday'}</option></select> of </div>
                        <div>
                          <div className={'check-container ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : 'valid-failed')}>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="January" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('Jan')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Jan</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="February" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('Feb')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Feb</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="March" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('March')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Mar</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="April" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('April')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Apr</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="May" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('May')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>May</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="June" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('June')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>June</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="July" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('July')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>July</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="August" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('August')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Aug</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="September" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('Sept')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Sept</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="October" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('October')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Oct</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="November" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('November')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Nov</div>
                              </div>
                            </label>
                            <label className="check-child">
                              <div>
                                <input disabled={this.props.ReadOnly} type="checkbox" id="December" checked={this.props.RecurForm.MonthPatternDays && this.props.RecurForm.MonthPatternDays.includes('December')} onChange={(e) => { this.onCheckboxChange(e, 'MonthPatternDays') }} className="monthPatternChecks valid-failed"></input>
                                <span className={'recur-check' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('monthPatternChecks')) ? '' : ' valid-failed')}></span>
                                <div>Dec</div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>}
                {this.props.RecurForm && this.props.RecurForm.FixedSchedType == '4' &&
                  <div className='rec-child'>
                    <h4>Yearly</h4>
                    <div>Recur every <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('yearlyFrequency')) ? '' : 'valid-failed')} type='number' min="1" id="yearlyTxt" value={(this.props.RecurForm && this.props.RecurForm.YearlyFrequency ? this.props.RecurForm.YearlyFrequency : "")} onChange={(e) => { this.onFormChange(e, 'YearlyFrequency') }} /> year(s)</div>
                    <div className={(this.props.RecurForm && ((this.props.RecurForm.yearlyRadio && this.props.RecurForm.yearlyRadio !== 'yearly1')) ? 'disabled-recur' : '')}>
                      <label>
                        <input disabled={this.props.ReadOnly} type="radio" name='yearly' value="yearly1" id="yearly1" checked={this.props.RecurForm.yearlyRadio === 'yearly1'} onChange={(e) => { this.onFormChange(e, 'yearlyRadio') }} />
                        <span className='recur-radio recur-check'></span>
                        <div>On <select disabled={this.props.ReadOnly} id="yearly1monthPattern" value={this.props.RecurForm.MonthPattern} onChange={(e) => { this.onFormChange(e, 'MonthPattern') }}><option value='1'>{'January'}</option><option value='2'>{'February'}</option><option value='4'>{'March'}</option><option value='8'>{'April'}</option><option value='16'>{'May'}</option><option value='32'>{'June'}</option><option value='64'>{'July'}</option><option value='128'>{'August'}</option><option value='256'>{'September'}</option><option value='512'>{'October'}</option><option value='1024'>{'November'}</option><option value='2048'>{'December'}</option></select> <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('dayExact')) ? '' : 'valid-failed')} type="number" min="1" id="yearly1dayExact" value={(this.props.RecurForm && this.props.RecurForm.DayExact ? this.props.RecurForm.DayExact : "")} onChange={(e) => { this.onFormChange(e, 'DayExact') }} /></div>
                      </label>
                    </div>
                    <div className={(this.props.RecurForm && ((this.props.RecurForm.yearlyRadio && this.props.RecurForm.yearlyRadio !== 'yearly2')) ? 'disabled-recur' : '')}>
                      <label>
                        <input disabled={this.props.ReadOnly} type="radio" name='yearly' value="yearly2" id="yearly2" checked={this.props.RecurForm.yearlyRadio === 'yearly2'} onChange={(e) => { this.onFormChange(e, 'yearlyRadio') }} />
                        <span className='recur-radio recur-check'></span>
                        <div>On the <select disabled={this.props.ReadOnly} id="yearly2dayInstance" value={this.props.RecurForm.DayInstance} onChange={(e) => { this.onFormChange(e, 'DayInstance') }}><option value='1'>{'1st'}</option><option value='2'>{'2nd'}</option><option value='3'>{'3rd'}</option><option value='4'>{'4th'}</option><option value='5'>{'Last'}</option></select> <select disabled={this.props.ReadOnly} id="yearly2dayPattern" value={this.props.RecurForm.DayPattern} onChange={(e) => { this.onFormChange(e, 'DayPattern') }}><option value='1'>{'Monday'}</option><option value='2'>{'Tuesday'}</option><option value='4'>{'Wednesday'}</option><option value='8'>{'Thursday'}</option><option value='16'>{'Friday'}</option><option value='32'>{'Saturday'}</option><option value='64'>{'Sunday'}</option></select> of <select disabled={this.props.ReadOnly} id="yearly2monthPattern" value={this.props.RecurForm.MonthPattern} onChange={(e) => { this.onFormChange(e, 'MonthPattern') }}><option value='1'>{'January'}</option><option value='2'>{'February'}</option><option value='4'>{'March'}</option><option value='8'>{'April'}</option><option value='16'>{'May'}</option><option value='32'>{'June'}</option><option value='64'>{'July'}</option><option value='128'>{'August'}</option><option value='256'>{'September'}</option><option value='512'>{'October'}</option><option value='1024'>{'November'}</option><option value='2048'>{'December'}</option></select></div>
                      </label>
                    </div>
                  </div>}
              </div>
              <div className='fixed-flex'>
                <div className="recurrence-calendar">
                  {this.state.calendarDay && (this.state.calendarDay instanceof Date && !isNaN(this.state.calendarDay)) &&
                    <Calendar
                      calendarType='US'
                      selectRange={false}
                      // value={this.getCalendarValue()}
                      // defaultActiveStartDate={this.getCalendarValue()}
                      // defaultValue={this.getCalendarValue()}
                      // activeStartDate={this.state.calendarDay}
                      onActiveStartDateChange={(e) => { this.changeCalendarDate(e); }}
                      tileClassName={({ date, view }) => {
                        let formattedDate = helpers.formatDate(date);
                        if (this.props.RecurForm && this.props.RecurForm.CalendarDates) {
                          if (this.props.RecurForm.CalendarDates.find(x => x === (formattedDate))) {
                            if (this.props.RecurForm.CalendarDates[0] === formattedDate) {
                              return 'first-marked-day'
                            }
                            if (this.props.RecurForm.CalendarDates[this.props.RecurForm.CalendarDates.length - 1] === formattedDate) {
                              return 'last-marked-day'
                            }
                            return 'marked-day';
                          }
                        }
                      }}
                    />
                  }

                </div>
                <div className='recur-view-btn'>
                  <div className='text-widget'>
                    <div className='submit-rec-btn' onClick={this.viewSchedule}><p>View Schedule</p></div>
                  </div>
                </div>

              </div>
            </div>
          }
          {
            this.props.RecurForm && this.props.RecurForm.ScheduleTypeLid == this.props.FloatingLid &&
            <div className='rec-container'>
              <div className='rec-child2'>
                <h4>Daily</h4>
                <div className={"radio " + (this.props.RecurForm && (this.props.RecurForm.floatSelection && this.props.RecurForm.floatSelection !== 'daily') ? 'disabled-recur' : '')}>
                  <label>
                    <input disabled={this.props.ReadOnly} type="radio" name='float' value="daily" checked={this.props.RecurForm.floatSelection === 'daily'} onChange={(e) => { this.onFormChange(e, 'floatSelection') }} />
                    <span className='recur-radio recur-check'></span>
                    <div>Every <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('floatingDays')) ? '' : 'valid-failed')} type="number" min="1" id="floatDayText" value={(this.props.RecurForm && this.props.RecurForm.FloatingDays && !this.props.RecurForm.dailyIncluded ? this.props.RecurForm.FloatingDays : "")} onChange={(e) => { this.onFormChange(e, 'FloatingDays') }}></input> Days</div>
                  </label>
                </div>
              </div>
              <div className='rec-child2'>
                <h4>Unit</h4>
                <div>
                  <div className={"radio " + (this.props.RecurForm && (this.props.RecurForm.floatSelection && this.props.RecurForm.floatSelection !== 'unit') ? 'disabled-recur' : '')}>
                    <label>
                      <input disabled={this.props.ReadOnly} type="radio" name='float' value="unit" checked={this.props.RecurForm.floatSelection === 'unit'} onChange={(e) => { this.onFormChange(e, 'floatSelection') }} />
                      <span className='recur-radio recur-check'></span>
                      <div>Unit Based</div>
                    </label>
                    <div><br></br>
                      <label>
                        &nbsp; Unit Start &nbsp;
                        <input disabled={this.props.ReadOnly} className={'recur-text100 ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('unitStart')) ? '' : 'valid-failed')} type="number" id="unitStartText" value={(this.props.RecurForm && this.props.RecurForm.UnitStart ? this.props.RecurForm.UnitStart : "")} onChange={(e) => { this.onFormChange(e, 'UnitStart') }}></input>
                      </label><br></br><br></br>
                      <label>
                        &nbsp; Unit Frequency &nbsp;
                        <input disabled={this.props.ReadOnly} className={'recur-text100 ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('unitFrequency')) ? '' : 'valid-failed')} type="number" id="unitFrequencyText" value={(this.props.RecurForm && this.props.RecurForm.UnitFrequency ? this.props.RecurForm.UnitFrequency : "")} onChange={(e) => { this.onFormChange(e, 'UnitFrequency') }}></input>
                      </label><br></br><br></br>
                      <label>
                        &nbsp; Unit Type &nbsp;
                        <select disabled={this.props.ReadOnly} width='250px' value={this.props.RecurForm.UnitTypeLid} onChange={(e) => { this.onFormChange(e, 'UnitTypeLid') }}>
                          {this.getUnitTypeLids().map((x, idx) => (
                            <option key={idx} value={x.LookupItemId}>{x.Name1}</option>
                          ))}
                        </select>
                      </label><br></br><br></br><br></br>
                      <label>
                        <div>
                          <input disabled={this.props.ReadOnly} type="checkbox" id="unitDayCheck" checked={this.props.RecurForm.dailyIncluded && this.props.RecurForm.floatSelection !== 'daily'} onChange={(e) => { this.onCheckboxChange(e, 'dailyIncluded') }}></input>
                          <span className='recur-check single-check'></span>
                          <div>or Every <input disabled={this.props.ReadOnly} className={'small-textbox ' + ((this.props.RecurForm && this.props.RecurForm.validation && this.props.RecurForm.validation.get('floatingDays')) ? '' : 'valid-failed')} type="number" min="1" id="unitDayText" value={(this.props.RecurForm && this.props.RecurForm.FloatingDays && this.props.RecurForm.dailyIncluded ? this.props.RecurForm.FloatingDays : "")} onChange={(e) => { this.onFormChange(e, 'FloatingDays') }} ></input> Days</div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.props.RecurForm && !this.props.CopyExistingSchedule &&
            <div className='recur-view-btn'>
              <div className='text-widget'>
                <div className='submit-rec-btn' onClick={this.createSchedule}><p>Create Schedule</p></div>
              </div>
            </div>
          }
        </div>
      </div >
    );
  }
}

export default connect(mapStateToProps)(RecurScheduleForm);