import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import EntityCard from './EntityCard';
import TimeEntry from './TimeEntry';
import * as ttData from './ttData';
import { ReSelect, Clear } from './../../../Icons/Icons'
import cloneDeep from 'lodash/cloneDeep';

const mapStateToProps = (state, ownProps) => {
  let userKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'UserKey');
  let projLookup = ownProps.widget.Parameters.find(x => x.ParameterName === 'ProjectLTID');

  return {
    User: userKey ? state[userKey.ParameterValue] : undefined,
    Timesheet: state.ttr_Timesheet,
    CancelButton: state.ttr_TimesheetCancel,
    RefreshTimesheet: state.ttr_RefreshTimesheet || 0,
    MasterScenes: state.blu_MasterSceneList,
    SaveQueue: state.ttr_TimesheetSaveData,
    DateOverride: state.ttr_TimesheetDateOverride,
    ProjectLTID: projLookup ? Number(projLookup.ParameterValue) : undefined,
    SaveId: state.dbo_SaveId
  };
};

let prevSheet = null;

export class Timesheet extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('click', this.windowClick);
    document.addEventListener('keyup', this.keyPress);
    if (this.props.User && (!this.props.SaveQueue || this.props.SaveQueue.length === 0)) {
      this.getTimesheet();
    }
  }

  componentDidUpdate(prev) {
    if (this.userDidChange(this.props, prev)) {
      this.getTimesheet();
    }
    if (prev && prev.CancelButton.ClickId !== this.props.CancelButton.ClickId) {
      this.resetTimesheet();
    }
    if (prev && prev.RefreshTimesheet !== this.props.RefreshTimesheet) {
      this.getTimesheet();
    } else if (prev && prev.SaveId !== this.props.SaveId) {
      this.getTimesheet();
    }
  }

  resetTimesheet = () => {
    let activeIdx = this.props.Timesheet.Days.findIndex(x => x.Active);
    if (activeIdx !== -1) {
      prevSheet.Days.forEach(x => x.Active = false);
      prevSheet.Days[activeIdx].Active = true;
      prevSheet.Date = prevSheet.Days[activeIdx].Date;
    }

    this.props.dispatch(actions.UpdateProp({
      Key: 'ttr_Timesheet',
      Value: cloneDeep(prevSheet)
    }));
  }

  addRecord = (item) => {
    this.activeDay().TimeRecords.push(item);
    this.forceUpdate();
  }

  saveCancelDialog = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_Dialog',
      Value: {
        Title: 'Unsaved Changes',
        Message: 'Please Save or Cancel changes before continuing.'
      }
    }));
  }

  getTimesheet = (periodDelta = 0) => {
    if (!this.props.User)
      return;

    if (this.props.SaveQueue && this.props.SaveQueue.length > 0) {
      this.saveCancelDialog();
      return;
    }

    let sheet = this.props.Timesheet;
    let date = sheet ? sheet.Date : helpers.dateToInputFormat(new Date());

    if (this.props.DateOverride) {
      date = this.props.DateOverride;
      if (sheet) {
        sheet.DateOverride = helpers.formatDate(date);
      }
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_TimesheetDateOverride',
        Value: undefined
      }));
    }

    let body = {
      UserId: this.props.User.Id,
      Date: helpers.shortDate(date),
      PeriodDelta: periodDelta
    }

    if (this.props.Timesheet && this.props.Timesheet.Days) {
      let cleanDays = this.props.Timesheet.Days.map((x) => {
        return {
          ...x,
          TimeRecords: []
        };
      });
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_Timesheet',
        Value: {
          ...this.props.Timesheet,
          Days: cleanDays
        }
      }));
    }

    actions.ApiRequest('TimeTracking/GetTimesheet', body, (result) => {
      if (!result.Days) {
        console.error(result);
        return;
      }

      result.Date = sheet ? sheet.Date : date;
      result.resetEntry = true;

      this.setActiveDay(sheet, result);
      prevSheet = cloneDeep(result);

      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_Timesheet',
        Value: result
      }));
    });
  }

  setActiveDay = (sheet, result) => {
    if (sheet) {
      if (sheet.DateOverride) {
        let override = result.Days.find(x => x.Date === sheet.DateOverride);
        if (override) {
          result.Date = override.Date;
          override.Active = true;
        }
        return;
      }
      let activeIdx = sheet.Days.findIndex(x => x.Active);
      if (activeIdx !== -1) {
        result.Days[activeIdx].Active = true;
        result.Date = result.Days[activeIdx].Date;
      }
    } else {
      this.selectDate(result);
    }
  }

  selectDate = (sheet) => {
    console.log(helpers.dateToInputFormat(new Date()));
    let today = helpers.dateToInputFormat(sheet.Date ? new Date(helpers.shortDate(sheet.Date)) : new Date());
    let day = sheet.Days.find(x => x.Date === today);
    sheet.Date = today;
    if (day) {
      day.Active = true;
    }
  }

  userDidChange = (cur, prev) => {
    let curUser = cur.User;
    let prevUser = prev ? prev.User : null;
    if (!curUser)
      return false;

    return !prevUser || prevUser.Id !== curUser.Id;
  }

  changeDate = (e) => {
    console.log(e);
    this.props.Timesheet.Date = e.target.value;
    this.props.Timesheet.DateOverride = e.target.value;
    this.forceUpdate();
    this.getTimesheet();
  }

  nextPeriod = () => {
    this.getTimesheet(1);
  }

  prevPeriod = () => {
    this.getTimesheet(-1);
  }

  selectDay = (day) => {
    if (day.Active)
      return;

    if (this.props.SaveQueue && this.props.SaveQueue.length > 0) {
      this.saveCancelDialog();
      return;
    }

    this.props.Timesheet.Days.forEach(x => x.Active = false);
    this.props.Timesheet.Date = day.Date;
    day.Active = true;

    this.resetEntry();
    this.forceUpdate();
  }

  resetEntry = () => {
    this.props.Timesheet.resetEntry = true;
    this.props.dispatch(actions.UpdateProp({
      Key: 'ttr_RefreshEntry',
      Value: helpers.getId(10000)
    }));
  }

  noteChange = (e, row) => {
    row.Note = e.target.value;
    this.saveItem(row);
  }

  wageCodeChange = (e, row) => {
    row.WageCodeId = e.target.value;
    this.saveItem(row);
  }

  durationChange = (e, row) => {
    row.Duration = e.target.value * 60;
    this.saveItem(row);
  }

  deleteItem = (item) => {
    item.IsDeleted = true;
    this.saveItem(item);
  }

  saveItem = (item) => {
    ttData.addSaveDataForRecord(this.props.dispatch, this.props.User.Id, this.props.Timesheet, item);
    this.forceUpdate();
  }

  SetEditMode = (idx) => {
    this.activeDay().TimeRecords[idx].EditMode = true;
    this.forceUpdate();
  }

  EditComplete = (idx) => {
    this.activeDay().TimeRecords[idx].EditMode = false;
    this.forceUpdate();
  }

  moveDayToggle = (row) => {
    let val = !row.ShowMoveDay;
    this.closeMoveDayMenu();
    row.ShowMoveDay = val;
    this.forceUpdate();
  }

  moveDay = (tr, day) => {
    if (day.Active)
      return;

    let active = this.activeDay();
    active.TimeRecords = active.TimeRecords.filter(x => x.Id !== tr.Id);
    day.TimeRecords.push(tr);
    tr.MoveToDate = day.Date;
    this.saveItem(tr);
  }

  activeDay = () => {
    return this.props.Timesheet.Days.find(x => x.Active);
  }

  moveDayIsOpen = () => {
    if (!this.props.Timesheet)
      return false;

    let moveDayOpen = false;
    this.props.Timesheet.Days.forEach((day) => {
      day.TimeRecords.forEach(tr => {
        if (tr.ShowMoveDay) {
          moveDayOpen = true;
        }
      });
    });

    return moveDayOpen;
  }

  closeMoveDayMenu = () => {
    this.props.Timesheet.Days.forEach(x => x.TimeRecords.forEach(y => y.ShowMoveDay = false));
  }

  windowClick = (e) => {
    if (this.moveDayIsOpen() && !helpers.elementOrAncestorHasClass(e.target, 'day-select')) {
      this.closeMoveDayMenu();
      this.forceUpdate();
    }
  }

  keyPress = (e) => {
    if (this.moveDayIsOpen() && e.key === 'Escape') {
      this.closeMoveDayMenu();
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowClick);
    document.removeEventListener('keyup', this.keyPress);
  }

  getSheetHeaderText = (sheet) => {
    if (!sheet)
      return '';

    let ppn = sheet.PayPeriodNumber;
    let start = helpers.shortDate(sheet.Days[0].Date);
    let end = helpers.shortDate(sheet.Days[sheet.Days.length - 1].Date);
    return `${ppn} (${start} - ${end})`;
  }

  dayTotal = (days) => {
    let totalDuration = days.reduce((prev, next) => {
      if (!next || !next.TimeRecords) {
        return prev;
      }
      prev += next.TimeRecords.reduce((trPrev, trNext) => {
        trPrev += trNext.Duration;
        return trPrev;
      }, 0);
      return prev;
    }, 0);

    return (totalDuration / 60).toFixed(2);
  }

  loadEntity = (entity) => {
    let body = {
      EntityId: entity.EntityId,
      EntityTypeId: entity.EntityTypeId
    };

    helpers.LoadEntity(this.props.dispatch, actions, body, 'blu_MasterScene', 'Initial');
  }

  select = (e) => {
    e.target.select();
  }

  getDayClasses = (day) => {
    let classes = [];

    if (day.IsWeekend)
      classes.push('weekend');

    if (day.Active)
      classes.push('active');

    day.TimeRecords.forEach(record => {
      if (record.TimeEntity.ProjectTypeLid === 999) {
        classes.push('leave-requested');
      }
    })

    return classes.join(' ');
  }

  render() {
    let sheet = this.props.Timesheet;
    let isReadOnly = false;
    let activeDay = null;
    if (sheet) {
      isReadOnly = sheet.UserPayperiod.Status === 2 || sheet.UserPayperiod.Status === 3;
      activeDay = sheet.Days.find(x => x.Active);
    }

    return (
      !sheet ? null :
        <div className="timesheet-widget">
          <div className="timesheet-header">
            <div className="user">{sheet.UserName}</div>
            <input type="date" className="date-picker" value={sheet.Date} onChange={this.changeDate} />
            <div className="pp-number">{this.getSheetHeaderText(sheet)}</div>
            <div className="sheet-status">
              {sheet.UserPayperiod.Status === 2 && <div>Submitted <div className="check-submit">✔</div></div>}
              {sheet.UserPayperiod.Status === 3 && <div>Approved <div className="check-approve">✔</div></div>}
            </div>
          </div>
          <div className="sheet-days">
            <div className="period-select noselect" onClick={this.prevPeriod}>
              <div>{'<'}</div>
            </div>
            {sheet.Days.map((day, idx) => (
              <div key={idx} className={"sheet-day " + this.getDayClasses(day)}
                onClick={() => { this.selectDay(day) }}>
                <div className="label">{day.Day}</div>
                <div className="date">{helpers.shortDate(day.Date)}</div>
                <div className="total">
                  <div>{this.dayTotal([day])}</div>
                </div>
              </div>
            ))}
            <div className="period-select noselect" onClick={this.nextPeriod}>
              <div>{'>'}</div>
            </div>
          </div>
          <div className="bottom-bar">
            <div className="total">
              <div>{'Week 1: ' + this.dayTotal(sheet.Days.slice(0, 7))}</div>
            </div>
            <div className="total">
              <div>{'Week 2: ' + this.dayTotal(sheet.Days.slice(7, 14))}</div>
            </div>
            <div className="period-total">
              <div>{'Total: ' + this.dayTotal(sheet.Days)}</div>
            </div>
          </div>
          <div className="time-records">
            {sheet.Days.filter(x => x.Active).map((day) => (
              day.TimeRecords.filter(x => !x.IsDeleted).map((tr, idx) => (
                tr.EditMode ?
                  <div key={idx} className={"time-edit" + (idx % 2 === 0 ? ' even' : ' odd')}>
                    <TimeEntry projectLTID={this.props.ProjectLTID} sheet={sheet} User={this.props.User} addRecord={this.addRecord} userId={this.props.User.Id} EditMode={true} RowData={tr} EditComplete={() => { this.EditComplete(idx) }} />
                  </div>
                  :
                  <div key={idx} className="tr-container">
                    <div className={"time-record" + (idx % 2 === 0 ? ' even' : ' odd')}>
                      {tr.TimeEntity && tr.TimeEntity.EntityId ?
                        <EntityCard
                          TimeEntity={tr.TimeEntity}
                          ShowReselect={true}
                          IsReadOnly={isReadOnly}
                          EditMode={() => { this.SetEditMode(idx) }}
                          LoadEntity={this.loadEntity} /> :
                        <div className="time-entity">
                          <div className="reselect" onClick={() => { this.SetEditMode(idx) }} >
                            <ReSelect />
                          </div>
                        </div>
                      }
                      <div className="time-code">
                        <div className="wage-code">
                          <select disabled={isReadOnly} value={tr.WageCodeId || ''} onChange={(e) => { this.wageCodeChange(e, tr) }}>
                            {sheet.WageCodes.map((x, wcIdx) => (
                              //if x.Id=0 set value to 'value selected' to make All default select option
                              <option key={wcIdx} value={x.Id}>{x.Name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="time">
                          <input type="number" step=".25" disabled={isReadOnly} value={(tr.Duration / 60)} onClick={this.select} onChange={(e) => { this.durationChange(e, tr) }} />
                        </div>
                      </div>
                      <div className={"time-comment" + (isReadOnly ? ' no-pad' : '')}>
                        <textarea type="text" disabled={isReadOnly} value={tr.Note || ''} onChange={(e) => { this.noteChange(e, tr) }} />
                      </div>
                      {!isReadOnly &&
                        <div className="delete-row" onClick={() => { this.deleteItem(tr) }}>
                          <Clear />
                        </div>}
                    </div>
                    {!isReadOnly &&
                      <div className="day-select">
                        <div className="triangle" onClick={() => { this.moveDayToggle(tr) }}></div>
                        {
                          tr.ShowMoveDay &&
                          <div className="day-select-container">
                            <div className="day-select-label">
                              <div>Move To</div>
                            </div>
                            <div className="day-select-items">
                              {sheet.Days.map((moveTo, dIdx) => (
                                <div key={dIdx}
                                  className={"day-item" + (moveTo.IsWeekend ? ' weekend' : '') + (moveTo.Active ? ' active' : '')}
                                  onClick={() => { this.moveDay(tr, moveTo) }}>
                                  <div>{moveTo.Day}</div>
                                  <div>{helpers.shortDate(moveTo.Date)}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      </div>}
                  </div>
              ))
            ))}
            {
              !isReadOnly &&
              <TimeEntry projectLTID={this.props.ProjectLTID} sheet={sheet} User={this.props.User} addRecord={this.addRecord} userId={this.props.User.Id} />
            }
            {
              <div className="day-total">
                <div className="spacer"></div>
                <div className="total-amount">
                  <div>{this.dayTotal([activeDay])}</div>
                </div>
                <div className="spacer-right"></div>
              </div>
            }
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(Timesheet);
