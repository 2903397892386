import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import FormControl from './FormControl';

const mapStateToProps = (state, ownProps) => {
  let sourceProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntitySource');
  let outProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityOutput');
  let eventSave = ownProps.widget.Parameters.find(x => x.ParameterName === 'EventSave');
  let trackChanges = ownProps.widget.Parameters.find(x => x.ParameterName === 'TrackChanges');
  let mapSwid = ownProps.widget.Parameters.find(x => x.ParameterName === 'MapSwid');
  let onLoadId = ownProps.widget.Parameters.find(x => x.ParameterName === 'OnLoadEntityId');
  let onLoadType = ownProps.widget.Parameters.find(x => x.ParameterName === 'OnLoadEntityType');
  let reqEntityId = ownProps.widget.Parameters.find(x => x.ParameterName === 'RequireSourceEntityId');
  let customSaveQueue = ownProps.widget.Parameters.find(x => x.ParameterName === 'CustomSaveQueue');
  let newEntityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'NewEntityKey');
  let newEntityTypeId = ownProps.widget.Parameters.find(x => x.ParameterName === 'NewEntityTypeId');
  let sourceTypeIdColumn = ownProps.widget.Parameters.find(x => x.ParameterName === 'SourceTypeIdColumn');
  let overrideParameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'OverrideParameter');
  let loadData = ownProps.widget.Parameters.find(x => x.ParameterName === 'LoadData');
  let formReadOnly = ownProps.widget.Parameters.find(x => x.ParameterName === 'FormReadOnly');
  let entityLoadScriptRunner = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityLoadScriptRunner');
  let formColumnWidth = ownProps.widget.Parameters.find(x => x.ParameterName === 'FormColumnWidth');
  let historySceneKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'HistorySceneKey');
  let tabHorizontal = ownProps.widget.Parameters.find(x => x.ParameterName === 'TabHorizontal');
  let haltSiteInit = ownProps.widget.Parameters.find(x => x.ParameterName === 'HaltSiteInitUntilLoaded');
  let onChangeScriptRunner = ownProps.widget.Parameters.find(x => x.ParameterName === 'OnChangeScriptRunner');

  if (!sourceProp && newEntityKey) {
    sourceProp = newEntityKey;
  }

  let isEventSave = eventSave ? helpers.stringToBool(eventSave.ParameterValue) : false;
  let saveQueue = isEventSave ? 'dbo_EventSaveData' : 'dbo_SaveData';
  saveQueue = customSaveQueue ? customSaveQueue.ParameterValue : saveQueue;

  return {
    EntitySource: sourceProp ? state[sourceProp.ParameterValue] : undefined,
    EntitySourceKey: sourceProp ? sourceProp.ParameterValue : undefined,
    CurrentEntity: outProp ? state[outProp.ParameterValue] : undefined,
    CurrentEntityKey: outProp ? outProp.ParameterValue : undefined,
    EntityMetadata: state.met_EntityMetadata,
    EventSave: isEventSave,
    TrackChanges: trackChanges ? helpers.stringToBool(trackChanges.ParameterValue) : true,
    OnLoadId: onLoadId ? onLoadId.ParameterValue : undefined,
    OnLoadTypeId: onLoadType ? Number(onLoadType.ParameterValue) : undefined,
    SWID: ownProps.widget.SceneWidgetId,
    MapSwid: mapSwid ? Number(mapSwid.ParameterValue) : undefined,
    RefreshProps: state.ent_RefreshProps,
    RequireSourceEntityId: reqEntityId ? helpers.stringToBool(reqEntityId.ParameterValue) : true,
    CustomSaveQueue: customSaveQueue ? customSaveQueue.ParameterValue : undefined,
    SaveQueue: customSaveQueue ? state[customSaveQueue.ParameterValue] : undefined,
    NewEntityKey: newEntityKey ? newEntityKey.ParameterValue : undefined,
    NewEntityTypeId: newEntityTypeId ? Number(newEntityTypeId.ParameterValue) : undefined,
    SourceTypeIdColumn: sourceTypeIdColumn ? sourceTypeIdColumn.ParameterValue : undefined,
    OverrideParameter: overrideParameter ? overrideParameter.ParameterValue : undefined,
    LoadData: loadData ? helpers.stringToBool(loadData.ParameterValue) : true,
    FormReadOnly: formReadOnly ? helpers.stringToBool(formReadOnly.ParameterValue) : false,
    ExistingSaveData: state[saveQueue] || [],
    EntityLoadScriptRunner: entityLoadScriptRunner ? entityLoadScriptRunner.ParameterValue : null,
    FormColumnWidth: formColumnWidth ? formColumnWidth.ParameterValue : null,
    HistorySceneKey: historySceneKey ? historySceneKey.ParameterValue : null,
    AltPress: state.AltPress || false,
    TabHorizontal: tabHorizontal ? helpers.stringToBool(tabHorizontal.ParameterValue) : false,
    HaltSiteInit: haltSiteInit ? helpers.stringToBool(haltSiteInit.ParameterValue) : false,
    OnChangeScriptRunner: onChangeScriptRunner ? onChangeScriptRunner.ParameterValue : null
  };
}

let FormInstance = {};
let EntityLoaded = {};

export class Form extends React.PureComponent {
  unmounted = false;
  constructor(props) {
    super(props);
    if (this.props.HaltSiteInit) {
      this.props.dispatch(actions.UpdateProp({ Key: 'HaltSiteInit', Value: true }));
    }
    let source = this.props.EntitySource;
    if (source && (source.EntityId || !this.props.RequireSourceEntityId) && (!this.props.CurrentEntity || !this.props.CurrentEntity.IsNew || source.EntityTypeId !== this.props.CurrentEntity.EntityTypeId)) {
      this.getFormData(this.props.EntitySource, true);
    }

    this.newEntityCheck();
    this.onLoadEntityCheck();
    this.refreshPropsCheck();
  }

  state = {
    params: helpers.paramsToObject(this.props.widget)
  };

  componentDidUpdate = (prevProps) => {
    setTimeout(() => {
      if (this.unmounted)
        return;

      this.onLoadEntityCheck();
      this.newEntityCheck();

      if (!this.props.EntitySource)
        return;

      if (this.props.NewEntityKey && !prevProps.EntitySource && this.props.EntitySource.IsNew && !this.props.EntitySource.NewEntityInit) {
        this.props.EntitySource.NewEntityInit = true;
        let emd = this.props.EntityMetadata.EntityTypes.find(x => x.TypeId === this.props.NewEntityTypeId);
        this.loadEntityShell(this.props.EntitySource, emd);
        this.addSaveDataForNewEntityProp();
      }

      if (this.props.RefreshProps && !this.props.RefreshProps[this.props.SWID]) {
        EntityLoaded[this.props.CurrentEntityKey] = null;
        this.getFormData(this.props.EntitySource, true);
        this.props.dispatch(actions.UpdateProp({
          Key: 'ent_RefreshProps',
          Value: { ...this.props.RefreshProps, [this.props.SWID]: true }
        }));
      } else {
        if (!prevProps.EntitySource || helpers.entId(prevProps.EntitySource) !== helpers.entId(this.props.EntitySource)) {
          this.getFormData(this.props.EntitySource);
        } else if (this.props.EntityMetadata.EntityTypes && !prevProps.EntityMetadata.EntityTypes) {
          this.getFormData(this.props.EntitySource);
        }
      }
    }, 0);
  }

  refreshPropsCheck = () => {
    if (this.props.RefreshProps && !this.props.RefreshProps[this.props.SWID]) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_RefreshProps',
        Value: { ...this.props.RefreshProps, [this.props.SWID]: true }
      }));
    }
  }

  newEntityCheck = () => {
    if (!this.props.EntitySource && !this.props.CurrentEntity && this.props.NewEntityKey) {
      let emd = this.props.EntityMetadata.EntityTypes.find(x => x.TypeId === this.props.NewEntityTypeId);
      let newSource = {
        EntityId: -1,
        EntityTypeId: this.props.NewEntityTypeId
      };

      this.loadEntityShell(newSource, emd);
    }
  }

  addSaveDataForNewEntityProp = () => {
    let triggerItem = this.props.SaveQueue.find(x => x.Id === -1);
    if (triggerItem) {
      triggerItem.Id = null;
      triggerItem.InsertKey = this.props.EntitySource.InsertKeys[triggerItem.Table];
      let prop = this.props.CurrentEntity.Properties.find(x => x.SaveData.Table === triggerItem.Table && x.SaveData.Column === triggerItem.Column);
      if (prop) {
        prop.Value = triggerItem.Value;
      }
      this.props.dispatch(actions.AddSaveData(triggerItem));
      this.forceUpdate();
    }
  }


  getSourceTypeId = (source) => {
    let typeId = source.EntityTypeId;
    if (this.props.SourceTypeIdColumn && source[this.props.SourceTypeIdColumn]) {
      typeId = source[this.props.SourceTypeIdColumn];
    }

    return typeId;
  }

  getFormData = (source, initialLoad = false) => {
    if (this.unmounted || this.props.NewEntityKey || !this.props.LoadData)
      return;

    let entKey = this.props.CurrentEntityKey;
    if (this.props.CurrentEntity && helpers.entId(this.props.CurrentEntity) === helpers.entId(source)) {
      if (!initialLoad || (EntityLoaded[entKey] && EntityLoaded[entKey] === helpers.entId(source))) {
        return;
      }
    }

    if (!this.props.EntityMetadata || !this.props.EntityMetadata.EntityTypes)
      return;

    let typeId = this.getSourceTypeId(source);

    let body = {
      EntityId: source.EntityId,
      EntityTypeId: typeId
    }

    let emd = this.props.EntityMetadata.EntityTypes.find(x => x.TypeId === typeId);
    if (!emd)
      return;

    this.loadEntityShell(source, emd);

    if (source.ShellOnly || source.EntityId < 0) {
      return;
    }

    FormInstance[this.props.SWID] = true;
    EntityLoaded[entKey] = helpers.entId(source);

    actions.ApiRequest('Entity/GetEntity', body, (result) => {
      if (helpers.entId(source) !== EntityLoaded[entKey]) {
        console.log('outdated form results blocked');
        return;
      }

      if (!result || !result.Properties || !this.props.CurrentEntity.Properties) {
        return;
      }

      let primaryText = result.PrimaryText;
      let secondaryText = result.SecondaryText;
      let keyRing = helpers.idValueArrayToObject(result.Properties);

      this.props.CurrentEntity.Properties = this.props.CurrentEntity.Properties.sort((a, b) => {
        let aVal = keyRing[a.Id] && keyRing[a.Id].Key ? Number(keyRing[a.Id].Key) === source.EntityId ? 0 : 1 : 1;
        let bVal = keyRing[b.Id] && keyRing[b.Id].Key ? Number(keyRing[b.Id].Key) === source.EntityId ? 0 : 1 : 1;
        return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
      });

      this.props.CurrentEntity.Properties.forEach(x => {
        let formData = keyRing[x.Id];
        if (formData && !x.HasSaveDataValue) {
          x.Value = formData.Value;
          let displayVal = helpers.entPropValue(x);

          primaryText = primaryText.replace(new RegExp('\\[' + x.Name + '\\]', 'g'), displayVal || '-');
          secondaryText = secondaryText.replace(new RegExp('\\[' + x.Name + '\\]', 'g'), displayVal || '-');

          if (x.SaveData) {
            x.SaveData.Id = formData.Key;
          }
        }
      });

      this.props.CurrentEntity.IsShell = false;
      this.props.CurrentEntity.EntityLabel = result.EntityLabel;
      this.props.CurrentEntity.PrimaryImage = result.PrimaryImage;
      this.props.CurrentEntity.HasTime = emd.HasTime;
      this.props.CurrentEntity.HasMaterial = emd.HasMaterial;
      this.props.CurrentEntity.HasEquipment = emd.HasEquipment;
      this.props.CurrentEntity.PrimaryText = primaryText;
      this.props.CurrentEntity.SecondaryText = secondaryText;
      this.props.CurrentEntity.ShowPrintReport = result.ShowPrintReport;
      this.props.CurrentEntity.AgencyId = result.AgencyId;
      this.props.CurrentEntity.DocumentCount = result.DocumentCount;

      if (result.AgencyId) {
        this.props.CurrentEntity.AgencyId = result.AgencyId
      }
      if (result.TenantId) {
        this.props.CurrentEntity.TenantId = result.TenantId;
      }
      if (result.Geometry) {
        this.props.CurrentEntity.Geometry = result.Geometry;
      }

      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntitySourceKey + 'OnLoad',
        Value: true
      }));

      this.entityLoadScriptRunner();
      this.props.dispatch(actions.SetCurrentEntity(this.props.CurrentEntity, this.props.CurrentEntityKey));
      // if (this.props.HaltSiteInit) {
      //   this.props.dispatch(actions.UpdateProp({ Key: 'HaltSiteInit', Value: false }));
      // }
    });
  }

  loadEntityShell = (source, emd) => {
    console.log('load shell');
    if (!this.props.EntityMetadata)
      return;

    if (source.IsNew) {
      this.entityLoadScriptRunner();
    }

    let typeId = this.getSourceTypeId(source);
    let shell = {
      IsShell: (source && source.IsNew) ? false : true,
      ...emd,
      PrimaryText: '',
      SecondaryText: '',
      EntityId: source.EntityId,
      EntityTypeId: typeId,
      IsNew: source.IsNew || false,
      Properties: emd.Properties.map((x) => {
        if (source.IsNew) {
          x.SaveData.Id = null;
          if (x.SaveData.Table) {
            x.SaveData.InsertKey = source.InsertKeys[x.SaveData.Table];
          }
          if (!x.DefaultSet) {
            x.Value = x.DefaultValue || '';
          }
        } else {
          x.SaveData.Id = source.EntityId;
          x.SaveData.InsertKey = null;
          x.Value = '';
        }

        //check for save data tied to this prop
        let valFromSaveData = null;
        if (x.SaveData && x.SaveData.Column) {
          try {
            let existingItem = this.props.ExistingSaveData.find(sd => {
              let isMatch = (sd.Id || sd.InsertKey).toString() === (x.SaveData.Id || x.SaveData.InsertKey).toString() && x.SaveData.Column === sd.Column && x.SaveData.Table === sd.Table;
              return isMatch;
            });
            if (existingItem) {
              valFromSaveData = existingItem.Value;
            }
          } catch { }
        }

        if (valFromSaveData !== null) {
          x.Value = valFromSaveData;
          x.HasSaveDataValue = true;
        } else {
          x.HasSaveDataValue = false;
        }

        return x;
      })
    }
    if (source.TenantId) {
      shell.TenantId = source.TenantId;
    }
    if (source.GeometryType) {
      shell.GeometryType = source.GeometryType;
    }

    if (source.IsNew) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntitySourceKey + 'OnLoad',
        Value: true
      }));
    }

    this.props.dispatch(actions.SetCurrentEntity(shell, this.props.CurrentEntityKey));
  }

  entityLoadScriptRunner = () => {
    if (this.props.EntityLoadScriptRunner) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityLoadScriptRunner,
        Value: true
      }));
    }
  }

  getProperties = () => {
    if (!this.props.CurrentEntity || !this.props.CurrentEntity.Properties)
      return [];

    let formName = this.state.params.FormName || '';
    let props = this.props.CurrentEntity.Properties.filter(x =>
      x.FormData && x.FormData.find(f => f.FormColumn && f.FormName === formName)
    );

    props.forEach(prop => {
      prop.Location = prop.FormData.find(x => x.FormColumn && x.FormName === formName);
      if (this.props.CustomSaveQueue && prop.SaveData) {
        prop.SaveData.SaveQueue = this.props.CustomSaveQueue;
      }
    });

    props.sort((a, b) => {
      let aVal = { primary: -1, secondary: -1 };
      let bVal = { primary: -1, secondary: -1 };
      this.setSortValues(a, aVal, formName);
      this.setSortValues(b, bVal, formName);

      return aVal.primary > bVal.primary ? 1 : bVal.primary > aVal.primary ? -1 :
        aVal.secondary > bVal.secondary ? 1 : bVal.secondary > aVal.secondary ? -1 : 0;
    });

    let emptyVals = [0, ''];
    let focusIdx = props.reduce((prev, next) => {
      let isEmpty = emptyVals.includes(next.Value);
      if (next.FocusIndex && isEmpty && (prev === null || next.FocusIndex < prev)) {
        return next.FocusIndex;
      }
      return prev;
    }, null);

    props.forEach(prop => {
      prop.Focused = prop.FocusIndex === focusIdx;
    });

    props = props.sort((a, b) => {
      let aVal = a.Location && a.Location.ControlOrder ? a.Location.ControlOrder : 0;
      let bVal = b.Location && b.Location.ControlOrder ? b.Location.ControlOrder : 0;
      return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
    });

    return props;
  }

  setSortValues = (item, value, formName) => {
    let formData = item.FormData.find(x => x.FormName === formName);
    let priVal = this.props.TabHorizontal ? formData.FormRow : formData.FormColumn;
    let secVal = this.props.TabHorizontal ? formData.FormColumn : formData.FormRow;

    value.primary = Number((priVal || '').split('/')[0].trim());
    value.secondary = Number((secVal || '').split('/')[0].trim());
  }

  getColumns = () => {
    let paramCols = this.state.params.FormColumns;
    let val = paramCols ? paramCols : this.props.CurrentEntity.GridColumns;

    let colWidth = this.props.FormColumnWidth || '1fr';

    return 'repeat(' + val + ', ' + colWidth + ')';
  }

  getRows = () => {
    let fromEntity = this.props.CurrentEntity.GridRows;
    let val = this.state.params.GridRows || fromEntity;
    return val;
  }

  refresh = (updateFromSaveQueue) => {
    if (this.props.NewEntityKey && this.props.CurrentEntity && !this.props.CurrentEntity.IsNew) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.NewEntityKey + 'New',
        Value: { EntityTypeId: this.props.NewEntityTypeId }
      }));
    }
    if (updateFromSaveQueue) {
      this.updatePropsFromSaveQueue();
    }
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.CurrentEntityKey,
      Value: cloneDeep(this.props.CurrentEntity)
    }));
  }

  updatePropsFromSaveQueue = () => {
    this.props.CurrentEntity.Properties.map((x) => {
      //check for save data tied to this prop
      let valFromSaveData = null;
      if (x.SaveData && x.SaveData.Column) {
        try {
          let existingItem = this.props.ExistingSaveData.find(sd => {
            let isMatch = (sd.Id || sd.InsertKey).toString() === (x.SaveData.Id || x.SaveData.InsertKey).toString() && x.SaveData.Column === sd.Column && x.SaveData.Table === sd.Table;
            return isMatch;
          });
          if (existingItem) {
            valFromSaveData = existingItem.Value;
          }
        } catch { }
      }

      if (valFromSaveData !== null) {
        x.Value = valFromSaveData;
      }

      return x;
    });
  }

  onLoadEntityCheck = () => {
    let onLoadId = this.props.OnLoadId;
    if (onLoadId && onLoadId.includes('[TenantId]')) {
      onLoadId = onLoadId.replace('[TenantId]', this.props.EntityMetadata.CurrentTenant);
    }

    if (!FormInstance[this.props.SWID] && onLoadId && this.props.OnLoadTypeId) {
      let body = { EntityId: Number(onLoadId), EntityTypeId: this.props.OnLoadTypeId };
      this.getFormData(body, true);
    } else if (!FormInstance[this.props.SWID] && this.props.OnLoadTypeId) {
      let body = { EntityTypeId: this.props.OnLoadTypeId }
      this.getFormData(body, true);
    }
  }

  openHistoryModal = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_AuditRecord',
      Value: this.props.EntitySource
    }));
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene2',
      Value: { Value: this.props.HistorySceneKey, Enabled: true }
    }));
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    let properties = this.getProperties();
    let entityProps = this.props.CurrentEntity ? this.props.CurrentEntity.Properties || [] : [];
    return (
      <div className={"form" + (this.props.AltPress ? ' alt-press' : '')}>
        {!this.props.CurrentEntity ? null :
          this.props.CurrentEntity.Properties &&
          <div className={'form-grid' + (this.props.HistorySceneKey ? ' form-grid-marg-bot' : '')}
            style={{ gridAutoRows: this.getRows() + 'px', gridTemplateColumns: this.getColumns() }}>
            {properties.map((x, idx) => (
              <FormControl key={x.Id}
                control={x}
                trackChanges={this.props.TrackChanges}
                isEventSave={this.props.EventSave}
                allProps={properties}
                entityProps={entityProps}
                refresh={this.refresh}
                entity={this.props.CurrentEntity}
                entitySource={this.props.EntitySource}
                parentProps={{ ...this.props }}
                overrideParameter={this.props.OverrideParameter}
                formReadOnly={this.props.FormReadOnly}
              />
            ))}
          </div>
        }
        {this.props.HistorySceneKey &&
          <div className='form-audit-text' onClick={this.openHistoryModal}>
            {this.props.CurrentEntity && this.props.CurrentEntity.EntityTypeId} {this.props.CurrentEntity && this.props.CurrentEntity.EntityId > 0 && '/ ' + this.props.CurrentEntity.EntityId}
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(Form);