import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {
    Metadata: state.met_EntityMetadata,
    LegendItems: state.map_LegendItems,
    SelectedQuery: state.qry_SelectedQuery ? state.qry_SelectedQuery : state.qry_SelectedAnalytics
  };
};

export class QueryMap extends React.PureComponent {
  //ent_Current

  componentDidMount() {
    if (!this.props.Parameter || this.props.Parameter.length === 0)
      return;

    this.selectedQuery = this.props.Parameter[0];

    if (this.props.Parameter.findIndex(x => x === undefined) !== -1)
      return;

    this.setUserQueryLayer();
  }

  setUserQueryLayer = () => {
    if (window.userQueryLayerValid)
      return;

    window.userQueryLayerValid = true;

    let body = {
      GridRequest: window.lastAgRequestBody,
      GeoColumn: this.props.SelectedQuery.GeoColumn
    }

    actions.ApiRequest('Map/UserQueryLayer', body, (result) => {
      let layerId = Number(result.LayerId);
      if (!result || isNaN(layerId))
        return;

      let items = this.props.LegendItems;

      let toClear = items.filter(x => x.IsUserQueryLayer);
      toClear.forEach(layer => {
        helpers.clearMapLayer(layer.LayerId, true);
      });

      items = items.filter(x => !x.IsUserQueryLayer);

      items.push({
        LayerType: 2,
        Active: true,
        LayerId: layerId,
        IsUserQueryLayer: true,
        Name: 'UserQuery-' + layerId,
        MinZoom: 2,
        MaxZoom: 22,
        ZIndex: 10,
        DefaultStyleId: this.props.SelectedQuery.StyleId
      });

      this.props.dispatch(actions.UpdateProp({
        Key: 'map_LegendItems',
        Value: cloneDeep(items)
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(QueryMap);