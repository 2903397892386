import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { Clear } from './../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class IsDeleted extends React.PureComponent {
  onClick = () => {
    let control = this.props.control;

    if (control.IsRO)
      return;

    control.Value = !control.Value;
    this.forceUpdate();

    if (this.props.refresh)
      this.props.refresh('forceupdate');

    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        Id: control.SaveData.Id,
        Table: control.SaveData.Table,
        Column: control.SaveData.Column,
        Value: control.Value,
        IsEventData: this.props.isEventSave,
        SaveQueue: control.SaveData.SaveQueue,
        Radio: control.SaveData.Radio,
        InsertKey: control.SaveData.InsertKey
      }));
    }
  }

  render() {
    let control = this.props.control;
    let value = helpers.stringToBool(control.Value);
    return (
      <div className="delete-control" onClick={this.onClick}>
        <Clear />
      </div>
    );
  }
}

export default connect(mapStateToProps)(IsDeleted);