import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    PartConfigurations: state.inv_PartConfigurations,
    PartSelected: state.ent_PartSelected
  };
};

export class AM_PartConfigurationCheckAll extends React.PureComponent {
  componentDidMount() {
    let partConfig = this.props.PartConfigurations.slice();
    if (partConfig[0] && partConfig[0].Active) { //Uncheck all
      partConfig.forEach(x => {
        x.Active = false;
        if (x.Children && x.Children.length > 0) {
          x.Children.forEach(y => {
            y.Active = false;
            if (y.Children && y.Children.length > 0) {
              y.Children.forEach(z => {
                z.Active = false;
              })
            }
          })
        }
      })
    } else { //Check all
      partConfig.forEach(x => {
        x.Active = true;
        if (x.Children && x.Children.length > 0) {
          x.Children.forEach(y => {
            y.Active = true;
            if (y.Children && y.Children.length > 0) {
              y.Children.forEach(z => {
                z.Active = true;
              })
            }
          })
        }
      })
    }

    this.props.dispatch(actions.UpdateProp({
      Key: 'inv_PartConfigurations',
      Value: partConfig
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(AM_PartConfigurationCheckAll);