import React from 'react';

const FourTextCard = props => {
  let getClasses = () => {
    let result = '';

    if (props.selected && props.selected.Id === props.card.Id && props.card.Id !== undefined) {
      result += ' selected';
    } else if (props.card.Id && props.card.GobId && props.card.Id === props.card.GobId) {
      result += ' selected';
    }

    return result;
  }

  let onClick = (e) => {
    props.parentClick(props.card);
  }

  let getStyle = () => {
    let obj = { marginLeft: (props.level * 15) + 'px' };

    if (props.card.Style) {
      try {
        let styles = props.card.Style.split(',').map(x => x.trim());
        styles.forEach(x => {
          let pieces = x.split(':').map(x => x.trim());
          if (pieces.length = 2) {
            obj[pieces[0]] = pieces[1];
          }
        })
      } catch {
        console.error('error parsing card style: ' + props.card.Style);
      }
    }

    return obj;
  }

  return (
    <React.Fragment>
      <div className={"four-text-card " + getClasses()} onClick={onClick} style={getStyle()}>
        {props.card.Text1 !== undefined && <div className="text-1"><div>{props.card.Text1}</div></div>}
        {props.card.Text2 !== undefined && <div className="text-2"><div>{props.card.Text2}</div></div>}
        {props.card.Text3 !== undefined && <div className="text-3"><div>{props.card.Text3}</div></div>}
        {props.card.Text3 !== undefined && <div className="text-4"><div>{props.card.Text4}</div></div>}
      </div>
    </React.Fragment>
  );
}

export default FourTextCard