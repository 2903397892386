import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';

const mapStateToProps = (state, ownProps) => {

  return {
    SaveData: state.dbo_SaveData,
    Metadata: state.met_EntityMetadata,
    DropdownBuckets: state.met_DropdownBuckets
  };
}

export class BucketDropDown extends React.PureComponent {

  onChange = (e) => {
    this.props.control.Value = e.target.value;
    if (this.props.control.FailedValidate) {
      this.props.control.FailedValidate = false;
    }

    this.forceUpdate();

    if (this.props.refresh)
      this.props.refresh();

    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: e.target.value,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
  }

  getBucketItems = () => {
    let control = this.props.control;
    let bucketDropdowns = this.props.DropdownBuckets;

    if (!bucketDropdowns)
      return [];

    let bucket = bucketDropdowns.find(x => x.Id == control.LookupSetId);

    if (!bucket)
      return [];


    let result = bucket.BucketData.reduce((prev, next) => {
      if (!prev.groups.includes(next.OptGroup)) {
        prev.groups.push(next.OptGroup);
        prev[next.OptGroup] = [];
      }
      prev[next.OptGroup].push(next);
      return prev;
    }, { groups: [] })

    return result;
  }

  render() {
    let control = this.props.control;
    let bucketItems = this.getBucketItems();
    return (
      !this.props.Metadata ? null :
        <div className={"dropdown-control" + (control.FailedValidate ? " failed-validate" : "")}>
          <div className="control-label">
            {control.Label}
            {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
          </div>
          <select
            disabled={control.IsRO} value={control.Value || ''} onChange={this.onChange}>
            {bucketItems.groups.map((groupName, gIdx) => (
              <>
                {groupName && <optgroup key={gIdx} label={groupName} >
                  {bucketItems[groupName].map((x, idx) => (
                    <option key={idx} value={x.Key}>{x.Value}</option>
                  ))}
                </optgroup>
                }
                {!groupName && <>
                  {bucketItems[groupName].map((x, idx) => (
                    <option key={idx} value={x.Key}>{x.Value}</option>
                  ))}
                </>
                }
              </>
            ))}
          </select>
        </div >
    );
  }
}

export default connect(mapStateToProps)(BucketDropDown);