import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from './../../../../Stores/Actions/actions';
import * as helpers from './../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEvent: state.ent_CurrentEvent,
    CurrentService: state.ent_CurrentService
  };
};

export class UBRegisterDials extends React.PureComponent {

  componentDidMount() {
    hub.subscribe('BeforeSave', (saveData) => {
      let event = this.props.CurrentEvent;
      let service = this.props.CurrentService;

      if (!event)
        return;

      let dialsProp = event.IsNew && service ? service.Properties.find(x => x.Name === 'RTruncate') : event.Properties.find(x => x.Name === 'RTruncate');

      let dialsVal = null;
      if (dialsProp && dialsProp.Value) {
        dialsVal = Number(dialsProp.Value);
      }

      let readingSD = saveData.filter(x => x.Table && x.Table.includes('MeterRead') && x.Column === 'Reading');

      readingSD.forEach(x => {
        let rTrunc = x.RTruncate ? Number(x.RTruncate) : dialsVal;

        if (x.Value && rTrunc && x.Value.length > rTrunc) {
          x.Value = x.Value.slice(0, x.Value.length - rTrunc);
        }
      });
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UBRegisterDials);

