import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    SaveData: state.dbo_SaveData,
    Metadata: state.met_EntityMetadata
  };
}

export class DropDownMobile extends React.PureComponent {
  parentVal = undefined;

  state = {
    showOptions: false,
  }

  onChange = (value) => {
    this.props.control.Value = value;

    if (this.props.refresh)
      this.props.refresh();

    this.setState({ showOptions: false });
    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: value,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
  }

  getLookupItems = () => {
    let lookupId = this.props.control.LookupSetId;

    if (lookupId === 'UserList')
      return this.props.Metadata.UserList;

    let result = [];
    let lookup = this.props.Metadata.LookupItems.find(x => x.LookupSet === lookupId);

    let parentProp = this.props.allProps.find(x => x.LookupSetId === lookup.ParentId);
    let parentLookupValue = parentProp ? Number(parentProp.Value) : undefined;

    if (lookup && parentProp) {
      result = lookup.LookupItems.filter(x => !x.ParentId || x.ParentId === parentLookupValue);
    } else if (lookup) {
      result = lookup.LookupItems;
    }

    //reset value if previously set but now that option does not exist
    if (this.props.control.parentVal !== parentLookupValue) {
      this.props.control.parentVal = parentLookupValue;
      this.onChange(0);
    }

    return result.filter(x => x.On);
  }

  showOptions = (items) => {
    if (items.length === 0)
      return;

    this.setState({ showOptions: true });
  }

  render() {
    let control = this.props.control;
    let lookupItems = this.getLookupItems();
    let selected = lookupItems.find(x => x.LookupItemId === control.Value);
    return (
      !this.props.Metadata ? null :
        !this.state.showOptions ?
          <div className={"mobile-dropdown-control"}>
            <div className={"mobile-dropdown-button" + (lookupItems.length === 0 ? ' disabled' : '') + (this.props.control.Focused ? ' focus-control' : '')}
              onClick={() => { this.showOptions(lookupItems) }}>
              <div className="dropdown-text">
                <div>{selected ? selected.Name1 : control.Label}</div>
                <div className="mobile-dropdown-chevron">›</div>
              </div>
            </div>
          </div>
          :
          <div className="mobile-options-picker">
            <div className="mobile-options-container">
              <div className="mobile-options-scrollbox">
                {lookupItems.map((x, idx) => (
                  <div className="mobile-option-item" key={idx} onClick={() => { this.onChange(x.LookupItemId) }}>
                    <div>{x.Name1}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
    );
  }
}

export default connect(mapStateToProps)(DropDownMobile);