import React from 'react';
import Draggable from 'react-draggable';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../Utils/Helpers';
import { MagnifyingGlass, RefreshArrow } from '../../../../../Icons/Icons';
import { isConsole } from 'react-device-detect';


const mapStateToProps = (state, ownProps) => {
  return {
    WidgetName: ownProps.Parent.props.WidgetName,
    EditingWidget: state.EditingWidget,
    EditorDetails: state.dbo_EditorDetails || {},
    EditSWID: state.EditingWidget ? state.EditingWidget.SceneWidgetId : null
  };
};

export class WidgetDetailsInput extends React.PureComponent {

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  setProp = (e, type, prop) => {
    this.props.Parent.props.Parent.setProp(e.target.value, this.props.WidgetName, type, prop);
  }

  currentDetails = (type) => {
    try {
      return this.props.EditorDetails.CardList[this.props.EditSWID][type];
    } catch {
      return null;
    }
  }

  getValue = (type, prop) => {
    try {
      let myDetail = this.currentDetails(type);
      return myDetail[prop];
    } catch {
      return undefined;
    }
  }

  render() {
    return (
      <input className="detail-input" type="text" placeholder={this.props.Prop} value={this.getValue(this.props.Type, this.props.Prop)} onChange={(e) => { this.setProp(e, this.props.Type, this.props.Prop) }} />
    );
  }
}

export default connect(mapStateToProps)(WidgetDetailsInput);
