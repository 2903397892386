import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from './../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';
import cloneDeep from 'lodash/cloneDeep';

const mapStateToProps = (state, ownProps) => {
  return {
    SelectedQuery: state.qry_SelectedQuery,
    GroupedColumns: state.qry_GroupedColumns ? state.qry_GroupedColumns[ownProps.queryId] : undefined,
    RefreshPage: state.qry_RefreshCurrentPage || 0,
    GroupPageRequests: state.qry_GroupPageRequests || [],
    Query: state.qry_Queries[ownProps.queryId]
  };
}

const rowClick = (props) => {
  props.row.GroupItem.Expand = !props.row.GroupItem.Expand;
  props.row.GroupItem.Toggled = true;


  props.dispatch(actions.UpdateProp({
    Key: 'qry_SelectedQuery',
    Value: props.SelectedQuery
  }));

}

const requestPage = (props) => {
  if (!props.GroupPageRequests.map(x => x.Index).includes(props.row.Index)) {
    let nextRow = props.Query.rows[props.row.Index + 1];
    if (!nextRow || nextRow.length === 0) {
      props.dispatch(actions.UpdateProp({
        Key: 'qry_GroupPageRequests',
        Value: [...props.GroupPageRequests, props.row]
      }));
    }
  }
}

const GroupRow = props => {
  if (props.row.GroupItem.Expand) {
    requestPage(props);
  }
  return (
    <div className={"group-by-row" + (!props.pinned ? ' header-width' : '')} onClick={() => { rowClick(props) }}>
      <div className="group-text">{
        (props.row.IsLookup ?
          helpers.getLookupName(props.row.IsUserList ? 'userlist' : null, props.row.GroupItem[1]) :
          props.row.GroupItem[1]) + ' (' + Number(props.row.GroupItem[0]).toLocaleString() + ')'
      }</div>
    </div>
  );
}

export default connect(mapStateToProps)(GroupRow);