import { cloneDeep } from 'lodash';
import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {

  return {
    CurrentContractor: state.ent_CurrentContractor,
  };
};

export class MV_LinkToContractorSite extends React.PureComponent {

  componentDidMount() {
    let website = this.props.CurrentContractor.Properties.find(x => x.Name == 'WebSite').Value;
    window.open(website);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_LinkToContractorSite);