import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');

  return {
    SelectedEntity: state.ent_Selected,
    CurrentEntity: state.ent_Current,
    Metadata: state.met_EntityMetadata,
    AdminSelectedPayPeriod: state.ttr_AdminSelectedPayPeriod,
    Dbo_SaveId: state.dbo_SaveId
  };
};

let _ppLockWaiting;

export class TogglePayPeriodLock extends React.PureComponent {

  componentDidMount() {
    this.changeLock();
  }

  changeLock() {
    if (_ppLockWaiting)
      return

    _ppLockWaiting = true;

    let baseObj = {
      Id: this.props.AdminSelectedPayPeriod.Id,
      InsertKey: null,
      Table: 'ttr.Payperiod',
      IsEventData: false,
      IsBaseTable: true,
      EntityTypeId: 100
    }

    let saveData = [
      helpers.saveDataItem(baseObj, 'IsLocked', !this.props.AdminSelectedPayPeriod.IsLocked)
    ];

    actions.ApiRequest('Save/Save', { SaveData: saveData }, () => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.Dbo_SaveId + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_AdminSelectedPayPeriod',
        Value: { ...this.props.AdminSelectedPayPeriod, IsLocked: !this.props.AdminSelectedPayPeriod.IsLocked }
      }));
      _ppLockWaiting = false;
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(TogglePayPeriodLock);