import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {};
};

export class SignDisplayOrder extends React.PureComponent {
  SignRelTable = 'rel.SignSupport';
  DisplayOrderColumn = 'DisplayOrder';

  //_grid2322,sr_DisplayOrderId,dbo_SaveData

  componentDidMount() {
    let params = this.props.Parameter;
    let keys = this.props.ParameterKeys;

    this.sourceGrid = params[0];
    this.rowId = params[1];

    this.sourceGridKey = keys[0];
    this.saveQueueKey = keys[2];

    if (params.findIndex(x => x === undefined) !== -1)
      return;

    if (!this.isValid())
      return;

    this.updateGridRows();
  }

  updateGridRows = () => {
    let sourceCopy = cloneDeep(this.sourceGrid);

    let idx = sourceCopy.Rows.findIndex(x => x.Id === this.rowId);
    let newIdx = this.props.TriggerKey.includes('Down') ? idx + 1 : idx - 1;

    [sourceCopy.Rows[idx], sourceCopy.Rows[newIdx]] = [sourceCopy.Rows[newIdx], sourceCopy.Rows[idx]];

    this.setDisplayOrder(sourceCopy.Rows);

    this.props.dispatch(actions.UpdateProp({
      Key: this.sourceGridKey,
      Value: sourceCopy
    }));
  }

  isValid = () => {
    let rowIdx = this.sourceGrid.Rows.findIndex(x => x.Id === this.rowId);

    if (rowIdx === -1 || (rowIdx === 0 && !this.props.TriggerKey.includes('Down')) || (rowIdx === this.sourceGrid.Rows.length - 1 && this.props.TriggerKey.includes('Down')))
      return false;

    return true;
  }

  setDisplayOrder = (rows) => {
    batch(() => {
      rows.forEach((x, idx) => {
        this.props.dispatch(actions.AddSaveData({
          Id: x.Id,
          Table: this.SignRelTable,
          Column: this.DisplayOrderColumn,
          Value: idx,
          IsEventData: false,
          SaveQueue: this.saveQueueKey,
          InsertKey: null,
          IsBaseTable: true
        }));
      });
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(SignDisplayOrder);