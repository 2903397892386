import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';
import { Trashcan } from '../../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {

  return {
    CurrentEvent: state.ent_CurrentEvent,
    NewContact: state.ent_NewContact,
    EventSaveQueue: state.dbo_EventSaveData,
    ContactMgtSaveData: state.dbo_ContactMgtSaveData
  };
};

export class UB_AddressCheck extends React.PureComponent {

  componentDidMount() {
    //check which button to animate
    let elements = document.getElementsByClassName('address-check-btn');
    let element = '';
    let props = '';

    if (elements && elements.length === 2) { // contact event open in front of new contact form
      element = elements[1];
      props = this.props.CurrentEvent.Properties;
    } else if (elements && elements.length === 1) {
      element = elements[0];
      if (this.props.NewContact) {
        props = this.props.NewContact.Properties;
        this.setState({ isNewContact: true });
      } else {
        props = this.props.CurrentEvent.Properties;
      }
    } else {
      return;
    }

    this.setState({ element: element });

    if (element) { element.firstChild.firstChild.innerHTML = 'Checking...'; }

    let address1 = props.find(x => x.Name == 'Address1').Value;
    let address2 = props.find(x => x.Name == 'Address2').Value;
    let city = props.find(x => x.Name == 'City').Value;
    let state = props.find(x => x.Name == 'State').Value;
    let zip = props.find(x => x.Name == 'Zip').Value;

    let body = { Address1: address1, Address2: address2, City: city, State: state, Zip: zip };

    actions.ApiRequest('Entity/ValidateAddress', body, (result) => {
      this.updateAddressFields(result);
    });

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    // myHeaders.append("Cookie", "TLTSID=91b6de7b3816161e9a0800e0ed96ae55; NSC_uppmt-usvf-ofx=ffffffff3b2237bd45525d5f4f58455e445a4a4212d3");
    // var urlencoded = new URLSearchParams();
    // urlencoded.append("address1", address1);
    // urlencoded.append("address2", address2);
    // urlencoded.append("city", city);
    // urlencoded.append("state", state);
    // urlencoded.append("zip", zip);
    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: urlencoded,
    //   redirect: 'follow'
    // };
    // fetch("https://tools.usps.com/tools/app/ziplookup/zipByAddress", requestOptions)
    //   .then(response => response.text())
    //   .then(result => this.updateAddressFields(result))
    //   .catch(error => { console.log(error); if (element) { element.firstChild.firstChild.innerHTML = 'Error finding address'; } });
  }

  updateAddressFields = (result) => {
    let element = this.state.element;
    result = JSON.parse(result);
    console.log(result);
    if (result && result.resultStatus === 'SUCCESS') {
      let returnedAddress = result.addressList[0];
      let fullZipReturned = returnedAddress.zip5 + returnedAddress.zip4;
      let addressReturned = returnedAddress.addressLine1;

      let currentEvent = '';
      let gobkey = '';

      if (this.state.isNewContact) {
        currentEvent = this.props.NewContact;
        gobkey = 'ent_NewContact';
      } else {
        currentEvent = this.props.CurrentEvent;
        gobkey = 'ent_CurrentEvent';
      }

      let props = currentEvent.Properties;
      let address1 = props.find(x => x.Name === 'Address1').Value;
      let zip = props.find(x => x.Name === 'Zip').Value;

      if (fullZipReturned !== zip || addressReturned !== address1) {
        if (element) { element.firstChild.firstChild.innerHTML = 'Address has been updated'; }

        let zipProp = currentEvent.Properties.find(x => x.Name === 'Zip');
        zipProp.Value = fullZipReturned;

        let addressProp = currentEvent.Properties.find(x => x.Name === 'Address1');
        addressProp.Value = addressReturned;

        if (this.state.isNewContact) {
          zipProp.SaveData.SaveQueue = 'dbo_ContactMgtSaveData';
          zipProp.SaveData.IsBaseTable = true;
          addressProp.SaveData.SaveQueue = 'dbo_ContactMgtSaveData';
          addressProp.SaveData.IsBaseTable = true;
        } else {
          zipProp.SaveData.IsEventData = true;
          addressProp.SaveData.IsEventData = true;
        }

        this.props.dispatch(actions.UpdateProp({
          Key: gobkey,
          Value: currentEvent
        }));

        helpers.addSaveData(this.props.dispatch, zipProp.SaveData, 'Zip', fullZipReturned);
        helpers.addSaveData(this.props.dispatch, addressProp.SaveData, 'Address1', addressReturned);

      } else {
        if (element) { element.firstChild.firstChild.innerHTML = 'Address up to Date'; }
      }
    } else {
      if (element) { element.firstChild.firstChild.innerHTML = 'Address not found'; }
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_AddressCheck);