import React from 'react';
import { batch, connect } from 'react-redux';
// import loader from "@monaco-editor/loader";
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';

const mapStateToProps = (state, ownProps) => {
  return {};
};

export class Monaco extends React.PureComponent {

  valueChange = (value, e) => {
    this.props.onChange(value);
  }

  componentDidUpdate() {
    if (this.editor) {
      // this.editor.setValue(this.props.value || '');
    }
  }

  editorDidMount = (editor) => {
    this.editor = editor;
    this.editor.setValue(this.props.value || '');
  }

  render() {
    let options = {
      value: this.props.value,
      language: this.props.language || "html",
      lineDecorationsWidth: 10,
      lineNumbersMinChars: 0,
      glyphMargin: false,
      folding: false,
      minimap: {
        enabled: false
      }
    };

    return (
      <div className="monaco-editor">
        <Editor
          options={options}
          defaultLanguage={this.props.language || 'html'}
          onChange={this.valueChange}
          onMount={this.editorDidMount}
          defaultValue={this.props.value}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(Monaco);