import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../Utils/Helpers';
import { MagnifyingGlass } from '../../../../../Icons/Icons';
import ButtonState from './ActionTypes/ButtonState';
import LoadScene from './ActionTypes/LoadScene.js';
import ApiCall from './ActionTypes/ApiCall.js';
import GobUpdate from './ActionTypes/GobUpdate.js';
import UpdateProp from './ActionTypes/UpdateProp.js';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class ActionEditor extends React.PureComponent {
  state = {
    Types: [{
      TypeId: 0,
      Label: '',
      OtherProps: null
    },
    {
      TypeId: 1,
      Label: 'Set Button State',
      OtherProps: ButtonState
    },
    {
      TypeId: 2,
      Label: 'Load Scene',
      OtherProps: LoadScene
    },
    {
      TypeId: 3,
      Label: 'Api Call',
      OtherProps: ApiCall
    },
    {
      TypeId: 4,
      Label: 'Key Transfer',
      OtherProps: GobUpdate
    },
    {
      TypeId: 5,
      Label: 'Update Gob Prop',
      OtherProps: UpdateProp
    }],
    TypeId: 0,
    OrderId: 1,
    Text1: '',
    Text2: '',
    Text3: '',
    ItemId: null
  }

  componentDidMount() {
    if (this.props.editItem) {
      let item = this.props.editItem;
      this.setState({
        TypeId: item.TypeId,
        OrderId: item.OrderId,
        IsAnd: item.IsAnd,
        Text1: item.Text1,
        Text2: item.Text2,
        Text3: item.Text3,
        ItemId: item.ItemId
      });
    }
  }

  setType = (e) => {
    let type = this.state.Types.find(x => x.TypeId === Number(e.target.value));
    this.setState({
      TypeId: type.TypeId,
      Text1: '',
      Text2: '',
      Text3: ''
    });
  }

  setOrder = (e) => {
    this.setState({ OrderId: e.target.valueAsNumber });
  }

  toggleAnd = () => {
    this.setState({ IsAnd: !this.state.IsAnd });
  }

  setProp = (e, name) => {
    this.setState({ [name]: e.target.value });
  }

  addAction = () => {
    this.props.addAction({
      TypeId: this.state.TypeId,
      OrderId: this.state.OrderId,
      IsAnd: this.state.IsAnd,
      Text1: this.state.Text1,
      Text2: this.state.Text2,
      Text3: this.state.Text3,
      ItemId: this.state.ItemId
    });
  }

  render() {
    let curType = this.state.Types.find(x => x.TypeId === this.state.TypeId);
    let OtherProps = curType ? curType.OtherProps : null;
    return (
      <div className="new-action">
        <div className="wf-title">
          {!this.state.ItemId && <div>New Action</div>}
          {this.state.ItemId && <div>{'Editing Action ' + this.state.ItemId}</div>}
        </div>
        <div className="side-bar-prop">
          <div>Type:</div>
          <select value={this.state.TypeId} onChange={this.setType}>
            {this.state.Types.map((x, idx) => (
              <option key={idx} value={x.TypeId}>{x.Label}</option>
            ))}
          </select>
        </div>
        <div className="horizontal-props">
          <div className="side-bar-prop">
            <div>Order:</div>
            <input type="number" value={this.state.OrderId} onChange={this.setOrder} />
          </div>
        </div>
        {OtherProps && <OtherProps setProp={this.setProp} state={this.state} />}
        {OtherProps &&
          <div className="wf-btn-bar">
            <div className="wf-btn" onClick={this.props.cancelAction}>
              <div>Cancel</div>
            </div>
            <div className="wf-btn" onClick={this.addAction}>
              <div>Done</div>
            </div>
          </div>}
      </div>
    )
  }
}

export default connect(mapStateToProps)(ActionEditor);