import React from 'react';

export class NotFound extends React.PureComponent {
  state = {
    Name: this.props.control.Name,
    Value: this.props.control.Value || '',
    Label: this.props.control.Label
  }

  render() {
    return (
      <div className="textbox-control">
        <div className="control-label">{this.state.Label}</div>
        <input type="text" value={this.props.control.ControlType} disabled />
      </div>
    );
  }
}

export default NotFound;