import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const mapStateToProps = (state, ownProps) => {

  return {
    SWID: ownProps.widget.SceneWidgetId,
    SaveId: state.dbo_SaveId
  }
};

export class Wiki extends React.PureComponent {

  state = {
    Pages: [],
    IsNew: false,
    waiting: false,
    EditMode: false,
    Content: null,
    editorState: EditorState.createEmpty()
  }

  componentDidMount() {
    this.retrieveWikiPages();
    //test
    // let existingContent = convertFromRaw(content);
    // this.setState({ Content: existingContent });
  }

  retrieveWikiPages = () => {
    /* Make call to get JSON for all wiki pages and store it in state - WikiJSON */
    let body = {
      SWID: this.props.SWID,
      Parameter: 1
    }

    let orderedList = [];

    actions.ApiRequest('List/GetList', body, (result) => {
      //order results and give classes for nesting on parentId's
      result.forEach(x => {
        x.ContentJSON = JSON.parse(x.ContentJSON);
        if (x.ParentId === null) {
          orderedList.push(x);
          result.forEach(y => {
            if (x.WikiId === y.ParentId) {
              y.className = 'margin-left-15'
              orderedList.push(y);
              result.forEach(z => {
                if (y.WikiId === z.ParentId) {
                  z.className = 'margin-left-30'
                  orderedList.push(z);
                }
              })
            }
          })
        }
      })
      this.setState({ AllPages: orderedList, Pages: orderedList });
    });
  }

  searchTyped(e) {
    if (!isNaN(e.target.value) && e.target.value !== '')
      return

    let filteredList = [];
    this.state.AllPages.forEach(x => {
      if (x.Topic.toUpperCase().includes(e.target.value.toUpperCase())) {
        filteredList.push(x);
      }
    })
    this.setState({ Pages: filteredList });
  }

  selectWiki = (wiki) => {
    this.setState({ selectedWiki: wiki, Content: wiki.ContentJSON });
  }

  saveWiki = () => {
    let baseObj = {
      Id: null,
      InsertKey: helpers.getInsertKey(),
      Table: 'xwg.Wiki',
      IsBaseTable: true
    }

    let saveData = [
      helpers.saveDataItem(baseObj, 'Topic', this.state.Topic),
      helpers.saveDataItem(baseObj, 'ParentId', this.state.ParentId),
      helpers.saveDataItem(baseObj, 'ContentJSON', JSON.stringify(this.state.Content))
    ];

    actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
      this.setState({ isNew: false });
      this.retrieveWikiPages();
    });
  }

  createNew = () => {
    //create select and store in state for parent drop down
    let parentOptions = []
    this.state.AllPages.forEach(x => {
      parentOptions.push(<option value={x.WikiId}>{x.Topic}</option>)
    })
    this.setState({ isNew: true, EditMode: true, Content: null, parentSelect: <select name='ParentId' id='parent-wiki-select' onChange={(e) => this.onInputChange(e)} ><option value={null}>None</option>{parentOptions.concat()}</select> })
  }

  cancelNew = () => {
    this.setState({ isNew: false, EditMode: false });
  }

  editWiki = () => {
    this.setState({ EditMode: true });
  }

  onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onContentChange = (contentState) => {
    this.setState({ Content: contentState });
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState: editorState });
  }

  saveWikiEdit = () => {

  }

  cancelWikiEdit = () => {
    this.setState({ EditMode: false });
    //Reload existing contentJSON for selected wiki
  }

  render() {
    const { editorState } = this.state.editorState;
    return (
      <div className='wiki-container'>
        {!this.state.isNew ? <div className='wiki-list'>
          <input className='wiki-search' type='search' placeholder='Search Wiki' onChange={(e) => this.searchTyped(e)} /><hr style={{ marginBottom: "5px" }} />
          {/* List of all wiki articles */}
          {this.state.Pages.map(wiki => {
            return <div className={wiki === this.state.selectedWiki ? 'selected-wiki ' + wiki.className + ' wiki-item' : wiki.className + ' wiki-item'} onClick={() => this.selectWiki(wiki)}>{wiki.Topic}</div>
          })}
          <button onClick={() => { this.createNew() }} className='wiki-new-btn'>New Article</button>
        </div> :
          <div className='wiki-list'>
            <div className='new-wiki-sidebar'>
              <div>Topic : <input type='text' name='Topic' value={this.state.Topic} onChange={(e) => this.onInputChange(e)} /></div><br />
              <div>Parent : {this.state.parentSelect}</div>
              <div className='wiki-save-cancel'>
                <button onClick={this.saveWiki} >Create</button>
                <button onClick={this.cancelNew} >Cancel</button>
              </div>
            </div>
          </div>
        }
        <div className='wiki-content'>
          <div className={this.state.EditMode ? 'wiki-editor-active' : 'wiki-editor'}>
            {!this.state.EditMode ?
              <button onClick={this.editWiki} className='wiki-edit-btn' >Edit</button>
              : <div className='wiki-edit-save-cancel'>
                <button onClick={this.saveWikiEdit} >Save</button>
                <button onClick={this.cancelWikiEdit} >Cancel</button>
              </div>
            }
            <Editor
              onContentStateChange={this.onContentChange}
              contentState={this.state.Content}
              // editorState={editorState}
              // onEditorStateChange={(editorState) => this.setState({ editorState: editorState })}
              readOnly={!this.state.EditMode}
              toolbarHidden={!this.state.EditMode}
              editorClassName={this.state.EditMode ? "wiki-editor-class margin-top-50" : "wiki-editor-class"}
              toolbarClassName="wiki-toolbar-class"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Wiki);