import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import Board from 'react-trello';
import KanbanCard from './KanbanCard';
import * as hub from '../../../../Utils/PubSub';
import * as icons from '../../../Icons/Icons';
import { flipChartAxisDirection } from 'ag-charts-community';

const mapStateToProps = (state, ownProps) => {
  let boardName = ownProps.widget.Parameters.find(x => x.ParameterName === 'BoardName');
  let param = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');

  return {
    KanbanBoard: state['_KanbanBoard' + ownProps.widget.SceneWidgetId],
    BoardName: boardName ? boardName.ParameterValue : null,
    BoardKey: '_KanbanBoard' + ownProps.widget.SceneWidgetId,
    SourceData: state['_KanbanBoardSource' + ownProps.widget.SceneWidgetId],
    SourceKey: '_KanbanBoardSource' + ownProps.widget.SceneWidgetId,
    Parameter: param ? helpers.getListParameter(state, param.ParameterValue) : {},
    SaveId: state.dbo_SaveId
  };
}

export class KanbanBoard extends React.PureComponent {

  componentDidMount() {
    this.hubListener();
    this.GetBoard();
  }

  componentDidUpdate(prev) {
    if (prev && this.props.SaveId !== prev.SaveId) {
      this.GetBoard();
    }
    if (prev && prev.Parameter && prev.Parameter.EntityId !== this.props.Parameter.EntityId) {
      this.GetBoard();
    }
  }

  GetBoard = () => {
    let body = {
      BoardName: this.props.BoardName,
      Parameter: this.props.Parameter ? JSON.stringify(this.props.Parameter) : null
    }
    actions.ApiRequest('List/GetKanbanBoard', body, (result) => {
      batch(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.BoardKey,
          Value: this.convertToBoard(result)
        }));
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.SourceKey,
          Value: cloneDeep(result)
        }));
      });
    });
  }

  hubListener = () => {
    hub.subscribe('KanbanUpdate', (arg) => {
      let user = Number(arg.split('-')[0]);
      let boardName = arg.split('-')[1];
      if (user !== this.props.CurrentUserId && this.props.BoardName === boardName) {
        this.GetBoard();
      }
    }, this.props.SWID);
  }

  convertToBoard = (data) => {
    data.Lanes = data.Lanes.map(lane => {
      lane.cards = data.Rows.filter(x => x.kbLaneId === lane.LaneId);
      return lane;
    });

    data.Lanes.forEach(lane => {
      let topCard = lane.cards.find(x => x.KanbanRowId === x.kbParentId);
      let cardArr = topCard ? [topCard] : [];
      let cardMap = lane.cards.reduce((prev, next) => {
        if (next.KanbanRowId !== next.kbParentId) {
          prev[next.kbParentId] = next;
        }
        return prev;
      }, {});


      for (var i = 0; i < lane.cards.length - 1; i++) {
        try {
          let arrItem = cardMap[cardArr[i].KanbanRowId];
          cardArr.push(arrItem);
        } catch (e) {
          console.log(cardArr[i - 1]);
        }
      }



      lane.cards = cardArr;
    })

    return {
      lanes: data.Lanes.map(lane => {
        let cardsInLane = lane.cards.map((x, idx) => {
          return {
            id: x.KanbanRowId + '',
            laneId: x.kbLaneId,
            card: x,
            columns: data.Columns.map(col => this.addStyleObj(col)),
            oldPosition: idx
          }
        });

        return {
          cards: cardsInLane,
          title: lane.Lane,
          id: lane.LaneId + ''
        }
      })
    }
  }

  addStyleObj = (col) => {
    let rows = null;
    let cols = null;

    try {
      rows = col.Location.split('-')[0].split(',')[1] + ' / ' + col.Location.split('-')[1].split(',')[1];
      cols = col.Location.split('-')[0].split(',')[0] + ' / ' + col.Location.split('-')[1].split(',')[0];
    } catch (e) {
      console.log('card column location failed to parse:');
      console.log({ column: col });
    }
    return {
      ...col,
      Icon: icons[col.Icon],
      styleObj: {
        gridRow: rows,
        gridColumn: cols,
        ...helpers.getElementStyleObject(col.Style)
      }
    }
  }

  handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
    if (sourceLaneId !== targetLaneId || position !== cardDetails.oldPosition) {
      let droppedInLane = this.props.KanbanBoard.lanes.find(x => x.id === targetLaneId);

      let parentDelta = position > cardDetails.oldPosition ? 0 : -1;
      let childDelta = position > cardDetails.oldPosition ? 1 : 0;

      if (sourceLaneId !== targetLaneId) {
        parentDelta = -1;
        childDelta = 0;
      }

      //update card underneath parentid
      let parent = droppedInLane.cards.find(x => x.oldPosition === position + parentDelta);
      let underMe = droppedInLane.cards.find(x => x.oldPosition === position + childDelta);

      let newChildUpdate = [];
      if (underMe) {
        newChildUpdate = this.updateCardLocation(underMe.id, underMe.laneId, cardId);
      }

      //sew up old parent and old child
      let tieOldSiblings = [];
      let sourceLane = this.props.KanbanBoard.lanes.find(x => x.id === sourceLaneId);
      let oldParent = sourceLane.cards.find(x => x.card.KanbanRowId !== cardDetails.card.KanbanRowId && x.card.KanbanRowId === cardDetails.card.kbParentId);
      let oldChild = sourceLane.cards.find(x => x.card.KanbanRowId !== cardDetails.card.KanbanRowId && x.card.kbParentId === cardDetails.card.KanbanRowId);
      if (oldChild) {
        let parentId = oldParent ? oldParent.id : oldChild.id;
        tieOldSiblings = [...this.updateCardLocation(oldChild.id, oldChild.laneId, parentId)];
      }

      //fix old + update dropped card + update card under where we dropped
      let saveData = [
        ...tieOldSiblings,
        ...this.updateCardLocation(cardId, targetLaneId, parent ? parent.id : cardDetails.id),
        ...newChildUpdate
      ];

      actions.ApiRequest('Save/Save', { SaveData: saveData }, () => { });

      setTimeout(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.BoardKey,
          Value: this.convertToBoard(cloneDeep(this.props.SourceData))
        }));
      }, 100);
    }
  }

  updateCardLocation = (cardId, laneId, parentId) => {
    let card = this.props.SourceData.Rows.find(x => x.KanbanRowId === Number(cardId))
    card.kbLaneId = Number(laneId);
    card.kbParentId = Number(parentId);

    let lane = this.props.SourceData.Lanes.find(x => x.LaneId === Number(laneId));

    let arr = [
      helpers.saveDataItem({ Id: Number(cardId), Table: 'qry.KanbanRow' }, 'ParentId', Number(parentId)),
      helpers.saveDataItem({ Id: Number(cardId), Table: 'qry.KanbanRow' }, 'LaneId', Number(laneId))
    ];

    if (lane.LidTable) {
      arr.push(helpers.saveDataItem({ Id: card.EntityId, Table: lane.LidTable }, lane.LidColumn, Number(laneId)))
    }

    return arr;
  }

  render() {
    if (!this.props.KanbanBoard)
      return (null);

    let components = {
      Card: KanbanCard
    }

    return (
      <div className="kanban-board-widget">
        <Board data={this.props.KanbanBoard} editable={false} canAddLanes={false} components={components} onCardMoveAcrossLanes={this.onCardMoveAcrossLanes} handleDragEnd={this.handleDragEnd} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(KanbanBoard);


