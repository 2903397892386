import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
});

export class NotFound extends React.PureComponent {
  render() {
    return (
      <div className="widget-not-found">
        Widget not found:
        <span>{this.props.name}</span>
        <span>{this.props.version}</span>
      </div>
    );
  }
}

export default connect(mapStateToProps)(NotFound);