import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    Metadata: state.met_EntityMetadata
  };
};

export class Hive extends React.PureComponent {

  componentDidMount() {
    actions.ApiRequest('Logging/GlobalSQL', { GetDbList: true }, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'DBList',
        Value: result.map((x) => { return { Text1: x, Id: x, AdditionalCards: [{ CardKey: 'blu_ModalScene', Value: null }, { CardKey: 'TargetDB', JsonValue: '"' + x + '"' }] } })
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Hive);

