import Honeycomb, { SendEmailsParameters } from "@beehive-development/honeycomb";
import { connect } from "react-redux";
import React from "react";

type StringKeysObject = { [key: string]: any };

const mapStateToProps = (state: StringKeysObject, ownProps: StringKeysObject) => {
    return {
        ConsumerId: state.ms_ActiveConsumerId,
        CurrentEntity: state.ent_Current,
    };
}

export class MV_SendEmails extends React.PureComponent<ReturnType<typeof mapStateToProps>> {
    async componentDidMount() {
        await this.sendEmails();
    }

    async sendEmails() {
        // @ts-expect-error
        // TypeScript can't assert `_getToken` exists due to it being placed in the `window` object at runtime in vanilla JS.
        const jwtToken = window.isSalesmanPublic ? await window._getToken : await window._getToken();

        const honeycomb = new Honeycomb({
            requestHeaders: {
                Authorization: `Bearer ${jwtToken}`,
                "href-origin": window.location.href,
            },
        });

        const sendEmailsParameters: SendEmailsParameters = {
            emailTo: [ this.props.CurrentEntity.Properties.find((o: { Name: string; }) => o.Name === "EMail").Value ],
            consumerId: this.props.ConsumerId,
        };

        await honeycomb.mySalesman.sendEmails(sendEmailsParameters);
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps)(MV_SendEmails);