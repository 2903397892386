import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import InfoCard from './../../InfoCard/v001/InfoCard';
import { ReSelect } from './../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {

  if (ownProps.overrideParameter) {
    let pieces = ownProps.overrideParameter.split('=');
    if (pieces.length === 2) {
      let toReplace = pieces[0].trim();
      ownProps.control.AutoFillParams = ownProps.control.AutoFillParams.replace(new RegExp(toReplace, 'g'), pieces[1].trim());
    }
  }
  let param = helpers.getListParameter(state, ownProps.control.AutoFillParams);

  return {
    SaveData: state.dbo_SaveData,
    Metadata: state.met_EntityMetadata,
    Parameter: param
  };
}

export class AutoFillDropDown extends React.PureComponent {
  state = {
    hoverIdx: 0,
    CardId: helpers.getId()
  }

  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
    this.createKeyNav();
  }

  componentWillUnmount() {
    this.destroyKeyNav();
  }

  onChange = (e) => {
    let val = e.target.value;
    this.props.control.SearchText = val || '';

    this.getAutoComplete(val || '');
    this.forceUpdate();

    if (this.props.refresh)
      this.props.refresh();
  }

  getAutoComplete = (text) => {
    let body = {
      Parameter: JSON.stringify({ SearchText: text, Parameter: this.props.Parameter }),
      EMCID: this.props.control.Id
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      if (!this.props.control.AutoComplete)
        this.props.control.AutoComplete = {};

      let autoComplete = this.props.control.AutoComplete;
      autoComplete.Items = result.map((x) => {
        let obj = {
          ...x,
          CardProps: []
        }

        if (x.Text1) {
          obj.CardProps.push({ Value: x.Text1 });
        }
        if (x.Text2) {
          obj.CardProps.push({ Value: x.Text2 });
        }
        if (x.Text3) {
          obj.CardProps.push({ Value: x.Text3 });
        }

        return obj;
      });
      this.forceUpdate();
    });
  }

  focusChange = () => {
    this.forceUpdate();
  }

  selectCard = (card) => {
    console.log(card);
    this.props.control.InfoCard = card;
    if (this.props.trackChanges) {
      let saveItem = {
        Id: this.props.control.SaveData.Id,
        InsertKey: this.props.control.SaveData.InsertKey,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: this.getGobAutofill(card),
        IsEventData: this.props.isEventSave,
        IsBaseTable: this.props.control.IsBaseTable,
        SaveQueue: this.props.control.SaveData.SaveQueue
      }
      console.log({ saveItem: saveItem, card: card });
      this.props.dispatch(actions.AddSaveData(saveItem));

      this.updateChildAutofill();
    }
    this.forceUpdate();
  }

  getGobAutofill = (card) => {
    if (this.props.control.GobAutofill === 'frm.EntityId') {
      return this.props.entity.EntityId;
    }
    if (this.props.control.GobAutofill.startsWith('card.')) {
      let pieces = this.props.control.GobAutofill.split('.').map(x => x.trim());
      return card[pieces[1]];
    }
    return '';
  }

  updateChildAutofill = (clearAll) => {
    try {
      let id = this.props.control.Id;
      let children = this.props.entity.Properties.filter(x => helpers.propHasParent(x, id) && x.GobAutofill);
      children.forEach((prop) => {
        let value = undefined;
        if (!clearAll) {
          let pieces = prop.GobAutofill.split('.');
          value = pieces.reduce((prev, next) => prev[next], this.props);
        }
        this.props.dispatch(actions.AddSaveData({
          Id: this.props.control.SaveData.Id,
          InsertKey: this.props.control.SaveData.InsertKey,
          Table: prop.SaveData.Table,
          Column: prop.SaveData.Column,
          Value: value,
          IsEventData: this.props.isEventSave,
          IsBaseTable: this.props.control.IsBaseTable
        }));
      });
    } catch { }
  }

  saveDataNull = () => {
    let saveItem = {
      Id: this.props.control.SaveData.Id,
      InsertKey: this.props.control.SaveData.InsertKey,
      Table: this.props.control.SaveData.Table,
      Column: this.props.control.SaveData.Column,
      Value: undefined,
      IsEventData: this.props.isEventSave,
      IsBaseTable: this.props.control.IsBaseTable,
      SaveQueue: this.props.control.SaveData.SaveQueue
    }
    this.props.dispatch(actions.AddSaveData(saveItem));
  }

  clearSelection = (keyEvent) => {
    this.props.control.InfoCard = undefined;
    this.props.control.Value = undefined;
    this.updateChildAutofill(true);
    this.saveDataNull();
    if (keyEvent) {
      console.log(keyEvent);
      this.props.control.SearchText = keyEvent.key;
    }
    this.forceUpdate();
    setTimeout(() => {
      this.searchInput.current.focus();
      this.forceUpdate();
    }, 0);
  }

  hoverCard = (idx) => {
    this.setState({ hoverIdx: idx });
  }

  createKeyNav = () => {
    document.addEventListener('keydown', this.keyDown);
  }

  destroyKeyNav = () => {
    document.removeEventListener('keydown', this.keyDown);
  }

  keyDown = (e) => {
    let autoComplete = this.props.control.AutoComplete;

    if (this.cardFocused() && helpers.keyIsPrintable(e)) {
      this.clearSelection(e);
      return;
    }

    if (!this.inputFocused() || !autoComplete || autoComplete.Items.length === 0) {
      return;
    }

    if (e.which === 38) { // up         
      this.updateHover(-1);
      this.scrollIntoView();
    }
    if (e.which === 40) { // down      
      this.updateHover(1);
      this.scrollIntoView();
    }
    if (e.which === 13 || e.which === 9) { // enter or tab
      let card = this.props.control.AutoComplete.Items[this.state.hoverIdx];
      this.selectCard(card);
    }
  }

  scrollIntoView = () => {
    let element = document.querySelector('.auto-complete-item.hover');
    if (element) {
      let parentTop = element.parentNode.scrollTop;
      let parentHeight = element.parentNode.clientHeight;

      if ((parentTop + parentHeight - element.clientHeight) < element.offsetTop) {
        element.parentNode.scrollTop = element.offsetTop - parentHeight + element.clientHeight;
      }
      if (parentTop > element.offsetTop) {
        element.parentNode.scrollTop = element.offsetTop;
      }
    }
  }

  updateHover = (delta) => {
    let autoComplete = this.props.control.AutoComplete;
    let newIdx = (this.state.hoverIdx + delta + autoComplete.Items.length) % autoComplete.Items.length;
    this.setState({ hoverIdx: newIdx });
  }

  inputFocused = () => {
    let focusId = document.activeElement ? document.activeElement.id : '';
    return 'input' + this.props.control.Id === focusId;
  }

  cardFocused = () => {
    let focusId = document.activeElement ? document.activeElement.id : '';
    return 'infocard' + this.state.CardId === focusId;
  }

  onFocus = () => {
    this.onChange({ target: { value: this.props.control.Value } });
  }

  render() {
    let control = this.props.control;
    let autoComplete = this.props.control.AutoComplete;
    let hasFocus = this.inputFocused();

    if (control && (control.Value === 'AutoCompleteCard' || control.Value === 'AutoCompleteImageCard'))
      control.Value = null;


    return (
      !this.props.Metadata ? null :
        control.Value ?
          <div className="auto-fill-drop-down">
            <div className="selected-card">
              <InfoCard Entity={this.props.entity} CardForm={control.DefaultValue} CardId={this.state.CardId} ParentId={control.Id} Tabbable={true} />
              <div className="re-select">
                <div onClick={this.clearSelection}>
                  <ReSelect />
                </div>
              </div>
            </div>
          </div>
          :
          !control.InfoCard ?
            <div className={"auto-fill-drop-down" + (control.FailedValidate ? " failed-validate" : "")}>
              <input id={'input' + control.Id} type="text" ref={this.searchInput}
                value={control.SearchText || ''} placeholder={control.Label} onFocus={this.onFocus} onChange={this.onChange} autoComplete="chrome-off" />
              {
                hasFocus && autoComplete && autoComplete.Items &&
                <div className="auto-complete-items">
                  {autoComplete.Items.map((x, idx) => (
                    <div key={idx} className={"auto-complete-item" + (this.state.hoverIdx === idx ? ' hover' : '')}
                      onClick={() => { this.selectCard(x) }}
                      onMouseEnter={() => { this.hoverCard(idx) }}>
                      <InfoCard CardProps={x.CardProps} CardForm={this.props.control.DefaultValue} PreventClick={true} />
                    </div>
                  ))}
                </div>
              }
            </div>
            :
            <div className="auto-fill-drop-down">
              <div className="selected-card">
                <InfoCard CardProps={control.InfoCard.CardProps} CardForm={this.props.control.DefaultValue} Tabbable={true} CardId={this.state.CardId} />
                <div className="re-select">
                  <div onClick={this.clearSelection}>
                    <ReSelect />
                  </div>
                </div>
              </div>
            </div>
    );
  }
}

export default connect(mapStateToProps)(AutoFillDropDown);