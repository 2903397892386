import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';
import * as Azure from '@azure/storage-blob';

const mapStateToProps = (state, ownProps) => {
  return {
    TenantId: state.met_EntityMetadata.CurrentTenant,
    ConsumerId: state.ms_ActiveConsumerId,
    BlobSASPublic: state.BlobSASPublic,
    // ImagePath: ownProps.entity.EntityTypeId + '/' + ownProps.entity.EntityId + '/' + (ownProps.control.Name || ownProps.control.Label),
  };
};

export class MapSnapshot extends React.PureComponent {

  componentDidMount() {
    this.getSnapshot()
  }

  getSnapshot = async () => {
    let mapElement = document.querySelector('#map');
    if (!mapElement)
      return;

    let swid = mapElement.getAttribute('swid');
    let map = window.MapInstance[swid];

    const mapCanvas = document.createElement('canvas');
    const size = map.getSize();
    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    if(window.IsMobile) {
      mapCanvas.width = 400
      mapCanvas.height = 800
    }
    const mapContext = mapCanvas.getContext('2d');
    Array.prototype.forEach.call(
      map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
      function (canvas) {
        if (canvas.width > 0) {
          const opacity =
            canvas.parentNode.style.opacity || canvas.style.opacity;
          mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
          let matrix;
          const transform = canvas.style.transform;
          if (transform) {
            // Get the transform parameters from the style's transform matrix
            matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(',')
              .map(Number);
          } else {
            matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
          }
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(
            mapContext,
            matrix
          );
          const backgroundColor = canvas.parentNode.style.backgroundColor;
          if (backgroundColor) {
            mapContext.fillStyle = backgroundColor;
            mapContext.fillRect(0, 0, canvas.width, canvas.height);
          }
          mapContext.drawImage(canvas, 0, 0);
        }
      }
    );
    mapContext.globalAlpha = 1;
    mapContext.setTransform(1, 0, 0, 1, 0, 0);
    let dataUrl = mapCanvas.toDataURL();
    const blob = await (await fetch(dataUrl)).blob();

    // let dateSnap = new Date().toLocaleDateString().replace(/\//g, '_') + '_' + new Date().toLocaleTimeString().replace(/ /g, '_').replace(/:/g, '_');
    let dateSnap = 'BudgetMap';

    const file = new File([blob], dateSnap + '.jpg', { type: "image/jpeg", lastModified: new Date() });
    this.uploadFileToBlob(file, 'MapSnapshot/' + this.props.TenantId + '/' + this.props.ConsumerId + '/' + dateSnap + '.jpg');

    // this.props.dispatch(actions.UpdateProp({
    //   Key: 'MainTabContent',
    //   Value: { Value: 'MySalesmanCompleted', Enabled: true }
    // }));

  }

  uploadFileToBlob = (file, path) => {
    let containerClient = this.getBlobContainerClient();
    let uploadOptions = { blobHTTPHeaders: { blobContentType: 'image/jpeg' || 'application/octet-stream' } };
    const blockBlobClient = containerClient.getBlockBlobClient(path);
    blockBlobClient.uploadBrowserData(file, uploadOptions);
  }

  getBlobContainerClient = () => {
    let blobSaS = this.props.BlobSASPublic;
    let blobServiceClient = new Azure.BlobServiceClient(blobSaS.Key);
    let containerName = '';
    return blobServiceClient.getContainerClient(containerName);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MapSnapshot);





// uploadFileToBlob = (file, pathOverride) => {
//   let containerClient = this.getBlobContainerClient();
//   let oploadOptions = { blobHTTPHeaders: { blobContentType: 'image/jpeg' || 'application/octet-stream' } };
//   // let oploadOptions = { blobHTTPHeaders: { blobContentType: file.type } };
//   const blockBlobClient = containerClient.getBlockBlobClient(pathOverride || this.props.ImagePath);
//   blockBlobClient.uploadBrowserData(file, oploadOptions);
// }

// dropFile = (e) => {
//   e.preventDefault();
//   e.currentTarget.style.boxShadow = 'none';
//   this.onChange(e);
// }

// onDragOver = (e) => {
//   e.currentTarget.style.boxShadow = 'inset 0 0 2em 1em orange, 0 0 0 2px rgb(0, 0, 0)';
//   e.preventDefault();
// }

// onDragLeave = (e) => {
//   e.preventDefault();
//   e.currentTarget.style.boxShadow = 'none';
// }

// getBlobContainerClient = () => {
//   let blobSaS = this.props.IsPublic ? this.props.BlobSASPublic : this.props.BlobSAS;
//   let blobServiceClient = new Azure.BlobServiceClient(blobSaS.Key);
//   let containerName = this.props.IsPublic ? '' : blobSaS.Database.replace(/_/g, '').toLowerCase();
//   return blobServiceClient.getContainerClient(containerName);
// }