import React, { PureComponent } from 'react'
import { connect, batch } from 'react-redux';
import WidgetLoader from './WidgetLoader';
import * as actions from './../Stores/Actions/actions';
import * as helpers from './../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let scene = state.blu_ModalScene;
  let interrupt = false;
  if (scene && scene.MasterScene) {
    let master = state.blu_MasterScene;
    if (master && scene.MasterScene !== master.Value) {
      interrupt = true;
    }
  }

  if (scene && scene.ParentKey) {
    let curScene = state[scene.ParentKey];
    if (curScene && curScene.Value !== scene.ParentValue) {
      interrupt = true;
    }
  }

  let modalFrame = null;
  if (state.blu_ModalFrame && state.blu_ModalFrame.Value) {
    modalFrame = state.blu_ModalFrame.Value;
  }

  return {
    Scene: scene && !interrupt ? state.blu_Scene.find(x => x.Name === scene.Value) : null,
    ModalFrame: scene && !interrupt && modalFrame ? state.blu_Scene.find(x => x.Name === modalFrame) : null,
    ModalClass: scene && !interrupt && state.blu_ModalFrame ? state.blu_ModalFrame.ModalClass : '',
    EditMode: state.EditMode,
    ModalScene2: state.blu_ModalScene2
  };
}

export class ModalLayer extends PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.keyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
  }

  // componentDidUpdate(prev) {
  //   if (!this.props.Scene && prev && prev.Scene) {
  //     console.log('you closed a modal');
  //   }
  // }

  keyPress = (e) => {
    if (e.key === 'Escape') {
      if (this.props.ModalScene2 || !this.props.Scene)
        return;

      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    }
  }

  formatRowCols = (val) => {
    let result = val;
    if (!isNaN(Number(result)) && Number(result).toString() === result.toString()) {
      result = 'repeat(' + result + ', 1fr)';
    }

    return result;
  }

  render() {
    let scene = this.props.Scene;
    if (!scene)
      return null;

    let rowColScene = this.props.ModalFrame || scene;

    let rows = this.formatRowCols(rowColScene.Rows);
    let cols = this.formatRowCols(rowColScene.Columns);

    scene.Widgets.sort((a, b) => {
      return a.ZIndex > b.ZIndex ? 1 : a.ZIndex < b.ZIndex ? -1 : 0;
    });

    return (
      <div className={"modal-layer" + (this.props.EditMode ? ' edit-mode' : '') + (' ' + this.props.ModalClass)}>
        <div className="grid" style={{ gridTemplateRows: rows, gridTemplateColumns: cols }}>
          {scene.Widgets.map((item) => (
            <WidgetLoader key={item.SceneWidgetId} widget={item} />
          ))}
          {this.props.ModalFrame && this.props.ModalFrame.Widgets.map((item) => (
            <WidgetLoader key={item.SceneWidgetId} widget={item} />
          ))}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ModalLayer);