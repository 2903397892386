import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import Checkbox from './../../Controls/Checkbox/Checkbox';
import TextBox from './../../Controls/TextBox/TextBox';
import NumberBox from './../../Controls/NumberBox/NumberBox';
import TitleLabel from './../../Controls/TitleLabel/TitleLabel';
import TinyTitleLabel from '../../Controls/TinyTitleLabel/TinyTitleLabel';
import DropDown from './../../Controls/DropDown/DropDown';
import RadioLookup from './../../Controls/RadioLookup/RadioLookup';
import DropDownMobile from './../../Controls/DropDownMobile/DropDownMobile';
import RichTextBox from './../../Controls/RichTextBox/RichTextBox';
import DatePicker from './../../Controls/DatePicker/DatePicker';
import AutoFillDropDown from './../../Controls/AutoFillDropDown/AutoFillDropDown';
import NotFound from './../../Controls/NotFound/NotFound';
import AutoFillButton from './../../Controls/AutoFillButton/AutoFillButton';
import UserList from './../../Controls/UserList/UserList';
import AllUserList from './../../Controls/AllUserList/AllUserList';
import IsDeleted from './../../Controls/IsDeleted/IsDeleted';
import Button from './../../Controls/Button/Button';
import TimePicker from './../../Controls/TimePicker/TimePicker';
import AutoDropDown from './../../Controls/AutoDropDown/AutoDropDown';
import AutoSearch from './../../Controls/AutoSearch/AutoSearch';
import FormWidget from './FormWidget';
import BucketDropDown from '../../Controls/BucketDropDown/BucketDropDown';
import PhotoUploader from '../../Controls/PhotoUploader/PhotoUploader';
import PhotoUploaderPublic from '../../Controls/PhotoUploaderPublic/PhotoUploaderPublic';
import ColorPicker from '../../Controls/ColorPicker/ColorPicker';

const mapStateToProps = (state, ownProps) => {
  let options = ownProps.control.DataMaskOptions ? eval('(' + ownProps.control.DataMaskOptions + ')') : {};
  let render = true;
  if (options.PropRenderCondition) {
    Object.keys(options.PropRenderCondition).forEach(key => {
      let prop = ownProps.allProps.find(x => x.Name === key);
      if(prop === undefined) {
        prop = ownProps.allProps.find(x => x.Label === key)
      }
      /* can pass PropRenderCondition[key] with an array of values or as a single value*/
      if (Array.isArray(options.PropRenderCondition[key])) {
        render = false;
        options.PropRenderCondition[key].forEach(y => {
          if (prop && prop.Value == y) {
            render = true;
          }
        })
      } else {
        if (prop && prop.Value != options.PropRenderCondition[key]) {
          render = false;
        }
      }
    })
  }
  return {
    GlobalReadOnly: state.GlobalReadOnly,
    Render: render
  };
}

export class FormControl extends React.PureComponent {
  formControls = {
    Checkbox: Checkbox,
    CheckBox: Checkbox,
    TextBox: TextBox,
    DropDown: DropDown,
    RadioLookup: RadioLookup,
    DropDownMobile: DropDownMobile,
    TitleLabel: TitleLabel,
    TinyTitleLabel: TinyTitleLabel,
    RichTextBox: RichTextBox,
    DatePicker: DatePicker,
    AutoFillDropDown: AutoFillDropDown,
    UserList: UserList,
    AllUserList: AllUserList,
    IsDeleted: IsDeleted,
    Button: Button,
    NumberBox: NumberBox,
    TimePicker: TimePicker,
    AutoDropDown: AutoDropDown,
    AutoSearch: AutoSearch,
    BucketDropDown: BucketDropDown,
    PhotoUploader: PhotoUploader,
    PhotoUploaderPublic: PhotoUploaderPublic,
    ColorPicker: ColorPicker
  }

  render() {
    let loc = this.props.control.Location;
    let Control = this.formControls[this.props.control.ControlType];
    let styleObj = loc ? { gridColumn: loc.FormColumn, gridRow: loc.FormRow } : null;

    if (this.props.GlobalReadOnly && this.props.control) {
      this.props.control.initialReadOnly = this.props.control.IsRO;
      this.props.control.IsRO = true;
    }

    if (!this.props.GlobalReadOnly && this.props.control.initialReadOnly !== undefined) {
      this.props.control.IsRO = this.props.control.initialReadOnly;
    }

    if (this.props.formReadOnly && this.props.control) {
      this.props.control.IsRO = true;
    }

    if (this.props.entity && !this.props.entity.IsNew && this.props.control.ClassName && this.props.control.ClassName.includes('not-new-disabled')) {
      this.props.control.IsRO = true;
    }

    let autoFillControls = ['AutoFillDropDown', 'AutoDropDown', 'AutoSearch'];

    let isWidget = this.props.control.ControlType && this.props.control.ControlType.includes('widget:');
    return (
      !this.props.Render ? null :
        <div className={"control-wrapper " + this.props.control.ControlType} style={styleObj}>
          {
            !isWidget && this.props.control.ControlType !== 'AutoFillButton' &&
            <div className={"form-control" + (this.props.control.ClassName ? ' ' + this.props.control.ClassName : '')}>
              {this.formControls[this.props.control.ControlType] ?
                <Control control={this.props.control}
                  trackChanges={this.props.trackChanges}
                  isEventSave={this.props.isEventSave}
                  allProps={this.props.allProps}
                  entityProps={this.props.entityProps}
                  refresh={this.props.refresh}
                  entity={this.props.entity}
                  entitySource={this.props.entitySource}
                  parentProps={this.props.parentProps}
                  forceRefreshId={this.props.forceRefreshId}
                  overrideParameter={this.props.overrideParameter}
                  ignoreBlur={this.props.ignoreBlur} />
                :
                <NotFound control={this.props.control} />
              }
            </div>
          }
          {
            !isWidget && this.props.control.AutoFillSQL && !autoFillControls.includes(this.props.control.ControlType) &&
            <div className="auto-fill">
              <AutoFillButton control={this.props.control} entity={this.props.entity} entitySource={this.props.entitySource} refresh={this.props.refresh} isEventSave={this.props.isEventSave} />
            </div>
          }
          {
            isWidget && <FormWidget Control={this.props.control} />
          }
        </div>
    );
  }
}

export default connect(mapStateToProps)(FormControl);