import React from 'react';
import { connect, batch } from 'react-redux';
import * as helpers from './../../../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class Formula extends React.PureComponent {
  getFormula = () => {
    return helpers.getFormula(this.props.items);
  }

  render() {
    return (
      <div className="wf-formula">
        <div>
          {this.getFormula()}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Formula);