import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';


export default class BucketFilter extends React.PureComponent {

  getBuckets = () => {
    let buckets = helpers.getQueryBuckets();
    if (!buckets)
      return [];

    let bucket = buckets[this.props.colDef.bucketName];
    let allItems = bucket.FilterData || bucket.Data;

    let items = Object.keys(allItems).map((key) => {
      return {
        key: key,
        active: true,
        Name1: allItems[key]
      }
    });

    items.sort((a, b) => {
      let aVal = a.Name1 ? a.Name1.toLowerCase() : '';
      let bVal = b.Name1 ? b.Name1.toLowerCase() : '';
      return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
    });

    return items;
  }

  getBucketMetadata = () => {
    let buckets = helpers.getQueryBuckets();
    if (!buckets)
      return [];

    return buckets[this.props.colDef.bucketName];
  }

  state = {
    lookupItems: this.getBuckets(),
    allToggle: true,
    filterType: 'Bucket',
    bucketMetadata: this.getBucketMetadata()
  }

  isFilterActive() {
    let isActive = !!this.state.lookupItems.find(x => !x.active);
    return isActive;
  }

  componentDidMount() {
    console.log(this.props);
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  toggle = (lookup) => {
    lookup.active = !lookup.active;
    let items = cloneDeep(this.state.lookupItems);
    this.setState({ lookupItems: items },
      () => { this.props.filterChangedCallback() });
  }

  toggleAll = () => {
    this.state.lookupItems.forEach(x => {
      x.active = !this.state.allToggle;
    });

    this.setState({ lookupItems: cloneDeep(this.state.lookupItems), allToggle: !this.state.allToggle },
      () => { this.props.filterChangedCallback() });
  }

  getModel() {
    let filterItems = this.state.lookupItems.filter(x => x.active);

    if (filterItems.length === this.state.lookupItems.length)
      return { value: '', remove: true };

    let filterIds = filterItems.map(x => "'" + x.key + "'");

    let filterStr = ' IN (' + filterIds.join(', ') + ')';

    if (filterItems.length === 0) {
      filterStr = ' IN (-1)';
    }

    let includeNull = !!filterItems.find(x => x.Name1 === '');
    let metadata = this.state.bucketMetadata;

    return {
      value: filterStr,
      filterType: this.state.filterType,
      dataType: 'Bucket',
      includeNull: includeNull,
      joinColumn: metadata.JoinColumn,
      joinTable: metadata.JoinTable,
      sortColumn: metadata.SortColumn,
    };
  }

  setModel(model) {
    if (!model || !model.value) {
      this.state.lookupItems.forEach(x => x.active = true);
      this.setState({ lookupItems: cloneDeep(this.state.lookupItems) });
      return;
    }

    let includes = model.value.replace(' IN (', '').replace(')', '').split(', ');
    let lookups = cloneDeep(this.state.lookupItems);
    lookups.forEach(x => x.active = false);

    includes.forEach(key => {
      let lookup = lookups.find(x => "'" + x.key + "'" === key);
      if (lookup) {
        lookup.active = true;
      }
    });
    this.setState({ lookupItems: lookups });
  }

  doesFilterPass(params) {
    console.log({ doesFilterPass: params });
  }

  render() {
    return (
      <div className="ag-lookup-filter">
        <div className="ag-filter-item" onClick={this.toggleAll}>
          <input type="checkbox" checked={this.state.allToggle} onChange={() => { }} />
          <div>(Toggle All)</div>
        </div>
        {
          this.state.lookupItems.map((x, idx) => (
            <div key={idx} className="ag-filter-item" onClick={() => { this.toggle(x) }}>
              <input type="checkbox" checked={x.active} onChange={() => { }} />
              <div>{x.Name1 || '(Empty)'}</div>
            </div>
          ))
        }
      </div>
    )
  }
}

// export default connect(mapStateToProps)(LookupFilter);