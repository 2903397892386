import { cloneDeep } from 'lodash';
import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {
    CurrentEntity: state.ent_Current,
    CurrentEvent: state.ent_CurrentEvent,
    SelectedEvent: state.ent_SelectedEvent,
    Recurrence: state.ent_RecurrenceData
  };
};

export class RecurNewEvent extends React.PureComponent {

  componentDidMount() {
    //Set active schedule to null
    //Set recurrence object to init/empty

    this.props.dispatch(actions.UpdateProp({
      Key: 'rcr_NewSchedule',
      Value: true
    }));

    //   this.props.dispatch(actions.UpdateProp({
    //     Key: 'ent_RecurrenceData',
    //     Value: { ...this.props.Recurrence, RecurringEvent: { EntityId: this.props.SelectedEvent.EntityId, EntityTypeId: this.props.SelectedEvent.EntityTypeId } }
    //   }));
    //   this.props.dispatch(actions.UpdateProp({
    //     Key: 'ent_SelectedEvent',
    //     Value: undefined
    //   }));
    //   this.props.dispatch(actions.UpdateProp({
    //     Key: 'blu_ModalFrame',
    //     Value: undefined
    //   }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(RecurNewEvent);