import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { EmptyCheck, Checked, IndeterminateCheck } from './../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  return {
    AllProps: ownProps.entityProps
  };
}

export class Button extends React.PureComponent {
  onClick = () => {
    let btnObj = this.getObj();
    if (btnObj) {
      this.props.dispatch(actions.UpdateProp({
        Key: btnObj.Key,
        Value: {
          Enabled: true,
          Value: btnObj.Value
        }
      }));
    }
  }

  isEnabled = () => {
    let btnObj = this.getObj();

    if (!btnObj)
      return false;

    let enabled = true;
    if (btnObj && btnObj.RequiredProps) {
      btnObj.RequiredProps.forEach((prop) => {
        let reqProp = this.props.AllProps.find(x => x.Name === prop);
        if (reqProp && !reqProp.Value) {
          enabled = false;
        }
      });
    }

    return enabled;
  }

  getObj = () => {
    try {
      let btnObj = JSON.parse(this.props.control.Value);
      return btnObj;
    } catch (e) {
      console.error('Error parsing JSON: ' + this.props.control.Value);
      console.error(e);
    }
  }

  render() {
    let control = this.props.control;
    let enabled = this.isEnabled();
    return (
      <div className={"button-control button" + (!enabled ? ' disabled' : '')}
        onClick={() => { if (enabled) { this.onClick() } }}>
        <div>{control.Label}</div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Button);