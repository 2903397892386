import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');

  return {
    SaveButton: state.btn_EventButtonBarSave,
  };
};

export class MV_SuperAdminNewContractor extends React.PureComponent {

  componentDidMount() {
    let saveData = {
      IsPlaceholder: true,
      IsEventData: true,
      AfterSave: (changemap, result) => {
        console.log({ ChangeMap: changemap });
        if (changemap.new && changemap.new.EntityId && changemap.new.EntityId > 0 && changemap.old && changemap.old.EntityId && changemap.old.EntityId < 0) {
          let body = {
            SWID: this.props.SWID,
            Parameter: changemap.new.EntityId
          }
          actions.ApiRequest('List/GetList', body, (result) => {
            console.log(result);
          });
        }
      }
    }

    batch(() => {
      this.props.dispatch(actions.AddSaveData(saveData));
      this.props.dispatch(actions.UpdateProp({
        Key: 'btn_EventButtonBarSave',
        Value: { ...this.props.SaveButton, ClickId: this.props.SaveButton.ClickId++ }
      }))
    })
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_SuperAdminNewContractor);