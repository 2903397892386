import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../Utils/Helpers';
import { MagnifyingGlass, RefreshArrow } from '../../../../../Icons/Icons';
import { isConsole } from 'react-device-detect';
import DetailsInput from '../Helpers/WidgetDetailsInput';
import GetDetails from './../Helpers/GetDetails';
import Tabs from './../Helpers/Tabs';
import Tab from './../Helpers/Tab';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class CardListDetails extends React.PureComponent {
  state = {
    Tabs: ['Presets', 'Add Tabs']
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  saveDetails = (type) => {
    let body = {
      Type: type,
      Parameter: JSON.stringify({
        SWID: this.props.Parent.props.EditSWID,
        ...this.props.Parent.currentDetails(type)
      })
    }
    actions.ApiRequest('Editor/SaveDetails', body, () => {
      this.state.RefreshDetails[type]();
    });
  }

  render() {
    return (
      <>
        <Tabs Parent={this} />
        <div className="card-list-details" style={{ width: '650px' }}>
          <Tab Name={'Presets'} Parent={this} >
            <div className="card-list-presets">
              <div>Choose a preset</div>
            </div>
          </Tab>
          <Tab Name={'Add Tabs'} Parent={this} >
            <div className="tab-list-options">
              <GetDetails Type={'NewTab'} Refresh={this.DetailsRefresh} Parent={this} />
              <div>
                <div>
                  Add another tab to this tab list:
                </div>
                <div className="horizontal-btn-bar">
                  <DetailsInput Type={'NewTab'} Prop={'Label'} Parent={this} />
                  <DetailsInput Type={'NewTab'} Prop={'SceneName'} Parent={this} />
                  <DetailsInput Type={'NewTab'} Prop={'DisplayOrder'} Parent={this} />
                  <div className="go-btn" onClick={() => { this.saveDetails('NewTab') }}><div>Go</div></div>
                </div>
              </div>
            </div>
          </Tab>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(CardListDetails);
