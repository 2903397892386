import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import HoverInfo from '../../../../Components/Widgets/HoverInfo/v001/HoverInfo';
import { OrangeRadio, OrangeRadioUnchecked, IndeterminateCheck } from './../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let tenantId = null;

  if (state.ent_Current) {
    tenantId = state.ent_Current.TenantId;
  } else if (state.met_EntityMetadata && state.met_EntityMetadata.CurrentUser) {
    tenantId = state.met_EntityMetadata.CurrentUser.TenantId;
  }

  return {
    SaveData: state.dbo_SaveData,
    Metadata: state.met_EntityMetadata,
    TenantId: tenantId
  };
}

export class RadioLookup extends React.PureComponent {
  onChange = (val) => {
    console.log('%%%%% on change');
    if (this.props.control.IsRO) {
      return;
    }

    this.props.control.Value = val;
    if (this.props.control.FailedValidate) {
      this.props.control.FailedValidate = false;
    }

    this.forceUpdate();

    if (this.props.trackChanges) {
      this.props.dispatch(actions.AddSaveData({
        Id: this.props.control.SaveData.Id,
        Table: this.props.control.SaveData.Table,
        Column: this.props.control.SaveData.Column,
        Value: val,
        IsEventData: this.props.isEventSave,
        SaveQueue: this.props.control.SaveData.SaveQueue,
        InsertKey: this.props.control.SaveData.InsertKey,
        IsBaseTable: this.props.control.IsBaseTable
      }));
    }
  }

  getLookupItems = () => {
    let control = this.props.control;
    let lookupId = control.LookupSetId;

    if (this.props.IsUserList)
      return helpers.getLookupItems('UserList', this.props.TenantId);

    let result = [];
    let lookup = this.props.Metadata.LookupItems.find(x => x.LookupSet === lookupId);

    if (!lookup)
      return result;

    let parentProp = this.props.allProps ? this.props.allProps.find(x => x.LookupSetId === lookup.ParentId) : undefined;
    let parentLookupValue = parentProp ? Number(parentProp.Value) : undefined;

    if (lookup && parentProp) {
      result = lookup.LookupItems.filter(x => !x.ParentId || x.ParentId === parentLookupValue);
    } else if (lookup) {
      result = lookup.LookupItems;
    }

    let curVal = control.Value ? Number(control.Value) : null;
    result = result.filter(x => x.On || x.LookupItemId === curVal);

    if (curVal) {
      let curItem = result.find(x => x.LookupItemId === Number(control.Value));
      if (curItem && curItem.ParentId && curItem.ParentId !== parentLookupValue) {
        this.onChange({ target: { value: 0 } });
      }
    }

    result = result.filter(x => !x.EntityTypeId || this.props.entity.EntityTypeId === x.EntityTypeId);

    return result;
  }

  getClasses = (x) => {
    let control = this.props.control;
    if (control.Value == (x.OverrideValue !== null ? x.OverrideValue : x.LookupItemId)) {
      return 'selected';
    } else {
      return '';
    }

  }

  render() {
    let control = this.props.control;
    let isUserList = this.props.IsUserList || false;

    return (
      !this.props.Metadata ? null :
        <div className={"radiolookup-control" + (control.FailedValidate ? " failed-validate" : "")}>
          <div className="control-label">
            {control.Label}
            {control.HoverInfo && <HoverInfo Text={control.HoverInfo} />}
          </div>
          <div className="radio-lookup-items">
            {this.getLookupItems().map((x, idx) => (
              <div
                tabIndex={0}
                // tabIndex={control.Location && control.Location.ControlOrder ? control.Location.ControlOrder + idx : 0} 
                key={idx} className={"radio-lookup-item " + this.getClasses(x)} onClick={() => { this.onChange((x.OverrideValue !== null ? x.OverrideValue : x.LookupItemId)) }} onKeyPress={(e) => { e.preventDefault(); this.onChange((x.OverrideValue !== null ? x.OverrideValue : x.LookupItemId)) }}>
                <div className="radio-lookup-check">
                  {control.Value == (x.OverrideValue !== null ? x.OverrideValue : x.LookupItemId) && <OrangeRadio />}
                  {control.Value != (x.OverrideValue !== null ? x.OverrideValue : x.LookupItemId) && <OrangeRadioUnchecked />}
                </div>
                <div className="radio-lookup-label">{x.Name1}</div>
              </div>
            ))}
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(RadioLookup);