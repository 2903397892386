import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from './../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    Data: state.qry_Queries[ownProps.queryId].data,
    Query: state.qry_Queries[ownProps.queryId],
    PageRequests: state.qry_Queries[ownProps.queryId].pageRequests,
    RefreshCurrentPage: state.qry_RefreshCurrentPage,
    topRow: state.qry_Queries[ownProps.queryId].topRow,
    pinnedRows: state.qry_Queries[ownProps.queryId].pinnedRows || [],
    visibleRows: state.qry_VisibleRows,
    GroupedColumns: state.qry_GroupedColumns ? state.qry_GroupedColumns[ownProps.queryId] : undefined,
    GroupPageRequests: state.qry_GroupPageRequests || []
  };
};

let _pageSize = 250;

export class GridDataEngine extends React.PureComponent {
  pendingRequests = [];
  pendingGroupRequests = [];

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(prevProps) {
    this.initializeData();

    if (this.props.PageRequests && this.props.PageRequests.length > 0) {
      this.requestPage(this.props.PageRequests[this.props.PageRequests.length - 1]);
    }

    if (this.props.GroupPageRequests && this.props.GroupPageRequests.length > 0) {
      this.groupByRequest(this.props.GroupPageRequests[this.props.GroupPageRequests.length - 1]);
    }

    if (helpers.propDidChange(this.props, prevProps, 'RefreshCurrentPage')) {
      let topPage = Math.floor(this.props.Query.topRow / this.props.Query.data.pageSize);
      this.requestPage(topPage);
      this.requestPage(topPage + 1);
    }
  }

  initializeData = () => {
    if (!this.props.Data) {
      this.props.dispatch(actions.UpdateQueryData({
        queryId: this.props.queryId,
        data: {
          pageSize: _pageSize,
          topRow: 0,
          rowCount: null,
          rows: []
        },
        dataKey: 'data'
      }));
      this.requestPage(0);
    }
  }

  requestPage = (pageNumber) => {
    if (this.pendingRequests.find(x => x === pageNumber))
      return;

    this.pendingRequests.push(pageNumber);
    let body = this.getPageRequestBody(pageNumber);

    if (!body)
      return;

    actions.ApiRequest('Query/GetQueryData', body, (result) => {
      let queryData = this.props.Data;
      if (body.IncludeRowCount) {
        queryData.rowCount = result.RowCount;
      }

      let requests = (this.props.PageRequests || []).filter(x => x !== pageNumber);
      this.pendingRequests = this.pendingRequests.filter(x => x !== pageNumber);

      batch(() => {
        this.props.dispatch(actions.UpdatePageRequests({
          queryId: this.props.queryId,
          requests: requests
        }));

        this.props.dispatch(actions.AddGridRows({
          queryId: this.props.queryId,
          rows: result.Data,
          page: pageNumber,
          dataKey: 'rows',
          rowNumber: body.DataIndex
        }));

        this.props.dispatch(actions.SetRowCount({
          queryId: this.props.queryId,
          rowCount: queryData.rowCount
        }));

        if (body.RowCountFilterUpdate) {
          this.props.dispatch(actions.UpdateProp({
            Key: 'qry_RefreshCurrentPage',
            Value: this.props.RefreshCurrentPage + 1
          }));
        }
      });
    });
  }

  getPageRequestBody = (pageNumber) => {
    if (this.props.GroupedColumns && this.props.GroupedColumns.length > 0) {
      return this.groupPageRequest(pageNumber);
    }

    let query = this.props.Query;

    let updateRowCount = query.allIds.find(x => query[x].UpdateRowCount) !== undefined;
    if (updateRowCount) {
      query.allIds.forEach(x => query[x].UpdateRowCount = undefined);
    }

    return {
      QueryId: this.props.queryId,
      SortBy: query.sortBy,
      Page: pageNumber,
      PageSize: _pageSize,
      IncludeRowCount: updateRowCount || !this.props.Data || this.props.Data.rowCount === null,
      RowCountFilterUpdate: updateRowCount,
      Columns: this.props.Query.allIds.map((x, idx) => {
        let queryObj = this.props.Query[x];
        let queryFilter = helpers.parseFilter(queryObj);
        return {
          ColumnName: queryObj.ColumnName,
          IsVisible: queryObj.IsVisible,
          DisplayOrder: queryObj.DisplayOrder,
          Filter: queryFilter,
          Aggregate: {}
        };
      })
    }
  }

  groupByRequest = (req) => {
    if (this.pendingGroupRequests.includes(req.Index))
      return;

    this.pendingGroupRequests.push(req.Index);
    let pageSize = Math.min(_pageSize - 1 - (req.Index % _pageSize), req.Qty);
    let body = this.requestBody(0, pageSize, false, req.Filter);

    actions.ApiRequest('Query/GetQueryData', body, (result) => {
      let queryData = this.props.Data;
      if (body.IncludeRowCount) {
        queryData.rowCount = result.RowCount;
      }

      this.pendingGroupRequests = this.pendingGroupRequests.filter(x => x !== req.Index);

      batch(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'qry_GroupPageRequests',
          Value: this.props.GroupPageRequests.filter(x => x.Index !== req.Index)
        }));

        this.props.dispatch(actions.AddGridRows({
          queryId: this.props.queryId,
          rows: result.Data,
          page: null,
          dataKey: 'rows',
          rowNumber: req.Index + 1
        }));

        this.props.dispatch(actions.SetRowCount({
          queryId: this.props.queryId,
          rowCount: queryData.rowCount
        }));

        if (body.RowCountFilterUpdate) {
          this.props.dispatch(actions.UpdateProp({
            Key: 'qry_RefreshCurrentPage',
            Value: this.props.RefreshCurrentPage + 1
          }));
        }
      });
    });
  }

  groupPageRequest = (pageNumber) => {
    let p = this.props;
    let rows = p.Query.rows;
    let groupItems = this.props.GroupedColumns[0].RowSourceItems;
    if (!groupItems) {
      this.cancelRequest(pageNumber);
      return;
    }


    let rowItem = rows[pageNumber * _pageSize];
    if (!rowItem) {
      this.cancelRequest(pageNumber);
      return;
    }

    let groupItem = groupItems.find(x => x.Index === rowItem.GroupIdx);
    if (!groupItem) {
      this.cancelRequest(pageNumber);
      return;
    }

    let pageSize = Math.min(_pageSize, groupItem.Qty - rowItem.GroupRowNumber);

    let updateRowCount = p.Query.allIds.find(x => p.Query[x].UpdateRowCount) !== undefined;
    if (updateRowCount) {
      p.Query.allIds.forEach(x => p.Query[x].UpdateRowCount = undefined);
    }

    return this.requestBody(0, pageSize, updateRowCount, groupItem.Filter, rowItem.GroupRowNumber, 1 + groupItem.Index + rowItem.GroupRowNumber);
  }

  cancelRequest = (pageNumber) => {
    this.pendingRequests = this.pendingRequests.filter(x => x !== pageNumber);
  }

  requestBody = (pageNumber, pageSize, updateRowCount, staticFilter, offsetOverride = null, dataIndex = null) => {
    return {
      QueryId: this.props.queryId,
      SortBy: this.props.Query.sortBy,
      Page: pageNumber,
      PageSize: pageSize,
      OffsetOverride: offsetOverride,
      IncludeRowCount: updateRowCount || !this.props.Data || this.props.Data.rowCount === null,
      RowCountFilterUpdate: updateRowCount,
      DataIndex: dataIndex,
      StaticFilter: staticFilter,
      Columns: this.props.Query.allIds.map((x, idx) => {
        let queryObj = this.props.Query[x];
        let queryFilter = helpers.parseFilter(queryObj);
        return {
          ColumnName: queryObj.ColumnName,
          IsVisible: queryObj.IsVisible,
          DisplayOrder: queryObj.DisplayOrder,
          Filter: queryFilter,
          Aggregate: {}
        };
      })
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(GridDataEngine);