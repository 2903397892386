import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  // let swids = Object.keys(state.ActiveSWIDs);
  let widgets = state.blu_Scene.reduce((prev, next) => {
    prev.push(...next.Widgets);
    return prev;
  }, []);

  let subscenes = widgets.filter(x => x.WidgetName === 'SubScene' && state.ActiveSWIDs[x.SceneWidgetId]).map(x => {
    let currentScene = null;

    let initScene = x.Parameters.find(p => p.ParameterName === 'InitialScene');
    let sceneKey = x.Parameters.find(p => p.ParameterName === 'SceneKey');

    let sceneName = sceneKey && state[sceneKey.ParameterValue] ? state[sceneKey.ParameterValue].Value : initScene.ParameterValue

    if (sceneName) {
      currentScene = state.blu_Scene.find(s => s.Name === sceneName);
    }

    return {
      Name: currentScene ? currentScene.Name : 'Not Found',
      SWID: x.SceneWidgetId
    }
  });

  return {
    ActiveSwids: state.ActiveSWIDs,
    ActiveSubScenes: subscenes,
    FullEditSubSceneSwid: state.FullEditSubScene ? state.FullEditSubScene.SWID : null
  };
};

export class FullEditSidebar extends React.PureComponent {

  setFullEditSubscene = (subscene) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'FullEditSubScene',
      Value: subscene
    }));
  }

  render() {
    return (
      <div className="side-bar">
        <div className="sub-scene-container">
          <div className="sub-scene-label"><div>Visible SubScenes</div></div>
          {this.props.ActiveSubScenes.map((x, idx) => (
            <div key={idx} className={"sub-scene-item" + (this.props.FullEditSubSceneSwid === x.SWID ? ' selected' : '')}>
              <div className="sub-scene-name" onClick={() => { this.setFullEditSubscene(x) }}>
                <div>{x.Name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(FullEditSidebar);