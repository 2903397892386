import React from 'react';
import { connect } from 'react-redux';
import * as actions from './../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';
import BaseFilter from './Filters/BaseFilter';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class ColumnFilter extends React.PureComponent {
  render() {
    let settings = this.props.settings;

    let filters = {
      Number: BaseFilter,
      String: BaseFilter,
      Lookup: BaseFilter,
      Date: BaseFilter,
      Bool: BaseFilter
    }

    let FilterComponent = filters[settings.DataType];

    return (
      <div className={'header-filter ' + settings.ColumnId + '-filter'}>
        {FilterComponent && <FilterComponent settings={settings} />}
        {!FilterComponent && <div>{settings.DataType + ' filter not implemented'}</div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ColumnFilter);