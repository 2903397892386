import './App.css';
import './311.css';
import './Styles/ol.css';
import React from 'react';
import Store from './Stores/Store';
import SceneLayout from './Components/SceneLayout';
import ModalLayer from './Components/ModalLayer';
import ModalLayer2 from './Components/ModalLayer2';
import DialogLayer from './Components/DialogLayer';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './Stores/Reducers/reducer';
import thunk from 'redux-thunk';
import { useAuth0 } from "./react-auth0-spa";


let _token = null;
let _waiting = false;

function App() {
  const { loading, loginWithRedirect, isAuthenticated, getTokenSilently, logout } = useAuth0();
  const [, forceUpdate] = React.useState(0);

  if (loading)
    return <div></div>;

  if (!_token && !_waiting) {
    // logout();
    _waiting = true;
    window._getToken = getTokenSilently;
    window._logout = logout;
    getTokenSilently().then(result => {
      _token = result;
      forceUpdate(n => ++n);
    }).catch(e => {
      console.log({ catchResult: e });
    }).finally(e => {
      console.log({ finallyResult: e });
    });
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { targetUrl: window.location.href.replace(window.location.origin, "") } });
  } else {
    return (
      !_token ? <div></div> :
        <Provider store={Store}>
          <div id="app">
            <div id="widget-detail-portal"></div>
            <DialogLayer />
            <ModalLayer />
            <ModalLayer2 />
            <SceneLayout token={_token} />
          </div>
        </Provider>
    );
  }
}

export default App;