import React from 'react';
import * as helpers from '../../../../Utils/Helpers';
import { ReSelect } from './../../../Icons/Icons'

const EntityCard = props => {
  let ent = props.TimeEntity;
  let isLeave = ent.ProjectTypeLid === 999;
  let imgName = isLeave ? 'leave' : ent.EntityTypeId;

  let projTypeName = '';
  if (ent.Text4 && ent.Text4.Value) {
    if (ent.Text4.LookupSetId) {
      projTypeName = helpers.getLookupName(null, ent.Text4.Value);
    } else {
      projTypeName = ent.Text4.Value;
    }
  }

  let canLink = props.LoadEntity && projTypeName !== 'Admin';

  return (
    <div className={"time-entity" + (isLeave ? ' lr-entity' : '')}>
      <div className="icon">
        <img src={`https://beehivetilcache.blob.core.windows.net/staticassets/FeatureClassIcons/${imgName}.png`}></img>
      </div>
      <div className="time-entity-details">
        <div className="top-row">
          <div className={"name" + (!canLink ? ' no-link' : '')} onClick={() => { if (canLink) { props.LoadEntity(ent) } }} >{ent.Name}</div>
          <div className="date">{ent.Date}</div>
          <div className="right-align">{projTypeName}</div>
        </div>
        <div className="bottom-row">
          <div className="text-1">
            <div className="text-label">{ent.Text1.Title + ':'}</div>
            <div>{ent.Text1.Value}</div>
          </div>
          <div className="text-2">
            <div className="text-label">{ent.Text2.Title + ':'}</div>
            <div>
              {!ent.Text2.LookupSetId && ent.Text2.Value}
              {ent.Text2.LookupSetId && helpers.getLookupName(ent.Text2.LookupSetId, ent.Text2.Value)}
            </div>
          </div>
          <div className="text-3">
            <div className="text-label">{ent.Text3.Title + ':'}</div>
            <div>
              {!ent.Text3.LookupSetId && ent.Text3.Value}
              {ent.Text3.LookupSetId && helpers.getLookupName(ent.Text3.LookupSetId, ent.Text3.Value)}
            </div>
          </div>
        </div>
      </div>
      {(props.IsReadOnly || !props.ShowReselect) ? null :
        <div className="reselect" onClick={() => { props.EditMode() }} >
          <ReSelect />
        </div>
      }
    </div>
  );
}


export default EntityCard