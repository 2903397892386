import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import Inputmask from "inputmask";
import { forEach } from 'lodash';
import { preventDefault } from 'ol/events/Event';

const mapStateToProps = (state, ownProps) => {

  //ownProps.Text if used by controls, if empty it is used by widget and set text from scenewidgetparameter
  let infoText = '';
  if (!ownProps.Text) {
    let infoObject = ownProps.widget.Parameters.find(x => x.ParameterName === 'Text');
    infoText = infoObject.ParameterValue
  } else {
    infoText = ownProps.Text;
  }

  //Set \n to be new lines
  let infoFinalText = infoText.replaceAll("\\n", "\n");

  return {
    Text: infoFinalText
  };
}

export class HoverInfo extends React.PureComponent {
  state = {
    showInfo: false,
    uniqueId: Math.random()
  }

  componentDidMount() {
    this.fixOverflow();
    document.addEventListener('mousedown', this.mouseDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.mouseDown);
  }

  fixOverflow = (e) => {
    let hoverInfos = document.getElementsByClassName("show-info-outer");
    if (hoverInfos.length > 0) {
      hoverInfos[0].parentElement.parentElement.parentElement.parentElement.style.overflow = 'visible';
    }
  }

  open = () => {
    let swap = !this.state.showInfo;
    this.setState({ showInfo: swap });
  }

  mouseDown = (e) => {
    if (e.target.getAttribute('id') === this.state.uniqueId.toString()) {
      return
    } else {
      if (this.state.showInfo === true) {
        this.setState({ showInfo: false });
      }
    }
  }

  close = () => {
    this.setState({ showInfo: false });
  }

  render() {
    let wider = false;
    if (this.props.Text.length > 45) {
      wider = true;
    }
    return (
      <div className="show-info-outer">
        <div className="show-info-container">
          <div className="show-info-circle" onClick={this.open}>
            <div id={this.state.uniqueId}>?</div>
          </div>
        </div>
        {this.state.showInfo &&
          <div className="hover-info">
            <div className="hover-info-parent">
              <div className={"hover-info-container " + (wider ? "width-300" : "")}>
                <div className="hover-info-caret"></div>
                <div className="hover-close-btn" onClick={this.close}>✖</div>
                <div className="hover-text">{this.props.Text}</div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(HoverInfo);