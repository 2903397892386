import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import EntityCard from './EntityCard';
import * as ttData from './ttData';

const mapStateToProps = (state, ownProps) => {
  return {
    Refresh: state.ttr_RefreshEntry || 0
  };
};

export class TimeEntry extends React.PureComponent {
  initialState = {
    projectType: 0, //from -1 to 0, so it loads all events without having to change drop down to reset from -1
    searchText: '',
    wageCodeId: -1,
    duration: 0,
    note: '',
    entitySearchResults: [],
    showEntityDropdown: false,
  }

  state = cloneDeep(this.initialState);

  componentDidMount() {
    this.createKeyNav();
    let row = this.props.RowData;
    if (!row) {
      this.setDefaultWageCode();
    } else {
      this.setState({
        wageCodeId: row.WageCodeId,
        duration: row.Duration,
        note: row.Note,
      });
    }
  }

  componentDidUpdate() {
    this.setDefaultWageCode();
    if (this.props.sheet.resetEntry) {
      this.props.sheet.resetEntry = false;
      this.setState(cloneDeep(this.initialState));
    }
  }

  setDefaultWageCode = () => {
    if (this.state.wageCodeId !== -1)
      return;

    let WCs = this.props.sheet.WageCodes;
    let dft = WCs.find(x => x.IsDefault);
    if (dft) {
      this.setState({ wageCodeId: dft.Id });
    } else if (WCs.length > 0) {
      this.setState({ wageCodeId: WCs[0].Id });
    }
  }

  generateNewItem = (entity) => {
    if (this.props.EditMode) {
      this.props.RowData.TimeEntity = entity;
      let WCs = this.props.sheet.WageCodes;
      let dft = WCs.find(x => x.IsDefault);
      this.props.RowData.WageCodeId = entity.WageCodeOverride || dft.Id || this.state.wageCodeId;
      if (entity.EntityId == 222215 && entity.EntityTypeId == 1506) { this.props.RowData.WageCodeId = 5; } //Holiday project to default to Holiday wage code
      ttData.addSaveDataForRecord(this.props.dispatch, this.props.userId, this.props.sheet, this.props.RowData);
      this.props.EditComplete();
    } else {
      let item = {
        Id: null,
        InsertKey: helpers.getInsertKey(),
        Duration: this.state.duration,
        Note: this.state.note,
        TimeEntity: entity,
        WageCodeId: entity.WageCodeOverride || this.state.wageCodeId
      }
      if (entity.EntityId == 222215 && entity.EntityTypeId == 1506) { item.WageCodeId = 5; } //Holiday project to default to Holiday wage code
      this.props.addRecord(item);
      ttData.addSaveDataForRecord(this.props.dispatch, this.props.userId, this.props.sheet, item);
      this.setState(cloneDeep(this.initialState));
      this.forceUpdate();
    }
  }

  getProjectTypes = () => {
    let vals = helpers.getLookupItems(this.props.projectLTID);
    vals = [
      {
        Name1: 'All',
        Name2: '0',
        On: true,
        LookupItemId: 0,
        ParentId: null
      },
      ...vals];

    return vals;
  }

  setProjectType = (e) => {
    this.setState({ projectType: e.target.value });
    this.setState({ entitySearchResults: [] });
    if (e.target.value === '999') {
      setTimeout(() => {
        this.setState({ showEntityDropdown: true });
        this.doSearch();
      }, 0);
    }
  }

  setSearchText = (e) => {
    this.setState({ searchText: e.target.value, showEntityDropdown: true });

    if (e.target.value.trim() === '' && this.state.projectType !== '999') {
      this.setState({ showEntityDropdown: false, entitySearchResults: [] });
      return;
    }
    this.doSearch(e);
  }

  doSearch = (e) => {
    if (Number(this.state.projectType) == -1)
      return;

    let body = {
      SearchText: e ? e.target.value : '',
      SearchType: Number(this.state.projectType),
      PageNumber: 0,
      UserId: this.props.User.Id,
      PayPeriodId: this.props.sheet.PayPeriodId
    }

    actions.ApiRequest('TimeTracking/EntitySearch', body, (result) => {
      this.setState({ entitySearchResults: result });
      console.log(result);
    });
  }

  wageCodeChange = (e) => {
    this.setState({ wageCodeId: e.target.value });
  }

  durationChange = (e) => {
    this.setState({ duration: e.target.value * 60 });
  }

  noteChange = (e) => {
    this.setState({ note: e.target.value });
  }

  cardHover = (card) => {
    this.state.entitySearchResults.forEach(x => x.Active = false);
    card.Active = true;
    this.forceUpdate();
  }

  keyDown = (e) => {

    let idx = this.state.entitySearchResults.findIndex(x => x.Active);

    if (e.which === 38) { // up         
      if (idx === -1)
        return;

      let item = this.state.entitySearchResults[idx - 1];
      if (item) {
        this.cardHover(item);
        helpers.scrollIntoView(document.querySelector('.result-item.active'));
      }
    }
    if (e.which === 40) { // down            
      let item = this.state.entitySearchResults[idx + 1];
      if (item) {
        this.cardHover(item);
        helpers.scrollIntoView(document.querySelector('.result-item.active'));
      }
    }
    if (e.which === 13 || e.which === 9) { // enter or tab
      if (idx === -1)
        return;

      if (this.state.showEntityDropdown) {
        let item = this.state.entitySearchResults.find(x => x.Active === true);
        if (item) {
          this.generateNewItem(item);
        }
      }
    }
  }

  windowClick = (e) => {
    if (!helpers.elementOrAncestorHasClass(e.target, 'entity-search')) {
      this.setState({ showEntityDropdown: false });
    }
  }

  searchFocus = () => {
    if (this.state.searchText || this.state.projectType === '999') {
      this.setState({ showEntityDropdown: true });
    }
  }

  componentWillUnmount() {
    this.destroyKeyNav();
  }

  createKeyNav = () => {
    document.addEventListener('keydown', this.keyDown);
    window.addEventListener('click', this.windowClick);
  }

  destroyKeyNav = () => {
    document.removeEventListener('keydown', this.keyDown);
    window.removeEventListener('click', this.windowClick);
  }

  select = (e) => {
    e.target.select();
  }

  render() {
    let sheet = this.props.sheet;
    return (
      !sheet ? null :
        <div className={"time-entry" + (this.props.EditMode ? '' : ' new-record')}>
          <div className="project-type">
            <select value={this.state.projectType} onChange={this.setProjectType}>
              {this.getProjectTypes().map((x, idx) => (
                <option key={idx} value={x.Name2}>{x.Name1}</option>
              ))}
            </select>
          </div>
          <div className="entity-search">
            <input type="text" placeholder="Entity Search" value={this.state.searchText} onChange={this.setSearchText} onFocus={this.searchFocus} />
            {this.state.showEntityDropdown &&
              <div className="search-results">
                {this.state.entitySearchResults.map((x, idx) => (
                  <div key={idx} className={"result-item" + (x.Active ? ' active' : '')}
                    onClick={() => { this.generateNewItem(x) }}
                    onMouseEnter={() => { this.cardHover(x) }}>
                    <EntityCard TimeEntity={x} />
                  </div>
                ))}
              </div>}
          </div>
          <div className="time-code">
            <div className="wage-code">
              <select value={this.state.wageCodeId} onChange={this.wageCodeChange}>
                {sheet.WageCodes.map((x, wcIdx) => (
                  <option key={wcIdx} value={x.Id}>{x.Name}</option>
                ))}
              </select>
            </div>
            <div className="time">
              <input type="number" step=".25" value={(this.state.duration / 60)} onChange={this.durationChange} onClick={this.select} />
            </div>
          </div>
          <div className="time-comment">
            <textarea placeholder="Comment" type="text" value={this.state.note} onChange={this.noteChange} />
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(TimeEntry);
