
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { batch } from 'react-redux';

export const addSaveData = (dispatch, userId, bank, propName, obj, typeId) => {
  let insertKey = null;

  if (obj.Id === null) {
    insertKey = obj.insertKey || helpers.getInsertKey();
    obj.insertKey = insertKey;
  }

  let baseObj = {
    Id: obj.Id,
    InsertKey: insertKey,
    Table: 'ttr.UserAccrual',
    IsEventData: false,
    SaveQueue: 'ttr_LeaveSaveData',
    IsBaseTable: true,
    EntityTypeId: 100
  }

  batch(() => {
    helpers.addSaveData(dispatch, baseObj, 'UserId', userId);
    helpers.addSaveData(dispatch, baseObj, 'AccrualTypeId', typeId);
    helpers.addSaveData(dispatch, baseObj, 'TimeBankId', bank.TimeBankId);
    helpers.addSaveData(dispatch, baseObj, 'WageCodeId', typeId === 1 ? null : obj.WageCodeId);
    helpers.addSaveData(dispatch, baseObj, 'IsDeleted', false);

    let propValue = isNaN(obj[propName]) ? null : obj[propName];

    if (propName !== 'MaxAccrual' && propName !== 'YearlyAccrual') {
      helpers.addSaveData(dispatch, baseObj, 'AccrualRate', propValue);
    } else {
      if (propName === 'MaxAccrual') {
        helpers.addSaveData(dispatch, baseObj, 'MaxAccrual', propValue);
      }
      if (propName === 'YearlyAccrual') {
        helpers.addSaveData(dispatch, baseObj, 'YearlyAccrual', propValue);
      }
    }
  });
}

export const saveAdjustment = (timebanks, bank) => {
  let baseObj = {
    Id: null,
    InsertKey: helpers.getInsertKey(),
    Table: 'ttr.Ledger',
    IsEventData: false,
    SaveQueue: 'ttr_LeaveSaveData',
    IsBaseTable: true,
    EntityTypeId: 100
  }

  let adjObj = bank.Adjustment;

  let saveData = [
    helpers.saveDataItem(baseObj, 'UserId', timebanks.UserId),
    helpers.saveDataItem(baseObj, 'PayPeriodId', adjObj.PayPeriodId > 0 ? adjObj.PayPeriodId : null),
    helpers.saveDataItem(baseObj, 'TimeBankId', bank.TimeBankId),
    helpers.saveDataItem(baseObj, 'Adjustment', adjObj.Adjustment),
    helpers.saveDataItem(baseObj, 'AccrualReasonId', 3),
    helpers.saveDataItem(baseObj, 'AdjustmentDate', new Date().toISOString()),
    helpers.saveDataItem(baseObj, 'Note', adjObj.Note)
  ];

  actions.ApiRequest('Save/Save', { SaveData: saveData }, () => { });
}
