import React, { PureComponent } from 'react'
import ModeSelector from '../ModeSelector/ModeSelector';
import { Clear, Done } from '../../../../../../Icons/Icons';
import * as actions from '../../../../../../../Stores/Actions/actions';

export default class BoolField extends PureComponent {
  toggleProp = (propName) => {
    let parent = this.props.parent;

    parent.props.dispatch(actions.SetPendingFilter({
      colId: parent.props.settings.ColumnId,
      filter: {
        ...parent.props.Filter,
        Mode: { ...this.props.mode, [propName]: !this.props.mode[propName] }
      }
    }));
  }

  getHelperText = () => {
    let vals = [];

    if (this.props.mode.BoolEmpty)
      vals.push('Empty');

    if (this.props.mode.BoolTrue)
      vals.push('True');

    if (this.props.mode.BoolFalse)
      vals.push('False');

    return vals.join(', ');
  }

  onChange = () => {
  }

  render() {
    let parent = this.props.parent;
    return (
      <div>
        {
          <div className={'type-filter text-filter filter-col-' + parent.props.settings.ColumnId}>
            <div className="bool-helper-text" onClick={parent.showFilterToggle}>
              <div>{this.getHelperText()}</div>
            </div>
            {
              parent.state.ShowFilters &&
              <div className="bool-options-dropdown">
                <div className="bool-option" onClick={() => { this.toggleProp('BoolEmpty') }}>
                  <input type="checkbox" checked={this.props.mode.BoolEmpty} onChange={this.onChange} />
                  <div>Empty</div>
                </div>
                <div className="bool-option" onClick={() => { this.toggleProp('BoolTrue') }}>
                  <input type="checkbox" checked={this.props.mode.BoolTrue} onChange={this.onChange} />
                  <div>True</div>
                </div>
                <div className="bool-option" onClick={() => { this.toggleProp('BoolFalse') }}>
                  <input type="checkbox" checked={this.props.mode.BoolFalse} onChange={this.onChange} />
                  <div>False</div>
                </div>
              </div>
            }
            {
              parent.isDirty() && this.props.parent.state.Editing &&
              <div className="filter-done" onClick={parent.confirmFilter}>
                <Done />
              </div>
            }
            <div className="filter-clear" onClick={parent.clearFilter}>
              <Clear />
            </div>
          </div>
        }
      </div>
    )
  }
}


