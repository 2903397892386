import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import FormControl from '../../../Form/v001/FormControl';

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export class ResourceEntry extends React.PureComponent {
  render() {
    return (
      <div className="resource-entry">
        <div className="entry-label" style={{ backgroundColor: this.props.Resource.HeaderColor }}>
          <div>{this.props.Resource.Label + ' Entry'}</div>
        </div>
        <div className="fields">
          {this.props.Resource.Fields.filter(x => x.Label).map((x, idx) => (
            <FormControl key={idx} control={x} trackChanges={false} />
          ))}
        </div>
        <div className="entry-button-container">
          <div className="entry-button entry-add" onClick={() => { this.props.isEditMode ? this.props.updateRow(this.props.Resource) : this.props.addRow(this.props.Resource) }}>
            <div>{this.props.isEditMode ? 'Update' : 'Add'}</div>
          </div>
          {!this.props.isEditMode ? null :
            <div className="entry-button entry-remove" onClick={this.props.removeRow}>
              <div>Remove</div>
            </div>
          }
          {
            <div className="entry-button entry-clear" onClick={this.props.cancel}>
              <div>Cancel</div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ResourceEntry);