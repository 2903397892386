import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    UserLocation: state.UserLocation
  };
};

export class ADLMonitor extends React.PureComponent {
  _lastSent = null;

  componentDidUpdate() {
    if (!this.props.UserLocation || !this.props.UserLocation.Latitude)
      return;

    let param = JSON.stringify({ Latitude: this.props.UserLocation.Latitude.toFixed(3), Longitude: this.props.UserLocation.Longitude.toFixed(3) });
    if (param !== this._lastSent) {
      this._lastSent = param;

      let body = {
        SWID: this.props.widget.SceneWidgetId,
        Parameter: JSON.stringify({ Latitude: this.props.UserLocation.Latitude.toFixed(5), Longitude: this.props.UserLocation.Longitude.toFixed(5) })
      }

      actions.ApiRequest('List/GetList', body, (result) => {
        console.log({ getListResult: result });
      });
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(ADLMonitor);