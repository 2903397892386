import React from 'react';
import { batch, connect } from 'react-redux';
import parser from 'html-react-parser';
import * as helpers from '../../../../Utils/Helpers';
import * as actions from '../../../../Stores/Actions/actions';
import * as icons from '../../../Icons/Icons';
import * as Azure from '@azure/storage-blob';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let templateName = ownProps.widget.Parameters.find(x => x.ParameterName === 'TemplateName');
  let scriptRunnerOnLoad = ownProps.widget.Parameters.find(x => x.ParameterName === 'ScriptRunnerOnLoad');
  let refreshOnSave = ownProps.widget.Parameters.find(x => x.ParameterName === 'RefreshOnSave');

  let param = null;
  if (parameter) {
    param = helpers.getListParameter(state, parameter.ParameterValue);
  }

  return {
    Parameter: param ? JSON.stringify(param) : null,
    TemplateName: templateName ? templateName.ParameterValue : null,
    Markup: state['_template' + ownProps.widget.SceneWidgetId],
    MarkupKey: '_template' + ownProps.widget.SceneWidgetId,
    ScriptRunnerOnLoad: scriptRunnerOnLoad ? scriptRunnerOnLoad.ParameterValue : null,
    RefreshOnSave: refreshOnSave ? helpers.stringToBool(refreshOnSave.ParameterValue) : false,
    SaveId: state.dbo_SaveId
  };
};

export class Template extends React.PureComponent {
  reqId = 0;

  componentDidMount() {
    this.getTemplate();
  }
  componentDidUpdate(prev) {
    if (prev && prev.SaveId !== this.props.SaveId) {
      this.getTemplate();
    } else if (prev && prev.Parameter !== this.props.Parameter) {
      this.getTemplate();
    }
  }

  getTemplate = () => {
    if (!this.props.TemplateName)
      return;

    this.reqId++;

    let body = {
      Name: this.props.TemplateName,
      Parameter: this.props.Parameter,
      ReqId: this.reqId
    }

    actions.ApiRequest('Document/GetTemplate', body, (result) => {
      if (this.reqId !== body.ReqId) {
        console.log('outdated template result blocked');
        return;
      }
      if (this.props.ScriptRunnerOnLoad) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.ScriptRunnerOnLoad,
          Value: true
        }));
      }
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.MarkupKey,
        Value: result.Markup
      }));
    });
  }

  render() {
    return (
      <div className="template-widget">
        {this.props.Markup && parser(this.props.Markup)}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Template);