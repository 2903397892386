import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';


export default class BoolFilter extends React.PureComponent {
  state = {
    lookupItems: [{ Name1: 'True', active: false }, { Name1: 'False', active: false }],
    filterType: 'Bool'
  }

  isFilterActive() {
    return this.state.lookupItems[0].active !== this.state.lookupItems[1].active;
  }

  componentDidMount() { }

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  toggle = (lookup) => {
    lookup.active = !lookup.active;
    let items = cloneDeep(this.state.lookupItems);
    this.setState({ lookupItems: items },
      () => { this.props.filterChangedCallback() });
  }

  getModel() {
    let items = this.state.lookupItems;
    let filterItem = items.find(x => x.active);

    if (!filterItem || items[0].active === items[1].active)
      return { value: '', remove: true }

    let filterStr = '= ' + (filterItem.Name1 === 'True' ? '1' : '0');

    return { value: filterStr, filterType: this.state.filterType };
  }

  setModel(model) {
    if (!model || !model.value) {
      this.state.lookupItems.forEach(x => x.active = true);
      this.setState({ lookupItems: cloneDeep(this.state.lookupItems) });
      return;
    }

    let excludes = model.value.replace('NOT IN (', '').replace(')', '').split(', ').map(x => Number(x));
    let lookups = cloneDeep(this.state.lookupItems);
    excludes.forEach(id => {
      let lookup = lookups.find(x => x.LookupItemId === id);
      if (lookup) {
        lookup.active = false;
      }
    });
    this.setState({ lookupItems: lookups });
  }

  doesFilterPass(params) {
    console.log({ doesFilterPass: params });
  }

  render() {
    return (
      <div className="ag-lookup-filter">
        {
          this.state.lookupItems.map((x, idx) => (
            <div key={idx} className="ag-filter-item" onClick={() => { this.toggle(x) }}>
              <input type="checkbox" checked={x.active} onChange={() => { }} />
              <div>{x.Name1}</div>
            </div>
          ))
        }
      </div>
    )
  }
}

// export default connect(mapStateToProps)(LookupFilter);