import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');

  return {
    ActiveConsumerId: state.ms_ActiveConsumerId,
    SelectedPolygons: state.ms_SelectedPolygons
  };
};

export class MV_GetCost extends React.PureComponent {

  componentDidMount() {
    //call cost calculation
    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify({ ConsumerId: this.props.ActiveConsumerId })
    }
    actions.ApiRequest('List/GetList', body, (result2) => {
      let selectedPolygons = this.props.SelectedPolygons.slice();
      if (!result2 || result2 === {} || result2.StackTrace || !Array.isArray(result2)) {
        console.log({ result2: result2 });
        return;
      }
      //update ms_SelectedPolygons with new cost info
      selectedPolygons.forEach(x => {
        let newCost = result2.find(y => y.PlanId == x.planId);
        if (newCost) {
          x.lowCost = newCost.LowCost;
          x.highCost = newCost.HighCost;
          if (newCost.HidePricingText) {
            x.Amount = newCost.HidePricingText;
          } else if (!newCost.ShowRange) {
            x.lowCost = newCost.FinalCharge;
            x.highCost = newCost.FinalCharge;
            x.Amount = '$' + newCost.FinalCharge.toLocaleString("en-US");
          } else {
            x.Amount = '$' + newCost.LowCost.toLocaleString("en-US") + ' - $' + newCost.HighCost.toLocaleString("en-US");
          }
        }
      })
      this.props.dispatch(actions.UpdateProp({
        Key: 'ms_SelectedPolygons',
        Value: selectedPolygons
      }));
      setTimeout(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_UpdateTotalEstimate',
          Value: true
        }));
        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_Saving',
          Value: false
        }));
      })
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_GetCost);