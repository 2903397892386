import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {
    LookupId: state.sr_LookupId,
    SourceGrid: state.AdminLookupItems
  };
};

export class LookupDisplayOrder extends React.PureComponent {

  componentDidMount() {
    if (!this.isValid())
      return;

    this.updateGridRows();
  }

  updateGridRows = () => {
    let sourceCopy = this.props.SourceGrid;

    let idx = sourceCopy.Rows.findIndex(x => x.Id === this.props.LookupId);
    let newIdx = this.props.TriggerKey.includes('Down') ? idx + 1 : idx - 1;

    [sourceCopy.Rows[idx], sourceCopy.Rows[newIdx]] = [sourceCopy.Rows[newIdx], sourceCopy.Rows[idx]];

    this.setDisplayOrder(sourceCopy.Rows);

    this.props.dispatch(actions.UpdateProp({
      Key: '_grid5164',
      Value: sourceCopy
    }));
  }

  isValid = () => {
    let rowIdx = this.props.SourceGrid.Rows.findIndex(x => x.Id === this.props.LookupId);

    if (rowIdx === -1 || (rowIdx === 0 && !this.props.TriggerKey.includes('Down')) || (rowIdx === this.props.SourceGrid.Rows.length - 1 && this.props.TriggerKey.includes('Down')))
      return false;

    return true;
  }

  setDisplayOrder = (rows) => {
    batch(() => {
      rows.forEach((x, idx) => {
        this.props.dispatch(actions.AddSaveData({
          Id: x.Id,
          Table: 'met.LookupItem',
          Column: 'SortOrder',
          Value: (idx + 1) * 10,
          IsEventData: false,
          SaveQueue: 'dbo_PropertiesSaveData',
          InsertKey: null,
          IsBaseTable: true
        }));
      });
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(LookupDisplayOrder);