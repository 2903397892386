import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import Honeycomb from "@beehive-development/honeycomb";
import store from '../../../../../Stores/Store';

const mapStateToProps = (state, ownProps) => {
  return {
    SelectedEvent: state.ent_SelectedEvent ? state.ent_SelectedEvent : null,
    Integrations: state.ent_Integrations ? state.ent_Integrations : null
  };
};

export class MV_ArcSite extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: {
          Title: "Send budget to ArcSite",
          Message: (
            <>
              <p style={{ fontSize: "1.1rem" }}>
                Are you sure you want to send this budget to ArcSite?
              </p>
                <div className="flex-btn-bar" style={{ paddingLeft: "10px", marginTop: "10px" }}>
                    <div className="btn-container" style={{ flex: "0 1 140px" }}>
                        <div className="btn-bar-btn polygon-btn" onClick={() => this.confirmSend()}>
                            <div>
                                Send
                            </div>
                        </div>
                    </div>
                    <div className="btn-container" style={{ flex: "0 1 140px" }}>
                        <div className="btn-bar-btn polygon-btn-neg" onClick={this.cancelButton}>
                            <div>
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </>
          ),
          Buttons: [],
        },
      })
    );
  }

  confirmSend = async () => {
    const state = store.getState();
    const eventProps = state.ent_CurrentEvent.Properties;
    const jobId = eventProps.find((e) => e.Label === "ArcSiteJobId");
    if (jobId && jobId.Value === "") {
        try {
            this.props.dispatch(actions.UpdateProp({
                Key: "blu_Dialog",
                Value: null
            }));

            const integrations = this.props.Integrations;
            const budgetId = state.ent_SelectedEvent.EntityId;
            const integ = integrations.find((e) => e.IntegrationTypeId === 2096);
            const body = {
                Owner: integ.Value1,
                BudgetId: budgetId,
            };

            const honeycomb = new Honeycomb({
                requestHeaders: {
                    "href-origin": window.location.href,
                },
            });
        
            const respBody = await honeycomb.mySalesman
                .arcSite(body)
                .then((response) => response.json());

            const saveData = [];
            const baseConsumerObj = {
                Id: respBody.budgetId,
                Table: "xmv.Consumer",
                IsBaseTable: true,
                EntityTypeId: 2040,
            };

            saveData.push(helpers.saveDataItem(baseConsumerObj, "ArcSiteJobId", respBody.arcSiteJobId));

            actions.ApiRequest("Save/Save", { SaveData: saveData }, (result) => {
                this.props.dispatch(actions.UpdateProp({
                    Key: "blu_Dialog",
                    Value: {
                        Title: "Successfully Sent Budget",
                        Message: (
                        <p style={{ fontSize: "1.1rem", marginTop: "20px" }}>
                            Successfully sent budget to ArcSite.
                        </p>
                        ),
                    },
                }));
            });
        } catch (e) {
            this.props.dispatch(actions.UpdateProp({
                Key: "blu_Dialog",
                Value: {
                    Title: "Failed Sending Budget",
                    Message: (
                        <p style={{ fontSize: "1.1rem", marginTop: "20px" }}>
                            Budget failed to send.
                        </p>
                    ),
                },
            }));
        }

        this.props.dispatch(actions.UpdateProp({
            Key: "ent_CurrentEvent",
            Value: null,
        }));

        this.props.dispatch(actions.UpdateProp({
            Key: "ent_SelectedEvent",
            Value: null,
        }));
    } else {
        this.props.dispatch(actions.UpdateProp({
            Key: "blu_Dialog",
            Value: {
                Title: "Already sent",
                Message: (
                    <p style={{ fontSize: "1.1rem", marginTop: "20px" }}>
                        This budget has already been sent to ArcSite.
                    </p>
                ),
            },
        }));
    }
  };

  cancelButton = () => {
    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: null,
      })
    );
  };

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_ArcSite);