import React from 'react';
import { connect, batch } from 'react-redux';
import { Trashcan } from '../../../../Icons/Icons';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state) => {
  return {
    DrawingActive: state.ms_DrawingActive,
    ModifyActive: state.ms_ModifyActive,
    MapAction: state.ms_MySalesMapAction
  };
}

export class MySalesAddAnItem extends React.PureComponent {

  getClasses = () => {
    let result = '';

    if (this.props.selected && this.props.selected.Id === this.props.card.Id && this.props.card.Id !== undefined) {
      result += ' selected';
    } else if (this.props.card.Id && this.props.card.GobId && this.props.card.Id === this.props.card.GobId) {
      result += ' selected';
    }

    let selected = result.includes('selected');

    if (this.props.card.IconSource) {
      result += ' icon-card';
    }

    if (this.props.DrawingActive) {
      result += ' mysales-action-menu-inactive';

      // if (selected) {
      //   result += ' mysales-action-menu-active';
      // } else {
      //   result += ' mysales-action-menu-inactive';
      // }
    }

    if (this.props.ModifyActive) {
      if (/*selected || (*/this.props.card.EntityTypeId == 2059 && this.props.MapAction && this.props.MapAction.Id == 2058/*)*/) {
        result += ' mysales-action-menu-active';
      } else {
        result += ' mysales-action-menu-inactive';
      }
    }

    if (!this.props.DrawingActive && !this.props.ModifyActive) {
      result += ' mysales-action-menu-active';
    }

    return result;
  }

  getStyle = () => {
    let obj = { marginLeft: (this.props.level * 15) + 'px' };

    if (this.props.card.Style) {
      try {
        let styles = this.props.card.Style.split(',').map(x => x.trim());
        styles.forEach(x => {
          let pieces = x.split(':').map(x => x.trim());
          if (pieces.length = 2) {
            obj[pieces[0]] = pieces[1];
          }
        })
      } catch {
        console.error('error parsing card style: ' + this.props.card.Style);
      }
    }

    return obj;
  }

  onClick = (e) => {
    this.props.parentClick(this.props.card);
  }

  render() {
    return (
      <div className={"card-item " + this.getClasses()} onClick={this.onClick} style={this.getStyle()}>
        {this.props.card.IconSource &&
          <div className={"card-icon" + (this.props.card.IconClickToPan ? ' click-to-pan' : '')} onClick={(e) => { this.props.panToCard(e, this.props.card) }}>
            <img src={this.props.card.IconSource} onError={(e) => { e.target.src = 'https://bhpublic.blob.core.windows.net/imagepicker/no-image.png'; e.target.onerror = null; }}></img>
          </div>}
        {/* {props.card.Icon &&
          <div className="icon">
            {CardIcon && <CardIcon />}
          </div>} */}
        {this.props.card.Text1 !== undefined && <div className="text-1">{this.props.card.Text1}</div>}
        {this.props.card.Text2 !== undefined && <div className="text-2">{this.props.card.Text2}</div>}
        {this.props.card.Text3 !== undefined && <div className="text-3">{this.props.card.Text3}</div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(MySalesAddAnItem);