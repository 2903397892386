import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import Draw from 'ol/interaction/Draw';
import { Vector as LayerVector } from 'ol/layer'
import { Vector as SourceVector } from 'ol/source';
import { Style, Circle, Fill, Stroke } from 'ol/style';
import { WKT, GeoJSON } from 'ol/format';
import Turf from '@turf/intersect';

const mapStateToProps = (state, ownProps) => {
  return {
    PointSelectBtn: state.map_PointSelection,
    GeoKey: ownProps.PointSelectionKey,
    SaveId: state.dbo_SaveId,
    CancelId: state.dbo_CancelId
  };
}

export class PointSelection extends React.PureComponent {

  componentDidUpdate(prev) {
    let oldId = prev && prev.PointSelectBtn ? prev.PointSelectBtn.ClickId : 0;
    let curId = this.props.PointSelectBtn ? this.props.PointSelectBtn.ClickId : 0;
    if (oldId != curId) {
      this.startDrawing();
    }
    if (this.props.SaveId !== prev.SaveId || this.props.CancelId !== prev.CancelId) {
      this.clearDrawing();
    }
  }

  stopDrawing = () => {
    let map = this.props.map;
    if (!map || !map.pointInteraction)
      return;

    map.removeInteraction(map.pointInteraction);
    map.pointInteraction = null;
  }

  clearDrawing = () => {
    let map = this.props.map;
    if (!map)
      return;

    let layer = map.getLayers().getArray().find(x => x.LayerId === 'PointSelection');
    layer.getSource().clear();
    map.getLayers().getArray().forEach(layer => {
      if (!layer.getSource)
        return;

      let source = layer.getSource();
      if (source.isBeehiveSource) {
        source.refresh();
      }
    });
  }

  startDrawing = () => {
    let map = this.props.map;
    if (!map)
      return;

    if (map.pointInteraction) {
      map.removeInteraction(map.pointInteraction);
    }

    let source = this.getPointVector();
    let draw = new Draw({
      source: source,
      type: 'Point'
    });

    draw.on('drawend', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let wktFormat = new WKT();
      let geoJsonFormat = new GeoJSON();

      setTimeout(() => {
        let layer = map.getLayers().getArray().find(x => x.LayerId === 'PointSelection');
        let clone = e.feature.clone();
        layer.getSource().addFeature(clone);

        this.props.dispatch(actions.UpdateProp({
          Key: this.props.GeoKey,
          Value: {
            WKT: wktFormat.writeGeometry(clone.getGeometry().clone().transform('EPSG:3857', map.projName)),
            GeoJSON: geoJsonFormat.writeFeatureObject(clone),
            olFet: clone,
            Id: helpers.getId()
          }
        }));
      }, 0);

      this.stopDrawing();
    });

    map.addInteraction(draw);
    map.pointInteraction = draw;
  }

  getPointVector = () => {
    let layers = this.props.map.getLayers().getArray();
    let drawLayer = layers.find(x => x.LayerId === 'PointSelection');
    let vector;

    if (!drawLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,
        style: () => {
          return new Style({
            image: new Circle({
              radius: 7,
              fill: new Fill({ color: '#183eb2' }),
              stroke: new Stroke({ color: 'white', width: 2 })
            })
          });
        }
      });

      layer.setZIndex(1111);
      layer.LayerId = 'PointSelection';
      layer.Vector = vector;
      this.props.map.addLayer(layer);
    } else {
      vector = drawLayer.Vector;
    }

    return vector;
  }

  mouseDown = (e) => {
    let map = this.props.map;
    if (e.which === 3 && map && map.pointInteraction) {
      e.preventDefault();
      e.stopPropagation();
      this.stopDrawing();
    }
  }

  componentDidMount() {
    document.body.addEventListener('contextmenu', this.mouseDown)
  }

  componentWillUnmount() {
    document.body.removeEventListener('contextmenu', this.mouseDown)
  }

  render() {
    return (
      null
    )
  }
}

export default connect(mapStateToProps)(PointSelection);