import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import InfoCardEventHeader from './CardTypes/InfoCardEventHeader';
import InfoCardHeader from './CardTypes/InfoCardHeader';
import AutoCompleteCard from './CardTypes/AutoCompleteCard';
import AutoCompleteImageCard from './CardTypes/AutoCompleteImageCard';


const mapStateToProps = (state, ownProps) => {
  if (ownProps.widget) {
    //infocard as widget
    let entityProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
    let iconEntity = ownProps.widget.Parameters.find(x => x.ParameterName === 'IconEntity');
    let cardForm = ownProps.widget.Parameters.find(x => x.ParameterName === 'CardForm');
    let iconSrc = ownProps.widget.Parameters.find(x => x.ParameterName === 'IconSource');

    if (state.blu_MasterScene && state.blu_MasterScene.Label && state.blu_MasterScene.Label.includes('311')) {
      if (!entityProp.ParameterValue.includes('Event')) {
        entityProp = { ParameterValue: 'ent_Triage' };
      }
    }

    return {
      Entity: entityProp ? state[entityProp.ParameterValue] : undefined,
      IconEntity: iconEntity ? state[iconEntity.ParameterValue] : undefined,
      CardForm: cardForm ? cardForm.ParameterValue : undefined,
      IconSource: iconSrc ? iconSrc.ParameterValue : undefined
    };
  } else if (ownProps.Entity) {
    //infocard used by other widgets, passed entity as param    
    return {
      Entity: ownProps.Entity,
      CardForm: ownProps.CardForm,
      IconSource: ownProps.IconSource,
      IconEntity: undefined
    };
  } else if (ownProps.CardProps) {
    //infocard without an associated entity, passed props explicitly 
    return {
      CardProps: ownProps.CardProps
    }
  }
}

export class InfoCard extends React.PureComponent {
  state = {
    min: '0'
  }

  cards = {
    InfoCardHeader: InfoCardHeader,
    InfoCardEventHeader: InfoCardEventHeader,
    AutoCompleteCard: AutoCompleteCard,
    AutoCompleteImageCard: AutoCompleteImageCard
  }

  cardProps = () => {
    if (this.props.CardProps)
      return this.props.CardProps;

    if (!this.props.Entity || !this.props.Entity.Properties || !this.props.CardForm)
      return;

    let parentId = this.props.ParentId;
    let props = this.props.Entity.Properties.filter(x => helpers.propInForm(x, this.props.CardForm));
    if (parentId) {
      props = props.filter(x => helpers.propHasParent(x, parentId));
    }
    return props.sort((a, b) => {
      let aVal = a.FormData.find(x => x.FormName === this.props.CardForm).ControlOrder;
      let bVal = b.FormData.find(x => x.FormName === this.props.CardForm).ControlOrder;
      return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
    });
  }

  componentDidUpdate() {
    this.resetImage();
  }

  entityLabel = () => {
    if (!this.props.Entity)
      return '';

    return this.props.Entity.EntityLabel || '';
  }

  entityIcon = () => {
    let ent = this.props.IconEntity;

    if (!ent || !ent.EntityTypeId)
      ent = this.props.Entity;

    if (ent.EntityTypeId.toString().startsWith('311')) {
      return null;
    }
    return ent && this.props.IconSource ? this.props.IconSource.replace('{typeId}', ent.EntityTypeId.toString()) : '';
  }

  resetImage = (second) => {
    let containers = document.getElementsByClassName('info-card-icon');
    let imgs = document.getElementsByClassName('info-card-img');
    if (!second) {
      this.setMinWidthHeight(containers, '');
      this.setMinWidthHeight(imgs, '');
    }
    setTimeout(() => {
      this.setMinWidthHeight(containers, '0');
      this.setMinWidthHeight(imgs, '0');
      if (!second) {
        setTimeout(() => {
          this.resetImage(true);
        }, 100);
      }
    }, 0);

    return;
  }

  setMinWidthHeight = (elements, width) => {
    Array.from(elements).forEach((el) => {
      el.style.minWidth = width;
      el.style.minHeight = width;
    });
  }

  render() {
    let props = this.cardProps();
    let Card = this.cards[this.props.CardForm];
    return (
      !props ? null :
        <React.Fragment>
          <Card parent={this} preventClick={this.props.PreventClick} entProps={props} entity={this.props.Entity} cardForm={this.props.CardForm} Tabbable={this.props.Tabbable} CardId={this.props.CardId} />
        </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(InfoCard);