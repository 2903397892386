import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import { AgGridReact } from 'ag-grid-react';
import * as hub from './../../../../../Utils/PubSub';

export default class DropDownEditor extends React.PureComponent {

  state = {
    lookupItems: null,
    value: null
  }

  componentDidMount() {
    this.setState({ value: this.props.value });
    this.inputRef = React.createRef();
  }

  componentWillUnmount() {

  }

  componentDidUpdate() {
    this.inputRef.current.focus();
  }

  getLookupItems = () => {
    if (!this.state.lookupItems) {
      let items = helpers.getLookupItems(this.props.colDef.LookupSetId);
      this.setState({
        lookupItems: items
      });
      return items;
    }
    return this.state.lookupItems;
  }

  getValue() {
    return this.state.value;
  }

  isPopup() {
    return true;
  }

  onChange = (e) => {
    this.setState({ value: e.target.value }, () => {
      this.props.api.stopEditing();
    });
  }

  render() {
    return (
      <div tabIndex={1} className="ag-editor-container">
        <select
          ref={this.inputRef}
          value={this.props.value} onChange={this.onChange}>
          {this.getLookupItems().map((x, idx) => (
            <option key={idx} value={x.OverrideValue !== null ? x.OverrideValue : x.LookupItemId}>{x.Name1}</option>
          ))}
        </select>
      </div>
    );
  }
}