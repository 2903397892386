import React from 'react';
import { connect } from 'react-redux';
import Honeycomb, { Auth0DeleteUserParams } from "@beehive-development/honeycomb";

type StringKeysObject = { [key: string]: any };

const mapStateToProps = (state: StringKeysObject, ownProps: StringKeysObject) => {
    return {
        ContactData: state.ent_CurrentEvent,
        SelectedEvent: state.ent_SelectedEvent
    };
};

export class MV_DeleteContact extends React.PureComponent<ReturnType<typeof mapStateToProps>> {

    async componentDidMount() {
        await this.deleteContactUser();
    }

    async deleteContactUser() {
        // @ts-expect-error
        // TypeScript can't assert `_getToken` exists due to it being placed in the `window` object at runtime in vanilla JS.
        const jwtToken = window.isSalesmanPublic ? await window._getToken : await window._getToken();

        const contactData = this.props.ContactData;
        const properties = contactData.Properties;
        const email = properties.find((e: { Name: string }) => e.Name === 'Email').Value;

        if (this.props.SelectedEvent.EntityId > 0 && email) {
            const honeycomb = new Honeycomb({
                requestHeaders: {
                    Authorization: `Bearer ${jwtToken}`,
                    "href-origin": window.location.href,
                }
            });

            const auth0DeleteUserParams: Auth0DeleteUserParams = {
                email: email.trim(),
            };

            await honeycomb.mySalesman.auth0DeleteUser(auth0DeleteUserParams);
        }
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps)(MV_DeleteContact);