import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as hub from '../../../../Utils/PubSub';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    Metadata: state.met_EntityMetadata,
    SelectedProduct: state.Controls ? state.Controls.SelectedProduct : null,
    Scenes: state.blu_Scene,
    CatalogConfig: state.xmv_CatalogConfig,
    TabContent: state.CatalogTab
  };
};

export class MV_CatalogSwitcher extends React.PureComponent {
  //hard coding this to a swid so I can have a sproc param
  componentDidMount() {
    let body = {
      SWID: -100,
      Parameter: ""
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'xmv_CatalogConfig',
        Value: result
      }))
    });
  }

  componentDidUpdate() {
    if (!this.props.SelectedProduct || !this.props.CatalogConfig)
      return;

    let catScene = this.props.CatalogConfig.find(x => x.Key === Number(this.props.SelectedProduct));

    if (!catScene)
      return;

    let scene = this.props.Scenes.find(x => x.SceneId === catScene.SceneId);
    if (scene && (!this.props.TabContent || this.props.TabContent.Value !== scene.Name)) {

      this.props.dispatch(actions.UpdateProp({
        Key: 'CatalogTab',
        Value: {
          Value: scene.Name,
          Id: scene.SceneId
        }
      }));
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_CatalogSwitcher);

