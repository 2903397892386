import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import TimeDisplay from './Types/TimeDisplay';

const mapStateToProps = (state, ownProps) => {
  return {
    Metadata: state.met_EntityMetadata
  };
}

export class ResourceDisplay extends React.PureComponent {
  getFlexWidth = (width) => {
    return width.includes('px') ? '0 0 ' + width : width;
  }

  getLookupText = (lookupSet, lookupId) => {
    let text = '';
    if (lookupSet === 'UserList') {
      let set = this.props.Metadata.UserList;
      let item = set ? set.find(x => x.UserId === lookupId || (x.UserId + '') === lookupId) : null;
      text = item ? item.Name : '';
    } else {
      let set = this.props.Metadata.LookupItems.find(x => x.LookupSet === lookupSet);

      let item = set ? set.LookupItems.find(x => x.LookupItemId === lookupId || (x.LookupItemId + '') === lookupId) : null;
      text = item ? item.Name1 : '';
    }

    return text;
  }

  render() {
    return (
      <div className="resource-items">
        {(this.props.Resource.Rows.map((row, rowIdx) => (
          <div key={rowIdx} className={"resource-item"} onClick={() => { this.props.setEntryItem(this.props.Resource.Label, row, rowIdx) }} >
            {<TimeDisplay row={row} getLookupText={this.getLookupText} PrimaryField={this.props.PrimaryField} />}
          </div>
        )))}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ResourceDisplay);