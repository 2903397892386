import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEvent: state.ent_CurrentEvent,
    EventSave: state.btn_EventSave,
    CurrentUser: state.CurrentUser,
    SaveId: state.dbo_SaveId
    // Credit and Debit grids SWIDs hardcoded in batch, passed in through Parameter[creditGrid, debitGrid]
  };
};

export class JournalReadOnlyCheck extends React.PureComponent {
  busy = false;

  componentDidMount() {
    if (!this.props.CurrentEvent || this.busy)
      return;

    let statusProp = this.props.CurrentEvent.Properties.find(x => x.Name === 'StatusLId');
    let status = helpers.propDisplayValue(statusProp, this.props.CurrentEvent);

    let gridsRO = status === 'Complete' || status === 'Rejected';

    if (statusProp && (helpers.propDisplayValue(statusProp, this.props.CurrentEvent) === 'Complete' || helpers.propDisplayValue(statusProp, this.props.CurrentEvent) === 'Rejected')) {
      let evt = cloneDeep(this.props.CurrentEvent);

      if (evt.Properties.find(x => !x.IsRO)) {
        evt.Properties.forEach(x => {
          x.IsRO = true;
        });

        this.props.dispatch(actions.UpdateProp({
          Key: 'ent_CurrentEvent',
          Value: evt
        }));
      }
    }

    let creditGridKey = this.props.ParameterKeys[0];
    let debitGridKey = this.props.ParameterKeys[1];

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: creditGridKey + 'RO',
        Value: gridsRO
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: debitGridKey + 'RO',
        Value: gridsRO
      }));
    });

    // setTimeout(() => {
    //   let creditRefreshVal;
    //   let debitRefreshVal;

    //   // try {
    //   //   creditRefreshVal = cloneDeep(this.props.Parameter[0]);
    //   //   debitRefreshVal = cloneDeep(this.props.Parameter[1]);
    //   // } catch {
    //   creditRefreshVal = { ...this.props.Parameter[0] };
    //   debitRefreshVal = { ...this.props.Parameter[1] };
    //   // }

    //   this.props.dispatch(actions.UpdateProp({
    //     Key: creditGridKey,
    //     Value: creditRefreshVal
    //   }));

    //   this.props.dispatch(actions.UpdateProp({
    //     Key: debitGridKey,
    //     Value: debitRefreshVal
    //   }));
    // }, 0);

    this.busy = true;
    setTimeout(() => { this.busy = false; }, 200);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(JournalReadOnlyCheck);
