import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let triggerKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'TriggerKey');

  let param = null;
  if (parameter) {
    param = helpers.getListParameter(state, parameter.ParameterValue);
  }

  return {
    Parameter: param,
    ParameterKeys: parameter ? parameter.ParameterValue.split(',').map(x => x.trim()) : null,
    TriggerKey: triggerKey ? triggerKey.ParameterValue : null,
    Trigger: triggerKey ? state[triggerKey.ParameterValue] : null
  };
};

export class GetList extends React.PureComponent {

  componentDidUpdate() {
    if (this.props.Trigger && this.props.Script) {
      this.getList();
      this.resetTrigger();
    }
  }

  getList = () => {
    let body = {
      SWID: this.props.widget.SceneWidgetId,
      Parameter: JSON.parse(this.props.Parameter)
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      console.log({ getListResult: result });
    });
  }

  resetTrigger = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.TriggerKey,
      Value: false
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(GetList);