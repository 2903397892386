
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { TileArcGISRest } from 'ol/source';

const tileArcGISRestLayer = (path, params) => {
  console.log(params);
  return new TileLayer({
    source: new TileArcGISRest({
      url: path,
      params: params
    })
  });
}

export default {
  tileArcGISRestLayer: tileArcGISRestLayer
}
