import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as icons from '../../../Icons/Icons';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSource');
  let type = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonType');
  let confirm = ownProps.widget.Parameters.find(x => x.ParameterName === 'ConfirmationText');
  let activeKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ActiveOnKeyChange');
  let hideOnUnsaved = ownProps.widget.Parameters.find(x => x.ParameterName === 'HideOnUnsavedEntity');
  let evtPrintBtn = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsEventPrintButton');
  let keybind = ownProps.widget.Parameters.find(x => x.ParameterName === 'Keybind');
  let enableCondition = ownProps.widget.Parameters.find(x => x.ParameterName === 'EnableCondition');
  let activateOnLoad = ownProps.widget.Parameters.find(x => x.ParameterName === 'ActivateOnLoad');
  let scriptRunner = ownProps.widget.Parameters.find(x => x.ParameterName === 'ScriptRunner');
  let param = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  let sproc = ownProps.widget.Parameters.find(x => x.ParameterName === 'Sproc');
  let enterKeyClick = ownProps.widget.Parameters.find(x => x.ParameterName === 'EnterKeyClick');

  let enable = true;
  if (enableCondition && enableCondition.ParameterValue) {
    try {
      enable = eval(enableCondition.ParameterValue);
    } catch (e) {
      console.error('error in EnableCondition: ' + enableCondition.ParameterValue)
    }
  }

  let hidden = false;
  if (hideOnUnsaved && state[hideOnUnsaved.ParameterValue] && state[hideOnUnsaved.ParameterValue].IsNew) {
    hidden = true;
  }

  if (evtPrintBtn && helpers.stringToBool(evtPrintBtn.ParameterValue) && (!state.ent_CurrentEvent || !state.ent_CurrentEvent.PrintReport)) {
    hidden = true;
  }

  let button = { Enabled: true };
  if (source) {
    button = state[source.ParameterValue] || {
      ClickId: 0,
      Enabled: true,
      Value: ''
    };
  }

  return {
    Button: button,
    ButtonSource: source ? source.ParameterValue : '',
    ButtonType: type ? type.ParameterValue : 'Button',
    ConfirmationText: confirm ? confirm.ParameterValue : null,
    ActiveKeyValue: activeKey ? state[activeKey.ParameterValue] : null,
    IsHidden: hidden,
    Keybind: keybind ? state[keybind.ParameterValue] : null,
    SWID: ownProps.widget.SceneWidgetId,
    EnableCondition: enable,
    ActivateOnLoad: activateOnLoad ? helpers.stringToBool(activateOnLoad.ParameterValue) : false,
    ScriptRunner: scriptRunner ? scriptRunner.ParameterValue : null,
    Parameter: param ? helpers.getListParameter(state, param.ParameterValue) : undefined,
    IsSprocButton: param && sproc && param.ParameterValue && sproc.ParameterValue,
    EnterKeyClick: enterKeyClick ? helpers.stringToBool(enterKeyClick.ParameterValue) : false
  };
};

let keyBind = {};

export class Button extends React.PureComponent {
  state = {
    params: helpers.paramsToObject(this.props.widget),
    waiting: false,
    confirm: false
  }

  componentDidMount() {
    if (this.props.ActivateOnLoad) {
      this.onClick();
    }
    if (this.props.EnterKeyClick) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (this.props.EnterKeyClick) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onClick();
    }
  }

  componentDidUpdate(prev) {
    if (this.props.ActiveKeyValue && prev && prev.ActiveKeyValue) {
      let activeKey = this.props.ActiveKeyValue;
      let prevKey = prev.ActiveKeyValue;
      if (this.props.ButtonType === 'Tab' && helpers.entId(activeKey) !== helpers.entId(prevKey))
        this.tabClick();
    }

    if (this.props.Keybind && this.props.Button) {
      if (!keyBind[this.props.SWID]) {
        keyBind[this.props.SWID] = true;
        this.onClick();
      }
    }

    if (!this.props.Keybind && keyBind[this.props.SWID]) {
      keyBind[this.props.SWID] = false;
    }
  }

  onClick = () => {
    if (!this.props.Button || !this.props.Button.Enabled || !this.props.EnableCondition || this.state.waiting)
      return;

    if (this.state.params.FireDelay) {
      setTimeout(() => {
        this.clickByType();
      }, Number(this.state.params.FireDelay));
    } else {
      this.clickByType();
    }
  }

  clickByType = () => {
    if (this.props.ButtonType === 'Button') {
      this.buttonClick();
    }

    if (this.props.ButtonType === 'Tab') {
      this.tabClick();
    }
  }

  buttonClick = () => {
    if (this.props.ConfirmationText === null) {
      this.doClickAction();
    } else {
      this.setState({ confirm: true });
    }
  }

  doClickAction = () => {
    let btn = this.props.Button;
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        Label: this.state.params.Label,
        ClickId: btn.ClickId + 1,
        Enabled: btn.Enabled
      }
    }));
    if (this.state.confirm) {
      this.setState({ confirm: false });
    }
    if (this.state.params.SetOnClickKey && this.state.params.SetOnClickValue) {
      this.setSceneOnClick();
    }
    if (this.props.ScriptRunner) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.ScriptRunner,
        Value: true
      }));
    }
    if (this.props.IsSprocButton) {
      this.sprocButtonClick();
    }
  }

  sprocButtonClick = () => {
    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify(this.props.Parameter)
    }

    this.setState({ waiting: true });

    actions.ApiRequest('List/GetList', body, () => {
      this.setState({ waiting: false });
    });
  }

  setSceneOnClick = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.state.params.SetOnClickKey,
      Value: {
        Value: this.state.params.SetOnClickValue,
        Enabled: true
      }
    }));
  }

  cancelConfirm = () => {
    this.setState({ confirm: false });
  }

  tabClick = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        Label: this.state.params.Label,
        Value: this.state.params.TabValue,
        Enabled: true
      }
    }));

    if (this.state.params.SetOnClickKey && this.state.params.SetOnClickValue) {
      this.setSceneOnClick();
    }
  }

  classNames = (label, btn) => {
    let classes = [];
    classes.push(this.props.ButtonType.toLowerCase());

    if (label && label.split(' ').length <= 1)
      classes.push(label);

    if (this.props.ButtonType === 'Tab' && btn.Value === this.state.params.TabValue) {
      classes.push('active');
    }
    if (!btn.Enabled || !this.props.EnableCondition || this.state.waiting) {
      classes.push('disabled');
    }
    if (this.props.IsSprocButton) {
      classes.push('sproc-btn');
    }

    let parameterClasses = this.state.params.ButtonClass;
    classes.push(parameterClasses ? parameterClasses : 'default');

    return classes.join(' ');
  }

  render() {
    let btn = this.props.Button;
    let label = this.state.params.Label;
    let isLinkButton = (this.state.params.ClassName || '').includes('link-button');
    let Icon = this.state.params.Icon ? icons[this.state.params.Icon] : null;
    return (
      this.props.IsHidden ? null : <div className={"button-container container-type-" + this.props.ButtonType + (this.state.confirm ? ' confirm-mode' : '')}>
        {btn && !this.state.confirm &&
          <div className={this.classNames(label, btn)} onClick={() => { if (!isLinkButton) { this.onClick() } }}>
            {Icon && <div className="icon-btn-container">
              <Icon />
              {label && <div>{label}</div>}
            </div>}
            {!Icon && <div onClick={() => { if (isLinkButton) { this.onClick() } }}>{label}</div>}
          </div>
        }
        {btn && this.state.confirm &&
          <div className="button-confirmation">
            <div className="confirm-text">{this.props.ConfirmationText}</div>
            <div className="confirm-buttons">
              <div className={this.classNames(label, btn)} onClick={this.doClickAction}>
                <div>{label}</div>
              </div>
              <div className="confirm-cancel-btn button default" onClick={this.cancelConfirm}>
                <div>Cancel</div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(Button);