import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEvent: state.ent_CurrentEvent,
    SelectedEvent: state.ent_SelectedEvent
  };
};

export class PaymentSaved extends React.PureComponent {

  componentDidMount() {
    if (!this.props.SelectedEvent || this.props.SelectedEvent.EntityTypeId !== 2020109)
      return;

    console.log('after save payment');

    let evtSelected = cloneDeep(this.props.SelectedEvent);

    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_SelectedEvent',
        Value: {
          ...evtSelected,
          EntityTypeId: 2020200
        }
      }));
    }, 0);
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(PaymentSaved);