import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class PrintTemplate extends React.PureComponent {

  componentDidMount() {
    let tmpl = document.querySelector('.template-widget html');
    if (!tmpl) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene2',
        Value: {
          Value: 'PrintModal'
        }
      }));
    } else {
      setTimeout(() => {
        document.body.classList.add('do-not-print');

        setTimeout(() => {
          let tmpl = document.querySelector('.template-widget html');

          let scale = window.innerHeight / tmpl.scrollHeight;
          tmpl.style.transform = 'scale(' + scale + ')';

          setTimeout(() => {
            window.print();
            tmpl.style.transform = 'unset';
            document.body.classList.remove('do-not-print');
            this.props.dispatch(actions.UpdateProp({
              Key: 'blu_ModalScene2',
              Value: null
            }));
          }, 0);
        }, 0);
      });
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(PrintTemplate);