import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSource');
  let saveData = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveData');

  return {
    Button: source ? state[source.ParameterValue] : undefined,
    ButtonSource: source ? source.ParameterValue : undefined,
    SaveData: saveData ? state[saveData.ParameterValue] : undefined,
    SaveDataKey: saveData ? saveData.ParameterValue : undefined,
    TimeTrackingRefresh: state.ttr_TimeTrackingRefresh,
    SaveId: state.dbo_SaveId
  };
};

export class AdminUnsubmit extends React.PureComponent {
  waiting = false;

  constructor(props) {
    super(props);
    this.updateButton(0, false);
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.Button)
      return;

    this.checkEnabled(prevProps);
    this.handleClick(prevProps);
  }

  checkEnabled = () => {
    let enabled = false;

    if (this.props.SaveData && this.props.SaveData.find(x => x.Value === true)) {
      enabled = true;
    }

    if (this.props.Button.Enabled !== enabled) {
      this.updateButton(this.props.Button.ClickId, enabled);
    }
  }

  handleClick = (prevProps) => {
    if (!prevProps.Button)
      return;

    if (prevProps.Button.ClickId !== this.props.Button.ClickId) {
      this.doUnapprove();
    }
  }

  doUnapprove = () => {
    if (this.waiting)
      return;

    let saveData = [];

    this.props.SaveData.filter(x => x.Value === true).forEach((x) => {
      let ids = x.Id.split('-').map(x => Number(x));
      let userId = ids[0];
      let payperiodId = ids[1];
      let userPayperiodId = ids[2];

      saveData = [
        ...saveData,
        {
          Id: userPayperiodId,
          InsertKey: null,
          Table: 'ttr.UserPayperiod',
          Column: 'UserId',
          Value: userId,
          IsBaseTable: true,
          EntityTypeId: 100
        }, {
          Id: userPayperiodId,
          InsertKey: null,
          Table: 'ttr.UserPayperiod',
          Column: 'PayperiodId',
          Value: payperiodId,
          IsBaseTable: true,
          EntityTypeId: 100
        }, {
          Id: userPayperiodId,
          InsertKey: null,
          Table: 'ttr.UserPayperiod',
          Column: 'PayperiodStatus',
          Value: 1,
          IsBaseTable: true,
          EntityTypeId: 100
        }, {
          Id: userPayperiodId,
          InsertKey: null,
          Table: 'ttr.UserPayperiod',
          Column: 'IsDeleted',
          Value: false,
          IsBaseTable: true,
          EntityTypeId: 100
        }];
    });

    this.waiting = true;

    actions.ApiRequest('Save/Save', { SaveData: saveData }, () => {
      this.waiting = false;
      this.props.dispatch(actions.ClearSaveData(this.props.SaveDataKey));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ttr_TimeTrackingRefresh',
        Value: (this.props.TimeTrackingRefresh || 0) + 1
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });
  }

  updateButton = (clickId, isEnabled) => {
    if (!this.props.ButtonSource)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        ClickId: clickId,
        Enabled: isEnabled
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(AdminUnsubmit);