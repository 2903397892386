
import Image from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS'

const wmsLayer = (path, params) => {
  console.log(params);
  var source = new ImageWMS({
    url: path,
    params: {
      ...params,
      'TILED': true
    },
    projection: 'EPSG:3857',
    serverType: 'geoserver',
  });

  return new Image({
    visible: true,
    source: source,
    opacity: 0.5
  });
}

export default {
  wmsLayer: wmsLayer
}
