import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let assetKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'NewAssetKey');
  let eventKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'NewEventKey');
  let selectedEventKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'SelectedEventKey');
  let saveQueue = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveQueue');
  let clearSaveQueue = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClearSaveQueue');
  let sceneKeyOverride = ownProps.widget.Parameters.find(x => x.ParameterName === 'SceneKeyOverride');

  return {
    NewAsset: assetKey ? state[assetKey.ParameterValue + 'New'] : undefined,
    NewEvent: eventKey ? state[eventKey.ParameterValue] : undefined,
    Metadata: state.met_EntityMetadata,
    NewAssetKey: assetKey ? assetKey.ParameterValue : 'ent_Selected',
    NewEventKey: eventKey ? eventKey.ParameterValue : undefined,
    NewEventParent: state.ent_NewEventParent,
    SaveQueue: saveQueue ? saveQueue.ParameterValue : undefined,
    SaveQueueContents: saveQueue ? state[saveQueue.ParameterValue] : undefined,
    CurrentUser: state.CurrentUser,
    ClearSaveQueue: clearSaveQueue ? helpers.stringToBool(clearSaveQueue.ParameterValue) : false,
    SelectedEventKey: selectedEventKey ? selectedEventKey.ParameterValue : null,
    SceneKeyOverride: sceneKeyOverride ? sceneKeyOverride.ParameterValue : null,
  };
};

export class New extends React.PureComponent {
  busy = false;

  componentDidUpdate(prevProps) {
    if (this.busy) {
      return;
    }

    if (this.props.NewAsset) {
      let prevAsset = prevProps ? prevProps.NewAsset : null;
      if ((!prevAsset && this.props.NewAsset) || (this.props.NewAsset && (!prevAsset || this.props.NewAsset.EntityTypeId !== prevProps.NewAsset.EntityTypeId))) {
        this.createNewEntity(this.props.NewAsset.EntityTypeId, 'Asset');
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.NewAssetKey + 'New',
          Value: undefined
        }));
        this.busy = true;
        setTimeout(() => { this.busy = false; }, 100);
      }
    }

    if (this.props.NewEvent) {
      let prevEvent = prevProps ? prevProps.NewEvent : null;
      if ((!prevEvent && this.props.NewEvent) || (this.props.NewEvent && (!prevEvent || this.props.NewEvent.EntityTypeId !== prevProps.NewEvent.EntityTypeId))) {
        this.createNewEntity(this.props.NewEvent.EntityTypeId, 'Event');
        // this.props.dispatch(actions.UpdateProp({
        //   Key: 'blu_ModalScene',
        //   Value: { Value: window.IsMobile ? 'MobileEventDialog' : 'EventDialog', Enabled: true }
        // }));
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.NewEventKey,
          Value: undefined
        }));
        this.busy = true;
        setTimeout(() => { this.busy = false; }, 100);
      }
    }
  }

  createNewEntity = (typeId, entityType) => {
    if (!this.props.Metadata || !this.props.Metadata.EntityTypes)
      return;

    let insertKey = helpers.getInsertKey();
    let type = this.props.Metadata.EntityTypes.find(x => x.TypeId === typeId);

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_NewEventKey',
        Value: insertKey.toString()
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_NewAssetKey',
        Value: insertKey.toString()
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_NewEventType',
        Value: typeId
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'sr_NewEvent',
        Value: true
      }));

      if (this.props.ClearSaveQueue && this.props.SaveQueue) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.SaveQueue,
          Value: []
        }));
      }

      let tableKeyMap = type.Properties.reduce((prev, next) => {
        if (next.SaveData.Table && !prev[next.SaveData.Table]) {
          let tableKey = next.IsBaseTable ? insertKey : helpers.getInsertKey();
          prev[next.SaveData.Table] = tableKey
          prev[next.SaveData.Table.split('.')[1]] = tableKey;
        }
        return prev;
      }, {});

      let distinctProps = type.Properties.filter(helpers.autoInsertProps).reduce((prev, next) => {
        if (!prev.hasBeenAdded[next.Name] && (!next.SaveData || !(next.SaveData.Table || '').includes('rel.'))) {
          prev.hasBeenAdded[next.SaveData.Table + '_' + next.Name] = 1;
          prev.props.push(next);
        }
        return prev;
      }, { props: [], hasBeenAdded: {} }).props;

      let defaultUser = this.assignDefaultUser();
      let assignedToProp = type.Properties.find(obj => {
        return obj.Label === 'Assigned To' || obj.Label === 'Entered By' || obj.Label === 'Received By';
      });

      if (assignedToProp && defaultUser) {
        distinctProps = distinctProps.filter(x => x.Label !== 'Assigned To' && x.Label !== 'Entered By' && x.Label !== 'Received By');
        assignedToProp.DefaultValue = defaultUser;
        assignedToProp.DoInitialQuery = true;
        assignedToProp.SkipInitialSaveData = true;
        distinctProps.push(assignedToProp);
      }

      distinctProps.forEach(prop => {
        let typeDefault = prop.DefaultValue === 'ent_EntityTypeId' ? typeId : null;

        let isForeignUserProp = type.BaseTable !== 'usr.Users' && prop.SaveData.Table === 'usr.Users';

        if (prop.Name !== 'Geometry' && !prop.SkipInitialSaveData && !isForeignUserProp) {
          this.props.dispatch(actions.AddSaveData({
            Id: null,
            InsertKey: prop.SaveData.Table ? tableKeyMap[prop.SaveData.Table] : insertKey.toString(),
            Table: prop.SaveData.Table,
            Column: prop.SaveData.Column,
            Value: typeDefault || helpers.formatDefaultValue(prop, this.props.CurrentUser, tableKeyMap) || '',
            GobAutofill: prop.GobAutofill,
            IsEventData: entityType === 'Event',
            IsBaseTable: prop.IsBaseTable,
            AutoGenerated: true,
            NewEntitySaveData: true,
            SaveQueue: this.props.SaveQueue
          }));
        }
      });

      if (this.props.NewEventParent) {
        this.props.dispatch(actions.AddSaveData({
          Id: null,
          InsertKey: tableKeyMap[this.props.NewEventParent.Table],
          Table: this.props.NewEventParent.Table,
          Column: 'ParentId',
          Value: this.props.NewEventParent.Value,
          IsEventData: true,
          IsBaseTable: true,
          AutoGenerated: true,
          NewEntitySaveData: true,
          SaveQueue: this.props.SaveQueue
        }));

        this.props.dispatch(actions.UpdateProp({
          Key: 'ent_NewEventParent',
          Value: null
        }));
      }

      if (entityType === 'Asset') {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.NewAssetKey,
          Value: { ...this.props.NewAsset, EntityId: insertKey, IsNew: true, InsertKeys: tableKeyMap }
        }));
      }

      if (entityType === 'Event') {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.SelectedEventKey || 'ent_SelectedEvent',
          Value: { ...this.props.NewEvent, EntityId: insertKey, IsNew: true, InsertKeys: tableKeyMap, SceneKeyOverride: this.props.SceneKeyOverride }
        }));
      }
    });
  }

  assignDefaultUser = () => {
    let currentUser = this.props.Metadata.UserList.find(obj => {
      return obj.UserId === this.props.CurrentUser.Id
    });
    if (currentUser && currentUser.IsDefault === true) {
      return currentUser.Name;
    }
  }
 
  render() {
    return null;
  }
}

export default connect(mapStateToProps)(New);