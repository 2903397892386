import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state.ent_Current,
    Selected: state.ent_Selected
  };
};

export class UB_Title_NewFinal extends React.PureComponent {

  componentDidMount() {
    console.log('create a new final');
    console.log(this.props.Selected);

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_NewEvent',
        Value: {
          ShellOnly: true,
          EntityTypeId: 2020002
        }
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'SelectedTimeSlot',
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_EventSaveData',
        Value: []
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_Title_NewFinal);