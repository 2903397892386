import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

export class UB_Admin_GroupClear extends React.PureComponent {

  componentDidMount() {
    this.props.dispatch(actions.UpdateProp({
        Key: 'ent_SelectedPermissionGroup',
        Value: undefined
    }));
    this.props.dispatch(actions.UpdateProp({
        Key: 'ent_SelectedPermissionEntity',
        Value: undefined
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(UB_Admin_GroupClear);