import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from './../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    State: state,
    ApplicationId: state.blu_ApplicationId,
    BeforeSaveId: state.dbo_BeforeSaveId,
    CancelId: state.dbo_CancelId,
    RefreshProps: state.ent_RefreshProps,
    ModalScene: state.blu_ModalScene
  };
};

export class Workflow extends React.PureComponent {
  //-------Trigger Types-------//
  //1: Entity Property 
  //   Text1 = Entity Key
  //   Text2 = Property Name
  //   Text3 = Value  
  //2: Button Click
  //   Text1 = Button Source
  //3: Scene Load
  //   Text1: Scene Key
  //   Text2: Scene Value
  //4: Before Save

  requestMade = false;

  componentDidMount() {
    if (!this.requestMade && this.props.ApplicationId) {
      this.requestWorkflows();
    }
  }

  componentDidUpdate(prev) {
    if (this.propsRefreshed(prev)) {
      this.resetChangeTriggers();
    }
    if (this.props.CancelId !== prev.CancelId) {
      this.resetChangeTriggers();
    }
    if (!this.props.ModalScene && prev.ModalScene) {
      this.resetChangeTriggers();
    }

    if (this.props.State.wkf_Workflows) {
      this.processWorkflows(prev);
    } else if (!this.requestMade && this.props.ApplicationId) {
      this.requestWorkflows();
    }
  }

  propsRefreshed = (prev) => {
    let hasRefreshed = false;
    let curVal = this.props.RefreshProps;
    let oldVal = prev.RefreshProps;

    try {
      if (curVal && oldVal && JSON.stringify(curVal) !== JSON.stringify(oldVal)) {
        hasRefreshed = true;
      }
    } catch (e) { }

    return hasRefreshed;
  }

  resetChangeTriggers = () => {
    let workflows = this.props.State.wkf_Workflows || [];
    workflows.forEach(wf => {
      wf.Triggers.forEach(trigger => {
        if (trigger.TypeId === 1 && trigger.Text3 === 'CHANGED') {
          trigger.OldVal = null;
        }
      });
    });
  }

  requestWorkflows = () => {
    this.requestMade = true;

    let body = {
      ApplicationId: this.props.ApplicationId
    }

    actions.ApiRequest('Editor/GetWorkflows', body, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'wkf_Workflows',
        Value: result
      }));
    });
  }

  processWorkflows = (prev) => {
    let workflows = this.props.State.wkf_Workflows || [];
    workflows.forEach(wf => {
      this.checkWorkflow(wf, prev);
      let formula = helpers.getFormula(wf.Triggers);

      wf.Triggers.forEach(trigger => {
        formula = formula.replace(trigger.ItemId, trigger.Value);
      });

      try {
        let prevExecute = wf.ShouldExecute || false;
        wf.ShouldExecute = eval(formula);
        if (wf.ShouldExecute && !prevExecute) {
          console.log('WORKFLOW FIRE!');
          console.log(wf.Actions);
          this.executeActions(wf.Actions);
        }
      } catch (e) {
        console.log('eval crashed');
        console.error(e);
      }
    });
  }

  checkWorkflow = (wf, prev) => {
    wf.Triggers.forEach(trigger => {
      trigger.Value = this.getTriggerValue(trigger, prev);
    });
  }

  getTriggerValue = (trigger, prev) => {
    if (trigger.TypeId === 1)
      return this.triggerCheckType1(trigger);
    if (trigger.TypeId === 2)
      return this.triggerCheckType2(trigger);
    if (trigger.TypeId === 3)
      return this.triggerCheckType3(trigger);
    if (trigger.TypeId === 4)
      return this.triggerCheckType4(prev);
  }

  executeActions = (actions) => {
    actions.sort((a, b) => {
      let aVal = a.OrderId;
      let bVal = b.OrderId;
      return aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
    });

    actions.forEach(action => {
      console.log({ doingaction: action });
      this.doAction(action);
    });
  }

  doAction = (action) => {
    if (action.TypeId === 1)
      this.doAction1(action);
    if (action.TypeId === 2)
      this.doAction2(action);
    if (action.TypeId === 3)
      this.doAction3(action);
    if (action.TypeId === 4)
      this.doAction4(action);
    if (action.TypeId === 5)
      this.doAction5(action);
  }

  triggerCheckType1 = (trigger) => {
    let state = this.props.State;
    let result = false;
    if (state[trigger.Text1] && state[trigger.Text1].Properties && (!state[trigger.Text1].IsShell || state[trigger.Text1].IsNew)) {
      let prop = state[trigger.Text1].Properties.find(x => x.Name === trigger.Text2);
      if (prop) {
        try {
          let evalStr = '';
          let nullVals = ['\'\'', 'null', 'undefined'];

          if (trigger.Text3 === 'CHANGED') {
            let oldVal = trigger.OldVal;
            if (oldVal !== prop.Value) {
              console.log({ oldVal: oldVal });
              console.log({ pval: prop.Value });
              result = true;
            }
            trigger.OldVal = prop.Value;
          } else {
            if (prop.ControlType === 'DropDown' || prop.ControlType === 'DropDownMobile') {
              nullVals.push('0');
              if (trigger.Text3.includes('[null]') && trigger.Text3.includes('===')) {
                evalStr = `[${nullVals}].includes(${prop.Value})`;
              }

              if (trigger.Text3.includes('[null]') && trigger.Text3.includes('!==')) {
                evalStr = `![${nullVals}].includes(${prop.Value})`;
              }
            } else {
              trigger.Text3 = trigger.Text3.replace('[null]', '\'\'');
              evalStr = `"${prop.Value}" ${trigger.Text3}`;
            }
            result = eval(evalStr);
          }
        } catch {
          return result;
        }
      }
    }

    return result;
  }

  triggerCheckType2 = (trigger) => {
    let state = this.props.State;
    let result = false;
    if (state[trigger.Text1]) {
      let clickId = state[trigger.Text1].ClickId;
      if ((trigger.ClickId || 0) !== clickId) {
        result = true;
      }
      trigger.ClickId = clickId;
    }

    return result;
  }

  triggerCheckType3 = (trigger) => {
    let state = this.props.State;
    let result = false;
    if (state[trigger.Text1] && state[trigger.Text1].Value) {
      result = state[trigger.Text1].Value === trigger.Text2;
    }

    if ((trigger.Text2 || '').toLowerCase() === 'null' && state[trigger.Text1] === null) {
      result = true;
    }

    return result;
  }

  triggerCheckType4 = (prev) => {
    return prev && this.props.BeforeSaveId !== prev.BeforeSaveId;
  }

  doAction1 = (action) => {
    let btn = this.props.State[action.Text1];
    let enable = action.Text2 === 'true' ? true : false;
    if (btn && btn.Enabled !== enable) {
      let newVal = cloneDeep(btn);
      newVal.Enabled = enable;
      this.props.dispatch(actions.UpdateProp({
        Key: action.Text1,
        Value: newVal
      }));
    }
  }

  doAction2 = (action) => {
    let scene = this.props.State[action.Text1];
    if (scene && scene.Value && scene.Value !== action.Text2) {
      let newVal = cloneDeep(scene);
      newVal.Value = action.Text2;
      if (action.Text1 === 'blu_MasterScene') {
        let sceneItem = this.props.State.blu_MasterSceneList.find(x => x.Value === action.Text2);
        if (sceneItem) {
          newVal.Id = sceneItem.Id;
        }
      }
      this.props.dispatch(actions.UpdateProp({
        Key: action.Text1,
        Value: newVal
      }));
    }
  }

  doAction3 = (action) => {
    //apicall
  }

  doAction4 = (action) => {
    let state = this.props.State;
    let source = state[action.Text1];
    let dest = state[action.Text2];
    let _helpers = helpers;

    if (action.Text3) {
      let result = eval(`(() => {${action.Text3}})()`);
      this.props.dispatch(actions.UpdateProp({
        Key: action.Text2,
        Value: cloneDeep(result)
      }));
    } else {
      this.props.dispatch(actions.UpdateProp({
        Key: action.Text2,
        Value: cloneDeep(state[action.Text1])
      }));
    }
  }

  doAction5 = (action) => {
    let state = this.props.State;
    let source = state[action.Text1];
    let _helpers = helpers;
    let _dispatch = this.props.dispatch;
    let _actions = actions;

    let result = eval(`(() => {${action.Text2}})()`);
    console.log({ actionres: result });
    if (result === '!CANCEL')
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: action.Text1,
      Value: cloneDeep(result)
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Workflow);