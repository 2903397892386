import StyleEngine from '../Styles/StyleEngine';

let _library = null;

export const ApplyStyle = (feature) => {
  if (!_library) {
    _library = StyleEngine.getStyleLibrary();
  }
  let styles = _library[feature.styleId + (feature.StyleIndex ? ('.' + feature.StyleIndex) : '')];
  if (!styles)
    return;

  if (feature.replacement) {
    let engString = styles.StyleString;

    Object.keys(feature.replacement).forEach(key => {
      var replace = '@' + key;
      var reg = new RegExp(replace, "g");
      engString = engString.replace(reg, feature.replacement[key]);
    });

    let dynamicStyle = StyleEngine.buildStyleLibrary([{
      StyleId: feature.styleId,
      StyleJSON: engString,
      IsEnabled: true
    }], true);

    dynamicStyle[feature.styleId].forEach(style => {
      let t = style.getText();
      if (t) {
        t.setText(feature.label);
      }
    });

    return dynamicStyle[feature.styleId];
  } else {
    styles.forEach(style => {
      let t = style.getText();
      if (t) {
        t.setText(feature.label);
      }
    });

    styles.bhSetStyle(feature);

    return _library[feature.styleId + (feature.StyleIndex ? ('.' + feature.StyleIndex) : '')];
  }
}

export const SelectedStyle = (feature) => {
  if (!_library) {
    _library = StyleEngine.getStyleLibrary();
  }

  if (feature.geometry) {
    let styleName = feature.geometry.split(' ')[0].split('(')[0] + '_Selected';
    return Object.keys(_library).map(x => _library[x]).find(x => x.Name === styleName);
  }
}

export default {
  ApplyStyle: ApplyStyle,
  SelectedStyle: SelectedStyle
}