import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import DocumentItem from './DocumentItem';
import NewDocument from './NewDocument';
import SingleFile from './SingleFile';

const mapStateToProps = (state, ownProps) => {
  let entityProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let docsProp = ownProps.widget.Parameters.find(x => x.ParameterName === 'DocumentsKey');
  let singleFile = ownProps.widget.Parameters.find(x => x.ParameterName === 'SingleFile');
  let engSwid = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveEngineSwid');
  let cancelSrc = ownProps.widget.Parameters.find(x => x.ParameterName === 'CancelButtonSource');
  let saveQueue = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveQueue');
  let waitForEntitySave = ownProps.widget.Parameters.find(x => x.ParameterName === 'WaitForEntitySave');
  let newText = ownProps.widget.Parameters.find(x => x.ParameterName === 'NewText');
  let className = ownProps.widget.Parameters.find(x => x.ParameterName === 'ClassName');

  return {
    CurrentEntity: entityProp ? state[entityProp.ParameterValue] : undefined,
    Documents: docsProp ? state[docsProp.ParameterValue] : undefined,
    DocumentsKey: docsProp ? docsProp.ParameterValue : '',
    SingleFileMode: singleFile ? helpers.stringToBool(singleFile.ParameterValue) : false,
    SaveEngineSwid: engSwid ? engSwid.ParameterValue : null,
    CancelSource: cancelSrc ? cancelSrc.ParameterValue : null,
    SWID: ownProps.widget.SceneWidgetId,
    Preview: state['preview' + ownProps.widget.SceneWidgetId],
    PreviewKey: 'preview' + ownProps.widget.SceneWidgetId,
    SaveQueue: saveQueue ? saveQueue.ParameterValue : undefined,
    WaitForEntitySave: waitForEntitySave ? helpers.stringToBool(waitForEntitySave.ParameterValue) : false,
    BlobSAS: state.BlobSAS,
    NewText: newText ? newText.ParameterValue : null,
    NameOnlyPreview: className && className.ParameterValue.includes('no-controls') ? true : false
  };
}

let _waitForSave = false;

export class Document extends PureComponent {
  componentDidMount() {
    this.checkRefresh();
  }

  componentDidUpdate() {
    this.checkRefresh();
  }

  checkRefresh = () => {
    if (!this.props.CurrentEntity)
      return;

    if (!_waitForSave && (!this.props.Documents || this.shouldLoadDocuments())) {
      this.getDocuments();
      this.props.dispatch(actions.UpdateProp({
        Key: 'uploading_' + this.props.SWID,
        Value: false
      }));
    }
  }

  getDocuments = () => {
    let body = helpers.getEntityIds(this.props.CurrentEntity);
    if (!body.EntityId)
      return;

    this.clearDocuments();
    this.props.dispatch(actions.SetEntityDocuments({
      DocumentsKey: this.props.DocumentsKey,
      EntityId: body.EntityId,
      EntityTypeId: body.EntityTypeId,
      Items: []
    }));
    actions.ApiRequest('Document/GetDocuments', body, (result) => {
      if (!Array.isArray(result) || result.length === 0) {
        return;
      }

      result.forEach(item => item.Active = false);
      this.props.dispatch(actions.SetEntityDocuments({
        DocumentsKey: this.props.DocumentsKey,
        EntityId: body.EntityId,
        EntityTypeId: body.EntityTypeId,
        Items: [...result]
      }));
    });
  }

  clearDocuments = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.PreviewKey,
      Value: null
    }));

    this.props.dispatch(actions.SetEntityDocuments({
      DocumentsKey: this.props.DocumentsKey
    }));
  }

  shouldLoadDocuments = () => {
    let info = helpers.getEntityIds(this.props.CurrentEntity);

    return (
      (info.EntityTypeId && info.EntityId) &&
      info.EntityTypeId !== this.props.Documents.EntityTypeId ||
      info.EntityId !== this.props.Documents.EntityId
    )
  }

  getSingleFile = () => {
    let doc = null;
    if (this.props.Documents && this.props.Documents.Items) {
      doc = this.props.Documents.Items.find(x => !x.IsDeleted && x.IsDisplay);
    }

    return doc;
  }

  resetSingleFile = () => {
    let picker = document.getElementById('DocumentPicker');
    if (picker) {
      picker.value = '';
    }

    this.props.dispatch(actions.UpdateProp({
      Key: this.props.PreviewKey,
      Value: null
    }));

    this.props.dispatch(actions.NewDocument({
      DocumentId: 'NewDocument',
      DocumentsKey: this.props.DocumentsKey,
      AssociatedWith: -1,
      Comment: '',
      DocDate: new Date().toISOString().slice(0, 10),
      DocTypeId: null,
      IsDeleted: false,
      IsDisplay: true,
      IsGroup: false,
      IsPublic: false,
      IsNew: true,
      Active: true,
      Dirty: true
    }));
  }

  dirtySaveQueue = () => {
    if (!this.props.SaveQueue)
      return;

    let mySaveQueueItem = {
      Id: this.props.SWID,
      SaveQueue: this.props.SaveQueue,
      IsPlaceholder: true,
      OnCancel: () => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'cancel_' + this.props.SWID,
          Value: true
        }));
      },
      OnSave: () => {
        _waitForSave = true;
      },
      AfterSave: (entityChangeMap) => {
        _waitForSave = false;
        if (this.props.Documents && this.props.Documents.Items) {
          this.props.Documents.Items.forEach(item => {
            if (item.saveFunc) {
              item.saveFunc(entityChangeMap, item);
            }
          })
        }
      }
    };

    this.props.dispatch(actions.AddSaveData(mySaveQueueItem))
  }

  render() {
    let docs = this.props.Documents;
    let model = this.getSingleFile();

    if (this.props.SingleFileMode && !model) {
      this.resetSingleFile();
    }

    return (
      <div className="document-widget">
        {!this.props.SingleFileMode &&
          <div className="document-items">
            {
              docs && docs.Items &&
              docs.Items.map((x, idx) => (
                <DocumentItem key={idx} odd={idx % 2 !== 0} model={x} NameOnlyPreview={this.props.NameOnlyPreview} DocumentsKey={this.props.DocumentsKey} CurrentEntity={this.props.CurrentEntity} SWID={this.props.SWID} dirtySaveQueue={this.dirtySaveQueue} SaveQueue={this.props.SaveQueue} RefreshDocuments={this.getDocuments} />
              ))
            }
          </div>
        }
        {
          !this.props.SingleFileMode &&
          <NewDocument NewText={this.props.NewText} DocumentsKey={this.props.DocumentsKey} CurrentEntity={this.props.CurrentEntity} Documents={this.props.Documents} SWID={this.props.SWID} dirtySaveQueue={this.dirtySaveQueue} SaveQueue={this.props.SaveQueue} />
        }
        {
          this.props.SingleFileMode && model &&
          <SingleFile SWID={this.props.SWID} WaitForEntitySave={this.props.WaitForEntitySave} model={model} getDocuments={this.getDocuments} CancelSource={this.props.CancelSource} SaveEngineSwid={this.props.SaveEngineSwid} DocumentsKey={this.props.DocumentsKey} CurrentEntity={this.props.CurrentEntity} Documents={this.props.Documents} />
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(Document);

