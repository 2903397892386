import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import LookupFilter from './LookupFilter';
import BoolFilter from './BoolFilter';
import BucketFilter from './BucketFilter';
import * as Layout from './../../QueryLayout/v001/Save';
import * as LayoutEngine from './../../QueryLayout/v001/LayoutEngine';
import * as QueryActions from './QueryActions';
import { RefreshArrow } from './../../../Icons/Icons';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';



const mapStateToProps = (state, ownProps) => {
  let assetScene = ownProps.widget.Parameters.find(x => x.ParameterName === 'AssetScene');
  let assetSceneKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'AssetSceneKey');
  let rowNumbers = ownProps.widget.Parameters.find(x => x.ParameterName === 'RowNumbers');

  let hideLayout = false;
  if (state.agCurrentLayout && state.qry_SelectedQuery) {
    hideLayout = state.agCurrentLayout.QueryId !== state.qry_SelectedQuery.QueryId;
  }
  return {
    QueryList: state.qry_QueryList,
    SelectedQuery: state.qry_SelectedQuery,
    QueryId: state.qry_SelectedQuery ? state.qry_SelectedQuery.QueryId : undefined,
    WidgetState: state.widgetState[ownProps.widget.SceneWidgetId],
    SWID: ownProps.widget.SceneWidgetId,
    LoadLayout: state.agGridLoadLayout,
    AssetScene: assetScene ? assetScene.ParameterValue : 'Initial',
    AssetSceneKey: assetSceneKey ? assetSceneKey.ParameterValue : 'blu_MasterScene',
    CurrentLayout: hideLayout ? null : state.agCurrentLayout,
    CurrentUser: state.met_EntityMetadata ? state.met_EntityMetadata.CurrentUser : undefined,
    ExcelExporting: state.qry_ExcelExporting,
    TriggerEventSpray: state.qry_TriggerEventSpray,
    CancelEventSpray: state.qry_CancelEventSpray,
    EventSaveData: state.dbo_EventSaveData,
    Buckets: state.qry_Buckets,
    BucketMetadata: state.qry_BucketMetadata,
    Metadata: state.met_EntityMetadata,
    MasterSceneList: state.blu_MasterSceneList,
    RowNumbers: rowNumbers ? helpers.stringToBool(rowNumbers.ParameterValue) : false
  };
}

export class AgGrid extends React.PureComponent {
  nextRequestAggregate = false;
  lastRequest = null;
  refreshRowCount = true;

  state = {
    rowModelType: 'serverSide',
    columnDefs: [],
    defaultColDef: {
      flex: 1,
      minWidth: 70,
      width: 150,
      maxWidth: 500,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      allowedAggFuncs: ['avg', 'max', 'min', 'sum'],
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: (e) => {
        var result = '';
        if (e && typeof e.value === 'object' && e.value.hideIfNotGroupRow) {
          result = e.colDef.headerName === 'Group' ? e.value.display : '';
        } else {
          result = e && e.value !== undefined ? e.value.display : '';
        }
        return result || null;
      },
      cellClass: (params) => {
        if (this.filterModel && this.filterModel[params.colDef.field]) {
          return 'ag-filtered-cell';
        }
        return null;
      }
    },
    gridOptions: {
      suppressFieldDotNotation: true,
      rowSelection: 'single',
      rowGroupPanelShow: 'always',
      suppressDragLeaveHidesColumns: true,
      suppressContextMenu: true,
      serverSideStoreType: 'partial',
      onModelUpdated: () => { this.rowDataChanged() },
      onFilterChanged: () => { this.filterChanged() },
      onRowDoubleClicked: (e) => { this.rowDoubleClicked(e) },
      getChildCount: (data) => {
        return data && data.groupCount ? data.groupCount : '';
      },
      getRowNodeId: (data) => { return data.myId; },
      autoGroupColumnDef: {
        minWidth: 200
      }
    },
    frameworkComponents: { LookupFilter: LookupFilter, BoolFilter: BoolFilter, BucketFilter: BucketFilter },
    dataSourceInit: false,
    pendingResize: false,
    autoResize: false,
    blockLoadDebounceMillis: 100,
    showAggregates: false,
    aggregateFunc: 'avg',
    pinnedBottomRowData: [],
    rowCount: 0,
    savingLayout: false
  }

  emptyFilter = {
    displayKey: 'isempty',
    displayName: '(Empty)',
    test: (e) => { return true; },
    hideFilterInput: true
  }

  notEmptyFilter = {
    displayKey: 'isnotempty',
    displayName: '(Not empty)',
    test: (e) => { return true; },
    hideFilterInput: true
  }

  thisWeek = {
    displayKey: 'thisweek',
    displayName: 'This Week',
    test: (e) => { return true; },
    hideFilterInput: true
  }

  lastWeek = {
    displayKey: 'lastweek',
    displayName: 'Last Week',
    test: (e) => { return true; },
    hideFilterInput: true
  }

  thisMonth = {
    displayKey: 'thismonth',
    displayName: 'This Month',
    test: (e) => { return true; },
    hideFilterInput: true
  }

  lastMonth = {
    displayKey: 'lastmonth',
    displayName: 'Last Month',
    test: (e) => { return true; },
    hideFilterInput: true
  }

  thisYear = {
    displayKey: 'thisyear',
    displayName: 'This Year',
    test: (e) => { return true; },
    hideFilterInput: true
  }

  lastYear = {
    displayKey: 'lastyear',
    displayName: 'Last Year',
    test: (e) => { return true; },
    hideFilterInput: true
  }

  filterOptions = {
    Number: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange', this.emptyFilter, this.notEmptyFilter],
    Date: ['equals', 'greaterThan', 'lessThan', 'notEqual', 'inRange', this.emptyFilter, this.notEmptyFilter, this.thisWeek, this.lastWeek, this.thisMonth, this.lastMonth, this.thisYear, this.lastYear],
    String: ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith', this.emptyFilter, this.notEmptyFilter],
    UserList: ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith', this.emptyFilter, this.notEmptyFilter]
  }

  componentDidMount() {
    if (!this.props.Buckets) {
      this.getBuckets();
    }
  }

  componentDidUpdate(prev) {
    let prevQid = prev && prev.SelectedQuery ? prev.SelectedQuery.QueryId : null;
    let curQid = this.props.SelectedQuery ? this.props.SelectedQuery.QueryId : null;

    if (curQid && curQid !== prevQid) {
      console.log({ selectedQuery: this.props.SelectedQuery });
      let qry = this.props.SelectedQuery;
      this.loadQuery(qry.Layout, qry.DefaultLayout);
    }
    if (this.props.LoadLayout) {
      this.loadLayout(this.props.LoadLayout);
      this.props.dispatch(actions.UpdateProp({
        Key: 'agGridLoadLayout',
        Value: null
      }));
    }
    if (this.props.TriggerEventSpray) {
      this.sprayEvents();
    }

    if (this.props.CancelEventSpray) {
      this.cancelEventSpray();
    }
  }

  sprayEvents = () => {
    let reqBody = this.getRequestBody(this.lastRequest);
    QueryActions.doEventSpray(actions, reqBody, this.props.EventSaveData, this.props.SelectedQuery.EntityTypeId);

    batch(() => {
      this.props.dispatch(actions.ClearSaveData('dbo_EventSaveData'));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_TriggerEventSpray',
        Value: null
      }));
    });

    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    }, 0);
  }

  cancelEventSpray = () => {
    batch(() => {
      this.props.dispatch(actions.ClearSaveData('dbo_EventSaveData'));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_CancelEventSpray',
        Value: null
      }));
    });

    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    }, 0);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    window.agGridApi = {
      gridApi: params.api,
      columnApi: params.columnApi
    };

    if (this.props.WidgetState) {
      this.loadLayout(this.props.WidgetState);
    }
  }

  loadLayout = (layout) => {
    layout.columnDefs.forEach((x) => {
      if (this.filterOptions[x.dataType]) {
        x.filterParams = {
          filterOptions: [
            ...this.filterOptions[x.dataType]
          ]
        }
      }
    });
    setTimeout(() => {
      this.setState({ columnDefs: layout.columnDefs }, () => {
        setTimeout(() => {
          this.gridApi.setFilterModel(layout.filters);
          this.gridColumnApi.setColumnState(layout.columns);
          this.loadData();
        }, 0);
      });
    }, 0);
  }

  loadQuery = (query, defaultLayout) => {
    let filterTypes = {
      Number: 'agNumberColumnFilter',
      Date: 'agDateColumnFilter',
      String: 'agTextColumnFilter',
      Lookup: 'LookupFilter',
      Bucket: 'BucketFilter',
      UserList: 'agTextColumnFilter',
      Bool: 'BoolFilter'
    }

    query.Columns.forEach(col => {
      if (col.DataType === 'Number' && col.ColumnName.indexOf('AssignedTo') > -1) {
        col.ControlType = 'UserList'
      }
    });

    let columnDefs = query.Columns.filter(x => x.Label[0] !== '!').map((x) => {
      let type = this.getDataType(x);
      let obj = {
        headerName: x.Label,
        field: this.fullColumnName(x),
        enableValue: x.DataType === 'Number',
        dataType: type
      }

      if (filterTypes[type]) {
        obj.filter = filterTypes[type];
        if (x.DataType === 'Lookup') {
          obj.lookupSetId = x.LookupSetId;
        }
        if (x.DataType === 'Bucket') {
          obj.bucketName = x.BucketName;
        }
        if (this.filterOptions[type]) {
          obj.filterParams = {
            filterOptions: [
              ...this.filterOptions[type]
            ]
          }
        }
      }

      return obj;
    });

    window.agGridApi.columnDefs = columnDefs;
    this.refreshRowCount = true;
    this.filterModel = null;

    if (!defaultLayout && this.props.CurrentLayout) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'agCurrentLayout',
        Value: undefined
      }));
    }

    this.setState({ columnDefs: [] }, () => {
      if (this.props.RowNumbers) {
        columnDefs.unshift({
          headerName: "Row", sortable: false,
          valueGetter: (params) => {
            return { display: params.node.rowIndex + 1, raw: params.node.rowIndex + 1 }
          }
        });
      }
      this.setState({ columnDefs: columnDefs, pendingResize: true, pinnedBottomRowData: [], rowCount: 0 }, () => { this.loadData(defaultLayout) });
    });
  }

  loadData = (defaultLayout) => {
    if (!this.state.dataSourceInit) {
      this.gridApi.setServerSideDatasource({
        getRows: this.getRows
      });

      this.setState({ dataSourceInit: true });
    } else {
      this.gridApi.ensureIndexVisible(0);
      this.gridApi.refreshServerSideStore({ purge: true });
      if (this.state.showAggregates) {
        this.nextRequestAggregate = true;
      }
    }

    if (defaultLayout) {
      this.loadLayout(defaultLayout);
    }
  }

  getRows = (req) => {
    let body = this.getRequestBody(req);
    this.lastRequest = req;

    this.apiRequest(req, body);
    if (this.nextRequestAggregate) {
      this.aggregateRequest();
      this.nextRequestAggregate = false;
    }
  }

  getRequestBody = (req) => {
    if (!this.props.SelectedQuery)
      return;

    let cols = this.props.SelectedQuery.Layout.Columns;

    let body = {
      ...req.request,
      refreshRowCount: this.refreshRowCount,
      queryId: this.props.QueryId,
      allColumns: cols,
      bucketMetadata: this.props.BucketMetadata,
      filterModel: Object.keys(req.request.filterModel).map((key) => {
        let col = cols.filter(x => x.Label && x.Label[0] !== '!').find(c => this.fullColumnName(c) === key);
        return {
          field: key,
          dataType: this.getDataType(col),
          bucketName: col.BucketName,
          ...req.request.filterModel[key]
        }
      })
    };

    body.filterModel = body.filterModel.filter(x => !x.remove);
    body.groupKeys = body.groupKeys.map(x => x && x.raw !== undefined ? x.raw : x);
    body.rowGroupCols = body.rowGroupCols.map((x) => {
      let col = cols.find(c => this.fullColumnName(c) === x.field && c.Label === x.displayName);
      return {
        ...x,
        dataType: this.getDataType(col),
        bucketName: col.BucketName
      }
    });

    body.sortModel = body.sortModel.map((x) => {
      let col = cols.filter(x => x.Label && x.Label[0] !== '!').find(c => this.fullColumnName(c) === x.colId);
      x.isLookup = col && col.DataType === 'Lookup' && col.ControlType !== 'UserList';
      x.dataType = this.getDataType(col);
      return x;
    });

    return body;
  }

  getDataType = (col) => {
    return col ? col.ControlType === 'UserList' ? 'UserList' : col.DataType : 'String';
  }

  apiRequest = (req, body) => {
    window.lastAgRequestBody = body;
    actions.ApiRequest('Query/AgGridData', { ...body, allColumns: null }, (result) => {
      console.log({ agResult: result });
      if (!result || !result.Data)
        return;

      if (this.state.pendingResize) {
        this.setState({ autoResize: true });
      }

      if (result.QueryRows) {
        this.refreshRowCount = false;
        this.setState({ rowCount: result.QueryRows });
        window.agGridRowCount = result.QueryRows;
      }

      req.successCallback(this.parseResponseRows(result, body), result.LastRow)
    });
  }

  parseResponseRows = (result, body) => {
    let rowData = [];
    let cols = this.props.SelectedQuery.Layout.Columns;
    let data = result.Data;

    if (!data || !data[0])
      return rowData;

    if (!result.ColumnMap) {
      rowData = data.map((row) => {
        return row.reduce((prev, cellValue, idx) => {
          let gridColumn = cols[idx];
          let isUserList = gridColumn.ControlType === 'UserList';
          let val = cellValue;

          if (gridColumn.DataType !== 'Bucket') {
            val = (gridColumn.DataType === 'Lookup' || isUserList) ? helpers.getLookupName(isUserList ? 'userlist' : gridColumn.LookupSetId, cellValue) : cellValue;
          } else if (this.props.Buckets) {
            val = this.props.Buckets[gridColumn.BucketName].Data[cellValue];
          }

          if (gridColumn.Label[0] === '!') {
            prev[gridColumn.Label] = val;
          }

          prev[this.fullColumnName(gridColumn)] = {
            display: this.isLocaleCol(gridColumn, val, isUserList) ? Number(val).toLocaleString() : val,
            raw: cellValue
          };

          return prev;
        }, { myId: helpers.getId(1000) });
      });
    } else {
      let colMap = result.ColumnMap.split(', ');
      let hideGroupVal = body.rowGroupCols.length > 0 ? body.rowGroupCols[body.groupKeys.length].field : null;
      rowData = data.map((row) => {
        let rowObj = { myId: helpers.getId(1000) };
        colMap.forEach((key, idx) => {
          let col = cols.filter(x => x.Label && x.Label[0] !== '!').find(x => this.fullColumnName(x) === key);
          if (col) {
            let isUserList = col.ControlType === 'UserList';
            let hideIfNotGroupRow = hideGroupVal && hideGroupVal === colMap[idx];
            rowObj[colMap[idx]] = {
              display: this.isLocaleCol(col, row[idx], isUserList) ? Number(row[idx]).toLocaleString() : row[idx],
              raw: row[idx],
              hideIfNotGroupRow: hideIfNotGroupRow
            };
          } else {
            if (key.startsWith('count_')) {
              rowObj.groupCount = row[idx];
            }
          }
        });
        return rowObj;
      });
    }

    return rowData;
  }

  isLocaleCol = (col, val, isUserList) => {
    return !isUserList && val && col.DataType === 'Number' && col.Label !== 'Year';
  }

  rowDataChanged = () => {
    if (this.state.autoResize) {
      this.gridColumnApi.autoSizeAllColumns();
      this.setState({ pendingResize: false, autoResize: false });
    }
  }

  filterChanged = () => {
    this.filterModel = this.gridApi.getFilterModel();
    for (let key in this.filterModel) {
      if (this.filterModel[key].remove) {
        delete this.filterModel[key];
      }
    }
    this.refreshRowCount = true;
    if (this.state.showAggregates) {
      this.nextRequestAggregate = true;
    }
  }

  rowDoubleClicked = (e) => {
    if (e.data['!payperiodid']) {
      this.loadTimeRow(e);
      return;
    }

    if (!e.data['!EntityId'] || !e.data['!EntityTypeId'])
      return;

    let body = {
      EntityId: Number(e.data['!EntityId']),
      EntityTypeId: Number(e.data['!EntityTypeId'])
    };

    helpers.LoadEntity(this.props.dispatch, actions, body, this.props.AssetSceneKey, this.props.SelectedQuery.RowClickScene || this.props.AssetScene);
  }

  loadTimeRow = (e) => {
    try {
      batch(() => {
        let timesheetTab = this.props.MasterSceneList.find(x => x.Value.includes('Timesheet'));

        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_MasterScene',
          Value: {
            Label: timesheetTab.Label,
            Value: timesheetTab.Value,
            Enabled: true,
            Id: timesheetTab.Id
          }
        }));

        let userId = (e.data['usr.Users.UserId'] || e.data['ATE.UserId']).raw;
        let payPeriodId = Number(e.data['!payperiodid']);
        if (userId && payPeriodId) {
          this.props.dispatch(actions.UpdateProp({
            Key: 'ttr_SelectedUser',
            Value: {
              Id: userId,
              EntityId: userId
            }
          }));
        }

        let startDate = (e.data['ttr.TimeEntry.StartDate'] || e.data['ATE.Date']).raw;
        if (startDate) {
          this.props.dispatch(actions.UpdateProp({
            Key: 'ttr_TimesheetDateOverride',
            Value: startDate
          }));
        }
      });

    } catch (e) {
      console.error(e);
    }
  }

  fullColumnName = (col) => {
    return col.ColumnName;
  }

  toggleAggregate = () => {
    this.setState({ showAggregates: !this.state.showAggregates }, () => {
      if (this.state.showAggregates) {
        this.aggregateRequest();
      } else {
        this.setState({ pinnedBottomRowData: [] });
      }
    });
  }

  aggregateRequest = () => {
    if (!this.lastRequest)
      return;

    console.log({ aggregateRequest: this.lastRequest });

    let body = this.getRequestBody(this.lastRequest);
    let cols = this.props.SelectedQuery.Layout.Columns;

    body.refreshRowCount = false;
    body.totalAggregate = cols.filter(x => x.Label[0] !== '!' && x.DataType === 'Number' && x.ControlType !== 'UserList').map((x) => {
      return {
        field: this.fullColumnName(x),
        aggFunc: this.state.aggregateFunc,
        headerName: x.Label
      }
    });

    actions.ApiRequest('Query/AgGridData', body, (result) => {
      console.log({ aggregateResult: result });
      if (!result || !result.AggregateData)
        return;

      this.parseAggregateResponse(result, body);
    });
  }

  parseAggregateResponse = (result, body) => {
    if (result.AggregateData && result.AggregateData.length > 0) {
      let topRow = body.totalAggregate.reduce((prev, next) => {
        prev[next.field] = {
          display: `${next.aggFunc}(${next.headerName})`,
          raw: `${next.aggFunc}(${next.headerName})`
        }
        return prev;
      }, {});
      let aggregateRows = result.AggregateData.map((row) => {
        return row.reduce((prev, next, idx) => {
          prev[body.totalAggregate[idx].field] = {
            display: next ? Number(next).toLocaleString() : next,
            raw: next ? Number(next).toLocaleString() : next
          };
          return prev;
        }, {});
      });

      this.setState({ pinnedBottomRowData: [topRow, ...aggregateRows] });
    }
  }

  setAggregate = (e) => {
    if (e.target.value === 'hide') {
      this.setState({ showAggregates: !this.state.showAggregates, aggregateFunc: 'avg', pinnedBottomRowData: [] });
    } else {
      this.setState({ aggregateFunc: e.target.value }, this.aggregateRequest);
    }
  }

  getQueryLabel = () => {
    let result = '';
    let qry = this.props.SelectedQuery;

    if (qry && qry.Parent && qry.Parent.Text1) {
      result = qry.Parent.Text1 + ' > ';
    }

    if (qry && qry.Text1) {
      result += qry.Text1;
    }

    return result;
  }

  updateLayout = () => {
    let layout = this.props.CurrentLayout
    layout.Layout = LayoutEngine.getLayoutObject();
    this.setState({ savingLayout: true });
    Layout.saveLayout(this.props.CurrentUser, layout, this.props.QueryId, this.props.CurrentLayout, () => {
      this.setState({ savingLayout: false });
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_RefreshLayouts',
        Value: true
      }));
    });
  }

  excelExport = () => {
    if (this.props.ExcelExporting)
      return;

    let body = this.getRequestBody(this.lastRequest);

    let cols = this.props.SelectedQuery.Layout.Columns;

    body.exportCols = this.gridColumnApi.getColumnState();
    body.exportCols = body.exportCols.map((x) => {
      let col = cols.filter(x => x.Label && x.Label[0] !== '!').find(c => this.fullColumnName(c) === x.colId);
      return !col ? null : {
        ...x,
        dataType: this.getDataType(col),
        field: x.colId,
        displayName: col.Label,
        bucketName: col.BucketName,
        isOverrideLookup: col.IsOverrideLookup
      }
    });

    body.exportCols = body.exportCols.filter(x => x !== null && !x.hide);


    this.props.dispatch(actions.UpdateProp({
      Key: 'qry_ExcelExporting',
      Value: true
    }));

    actions.ApiRequest('Query/ExcelExport', body, (result) => {
      if (result.FileName) {
        setTimeout(() => {
          this.asyncDownloadFile(result.FileName, 0);
        }, 2000);
      }
    });
  }

  asyncDownloadFile = (fileName, attemptCount) => {
    actions.ApiGet('betty/' + fileName, fileName, (dlRes) => {
      try {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(dlRes);
        a.href = url;
        a.download = this.props.SelectedQuery.Text1.replaceAll(' ', '') + '_' + new Date().toLocaleDateString().replaceAll('/', '_') + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        this.props.dispatch(actions.UpdateProp({
          Key: 'qry_ExcelExporting',
          Value: false
        }));
      } catch {
        if (attemptCount < 25) {
          setTimeout(() => {
            attemptCount++;
            this.asyncDownloadFile(fileName, attemptCount);
          }, 2000 + (attemptCount * 2000));
        }
      }
    });
  }

  componentWillUnmount() {
    let layout = {
      filters: this.gridApi.getFilterModel(),
      columns: this.gridColumnApi.getColumnState(),
      columnDefs: this.state.columnDefs
    }
    this.props.dispatch(actions.SetWidgetState({
      swid: this.props.SWID,
      state: layout
    }));
  }

  clearFilters = () => {
    this.gridApi.setFilterModel(null);
    this.filterModel = null;
  }

  refreshRows = () => {
    this.gridApi.refreshServerSideStore({ purge: true });
    this.refreshRowCount = true;
  }


  getBuckets = () => {
    let hasSprocParam = this.props.widget.Parameters.find(x => x.ParameterName === 'Sproc');
    if (!hasSprocParam)
      return;

    let body = {
      SWID: this.props.SWID,
      Parameter: ''
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      let bucketIdMap = {};
      let bucketObj = result.Buckets.reduce((prev, next) => {
        prev[next.Name] = { ...next, Data: {} };
        bucketIdMap[next.Id] = next.Name;
        return prev;
      }, {});

      let bucketData = result.BucketData.reduce((prev, next) => {
        prev[bucketIdMap[next.Id]].Data[next.Key] = next.Value;
        return prev;
      }, bucketObj);

      let metaBuckets = this.getBucketsFromMetadata();
      let allBucketData = { ...bucketData, ...metaBuckets.data };

      helpers.setQueryBuckets(allBucketData);

      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_BucketMetadata',
        Value: [...result.Buckets, ...metaBuckets.metadata]
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_Buckets',
        Value: allBucketData
      }));
    });
  }

  getBucketsFromMetadata = () => {
    let eventBuckets = this.getEventListBuckets();
    let allEvents = this.getAllEventsBucket(eventBuckets);
    let userBucket = this.getUserBucket();

    return {
      data: {
        ...eventBuckets.data,
        ...allEvents.data,
        ...userBucket.data
      },
      metadata: [
        ...eventBuckets.metadata,
        ...allEvents.metadata,
        ...userBucket.metadata
      ]
    }
  }

  getAllEventsBucket = (eventBuckets) => {
    let allBucket = {
      Data: {},
      FilterData: {},
      Id: 99001,
      JoinColumn: 'EntityTypeId',
      JoinTable: 'met.EM',
      SortColumn: 'Label',
      Name: 'EventTypes',
    };

    let entNames = helpers.getEntityNameMap();

    let relNames = this.props.Metadata.EntityRelationships.filter(x => x.Relationship === 'Event').reduce((prev, next) => {
      prev[next.RelatedId] = entNames[next.TypeId];
      return prev;
    }, {});

    let items = Object.keys(eventBuckets.data).map(key => eventBuckets.data[key]);
    items = cloneDeep(items);

    items.forEach(x => {
      x.FilterData = {};
      Object.keys(x.Data).forEach(key => {
        x.FilterData[key] = relNames[key] + ' - ' + x.Data[key];
      });
      allBucket.Data = { ...allBucket.Data, ...x.Data };
      allBucket.FilterData = { ...allBucket.FilterData, ...x.FilterData };
    });

    return {
      data: { EventTypes: allBucket },
      metadata: [{ ...allBucket, Data: undefined }]
    }
  }

  getUserBucket = () => {
    let userBucket = {
      Data: {},
      Id: 99000,
      JoinColumn: 'UserId',
      JoinTable: 'usr.Users',
      Name: 'Users',
      SortColumn: 'DisplayName'
    };

    this.props.Metadata.UserList.forEach((x) => {
      userBucket.Data[x.UserId] = x.Name;
    });

    return {
      data: { Users: userBucket },
      metadata: [{ ...userBucket, Data: undefined }]
    }
  }

  getEventListBuckets = () => {
    let entNames = helpers.getEntityNameMap();

    let eventBuckets = this.props.Metadata.EntityRelationships.filter(x => x.Relationship === 'Event').reduce((prev, next, idx) => {
      if (!entNames[next.RelatedId])
        return prev;

      if (!prev['EventTypes' + next.TypeId]) {
        prev['EventTypes' + next.TypeId] = {
          Id: 100000 + idx,
          JoinColumn: 'EntityTypeId',
          JoinTable: 'met.EM',
          SortColumn: 'Label',
          Name: 'EventTypes' + next.TypeId,
          Data: {
            [next.RelatedId]: entNames[next.RelatedId]
          }
        };
      } else {
        prev['EventTypes' + next.TypeId].Data = {
          ...prev['EventTypes' + next.TypeId].Data,
          [next.RelatedId]: entNames[next.RelatedId]
        };
      }

      return prev;
    }, {});

    let eventBucketMetadata = Object.keys(eventBuckets).map((key) => {
      let bucketItem = eventBuckets[key];
      return {
        Id: bucketItem.Id,
        JoinColumn: bucketItem.JoinColumn,
        JoinTable: bucketItem.JoinTable,
        Name: bucketItem.Name,
        SortColumn: bucketItem.SortColumn
      };
    });

    return {
      data: eventBuckets,
      metadata: eventBucketMetadata
    }
  }

  render() {
    return (
      <div className="ag-grid-widget">
        <div className="ag-grid-refresh-arrow-v1" onClick={this.refreshRows}>
          <div className="ag-svg-container">
            <RefreshArrow />
          </div>
        </div>
        <div className="ag-grid-row-count">
          <div>{this.state.rowCount.toLocaleString()} Rows</div>
        </div>
        <div className="ag-grid-excel-export" onClick={this.excelExport}>
          <div>{(!this.props.ExcelExporting ? 'Excel Export' : '...')}</div>
        </div>
        <div className="ag-grid-query-name v-2">
          <div className="label">
            <div>{this.getQueryLabel()}</div>
            {this.props.CurrentLayout && <div className="layout">Layout: {this.props.CurrentLayout.Name}</div>}
            {this.props.CurrentLayout && this.props.CurrentUser === this.props.CurrentLayout.UserId &&
              <div className="update-layout-btn" onClick={this.updateLayout}>
                <div>{(this.state.savingLayout ? '...' : 'Apply Layout Changes')}</div>
              </div>}
          </div>
        </div>
        {!this.state.showAggregates &&
          <div className="ag-grid-button ag-top-bar-control" onClick={this.toggleAggregate}>
            <div>Σ Totals</div>
          </div>
        }
        {
          this.state.showAggregates &&
          <div className="ag-top-bar-control aggregate-selector">
            <div>Σ</div>
            <select value={this.state.aggregateFunc} onChange={this.setAggregate}>
              <option value="avg">Avg</option>
              <option value="max">Max</option>
              <option value="min">Min</option>
              <option value="sum">Sum</option>
              <option value="hide">None</option>
            </select>
          </div>
        }
        <div className="ag-grid-clear-filters" onClick={this.clearFilters}>
          <div>Clear Filters</div>
        </div>
        <div className="ag-theme-balham">
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowModelType={this.state.rowModelType}
            onGridReady={this.onGridReady}
            gridOptions={this.state.gridOptions}
            blockLoadDebounceMillis={this.state.blockLoadDebounceMillis}
            frameworkComponents={this.state.frameworkComponents}
            pinnedBottomRowData={this.state.pinnedBottomRowData}
            cacheBlockSize={500}
            serverSideStoreType={'partial'}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AgGrid);