import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  // let parameter = ownProps.widget.Parameters.find(x => x.ParameterName === 'Parameter');
  return {
    SelectedEntity: state.ent_Selected,
    CurrentEntity: state.ent_Current,
    Metadata: state.met_EntityMetadata,
    CurrentUser: state.CurrentUser
  };
};

export class CopySignSupport extends React.PureComponent {
  RelTable = 'rel.SignSupport';
  SupportTable = 'xtn.A1088';

  componentDidMount() {
    this.newGeo = this.props.Trigger;
    this.copySupport();
  }

  copySupport = () => {
    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify(this.props.SelectedEntity)
    }

    let currentEntity = cloneDeep(this.props.CurrentEntity);

    actions.ApiRequest('List/GetList', body, (result) => {
      this.copyEntity(currentEntity);
      this.setGeoAndClone(currentEntity);
      batch(() => {
        result.Signs.forEach((sign, idx) => {
          this.copyEntity(sign);
          this.addRelRecord(currentEntity, sign, idx + 1);
        });
      });
    });

    //copy the support    
    //2. go through all props on signs returned from 1. and make new entities out of them
    //3. make a new support from this.props.CurrentEntity
    //4. attach new sign entities to new support entitiy
  }

  setGeoAndClone = (support) => {
    let baseObj = {
      Id: null,
      InsertKey: support.insertKey,
      Table: this.SupportTable,
      IsBaseTable: true,
      AutoGenerated: true,
      SaveQueue: 'dbo_SignMgtSaveData'
    }

    let saveData = [
      helpers.saveDataItem(baseObj, 'Geometry', this.newGeo.WKT),
      helpers.saveDataItem(baseObj, 'CloneOf', support.EntityId)
    ];

    saveData.forEach(sd => {
      this.props.dispatch(actions.AddSaveData(sd));
    });
  }

  addRelRecord = (support, sign, displayOrder) => {
    let baseObj = {
      Id: null,
      InsertKey: helpers.getInsertKey(),
      Table: this.RelTable,
      IsBaseTable: false,
      EntityTypeId: 1088,
      SaveQueue: 'dbo_SignMgtSaveData'
    };

    let saveData = [
      helpers.saveDataItem(baseObj, 'ParentId', support.insertKey),
      helpers.saveDataItem(baseObj, 'ParentTypeId', support.EntityTypeId),
      helpers.saveDataItem(baseObj, 'ChildId', sign.insertKey),
      helpers.saveDataItem(baseObj, 'ChildTypeId', sign.EntityTypeId),
      helpers.saveDataItem(baseObj, 'DisplayOrder', displayOrder),
      helpers.saveDataItem(baseObj, 'IsDeleted', false)
    ];

    saveData.forEach(sd => {
      this.props.dispatch(actions.AddSaveData(sd));
    });
  }

  copyEntity = (entity) => {
    let insertKeyMap = {};
    let saveData = [];

    let insertKey = helpers.getInsertKey();
    insertKeyMap[entity.EntityId] = insertKey;
    entity.insertKey = insertKey;

    let type = this.props.Metadata.EntityTypes.find(x => x.TypeId === entity.EntityTypeId);

    if (!type)
      return;

    let tableKeyMap = type.Properties.reduce((prev, next) => {
      if (next.SaveData.Table && !prev[next.SaveData.Table]) {
        prev[next.SaveData.Table] = next.IsBaseTable ? insertKey : helpers.getInsertKey()
      }
      return prev;
    }, {});

    let distinctProps = type.Properties.filter(helpers.autoInsertProps).reduce((prev, next) => {
      if (!prev.hasBeenAdded[next.Name] && (!next.SaveData || !(next.SaveData.Table || '').includes('rel.'))) {
        prev.hasBeenAdded[next.SaveData.Table + '_' + next.Name] = 1;
        prev.props.push(next);
      }
      return prev;
    }, { props: [], hasBeenAdded: {} }).props;

    distinctProps.forEach(prop => {
      if (prop.Name !== 'Geometry') {
        saveData.push({
          Id: null,
          InsertKey: prop.SaveData.Table ? tableKeyMap[prop.SaveData.Table] : insertKey.toString(),
          Table: prop.SaveData.Table,
          Column: prop.SaveData.Column,
          Value: helpers.formatDefaultValue(prop, this.props.CurrentUser) || '',
          GobAutofill: prop.GobAutofill,
          IsBaseTable: prop.IsBaseTable,
          AutoGenerated: true,
          SaveQueue: 'dbo_SignMgtSaveData'
        });
      }
    });

    let keyRing = helpers.idValueArrayToObject(entity.Properties);
    type.Properties.forEach((prop) => {
      let formData = keyRing[prop.Id];

      if (prop.SaveData && prop.SaveData.Column === 'Name') {
        prop.Value = '';
      } else if (formData) {
        prop.Value = formData.Value;
      }
    });

    type.Properties.forEach((prop) => {
      if (prop.SaveData.Table && prop.Value !== null && prop.Value !== undefined) {
        saveData.push({
          Id: null,
          InsertKey: tableKeyMap[prop.SaveData.Table],
          Table: prop.SaveData.Table,
          Column: prop.SaveData.Column,
          Value: prop.Value || helpers.formatDefaultValue(prop, this.props.CurrentUser) || '',
          GobAutofill: prop.GobAutofill,
          IsBaseTable: prop.IsBaseTable,
          AutoGenerated: true,
          SaveQueue: 'dbo_SignMgtSaveData'
        });
      }
    });

    saveData = saveData.filter(x => !x.Table.startsWith('met.') && (x.Value !== '' || x.DefaultValue));

    saveData.forEach(sd => {
      this.props.dispatch(actions.AddSaveData(sd));
    });

  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(CopySignSupport);