
import * as actions from '../../../../../Stores/Actions/actions';
import StyleEngine from '../Styles/StyleEngine';

const getMetadata = (dispatch) => {
  actions.ApiRequest('Map/GetMetadata', {}, (result) => {
    result.LegendItems.forEach(item => {
      item.Active = item.OnByDefault;
      item.Children = result.LegendItems.filter(x => x.ParentId === item.LayerId)
    });

    StyleEngine.buildStyleLibrary(result.Styles);
    dispatch(actions.SetMapStyles(result.Styles));
    dispatch(actions.SetLegendItems(result.LegendItems));
    dispatch(actions.UpdateProp({
      Key: 'AzureMapsKey',
      Value: result.AzureMapsKey
    }));
  });
}

export default {
  getMetadata: getMetadata
}









