import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEvent: state.ent_CurrentEvent,
    EventSaveData: state.dbo_EventSaveData,
    SelectedEvent: state.ent_SelectedEvent,
    NewEventParentTypeId: state.ent_NewEventParentTypeId
  };
};

export class Ohio_DefaultBMPTypeStormWater extends React.PureComponent {

  componentDidMount() {

    //                      **** These checks are done in scriptrunner **** 

    if (!this.props.CurrentEvent) {
      return;
    }

    //Check for current event EventTypeId = 1510003
    if (this.props.CurrentEvent && this.props.CurrentEvent.EntityTypeId !== 1510003) {
      return;
    }

    //Check that parent event is a Stormwater Control event - met.Grid sets NewEventParentTypeId? reference to check it is 1510001
    if (!this.props.NewEventParentTypeId) {
      return;
    }
    if (this.props.NewEventParentTypeId.EntityTypeId !== 1510001) {
      return;
    }

    //Remove existing default value from savedata
    let eventSaveData = this.props.EventSaveData;
    eventSaveData = eventSaveData.filter(x => x.Column !== "BMPTypeLid");
    this.props.dispatch(actions.UpdateProp({
      Key: 'dbo_EventSaveData',
      Value: eventSaveData
    }));

    //Set BMPNameLid prop on ent_CurrentEvent to 26753
    let evt = this.props.CurrentEvent;
    let bmpTypeLidProp = evt.Properties.find(x => x.Name === 'BMPTypeLid');
    bmpTypeLidProp.Value = 26753;
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_CurrentEvent',
      Value: evt
    }))

    //Add save data item for default BMP Type on this Site Inspection event
    let baseObj = {
      Id: null,
      InsertKey: this.props.SelectedEvent.InsertKeys['xtn.E15113'],
      Table: 'xtn.E15113',
      IsEventData: true,
      NewEntitySaveData: true
    }
    helpers.addSaveData(this.props.dispatch, baseObj, 'BMPTypeLid', 26753);

  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Ohio_DefaultBMPTypeStormWater);
