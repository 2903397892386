import React from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import { defaults as defaultControls, Control } from 'ol/control';
import { LegendIcon } from './../../../../Icons/Icons';

const mapStateToProps = (state) => {
  return {
  };
}

export class ZoomMonitor extends React.PureComponent {
  state = {
    zoom: 0
  }

  componentDidMount() {
    this.setZoom();
    this.props.map.getView().on('change:resolution', this.setZoom);
  }

  setZoom = () => {
    let zoom = this.props.map.getView().getZoom().toFixed(2);
    this.setState({ zoom: Number(zoom) });
  }

  render() {
    return (
      <div className="zoom-monitor ol-unselectable ol-control">
        <div>{this.state.zoom}</div>
      </div>
    );
  }
}

const zoomComponent = connect(mapStateToProps, null, null, { forwardRef: true })(ZoomMonitor);
export default helpers.OpenLayersCustomControl(Control, zoomComponent);




