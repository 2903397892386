import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as hub from './../../../../Utils/PubSub';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  let source = ownProps.widget.Parameters.find(x => x.ParameterName === 'ButtonSource');
  let saveData = ownProps.widget.Parameters.find(x => x.ParameterName === 'SaveData');
  let altSave = ownProps.widget.Parameters.find(x => x.ParameterName === 'AltSaveData');
  let resetKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'ResetKey');
  let onSaveSceneKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'OnSaveSceneKey');
  let onSaveSceneValue = ownProps.widget.Parameters.find(x => x.ParameterName === 'OnSaveSceneValue');
  let entityKey = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntityKey');
  let entitySrc = ownProps.widget.Parameters.find(x => x.ParameterName === 'EntitySource');
  let afterSaveSR = ownProps.widget.Parameters.find(x => x.ParameterName === 'AfterSaveScriptRunner');
  let validateNewRowsOnly = ownProps.widget.Parameters.find(x => x.ParameterName === 'ValidateNewRowsOnly');
  let preSaveSR = ownProps.widget.Parameters.find(x => x.ParameterName === 'PreSaveScriptRunner');
  let dontSave = ownProps.widget.Parameters.find(x => x.ParameterName === 'DontSave');

  return {
    Button: source ? state[source.ParameterValue] : null,
    ButtonSource: source ? source.ParameterValue : null,
    SaveData: saveData ? state[saveData.ParameterValue] : null,
    SaveDataKey: saveData ? saveData.ParameterValue : null,
    AltSaveData: altSave ? state[altSave.ParameterValue] : null,
    SaveId: state.dbo_SaveId,
    BeforeSaveId: state.dbo_BeforeSaveId,
    BeforeSaveSwid: state.dbo_BeforeSaveSwid,
    SWID: ownProps.widget.SceneWidgetId,
    SaveDataDebug: state.SaveDataDebug || [],
    ResetKey: resetKey ? resetKey.ParameterValue : undefined,
    OnSaveSceneKey: onSaveSceneKey ? onSaveSceneKey.ParameterValue : undefined,
    OnSaveSceneValue: onSaveSceneValue ? onSaveSceneValue.ParameterValue : undefined,
    CurrentEntity: entityKey ? state[entityKey.ParameterValue] : null,
    EntitySource: entitySrc ? state[entitySrc.ParameterValue] : null,
    EntitySourceKey: entitySrc ? entitySrc.ParameterValue : null,
    EntityKey: entityKey ? entityKey.ParameterValue : null,
    StopSave: state.dbo_StopSave,
    AfterSaveSR: afterSaveSR ? afterSaveSR.ParameterValue : null,
    ValidateNewRowsOnly: validateNewRowsOnly ? helpers.stringToBool(validateNewRowsOnly.ParameterValue) : false,
    PreSaveSR: preSaveSR ? preSaveSR.ParameterValue : null,
    DontSave: dontSave ? helpers.stringToBool(dontSave.ParameterValue) : false
  };
};

export class Save extends React.PureComponent {
  waiting = false;

  constructor(props) {
    super(props);
    this.updateButton(0, false);
    if (!this.props.SaveData && this.props.SaveDataKey) {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.SaveDataKey,
        Value: []
      }));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.Button)
      return;

    this.checkEnabled(prevProps);
    this.handleClick(prevProps);
    if (prevProps && this.props.SaveId !== prevProps.SaveId && this.props.CurrentEntity) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_RefreshProps',
        Value: {}
      }));
    }

    if (prevProps && prevProps.BeforeSaveId !== this.props.BeforeSaveId && this.props.BeforeSaveSwid === this.props.SWID) {
      setTimeout(() => {
        if (this.props.StopSave) {
          console.log('save stopped');
          this.props.dispatch(actions.UpdateProp({
            Key: 'dbo_StopSave',
            Value: false
          }));
          this.props.dispatch(actions.UpdateProp({
            Key: 'dbo_SaveInProgress',
            Value: false
          }));
        } else {
          this.doSave();
        }
      }, 0);
    }
  }

  checkEnabled = () => {
    if (!this.props.SaveData || !this.props.Button)
      return;

    let isEnabled = this.props.SaveData.length > 0 || (this.props.AltSaveData && this.props.AltSaveData.length > 0);

    if (this.props.Button.Enabled !== isEnabled) {
      this.updateButton(this.props.Button.ClickId, isEnabled);
    }
  }

  handleClick = (prevProps) => {
    if (this.waiting || !this.props.Button)
      return;

    if (prevProps.Button.ClickId !== this.props.Button.ClickId && this.props.SaveData && this.props.SaveData.length > 0) {
      this.beforeSave();
    }
  }

  beforeSave = () => {
    let ent = this.props.CurrentEntity;
    this.waiting = true;
    let saveData = this.props.SaveData;
    if (ent && ent.EntityTypeId) { //during mass update we aren't getting EntityTypeId from ent, since we are on query tab
      saveData = this.props.SaveData.map(x => {
        if (x.EntityTypeId !== 'none') {
          x.EntityTypeId = ent.EntityTypeId;
        }
        return x;
      });
    }

    let reqPropsValid = true;

    if (!this.props.ValidateNewRowsOnly || saveData.find(x => x.InsertKey)) {
      reqPropsValid = this.checkRequiredProps();
    }

    if (!reqPropsValid) {
      this.waiting = false;
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.EntityKey,
        Value: cloneDeep(ent)
      }));
    } else {
      if (this.props.PreSaveSR) {
        this.props.dispatch(
          actions.UpdateProp({
            Key: this.props.PreSaveSR,
            Value: true,
          })
        );
      }
      this.waiting = false;
      batch(() => {
        this.props.dispatch(
          actions.UpdateProp({
            Key: "dbo_BeforeSaveId",
            Value: (this.props.BeforeSaveId || 0) + 1,
          })
        );
        this.props.dispatch(
          actions.UpdateProp({
            Key: "dbo_BeforeSaveSwid",
            Value: this.props.SWID,
          })
        );
        this.props.dispatch(
          actions.UpdateProp({
            Key: "dbo_SaveInProgress",
            Value: true,
          })
        );
      });
    }
  }

  doSave = () => {
    if (this.waiting)
      return;

    if (this.props.DontSave)
      return;

    //check online status
    if (!navigator.onLine) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: { Title: 'Unable to Establish a Connection', Message: <p>Please ensure you are connected to the internet and try saving again.</p> }
      }));
      return;
    }

    let ent = this.props.CurrentEntity;
    this.waiting = true;

    let saveData = this.props.SaveData;

    hub.publish('BeforeSave', saveData, () => {
      console.log({
        FinalSaveData: saveData
      });
      if (ent && ent.EntityTypeId) {
        saveData = this.props.SaveData.map(x => {
          if (x.EntityTypeId === 'none') {
            x.EntityTypeId = null;
          } else {
            x.EntityTypeId = ent.EntityTypeId;
          }
          return x;
        });
      }

      helpers.resetEntityGeoLookup();

      if (ent && ent.EntityTypeId) {
        helpers.onSave(ent.EntityTypeId + '_' + ent.EntityId);
      }

      let afterSaveCallbacks = [];
      this.props.SaveData.forEach(item => {
        if (item.OnSave) {
          item.OnSave();
        }
        if (item.AfterSave) {
          afterSaveCallbacks.push(item.AfterSave);
        }
      });

      saveData = saveData.filter(x => !x.IsPlaceholder);

      if (saveData.length === 0) {
        this.afterSave(null, ent, afterSaveCallbacks);
        this.props.dispatch(actions.ClearSaveData(this.props.SaveDataKey));
        return;
      }

      if (window.supportInfo) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'SaveDataDebug',
          Value: [{
            SaveQueue: this.props.SaveDataKey,
            Time: new Date().toLocaleTimeString(),
            SaveData: JSON.stringify(saveData)
          },
          ...this.props.SaveDataDebug]
        }));
      }

      this.props.dispatch(actions.ClearSaveData(this.props.SaveDataKey));

      actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
        this.afterSave(result, ent, afterSaveCallbacks);
      }, undefined, () => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_Dialog',
          Value: { Title: 'Save Unsuccessful', Message: <p>Save unsuccessful, please re-enter information and try again.</p> }
        }));
        this.waiting = false;
      });
    });
  }

  afterSave = (result, ent, afterSaveCallbacks) => {
    this.waiting = false;

    let entityChangeMap = {
      old: cloneDeep(this.props.EntitySource)
    }
    batch(() => {
      if (ent && ent.EntityTypeId) {
        if (this.props.EntityKey) {
          actions.AfterSave(ent, this.props.EntityKey);
        }
      }

      if (ent && ent.IsNew && result && result.Id && this.props.EntitySourceKey && this.props.EntitySource) {
        let newEntity = { ...cloneDeep(this.props.EntitySource), EntityId: Number(result.Id), IsNew: false, ShellOnly: false, InsertKeys: null };
        entityChangeMap.new = newEntity;
        if (!this.props.EntitySource.disableModal) {
          this.props.dispatch(actions.UpdateProp({
            Key: this.props.EntitySourceKey,
            Value: newEntity
          }));
        }
      }

      if (this.props.ResetKey) {
        let keys = this.props.ResetKey.split(',').map(x => x.trim());
        keys.forEach(x => {
          this.props.dispatch(actions.UpdateProp({
            Key: x,
            Value: undefined
          }));
        });
      }

      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });

    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveInProgress',
        Value: false
      }));
      if (this.props.AfterSaveSR) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.AfterSaveSR,
          Value: true
        }));
      }
      if (afterSaveCallbacks && Array.isArray(afterSaveCallbacks)) {
        afterSaveCallbacks.forEach(afterSave => afterSave(entityChangeMap, result));
      }

      if (this.props.OnSaveSceneKey && this.props.OnSaveSceneValue) {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.OnSaveSceneKey,
          Value: {
            Value: this.props.OnSaveSceneValue,
            Enabled: true
          }
        }));
      }
    }, 10);
  }

  checkRequiredProps = () => {
    let checkPassed = true;
    if (this.props.CurrentEntity && this.props.CurrentEntity.EntityTypeId) {
      let reqProps = this.props.CurrentEntity.Properties.filter(x => x.IsRequired && !helpers.validationPropValue(x) && !x.InfoCard);

      if (reqProps.length > 0) {
        reqProps.forEach(prop => {
          prop.FailedValidate = true;
          console.log('Required prop stopping save: ' + prop.Name);
        });
        checkPassed = false;
        this.props.SaveData.forEach(sd => {
          sd.OnFailedValidate && sd.OnFailedValidate();
        });
      }
    }
    return checkPassed;
  }

  updateButton = (clickId, isEnabled) => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.ButtonSource,
      Value: {
        ClickId: clickId,
        Enabled: isEnabled
      }
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(Save);