import React, { PureComponent } from 'react'
import ModeSelector from '../ModeSelector/ModeSelector';
import { Clear, Done } from '../../../../../../Icons/Icons';

export default class DateField extends PureComponent {

  getShortDate = (date) => {
    if (!date)
      return '';

    let parts = date.split('-');
    return parts[1] + '-' + parts[2];
  }

  render() {
    let parent = this.props.parent;
    let myOptions = this.props.myOptions;
    let mode = this.props.mode;
    let inputVal = parent.props.Filter.Value;
    let inputVal2 = parent.props.Filter.Value2;

    return (
      <div>
        {
          <div className={'type-filter text-filter filter-col-' + parent.props.settings.ColumnId}>
            {
              parent.state.ShowFilters &&
              <ModeSelector filterOptions={myOptions} setMode={parent.setMode} Filter={parent.props.Filter} />
            }
            {
              !mode.TwoValues &&
              <div className="flex-box">
                <div className="filter-mode-box" onClick={parent.showFilterToggle}>
                  <div>{mode.Value || ''}</div>
                </div>
                <input ref={parent.primaryInput} type='Date' value={inputVal || ''} onChange={(e) => { parent.onChange(e, 'Value') }} />
              </div>
            }
            {
              mode.TwoValues &&
              <div className="flex-box two-value">
                <div className="filter-mode-box" onClick={parent.showFilterToggle}>
                  <div>{mode.Value || ''}</div>
                </div>
                <div className="date-display">
                  <div>
                    {this.getShortDate(inputVal) + (inputVal ? ' to ' : '') + this.getShortDate(inputVal2)}
                  </div>
                </div>
                {
                  this.props.parent.state.Editing && !parent.state.ShowFilters &&
                  <div className="double-date-filter">
                    <input ref={parent.primaryInput} type='Date' value={inputVal || ''} onChange={(e) => { parent.onChange(e, 'Value') }} />
                    <input type='Date' value={inputVal2 || ''} onChange={(e) => { parent.onChange(e, 'Value2') }} />
                  </div>
                }
              </div>
            }
            {
              parent.isDirty() &&
              <div className="filter-done" onClick={parent.confirmFilter}>
                <Done />
              </div>
            }
            <div className="filter-clear" onClick={parent.clearFilter}>
              <Clear />
            </div>
          </div>
        }
      </div>
    )
  }
}
