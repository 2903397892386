

import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { batch } from 'react-redux';

export const fileAttached = (e) => {
  // if (!e.path || !e.path[0])
  //   return;

  let file = e.target.files[0];
  if (!file)
    return;

  document.getElementById("slider-animation").style.display = "block"; //start animation
  document.getElementById("slider-animation-inside").style.animationPlayState = "running";

  let formData = new FormData();
  formData.append('xlImport_' + file.name, file, file.name);
  formData.append('FileType', file.type);
  formData.append('Extension', file.name.split('.').pop());
  formData.append('DocumentId', 'NewDocument');
  formData.append("DocDate", new Date().toISOString().slice(0, 10));

  formData.append('spLastModified', file.lastModified);
  formData.append('spName', file.name);
  formData.append('spSize', file.size);
  formData.append('spType', file.type);

  actions.ApiSendData('Document/ExcelImport', formData, (result) => {
    document.getElementById("slider-animation-inside").style.animationPlayState = "paused";
    if (result.Result === 'Worked') {
      document.getElementById("slider-animation-inside").innerHTML = "Complete!";
      document.getElementById("slider-animation-inside").style.width = "95px";
    } else {
      document.getElementById("slider-animation-inside").innerHTML = "Failed!";
      document.getElementById("slider-animation-inside").style.backgroundColor = "#EB3838";
      document.getElementById("slider-animation-inside").style.width = "95px";
      setTimeout(() => { alert('IMPORT FAILED: \n\n' + result.Result); }, 300);
    }
    setTimeout(() => {
      document.getElementById("slider-animation-inside").innerHTML = "Importing...";
      document.getElementById("slider-animation").style.display = "none";
      document.getElementById("slider-animation-inside").style.backgroundColor = "rgb(8, 230, 27)";
    }, 4000);
  });
}
