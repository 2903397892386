import React from 'react';
import ReactDOM from 'react-dom';
import store from './../Stores/Store';
import Style from 'ol/style/Style';
import { batch } from 'react-redux';
import * as actions from './../Stores/Actions/actions';
import Point from 'ol/geom/Point'
import cloneDeep from 'lodash/cloneDeep';
import { isMobileOnly, isMobile, isTablet } from 'react-device-detect';

export const paramsToObject = (widget) => {
  return widget.Parameters.reduce((prev, next) => {
    prev[next.ParameterName] = next.ParameterValue
    return prev;
  }, {});
}

export const idValueArrayToObject = (arr) => {
  return arr.reduce((prev, next) => {
    prev[next.Id] = next;
    return prev;
  }, {});
}

export const elementOrAncestorHasClass = (element, className) => {
  let nameArr = Array.isArray(className) ? className : [className];

  if (!element || element.length === 0) {
    return false;
  }
  var parent = element;
  do {
    if (parent === document) {
      break;
    }
    for (var i = 0; i < nameArr.length; i++) {
      if ((parent.getAttribute('class') || '').indexOf(nameArr[i]) >= 0) {
        return true;
      }
    }
  } while (parent = parent.parentNode);

  return false;
}

export const arrayMove = (arr, fromId, toId) => {
  if (fromId === toId)
    return arr;

  let target = arr[fromId];
  var dir = toId < fromId ? -1 : 1;

  for (var i = fromId; i !== toId; i += dir) {
    arr[i] = arr[i + dir];
  }

  arr[toId] = target;

  return arr;
}

let timeout;
export const debounce = (func, waitMs) => {
  if (timeout)
    clearTimeout(timeout);

  timeout = setTimeout(func, waitMs);
};

export const OpenLayersCustomControl = (Control, CustomControl) => {
  return function (Control) {
    function CustomControlWrapper(options, props) {
      const ref = React.createRef();
      ReactDOM.render(<CustomControl ref={ref} store={store} {...props} />, document.createElement('div'));

      options = options || {};
      Control.call(this, {
        target: options.target,
        element: ReactDOM.findDOMNode(ref.current)
      });
    }

    CustomControlWrapper.__proto__ = Control;
    CustomControlWrapper.prototype = Object.create(Control && Control.prototype);
    CustomControlWrapper.prototype.constructor = CustomControlWrapper;

    return CustomControlWrapper;
  }(Control, CustomControl);
}

export const getEntityIds = (entity) => {
  return {
    EntityId: entity ? (entity.EntityId || entity.FeatureId || entity.AssetId || entity.EventId) : null,
    EntityTypeId: entity ? (entity.EntityTypeId || entity.FeatureClassId || entity.AssetClassId || entity.EventTypeId) : null
  };
}

export const stringToBool = (str) => {
  let trueVals = ['True', 'true', '1', 'Yes', 'yes', true, 1];
  return str && trueVals.includes(str);
}

export const formatDate = (date) => {
  var d = date ? new Date(date) : new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

let keyId = -314159;
export const getInsertKey = () => {
  return keyId--;
}

export const propDidChange = (props, prevProps, key) => {
  if (!props || !prevProps)
    return false;

  if (!props[key] || !prevProps[key])
    return false;

  return props[key] !== prevProps[key];
}

export const closeEvent = (dispatch, actions) => {
  dispatch(actions.UpdateProp({
    Key: 'ent_TryCloseEvent',
    Value: true
  }));
}

let lookupItems = [];
let userList = [];
let userTenants = [];
let allMetadata = null;
let currentUserId = null;

export const getEntityType = (typeId) => {
  return allMetadata.EntityTypes.find(x => x.TypeId === typeId);
}

export const setLookupItems = (metadata) => {
  lookupItems = metadata.LookupItems;
  userList = [{ Name: '', UserId: null, On: true, DefaultItem: true }, ...metadata.UserList];
  userTenants = metadata.UserTenants;
  allMetadata = metadata;
  currentUserId = metadata.CurrentUser;
}

export const getLookupName = (lookupSetId, lookupItemId) => {
  if (typeof lookupSetId === 'string' && lookupSetId.toLowerCase() === 'userlist')
    return getUserName(lookupItemId);

  let setId = lookupSetId && typeof lookupSetId === 'string' ? Number(lookupSetId) : lookupSetId;
  let itemId = lookupItemId && typeof lookupItemId === 'string' ? Number(lookupItemId) : lookupItemId;

  //try to find using the lookup set
  let lookup = lookupItems.find(x => x.LookupSet === setId);
  if (lookup) {
    let lookupItem = lookup.LookupItems.find(x => x.LookupItemId === itemId || (x.OverrideValue && x.OverrideValue == itemId));
    if (lookupItem) {
      return lookupItem.OverrideValue || lookupItem.Name1;
    }
  }

  //if it gets here lookup wasn't found, lookup set might be wrong/missing - search with itemid only
  if (itemId) {
    let set = lookupItems.find(x => x.LookupItems.find(y => y.LookupItemId === itemId) !== undefined);
    if (set) {
      return set.LookupItems.find(x => x.LookupItemId === itemId).Name1;
    }
  }

  return '';
}

export const getLookupItem = (lookupItemId) => {
  let itemId = Number(lookupItemId);
  let set = lookupItems.find(x => x.LookupItems.find(y => y.LookupItemId === itemId) !== undefined);
  if (set) {
    return set.LookupItems.find(x => x.LookupItemId === itemId);
  }
}

export const getUserByDisplayName = (name) => {
  return userList.find(x => x.Name === name);
}

export const getUserName = (lookupItemId) => {
  let userId = lookupItemId && typeof lookupItemId === 'string' ? Number(lookupItemId) : lookupItemId;
  let user = userList.find(x => x.UserId === userId);
  return user ? user.Name : ''
}

export const shareWithItems = () => {
  let userTenantMap = userTenants.reduce((prev, next) => {
    prev[next.Id] = true;
    return prev;
  }, {});

  let distinct = userList.filter(x => x.On).reduce((prev, next) => {
    if (!prev.allIds[next.Name + '-' + next.TenantId]) {
      prev.users.push(next);
      prev.allIds[next.Name + '-' + next.TenantId] = true;
    }
    return prev;
  }, { allIds: {}, users: [] });

  return distinct.users.filter(x => userTenantMap[x.TenantId]);
}

export const getLookupItems = (lookupSetId, tenantId) => {
  if (typeof lookupSetId === 'string' && lookupSetId.toLowerCase() === 'userlist') {
    if (tenantId) {
      return userList.filter(x => x.DefaultItem || (x.TenantId === tenantId && x.On));
    } else {
      return userList.filter(x => x.DefaultItem);
    }
  }

  let setId = lookupSetId && typeof lookupSetId === 'string' ? Number(lookupSetId) : lookupSetId;
  let lookup = lookupItems.find(x => x.LookupSet === setId);
  return lookup ? lookup.LookupItems : [];
}

export const getFormula = (items) => {
  let map = items.reduce((prev, next) => {
    if (!prev[next.GroupId]) {
      prev[next.GroupId] = [next];
    } else {
      prev[next.GroupId].push(next);
    }
    return prev;
  }, {});

  let result = [];
  let keys = Object.keys(map);
  let multiGroup = keys.length > 1;
  for (var keyId = 0; keyId < keys.length; keyId++) {
    let key = keys[keyId];
    let rule = multiGroup ? keyId > 0 ? map[key][0].IsAnd ? ' && ( ' : '|| ( ' : ' ( ' : '';
    for (var i = 0; i < map[key].length; i++) {
      rule += (i > 0 ? map[key][i].IsAnd ? ' && ' : ' || ' : '') + map[key][i].ItemId;
    }
    rule += multiGroup ? ' ) ' : '';
    result.push(rule);
  }

  return result.join('');
}

export const distance = (lat1, lon1, lat2, lon2) => {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
}

export const entPropValue = (prop) => {
  let val = prop.Value;
  if (prop.LookupSetId) {
    val = getLookupName(prop.LookupSetId, prop.Value);
  }
  if (prop.ControlType === 'DatePicker' && prop.Value) {
    return shortDate(prop.Value);
  }
  return val;
}

export const validationPropValue = (prop) => {
  let val = prop.Value;
  console.log('checking validate for: ' + prop.Name);

  if (prop.LookupSetId && prop.ControlType !== 'BucketDropDown') {
    val = getLookupName(prop.LookupSetId, prop.Value);
  }
  if (prop.ControlType === 'DatePicker' && prop.Value) {
    return shortDate(prop.Value);
  }

  if (prop.ControlType === 'NumberBox') {
    console.log('validate prop value: ' + prop.Value);
    return Number(prop.Value);
  }

  if (prop.DataMaskOptions && prop.DataMaskOptions.includes('Regex') && JSON.parse(prop.DataMaskOptions).Regex) {
    let regex = new RegExp(JSON.parse(prop.DataMaskOptions).Regex);
    return regex.test(prop.Value);
  }

  return val;
}

export const propDisplayValue = (prop, entity) => {
  let result = '';
  if (allMetadata && allMetadata.EntityTypes && entity) {
    let ent = allMetadata.EntityTypes.find(x => x.TypeId === entity.EntityTypeId);

    if (!ent || !ent.Properties)
      return result;

    let entProp = ent.Properties.find(x => x.Name === prop.Name);

    if (!entProp)
      return result;

    if (entProp.LookupSetId || entProp.ControlType === 'UserList') {
      let lookupSet = (entProp.ControlType === 'UserList' ? 'UserList' : entProp.LookupSetId)
      return getLookupName(lookupSet, prop.Value);
    } else {
      return prop.Value;
    }
  } else {
    if (!entity && prop.Value) {
      return prop.Value;
    } else {
      return propValue(prop);
    }
  }
}

export const propDisplayValueByName = (propName, entity) => {
  let result = '';
  if (!propName || !entity || !entity.Properties)
    return result;

  let prop = entity.Properties.find(x => x.Name === propName);

  if (!prop)
    return result;

  return propDisplayValue(prop, entity);
}

export const propValueByName = (propName, entity) => {
  let result = '';
  if (!propName || !entity || !entity.Properties)
    return result;

  let prop = entity.Properties.find(x => x.Name === propName);

  if (!prop)
    return result;

  return prop.Value;
}

export const propValueByLabel = (propLabel, entity) => {
  let result = '';
  if (!propLabel || !entity || !entity.Properties)
    return result;

  let prop = entity.Properties.find(x => x.Label === propLabel);

  if (!prop)
    return result;

  return prop.Value;
}

export const userNameById = (id) => {
  if (!id || isNaN(Number(id)))
    return '';

  let user = userList.find(x => x.UserId === Number(id));

  return user ? user.Name || '' : '';
}

let id = 0;
export const getId = (base = 0) => {
  id++;
  return base + id;
}

export const keyIsPrintable = (e) => {
  var key = e.keyCode;
  var isPrintable =
    (key > 47 && key < 58) ||     // number keys      
    (key > 64 && key < 91) ||     // letter keys
    (key > 95 && key < 112) ||    // numpad keys
    (key > 185 && key < 193) ||   // ;=,-./` 
    (key > 218 && key < 223);     // [\]' 

  return isPrintable;
}

export const propInForm = (prop, formName) => {
  return prop && prop.FormData && prop.FormData.find(x => x.FormName === formName);
}

export const propHasParent = (prop, parentId) => {
  return prop && prop.FormData && prop.FormData.find(x => x.ParentEMCId === parentId);
}


export const showImagePreview = (img, containerElement) => {
  if (!img || !containerElement)
    return;

  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext("2d");
  let preview = containerElement;
  preview.innerHTML = '';
  ctx.drawImage(img, 0, 0);

  window.requestAnimationFrame(() => {
    setTimeout(() => {
      let maxWidth = preview.clientWidth;
      let maxHeight = preview.clientHeight;
      preview.appendChild(img);

      let dims = resizeDimensions(img.width, img.height, maxWidth, maxHeight);
      img.width = canvas.width = dims.width;
      img.height = canvas.height = dims.height;

      ctx.drawImage(img, 0, 0);
      setTimeout(() => {
        ctx.drawImage(img, 0, 0);
      }, 0);
    }, 0);
  });
}

export const resizeDimensions = (width, height, maxWidth, maxHeight) => {
  var ratio = Math.min(maxWidth / width, maxHeight / height);
  return { width: width * ratio, height: height * ratio };
}

let callWait = null;
export const callAfterWait = (callback, ms) => {
  if (callWait)
    clearInterval(callWait);

  callWait = window.setInterval(() => {
    callback();
    clearInterval(callWait);
  }, ms);
}

export const parseJson = (json) => {
  try {
    let obj = JSON.parse(json);
    return obj;
  } catch (e) {
    console.error('Error parsing JSON: ' + json);
    console.error(e);
    return {};
  }
}

export const globalGeoUpdate = (id, geometry) => {
  if (!id)
    return;

  Object.keys(window.MapInstance).forEach(key => {
    let map = window.MapInstance[key];
    let layers = map.getLayers().getArray();
    layers.forEach(layer => {
      let source = layer.getSource();
      if (!source.getFeatures)
        return;

      let fet = source.getFeatureById(id);
      if (fet) {
        fet.setGeometry(geometry);
      }
    });
  })
}

export const globalGeoReset = (id) => {
  if (!id)
    return;

  if (!window.MapInstance)
    return;

  let stylesToReset = deletedFets.filter(x => x.id === id);
  stylesToReset.forEach(x => {
    x.fet.setStyle(null);
  });

  deletedFets = deletedFets.filter(x => x.id !== id);
  resetEntityGeoLookup();
  Object.keys(window.MapInstance).forEach(key => {
    let map = window.MapInstance[key];
    let layers = map.getLayers().getArray();
    layers.forEach(layer => {
      let source = layer.getSource();
      if (!source.getFeatures)
        return;

      let fet = source.getFeatureById(id);
      if (fet) {
        if (!fet.originalGeo) {
          let og = fet.getGeometry().clone();
          fet.originalGeo = og;
        }
        fet.setGeometry(fet.originalGeo);
        fet.originalGeo = fet.originalGeo.clone();
      }
    });
  })
}

export const clearMapLayer = (layerId, isUserQueryLayer) => {
  Object.keys(window.MapInstance).forEach(key => {
    let map = window.MapInstance[key];
    let layers = map.getLayers().getArray().filter(x => x.LayerId === layerId && !!x.IsUserQueryLayer === isUserQueryLayer);
    layers.forEach(layer => {
      map.removeLayer(layer);
    });
  })
}

export const onSave = (id) => {
  deletedFets = [];
  if (window.MapInstance && Object.keys(window.MapInstance).length > 0) {
    Object.keys(window.MapInstance).forEach(key => {
      let map = window.MapInstance[key];
      let layers = map.getLayers().getArray();
      layers.forEach(layer => {
        let source = layer.getSource();
        if (!source.getFeatures)
          return;

        let fet = source.getFeatureById(id);
        if (fet) {
          let og = fet.getGeometry().clone();
          fet.originalGeo = og;
        }
      });
    })
  }
}

export const resetEntityGeoLookup = () => {
  if (!window.MapInstance)
    return;

  Object.keys(window.MapInstance).forEach(key => {
    let map = window.MapInstance[key];
    map.entityLookupComplete = false;
  });
}

let deletedFets = [];
export const globalGeoDelete = (id) => {
  if (!id)
    return;

  Object.keys(window.MapInstance).forEach(key => {
    let map = window.MapInstance[key];
    let layers = map.getLayers().getArray();
    layers.forEach(layer => {
      let source = layer.getSource();
      if (!source.getFeatures)
        return;

      let fet = source.getFeatureById(id);
      if (fet) {
        fet.setStyle(new Style({}));
        deletedFets.push({ id: id, fet: fet });
      }
    });
  });
}

export const getMapCenter = (swid) => {
  if (!swid || !window.MapInstance[swid])
    return null;

  let map = window.MapInstance[swid];
  let center = map.getView().getCenter();

  let centerPt = new Point([center[0], center[1]]).transform('EPSG:3857', 'EPSG:4326').getCoordinates();
  return centerPt;
}

export const clearEditLayer = () => {
  Object.keys(window.MapInstance).forEach(key => {
    let map = window.MapInstance[key];
    let curEntityLayer = map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer');

    if (curEntityLayer) {
      curEntityLayer.getSource().clear();
    }
  });
}

export const entId = (entity) => {
  if (!entity)
    return null;

  return entity.EntityTypeId + '_' + entity.EntityId;
}

export const dateToInputFormat = (date) => {
  return date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0);
}

export const shortDate = (date) => {
  let dateObj = new Date();
  if (date) {
    if (navigator.userAgent.includes('Firefox') || navigator.userAgent.includes('Safari')) {
      dateObj = new Date(date);
      dateObj.setDate(dateObj.getDate() + 1);
    } else {
      dateObj = new Date(date + ' ');
    }
  }
  let str = dateObj.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return str.split('/').map(x => Number(x) + '').join('/');
}


export const scrollIntoView = (element) => {
  if (element) {
    let parentTop = element.parentNode.scrollTop;
    let parentHeight = element.parentNode.clientHeight;

    if ((parentTop + parentHeight - element.clientHeight) < element.offsetTop) {
      element.parentNode.scrollTop = element.offsetTop - parentHeight + element.clientHeight + 10;
    }
    if (parentTop > element.offsetTop) {
      element.parentNode.scrollTop = element.offsetTop - 10;
    }
  }
}

export const addSaveData = (dispatch, baseObj, column, value) => {
  dispatch(actions.AddSaveData(saveDataItem(baseObj, column, value)));
}

export const saveDataItem = (base, prop, value) => {
  return {
    ...base,
    Column: prop,
    Value: value
  }
}

export const saveQueueObject = (saveData) => {
  return saveData.reduce((prev, next) => {
    if (!prev[next.Id]) {
      prev[next.Id] = {};
    }
    prev[next.Id][next.Column] = next.Value;
    return prev;
  }, {});
}

export const updateGridFromSaveQueue = (gridData, saveQueue) => {
  let queue = saveQueue;
  if (!queue)
    return;

  gridData.Columns.forEach(column => {
    gridData.Rows.filter(x => !x.InlineNewRow).forEach(row => {
      let rowItem = row[column.Name];
      if (queue && rowItem && rowItem.SaveData && column.Control && column.Control.SaveData && column.Control.ControlType !== 'AutoDropDown' && column.Control.ControlType !== 'AutoSearch') {
        let saveData = { ...row[column.Name].SaveData, ...column.Control.SaveData };
        if (saveData.Id || saveData.InsertKey) {
          let queueItem = queue.filter(x => (x.Id || x.InsertKey)).find(x => (x.Id || x.InsertKey).toString() === (saveData.Id || saveData.InsertKey).toString() && x.Column === saveData.Column && x.Table === saveData.Table);
          if (queueItem && rowItem.Value !== queueItem.Value) {
            rowItem.Value = queueItem.Reversed ? !queueItem.Value : queueItem.Value;
          }
        }
      }
    });
  });
}

export const highlightRequiredField = (element) => {
  element.classList.add('failed-validate');
  element.addEventListener('input', fieldValueChange);
}

const fieldValueChange = (e) => {
  e.target.classList.remove('failed-validate');
  e.target.removeEventListener('input', fieldValueChange);
}

export const jsonOrStringValue = (val) => {
  try {
    return JSON.parse(val);
  } catch {
    return val;
  }
}

export const isNumericData = (type) => {
  let numericTypes = ['Lookup', 'Number', 'Bool'];
  return type && numericTypes.includes(type);
}


export const parseFilter = (settings) => {
  let filter = settings.Filter;
  let colName = settings.TableName + '.' + settings.ColumnName;

  let val = ''
  if (filter && (filter.Value || !filter.NeedsValue)) {
    if (filter.Mode.Value === 'lookup') {
      return lookupFilter(filter, colName);
    }

    if (filter.Mode.Value === 'bool') {
      return boolFilter(filter, colName);
    }

    val = filter.Mode.Sql;
    val = val.replace(/{value}/g, filter.Value);
    val = val.replace(/{value2}/g, filter.Value2);
    val = val.replace(/{column}/g, colName);
  }
  return val;
}

export const lookupFilter = (filter, colName) => {
  let filterString = '';

  let lookupValues = filter.LookupItems.filter(x => !x.Empty && !x.ToggleAll);
  let enabled = lookupValues.filter(x => x.Enabled);

  let empty = filter.LookupItems.find(x => x.Empty && x.Enabled);
  if (empty) {
    filterString += '(' + colName + ' IS NULL OR '
  } else {
    filterString += '(' + colName + ' IS NOT NULL AND '
  }


  if (enabled.length > (lookupValues.length - enabled.length)) {
    let disabled = lookupValues.filter(x => !x.Enabled).map(x => x.LookupItemId);
    if (disabled.length === 0) {
      filterString += '1 = 1';
    } else {
      filterString += colName + ' NOT IN (' + disabled.join(',') + ')';
    }
  } else {
    let enabled = lookupValues.filter(x => x.Enabled).map(x => x.LookupItemId);
    if (enabled.length === 0) {
      filterString += '0 = 1';
    } else {
      filterString += colName + ' IN (' + enabled.join(',') + ')';
    }

  }

  return filterString + ')';
}

export const boolFilter = (filter, colName) => {
  let conditions = [];

  if (filter.Mode.BoolTrue)
    conditions.push(colName + ' = 1');

  if (filter.Mode.BoolFalse)
    conditions.push(colName + ' = 0');

  let filterString = conditions.join(' OR ');

  if (filter.Mode.BoolEmpty)
    filterString += (filterString ? ' OR ' : '') + colName + ' IS NULL';

  if (!filter.Mode.BoolEmpty)
    filterString += (filterString ? ' AND ' : '') + colName + ' IS NOT NULL';

  return '(' + filterString + ')';
}


export const alignGridHeaders = () => {
  setTimeout(() => {
    let totalWidth = 0;
    Array.from(document.getElementsByClassName('unpinned-header-col')).forEach((el) => {
      totalWidth += el.clientWidth;
    }, 0);

    Array.from(document.getElementsByClassName('header-width')).forEach((el) => {
      el.style.width = totalWidth + 'px';
    });
  }, 500);
}

export const propValue = (entity, prop) => {
  if (!entity || !entity.Properties || !prop)
    return null;

  let entProp = entity.Properties.find(x => x.Name === prop);

  if (!entProp)
    return null;

  return entProp.Value;
}

export const LoadEntity = (dispatch, actions, body, assetSceneKey, assetScene) => {
  dispatch(actions.UpdateProp({
    Key: assetSceneKey,
    Value: {
      Value: assetScene,
      Enabled: true
    }
  }));

  actions.ApiRequest('Entity/GetEntity', body, (result) => {
    batch(() => {
      let paramVal = cloneDeep(body);

      if (result && result.Parent && result.Parent.EntityId) {
        paramVal = {
          EntityId: result.Parent.EntityId,
          EntityTypeId: result.Parent.EntityTypeId
        }

        dispatch(actions.UpdateProp({
          Key: 'ent_SelectedEvent',
          Value: cloneDeep(body)
        }));
      }

      if (assetScene === 'Initial_Site') { //ohio specific
        dispatch(actions.UpdateProp({
          Key: 'ent_SiteSelected',
          Value: paramVal
        }));
      } else {
        dispatch(actions.UpdateProp({
          Key: 'ent_Selected',
          Value: paramVal
        }));
      }
    });
  });
}

export const siteInit = (appId) => {
  if ((appId == 3 || appId == 4) && window.location.href.toLowerCase().indexOf("app.elarawater.com") == -1) {
    document.title = 'Asset Management';
  } else if (appId == 22 || appId == 11) {
    document.title = 'Utility Billing';
  } else if (appId == 9 || appId == 10) {
    document.title = 'Beehive';
  } else if (appId == 17) {
    document.title = 'Waggle';
  } else if (appId == 13 || appId == 14 || appId == 15 || appId == 16 || appId == 18 || appId == 19) {
    document.title = 'NRD';
  } else if (appId == 23) {
    document.title = 'UB - Title Company';
  } else if (appId == 28) {
    document.title = 'Field - Utility Billing';
  } else if (appId == 31) {
    document.title = 'Admin';
    document.querySelector("link[rel*='icon']").href = "/favicon2.ico";
  } else if (appId == 26 || appId == 30) {
    document.title = 'mySalesman';
    document.querySelector("link[rel*='icon']").href = "/favicon3.ico";
  } else if(appId === 32 && window.location.href.toLowerCase().includes("app=ubadmin")) {
    document.title = 'UB - Admin'
  } else if (appId == 32) {
    document.title = 'mySalesman Admin';
    document.querySelector("link[rel*='icon']").href = "/favicon3.ico";
  } else if (appId == 34) {
    document.title = 'mySalesman Super Admin';
    document.querySelector("link[rel*='icon']").href = "/favicon3.ico";
  } else if (appId == 35) {
    document.title = 'Hive';
  } else if (window.location.href.toLowerCase().includes("app.elarawater.com") && (appId == 3 || appId == 4)) {
    document.title = 'Elara Water';
    document.querySelector("link[rel*='icon']").href = "/favicon4.ico";
  }
}

export const GetCreatableEvents = (entityId, currentEntity) => {
  let metadata = allMetadata;
  let assetType = entityId;
  let relationships = metadata.EntityRelationships.filter(x => x.TypeId === assetType && x.Relationship === 'Event');

  let eventList = relationships.map(rel => {
    let entity = metadata.EntityTypes.find(x => x.TypeId === rel.RelatedId);
    if (!entity || !entity.CanCreate)
      return null;

    if (rel.ConditionalProp && rel.ConditionalValue) {
      if (!currentEntity) {
        return null;
      } else {
        let condProp = currentEntity.Properties.find(x => x.Name === rel.ConditionalProp);
        if (condProp && condProp.Value != rel.ConditionalValue) {
          return null;
        }
      }
    }

    return {
      Text1: entity.Label,
      EntityTypeId: entity.TypeId,
      ShellOnly: true
    }
  });

  eventList = eventList.filter(x => x !== null);

  eventList = eventList.sort((a, b) => {
    return a.Text1 > b.Text1 ? 1 : b.Text1 > a.Text1 ? -1 : 0;
  });

  return eventList;
}

export const ParseAutoFillProps = (props) => {
  let autofill = props.control.AutoFillParams.split(',').map(x => x.trim());

  let autofillVals = autofill.map(x => {
    let val;
    if (x.includes('frm.')) {
      let propName = x.replace('frm.', '');
      let prop = props.entity.Properties.find(x => x.Name === propName);
      let fillVal = prop ? entPropValue(prop) : '';
      val = { Val: fillVal };
    }
    if (x.includes('ent.')) {
      let keyName = x.replace('ent.', '');
      let fillVal = props.entity[keyName];
      val = { Val: fillVal };
    }
    if (x.includes('gob.')) {
      let autoFill = x.replace('gob.', '');
      let pieces = autoFill.split('.');
      let value = pieces.reduce((prev, next) => prev[next], props.State);
      val = { Val: value };
    }

    return val;
  });

  return JSON.stringify(autofillVals);
}

export const getListParameter = (state, params, thisControl) => {
  if (!state || !params)
    return {};

  try {
    if (params.includes(',')) {
      return params.split(',').map(x => x.split('.').reduce((prev, next) => {
        return prev ? next.trim() === 'ThisControl' ? thisControl :
          next.trim()[0] === '!' ? next.trim().slice(1) : prev[next.trim()]
          : prev
      }, state));
    } else {
      if (params[0] === '!') {
        return [params.slice(1)];
      }
      return params.split('.').reduce((prev, next) => { return prev ? prev[next.trim()] : prev }, state);
    }
  } catch {
    console.error('failed to parse autofill params: ' + params);
  }
}
// ent_SelectedPermissionEntity, ent_SelectedPermissionGroup
export const getUBAdminParam = (state, params, thisControl) => {
  if (!state || !params)
    return {};

  try {
    return {PermissionGroupId:state.ent_SelectedPermissionGroup.EntityId,EntityTypeId:state.ent_SelectedPermissionEntity.EntityTypeId}
  } catch {
    console.error('failed to parse autofill params: ' + params);
  }
}

let queryBuckets = null;
export const setQueryBuckets = (buckets) => {
  queryBuckets = buckets;
}

export const getQueryBuckets = () => {
  return queryBuckets;
}

let distinctFilter = {};
export const setDistinctFilter = (queryId, result) => {
  if (result && result.DistinctValues && result.DistinctValues.length > 0) {
    distinctFilter[queryId] = result.DistinctValues;
  }
}

export const getDistinctFilter = (queryId, col) => {
  let result = [];
  if (col && distinctFilter && distinctFilter[queryId]) {
    let myCol = distinctFilter[queryId].find(x => x.Col === col);
    if (myCol) {
      result = myCol.Values;
    }
  }
  return result;
}

export const doDistinctFilter = (queryId) => {
  return !distinctFilter[queryId];
}

export const getEntityNameMap = () => {
  return allMetadata.EntityTypes.reduce((prev, next) => {
    prev[next.TypeId] = next.Label;
    return prev;
  }, {});
}

export const autoInsertProps = (prop) => {
  let ignoreAutoVals = ['ent_Selected', 'ent_SelectedEvent'];
  let validTable = prop.SaveData.Table && !prop.SaveData.Table.includes('rel.');
  let autoFillProp = (prop.DefaultValue && !ignoreAutoVals.includes(prop.DefaultValue)) || prop.GobAutofill;
  return validTable && autoFillProp;
}

export const formatDefaultValue = (prop, currentUser, keyMap) => {
  let val = prop.DefaultValue;

  if (!val)
    return val;

  if (val === 'AutoCompleteCard' || val === 'AutoCompleteImageCard')
    val = '';

  if (val.toLowerCase() === 'now') {
    let date = formatDate();
    prop.Value = date;
    prop.DefaultSet = true;
    return date;
  }

  if (val.toLowerCase() === 'timenow') {
    let time = formatTime();
    prop.Value = time;
    prop.DefaultSet = true;
    return time;
  }

  if (val === 'CurrentUser') {
    let name = getUserName(currentUser.Id);
    prop.DoInitialQuery = true;
    prop.Value = name;
    prop.DefaultSet = true;
    return name;
  }

  if (keyMap && val.toLowerCase().includes('keymap')) {
    if (keyMap[val.split('.')[1]]) {
      return keyMap[val.split('.')[1]];
    }
  }

  return val;
}

export const formatTime = () => {
  let today = new Date();
  let hrs = String(today.getHours()).padStart(2, '0');
  let mins = String(today.getMinutes()).padStart(2, '0');
  let sec = String(today.getSeconds()).padStart(2, '0');

  return [hrs, mins, sec].join(':');
}


// @media screen and (max-width:1120px) {
//   body:not(.mobile-app) {
//     zoom: 80%;
//   }
// }

// @media screen and (max-width:900px) {
//   body:not(.mobile-app) {
//     zoom: 70%;
//   }
// }

// @media screen and (max-width:732px) {
//   body:not(.mobile-app) {
//     zoom: 65%;
//   }
// }
let _hasZoomed = false;
export const siteZoomListenerStart = () => {
  if (isMobileOnly)
    return;

  window.setInterval(() => {
    if (!window.MapInstance)
      return;

    let width = window.innerWidth;
    let zoomFactor = null;

    if (width <= 1120 && width > 900) {
      zoomFactor = .8;
    }
    if (width <= 900 && width > 732) {
      zoomFactor = .7;
    }
    if (width <= 732) {
      zoomFactor = .65
    }

    if (zoomFactor !== null) {
      adjustForZoom(zoomFactor);
    } else if (_hasZoomed) {
      resetZoom();
    }
  }, 200);
}

let resetZoom = () => {
  let doReset = false;
  Object.keys(window.MapInstance).forEach(x => {
    let canvas = document.getElementById('swid-' + x);
    if (canvas && canvas.getAttribute('zoom-factor') !== '1') {
      doReset = true;
    }
  });

  if (doReset) {
    adjustForZoom(1);
  }
}

let adjustForZoom = (zoomFactor) => {
  Object.keys(window.MapInstance).forEach(x => {
    let canvas = document.getElementById('swid-' + x);
    if (!canvas || canvas.getAttribute('zoom-factor') !== zoomFactor.toString()) {
      let map = document.querySelector('#swid-' + x + ' #map');
      if (!map)
        return;

      _hasZoomed = true;
      let curWidth = map.offsetWidth;
      let curHeight = map.offsetHeight;
      canvas.style.zoom = ((1 / zoomFactor) * 100) + '%';
      map.style.width = (curWidth * zoomFactor) + 'px';
      map.style.height = (curHeight * zoomFactor) + 'px';
      canvas.setAttribute('zoom-factor', zoomFactor.toString());
    }
  });
}


export const resizeListenerStart = () => {
  if (isMobile || isTablet) {
    return;
  }

  window.addEventListener('resize', (e) => {
    debounce(() => {
      if (window.MapInstance) {
        Object.keys(window.MapInstance).forEach(swid => {
          let map = document.querySelector('#swid-' + swid + ' #map');
          if (map) {
            map.style.width = '100%';
            map.style.height = '100%';
            window.MapInstance[swid].updateSize();
          }
        });
      }
    }, 200);

  }, true)
}

export const stringToStyleObject = (styleStr) => {
  let obj = {};
  let styles = styleStr.split(',').map(x => x.trim());
  styles.forEach(x => {
    let pieces = x.split(':').map(x => x.trim());
    if (pieces.length = 2) {
      obj[pieces[0]] = pieces[1];
    }
  });
  return obj;
}

export const dayPatternEncoder = (dayPatternDays) => {
  let dayPattern = 0;
  if (dayPatternDays.includes('Monday')) {
    dayPattern += 1;
  }
  if (dayPatternDays.includes('Tuesday')) {
    dayPattern += 2;
  }
  if (dayPatternDays.includes('Wednesday')) {
    dayPattern += 4;
  }
  if (dayPatternDays.includes('Thursday')) {
    dayPattern += 8;
  }
  if (dayPatternDays.includes('Friday')) {
    dayPattern += 16;
  }
  if (dayPatternDays.includes('Saturday')) {
    dayPattern += 32;
  }
  if (dayPatternDays.includes('Sunday')) {
    dayPattern += 64;
  }
  return dayPattern;
}

export const monthPatternEncoder = (monthPatternDays) => {
  let monthPattern = 0;
  if (monthPatternDays.includes('January')) {
    monthPattern += 1;
  }
  if (monthPatternDays.includes('February')) {
    monthPattern += 2;
  }
  if (monthPatternDays.includes('March')) {
    monthPattern += 4;
  }
  if (monthPatternDays.includes('April')) {
    monthPattern += 8;
  }
  if (monthPatternDays.includes('May')) {
    monthPattern += 16;
  }
  if (monthPatternDays.includes('June')) {
    monthPattern += 32;
  }
  if (monthPatternDays.includes('July')) {
    monthPattern += 64;
  }
  if (monthPatternDays.includes('August')) {
    monthPattern += 128;
  }
  if (monthPatternDays.includes('September')) {
    monthPattern += 256;
  }
  if (monthPatternDays.includes('October')) {
    monthPattern += 512;
  }
  if (monthPatternDays.includes('November')) {
    monthPattern += 1024;
  }
  if (monthPatternDays.includes('December')) {
    monthPattern += 2048;
  }
  return monthPattern;
}

export const refreshMapLayers = (mapSWID) => {
  if (window.MapInstance && window.MapInstance[mapSWID] && window.MapInstance[mapSWID].getLayers) {
    let layers = window.MapInstance[mapSWID].getLayers().getArray();
    layers.forEach(x => {
      try {
        x.getLayersArray().forEach(y => {
          y.getSource().refresh();
        })
      } catch { }
    });
  }
}

export const getElementStyleObject = (style) => {
  if (style.Bold) {
    delete style.Bold;
    style.fontWeight = 'bold';
  }

  Object.keys(style).forEach(key => {
    style[key.charAt(0).toLowerCase() + key.slice(1)] = style[key];
  });

  delete style.ElementStyleId;
  delete style.StyleName;
  return style;
}

export const actionJson = (actions) => {
  if (typeof actions === 'string') {
    try {
      actions = JSON.parse(actions);
    } catch (e) {
      console.error(e);
    }
  }
  actions.forEach(action => {
    aj[action.Type](action);
  });
}

let aj = {
  SetGobKey: (action) => {
    window._dispatch(actions.UpdateProp({
      Key: action.Key,
      Value: action.Value
    }));
  },
  SetScene: (action) => {
    window._dispatch(actions.UpdateProp({
      Key: action.Key,
      Value: {
        Value: action.Value,
        Enabled: true
      }
    }));
  },
  SaveRefresh: () => {
    window._dispatch(actions.UpdateProp({
      Key: 'dbo_SaveId',
      Value: getId(1234)
    }));
  },
  LoadEntity: (action) => {
    window._dispatch(actions.UpdateProp({
      Key: action.Key,
      Value: {
        EntityId: action.EntityId,
        EntityTypeId: action.EntityTypeId
      }
    }));
  },
  CloseEvent: () => {
    window._dispatch(actions.UpdateProp({
      Key: 'ent_TryCloseEvent',
      Value: true
    }));
  }
}

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}