import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let type = ownProps.widget.Parameters.find(x => x.ParameterName === 'Type'); //Options: 'Waggle', 'UB', etc

  return {
    Type: type ? type.ParameterValue : null,
    CurrentEvent: state.ent_CurrentEvent,
    EventSave: state.btn_EventSave
  };
};

export class EmailDropper extends React.PureComponent {
  state = {
    Subject: ''
  };

  dropEmail = (e) => {
    e.preventDefault();
    e.currentTarget.style.boxShadow = 'none';
    let emailData = e.dataTransfer.getData("text").split('\t');
    let from = emailData[5].substring(2);
    let subject = emailData[6];
    let formData = new FormData();

    let file;
    if (e.target.files) {
      file = e.target.files[0];
    } else if (e.dataTransfer.files) {
      file = e.dataTransfer.files[0];
    } else {
      return;
    }

    formData.append(file.name + '_msg', file, file.name);
    formData.append('FileName', file.name); // replace spaces with _ ?
    formData.append("From", from);
    formData.append("Subject", subject);
    this.setState({ Subject: subject });
    formData.append("Type", this.props.Type);

    if (file.name.split('.')[file.name.split('.').length - 1] !== 'msg') {
      return;
    }

    actions.ApiSendData('Document/SaveEmail', formData, (result) => {
      this.createEventUsingEmailResult(result.Response.InternetMessageId);
    });
  }

  createEventUsingEmailResult = (internetMessageId) => {
    //1.) Call new engine
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_NewEvent',
      Value: { EntityTypeId: 2001002 }
    }));
    //2.) Add save data for Subject, StatusLId=28714, Category1LId = 28718, InternetMessageId
    let baseObj = {
      Id: null,
      InsertKey: this.props.CurrentEvent.EntityId,
      Table: this.props.CurrentEvent.BaseTable,
      IsBaseTable: true,
      IsEventData: true,
      AutoGenerated: true
    }

    let saveData = [
      helpers.saveDataItem(baseObj, 'Subject', this.state.Subject),
      helpers.saveDataItem(baseObj, 'StatusLId', 28714),
      helpers.saveDataItem(baseObj, 'Category1LId', 28718),
      helpers.saveDataItem(baseObj, 'InternetMessageId', internetMessageId)
    ];

    saveData.forEach(sd => {
      this.props.dispatch(actions.AddSaveData(sd));
    });
    //3.) Call save engine
    this.props.dispatch(actions.UpdateProp({
      Key: 'btn_EventSave',
      Value: { ClickId: this.props.EventSave.ClickId + 1, Enabled: true }
    }));
  }

  onDragOver = (e) => {
    e.currentTarget.style.boxShadow = 'inset 0px -.5em 2em rgba(50, 50, 50, 0.3), inset 0px .5em 2em rgba(50, 50, 50, 0.3)';
    e.preventDefault();
  }

  onDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.style.boxShadow = 'none';
  }

  render() {
    return (
      <div className="email-dropper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40px" height="40px" onDrop={this.dropEmail} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave}>
          <path id="envelope-down" d="M4,22.17188V9.82812L10.17169,16ZM15.40015,18h1.17169a1.15255,1.15255,0,0,0,.70709-.293l4.76361-4.76367A8.00684,8.00684,0,0,1,18.06946,7H3.986l10.707,10.707A1.15257,1.15257,0,0,0,15.40015,18Zm3.00012,1.41418A2.3055,2.3055,0,0,1,16.986,20H14.986a2.30474,2.30474,0,0,1-1.41424-.58594l-1.99988-2L3.986,25h24l-7.58581-7.58594Zm5.67248-5.65881L21.82825,16,28,22.17188V13.73669a7.72074,7.72074,0,0,1-3.92725.01868ZM32,6a6,6,0,1,1-6-6A6,6,0,0,1,32,6ZM28.14789,6H27V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V6H23.85211a.5.5,0,0,0-.37787.82739l2.14789,2.47913a.5.5,0,0,0,.75574,0l2.14789-2.47913A.5.5,0,0,0,28.14789,6Z" />
        </svg>
      </div>
    );
  }
}

export default connect(mapStateToProps)(EmailDropper);