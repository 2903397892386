import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../Utils/Helpers';


const mapStateToProps = (state, ownProps) => {
  return {
  };
};

export class Tab extends React.PureComponent {

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  render() {
    return (
      this.props.Parent.state.Tab !== this.props.Name ? <></> :
        <>
          {this.props.children}
        </>
    );
  }
}

export default connect(mapStateToProps)(Tab);
