import React from 'react';
import { connect, batch } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';


const mapStateToProps = (state, ownProps) => {
  let masterScene = state.blu_MasterScene;

  if (state.FullEditMode === undefined)
    state.FullEditMode = false;

  return {
    Metadata: state.met_EntityMetadata,
    Scenes: state.blu_Scene,
    MasterScene: masterScene ? masterScene.Value : '',
    EditingScene: state.EditingScene,
    EditingWidget: state.EditingWidget,
    FullEditMode: state.FullEditMode
  };
};

export class TopBar extends React.PureComponent {
  state = {
    ActiveScene: null,
    ActiveWidget: null
  }

  componentDidMount() {
    if (!this.props.MasterScene || !this.props.Scenes)
      return;

    let scene = this.props.Scenes.find(x => x.Name === this.props.MasterScene);
    if (!scene)
      return;

    this.setActiveScene(scene);
  }

  setActiveWidget = (val) => {
    this.props.dispatch(actions.SetEditingWidget(val));
  }

  setActiveScene = (val) => {
    this.props.dispatch(actions.SetEditingScene(val));
    this.setActiveWidget(val.Widgets[0]);
  }

  toggleEditMode = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'FullEditMode',
      Value: !this.props.FullEditMode
    }));
  }

  render() {
    return (
      <div className="top-bar">
        <div className="top-bar-scenes">
          <div className="top-bar-label">Scenes</div>
          <div className="top-bar-item-container">
            {this.props.EditingScene &&
              this.props.Scenes.map((x) => (
                <div key={x.SceneId}
                  onClick={() => { this.setActiveScene(x) }}
                  className={"top-bar-item " + (x.SceneId === this.props.EditingScene.SceneId ? 'active' : '')}>
                  <div>{x.Name}</div>
                </div>
              ))}
          </div>
        </div>
        <div className="top-bar-widgets">
          <div className="top-bar-label">
            <div>Widgets</div>
            <div onClick={this.toggleEditMode}>Edit Mode</div>
          </div>
          <div className="top-bar-item-container">
            {this.props.EditingWidget &&
              this.props.EditingScene.Widgets.map((x) => (
                <div key={x.SceneWidgetId}
                  onClick={() => { this.setActiveWidget(x) }}
                  className={"top-bar-item " + (x.SceneWidgetId === this.props.EditingWidget.SceneWidgetId ? 'active' : '')}>
                  <div>{x.WidgetName}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(TopBar);