import React from 'react';
import { connect, batch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from '../../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../../Utils/Helpers';
import { MagnifyingGlass, RefreshArrow } from '../../../../../Icons/Icons';
import { isConsole } from 'react-device-detect';


const mapStateToProps = (state, ownProps) => {
  return {
    WidgetName: ownProps.Parent.props.WidgetName,
    EditSWID: state.EditingWidget ? state.EditingWidget.SceneWidgetId : null,
    EditorDetails: state.dbo_EditorDetails || {}
  };
};

export class GetDetails extends React.PureComponent {

  componentDidMount() {
    this.RegisterRefresh();
    this.refresh();
  }

  componentDidUpdate() {

  }

  RegisterRefresh = () => {
    this.props.Parent.setState({
      RefreshDetails: {
        ...this.props.Parent.state.RefreshDetails,
        [this.props.Type]: this.refresh
      }
    });
  }

  refresh = () => {
    let body = {
      Type: this.props.Type,
      SWID: this.props.EditSWID
    }

    if (this.props.Parameter) {
      body.Parameter = JSON.stringify(this.props.Parameter)
    }

    actions.ApiRequest('Editor/GetDetails', body, (result) => {
      let newDetails = cloneDeep(this.props.EditorDetails);

      if (!newDetails[this.props.WidgetName])
        newDetails[this.props.WidgetName] = {};

      if (!newDetails[this.props.WidgetName][this.props.EditSWID])
        newDetails[this.props.WidgetName][this.props.EditSWID] = {};

      if (!newDetails[this.props.WidgetName][this.props.EditSWID].Details)
        newDetails[this.props.WidgetName][this.props.EditSWID].Details = {};

      newDetails[this.props.WidgetName][this.props.EditSWID].Details[this.props.Type] = result;

      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_EditorDetails',
        Value: newDetails
      }));
    });
  }

  getDetails = () => {
    try {
      let rows = this.props.EditorDetails[this.props.WidgetName][this.props.EditSWID].Details[this.props.Type];
      let colKeys = Object.keys(rows[0]).filter(x => x !== 'SaveChanges' && x[0] !== '!');
      return {
        cols: colKeys,
        rows: rows
      }
    } catch {
      return ''
    }
  }

  cellChanged = (e, row, col) => {
    row[col] = e.target.value;
    row.SaveChanges = true;
    this.forceUpdate();
  }

  render() {
    let data = this.getDetails();
    return (
      !data || this.props.DataOnly ? null :
        <div className="editor-details">
          <div className="data-header">
            {data.cols.map((col, idx) => (
              <div key={idx} className="data-header-cell">
                <div>{col.replace('%', '')}</div>
              </div>
            ))}
          </div>
          <div className="data-rows">
            {data.rows.map((row, rIdx) => (
              <div key={rIdx} className="data-row">{
                data.cols.map((col, cIdx) => (
                  <div key={cIdx} className="data-cell" title={row[col]}>
                    {this.props.Editable && col[0] !== '%' ?
                      <input type="text" value={row[col]} onChange={(e) => { this.cellChanged(e, row, col) }} />
                      :
                      <div>{row[col]}</div>
                    }
                  </div>
                ))
              }</div>
            ))}
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(GetDetails);
