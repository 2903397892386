import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import * as actions from '../../../Stores/Actions/actions';
import * as helpers from '../../../Utils/Helpers';
import * as hub from './../../../Utils/PubSub';

const mapStateToProps = (state, ownProps) => {
  return {

  };
}

export class GuideLines extends React.PureComponent {
  componentDidMount() {

  }

  componentDidUpdate(prev) {

  }

  mouseOver = () => {
    console.log({ row: this.props.row, col: this.props.col });
    this.props.dispatch(actions.UpdateProp({
      Key: 'HoverCell',
      Value: { row: this.props.row, col: this.props.col }
    }));
  }

  render() {
    return (
      <div
        className={"guide-line"}
        style={{ gridColumn: this.props.col + 1, gridRow: this.props.row + 1 }}
        onMouseEnter={() => { this.mouseOver() }}
      >
        {this.props.col === 0 && <div className="col-marker">{this.props.rowName}</div>}
        {this.props.row === 0 && <div className="row-marker">{this.props.colName}</div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(GuideLines);





