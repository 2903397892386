
import React from 'react';

const UB_AcctStatus = props => {
  let style = props.card.Color ? { color: props.card.Color } : null;
  return (
    <React.Fragment>
      <div className="ub-acct-status">
        <div className="top-label">
          <div>{props.card.Label}</div>
        </div>
        <div className="total-amt">
          <div style={style}>{props.card.Text1}</div>
        </div>
        <div className="day-lbl">
          <div>{props.card.Text2}</div>
        </div>
        <div className="day-lbl-subtext">
          <div>{props.card.Text3}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UB_AcctStatus