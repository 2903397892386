
import React from 'react';
import { connect, batch } from 'react-redux';
import ReactDOM from 'react-dom';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import cloneDeep from 'lodash/cloneDeep';

export const LinkClicked = (props, e) => {
  let linkType = e.colDef.LinkType.toLowerCase();
  let entityId = e.data['!EntityId'];
  let entityTypeId = e.data['!EntityTypeId'];

  if (!linkType)
    return;

  let body = {
    EntityId: Number(entityId),
    EntityTypeId: Number(entityTypeId)
  };

  if (linkType === 'event' || linkType === 'asset') {
    entityLink(props, body, linkType);
  }

  if (linkType === 'ub_service') {
    openService(props, e);
  }

  if (linkType === 'ub_event') {
    openUBEvent(props, e);
  }

  if (linkType === 'ub_contact') {
    openUBContact(props, e);
  }

  if (linkType === 'quickedit') {
    quickEdit(props, body, e);
  }

  if (props.SelectedQuery.LinkActionJson) {
    doActionJson(props, e);
  }
}

const doActionJson = (props, e) => {

  let linkActionJson = props.SelectedQuery.LinkActionJson;

  props.SelectedQuery.Layout.Columns.forEach(col => {
    let replaceVal = e.data[col.ColumnName];
    if (typeof replaceVal === 'object') {
      replaceVal = replaceVal.raw;
    }
    linkActionJson = linkActionJson.replace(new RegExp('@' + col.Label, 'g'), replaceVal);
  });

  try {
    let actionJson = JSON.parse(linkActionJson);
    helpers.actionJson(actionJson[e.colDef.LinkType]);
  } catch (e) {
    console.error({
      ErrorParsingActionJson: e,
      ActionJson: linkActionJson
    })
  }
}

const quickEdit = (props, body, e) => {
  let dispatch = props.dispatch;
  let curEntity = props.CurrentEntity;
  let selectedEntity = props.SelectedEntity;

  actions.ApiRequest('Entity/GetEntity', body, (result) => {
    batch(() => {
      let paramVal = cloneDeep(body);
      dispatch(actions.UpdateProp({
        Key: 'ent_Current_old',
        Value: curEntity
      }));
      dispatch(actions.UpdateProp({
        Key: 'ent_Selected_old',
        Value: selectedEntity
      }));

      paramVal = {
        EntityId: result.Parent.EntityId,
        EntityTypeId: result.Parent.EntityTypeId
      }

      dispatch(actions.UpdateProp({
        Key: 'ent_Selected',
        Value: { ...paramVal, PreventSceneSwitch: true }
      }));

      setTimeout(() => {
        if (result && result.Parent && result.Parent.EntityId) {
          let selectedEventVal = cloneDeep(body);

          dispatch(actions.UpdateProp({
            Key: 'ent_SelectedEvent',
            Value: selectedEventVal
          }));
        }
      }, 0);
    });
  });
}

const openUBContact = (props, e) => {
  let dispatch = props.dispatch;
  let assetSceneKey = props.AssetSceneKey;
  let assetScene = props.SelectedQuery.RowClickScene || props.AssetScene;
  batch(() => {
    dispatch(actions.UpdateProp({
      Key: assetSceneKey,
      Value: {
        Value: assetScene,
        Enabled: true
      }
    }));

    console.log(e.data);

    dispatch(actions.UpdateProp({
      Key: 'ent_Selected',
      Value: {
        EntityId: Number(e.data['!AcctId']),
        EntityTypeId: Number(e.data['!AcctTypeId'])
      }
    }));

    dispatch(actions.UpdateProp({
      Key: 'ent_SelectedEvent',
      Value: {
        EntityId: Number(e.data['!EntityId']),
        EntityTypeId: Number(e.data['!EntityTypeId'])
      }
    }));

    dispatch(actions.UpdateProp({
      Key: "SelectedAccountTab",
      Value: {
        Value: "AccountProfile",
        Enabled: true
      }
    }));
  })
}

const openUBEvent = (props, e) => {
  let dispatch = props.dispatch;
  let assetSceneKey = props.AssetSceneKey;
  let assetScene = props.SelectedQuery.RowClickScene || props.AssetScene;
  let eventTypeId = Number(e.data['!LinkEntity2TypeId']);
  let quickEditMode = eventTypeId === 2023001 && !Number(e.data['!LinkEntityId']);

  batch(() => {
    if (!quickEditMode) {
      dispatch(actions.UpdateProp({
        Key: assetSceneKey,
        Value: {
          Value: assetScene,
          Enabled: true
        }
      }));
      dispatch(actions.UpdateProp({
        Key: 'ent_Selected',
        Value: {
          EntityId: Number(e.data['!LinkEntityId']),
          EntityTypeId: Number(e.data['!LinkEntityTypeId'])
        }
      }));
      dispatch(actions.UpdateProp({
        Key: "SelectedAccountTab",
        Value: {
          Value: "AccountProfile",
          Enabled: true
        }
      }));
    }

    dispatch(actions.UpdateProp({
      Key: 'ent_SelectedEvent',
      Value: {
        EntityId: Number(e.data['!LinkEntity2Id']),
        EntityTypeId: eventTypeId
      }
    }));
  });
}

const openService = (props, e) => {
  let dispatch = props.dispatch;
  let assetSceneKey = props.AssetSceneKey;
  let assetScene = props.SelectedQuery.RowClickScene || props.AssetScene;

  batch(() => {
    dispatch(actions.UpdateProp({
      Key: assetSceneKey,
      Value: {
        Value: assetScene,
        Enabled: true
      }
    }));

    dispatch(actions.UpdateProp({
      Key: "SelectedAccountTab",
      Value: {
        Value: "AccountService",
        Enabled: true
      }
    }));

    dispatch(actions.UpdateProp({
      Key: 'ent_Selected',
      Value: {
        EntityId: Number(e.data['!LinkEntityId']),
        EntityTypeId: Number(e.data['!LinkEntityTypeId'])
      }
    }));

    if (e.data['!ServiceLinkId']) {
      dispatch(actions.UpdateProp({
        Key: 'PreventAutoLoad',
        Value: true
      }));

      dispatch(actions.UpdateProp({
        Key: 'ent_SelectedService',
        Value: {
          EntityId: Number(e.data['!ServiceLinkId']),
          EntityTypeId: Number(e.data['!ServiceLinkTypeId'])
        }
      }));
    }
  });
}

const entityLink = (props, body, linkType) => {
  let dispatch = props.dispatch;
  let assetSceneKey = props.AssetSceneKey;
  let assetScene = props.SelectedQuery.RowClickScene || props.AssetScene;

  actions.ApiRequest('Entity/GetEntity', body, (result) => {
    batch(() => {
      let paramVal = cloneDeep(body);

      if (result && result.Parent && result.Parent.EntityId) {
        paramVal = {
          EntityId: result.Parent.EntityId,
          EntityTypeId: result.Parent.EntityTypeId
        }

        let selectedEventVal = linkType === 'event' ? cloneDeep(body) : null;

        dispatch(actions.UpdateProp({
          Key: 'ent_SelectedEvent',
          Value: selectedEventVal
        }));
      }

      if (assetScene === 'Initial_Site') { //ohio specific
        dispatch(actions.UpdateProp({
          Key: 'ent_SiteSelected',
          Value: paramVal
        }));
      } else {
        dispatch(actions.UpdateProp({
          Key: 'ent_Selected',
          Value: paramVal
        }));
      }

      dispatch(actions.UpdateProp({
        Key: assetSceneKey,
        Value: {
          Value: assetScene,
          Enabled: true
        }
      }));

      if (props.SubAssetSceneKey && props.SubAssetScene) {
        dispatch(actions.UpdateProp({
          Key: props.SubAssetSceneKey,
          Value: {
            Value: props.SubAssetScene,
            Enabled: true
          }
        }));
      }
    });
  });
}


  // this.props.dispatch(actions.UpdateProp({
  //   Key: 'dbo_BeforeSaveId',
  //   Value: (this.props.BeforeSaveId || 0) + 1
  // }));
