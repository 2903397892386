import React from 'react';
import { connect, batch } from 'react-redux';
import SnapGuides from 'ol-ext/interaction/SnapGuides';
import bhModifyTouch from './bhModifyTouch';
import ModifyTouch from 'ol-ext/interaction/ModifyTouch';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from 'ol/style';
import { Text, RegularShape } from 'ol/style';
import { LineString, MultiPoint, Polygon, Point, Circle, MultiLineString, MultiPolygon } from 'ol/geom';
import { Layer, Vector as LayerVector } from 'ol/layer'
import { Source, Vector as SourceVector } from 'ol/source';
import { Draw, Modify, Snap } from 'ol/interaction';
import Overlay from 'ol/Overlay';
import { getArea, getLength } from 'ol/sphere';
import clickZoom from 'ol/interaction/DoubleClickZoom';
import * as actions from '../../../../../Stores/Actions/actions';
import { WKT } from 'ol/format';
import { buffer, getCenter } from 'ol/extent';
import { inAndOut } from 'ol/easing';
import { Collection, Feature } from 'ol';
import { fromLonLat } from 'ol/proj';
import * as helpers from '../../../../../Utils/Helpers';
import { YellowCrosshair } from '../../../../Icons/Icons';
import TileLayer from 'ol/layer/Tile';
import { Graticule, View } from 'ol';
import cloneDeep from 'lodash/cloneDeep';
import { fromExtent } from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ReactGA from "react-ga4";
import {never} from 'ol/events/condition.js';

const mapStateToProps = (state) => {
  return {
    OriginalModifyId: state.ms_OriginalModifyId,
    UserLoc: state.UserLocation,
    StartDrawing: state.ms_StartDrawing,
    DrawingActive: state.ms_DrawingActive,
    ModifyActive: state.ms_ModifyActive,
    SelectedPolygons: state.ms_SelectedPolygons,
    DeleteFeature: state.ms_DeleteFeature,
    SelectedImageGrouping: state.ms_SelectedImageGrouping,
    HomeSelected: state.map_MoveToLocation,
    ActiveModifyId: state.ms_ActiveModifyId,
    MapAction: state.ms_MySalesMapAction,
    RestartSelection: state.ms_RestartSelection,
    ModalActive: state.ms_ModalActive,
    FirstDrawCompleted: state.ms_FirstDrawCompleted,
    FirstGateCompleted: state.ms_FirstGateCompleted,
    AddGateModal: state.ms_addGateModal,
    PolygonHovered: state.ms_PolygonHovered,
    PolygonHoverExit: state.ms_PolygonHoverExit,
    ActiveConsumerId: state.ms_ActiveConsumerId,
    Crosshair: state.ms_MapCrosshair,
    SaveId: state.dbo_SaveId,
    CurrentMeasure: state.ms_currentMeasure,
    EntityMetadata: state.met_EntityMetadata,
    BlankCanvas: state.ms_BlankCanvas,
    FinishPanel3Check: state.ms_FinishPanel3Check,
    EditFenceRemoval: state.ms_EditFenceRemoval,
    EditRailingStain: state.ms_EditRailingStain,
    PanelContent: state.PanelContent,
    MainTabContent: state.MainTabContent,
    CurrentContractor: state.ent_CurrentContractor,
    ItemCardlist: state.ms_AddAnItem,
    MapOptions: state.ms_MapOptions,
    GridCheckbox: state.Controls ? state.Controls.GridCheckbox : null,
    AddGateButton: state.btn_AddGate,
    ContinueWithoutGateButton: state.btn_ContinueWithoutGate,
    UpdateTotalEstimate: state.ms_UpdateTotalEstimate,
    NextMapAction: state.ms_MySalesMapActionNext,
    SendToSummary: state.ms_SendToSummary,
    ContractorInitialized: state.ms_ContractorInitialized,
    ContactLast: state.ms_ContactLast,
    SendToSummaryOverride: state.ms_SendToSummaryOverride,
    SelectedEntity: state.ent_Selected,
    UseMetric: state.ms_UseMetric,
    ConsumerLoaded: state.ms_ConsumerLoaded,
    CreatedNew: state.ms_CreatedNew,
    currentUserInfo: state.ent_Current ? state.ent_Current.Properties : null,
    StairQuantity: state.ms_StairQuantity ? state.ms_StairQuantity : 1,
    CatalogPolygon: state.ms_CatalogPoly ? state.ms_CatalogPoly : null,
    gtagPropsSet: state.gtag_PropsSet ? state.gtag_PropsSet : false,
    Integrations: state.ent_Integrations ? state.ent_Integrations : null
  };
}

const activeStyle = [new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new Stroke({
    color: 'rgba(46, 41, 0, 1)',
    width: 6,
  })
}),
new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      width: 2,
      color: 'black',
    }),
    fill: new Fill({
      color: 'rgb(255, 190, 10)',
    }),
  }),
  geometry: (feature) => {
    let coordinates = feature.getGeometry().getCoordinates();
    if (feature.geometry === 'POLYGON') {
      coordinates = coordinates[0];
    }
    if (Array.isArray(coordinates[0][0])) { //Give MultiLineString vertex points
      let rawCoords = [];
      coordinates.forEach(c => {
        c.forEach(x => {
          rawCoords.push(x);
        })
      })
      return new MultiPoint(rawCoords);
    }
    return new MultiPoint(coordinates);
  }
})
  , new Style({
    type: 'LineString',
    stroke: new Stroke({
      color: 'rgba(255, 190, 10, 0.9)',
      width: 3,
    }),
  })];

const inactiveStyle = [new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new Stroke({
    color: 'black',
    width: 6,
  })
}),
new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      width: 2,
      color: 'black',
    }),
    fill: new Fill({
      color: 'lightblue',
    }),
  }),
  geometry: (feature) => {
    let coordinates = feature.getGeometry().getCoordinates();
    if (feature.geometry === 'POLYGON') {
      coordinates = coordinates[0];
    }
    if (Array.isArray(coordinates[0][0])) { //Give MultiLineString vertex points
      let rawCoords = [];
      coordinates.forEach(c => {
        c.forEach(x => {
          rawCoords.push(x);
        })
      })
      return new MultiPoint(rawCoords);
    }
    return new MultiPoint(coordinates);
  }
}), new Style({
  type: 'LineString',
  stroke: new Stroke({
    color: 'lightblue',
    width: 3,
  }),
})];

export class MySalesmanMapController extends React.PureComponent {
  state = {
    Guidelines: window.IsMobile ? false : true,
    addGateModal: false,
    helptextModal: false,
    measureUpdate: 0
  }

  componentDidMount() {
    if (this.props.SelectedPolygons) {
      this.setTotalEstimate();
    }
    document.addEventListener('keydown', this.keyDown);
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = this.backButton;
    if (this.props.AddGateModal) {
      this.setGobKey('ms_addGateModal', null);
      this.setState({ addGateModal: true });
    }

    //Hide CurrentEntityLayer so that Consumer's geometry doesn't show on the map
    if (this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer')) {
      this.props.map.getLayers().getArray().find(x => x.Name === 'CurrentEntityLayer').setVisible(false);
    }

    if (this.props.ContactLast && this.props.ActiveConsumerId && (this.props.SelectedEntity.EntityId != this.props.ActiveConsumerId)) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_Selected',
        Value: { EntityId: this.props.ActiveConsumerId, EntityTypeId: 2040 }
      }))
    }

    //Map loading takes focus from autofocused textbox, refocus after timeout
    let firstTextbox = document.getElementById('control-7490');
    if (firstTextbox) {
      setTimeout(() => {
        firstTextbox.focus();
      }, 750);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
  }

  keyDown = (e) => {
    if (e.key === 'Enter') {
      let gateInput = document.getElementById('submit-fence-removal');
      if (gateInput) {
        gateInput.click();
      }
    }
  }

  backButton = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.href);
    window.history.go(1);
    if (this.props.DrawingActive || this.props.ModifyActive || this.props.MainTabContent.Value === 'MySalesmanImageGrouping') {
      return;
    }

    if (window.IsMobile) {
      /*if (this.props.MainTabContent.Value === 'MySalesmanCompleted') {
        this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
      } else*/ if (this.props.MainTabContent.Value === 'Step2Panel') {
        this.setGobKey('MainTabContent', { Value: 'Step1Panel', Enabled: true });
      } else if (this.props.MainTabContent.Value === 'Step3Panel') {
        this.restartSelection();
      }
    } else {
      /*if (this.props.MainTabContent.Value === 'MySalesmanCompleted') {
        this.setGobKey('MainTabContent', { Value: 'TabOneScene', Enabled: true })
      } else*/ if (this.props.PanelContent.Value === 'Step2Panel') {
        this.setGobKey('PanelContent', { Value: 'Step1Panel', Enabled: true });
      } else if (this.props.PanelContent.Value === 'Step3Panel') {
        this.restartSelection();
      }
    }
  }

  componentDidUpdate(prev) {
    //google tag stuff
    if(!this.props.gtagPropsSet) {
      const integrations = this.props.Integrations
      if(integrations) {
        const googleTag = integrations.find(e => e.IntegrationTypeId === 2094)
        if(googleTag && googleTag.Value1 && googleTag.Value1 !== "") {
          ReactGA.initialize(googleTag.Value1);
        }
        ReactGA.initialize("G-FLTL2XD3WM");
        this.setGobKey('gtag_PropsSet', true)
      }
    }
    //save from image catalog
    if (this.props.CatalogPolygon) {
      this.savePlan(this.props.CatalogPolygon)
      this.setGobKey('ms_CatalogPoly', null)
    }
    //restart process / clear geos and home
    if (this.props.RestartSelection) {
      this.restartSelection();
    }
    //deleting key
    if (this.props.DeleteFeature) {
      this.deleteFeature(this.props.DeleteFeature.openlayersId);
    }
    //modify key
    if (this.props.ModifyActive && this.props.ModifyActive.Id) {
      this.modifyEnabled(this.props.ModifyActive.Active, this.props.ModifyActive.Id);
    }
    //show home icon
    if (this.props.HomeSelected && this.props.HomeSelected !== prev.HomeSelected) {
      this.homeSelectedMapMarker();
    }
    //blank canvas selected
    if (this.props.BlankCanvas) {
      this.setUpBlankCanvas();
    }
    //set gobkey with total estimate
    if (this.props.SelectedPolygons && JSON.stringify(this.props.SelectedPolygons) !== JSON.stringify(prev.SelectedPolygons)) {
      this.setTotalEstimate();
    }
    if (this.props.UpdateTotalEstimate) {
      this.setTotalEstimate();
      this.setGobKey('ms_UpdateTotalEstimate', null);
    }
    //MapAction given from cardlist item selected
    if ((this.props.MapAction && !prev.MapAction) || this.props.MapAction && this.props.MapAction.Value && prev.MapAction && prev.MapAction.Value != this.props.MapAction.Value) {
      //allow users to add gate during map action of fence
      if (this.props.MapAction.EntityTypeId == 2059 && prev.MapAction && prev.MapAction.EntityTypeId == 2058) {
        this.setGobKey('ms_MySalesMapActionNext', prev.MapAction);
      }
      //allow users to add stairs during map action of deck
      if (this.props.MapAction.EntityTypeId == 2064 && prev.MapAction && prev.MapAction.EntityTypeId == 2062) {
        this.setGobKey('ms_MySalesMapActionNext', prev.MapAction)
      }
      //allow users to add stairs during map action of deck stain
      if (this.props.MapAction.EntityTypeId == 2066 && prev.MapAction && prev.MapAction.EntityTypeId == 2065) {
        this.setGobKey('ms_MySalesMapActionNext', prev.MapAction)
      }
      this.startDraw(this.props.MapAction);
    }
    //Outline polygon which has been mouse-overed in MySalesGeo card cardlist
    if (this.props.PolygonHovered) {
      this.polygonHovered();
    }
    //De-highlight on mouse exit for polygon style hovers
    if (this.props.PolygonHoverExit) {
      this.polygonHoverExit();
    }
    //Finish check panel 3 / Check if the user has a gate added if they also have a fence and prompt gate or send to summary
    if (this.props.FinishPanel3Check) {
      this.finishPanel3Check();
    }
    //Edit fence removal
    if (this.props.EditFenceRemoval) {
      this.editFenceRemoval();
    }
    //Edit railing stain
    if(this.props.EditRailingStain) {
      this.editRailingStain();
    }
    //Check for banner color and set it
    if (this.props.CurrentContractor && !this.props.ContractorInitialized) {
      this.initializeForContractor();
    }
    //Check for grid checkbox to toggle grid overlay
    if (this.props.GridCheckbox !== prev.GridCheckbox) {
      this.enableGrid(this.props.GridCheckbox);
    }
    //Check for add gate button from modal
    if (this.props.AddGateButton && this.props.AddGateButton.ClickId !== prev.AddGateButton.ClickId) {
      let addGateCardlistItem = this.props.ItemCardlist.find(x => x.EntityTypeId == 2059);
      this.setGobKey('ms_MySalesMapAction', addGateCardlistItem);
      // this.startDraw(addGateCardlistItem);
    }
    //Check for continue anyway button from modal reminder about not having a gate
    if (this.props.ContinueWithoutGateButton && this.props.ContinueWithoutGateButton.ClickId !== prev.ContinueWithoutGateButton.ClickId) {
      this.sendToSummary();
    }
    //Send to summary from scriptrunner
    if (this.props.SendToSummary) {
      this.setGobKey('ms_SendToSummary', null);
      this.sendToSummary();
    }
    //Check if the consumer data has been loaded (used when consumerId in URL)
    if (this.props.ConsumerLoaded) {
      this.setGobKey('ms_ConsumerLoaded', null);
      this.consumerLoaded();
    }
  }

  setGobKey = (keyName, keyValue) => {
    this.props.dispatch(actions.UpdateProp({
      Key: keyName,
      Value: keyValue
    }))
  }

  loadExistingConsumer = (consumerId) => {
    this.setGobKey('ms_ActiveConsumerId', consumerId);
    this.setGobKey('sr_MV_GetConsumer', true);
  }

  initializeForContractor = () => {
    let banner = document.getElementsByClassName('mysalesman-banner')[0];
    const status = this.props.CurrentContractor.Properties.find(x => x.Name === 'StatusLId')
    if (banner && this.props.CurrentContractor.Properties.find(x => x.Name === 'BannerColor').Value && status && `${status.Value}` !== '29228') {
      let backgroundColor = this.props.CurrentContractor.Properties.find(x => x.Name === 'BannerColor').Value;
      let headerText = document.getElementsByClassName('contractor-header')[0];
      if(headerText && this.props.CurrentContractor.Properties.find(x => x.Name === 'TextColor').Value) {
        let textColor = this.props.CurrentContractor.Properties.find(x => x.Name === 'TextColor').Value
        headerText.style.color = textColor;
      } else if(headerText) {
        headerText.style.color = '#F5F5F5';
      }
      banner.style.backgroundColor = backgroundColor;
      this.setState({ bannerColorSet: true });

      // if consumerid provided, load MySalesmanMapping and correctly set cardlist / map to that consumer's //plans
      let consumerGiven = new URLSearchParams(window.location.search).get('consumerId');
      if (consumerGiven) {
        this.loadExistingConsumer(consumerGiven)
      }

      // check and set if we use metric system
      let useMetric = this.props.CurrentContractor.Properties.find(x => x.Name === 'UseMetric').Value;
      useMetric = (useMetric == null || useMetric == 0) ? false : true;
      this.setGobKey('ms_UseMetric', useMetric);

      // check and set if phone number is required ms_RequirePhone
      let requirePhone = this.props.CurrentContractor.Properties.find(x => x.Name === 'RequirePhone').Value;
      if (requirePhone == 1) {
        this.setGobKey('ms_RequirePhone', true);
      }

      // check if we should show contact form or address search first
      let requireInfo = this.props.CurrentContractor.Properties.find(x => x.Name === 'RequireContact').Value;
      if (requireInfo == 0) { //change to address search (make consumer exist)
        this.setGobKey('ms_ContactLast', true);
        if (window.IsMobile) {
          this.setGobKey('MainTabContent', { Value: 'Step2Panel', Enabled: true });
        } else {
          this.setGobKey('PanelContent', { Value: 'Step2Panel', Enabled: true });
        }
      }
      this.setGobKey('ms_ContractorInitialized', true);
      this.setGobKey('HaltSiteInit', false);
    }
  }

  consumerLoaded = (polys) => {
    //make measurement layer, gate layers
    let vector = this.getMeasurementVector();
    let gateVector = new SourceVector();
    let stairVector = new SourceVector();
    let svgLightBlue = '<svg width="80" height="80" fill="lightblue" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z"/></svg>';
    let stairSVGLightBlue = '<svg width="100px" height="100px" fill="lightblue" viewBox="0 0 2.4 2.4" xmlns="http://www.w3.org/2000/svg"><path d="M1.8 0.6h-0.3v0.3h-0.3v0.3h-0.299v0.3h-0.3v0.3H0.3v0.3h1.8V0.3h-0.3zM1.9 0.4h0.1v1.6H0.4v-0.1h0.3v-0.3h0.3v-0.3h0.3v-0.3h0.3v-0.299h0.3z"/><path fill="none" d="M0 0h2.4v2.4H0z"/></svg>';

    let gateLayer = new LayerVector({
      renderMode: 'vector',
      visible: true,
      source: gateVector,
      updateWhileInteracting: true
    })

    let stairLayer = new LayerVector({
      renderMode: 'vector',
      visible: true,
      source: stairVector,
      updateWhileInteracting: true
    })

    gateLayer.setZIndex(50);
    gateLayer.LayerId = 'Gate';
    gateLayer.Vector = gateVector;
    this.props.map.addLayer(gateLayer);

    stairLayer.setZIndex(50);
    stairLayer.LayerId = 'Stair';
    stairLayer.Vector = stairVector;
    this.props.map.addLayer(stairLayer)

    this.createModTouch(gateVector);
    this.createStairModTouch(stairVector)

    //add plans to the map
    let format = new WKT();
    let newFeature;
    let geometry;
    let extent;


    //loop through 
    let selectedPolygons = []
    if(polys && polys.length > 0) {
      selectedPolygons = polys
    } else {
      selectedPolygons = this.props.SelectedPolygons.slice();
    }
    selectedPolygons.forEach(x => {
      if (!x.Geometry) { return; }
      newFeature = format.readFeature(x.Geometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });
      geometry = newFeature.getGeometry();
      // newFeature.geometry = "POLYGON";
      switch (x.EntityTypeId) {
        case 2066:
          //Set deck stain stair style
          var quantity = x.Text2.split(" ")
          newFeature.setStyle(new Style({
            image: new Icon({
              opacity: 1,
              src: 'data:image/svg+xml;utf8,' + stairSVGLightBlue,
              scale: 0.4,
            }),
            text: new Text({
              font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
              fill: new Fill({
                color: 'white',
              }),
              stroke: new Stroke({
                color: 'black',
                width: 2,
              }),
              offsetY: 3,
              text: quantity[0]
            })
          }))
          newFeature.geometry = "POINT";
          x.ol_uid = newFeature.ol_uid;
          stairVector.addFeature(newFeature);
          break;
        case 2064:
          //Set deck stair style
          var quantity = x.Text2.split(" ")
          newFeature.setStyle(new Style({
            image: new Icon({
              opacity: 1,
              src: 'data:image/svg+xml;utf8,' + stairSVGLightBlue,
              scale: 0.4,
            }),
            text: new Text({
              font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
              fill: new Fill({
                color: 'white',
              }),
              stroke: new Stroke({
                color: 'black',
                width: 2,
              }),
              offsetY: 3,
              text: quantity[0]
            })
          }))
          newFeature.geometry = "POINT";
          x.ol_uid = newFeature.ol_uid;
          stairVector.addFeature(newFeature);
          break;
        case 2059:
          //Set gate style
          newFeature.setStyle(new Style({
            image: new Icon({
              opacity: 1,
              src: 'data:image/svg+xml;utf8,' + svgLightBlue,
              scale: 0.4,
            }),
            text: new Text({
              font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
              fill: new Fill({
                color: 'white',
              }),
              stroke: new Stroke({
                color: 'black',
                width: 2,
              }),
              offsetY: 3,
              text: x.Text2.includes('Single') ? 'S' : 'D'
            })
          }))
          newFeature.geometry = "POINT";
          x.ol_uid = newFeature.ol_uid;
          gateVector.addFeature(newFeature);
          break;
        case 2058:
          //fence
          x.ol_uid = newFeature.ol_uid;
          x.RawCoords = newFeature.getGeometry().getCoordinates();
          newFeature.geometry = "MULTILINESTRING";
          if (geometry instanceof MultiLineString || geometry instanceof LineString) {
            let measureTooltip;
            let measureTooltipElement;
            let createMeasureTooltip = () => {
              measureTooltipElement = document.createElement('div');
              measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
              measureTooltipElement.ol_uid = newFeature.ol_uid;
              measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center',
                className: 'ol-clickthrough'
              });
              this.props.map.addOverlay(measureTooltip);
            }

            let allCoords = geometry.getCoordinates();
            allCoords.forEach(linestring => {
              linestring.forEach((point, index) => {
                if (!linestring[index + 1]) { return; }
                let line = new LineString([linestring[index], linestring[index + 1]]);

                createMeasureTooltip();
                let tooltipCoord = line.getCoordinateAt(0.5);
                measureTooltipElement.innerHTML = this.formatLength(line);
                measureTooltip.setPosition(tooltipCoord);
              })
            })

            geometry.on('change', (evt) => {
              if (newFeature.ol_uid != x.ol_uid) { //ol_uid changes after adding feature to the map
                this.clearTooltipsByOlUid(x.ol_uid);
                let selectedPolygonsReset = this.props.SelectedPolygons.slice();
                selectedPolygonsReset[selectedPolygons.indexOf(x)].ol_uid = newFeature.ol_uid;
                this.setGobKey('ms_SelectedPolygons', selectedPolygonsReset);
              }

              //add new measurement based on each segment
              this.clearTooltipsByOlUid(newFeature.ol_uid);
              let allCoords = evt.target.getCoordinates();
              allCoords.forEach(linestring => {
                linestring.forEach((point, index) => {
                  if (!linestring[index + 1]) { return; }
                  let line = new LineString([linestring[index], linestring[index + 1]]);

                  createMeasureTooltip();
                  let tooltipCoord = line.getCoordinateAt(0.5);
                  measureTooltipElement.innerHTML = this.formatLength(line);
                  measureTooltip.setPosition(tooltipCoord);
                })
              })
            });
            vector.addFeature(newFeature);
            extent = newFeature.getGeometry().getExtent();
            this.props.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
          }
          break;
        case 2062:
          //deck
          x.ol_uid = newFeature.ol_uid;
          x.RawCoords = newFeature.getGeometry().getCoordinates();
          newFeature.geometry = "POLYGON";
          if (geometry instanceof Polygon) {
            let measureTooltip;
            let measureTooltipElement;
            let createMeasureTooltip = () => {
              measureTooltipElement = document.createElement('div');
              measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
              measureTooltipElement.ol_uid = newFeature.ol_uid;
              measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center',
                className: 'ol-clickthrough'
              });
              this.props.map.addOverlay(measureTooltip);
            }

            createMeasureTooltip()
            
            let output = this.formatArea(geometry);
            let tooltipCoord = getCenter(geometry.getExtent());
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
  
            geometry.on('change', (evt) => {
              let geom = evt.target;
              measureTooltipElement.innerHTML = this.formatArea(geom);
              measureTooltip.setPosition(geom.getInteriorPoint().getCoordinates());
            });
            vector.addFeature(newFeature);
            extent = newFeature.getGeometry().getExtent();
            this.props.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
          }
          break;
        case 2065:
          //deck stain
          x.ol_uid = newFeature.ol_uid;
          x.RawCoords = newFeature.getGeometry().getCoordinates();
          newFeature.geometry = "POLYGON";
          if (geometry instanceof Polygon) {
            var measureTooltip;
            var measureTooltipElement;
            let createMeasureTooltip = () => {
              measureTooltipElement = document.createElement('div');
              measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
              measureTooltipElement.ol_uid = newFeature.ol_uid;
              measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center',
                className: 'ol-clickthrough'
              });
              this.props.map.addOverlay(measureTooltip);
            }

            createMeasureTooltip()
            
            let output = this.formatArea(geometry);
            let tooltipCoord = getCenter(geometry.getExtent());
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
  
            geometry.on('change', (evt) => {
              let geom = evt.target;
              measureTooltipElement.innerHTML = this.formatArea(geom);
              measureTooltip.setPosition(geom.getInteriorPoint().getCoordinates());
            });
            vector.addFeature(newFeature);
            extent = newFeature.getGeometry().getExtent();
            this.props.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
          }
          break;
        case 2053:
          //pwash area
          x.ol_uid = newFeature.ol_uid;
          x.RawCoords = newFeature.getGeometry().getCoordinates();
          newFeature.geometry = "POLYGON";
          if (geometry instanceof Polygon) {
            let measureTooltip;
            let measureTooltipElement;
            let createMeasureTooltip = () => {
              measureTooltipElement = document.createElement('div');
              measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
              measureTooltipElement.ol_uid = newFeature.ol_uid;
              measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center',
                className: 'ol-clickthrough'
              });
              this.props.map.addOverlay(measureTooltip);
            }
  
            createMeasureTooltip()
              
            let output = this.formatArea(geometry);
            let tooltipCoord = getCenter(geometry.getExtent())
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
    
            geometry.on('change', (evt) => {
              let geom = evt.target;
              measureTooltipElement.innerHTML = this.formatArea(geom);
              measureTooltip.setPosition(geom.getInteriorPoint().getCoordinates());
            });
            vector.addFeature(newFeature);
            extent = newFeature.getGeometry().getExtent();
            this.props.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
          }
          break;
        case 2055:
          //pwash roof
          x.ol_uid = newFeature.ol_uid;
          x.RawCoords = newFeature.getGeometry().getCoordinates();
          newFeature.geometry = "POLYGON";
          if (geometry instanceof Polygon) {
            let measureTooltip;
            let measureTooltipElement;
            let createMeasureTooltip = () => {
              measureTooltipElement = document.createElement('div');
              measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
              measureTooltipElement.ol_uid = newFeature.ol_uid;
              measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center',
                className: 'ol-clickthrough'
              });
              this.props.map.addOverlay(measureTooltip);
            }
    
            createMeasureTooltip()
                
            let output = this.formatArea(geometry);
            let tooltipCoord = getCenter(geometry.getExtent());
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
      
            geometry.on('change', (evt) => {
              let geom = evt.target;
              measureTooltipElement.innerHTML = this.formatArea(geom);
              measureTooltip.setPosition(geom.getInteriorPoint().getCoordinates());
            });
            vector.addFeature(newFeature);
            extent = newFeature.getGeometry().getExtent();
            this.props.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
          }
          break;
        case 2068:
          //roof
          x.ol_uid = newFeature.ol_uid;
          x.RawCoords = newFeature.getGeometry().getCoordinates();
          newFeature.geometry = "POLYGON";
          if (geometry instanceof Polygon) {
            let measureTooltip;
            let measureTooltipElement;
            let createMeasureTooltip = () => {
              measureTooltipElement = document.createElement('div');
              measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
              measureTooltipElement.ol_uid = newFeature.ol_uid;
              measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center',
                className: 'ol-clickthrough'
              });
              this.props.map.addOverlay(measureTooltip);
            }
    
            createMeasureTooltip()
                
            let output = this.formatArea(geometry);
            let tooltipCoord = getCenter(geometry.getExtent());
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
      
            geometry.on('change', (evt) => {
              let geom = evt.target;
              measureTooltipElement.innerHTML = this.formatArea(geom);
              measureTooltip.setPosition(geom.getInteriorPoint().getCoordinates());
            });
            vector.addFeature(newFeature);
            extent = newFeature.getGeometry().getExtent();
            this.props.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
          }
          break;
        case 2054:
          //pwash vertical
          x.ol_uid = newFeature.ol_uid;
          x.RawCoords = newFeature.getGeometry().getCoordinates();
          newFeature.geometry = "MULTILINESTRING";
          if (geometry instanceof MultiLineString) {
            let measureTooltip;
            let measureTooltipElement;
            let createMeasureTooltip = () => {
              measureTooltipElement = document.createElement('div');
              measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
              measureTooltipElement.ol_uid = newFeature.ol_uid;
              measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center',
                className: 'ol-clickthrough'
              });
              this.props.map.addOverlay(measureTooltip);
            }
  
            let allCoords = geometry.getCoordinates();
            allCoords.forEach(linestring => {
              linestring.forEach((point, index) => {
                if (!linestring[index + 1]) { return; }
                let line = new LineString([linestring[index], linestring[index + 1]]);
  
                createMeasureTooltip();
                let tooltipCoord = line.getCoordinateAt(0.5);
                measureTooltipElement.innerHTML = this.formatLength(line);
                measureTooltip.setPosition(tooltipCoord);
              })
            })
  
            geometry.on('change', (evt) => {
              if (newFeature.ol_uid != x.ol_uid) { //ol_uid changes after adding feature to the map
                this.clearTooltipsByOlUid(x.ol_uid);
                let selectedPolygonsReset = this.props.SelectedPolygons.slice();
                selectedPolygonsReset[selectedPolygons.indexOf(x)].ol_uid = newFeature.ol_uid;
                this.setGobKey('ms_SelectedPolygons', selectedPolygonsReset);
              }
  
              //add new measurement based on each segment
              this.clearTooltipsByOlUid(newFeature.ol_uid);
              let allCoords = evt.target.getCoordinates();
              allCoords.forEach(linestring => {
                linestring.forEach((point, index) => {
                  if (!linestring[index + 1]) { return; }
                  let line = new LineString([linestring[index], linestring[index + 1]]);
  
                  createMeasureTooltip();
                  let tooltipCoord = line.getCoordinateAt(0.5);
                  measureTooltipElement.innerHTML = this.formatLength(line);
                  measureTooltip.setPosition(tooltipCoord);
                })
              })
            });
            vector.addFeature(newFeature);
            extent = newFeature.getGeometry().getExtent();
            this.props.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
          }
          break;
        default:
          x.RawCoords = newFeature.getGeometry().getCoordinates();
          vector.addFeature(newFeature);
          break;
      }

      // x.ol_uid = newFeature.ol_uid;
      // extent = newFeature.getGeometry().getExtent();
      // this.props.map.getView().fit(buffer(extent, 15), { duration: 2000, easing: inAndOut, maxZoom: 20 });
    })

    let geosToSnap = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'LINESTRING' || x.geometry === 'MULTILINESTRING' || x.geometry === 'POINT' || x.geometry === 'POLYGON');
    geosToSnap.concat(this.props.map.getLayers().getArray().find(x => x.LayerId === 'Gate').getSource().getFeatures());
    var snap = new Snap({
      // source: this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').Vector,
      features: new Collection(geosToSnap),
      pixelTolerance: 7,
      vertex: false
    })
    this.props.map.addInteraction(snap);


    this.setGobKey('ms_SelectedPolygons', selectedPolygons);
  }

  editFenceRemoval = () => {
    let selectedPolygons = this.props.SelectedPolygons.slice();
    let edited = selectedPolygons.find(x => x.ol_uid == this.props.EditFenceRemoval);
    this.setGobKey('ms_EditFenceRemoval', null);

    let submitFenceEdit = () => {
      let feet = Math.round(document.getElementById('gate-removal').value);
      edited.Measurement = feet;
      edited.Text2 = feet + ' ft';
      this.savePlan(edited);
      this.setGobKey('ms_SelectedPolygons', selectedPolygons);
      this.setGobKey('ms_ModalActive', null);
      if (window.IsMobile) {
        this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
      }
    }

    let modalHtml = <div style={{ minWidth: '350px' }}>
      <p>Existing fence for removal: <input style={{ width: '65px' }} type="number" id="gate-removal" autoFocus defaultValue={edited.Measurement} /> ft.</p>
      <div className='polygon-roof-modal-btns'>
        <button className="polygon-btn-neg" onClick={() => { this.setGobKey('ms_ModalActive', null); this.setGobKey('ms_MySalesMapAction', null); if (window.IsMobile) { this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true }); } }}>Cancel</button>
        <button className="polygon-btn-pos" id="submit-fence-removal" onClick={submitFenceEdit}>Submit</button>
      </div>
    </div>
    this.setGobKey('ms_ModalActive', modalHtml);
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
    }
  }

  editRailingStain = () => {
    let selectedPolygons = this.props.SelectedPolygons.slice();
    let edited = selectedPolygons.find(x => x.ol_uid == this.props.EditRailingStain);
    this.setGobKey('ms_EditRailingStain', null);

    let submitRailingEdit = () => {
      let feet = Math.round(document.getElementById('deck-railing').value);
      edited.Measurement = feet;
      edited.Text2 = feet + ' ft';
      this.savePlan(edited);
      this.setGobKey('ms_SelectedPolygons', selectedPolygons);
      this.setGobKey('ms_ModalActive', null);
      if (window.IsMobile) {
        this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
      }
    }

    let modalHtml = <div style={{ minWidth: '350px' }}>
      <p>Existing railing for staining: <input style={{ width: '65px' }} type="number" id="deck-railing" autoFocus defaultValue={edited.Measurement} /> ft.</p>
      <div className='polygon-roof-modal-btns'>
        <button className="polygon-btn-neg" onClick={() => { this.setGobKey('ms_ModalActive', null); this.setGobKey('ms_MySalesMapAction', null); if (window.IsMobile) { this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true }); } }}>Cancel</button>
        <button className="polygon-btn-pos" id="submit-fence-removal" onClick={submitRailingEdit}>Submit</button>
      </div>
    </div>
    this.setGobKey('ms_ModalActive', modalHtml);
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
    }
  }

  finishPanel3Check = () => {
    this.setGobKey('ms_FinishPanel3Check', false);

    let fence = this.props.SelectedPolygons.find(x => x.Type === 'fence_Fence');
    let gate = this.props.SelectedPolygons.find(x => x.Type === 'fence_Gate');
    let gateRequired = this.props.ItemCardlist.findIndex(e => e.EntityTypeId === 2059);
    if (fence && !gate && gateRequired >= 0) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_Dialog',
        Value: {
          Title: 'No Gates Found',
          Message: <p style={{ fontSize: '1.1rem' }}>You have not added a gate to your fence</p>,
          Buttons: [{
            "Label": "Add Gate",
            "Engines": ["btn_AddGate"],
            "Class": "polygon-btn",
            "Width": "140px"
          },
          {
            "Label": "Continue",
            "Engines": ["btn_ContinueWithoutGate"],
            "Class": "polygon-btn-neg",
            "Width": "140px",
            "PreventAction": true
          }]
        }
      }));
    } else {
      //If RequireContact false, switch scene to step1panel to fill out form ?
      this.sendToSummary();
    }
  }

  sendToSummary = () => {
    if (window.IsMobile) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'MainTabContent',
        Value: { Value: 'MySalesmanMapping', Enabled: true }
      }))
    }

    //find center of measurement layer and update map center
    let measureVectorLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure');
    let measureFeats = measureVectorLayer.getSource().getFeatures();
    let measureSource = new VectorSource({
      features: measureFeats,
    });

    if(!window.IsMobile) {
      this.props.map.getView().fit(measureSource.getExtent(), {
        padding: [100,100,100,100]
      });
    } else {
      this.props.map.getView().fit(measureSource.getExtent(), {
        size: [250,250],
        padding: [25,25,25,25]
      });
    }

    //if contactlast send to step1panel
    if (this.props.ContactLast && !this.props.SendToSummaryOverride) {
      this.setGobKey('ms_SendToSummaryOverride', true);
      if (window.IsMobile) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'MainTabContent',
          Value: { Value: 'Step1Panel', Enabled: true }
        }))
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: 'PanelContent',
          Value: { Value: 'Step1Panel', Enabled: true }
        }))
      }
      return;
    }

    const getLabelText = (text) => {
      return new Text({
        font: "2rem Arial, sans-serif",
        fill: new Fill({
          color: "rgb(26, 26, 26)",
        }),
        backgroundFill: new Fill({
          color: "rgb(248, 248, 248)"
        }),
        padding: [ 0, 8, 0, 8 ],
        textAlign: "center",
        justify: "center",
        text: text,
        overflow: true,
        maxAngle: 360,
      });
    }

    this.setGobKey('ms_SendToSummaryOverride', null);
    this.sendProgressSave('Summary'); //TriggerBudgetEmail

    if (measureVectorLayer) {
      const sortedPolygons = this.props.SelectedPolygons.sort(function(a,b){return a.planId - b.planId})
      let vectorSource = measureVectorLayer.getSource();
      let features = vectorSource.getFeatures();

      features.map((x, i) => {
        var segmentStyles = []
        var newLines = []
        var segNum = 1

        let index = sortedPolygons.findIndex(e => e.ol_uid === x.ol_uid)

        if (x.geometry !== 'POLYGON') {
          x.getGeometry().getLineStrings().map((e, idx) => {
            let coords = e.getCoordinates();
            for (var y = 0; y < (coords.length - 1); y++) {
              var newLine = new LineString([coords[y], coords[y + 1]], 'XY');
              newLines.push(newLine)
            }
          })

          let newMulti = new MultiLineString(newLines)
          x.setGeometry(newMulti)

          x.getGeometry().getLineStrings().map((e, idx) => {
            let coords = e.getCoordinates();
            var y;
            for (y = 0; y < (coords.length - 1); y++) {
              var newLine = new LineString([coords[y], coords[y + 1]], 'XY');
              newLines.push(newLine)
              segmentStyles.push(new Style({
                text: getLabelText(`${index + 1}${String.fromCharCode(96 + segNum)}`),
                geometry: () => {
                  const centerOfExtent = getCenter(newLine.getExtent());
                  return new Point(centerOfExtent);
                }
              }))
              segNum += 1;
            }
          })
        }

        x.setStyle(
        [...[new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 8,
          }),
        }),
        new Style({
          image: new CircleStyle({
            radius: 9,
            stroke: new Stroke({
              width: 3,
              color: 'black',
            }),
            fill: new Fill({
              color: 'lightblue',
            }),
          }),
          geometry: (feature) => {
            let coordinates = feature.getGeometry().getCoordinates();
            if (feature.geometry === 'POLYGON') {
              coordinates = coordinates[0];
            }
            if (Array.isArray(coordinates[0][0])) { //Give MultiLineString vertex points
              let rawCoords = [];
              coordinates.forEach(c => {
                c.forEach(x => {
                  rawCoords.push(x);
                })
              })
              return new MultiPoint(rawCoords);
            }
            return new MultiPoint(coordinates);
          }}), 
          new Style({
            source: vectorSource,
            type: 'LineString',
            stroke: new Stroke({
              color: 'black',
              width: 12,
            })
          }),
          new Style({
            source: vectorSource,
            type: 'LineString',
            stroke: new Stroke({
              color: 'lightblue',
              width: 9,
            }),
          }),
          new Style({
            text: getLabelText(`${index + 1}`),
            geometry: (feature) => {
              const centerOfExtent = getCenter(feature.getGeometry().getExtent());
              return new Point(centerOfExtent);
            }
          })
        ],
        ...segmentStyles]
      )});

      this.setGobKey('sr_UserInfoSubmit', true)
      this.setGobKey('sr_SendEmails', true)

      if(this.props.ActiveConsumerId) {
        const baseConsumerObj = {
          Id: this.props.ActiveConsumerId,
          Table: 'xmv.Consumer',
          IsBaseTable: true,
          EntityTypeId: 2040
        }
        const saveData = []

        saveData.push(helpers.saveDataItem(baseConsumerObj, 'StatusLId', 29176));

        actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {});
      }

      setTimeout(() => {
        this.setGobKey('sr_MapSnapshot', true);
        features.map(x => x.setStyle(inactiveStyle));
        this.setGobKey('MainTabContent', { Value: 'MySalesmanCompleted', Enabled: true });
      }, 50)
    }
  }

  setUpBlankCanvas = () => {
    this.setGobKey('ms_BlankCanvas', false);

    let vector = this.props.map.getLayers().getArray().find(x => x.LayerId == 594).getSource();

    let newView = new View({
      extent: vector.getExtent(),
      center: [-9685442.575119179, 5162335.828399206],
      maxZoom: 24,
      minZoom: 5,
      zoom: 21
    })

    let oldOptions = this.props.map.getView().options_;
    this.setGobKey('ms_MapOptions', oldOptions);

    setTimeout(() => {
      this.props.map.setView(newView);
    }, 2500)

    this.sendProgressSave('Canvas');

    let panelSceneKey = 'PanelContent';
    if (window.IsMobile) {
      panelSceneKey = 'MainTabContent';
    }
    this.props.dispatch(actions.UpdateProp({
      Key: panelSceneKey,
      Value: { Value: 'Step3Panel', Enabled: true }
    }))
  }

  sendProgressSave = (stepInfo) => {
    let saveData = [];

    let progBaseObj = {
      InsertKey: helpers.getInsertKey(),
      Table: 'xmv.Progress',
      IsBaseTable: false
    };
    saveData.push(helpers.saveDataItem(progBaseObj, 'ConsumerId', this.props.ActiveConsumerId));
    saveData.push(helpers.saveDataItem(progBaseObj, 'Step', stepInfo));

    //Add save data item marking completion of Step2Panel (Address selected from typeahead)
    actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });
  }

  gateHovered = (isHover) => {
    let vector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Gate');
    if (!vector) { return }
    let vectorSource = vector.getSource();
    let allFeats = vectorSource.getFeatures();
    let feat = null;
    let svg = '<svg width="80" height="80" fill="lightblue" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z"/></svg>';
    let svglightblue = '<svg width="80" height="80" fill="orange" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z"/></svg>';
    let svgBlack = '<svg width="80" height="80" fill="black" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z"/></svg>';
    if (isHover) {
      allFeats.forEach(x => {
        if (x.ol_uid == this.props.PolygonHovered.ol_uid) {
          feat = x;
        }
      })
      if (!feat) { return }
      feat.setStyle([new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgBlack,
          scale: 0.4,
        })
      }), new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svglightblue,
          scale: 0.4,
        }),
        text: new Text({
          font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          offsetY: 3,
          text: this.props.PolygonHovered.Text2.charAt(0)
        })
      })])
      this.setGobKey('ms_PolygonHovered', null);
    } else {
      allFeats.forEach(x => {
        if (x.ol_uid == this.props.PolygonHoverExit.ol_uid) {
          feat = x;
        }
      })
      if (!feat) { return }
      feat.setStyle([new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgBlack,
          scale: 0.4,
        })
      }), new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svg,
          scale: 0.4,
        }),
        text: new Text({
          font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          offsetY: 3,
          text: this.props.PolygonHoverExit.Text2.charAt(0)
        })
      })])
      this.setGobKey('ms_PolygonHoverExit', null);
    }
  }

  polygonHovered = () => {
    let vectorSource = this.getMeasurementVector();
    let allFeats = vectorSource.getFeatures();
    let feat = null;
    allFeats.forEach(x => {
      if (x.ol_uid == this.props.PolygonHovered.ol_uid) {
        feat = x;
      }
    })
    //if not found, check gate layer
    if (!feat) {
      this.gateHovered(true);
      return;
    }

    feat.setStyle(activeStyle);
    this.setGobKey('ms_PolygonHovered', null);
  }

  polygonHoverExit = () => {
    if (this.props.ModifyActive)
      return;
    let vectorSource = this.getMeasurementVector();
    let allFeats = vectorSource.getFeatures();
    let feat = null;
    allFeats.forEach(x => {
      if (x.ol_uid == this.props.PolygonHoverExit.ol_uid) {
        feat = x;
      }
    })
    //if not found, check gate layer
    if (!feat) {
      this.gateHovered(false);
      return;
    }
    feat.setStyle(inactiveStyle);
    this.setGobKey('ms_PolygonHoverExit', null);
  }

  restartSelection = () => {
    const updateData = this.props.SelectedPolygons.map((x) => {
      return {
        Column: "IsDeleted",
        Id: x.planId,
        IsBaseTable: true,
        Table: "xmv.Plans",
        Value: true
      }
    })

    actions.ApiRequest('Save/Save', { SaveData: updateData }, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });

    this.setGobKey('ms_RestartSelection', false);
    this.clearAllMeasures();
    let view = new View(this.props.MapOptions);
    this.props.map.setView(view);

    let tenant = this.props.EntityMetadata.UserTenants.find(x => x.Id == this.props.EntityMetadata.CurrentTenant);
    let bounds = JSON.parse(tenant.Bounds);
    var coordMin = fromLonLat([bounds[0], bounds[1]], 'EPSG:3857');
    var coordMax = fromLonLat([bounds[2], bounds[3]], 'EPSG:3857');
    view.fit([coordMin[0], coordMin[1], coordMax[0], coordMax[1]], this.props.map.getSize());

    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'Step2Panel', Enabled: true });
    } else {
      this.setGobKey('PanelContent', { Value: 'Step2Panel', Enabled: true });
    }
  }

  modifyEnabled = (isActive, Id) => {
    var interactions = this.props.map.getInteractions().getArray();
    let modify = interactions.find(x => x.pixelTolerance_ === 10); //className of 'main-modify' isn't being set / accessible ?? ol-ext bug
    let vectorSource = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();

    if (!modify) {
      let usePopup = (arg) => {
        console.log({ usePopup: arg });
        return false;
      }
      modify = new ModifyTouch({
        source: vectorSource,
        title: "❌ Delete point",
        className: "main-modify",
        pixelTolerance: 10,
        style: new Style({
          image: new RegularShape({
            stroke: new Stroke({
              color: 'rgb(255, 190, 10)',
              width: 3
            }),
            points: 4,
            radius: 8,
            radius2: 0,
            angle: 0,
          })
        }),
        usePopup: usePopup
      })
      this.props.map.addInteraction(modify);
    }

    modify.setActive(isActive);
    if (isActive) {
      this.setGobKey('ms_ModifyActive', true);
    } else {
      this.setGobKey('ms_ModifyActive', false);
    }

    //Zoom if needed
    if (Id && Id !== 'Gate') {
      this.setGobKey('ms_ActiveModifyId', Id);
      let allFeats = vectorSource.getFeatures();
      let feat = null;
      allFeats.forEach(x => {
        if (x.ol_uid === Id) {
          feat = x;
        }
      })

      feat.setStyle(activeStyle);
      let extent = feat.getGeometry().getExtent();
      this.props.map.getView().fit(buffer(extent, 15), { duration: 600, easing: inAndOut, maxZoom: 22 });
    } else if (Id === 'Gate') {
      this.setGobKey('ms_ActiveModifyId', 'Gate');
    }
  }

  deselectHomeMapMarker = () => {
    if (this.props.HomeSelected) {
      let homeLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Home').getSource();
      if (homeLayer && homeLayer.getFeatures()[0]) {
        homeLayer.getFeatures()[0].setGeometry(null);
        this.setGobKey('map_MoveToLocation', null);
      }
    }
  }

  discardRoofFootprint = () => {
    this.setGobKey('ms_ModalActive', false);
    // this.setState({ rooftopModal: false });
    let vector = this.getMeasurementVector();
    let features = vector.getFeatures();
    features.forEach(x => {
      if (x.unsavedRooftop) {
        vector.removeFeature(x);
        let tooltips = document.getElementsByClassName('ol-tooltip-clickthrough');
        for (let y of tooltips) {
          if (y.innerHTML.includes(x.Measurement)) {
            y.parentNode.removeChild(y);
          }
        }
      }
    })
    this.startDraw({ ...this.props.MapAction, Value: null });
    this.setGobKey('ms_ActiveModifyId', null);
  }

  startRooftop = () => {
    this.setGobKey('ms_MapCrosshair', true);
    this.setGobKey('ms_ModifyActive', true);
    let modalHtml = <div>
      <p><b>Find your house</b></p>
      <p>Aim the map crosshair at your house and then select <b><i>Next</i></b></p>
      <div className='polygon-roof-modal-btns'>
        <button className="polygon-btn-pos" onClick={() => this.setGobKey('ms_ModalActive', false)}>Got it</button>
      </div>
    </div>
    this.setGobKey('ms_ModalActive', modalHtml)
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
    }
  }

  rooftopGeo = () => {
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
    }
    this.setGobKey('ms_MapCrosshair', false);
    let rooftopLatLong = new Point(this.props.map.getView().getCenter()).transform('EPSG:3857', 'EPSG:4326');
    let body = {
      CenterPoint: {
        Latitude: rooftopLatLong.getCoordinates()[0],
        Longitude: rooftopLatLong.getCoordinates()[1]
      }
    }
    actions.ApiRequest('Map/GetFootprint', body, (result) => {
      let modalHtml = <div>
        <p><b>Rooftop was found</b></p>
        <p>Use and edit this rooftop, or redraw it manually?</p>
        <div className='polygon-roof-modal-btns'>
          <button className="polygon-btn-pos" onClick={() => { this.setGobKey('ms_ModalActive', false); this.setState({ helptextModal: true }); this.rotateHelpTriangleDown(); }}>Use This</button>
          <button className="polygon-btn-pos" onClick={this.discardRoofFootprint}>Draw my Own</button>
        </div>
      </div>

      if (!result.Geometry) {
        modalHtml = <div>
          <p><b>No rooftop found</b></p>
          <p>Center the map on the roof you wish to select and try again, or draw it manually</p>
          <div className='polygon-roof-modal-btns'>
            <button className="polygon-btn-pos" onClick={() => { this.setGobKey('ms_ModalActive', false); this.startRooftop(); }}>Try Again</button>
            <button className="polygon-btn-pos" onClick={this.discardRoofFootprint}>Draw my Own</button>
          </div>
        </div>
        this.setGobKey('ms_ModalActive', modalHtml);
        // this.setGobKey('ms_MySalesMapAction', null);
        return;
      }

      let vector = this.getMeasurementVector();
      let format = new WKT();
      let newFeature = format.readFeature(result.Geometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });

      let geometry = newFeature.getGeometry();
      newFeature.geometry = "POLYGON";
      newFeature.unsavedRooftop = true;
      newFeature.Measurement = this.formatArea(geometry);
      vector.addFeature(newFeature);
      let extent = geometry.getExtent();

      let measureTooltipElement = document.createElement('div');
      measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
      measureTooltipElement.innerHTML = this.formatArea(geometry);
      measureTooltipElement.ol_uid = newFeature.ol_uid;
      let measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
        className: 'ol-clickthrough',
        position: geometry.getInteriorPoint().getCoordinates()
      });
      this.props.map.addOverlay(measureTooltip);

      geometry.on('change', (evt) => {
        let geom = evt.target;
        measureTooltipElement.innerHTML = this.formatArea(geom);
        measureTooltip.setPosition(geom.getInteriorPoint().getCoordinates());
      });

      // this.setState({ rooftopModal: modalHtml });
      this.setGobKey('ms_ModalActive', modalHtml)
      this.setGobKey('ms_ActiveModifyId', newFeature.ol_uid);
      this.modifyEnabled(true);
      // if (window.IsMobile) {
      //   this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
      // }
      setTimeout(() => {
        this.props.map.getView().fit(buffer(extent, 15), { duration: 600/*, easing: inAndOut*/ });
      }, 100);

    });
  }

  addCardListEntry = () => {
    let selectedPolygons = this.props.SelectedPolygons.slice();
    let obj = { Measurement: 0, Amount: '-', Text2: 0, CostLinearFeet: 0 };
    if (this.props.MapAction && this.props.MapAction.Name) {
      obj.Text1 = this.props.MapAction.Name;
      obj.Type = this.props.MapAction.Value;
      obj.EntityTypeId = this.props.MapAction.EntityTypeId;
    }
    selectedPolygons.push(obj);
    this.setGobKey('ms_SelectedPolygons', selectedPolygons);
  }

  startDraw = (mapAction) => {
    this.deselectHomeMapMarker();

    if (!mapAction) { return; }

    let type = 'Polygon';

    switch (mapAction.Value) {
      case 'fence_Gate':
        let fenceExists = false;
        this.props.SelectedPolygons.forEach(x => {
          if (x.Type === 'fence_Fence') {
            fenceExists = true;
          }
        })
        if (fenceExists) {
          if (window.IsMobile) {
            this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
            this.setGobKey('ms_ModalActive', <div><div>Which type of gate would you like to add?</div><br /><div className='polygon-selection-modal-button-container'><button className="polygon-btn-pos" style={{ padding: "10px" }} onClick={() => this.addGate('S')}>Single Gate</button><button className="polygon-btn-pos" style={{ padding: "10px" }} onClick={() => this.addGate('D')}>Double Gate</button><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={this.cancelGate}>Cancel</div></div></div>);
          } else {
            this.setGobKey('ms_ModalActive', <div><div>Which type of gate would you like to add?</div><br /><div className='polygon-selection-modal-button-container'><button className="polygon-btn-pos" style={{ padding: "10px" }} onClick={() => this.addGate('S')}>Single Gate</button><button className="polygon-btn-pos" style={{ padding: "10px" }} onClick={() => this.addGate('D')}>Double Gate</button><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={this.cancelGate}>Cancel</div></div></div>);
            // this.setState({ addGateModal: true });
          }
        } else {
          this.props.dispatch(actions.UpdateProp({
            Key: 'blu_Dialog',
            Value: { Title: 'Cannot add Gate', Message: <p style={{ fontSize: '1.1rem' }}>You must have a fence drawn before you can add a fence gate</p> }
          }));
          this.setGobKey('ms_MySalesMapAction', null);
        }
        return;
      case 'powerwash_Vertical':
        type = 'LineString';
        break;
      case 'fence_Fence':
        type = 'LineString';
        break;
      case 'powerwash_Area':
        break;
      case 'powerwash_Roof':
        this.startRooftop();
        return;
      case 'roof_Roof':
        this.startRooftop();
        return;
      case 'fence_Remove':
        this.fenceRemoval();
        return;
      case 'deck_Deck':
        break;
      case 'deck_Stair':
        var deck_exists = false;
        this.props.SelectedPolygons.forEach(x => {
          if (x.Type === 'deck_Deck') {
            deck_exists = true;
          }
        })
        if(deck_exists) {
          if (window.IsMobile) {
            this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
            this.setGobKey('ms_ModalActive', <div><div>How many stairs will be in this staircase?</div><br /><div className='polygon-selection-modal-button-container'><input type="number" min="1" onChange={(e) => this.setGobKey('ms_StairQuantity',Number(e.target.value))}></input><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={() => this.addStair(this.props.StairQuantity)}>Place</div><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={this.cancelGate}>Cancel</div></div></div>);  
          } else {
            this.setGobKey('ms_ModalActive', <div><div>How many stairs will be in this staircase?</div><br /><div className='polygon-selection-modal-button-container'><input type="number" min="1" onChange={(e) => this.setGobKey('ms_StairQuantity',Number(e.target.value))}></input><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={() => this.addStair(this.props.StairQuantity)}>Place</div><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={this.cancelGate}>Cancel</div></div></div>);
          }
        } else {
          this.props.dispatch(actions.UpdateProp({
            Key: 'blu_Dialog',
            Value: { Title: 'Cannot add Stairs', Message: <p style={{ fontSize: '1.1rem' }}>You must have a deck drawn before you can add stairs</p> }
          }));
          this.setGobKey('ms_MySalesMapAction', null);
        }
        return;
      case 'deck_Railing_Stain':
        this.deckRailing()
        return;
      case 'deck_Stair_Stain':
        var deck_exists = false;
        this.props.SelectedPolygons.forEach(x => {
          if (x.Type === 'deck_Stain') {
            deck_exists = true;
          }
        })
        if(deck_exists) {
          if (window.IsMobile) {
            this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
            this.setGobKey('ms_ModalActive', <div><div>How many stairs will be stained on this staircase?</div><br /><div className='polygon-selection-modal-button-container'><input type="number" min="1" onChange={(e) => this.setGobKey('ms_StairQuantity',Number(e.target.value))}></input><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={() => this.addStairStain(this.props.StairQuantity)}>Place</div><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={this.cancelGate}>Cancel</div></div></div>);  
          } else {
            this.setGobKey('ms_ModalActive', <div><div>How many stairs will be stained on this staircase?</div><br /><div className='polygon-selection-modal-button-container'><input type="number" min="1" onChange={(e) => this.setGobKey('ms_StairQuantity',Number(e.target.value))}></input><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={() => this.addStairStain(this.props.StairQuantity)}>Place</div><div className="homepage-text" style={{ paddingTop: "10px" }} onClick={this.cancelGate}>Cancel</div></div></div>);
          }
        } else {
          this.props.dispatch(actions.UpdateProp({
            Key: 'blu_Dialog',
            Value: { Title: 'Cannot add Stairs', Message: <p style={{ fontSize: '1.1rem' }}>You must have a deck drawn before you can add stairs to stain</p> }
          }));
          this.setGobKey('ms_MySalesMapAction', null);
        }
        return;
    }

    this.addCardListEntry();
    this.setGobKey('ms_DrawingActive', true);
    let vector = this.getMeasurementVector();
    let draw = this.getDraw(vector, type);
    this.createMeasurement(draw);
    if (type === 'Polygon') {
      let snapi = new SnapGuides({
        source: vector
      })
      snapi.InteractionId = 'snapi';
      snapi.setDrawInteraction(draw);
      this.props.map.addInteraction(snapi);
      this.lineSnappingEnabled(false);
    } else {
      this.lineSnappingEnabled(true);
    }
    if (!this.state.Guidelines) { this.toggleGuidelines(false); }
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
    }
  }

  addStairStain = (stairQuantity) => {
    let svg = '<svg width="100px" height="100px" fill="orange" viewBox="0 0 2.4 2.4" xmlns="http://www.w3.org/2000/svg"><path d="M1.8 0.6h-0.3v0.3h-0.3v0.3h-0.299v0.3h-0.3v0.3H0.3v0.3h1.8V0.3h-0.3zM1.9 0.4h0.1v1.6H0.4v-0.1h0.3v-0.3h0.3v-0.3h0.3v-0.3h0.3v-0.299h0.3z"/><path fill="none" d="M0 0h2.4v2.4H0z"/></svg>';
    let svgLightBlue = '<svg width="100px" height="100px" fill="lightblue" viewBox="0 0 2.4 2.4" xmlns="http://www.w3.org/2000/svg"><path d="M1.8 0.6h-0.3v0.3h-0.3v0.3h-0.299v0.3h-0.3v0.3H0.3v0.3h1.8V0.3h-0.3zM1.9 0.4h0.1v1.6H0.4v-0.1h0.3v-0.3h0.3v-0.3h0.3v-0.3h0.3v-0.299h0.3z"/><path fill="none" d="M0 0h2.4v2.4H0z"/></svg>';
    let svgBlack = '<svg width="100px" height="100px" viewBox="0 0 2.4 2.4" xmlns="http://www.w3.org/2000/svg"><path d="M1.8 0.6h-0.3v0.3h-0.3v0.3h-0.299v0.3h-0.3v0.3H0.3v0.3h1.8V0.3h-0.3zM1.9 0.4h0.1v1.6H0.4v-0.1h0.3v-0.3h0.3v-0.3h0.3v-0.3h0.3v-0.299h0.3z"/><path fill="none" d="M0 0h2.4v2.4H0z"/></svg>';

    let stairLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Stair');
    let vector;

    if (!stairLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,
      })

      layer.setZIndex(50);
      layer.LayerId = 'Stair';
      layer.Vector = vector;
      this.props.map.addLayer(layer);

      this.createStairModTouch(vector);
    } else {
      vector = stairLayer.Vector;
      var interactions = this.props.map.getInteractions().getArray();
      let modTouch = interactions.find(x => x instanceof ModifyTouch && x.className !== 'main-modify');
      if (modTouch) {
        this.props.map.removeInteraction(modTouch);
        this.createStairModTouch(vector);
      }
    }

    let draw = new Draw({
      source: vector,
      type: 'Point',
      style: [new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgBlack,
          scale: 0.4,
        })
      }), new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svg,
          scale: 0.4,
        }),
        text: new Text({
          font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          offsetY: 3,
          text: `${stairQuantity}`
        })
      })]
    })

    var interactions = this.props.map.getInteractions().getArray();

    draw.on('drawend', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.removeSnapping();
      this.props.map.removeInteraction(draw);
      this.props.map.removeInteraction(e.target);
      let polysToSnap = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'POLYGON');
      polysToSnap.push(e.feature);
      var snap = new Snap({
        features: new Collection(polysToSnap),
        pixelTolerance: 7,
        vertex: false
      })
      this.props.map.addInteraction(snap);
      e.feature.setStyle(new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgLightBlue,
          scale: 0.4,
        }),
        text: new Text({
          font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          offsetY: 3,
          text: `${stairQuantity}`
        })
      }));
      this.setGobKey('ms_DrawingActive', false);
      let selectedPolygons = cloneDeep(this.props.SelectedPolygons);
      let index = selectedPolygons[selectedPolygons.length - 1].Id + 1;
      let gateType = `${stairQuantity} Stair(s)`

      let obj = { Id: index, Type: 'deck_Stair_Stain', Text1: 'Stair Stain', Text2: gateType, Amount: '-', Geometry: e.feature.getGeometry(), ol_uid: e.feature.ol_uid, EntityTypeId: this.props.MapAction.EntityTypeId, StairQuantity: this.props.StairQuantity };

      let measureVector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
      let deckTouchingFeature = measureVector.getClosestFeatureToCoordinate(e.feature.getGeometry().getCoordinates());
      let deckTouching = selectedPolygons.find(y => y.ol_uid == deckTouchingFeature.ol_uid);
      if (deckTouching) {
        obj.CostGate = deckTouching.CostGate;
        obj.deck_ol_uid = deckTouching.ol_uid;
      }

      obj = this.savePlan(obj);
      selectedPolygons.push(obj);

      if (this.props.NextMapAction) {
        this.setGobKey('ms_MySalesMapAction', { Id: this.props.NextMapAction.Id }); //re-highlight the cardlist
        this.modifyEnabled(true);
      } else {
        this.setGobKey('ms_MySalesMapAction', null);
      }

      this.setGobKey('ms_SelectedPolygons', selectedPolygons);
      if (window.IsMobile) {
        setTimeout(() => {
          this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
        }, 200)
      }
    });
    this.setGobKey('ms_DrawingActive', true);
    this.props.map.addInteraction(draw);

    interactions.forEach(x => {
      if (x instanceof ModifyTouch && x.className !== 'gate-modal') {
        x.setActive(false);
      }
    });

    let polysToSnap = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'POLYGON');
    var snap = new Snap({
      features: new Collection(polysToSnap),
      pixelTolerance: 1000,
      vertex: false
    })
    this.props.map.addInteraction(snap);
    this.setGobKey('ms_ModalActive', null);
  }

  addStair = (stairQuantity) => {
    let svg = '<svg width="100px" height="100px" fill="orange" viewBox="0 0 2.4 2.4" xmlns="http://www.w3.org/2000/svg"><path d="M1.8 0.6h-0.3v0.3h-0.3v0.3h-0.299v0.3h-0.3v0.3H0.3v0.3h1.8V0.3h-0.3zM1.9 0.4h0.1v1.6H0.4v-0.1h0.3v-0.3h0.3v-0.3h0.3v-0.3h0.3v-0.299h0.3z"/><path fill="none" d="M0 0h2.4v2.4H0z"/></svg>';
    let svgLightBlue = '<svg width="100px" height="100px" fill="lightblue" viewBox="0 0 2.4 2.4" xmlns="http://www.w3.org/2000/svg"><path d="M1.8 0.6h-0.3v0.3h-0.3v0.3h-0.299v0.3h-0.3v0.3H0.3v0.3h1.8V0.3h-0.3zM1.9 0.4h0.1v1.6H0.4v-0.1h0.3v-0.3h0.3v-0.3h0.3v-0.3h0.3v-0.299h0.3z"/><path fill="none" d="M0 0h2.4v2.4H0z"/></svg>';
    let svgBlack = '<svg width="100px" height="100px" viewBox="0 0 2.4 2.4" xmlns="http://www.w3.org/2000/svg"><path d="M1.8 0.6h-0.3v0.3h-0.3v0.3h-0.299v0.3h-0.3v0.3H0.3v0.3h1.8V0.3h-0.3zM1.9 0.4h0.1v1.6H0.4v-0.1h0.3v-0.3h0.3v-0.3h0.3v-0.3h0.3v-0.299h0.3z"/><path fill="none" d="M0 0h2.4v2.4H0z"/></svg>';

    let stairLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Stair');
    let vector;

    if (!stairLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,
      })

      layer.setZIndex(50);
      layer.LayerId = 'Stair';
      layer.Vector = vector;
      this.props.map.addLayer(layer);

      this.createStairModTouch(vector);
    } else {
      vector = stairLayer.Vector;
      var interactions = this.props.map.getInteractions().getArray();
      let modTouch = interactions.find(x => x instanceof ModifyTouch && x.className !== 'main-modify');
      if (modTouch) {
        this.props.map.removeInteraction(modTouch);
        this.createStairModTouch(vector);
      }
    }

    let draw = new Draw({
      source: vector,
      type: 'Point',
      style: [new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgBlack,
          scale: 0.4,
        })
      }), new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svg,
          scale: 0.4,
        }),
        text: new Text({
          font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          offsetY: 3,
          text: `${stairQuantity}`
        })
      })]
    })

    var interactions = this.props.map.getInteractions().getArray();

    draw.on('drawend', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.removeSnapping();
      this.props.map.removeInteraction(draw);
      this.props.map.removeInteraction(e.target);
      let polysToSnap = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'POLYGON');
      polysToSnap.push(e.feature);
      var snap = new Snap({
        features: new Collection(polysToSnap),
        pixelTolerance: 7,
        vertex: false
      })
      this.props.map.addInteraction(snap);
      e.feature.setStyle(new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgLightBlue,
          scale: 0.4,
        }),
        text: new Text({
          font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          offsetY: 3,
          text: `${stairQuantity}`
        })
      }));
      this.setGobKey('ms_DrawingActive', false);
      let selectedPolygons = cloneDeep(this.props.SelectedPolygons);
      let index = selectedPolygons[selectedPolygons.length - 1].Id + 1;
      let gateType = `${stairQuantity} Stair(s)`

      let obj = { Id: index, Type: 'deck_Stair', Text1: 'Stairs', Text2: gateType, Amount: '-', Geometry: e.feature.getGeometry(), ol_uid: e.feature.ol_uid, EntityTypeId: this.props.MapAction.EntityTypeId, StairQuantity: this.props.StairQuantity };

      let measureVector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
      let deckTouchingFeature = measureVector.getClosestFeatureToCoordinate(e.feature.getGeometry().getCoordinates());
      let deckTouching = selectedPolygons.find(y => y.ol_uid == deckTouchingFeature.ol_uid);
      if (deckTouching) {
        obj.CostGate = deckTouching.CostGate;
        obj.deck_ol_uid = deckTouching.ol_uid;
      }

      obj = this.savePlan(obj);
      selectedPolygons.push(obj);

      if (this.props.NextMapAction) {
        this.setGobKey('ms_MySalesMapAction', { Id: this.props.NextMapAction.Id }); //re-highlight the cardlist
        this.modifyEnabled(true);
      } else {
        this.setGobKey('ms_MySalesMapAction', null);
      }

      this.setGobKey('ms_SelectedPolygons', selectedPolygons);
      if (window.IsMobile) {
        setTimeout(() => {
          this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
        }, 200)
      }
    });
    this.setGobKey('ms_DrawingActive', true);
    this.props.map.addInteraction(draw);

    interactions.forEach(x => {
      if (x instanceof ModifyTouch && x.className !== 'gate-modal') {
        x.setActive(false);
      }
    });

    let polysToSnap = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'POLYGON');
    var snap = new Snap({
      features: new Collection(polysToSnap),
      pixelTolerance: 1000,
      vertex: false
    })
    this.props.map.addInteraction(snap);
    this.setGobKey('ms_ModalActive', null);
  }

  fenceRemoval = () => {
    let modalHtml = <div style={{ minWidth: '350px' }}>
      <p>Existing fence for removal: <input style={{ width: '65px' }} type="number" id="gate-removal" autoFocus /> ft.</p>
      <div className='polygon-roof-modal-btns'>
        <button className="polygon-btn-neg" onClick={() => { this.setGobKey('ms_ModalActive', null); this.setGobKey('ms_MySalesMapAction', null); if (window.IsMobile) { this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true }); } }}>Cancel</button>
        <button className="polygon-btn-pos" id="submit-fence-removal" onClick={this.submitFenceRemoval}>Submit</button>
      </div>
    </div>
    this.setGobKey('ms_ModalActive', modalHtml);
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
    }
  }

  deckRailing = () => {
    let modalHtml = <div style={{ minWidth: '350px' }}>
      <p>Existing railing for staining: <input style={{ width: '65px' }} type="number" id="deck-railing" autoFocus /> ft.</p>
      <div className='polygon-roof-modal-btns'>
        <button className="polygon-btn-neg" onClick={() => { this.setGobKey('ms_ModalActive', null); this.setGobKey('ms_MySalesMapAction', null); if (window.IsMobile) { this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true }); } }}>Cancel</button>
        <button className="polygon-btn-pos" id="submit-fence-removal" onClick={this.submitDeckRailing}>Submit</button>
      </div>
    </div>
    this.setGobKey('ms_ModalActive', modalHtml);
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'MySalesmanMapping', Enabled: true });
    }
  }

  submitFenceRemoval = () => {
    let feet = Math.round(document.getElementById('gate-removal').value);

    let selectedPolygons = this.props.SelectedPolygons.slice();
    let obj = { Type: 'fence_Remove', Measurement: feet, Text1: 'Fence Removal', Text2: feet + ' ft', Amount: '-', Geometry: null, EntityTypeId: this.props.MapAction.EntityTypeId, ol_uid: Math.random() };
    obj = this.savePlan(obj);
    selectedPolygons.push(obj);
    this.setGobKey('ms_SelectedPolygons', selectedPolygons);

    this.setGobKey('ms_ModalActive', null);
    this.setGobKey('ms_MySalesMapAction', null);
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
    }
  }

  submitDeckRailing = () => {
    let feet = Math.round(document.getElementById('deck-railing').value);

    let selectedPolygons = this.props.SelectedPolygons.slice();
    let obj = { Type: 'deck_Railing_Stain', Measurement: feet, Text1: 'Deck Railing Stain', Text2: feet + ' ft', Amount: '-', Geometry: null, EntityTypeId: this.props.MapAction.EntityTypeId, ol_uid: Math.random() };
    obj = this.savePlan(obj);
    selectedPolygons.push(obj);
    this.setGobKey('ms_SelectedPolygons', selectedPolygons);

    this.setGobKey('ms_ModalActive', null);
    this.setGobKey('ms_MySalesMapAction', null);
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
    }
  }

  getMeasurementVector = () => {
    let layers = this.props.map.getLayers().getArray();
    let measureLayer = layers.find(x => x.LayerId === 'Measure');
    let vector;

    if (!measureLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,
        style: activeStyle
      });

      layer.setZIndex(20);
      layer.LayerId = 'Measure';
      layer.Vector = vector;
      this.props.map.addLayer(layer);

    } else {
      vector = measureLayer.Vector;
    }
    return vector;
  }

  createMeasurement = (draw) => {
    let sketch;
    let listener;
    let measureTooltip;
    let measureTooltipElement;
    let createMeasureTooltip = () => {
      // if (measureTooltipElement) {
      //   measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      // }
      measureTooltipElement = document.createElement('div');
      measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
      measureTooltipElement.ol_uid = 'unsaved';
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
        className: 'ol-clickthrough'
      });
      this.props.map.addOverlay(measureTooltip);
    }

    draw.on('drawstart', (evt) => {
      // set sketch
      sketch = evt.feature;

      /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
      let tooltipCoord = evt.coordinate;

      createMeasureTooltip();

      listener = sketch.getGeometry().on('change', (evt) => {
        let tooltips = Array.prototype.slice.call(document.getElementsByClassName('ol-tooltip-clickthrough'));
        tooltips.forEach(x => {
          x.style.display = 'block';
        })
        let geom = evt.target;
        let output;
        if (geom instanceof Polygon) {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCoord);
        } else if (geom instanceof LineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getCoordinateAt(0.5);
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCoord);
          // tooltipCoord = geom.getLastCoordinate();
        }
        if (this.props.CurrentMeasure) {
          //find lengths of all other features with current drawId == draw.ol_uid
          let measureLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
          let lines = measureLayer.getFeatures().filter(x => x.drawId == draw.ol_uid);
          let existingMeasure = 0;
          lines.forEach(x => {
            let measure = this.formatLength(x.getGeometry());
            existingMeasure += Number(measure.split(' ')[0].replace(',', ''));
          })
          let newMeasure = Number(output.split(' ')[0].replace(',', ''));
          let units = output.split(' ')[1];
          output = existingMeasure + newMeasure + ' ' + units;
          if (this.props.CurrentMeasure != output) {
            this.setGobKey('ms_currentMeasure', output);
          }
        } else {
          this.setGobKey('ms_currentMeasure', output);
        }
      });
    });

    draw.on('drawend', (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.target.maxPoints_ == 2) {
        e.feature.drawId = e.target.ol_uid;
        return;
      }

      this.setState({ helptextModal: true });
      this.rotateHelpTriangleDown();

      //if type is linestring, don't end the drawing and let them draw another
      //add snapping to draw against only itself

      e.feature.geometry = draw.type_.toUpperCase();
      this.props.map.removeInteraction(draw);

      //deleting snap guidelines
      if (e.target.mode_ === 'Polygon') {
        let snapiLines = this.props.map.getInteractions().getArray().find(x => x.InteractionId === 'snapi');
        snapiLines.clearGuides();
        this.props.map.removeInteraction(snapiLines);

        let extent = e.feature.getGeometry().getExtent();
        setTimeout(() => {
          this.props.map.getView().fit(buffer(extent, 15), { duration: 600/*, easing: inAndOut*/ });
        }, 100);

        let tooltips = Array.prototype.slice.call(document.getElementsByClassName('ol-tooltip-clickthrough')).filter(x => x.ol_uid == 'unsaved');
        tooltips.forEach(x => {
          x.ol_uid = e.feature.ol_uid;
        })
      }

      this.doubleClickZoomEnabled(false);
      setTimeout(() => { this.doubleClickZoomEnabled(true); }, 251);

      batch(() => {
        this.setGobKey('ms_DrawingActive', false);
        this.modifyEnabled(true);
        this.setGobKey('ms_ActiveModifyId', e.feature.ol_uid);
      });
    });

    this.props.map.addInteraction(draw);

    let vector = this.props.map.getLayers().getArray().find(x => x.LayerId == 594);
    if(vector) {
      let vectorSource = vector.getSource();
      let snap = new Snap({
        source: vectorSource,
        pixelTolerance: 11,
        id: 'gridSnap'
      })
      this.props.map.addInteraction(snap);  
    }
    
    // if (draw.mode_ === 'Polygon') {
    //   createMeasureTooltip();
    // }
    // createMeasureTooltip();

  }

  homeSelectedMapMarker = () => {
    let homeLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Home');
    let vector;
    // let svgMarker = '<svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 32 32"><path id="map-marker" d="M16,4a8.9999,8.9999,0,0,0-9,9c0,6,6.7583,13.07764,8.16156,14.63135a1.13778,1.13778,0,0,0,1.67688,0C18.2417,26.07764,25,19,25,13A8.9999,8.9999,0,0,0,16,4Zm0,14a5,5,0,1,1,5-5A5.00013,5.00013,0,0,1,16,18Z"/></svg>';
    let svgCircle = '<svg width="120" height="120" version="1.1" fill="white" xmlns="http://www.w3.org/2000/svg"><circle cx="60" cy="60" r="60"/></svg>';
    let svg = '<svg id="glyphicons-basic" width="80" height="80" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="home-flag" d="M27.10482,17H24V27.5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5V17H4.89518a.785.785,0,0,1-.55508-1.34008L15.46967,4.53023a.75.75,0,0,1,1.06066,0L27.6599,15.65992A.785.785,0,0,1,27.10482,17ZM24,9.17188V6.57141l4.92767-2.11188a.5.5,0,0,0,0-.91912L24,cellLength859V1a1,1,0,0,0-2,0V7.17188Z"/></svg>';

    let selectedHome = this.props.HomeSelected[Object.keys(this.props.HomeSelected)[0]];
    let newHome = new Point(fromLonLat([selectedHome.Longitude, selectedHome.Latitude]));

    if (!homeLayer) {

      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,
        style: () => {
          return [new Style({
            image: new Icon({
              src: 'data:image/svg+xml;utf8,' + svgCircle,
              color: 'white',
              scale: .43
            }),
          }), new Style({
            image: new Icon({
              src: 'data:image/svg+xml;utf8,' + svgCircle,
              color: 'black',
              scale: .4
            }),
          }), new Style({
            image: new Icon({
              src: 'data:image/svg+xml;utf8,' + svg,
              scale: .55,
              color: 'orange'
            })
          })];
        }
      })

      layer.setZIndex(30);
      layer.LayerId = 'Home';
      layer.Vector = vector;
      this.props.map.addLayer(layer);

      vector.addFeature(new Feature({
        geometry: newHome,
      }))
    } else {
      vector = homeLayer.Vector;
      let homeFeature = vector.getFeatures()[0];
      homeFeature.setGeometry(newHome);
    }

    let extent = vector.getFeatures()[0].getGeometry().getExtent();
    if (window.IsMobile) {
      this.props.map.getView().fit(extent, { maxZoom: 18 });
    } else {
      setTimeout(() => {
        this.props.map.getView().fit(extent, { duration: 250, easing: inAndOut, maxZoom: 18 });
      }, 100);
    }

    let format = new WKT();
    //new Point(this.props.map.getView().getCenter()) not giving correct center? using geo given from map search
    let centerPoint = format.writeGeometry(vector.getFeatures()[0].getGeometry(), { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });

    //centerpoint isn't centerpoint of map, can use this.props.map.getView().getCenter() to get coords but needs converted to geo for db
    this.setGobKey('ms_AddressSelected', { Address: selectedHome.Address, SearchedText: selectedHome.SearchedText, CenterPoint: centerPoint, HouseAddress: selectedHome.HouseAddress, State: selectedHome.State, City: selectedHome.City, Zip: selectedHome.Zip });
  }

  setTotalEstimate = () => {
    let lowTotal = 0;
    let highTotal = 0;
    let singleTotal = 0;
    let selectedPolygons = this.props.SelectedPolygons.slice();
    let hidePricingText = null;
    selectedPolygons.forEach(x => {
      x.Id = selectedPolygons.indexOf(x) + 1;
      if (x.lowCost && x.highCost) {
        lowTotal += Number(x.lowCost);
        highTotal += Number(x.highCost);
      }
      if(!x.Amount.includes("$") && !x.Amount.includes("-")) {
        hidePricingText = x.Amount;
      }
      if (x.lowCost && x.highCost && (x.lowCost === x.highCost)) {
        singleTotal += Number(x.lowCost);
      }
    })

    let totalEstimate = ''
    if (hidePricingText) {
      totalEstimate = hidePricingText;
    } else if (singleTotal > 0) {
      totalEstimate = '$' + singleTotal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
      totalEstimate = '$' + lowTotal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' - $' + highTotal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    this.setGobKey('ms_TotalEstimate', totalEstimate);
    this.setGobKey('ms_SelectedPolygons', selectedPolygons);
  }

  deleteFeature = (openlayersId) => {
    let search = window.location.search
    this.setGobKey('ms_DeleteFeature', null);

    if(search.includes('&consumerId') && !this.props.CreatedNew) {
      let selectedPolygons = this.props.SelectedPolygons;
      let deletedPolygon = selectedPolygons.find(x => x.ol_uid == openlayersId);
      deletedPolygon.isDeleted = true
      this.savePlan(deletedPolygon)
    } else {
      let selectedPolygons = this.props.SelectedPolygons;
      let deletedPolygon = selectedPolygons.find(x => x.ol_uid == openlayersId);
      let newPolygons = selectedPolygons.filter(x => x.ol_uid !== openlayersId);
      newPolygons.forEach(x => {
        x.Id = newPolygons.indexOf(x) + 1;
      })
      if (deletedPolygon.Type === 'fence_Fence') {
        newPolygons = newPolygons.filter(x => x.fence_ol_uid != deletedPolygon.ol_uid);
      }
      this.setGobKey('ms_SelectedPolygons', newPolygons);
  
      if (deletedPolygon.Type === 'fence_Gate') {
        let gateSource = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Gate').getSource();
        if (gateSource) {
          let featuresList = gateSource.getFeatures();
          featuresList.forEach(x => {
            if (x.ol_uid == openlayersId) {
              gateSource.removeFeature(x);
            }
          })
        }
      } else if (deletedPolygon.Type === 'deck_Stair' || deletedPolygon.Type === 'deck_Stair_Stain') {
        let deckSource = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Stair').getSource();
        if (deckSource) {
          let featuresList = deckSource.getFeatures();
          featuresList.forEach(x => {
            if (x.ol_uid == openlayersId) {
              deckSource.removeFeature(x);
            }
          })
        }
      } else {
        let vectorSource = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
        let featuresList = vectorSource.getFeatures();
        featuresList.forEach(x => {
          if (x.ol_uid == openlayersId) {
            vectorSource.removeFeature(x);
          }
        })
      }
  
      this.clearTooltipsByOlUid(openlayersId);
  
      //Delete any gate on the fence
      let saveData = [];
      if (deletedPolygon.Type === 'fence_Fence') {
        let gatesToDelete = selectedPolygons.filter(y => y.fence_ol_uid == deletedPolygon.ol_uid);
        let gateVector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Gate');
        let gateSource = gateVector ? gateVector.getSource() : null;
  
        gatesToDelete.forEach(x => {
          if (gateSource) {
            let featuresList = gateSource.getFeatures();
            gateSource.removeFeature(featuresList.find(y => y.ol_uid == x.ol_uid));
          }
  
          let baseObjGate = {
            Id: x.planId,
            Table: 'xmv.Plans',
            IsBaseTable: true
          }
          if (baseObjGate.Id) {
            saveData.push(helpers.saveDataItem(baseObjGate, 'IsDeleted', true));
          }
        })
      }
  
      //Save IsDeleted = 1 in xmv.Plans
      let baseObj = {
        Id: deletedPolygon.planId,
        Table: 'xmv.Plans',
        IsBaseTable: true
      };
      if (baseObj.Id) { // if Id is null creates 32,000 new xmv.Plans records with isDeleted = 1 and other columns null
        saveData.push(helpers.saveDataItem(baseObj, 'IsDeleted', true));
        actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
          this.props.dispatch(actions.UpdateProp({
            Key: 'dbo_SaveId',
            Value: this.props.SaveId + 1
          }));
          //call cost calculation
          this.setGobKey('sr_MV_GetCost', true);
        });
      }
    }
  }

  doubleClickZoomEnabled = (isActive) => {
    var interactions = this.props.map.getInteractions().getArray();
    interactions.forEach(x => {
      if (x instanceof clickZoom) {
        x.setActive(isActive);
      }
    });
  }

  lineSnappingEnabled = (isActive) => {
    var interactions = this.props.map.getInteractions().getArray();
    let snap = interactions.find(x => x instanceof Snap && x.Id === 'lineSnap');
    if (snap) {
      this.props.map.removeInteraction(snap);
    }
    if (isActive) {
      let vector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
      snap = new Snap({
        source: vector,
        pixelTolerance: window.IsMobile ? 28 : 7
      })
      snap.Id = 'lineSnap';
      this.props.map.addInteraction(snap);
    }

  }

  toggleGuidelines = (enableGuidelines = !this.state.Guidelines) => {
    let enable = enableGuidelines;
    var interactions = this.props.map.getInteractions().getArray();
    interactions.forEach(x => {
      if (x instanceof SnapGuides) {
        x.setActive(enable);
        this.setState({ Guidelines: enable });
      }
    });
  }

  refreshMapLayer = () => {
    this.props.map.getLayers().getArray().forEach(x => {
      if (x.Name === 'CurrentEntityLayer') {
        x.getSource().refresh();
      }
    })
  }

  createMeasurementObject = (geo, existingItem) => {
    let format = new WKT();
    let rawCoords = geo.getCoordinates();
    let formattedGeo = format.writeGeometry(geo);
    let measurement = '';
    let units = '';

    if (formattedGeo.includes('LINE')) { //Calculate length
      if (this.props.UseMetric) {
        measurement = this.getMLengthFromGeometry(geo);
        units = 'Meters';
      } else {
        measurement = this.getFtLengthFromGeometry(geo);
        units = 'Feet';
      }
    } else { //Calculate area
      if (this.props.UseMetric) {
        measurement = this.getSqMAreaFromGeometry(geo);
        units = 'Square Meters';
      } else {
        measurement = this.getSqFtAreaFromGeometry(geo);
        units = 'Square Feet';
      }
    }

    let newTotal = 0;
    let lowCost = 0;
    let highCost = 0;
    if (existingItem) {
      newTotal = Number((existingItem.Measurement * (existingItem.CostLinearFeet || 1)).toFixed()); //to do - if CostLinearFeet doesn't exist do something
      lowCost = (newTotal + (newTotal * .02)).toFixed().toString();
      highCost = (newTotal + (newTotal * .25)).toFixed().toString();
    }

    let formattedCost = '-';
    if (lowCost && highCost) {
      formattedCost = '$' + lowCost.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' - $' + highCost.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    let formattedMeasurement = measurement.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    switch (units) {
      case 'Feet':
        formattedMeasurement = formattedMeasurement + ' ft'
        break;
      case 'Square Feet':
        formattedMeasurement = formattedMeasurement + ' ft²'
        break;
      case 'Meters':
        formattedMeasurement = formattedMeasurement + ' m'
        break;
      case 'Square Meters':
        formattedMeasurement = formattedMeasurement + ' m²'
        break;
    }

    let returnObj = { ...existingItem, RawCoords: rawCoords, Geometry: geo, Measurement: measurement, Amount: formattedCost, Text2: formattedMeasurement, /*Text3: formattedCost, lowCost: lowCost, highCost: highCost*/ };
    if (this.props.MapAction && this.props.MapAction.Name) {
      returnObj.Text1 = this.props.MapAction.Name;
      returnObj.Type = this.props.MapAction.Value;
      returnObj.EntityTypeId = this.props.MapAction.EntityTypeId;
    }

    return returnObj;
  }

  formatLength = (line) => {
    let length = getLength(line);
    let roundedUnit;
    if (this.props.UseMetric) {
      roundedUnit = Math.round(length * 100 / 100) + ' m';
    } else {
      length = length * 3.28084;
      roundedUnit = Math.round(length * 100 / 100) + ' ft';
    }
    let output = roundedUnit.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return output;
  }

  formatArea = (polygon) => {
    let area = getArea(polygon);
    let roundedUnit;
    if (this.props.UseMetric) {
      roundedUnit = Math.round(area * 100 / 100) + ' ' + 'm²';
    } else {
      area = area * 10.7639;
      roundedUnit = Math.round(area * 100 / 100) + ' ' + 'ft²';
    }
    let output = roundedUnit.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return output;
  };

  getSqMAreaFromGeometry = (polygon) => {
    let area = getArea(polygon);
    let output = Math.round(area * 100 / 100);
    return output;
  }

  getMLengthFromGeometry = (line) => {
    let length = getLength(line);
    let output = Math.round(length * 100 / 100);
    return output;
  }

  getSqFtAreaFromGeometry = (polygon) => {
    let area = getArea(polygon);
    let output = area * 10.7639;
    output = Math.round(output * 100 / 100);
    return output;
  }

  getFtLengthFromGeometry = (line) => {
    let length = getLength(line);
    let output = length * 3.28084;
    output = Math.round(output * 100 / 100);
    return output;
  }

  finishLineDrawing = (draw) => {

    this.setState({ helptextModal: true });
    this.rotateHelpTriangleDown();

    this.props.map.removeInteraction(draw);

    this.doubleClickZoomEnabled(false);
    setTimeout(() => { this.doubleClickZoomEnabled(true); }, 251);

    //get all linestrings with drawId of draw_ol_uid
    //create multilinestring and replace all individual line features with it
    let vector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
    let allFeatures = vector.getFeatures();
    let features = [];
    // features.push(allFeatures.filter(x => x.drawId == draw.ol_uid).getGeometry().getCoordinates());
    allFeatures.forEach(x => {
      if (x.drawId == draw.ol_uid) {
        vector.removeFeature(x);
        features.push(x.getGeometry().getCoordinates());
      }
    })

    let multiLineString = new MultiLineString(features);

    multiLineString = this.combineLineStrings(multiLineString);

    let feature = new Feature(multiLineString);
    vector.addFeature(feature);
    multiLineString = vector.getFeatures()[vector.getFeatures().length - 1];
    multiLineString.geometry = 'LINESTRING';
    this.lineSnappingEnabled(false);

    let ol_uid = multiLineString.ol_uid;

    console.log({ Listeners: multiLineString.getGeometry().listeners_ });

    if (!multiLineString.hasChangeListener) {
      multiLineString.hasChangeListener = true;
      multiLineString.getGeometry().on('change', (evt) => {
        //remove existing measurement tooltips for this ol_uid
        this.clearTooltipsByOlUid(ol_uid);

        //add new measurement based on each segment
        let allCoords = evt.target.getCoordinates();
        // console.log({ coords: allCoords });

        let measureTooltip;
        let measureTooltipElement;

        let createMeasureTooltip = () => {
          // if (measureTooltipElement) {
          //   measureTooltipElement.parentNode.removeChild(measureTooltipElement);
          // }
          measureTooltipElement = document.createElement('div');
          measureTooltipElement.className = 'ol-tooltip-clickthrough ol-tooltip-measure';
          measureTooltipElement.ol_uid = ol_uid;
          measureTooltip = new Overlay({
            element: measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
            className: 'ol-clickthrough'
          });
          this.props.map.addOverlay(measureTooltip);
        }

        allCoords.forEach(linestring => {
          linestring.forEach((point, index) => {
            if (!linestring[index + 1]) { return; }
            let line = new LineString([linestring[index], linestring[index + 1]]);

            createMeasureTooltip();

            let tooltipCoord = line.getCoordinateAt(0.5);
            measureTooltipElement.innerHTML = this.formatLength(line);
            measureTooltip.setPosition(tooltipCoord);
          })
        })
      });
    } else {
      console.log('HAS CHANGE LISTENER TRUE');
    }

    let extent = multiLineString.getGeometry().getExtent();
    setTimeout(() => {
      this.props.map.getView().fit(buffer(extent, 15), { duration: 600/*, easing: inAndOut*/ });
    }, 100);

    let tooltips = Array.prototype.slice.call(document.getElementsByClassName('ol-tooltip-clickthrough')).filter(x => x.ol_uid == 'unsaved');
    tooltips.forEach(x => {
      x.ol_uid = feature.ol_uid;
    })

    batch(() => {
      this.setGobKey('ms_DrawingActive', false);
      this.modifyEnabled(true);
      this.setGobKey('ms_ActiveModifyId', multiLineString.ol_uid);
    });
  }

  combineLineStrings = (multiLineString) => {

    const combinedLineStrings = [];
    const lineStrings = multiLineString.getLineStrings();

    const isEqualCoordinate = (coordinateA, coordinateB) => {
      return coordinateA[0] === coordinateB[0] && coordinateA[1] === coordinateB[1];
    };

    lineStrings.forEach(lineString => {
      const coordinates = lineString.getCoordinates();

      if (combinedLineStrings.length === 0) {
        // Add the first LineString
        combinedLineStrings.push(coordinates);
      } else {
        let combined = false;

        combinedLineStrings.forEach(combinedLineString => {
          const lastCoordinate = combinedLineString[combinedLineString.length - 1];
          const firstCoordinate = combinedLineString[0];
          const startPoint = coordinates[0];
          const endPoint = coordinates[coordinates.length - 1];

          if (isEqualCoordinate(lastCoordinate, startPoint)) {
            // Combine with the end of the existing combined LineString
            combinedLineString.push(...coordinates.slice(1));
            combined = true;
          } else if (isEqualCoordinate(firstCoordinate, endPoint)) {
            // Combine with the start of the existing combined LineString
            combinedLineString.unshift(...coordinates.slice(0, -1));
            combined = true;
          }
        });

        if (!combined) {
          // Add a new LineString
          combinedLineStrings.push(coordinates);
        }
      }
    });

    return new MultiLineString(combinedLineStrings);
  }

  finishDrawing = () => {
    if (this.props.Crosshair) {
      this.setGobKey('ms_ModifyActive', false);
      this.rooftopGeo();
      return;
    }
    let draw = this.props.map.getInteractions().getArray().find(x => x instanceof Draw);
    if (draw) { //Sending user to 'edit mode'
      if (!draw.sketchCoords_) {
        this.setGobKey('blu_Dialog', { Title: 'No Drawing', Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>Please use the map to draw your items before proceeding by clicking <b><i>Next</i></b>.</p> });
        return;
      }
      if (draw.mode_ === 'Polygon') {
        draw.finishDrawing();
      } else {
        this.finishLineDrawing(draw);
      }
    } else {
      let selectedPolygons = this.props.SelectedPolygons.slice();
      let vector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
      let allFeatures = vector.getFeatures();
      let feature = null;
      let activeSelectedPolygon = null;
      allFeatures.forEach(x => {
        if (x.ol_uid == this.props.ActiveModifyId) {
          feature = x;
          activeSelectedPolygon = selectedPolygons.find(x => x.ol_uid == this.props.ActiveModifyId);
        }
      })
      if (!activeSelectedPolygon) {
        activeSelectedPolygon = selectedPolygons.find(x => !x.ol_uid);
      }

      feature.unsavedRooftop = false;
      let geometry = feature.getGeometry();
      let obj = this.createMeasurementObject(geometry, activeSelectedPolygon);
      obj.ol_uid = feature.ol_uid;


      if (activeSelectedPolygon) {
        selectedPolygons[selectedPolygons.indexOf(activeSelectedPolygon)] = obj;
      } else {
        selectedPolygons.push(obj);
      }
      this.setGobKey('ms_SelectedPolygons', selectedPolygons);
      this.setGobKey('sr_MapSnapshot', true);
      let newObj = selectedPolygons.find(x => x.Id == obj.Id);
      this.savePlan(newObj);
      this.setGobKey('ms_currentMeasure', null);
      // this.clearAllTooltips();
      this.modifyEnabled(false);
      // let tooltips = Array.prototype.slice.call(document.getElementsByClassName('ol-tooltip-clickthrough'));
      // tooltips.forEach(tooltip => {
      //   this.props.SelectedPolygons.forEach(poly => {
      //     if (tooltip.innerHTML.includes(poly.Measurement) || tooltip.innerHTML.includes(poly.Measurement + 1) || tooltip.innerHTML.includes(poly.Measurement - 1)) {
      //       tooltips.splice(tooltips.indexOf(tooltip), 1);
      //     }
      //   })
      // })
      // tooltips.forEach(x => {
      //   x.parentNode.removeChild(x);
      // })

      // this.enableGrid(false);
      feature.setStyle(inactiveStyle);
      if (this.props.MapAction && this.props.MapAction.ImageCatalog) {
        let catalog = this.props.MapAction.ImageCatalog;
        catalog.forEach(x => x.Type = this.props.MapAction.Value);
        this.setGobKey('ms_CurrentCatalog', (this.props.MapAction.ImageCatalog));
        this.setGobKey('MainTabContent', { Value: 'MySalesmanImageGrouping', Enabled: true });
      } else if (this.props.NextMapAction && this.props.NextMapAction.ImageCatalog) {
        let catalog = this.props.NextMapAction.ImageCatalog;
        catalog.forEach(x => x.Type = this.props.NextMapAction.Value);
        this.setGobKey('ms_CurrentCatalog', (this.props.NextMapAction.ImageCatalog));
        this.setGobKey('ms_MySalesMapActionNext', null);
        this.setGobKey('MainTabContent', { Value: 'MySalesmanImageGrouping', Enabled: true });
      } else if (window.IsMobile) {
        this.props.dispatch(actions.UpdateProp({
          Key: 'MainTabContent',
          Value: { Value: 'Step3Panel', Enabled: true }
        }))
        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_ActiveModifyId',
          Value: null
        }))
      } else {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ms_ActiveModifyId',
          Value: null
        }))
      }

      this.setGobKey('ms_MySalesMapAction', null);
    }
  }

  savePlan = (item) => {
    let search = window.location.search
    var format = new WKT();

    //normal save for consumer bid creation
    if (!search.includes('&consumerId')) {
      let saveData = [];
      let firstSave = item.planId ? false : true;
      let selectedPolygons = this.props.SelectedPolygons;
      let currentUserInfo = this.props.currentUserInfo

      let fName = currentUserInfo.find(x => x.Name === 'FirstName').Value
      let lName = currentUserInfo.find(x => x.Name === 'LastName').Value
      let phone = currentUserInfo.find(x => x.Name === 'PhoneNumber').Value
      let email = currentUserInfo.find(x => x.Name === 'EMail').Value

      this.setGobKey('ms_ContactInfo', { Properties: { fName: fName, lName: lName, phone: phone, email: email } })

      //Save call to xmv.Plan and xmv.PlanItem, using planId - will only be 1-1 on planItems to Plan for fence/powerwash up until posts
      let baseObj = {
        Id: item.planId,
        Table: 'xmv.Plans',
        EntityTypeId: 2099,
        IsBaseTable: true
      };
      if (firstSave) {
        baseObj.Id = null;
        baseObj.InsertKey = helpers.getInsertKey();
        saveData.push(helpers.saveDataItem(baseObj, 'ConsumerId', this.props.ActiveConsumerId));
        saveData.push(helpers.saveDataItem(baseObj, 'EntityTypeId', item.EntityTypeId));
        saveData.push(helpers.saveDataItem(baseObj, 'IsDeleted', 0));
        saveData.push(helpers.saveDataItem(baseObj, 'TenantId', this.props.EntityMetadata.CurrentTenant));
        if (item.Type === 'fence_Gate') {
          let parentPlanId = selectedPolygons.find(x => x.ol_uid == item.fence_ol_uid).planId;
          saveData.push(helpers.saveDataItem(baseObj, 'ParentId', parentPlanId));
          if (item.Text2 === 'Double Gate') {
            saveData.push(helpers.saveDataItem(baseObj, 'IsDoubleGate', 1));
          } else {
            saveData.push(helpers.saveDataItem(baseObj, 'IsDoubleGate', 0));
          }
        }

        //add number of stairs
        if(item.Type === 'deck_Stair' || item.Type === 'deck_Stair_Stain') {
          let parentPlanId = selectedPolygons.find(x => x.ol_uid == item.deck_ol_uid).planId;
          saveData.push(helpers.saveDataItem(baseObj, 'ParentId', parentPlanId))
          saveData.push(helpers.saveDataItem(baseObj, 'StairQuantity', this.props.StairQuantity))
        }

        //check for gates that were added before fence plan was saved
        if (item.EntityTypeId == 2058 && item.ol_uid) {
          let gates = selectedPolygons.filter(x => x.EntityTypeId == 2059 && !x.fence_ol_uid);
          gates.forEach(x => {
            let gateBaseObj = {
              Id: x.planId,
              Table: 'xmv.Plans',
              EntityTypeId: 2099,
              IsBaseTable: false
            };
            saveData.push(helpers.saveDataItem(gateBaseObj, 'ParentId', baseObj.InsertKey));
            selectedPolygons.find(y => y.planId == x.planId).fence_ol_uid = item.ol_uid;
          })
        }
      }
      if (item.Type !== 'fence_Remove' && item.Type !== 'deck_Railing_Stain') { //No geo to save
        saveData.push(helpers.saveDataItem(baseObj, 'Geometry', format.writeGeometry(item.Geometry, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })));
      }
      saveData.push(helpers.saveDataItem(baseObj, 'Measurement', item.Measurement));

      actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
        if (result.Id) {
          item.planId = result.Id
        }
        this.props.dispatch(actions.UpdateProp({
          Key: 'dbo_SaveId',
          Value: this.props.SaveId + 1
        }));

        this.setGobKey('sr_UserInfoSubmit', true)

        //call cost calculation
        if ((item.EntityTypeId === 2058 && item.planItemId) || item.EntityTypeId !== 2058) {
          this.setGobKey('sr_MV_GetCost', true);
        }
      });

      let vector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure');
      if (vector) {
        let features = vector.getSource().getFeatures();
        setTimeout(() => {
          features.map(x => x.setStyle(inactiveStyle));
          this.setGobKey('sr_MapSnapshot', true);
        }, 0);
      }

      this.setGobKey('ms_StairQuantity',null)

      return item;
    } else if (this.props.CreatedNew) {
      let saveData = [];
      let firstSave = item.planId ? false : true;
      let selectedPolygons = this.props.SelectedPolygons;

      //Save call to xmv.Plan and xmv.PlanItem, using planId - will only be 1-1 on planItems to Plan for fence/powerwash up until posts
      let baseObj = {
        Id: item.planId,
        Table: 'xmv.Plans',
        EntityTypeId: 2099,
        IsBaseTable: true
      };

      if (!item.CostLinearFeet && this.props.OriginalModifyId) {
        baseObj.Id = selectedPolygons.find(e => e.Id === this.props.OriginalModifyId).planId
        firstSave = false
      }

      if (firstSave) {
        baseObj.Id = null;
        baseObj.InsertKey = helpers.getInsertKey();
        saveData.push(helpers.saveDataItem(baseObj, 'ConsumerId', this.props.ActiveConsumerId));
        saveData.push(helpers.saveDataItem(baseObj, 'EntityTypeId', item.EntityTypeId));
        saveData.push(helpers.saveDataItem(baseObj, 'IsDeleted', 0));
        saveData.push(helpers.saveDataItem(baseObj, 'TenantId', this.props.EntityMetadata.CurrentTenant));
        if (item.Type === 'fence_Gate') {
          let parentPlanId = selectedPolygons.find(x => x.ol_uid == item.fence_ol_uid).planId;
          saveData.push(helpers.saveDataItem(baseObj, 'ParentId', parentPlanId));
          if (item.Text2 === 'Double Gate') {
            saveData.push(helpers.saveDataItem(baseObj, 'IsDoubleGate', 1));
          } else {
            saveData.push(helpers.saveDataItem(baseObj, 'IsDoubleGate', 0));
          }
        }

        //add number of stairs
        if(item.Type === 'deck_Stair' || item.Type === 'deck_Stair_Stain') {
          saveData.push(helpers.saveDataItem(baseObj, 'StairQuantity', this.props.StairQuantity))
        }

        //check for gates that were added before fence plan was saved
        if (item.EntityTypeId == 2058 && item.ol_uid) {
          let gates = selectedPolygons.filter(x => x.EntityTypeId == 2059 && !x.fence_ol_uid);
          gates.forEach(x => {
            let gateBaseObj = {
              Id: x.planId,
              Table: 'xmv.Plans',
              EntityTypeId: 2099,
              IsBaseTable: false
            };
            saveData.push(helpers.saveDataItem(gateBaseObj, 'ParentId', baseObj.InsertKey));
            selectedPolygons.find(y => y.planId == x.planId).fence_ol_uid = item.ol_uid;
          })
        }
      }

      if (item.Type !== 'fence_Remove' && item.Type !== 'deck_Railing_Stain') { //No geo to save
        saveData.push(helpers.saveDataItem(baseObj, 'Geometry', format.writeGeometry(item.Geometry, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })));
      }
      saveData.push(helpers.saveDataItem(baseObj, 'Measurement', item.Measurement));

      actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
        if (result.Id) {
          item.planId = result.Id
        }
        this.props.dispatch(actions.UpdateProp({
          Key: 'dbo_SaveId',
          Value: this.props.SaveId + 1
        }));

        if (this.props.OriginalModifyId) {
          let index = selectedPolygons.findIndex(e => e.Id === this.props.OriginalModifyId)
          if (index > -1) {
            selectedPolygons[index].Measurement = saveData.find(e => e.Column === 'Measurement').Value
            selectedPolygons[index].Geometry = saveData.find(e => e.Column === 'Geometry').value
            selectedPolygons[index].Text2 = item.Text2
            this.setGobKey('ms_SelectedPolygons', selectedPolygons)
          }
        }

        this.setGobKey('ms_OriginalModifyId', null)

        this.setGobKey('sr_UserInfoSubmit', true)

        //call cost calculation
        if ((item.EntityTypeId === 2058 && item.planItemId) || item.EntityTypeId !== 2058) {
          this.setGobKey('sr_MV_GetCost', true);
        }

        this.setGobKey('ms_StairQuantity',null)
      });

      let vector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure');
      if (vector) {
        let features = vector.getSource().getFeatures();
        setTimeout(() => {
          features.map(x => x.setStyle(inactiveStyle));
          this.setGobKey('sr_MapSnapshot', true);
        }, 0);
      }

      return item;
    } else {
      //         -------------- FIRST edit mode logic ----------------
      var saveData = []
      var newConsumerId
      var selectedPolys = this.props.SelectedPolygons
      var planInsertKeys = []
      var firstSave = false

      let body = {
        EntityId: this.props.ActiveConsumerId,
        EntityTypeId: 2040001
      }

      //get consumer information for final summary page
      actions.ApiRequest('Entity/GetEntity', body, (result) => {
        let baseConsumerObj = {
          InsertKey: helpers.getInsertKey(),
          Table: 'xmv.Consumer',
          IsBaseTable: true,
          EntityTypeId: 2040
        }

        this.props.dispatch(actions.UpdateProp({ Key: 'dbo_SaveData', Value: [] }));

        saveData.push(helpers.saveDataItem(baseConsumerObj, 'TenantId', this.props.EntityMetadata.CurrentTenant));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'LocationSearch', ''));

        let fName = result.Properties.find((e) => { if (e.Name === 'FirstName') return e }).Value
        let lName = result.Properties.find((e) => { if (e.Name === 'LastName') return e }).Value
        let email = result.Properties.find((e) => { if (e.Name === 'EMail') return e }).Value
        let phone = result.Properties.find((e) => { if (e.Name === 'PhoneNumber') return e }).Value
        let address = result.Properties.find((e) => { if (e.Name === 'Address') return e }).Value
        let state = result.Properties.find((e) => { if (e.Name === 'State') return e }).Value
        let city = result.Properties.find((e) => { if (e.Name === 'City') return e }).Value
        let zip = result.Properties.find((e) => { if (e.Name === 'Zip') return e }).Value

        saveData.push(helpers.saveDataItem(baseConsumerObj, 'Geometry', result.Geometry));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'Address', address));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'State', state));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'City', city));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'Zip', zip));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'FirstName', fName));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'LastName', lName));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'PhoneNumber', phone));
        saveData.push(helpers.saveDataItem(baseConsumerObj, 'EMail', email));

        let progBaseObj = {
          InsertKey: helpers.getInsertKey(),
          Table: 'xmv.Progress',
          IsBaseTable: false
        };

        saveData.push(helpers.saveDataItem(progBaseObj, 'ConsumerId', helpers.getInsertKey()));
        saveData.push(helpers.saveDataItem(progBaseObj, 'Step', 'Step3Panel'));

        this.setGobKey('ms_AddressSelected', { Address: `${address}, ${city}, ${state} ${zip}` });
        this.setGobKey('ms_ContactInfo', { Properties: { fName: fName, lName: lName, phone: phone, email: email } })

        actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
          this.props.dispatch(actions.UpdateProp({
            Key: 'dbo_SaveId',
            Value: this.props.SaveId + 1
          }));

          var polySaveData = []
          newConsumerId = result.Id
          this.setGobKey('ms_ActiveConsumerId', newConsumerId)

          if(planInsertKeys.length === 0) {
            selectedPolys.map((z) => {
              if(z.planId !== null) {
                let key = helpers.getInsertKey()
                planInsertKeys.push({oldId: z.planId, insertKey: key})
              }
            })

            let newGateIndex = selectedPolys.findIndex(x => x.ol_uid === item.ol_uid)
            if((item.Type === 'fence_Gate' || item.Type === 'deck_Stair' || item.Type === 'deck_Stair_Stain') && newGateIndex === -1) {
              let modifiedItem = item
              let parentPlanId = selectedPolys.find(p => p.ol_uid === item.fence_ol_uid).planId
              modifiedItem.parentId = parentPlanId
              selectedPolys.push(item)
            }

            if(item.isDeleted) {
              selectedPolys = selectedPolys.filter(e => e.ol_uid !== item.ol_uid)
              selectedPolys = selectedPolys.filter(e => e.parentId !== item.planId)
            }

            selectedPolys.map((e) => {
              var value
              if(e.Id === item.Id && e.ol_uid !== item.ol_uid) {
                value = item
              } else {
                value = e
              }

              //update geo if it is a modified item
              if (this.props.OriginalModifyId === value.Id) {
                value.Measurement = item.Measurement
                value.Geometry = item.Geometry
              }

              let polyInsertKey = planInsertKeys.find(y => y.oldId === value.planId)
              if(polyInsertKey !== undefined) {
                polyInsertKey = polyInsertKey.insertKey
              } else {
                polyInsertKey = helpers.getInsertKey()
              }
              let planItemInsertKey = helpers.getInsertKey()
  
              let polyBaseObj = {
                InsertKey: polyInsertKey,
                Table: 'xmv.Plans',
                EntityTypeId: 2099,
                IsBaseTable: true
              }
              
              polySaveData.push(helpers.saveDataItem(polyBaseObj, 'ConsumerId', newConsumerId));
              polySaveData.push(helpers.saveDataItem(polyBaseObj, 'EntityTypeId', value.EntityTypeId));
              polySaveData.push(helpers.saveDataItem(polyBaseObj, 'IsDeleted', 0));
              polySaveData.push(helpers.saveDataItem(polyBaseObj, 'TenantId', this.props.EntityMetadata.CurrentTenant));
              
              if(value.CatalogSelected && value.CatalogSelected.length > 0) {
                let catalogData = value.CatalogSelected[0]
                let planItemBaseObj = {
                  InsertKey: planItemInsertKey,
                  Table: 'xmv.PlanItem',
                  EntityTypeId: 2098,
                  IsBaseTable: true
                }
  
                polySaveData.push(helpers.saveDataItem(planItemBaseObj, 'PlanId', polyInsertKey))
                polySaveData.push(helpers.saveDataItem(planItemBaseObj, 'IsDeleted', 0))
                polySaveData.push(helpers.saveDataItem(planItemBaseObj, 'ItemId1', catalogData.ItemId1))
                polySaveData.push(helpers.saveDataItem(planItemBaseObj, 'ItemId2', catalogData.ItemId2))
                polySaveData.push(helpers.saveDataItem(planItemBaseObj, 'ItemId3', catalogData.ItemId3))
                polySaveData.push(helpers.saveDataItem(planItemBaseObj, 'ItemId4', catalogData.ItemId4))
              }
  
              if(parseInt(value.parentId) > 0) {
                let newParentId = planInsertKeys.find(x => x.oldId === value.parentId).insertKey
                polySaveData.push(helpers.saveDataItem(polyBaseObj, 'ParentId', newParentId))
              }
              if (typeof value.Geometry === 'string' || value.Geometry instanceof String) {
                polySaveData.push(helpers.saveDataItem(polyBaseObj, 'Geometry', value.Geometry));
              } else {
                if(value.Geometry) {
                  polySaveData.push(helpers.saveDataItem(polyBaseObj, 'Geometry', format.writeGeometry(value.Geometry, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })));
                }
              }
  
              if (value.Type === 'fence_Gate') {
                if (value.Text2 === 'Double Gate') {
                  polySaveData.push(helpers.saveDataItem(polyBaseObj, 'IsDoubleGate', 1));
                } else {
                  polySaveData.push(helpers.saveDataItem(polyBaseObj, 'IsDoubleGate', 0));
                }
              } else if (value.Type === 'deck_Stair' || value.Type === 'deck_Stair_Stain') {
                let stairs = parseInt(value.Text2)
                polySaveData.push(helpers.saveDataItem(polyBaseObj, 'StairQuantity', stairs))
              }
              
              if(value.Measurement !== null) {
                polySaveData.push(helpers.saveDataItem(polyBaseObj, 'Measurement', value.Measurement ));
              }
            })
  
            actions.ApiRequest('Save/Save', { SaveData: polySaveData }, (result) => { 
              this.props.dispatch(actions.UpdateProp({
                Key: 'dbo_SaveId',
                Value: this.props.SaveId + 1
              }));
      
              let updateBody = {
                SWID: 5472,
                Parameter: JSON.stringify({ ConsumerId: newConsumerId, TenantId: this.props.EntityMetadata.CurrentTenant })
              }
      
              actions.ApiRequest('List/GetList', updateBody, (result3) => {
                let new_results = result3
                new_results.forEach((e,idx) => {
                  if(e.planItemId && e.Measurement && e.Type !== 'deck_Railing_Stain' && e.Type !== 'fence_Remove') {
                    let index = this.props.SelectedPolygons.findIndex(x => x.Measurement == e.Measurement)
                    if(index > -1) {
                      new_results[idx].CatalogOptions = this.props.SelectedPolygons[index].CatalogOptions.filter(x => x.EntityTypeId1 == new_results[idx].EntityTypeId)
                      new_results[idx].CatalogSelected = this.props.SelectedPolygons[index].CatalogSelected
                    }
                  }
                })
      
                this.clearAllMeasures();
                let view = new View(this.props.MapOptions);
                this.props.map.setView(view);
      
                let tenant = this.props.EntityMetadata.UserTenants.find(x => x.Id == this.props.EntityMetadata.CurrentTenant);
                let bounds = JSON.parse(tenant.Bounds);
                var coordMin = fromLonLat([bounds[0], bounds[1]], 'EPSG:3857');
                var coordMax = fromLonLat([bounds[2], bounds[3]], 'EPSG:3857');
                view.fit([coordMin[0], coordMin[1], coordMax[0], coordMax[1]], this.props.map.getSize());
      
                this.props.dispatch(actions.UpdateProp({
                  Key: 'dbo_SaveId',
                  Value: this.props.SaveId + 1
                }));
      
                let vector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure');
                if (vector) {
                  let features = vector.getSource().getFeatures();
                  setTimeout(() => {
                    features.map(x => x.setStyle(inactiveStyle));
                    this.setGobKey('sr_MapSnapshot', true);
                  }, 0);
                }
      
                this.setGobKey('ms_OriginalModifyId', null)
                this.setGobKey('ms_StairQuantity',null)
      
                this.setGobKey('ms_SelectedPolygons', new_results)
      
                this.setGobKey('sr_UserInfoSubmit', true)

                this.setGobKey('ms_EditModMeasure',item.Measurement)
                this.consumerLoaded(new_results)
                this.setGobKey('sr_MV_GetCost', true);
              })
            })
          }
        });
      })
      this.setGobKey('ms_CreatedNew', true)
      return item;
    }
  }

  undoLastPoint = () => {
    let draw = this.props.map.getInteractions().getArray().find(x => x instanceof Draw);
    if (draw) {
      if (draw.maxPoints_ == 2) {
        //get features from 'Measure' vectorsource with a drawId of draw.ol_uid and remove the recently added one
        let measureLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
        let lines = measureLayer.getFeatures().filter(x => x.drawId == draw.ol_uid);
        if (lines.length > 0) {
          measureLayer.removeFeature(lines[lines.length - 1]);
        }
        let tooltips = document.getElementsByClassName('ol-tooltip-clickthrough');
        if(tooltips && tooltips[0] && tooltips[0].parentNode) {
          tooltips[0].parentNode.removeChild(tooltips[0]);
        }
      } else {
        draw.removeLastPoint();
      }
    }
  }

  clearAllTooltips = () => {
    let tooltips = document.getElementsByClassName('ol-tooltip-clickthrough');
    while (tooltips.length > 0) {
      tooltips[0].parentNode.removeChild(tooltips[0]);
    }
  }

  clearTooltipsByOlUid = (ol_uid) => {
    let tooltips = document.getElementsByClassName('ol-tooltip-clickthrough');
    Array.from(tooltips).forEach(tip => {
      if (tip.ol_uid == ol_uid) {
        tip.parentNode.removeChild(tip);
      }
    })
  }

  clearAllMeasures = () => {
    let measureLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure');
    if (measureLayer) {
      measureLayer.getSource().clear();
      this.clearAllTooltips();
    }
    let gateLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Gate');
    if (gateLayer) {
      gateLayer.getSource().clear();
    }
    this.setGobKey('ms_SelectedPolygons', []);
  }

  cancelDrawing = () => {
    if (this.props.Crosshair) {
      this.setGobKey('ms_MapCrosshair', false);
      this.setGobKey('ms_MySalesMapAction', null);
      this.setGobKey('ms_ModifyActive', false);
      return;
    }

    let vectorSource = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
    let draw = this.props.map.getInteractions().getArray().find(x => x instanceof Draw);

    //If new drawing cancelled
    if (draw) {
      let snapGuides = this.props.map.getInteractions().getArray().find(x => x instanceof SnapGuides);
      this.props.map.removeInteraction(snapGuides);
      // this.enableGrid(false);
      this.props.map.removeInteraction(draw);
      if (draw.mode_ === 'LineString') {
        let lines = vectorSource.getFeatures().filter(x => x.drawId == draw.ol_uid);
        lines.forEach(x => {
          vectorSource.removeFeature(x);
        })
      }
    }

    // Drawing is new / is being be deleted upon cancel
    console.log({ ActiveModifyId: this.props.ActiveModifyId });
    console.log({ SelectedPolygons: this.props.SelectedPolygons });
    if (!this.props.ActiveModifyId || ((!this.props.SelectedPolygons.find(x => x.ol_uid == this.props.ActiveModifyId)) && this.props.ActiveModifyId !== 'Gate')) {
      console.log('activeModifyId not found in ms_SelectedPolygons');
      this.clearTooltipsByOlUid('unsaved');
      if (this.props.ModifyActive) {
        let allFeats = vectorSource.getFeatures();
        let featureToRemove = null;
        allFeats.forEach(x => {
          if (x.ol_uid == this.props.ActiveModifyId) {
            featureToRemove = x;
          }
        })
        // let featureToRemove = vectorSource.getFeatures()[vectorSource.getFeatures().length - 1];
        if (featureToRemove) {
          this.clearTooltipsByOlUid(featureToRemove.ol_uid);
          vectorSource.removeFeature(featureToRemove);
        }
      }
    }

    batch(() => {
      this.setGobKey('ms_DrawingActive', false);
      this.setGobKey('ms_ActiveModifyId', null);
      this.setGobKey('ms_MySalesMapAction', null);
      this.setGobKey('ms_currentMeasure', null);
      this.modifyEnabled(false);
    });

    let features = vectorSource.getFeatures();
    features.forEach(x => {
      this.props.SelectedPolygons.forEach(y => {
        if (x.ol_uid == y.ol_uid) {
          x.getGeometry().setCoordinates(y.RawCoords);
        }
      });
      // if (this.props.SelectedPolygons[features.indexOf(x)] && this.props.SelectedPolygons[features.indexOf(x)].RawCoords) {
      //   x.getGeometry().setCoordinates(this.props.SelectedPolygons[features.indexOf(x)].RawCoords);
      // } // else if (!this.props.SelectedPolygons[features.indexOf(x)]) { //if feature is on the map but not in the cardlist yet, delete it if cancel is clicked
      // //   vectorSource.removeFeature(x);
      // // }
    })

    let selectedPolygons = this.props.SelectedPolygons.slice();
    let existingPolygons = selectedPolygons.filter(x => x.ol_uid);
    this.props.dispatch(actions.UpdateProp({
      Key: 'ms_SelectedPolygons',
      Value: existingPolygons
    }))

    if (window.IsMobile) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'MainTabContent',
        Value: { Value: 'Step3Panel', Enabled: true }
      }))
    }
  }

  createModTouch = (vector) => {
    let svgHover = '<svg width="80" height="80" fill="yellow" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z"/></svg>';

    let modifyTouch = new ModifyTouch({
      source: vector,
      // title: "❌ Delete gate",
      className: "gate-modal",
      pixelTolerance: 50,
      usePopup: false,
      // setZIndex: 2500,
      style: new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgHover,
          scale: 0.4,
        })
      }),
      onRemove: (arg) => {
        console.log({ onRemove: arg });
        vector.removeFeature(arg.feature);
      }
    })

    modifyTouch.className = "gate-modal";
    let oldCoords;
    modifyTouch.on('modifystart', (e) => {
      console.log({ SelectedPolygons001: this.props.SelectedPolygons });
      oldCoords = e.target.vertexFeature_.getGeometry().getCoordinates();
    })
    modifyTouch.on('modifyend', (e) => {
      console.log({ SelectedPolygons: this.props.SelectedPolygons });
      let touchingGate = false;
      let features = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'LINESTRING' || x.geometry === 'MULTILINESTRING');
      features.forEach(x => {
        if (x.getGeometry().intersectsCoordinate(e.features.getArray()[0].getGeometry().getCoordinates())) {
          touchingGate = true;
        }
      })
      if (touchingGate) { //check if the gate is touching the feature, and if not we move it back to where it is from
        let selectedPolygons = this.props.SelectedPolygons.slice();
        e.features.getArray().forEach(x => {
          let gate = selectedPolygons.find(y => y.ol_uid == x.ol_uid);
          gate.Geometry = x.getGeometry();
          gate = this.savePlan(gate);
        })
      } else {
        e.features.getArray()[0].getGeometry().setCoordinates(oldCoords);
      }
    });
    this.props.map.addInteraction(modifyTouch);
  }

  createStairModTouch = (vector) => {
    let svgHover = '<svg width="100px" height="100px" fill="yellow" viewBox="0 0 2.4 2.4" xmlns="http://www.w3.org/2000/svg"><path d="M1.8 0.6h-0.3v0.3h-0.3v0.3h-0.299v0.3h-0.3v0.3H0.3v0.3h1.8V0.3h-0.3zM1.9 0.4h0.1v1.6H0.4v-0.1h0.3v-0.3h0.3v-0.3h0.3v-0.3h0.3v-0.299h0.3z"/><path fill="none" d="M0 0h2.4v2.4H0z"/></svg>';

    let modifyTouch = new ModifyTouch({
      source: vector,
      // title: "❌ Delete gate",
      className: "stair-modal",
      pixelTolerance: 50,
      usePopup: false,
      // setZIndex: 2500,
      style: new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgHover,
          scale: 0.4,
        })
      }),
      onRemove: (arg) => {
        console.log({ onRemove: arg });
        vector.removeFeature(arg.feature);
      }
    })

    modifyTouch.className = "stair-modal";
    let oldCoords;
    modifyTouch.on('modifystart', (e) => {
      console.log({ SelectedPolygons001: this.props.SelectedPolygons });
      oldCoords = e.target.vertexFeature_.getGeometry().getCoordinates();
    })
    modifyTouch.on('modifyend', (e) => {
      console.log({ SelectedPolygons: this.props.SelectedPolygons });
      let touchingDeck = false;
      let features = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'POLYGON');
      features.forEach(x => {
        if (x.getGeometry().intersectsCoordinate(e.features.getArray()[0].getGeometry().getCoordinates())) {
          touchingDeck = true;
        }
      })
      if (touchingDeck) { //check if the stair is touching the feature, and if not we move it back to where it is from
        let selectedPolygons = this.props.SelectedPolygons.slice();
        e.features.getArray().forEach(x => {
          let stair = selectedPolygons.find(y => y.ol_uid == x.ol_uid);
          stair.Geometry = x.getGeometry();
          stair = this.savePlan(stair);
        })
      } else {
        e.features.getArray()[0].getGeometry().setCoordinates(oldCoords);
      }
    });
    this.props.map.addInteraction(modifyTouch);
  }

  addGate = (iconLetter) => {
    let svg = '<svg width="80" height="80" fill="orange" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z"/></svg>';
    let svgLightBlue = '<svg width="80" height="80" fill="lightblue" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z"/></svg>';
    let svgBlack = '<svg width="80" height="80" fill="black" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z"/></svg>';

    let gateLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Gate');
    let vector;

    if (!gateLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,
      })

      layer.setZIndex(50);
      layer.LayerId = 'Gate';
      layer.Vector = vector;
      this.props.map.addLayer(layer);

      this.createModTouch(vector);
    } else {
      vector = gateLayer.Vector;
      var interactions = this.props.map.getInteractions().getArray();
      let modTouch = interactions.find(x => x instanceof ModifyTouch && x.className !== 'main-modify');
      if (modTouch) {
        this.props.map.removeInteraction(modTouch);
        this.createModTouch(vector);
      }
    }

    let draw = new Draw({
      source: vector,
      type: 'Point',
      style: [new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgBlack,
          scale: 0.4,
        })
      }), new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svg,
          scale: 0.4,
        }),
        text: new Text({
          font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          offsetY: 3,
          text: iconLetter
        })
      })]
    })

    var interactions = this.props.map.getInteractions().getArray();

    draw.on('drawend', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.removeSnapping();
      this.props.map.removeInteraction(draw);
      this.props.map.removeInteraction(e.target);
      // interactions.forEach(x => {
      //   if (x instanceof ModifyTouch) {
      //     x.setActive(true);
      //   }
      // });
      let linesToSnap = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'LINESTRING' || x.geometry === 'MULTILINESTRING');
      linesToSnap.push(e.feature);
      var snap = new Snap({
        // source: this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').Vector,
        features: new Collection(linesToSnap),
        pixelTolerance: 7,
        vertex: false
      })
      this.props.map.addInteraction(snap);
      e.feature.setStyle(new Style({
        image: new Icon({
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + svgLightBlue,
          scale: 0.4,
        }),
        text: new Text({
          font: 'bold 22px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          offsetY: 3,
          text: iconLetter
        })
      }));
      this.setGobKey('ms_DrawingActive', false);
      let selectedPolygons = cloneDeep(this.props.SelectedPolygons);
      let index = selectedPolygons[selectedPolygons.length - 1].Id + 1;
      let gateType = iconLetter === 'S' ? 'Single Gate' : 'Double Gate';
      let obj = { Id: index, Type: 'fence_Gate', Text1: 'Gate', Text2: gateType, Amount: '-', Geometry: e.feature.getGeometry(), ol_uid: e.feature.ol_uid, EntityTypeId: this.props.MapAction.EntityTypeId };

      //intersectsCoordinate wasn't working consistently so we use getClosestFeatureToCoordinate
      let measureVector = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource();
      let fenceTouchingFeature = measureVector.getClosestFeatureToCoordinate(e.feature.getGeometry().getCoordinates());
      let fenceTouching = selectedPolygons.find(y => y.ol_uid == fenceTouchingFeature.ol_uid);
      if (fenceTouching) {
        obj.CostGate = fenceTouching.CostGate;
        // obj.lowCost = fenceTouching.CostGate;
        // obj.highCost = fenceTouching.CostGate;
        // obj.Amount = '$' + fenceTouching.CostGate;
        obj.fence_ol_uid = fenceTouching.ol_uid;
      }

      obj = this.savePlan(obj);
      selectedPolygons.push(obj);

      if (this.props.NextMapAction) {
        this.setGobKey('ms_MySalesMapAction', { Id: this.props.NextMapAction.Id }); //re-highlight the cardlist
        this.modifyEnabled(true);
        // let fence = selectedPolygons.find(x => x.EntityTypeId == this.props.NextMapAction.Id);
        // if (fence) {
        //   this.modifyEnabled(true);
        // }
      } else {
        this.setGobKey('ms_MySalesMapAction', null);
      }

      this.setGobKey('ms_SelectedPolygons', selectedPolygons);
      if (window.IsMobile) {
        setTimeout(() => {
          this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
        }, 200)
      }
    });
    this.setGobKey('ms_DrawingActive', true);
    this.props.map.addInteraction(draw);

    interactions.forEach(x => {
      if (x instanceof ModifyTouch && x.className !== 'gate-modal') {
        x.setActive(false);
      }
    });

    let linesToSnap = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure').getSource().getFeatures().filter(x => x.geometry === 'LINESTRING' || x.geometry === 'MULTILINESTRING');
    var snap = new Snap({
      features: new Collection(linesToSnap),
      pixelTolerance: 1000,
      vertex: false
    })
    this.props.map.addInteraction(snap);
    this.setGobKey('ms_ModalActive', null);
    // this.setState({ addGateActive: true });
  }

  removeSnapping = () => {
    var interactions = this.props.map.getInteractions().getArray();
    interactions.forEach(x => {
      if (x instanceof Snap && x.Id !== 'lineSnap') {
        this.props.map.removeInteraction(x);
      }
    });
  }

  getDraw = (vector, type) => {
    return new Draw({
      source: vector,
      className: 'no-click-poly',
      type: type,
      maxPoints: type === 'LineString' ? 2 : null,
      snapTolerance: 50,
      freehandCondition: never,
      freehand: false/*window.IsMobile*/,
      style: [new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.3)',
        }),
        stroke: new Stroke({
          color: 'rgba(46, 41, 0, 1)',
          width: 12,
        }),
        geometry: (feature) => {
          const coordinates = feature.getGeometry().getCoordinates();
          return new MultiPoint(coordinates);
        },
      }), new Style({
        source: vector,
        type: 'Polygon',
        stroke: new Stroke({
          color: 'rgba(255, 190, 10, 0.9)',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, .4)'
        })
      }),
      new Style({
        image: new RegularShape({
          stroke: new Stroke({
            color: 'white',
            width: 2
          }),
          points: 4,
          radius: 8,
          radius2: 0,
          angle: 0,
        }),
      })],
    })
  }



  polygonPanelHelp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ helptextModal: !this.state.helptextModal });
    this.swapHelpDropdownTriangle();
  }

  swapHelpDropdownTriangle = () => {
    let triangleSVG = document.getElementById('polygon-panel-help-triangle');
    if (!triangleSVG) { return; }
    if (this.state.helptextModal) {
      triangleSVG.style.transform = 'rotate(90deg)';
      triangleSVG.style.marginTop = '0px';
      triangleSVG.style.marginRight = '10px';
    } else {
      triangleSVG.style.transform = 'rotate(0deg)';
      triangleSVG.style.marginTop = '-20px';
      triangleSVG.style.marginRight = '0px';
    }
  }

  rotateHelpTriangleDown = () => {
    setTimeout(() => {
      let triangleSVG = document.getElementById('polygon-panel-help-triangle');
      if (!triangleSVG) { return; }
      triangleSVG.style.transform = 'rotate(0deg)';
      triangleSVG.style.marginTop = '-20px';
      triangleSVG.style.marginRight = '0px';
    }, 0)
  }

  checkIfHelpNeeded = () => {
    if (this.props.DrawingActive && !this.props.FirstDrawCompleted) {
      this.setGobKey('ms_FirstDrawCompleted', true);
      this.setState({ helptextModal: true });
      this.rotateHelpTriangleDown();
    }
    if (this.state.addGateActive && !this.props.FirstGateCompleted) {
      this.setGobKey('ms_FirstGateCompleted', true);
      // this.setState({ helptextModal: true });
      setTimeout(() => {
        // this.swapHelpDropdownTriangle();
        let triangleSVG = document.getElementById('polygon-panel-help-triangle');
        if (!triangleSVG) { return; }
        triangleSVG.style.transform = 'rotate(0deg)';
        triangleSVG.style.marginTop = '-20px';
        triangleSVG.style.marginRight = '0px';
      }, 0)
    }
  }

  backToItemMobile = () => {
    this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
  }

  cancelGate = () => {
    // this.setState({ addGateModal: false });
    this.setGobKey('ms_ModalActive', null);
    let selectedPolygons = this.props.SelectedPolygons.slice();
    let existingPolygons = selectedPolygons.filter(x => x.ol_uid);
    this.setGobKey('ms_MySalesMapAction', null);
    this.setGobKey('ms_SelectedPolygons', existingPolygons);
    if (window.IsMobile) {
      this.setGobKey('MainTabContent', { Value: 'Step3Panel', Enabled: true });
    }
  }

  enableGrid = (value = null) => {
    let gridLayer = this.props.map.getLayers().getArray().find(x => x.LayerId == 594);

    if (value === true) {
      let grid = this.generateGrid();
      this.props.map.removeLayer(gridLayer);
      this.props.map.addLayer(grid);
    } else if (value === false) {
      this.props.map.removeLayer(gridLayer);
    } else {
      this.props.map.removeLayer(gridLayer);
    }
  }

  generateGrid = () => {
    let gridLayer = this.props.map.getLayers().getArray().find(x => x.LayerId == 594);
    this.props.map.removeLayer(gridLayer);
    
    let grid = new Graticule({
      strokeStyle: new Stroke({ 
        color: '#d6dadb', 
        width: '1' 
      }),
      showLabels: false,
      wrapX: true,
      targetSize: 2,
      intervals: [.00001],
      maxLines: 300,
      minZoom: 18
    })
    grid.Name = 'Grid';
    grid.LayerId = 594;
    return grid;
  }

  render() {
    // let activeSelectedPolygon = this.props.SelectedPolygons && this.props.SelectedPolygons[this.props.ActiveModifyId && this.props.ActiveModifyId - 1];
    // let canPlaceGate = !!(!this.props.DrawingActive && ((activeSelectedPolygon && activeSelectedPolygon.Type === 'fence_Fence') || (this.props.MapAction && this.props.MapAction.Type === 'fence_Fence')));
    let canPlaceGate = (this.props.ModifyActive && !this.props.DrawingActive) && this.props.MapAction && (this.props.MapAction.EntityTypeId == 2058 || this.props.MapAction.EntityTypeId == 2059) && !window.IsMobile;
    this.checkIfHelpNeeded();
    let isPolygon = this.props.MapAction && this.props.MapAction.GeoType === 'Polygon';
    let gateRequired = this.props.ItemCardlist ? this.props.ItemCardlist.findIndex(e => e.EntityTypeId === 2059) : -1

    return (
      <>
        {this.props.Crosshair &&
          <div className="map-crosshair"><YellowCrosshair /></div>}
        {/* {window.IsMobile &&
          <div className='polygon-panel-wrapper'>
            <button onClick={this.backToItemMobile} className='polygon-btn-pos' style={{ fontSize: '1.3rem', fontWeight: '500' }}>&lt;</button>
            Back to item selection
          </div>} */}
        {this.props.ModalActive ?
          <div className='polygon-selection-modal'>
            {this.props.ModalActive}
          </div> :
          (this.props.DrawingActive || this.props.ModifyActive) && <div className='polygon-panel-wrapper'>
            <div className="polygon-panel">
              <div className="polygon-panel-buttons">
                <div className="polygon-panel-help" onClick={this.polygonPanelHelp}>
                  <svg fill="white" version="1.1" id="polygon-panel-help-triangle" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32" xmlSpace="preserve">
                    <polygon points="24,24 16,32 8,24 " />
                  </svg>
                </div>
                {!this.props.ModifyActive && <button className="polygon-btn-neg" onClick={this.undoLastPoint}>Undo</button>}
                <button className="polygon-btn-neg" onClick={this.cancelDrawing}>Cancel</button>
                <button className="polygon-btn-pos" onClick={this.finishDrawing}>Next</button>
              </div>
              <div>
                {this.props.MapAction && this.props.MapAction.GeoType === 'Polygon' && <label><div style={{ marginLeft: '50px', paddingBottom: '10px' }} className="flexbox-center"><input type="checkbox" checked={this.state.Guidelines} onChange={() => this.toggleGuidelines()}></input><div>&nbsp;Guidelines</div></div></label>}
              </div>
              <div>
                {window.IsMobile && this.props.MapAction && this.props.MapAction.GeoType !== 'Polygon' && <label><div style={{ marginLeft: '50px', paddingBottom: '10px' }} className="flexbox-center"><input type="checkbox" onChange={() => this.enableGrid()}></input><div>&nbsp;Grid</div></div></label>}
              </div>
              <div style={{ marginLeft: '50px', marginRight: '50px' }}>
                {this.props.DrawingActive ? 'Draw' : this.props.Crosshair ? 'Select' : 'Edit'} the location
              </div>
            </div>
            {this.state.helptextModal &&
              <div className="polygon-panel-helptext">
                {this.props.DrawingActive && this.props.MapAction && this.props.MapAction.EntityTypeId != 2059 && this.props.MapAction.EntityTypeId != 2064 && this.props.MapAction.EntityTypeId != 2066 && <div>
                  <h4>Draw on the map the location</h4>
                  <p>- Can't find your home? Try the Grid Overlay checkbox</p>
                  <p>- Tap or Click to place points</p>
                  {isPolygon && <p>- Double click to finish drawing</p>}
                  <p>- Drag to pan</p>
                  <p>- Pinch or Scroll to zoom</p>
                  <p>- Tap or Click <b><i>Next</i></b> to continue</p>
                </div>}
                {canPlaceGate && gateRequired >= 0 && <div>
                  <h4>Place a gate with the 'Add a Gate' button</h4>
                  <p>- Tap or Click on the drawn fence to place the gate</p>
                  <p>- Drag the gate to move it along the fence</p>
                  <p>- Tap or Click the gate once placed to remove it</p>
                </div>}
                {this.props.MapAction && this.props.MapAction.EntityTypeId == 2059 && <div>
                  <h4>Click or Tap to place your gate</h4>
                </div>}
                {this.props.MapAction && (this.props.MapAction.EntityTypeId == 2064 || this.props.MapAction.EntityTypeId == 2066) && <div>
                  <h4>Click or Tap to place your stair&#40;s&#41;</h4>
                </div>}
                {this.props.ModifyActive && this.props.MapAction && this.props.MapAction.EntityTypeId != 2059 && this.props.MapAction.EntityTypeId != 2064 && this.props.MapAction.EntityTypeId != 2066 &&
                  <div>
                    <h4>Edit your drawing</h4>
                    {this.props.DrawingActive && <p>After you're finished drawing you will be able to edit </p>}
                    <p>- Tap or Click on vertice to delete them</p>
                    <p>- Tap or Click on any part of a line to add a new vertice</p>
                    <p>- Drag verticies to change the size of the shape drawn</p>
                    <p>- Tap or Click <b><i>Next</i></b> to continue</p>
                  </div>}
                <button className="polygon-btn-pos polygon-help-ok" onClick={this.polygonPanelHelp}>Got it</button>
              </div>
            }
          </div>}
      </>
    );
  }
}

export default connect(mapStateToProps)(MySalesmanMapController);
