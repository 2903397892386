import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state.ent_Current,
    CurrentEvent: state.ent_CurrentEvent,
    SelectedEvent: state.ent_SelectedEvent
  };
};

export class PracticeEventParent extends React.PureComponent {

  componentDidMount() {
    if (!this.props.CurrentEntity || !this.props.CurrentEntity.Properties)
      return;

    let assetIdProp = this.props.CurrentEntity.Properties.find(x => x.Name === 'AssetId');
    let assetClassProp = this.props.CurrentEntity.Properties.find(x => x.Name === 'AssetClassId');

    if (!assetIdProp || !assetClassProp) {
      console.log('could not find AssetId / AssetClassId properties on ent_Current.  ParentId scriptrunner failed');
      return;
    }

    let baseObj = {
      Id: null,
      InsertKey: this.props.SelectedEvent.InsertKeys[this.props.CurrentEvent.BaseTable],
      Table: this.props.CurrentEvent.BaseTable,
      IsEventData: true,
      IsBaseTable: true
    }

    batch(() => {
      helpers.addSaveData(this.props.dispatch, baseObj, 'AssetId', assetIdProp.Value)
      helpers.addSaveData(this.props.dispatch, baseObj, 'AssetClassId', assetClassProp.Value)
      helpers.addSaveData(this.props.dispatch, baseObj, 'ParentId', this.props.CurrentEntity.EntityId)
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(PracticeEventParent);
