
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { batch } from 'react-redux';

export const saveRequest = (userId, req) => {
  let baseObj = {
    Id: null,
    InsertKey: helpers.getInsertKey(),
    Table: 'ttr.LeaveRequest',
    IsEventData: false,
    SaveQueue: 'ttr_LeaveSaveData',
    IsBaseTable: true,
    EntityTypeId: 100
  }

  let saveData = [
    helpers.saveDataItem(baseObj, 'UserId', userId),
    helpers.saveDataItem(baseObj, 'ManagerId', null),
    helpers.saveDataItem(baseObj, 'LeaveStatusId', 1),
    helpers.saveDataItem(baseObj, 'RequestTypeLid', Number(req.RequestTypeId)),
    helpers.saveDataItem(baseObj, 'SubmittedDate', helpers.dateToInputFormat(new Date())),
    helpers.saveDataItem(baseObj, 'LeaveStartDate', req.StartDate),
    helpers.saveDataItem(baseObj, 'LeaveEndDate', req.EndDate),
    helpers.saveDataItem(baseObj, 'ApprovedDate', null),
    helpers.saveDataItem(baseObj, 'LeaveHours', req.Hours),
    helpers.saveDataItem(baseObj, 'UserNote', req.Note || ''),
    helpers.saveDataItem(baseObj, 'ManagerNote', null),
    helpers.saveDataItem(baseObj, 'IsDeleted', false)
  ];

  actions.ApiRequest('Save/Save', { SaveData: saveData }, () => { });
}