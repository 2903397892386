import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { connect, batch } from "react-redux";
import * as actions from "../../../../Stores/Actions/actions";
import * as helpers from "../../../../Utils/Helpers";
import * as hub from "./../../../../Utils/PubSub";
import parser, { domToReact } from "html-react-parser";

const mapStateToProps = (state, ownProps) => {
  let parameter = ownProps.widget.Parameters.find((x) => x.ParameterName === "Parameter");
  let tableName = ownProps.widget.Parameters.find((x) => x.ParameterName === "TableName");
  let saveQueue = ownProps.widget.Parameters.find((x) => x.ParameterName === "SaveQueue");
  let entityTypeId = ownProps.widget.Parameters.find((x) => x.ParameterName === "EntityTypeId");


  let swid = ownProps.widget.SceneWidgetId;
  let dataKey = '_jsonForm' + swid;
  let markupKey = '_jsonFormMarkup' + swid;
  if (!state[dataKey]) state[dataKey] = {};

  return {
    Parameter: parameter
      ? helpers.getListParameter(state, parameter.ParameterValue)
      : null,
    TableName: tableName ? tableName.ParameterValue : null,
    Markup: state[markupKey],
    Value: state[dataKey],
    DataKey: dataKey,
    MarkupKey: markupKey,
    SWID: ownProps.widget.SceneWidgetId,
    CancelId: state.dbo_CancelId,
    SaveQueue: saveQueue ? saveQueue.ParameterValue : null,
    EntityTypeId: entityTypeId ? entityTypeId.ParameterValue : null
  };
};

export class JsonForm extends React.PureComponent {

  componentDidMount() {
    if (!this.props.Markup) {
      this.getData();
    }
  }

  getData = () => {
    let body = {
      SWID: this.props.SWID,
      Parameter: JSON.stringify(this.props.Parameter)
    };
    actions.ApiRequest('List/GetList', body, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.MarkupKey,
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: this.props.DataKey,
        Value: { SaveData: result.SaveData, ...result.Value }
      }));
      setTimeout(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: this.props.MarkupKey,
          Value: result.Markup
        }))
      }, 0);
    });
  }

  componentDidUpdate(prev) {
    if (prev && prev.CancelId !== this.props.CancelId) {
      this.getData();
    }

    if (this.addSaveData) {
      let saveVal = { ...this.props.Value };
      delete saveVal.SaveData;
      this.addSaveData = false;

      let sd = {
        ...this.props.Value.SaveData,
        Value: JSON.stringify(saveVal),
        SaveQueue: this.props.SaveQueue
      }

      if (this.props.EntityTypeId) {
        sd.EntityTypeId = this.props.EntityTypeId
      }

      this.props.dispatch(actions.AddSaveData(sd));
    }
  }

  onChange = (e, path) => {
    let val = { ...this.props.Value };
    let propPath = path.split(".");

    let inputVal = e.target.value;
    if (e.target.attributes.nodeval)
      inputVal = e.target.attributes.nodeval.value;

    console.log(inputVal);
    console.log(e.target.checked);
    if (e.target.type === 'checkbox') {
      inputVal = e.target.checked;
    }
    propPath.reduce((prev, next, idx) => {
      if (!prev[next] && idx !== propPath.length - 1) {
        prev[next] = {};
      }
      if (idx === propPath.length - 1) {
        prev[next] = inputVal;
      }

      return prev[next];
    }, val);

    this.addSaveData = true;
    this.props.dispatch(
      actions.UpdateProp({
        Key: this.props.DataKey,
        Value: val,
      })
    );
  };

  render() {
    return !this.props.Markup ? null : parser(this.props.Markup, {
      replace: (domNode) => {
        if (domNode.name === "input" && domNode.attribs.path) {
          let style = {};
          if (domNode.attribs.style) {
            var regex = /([\w-]*)\s*:\s*([^;]*)/g;
            var match, properties = {};
            while (match = regex.exec(domNode.attribs.style)) {
              properties[match[1]] = match[2].trim();
            }
            style = properties;
          }

          return (
            <input
              onChange={(e) => {
                this.onChange(e, domNode.attribs.path);
              }}
              type={domNode.attribs.type}
              nodeVal={domNode.attribs.value}
              id={domNode.attribs.id}
              name={domNode.attribs.name}
              style={style}
              value={domNode.attribs.value || domNode.attribs.path.split('.').reduce((prev, next) => { return prev ? prev[next] : null }, this.props.Value)}
              checked={
                domNode.attribs.type === 'radio'
                  ? domNode.attribs.path.split('.').reduce((prev, next) => { return prev ? prev[next] : null }, this.props.Value) === domNode.attribs.value ? 'checked' : null
                  : domNode.attribs.type === 'checkbox' ?
                    domNode.attribs.path.split('.').reduce((prev, next) => { return prev ? prev[next] : false }, this.props.Value) : null
              }
            />
          );
        }
      },
    });
  }
}

export default connect(mapStateToProps)(JsonForm);
