import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    PWRPricingData: state.dbo_PWRPricingRules ? JSON.parse(state.dbo_PWRPricingRules[0].Value) : null
  };
};

export class MV_PWRPricingRules extends React.PureComponent {

  componentDidMount() {
    let data = this.props.PWRPricingData
    let error = false

    if(data) {
        // check for all "Pricing section rules selected"
        if(data.Pricing && data.Pricing.DisplayAs && data.Pricing.Round) {
            // if range check pct above and pct below are filled out
            if(data.Pricing.DisplayAs === "Range") {
                if(data.Pricing.PctAbove && data.Pricing.PctAbove !== "" && data.Pricing.PctBelow && data.Pricing.PctBelow !== "") { } else { error = true }
            }
        } else { error = true }

        // check for radius based pricing
        if(data.RadShortCharge && data.RadShortCharge.Type) {
            if(data.RadShortCharge.Type === "RadiusBased") {
                // if radius based charge check for other 3 rules
                let radActive = false
                if(data.RadShortCharge.Rule1 && data.RadShortCharge.Rule1.Active) {
                    // rule 1
                    radActive = true
                    if(data.RadShortCharge.Rule1.Below && data.RadShortCharge.Rule1.Below !== "") { } else { error = true }
                    if(data.RadShortCharge.Rule1.Above && data.RadShortCharge.Rule1.Above !== "") { } else { error = true }
                    if(data.RadShortCharge.Rule1.Charge && data.RadShortCharge.Rule1.Charge !== "") { } else { error = true }
                }

                if(data.RadShortCharge.Rule2 && data.RadShortCharge.Rule2.Active) { 
                    // rule 2
                    radActive = true
                    if(data.RadShortCharge.Rule2.Below && data.RadShortCharge.Rule2.Below !== "") { } else { error = true }
                    if(data.RadShortCharge.Rule2.Above && data.RadShortCharge.Rule2.Above !== "") { } else { error = true }
                    if(data.RadShortCharge.Rule2.Charge && data.RadShortCharge.Rule2.Charge !== "") { } else { error = true }
                }

                if(data.RadShortCharge.Rule3 && data.RadShortCharge.Rule3.Active) { 
                    // rule 3
                    radActive = true
                    if(data.RadShortCharge.Rule3.Above && data.RadShortCharge.Rule3.Above !== "") { } else { error = true }
                    if(data.RadShortCharge.Rule3.Charge && data.RadShortCharge.Rule3.Charge !== "") { } else { error = true }
                }

                if(!radActive) { error = true }
            }
        } else { error = true }

        // check discount fields are filled out
        if(data.Discount && data.Discount.Active) {
            // check for Above and Percent
            if(data.Discount.Above && data.Discount.Above !== "") { } else { error = true }
            if(data.Discount.Percent && data.Discount.Percent !== "") { } else { error = true }
        }
    }

    if(error) {
        //dispatch missing data error modal
        this.props.dispatch(actions.UpdateProp({
            Key: 'blu_Dialog',
            Value: { 
                Title: 'Missing Rule Fields', 
                Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>
                    Please enter details for all pricing rules. Missing details will result in $0 budgets
                </p> 
            }
        }));
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_PWRPricingRules);