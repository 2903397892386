
import React from 'react';
import { connect, batch } from 'react-redux';
import ReactDOM from 'react-dom';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  return {
    TriggerMassUpdate: state.qry_TriggerMassUpdate,
    MassUpdateAssetSaveData: state.dbo_MassUpdateAssetQueue,
    MassUpdateEventSaveData: state.dbo_MassUpdateEventQueue,
    MassUpdateSelectedAsset: state.ent_MassUpdateSelectedAsset,
    MassUpdateSelectedEvent: state.ent_MassUpdateSelectedEvent,
    CancelMassUpdate: state.qry_CancelMassUpdate,
    TriggerEventSpray: state.qry_TriggerEventSpray,
    CancelEventSpray: state.qry_CancelEventSpray,
    EventSaveData: state.dbo_EventSaveData,
    CloseDialogId: state.dbo_CloseDialogId,
    TriggerRecurrenceSpray: state.qry_TriggerRecurrenceSpray,
    CancelRecurrenceSpray: state.qry_CancelRecurrenceSpray,
    TriggerGroupedRecurrenceSpray: state.qry_TriggerGroupedRecurrenceSpray
  };
};

export class QueryActions extends React.PureComponent {

  componentDidUpdate(prev) {

    if (this.props.CloseDialogId != prev.CloseDialogId) {
      this.resetMassUpdate();
    }

    if (this.props.TriggerEventSpray) {
      this.sprayEvents();
    }

    if (this.props.CancelEventSpray) {
      this.cancelEventSpray();
    }

    if (this.props.TriggerMassUpdate) {
      this.massUpdateConfirm();
    }

    if (this.props.CancelMassUpdate) {
      this.cancelMassUpdate();
    }

    if (this.props.TriggerRecurrenceSpray) {
      this.sprayRecurrences(false);
    }

    if (this.props.TriggerGroupedRecurrenceSpray) {
      this.sprayRecurrences(true);
    }

    if (this.props.CancelRecurrenceSpray) {
      this.cancelRecurrenceSpray();
    }
  }

  doRecurrenceSpray = (agRequest, saveData, typeId, isGrouped) => {
    saveData.forEach((x) => {
      if (x.Column === 'AssetId') {
        x.Value = 'REPLACED_FROM_QUERY';
      }
      if (x.Column === 'AssetClassId') {
        x.Value = typeId;
      }
    });

    //if is grouped add in entry for rcr.RecurrenceGroup, and put a piece of save data to add this schedule to the group
    if (isGrouped) {

      let groupSaveData = [
        helpers.saveDataItem({ Id: null, InsertKey: helpers.getInsertKey(), Table: 'rcr.RecurrenceGroup', IsBaseTable: true, EntityTypeId: 527 }, 'DateCreated', new Date()),
      ];

      actions.ApiRequest('Save/Save', { SaveData: groupSaveData }, (result) => {
        let recScheduleInsertKey = saveData.find(x => x.Table === 'rcr.ScheduleEntity' && x.InsertKey != null).InsertKey;
        saveData.push(helpers.saveDataItem({ Id: null, InsertKey: recScheduleInsertKey, Table: 'rcr.ScheduleEntity', IsBaseTable: true }, 'GroupId', result.Id));
        let body = {
          SaveData: JSON.stringify(saveData),
          GridRequest: agRequest,
          DataQId: isGrouped ? 12 : 10
        }

        actions.ApiRequest('DataQueue/RecurrenceSpray', body, () => { });
      });
    } else {
      let body = {
        SaveData: JSON.stringify(saveData),
        GridRequest: agRequest,
        DataQId: isGrouped ? 12 : 10
      }

      actions.ApiRequest('DataQueue/RecurrenceSpray', body, () => { });
    }
  }

  doEventSpray = (agRequest, saveData, typeId) => {
    saveData.forEach((x) => {
      if (x.Column === 'AssetId' || x.Column === 'EntityId') {
        x.Value = 'REPLACED_FROM_QUERY';
      }
      if (x.Column === 'AssetClassId') {
        x.Value = typeId;
      }
    });

    let body = {
      SaveData: JSON.stringify(saveData),
      GridRequest: agRequest
    }

    actions.ApiRequest('DataQueue/EventSpray', body, () => { });
  }

  massUpdateConfirm = () => {
    if (!this.props.parent.props.SelectedQuery)
      return;

    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: {
          Title: "Please Confirm Mass Update",
          Message: (
            <>
              <p style={{ fontSize: "1.1rem" }}>
              You are about to perform a mass update. Are you sure you want to continue?
              </p>
                <div className="flex-btn-bar" style={{ paddingLeft: "10px", marginTop: "10px" }}>
                    <div className="btn-container" style={{ flex: "0 1 140px" }}>
                        <div className="btn-bar-btn polygon-btn" onClick={() => this.massUpdate()}>
                            <div>
                                Continue
                            </div>
                        </div>
                    </div>
                    <div className="btn-container" style={{ flex: "0 1 140px" }}>
                        <div className="btn-bar-btn polygon-btn-neg" onClick={this.cancelButton}>
                            <div>
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </>
          ),
          Buttons: [],
        },
      })
    );
  }

  cancelButton = () => {
    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: "blu_Dialog",
        Value: null
      }))
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_TriggerMassUpdate',
        Value: null
      }));
      this.resetMassUpdate();
    })
  }

  massUpdate = () => {
    if (!this.props.parent.props.SelectedQuery)
      return;

    this.props.dispatch(actions.UpdateProp({
      Key: "blu_Dialog",
      Value: null
    }))

    let reqBody = this.props.parent.getRequestBody(this.props.parent.lastRequest);
    let saveData = this.prepSaveData(this.props.MassUpdateAssetSaveData, this.props.MassUpdateEventSaveData)

    this.doMassUpdate(reqBody, saveData);

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_TriggerMassUpdate',
        Value: null
      }));
      this.resetMassUpdate();
    });
  }

  doMassUpdate = (agRequest, saveData) => {
    let keyIds = this.getKeyIds();
    let body = {
      AssetSaveData: JSON.stringify(saveData[0]),
      EventSaveData: JSON.stringify(saveData[1]),
      GridRequest: agRequest,
      KeyIds: keyIds
    }

    actions.ApiRequest('DataQueue/MassUpdate', body, () => { });
  }

  getKeyIds = () => {
    let keyIds = [];
    let asset = this.props.MassUpdateSelectedAsset;
    if (asset && asset.KeyIdColumn && asset.EntityTypeId) {
      keyIds.push({ Column: asset.KeyIdColumn, EntityTypeId: asset.EntityTypeId });
    }

    let event = this.props.MassUpdateSelectedEvent;
    if (event && event.KeyIdColumn && event.EntityTypeId) {
      keyIds.push({ Column: event.KeyIdColumn, EntityTypeId: event.EntityTypeId });
    }

    return keyIds;
  }

  prepSaveData = (assetData, eventData) => {
    let assetSaves = (assetData || []).map((x) => {
      return { ...x, Id: 'REPLACED_FROM_QUERY_ASSET' };
    });

    let eventSaves = (eventData || []).map((x) => {
      return { ...x, Id: 'REPLACED_FROM_QUERY_EVENT' };
    });

    return [assetSaves, eventSaves];
  }

  sprayRecurrences = (isGrouped) => {
    if (!this.props.parent.props.SelectedQuery)
      return;

    let reqBody = this.props.parent.getRequestBody(this.props.parent.lastRequest);
    this.doRecurrenceSpray(reqBody, this.props.EventSaveData, this.props.parent.props.SelectedQuery.EntityTypeId, isGrouped);
    batch(() => {
      this.props.dispatch(actions.ClearSaveData('dbo_EventSaveData'));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_TriggerRecurrenceSpray',
        Value: null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_TriggerGroupedRecurrenceSpray',
        Value: null
      }));
    });

    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    }, 0);
  }

  sprayEvents = () => {
    if (!this.props.parent.props.SelectedQuery)
      return;

    let reqBody = this.props.parent.getRequestBody(this.props.parent.lastRequest);
    this.doEventSpray(reqBody, this.props.EventSaveData, this.props.parent.props.SelectedQuery.EntityTypeId);
    batch(() => {
      this.props.dispatch(actions.ClearSaveData('dbo_EventSaveData'));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_TriggerEventSpray',
        Value: null
      }));
    });

    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    }, 0);
  }

  cancelRecurrenceSpray = () => {
    batch(() => {
      this.props.dispatch(actions.ClearSaveData('dbo_EventSaveData'));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_CancelRecurrenceSpray',
        Value: null
      }));
    });

    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    }, 0);
  }

  cancelEventSpray = () => {
    batch(() => {
      this.props.dispatch(actions.ClearSaveData('dbo_EventSaveData'));
      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_CancelEventSpray',
        Value: null
      }));
    });

    setTimeout(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_TryCloseEvent',
        Value: true
      }));
    }, 0);
  }

  cancelMassUpdate = () => {
    this.resetMassUpdate();
  }

  resetMassUpdate = () => {
    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_MassUpdateEventQueue',
        Value: []
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_MassUpdateAssetQueue',
        Value: []
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene',
        Value: null
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_CancelMassUpdate',
        Value: null
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_MassUpdateAsset',
        Value: null
      }));

      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_MassUpdateEvent',
        Value: null
      }));
    });
  }

  render() {
    return null
  }
}



export default connect(mapStateToProps)(QueryActions);
