import React from 'react';
import { connect, batch } from 'react-redux';
import * as hub from '../../../Utils/PubSub';
import subMap from './SubMap';

const mapStateToProps = (state, ownProps) => {
  return {
    ApplicationId: state.blu_ApplicationId
  };
};

export class HubSubscriptions extends React.PureComponent {

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  render() {
    if (!this.props.ApplicationId)
      return null;

    return (
      <>
        {
          subMap.filter(x => x.ApplicationId === this.props.ApplicationId).map((x, idx) => (
            <x.Type key={idx} />
          ))
        }
      </>
    )
  }
}

export default connect(mapStateToProps)(HubSubscriptions);

