import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../Stores/Actions/actions';
import * as helpers from '../../../Utils/Helpers';


const mapStateToProps = (state, ownProps) => {
  return {
    SaveDataDebug: state.SaveDataDebug || [],
    UserId: state.CurrentUser ? state.CurrentUser.Id : 0
  };
};

export class DebugInfo extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    });
  }

  close = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'sys_showDebugInfo',
      Value: false
    }));
  }

  getRunnableSaveData = (item) => {
    return (<textarea type="text" value={
      `declare @json nvarchar(max) 
exec dbo.[save] ${this.props.UserId}, '${item.SaveData}', @json output, 1
select @json`
    } onChange={() => { }} />);
  }

  render() {
    return (
      <div className="debug-data">
        <div className="close-debug-data">
          <div>Debug Info</div>
          <div onClick={this.close}>x</div>
        </div>
        <div className="save-data-title">
          <div>SaveData</div>
        </div>
        <div className="save-data-debug-container">
          {
            this.props.SaveDataDebug.map((x, idx) => (
              <div key={idx} className="save-data-debug-item">
                <div className="queue-name">
                  <div>Queue: {x.SaveQueue} ({x.Time})</div>
                </div>
                <div className="runnable-save-data">
                  <div>{this.getRunnableSaveData(x)}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DebugInfo);