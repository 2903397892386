import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    AppId: state.blu_ApplicationId,
    SaveId: state.dbo_SaveId,
    TenantId: state.ent_TenantSelected ? state.ent_TenantSelected.EntityId : null
  };
};

export class MV_DeleteCatalogItem extends React.PureComponent {

  componentDidMount() {
    let selectedItemId = this.props.Trigger;
    console.log({ ItemId: selectedItemId });
    // Check xmv.ItemFilter, if this item ID is used display back to them where its used and that it can't be deleted

    let body = {
      SWID: 5472,
      Parameter: `{"ItemId":"${this.props.Trigger}","TenantId":${this.props.TenantId}}`,
    }

    actions.ApiRequest('List/GetList', body, (result) => {
      if (result.length == 0) { //No ItemFilters found (can delete)
        console.log('can delete');
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_Dialog',
          Value: {
            Title: 'Delete an Item',
            Message: <>
              <p style={{ fontSize: '1.1rem' }}>Are you sure you wish to delete this item?</p>
              <div className='flex-btn-bar' style={{ paddingLeft: '10px', marginTop: '10px' }}>
                <div className='btn-container' style={{ flex: '0 1 140px' }}>
                  <div className='btn-bar-btn polygon-btn' onClick={() => this.confirmDelete(selectedItemId)}><div>Delete</div></div>
                </div>
                <div className='btn-container' style={{ flex: '0 1 140px' }}>
                  <div className='btn-bar-btn polygon-btn-neg' onClick={this.cancelButton}><div>Cancel</div></div>
                </div>
              </div>
            </>,
            Buttons: []
          }
        }));
      } else { //ItemFilters found (can't delete)
        console.log('cant delete');
        let count = result[0].Count;
        let unique = result.map(x => x.Name1).join(', ');
        this.props.dispatch(actions.UpdateProp({
          Key: 'blu_Dialog',
          Value: { Title: 'Unable to Delete Item', Message: <p style={{ fontSize: '1.1rem', marginTop: '20px' }}>This item cannot be deleted because it is currently in use by <b>{count}</b> configuration(s) of the Types <b>{unique}</b></p> }
        }));
      }

      console.log({ SprocResults: result });
    });

    // If not found in ItemFilter show a modal to confirm or deny the deletion of the Item and then send IsDeleted Bit and force a save

  }

  confirmDelete = (selectedItemId) => {
    console.log('confirm delete hit');
    let saveData = [];
    let baseTable = {
      Id: selectedItemId,
      Table: 'xmv.Item',
      IsBaseTable: true,
      SaveQueue: 'dbo_CatalogSaveData'
    };
    saveData.push(helpers.saveDataItem(baseTable, 'IsDeleted', true));

    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_Dialog',
      Value: null
    }));

    actions.ApiRequest('Save/Save', { SaveData: saveData }, (result) => {
      console.log('saved');
      this.props.dispatch(actions.UpdateProp({
        Key: 'dbo_SaveId',
        Value: this.props.SaveId + 1
      }));
    });
  }

  cancelButton = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_Dialog',
      Value: null
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(MV_DeleteCatalogItem);