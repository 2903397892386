
import UBRegisterDials from './SubscriptionTypes/UB_RegisterDials';
import MVNewItemSaveData from './SubscriptionTypes/MV_NewItemSaveData';
import MVSiteLoad from './SubscriptionTypes/MV_SiteLoad';
import JournalManager from './SubscriptionTypes/UB_JournalManager';
import CatalogSwitcher from './SubscriptionTypes/MV_CatalogSwitcher';
import Hive from './SubscriptionTypes/Hive';
export default [
  {
    ApplicationId: 11,
    Type: UBRegisterDials
  },
  {
    ApplicationId: 22,
    Type: UBRegisterDials
  },
  {
    ApplicationId: 28,
    Type: UBRegisterDials
  },
  {
    ApplicationId: 11,
    Type: JournalManager
  },
  {
    ApplicationId: 32,
    Type: MVNewItemSaveData
  },
  {
    ApplicationId: 34,
    Type: MVNewItemSaveData
  },
  {
    ApplicationId: 32,
    Type: MVSiteLoad
  },
  {
    ApplicationId: 34,
    Type: MVSiteLoad
  },
  {
    ApplicationId: 32,
    Type: CatalogSwitcher
  },
  {
    ApplicationId: 34,
    Type: CatalogSwitcher
  },
  {
    ApplicationId: 35,
    Type: Hive
  }
];
