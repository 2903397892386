import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, batch } from 'react-redux';
import { Trashcan, EmptyCheck, EmptyRadio, FillRadio, Checked } from './../../../Icons/Icons';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';



const mapStateToProps = (state, ownProps) => {
  return {
    Metadata: state.met_EntityMetadata,
    CustomShareWith: state.qry_ShareWith
  };
}

export class ShareWith extends React.PureComponent {

  state = {
    lookupItems: helpers.shareWithItems(),
    open: false,
    mode: 'users',
    tenantMap: null
  }

  componentDidMount() {
    this.checkShareWithTenant();
    window.addEventListener('mousedown', this.clickDown);
    if (this.props.HasUserListSproc) {
      this.setLookupItemsToSprocResults();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.clickDown);
  }

  setLookupItemsToSprocResults = () => {
    if (this.props.CustomShareWith) {
      this.setState({ lookupItems: this.props.CustomShareWith });
    } else {
      let body = {
        SWID: this.props.SWID,
        Parameter: this.props.SprocParameter
      }

      this.props.dispatch(actions.UpdateProp({
        Key: 'qry_ShareWith',
        Value: []
      }))

      actions.ApiRequest('List/GetList', body, (result) => {
        this.setState({ lookupItems: result });
        this.props.dispatch(actions.UpdateProp({
          Key: 'qry_ShareWith',
          Value: result
        }))
      });
    }
  }

  checkShareWithTenant = () => {
    let em = this.props.Metadata;
    if (em && em.ConfigurationSettings) {
      let shareWith = em.ConfigurationSettings.find(x => x.Key === 'LayoutShareWithTenant');
      if (shareWith && helpers.stringToBool(shareWith.Value)) {
        this.getTenantMap();
      }
    }
  }

  getTenantMap = () => {
    let tenantMap = this.props.Metadata.UserTenants.map((x) => {
      return {
        Id: 'tenant' + x.Id,
        Label: x.Tenant
      }
    })
    this.setState({ tenantMap: tenantMap });
  }

  clickDown = (e) => {
    if (this.state.open && this.props.shareWith && !helpers.elementOrAncestorHasClass(e.target, 'share-with-items')) {
      this.setState({ open: false });
    }
  }

  shareClick = (item) => {
    this.props.shareClick(item + '');
  }

  openShare = () => {
    this.setState({ open: true });
  }

  setPublic = () => {
    this.setState({ open: true });
    this.props.onChange({ target: { value: true } }, 'IsPublic');
  }

  setMode = (val) => {
    this.setState({ mode: val });
  }
  render() {
    let shareIds = (this.props.shareWith || '').split(', ').filter(x => x !== '');
    let userQty = shareIds.filter(x => !x.includes('tenant')).length;
    let tenantQty = shareIds.filter(x => x.includes('tenant')).length;
    return (
      <React.Fragment>
        <div className="layout-cbox">
          {this.props.IsPublic &&
            <div className="cbox" onClick={() => { this.props.onChange({ target: { value: false } }, 'IsPublic') }}>
              <EmptyRadio />
            </div>
          }
          {!this.props.IsPublic &&
            <div className="cbox">
              <FillRadio />
            </div>
          }
          <div className="control-label">Private</div>
        </div>
        <div className="layout-cbox">
          {this.props.IsPublic &&
            <div className="cbox">
              <FillRadio />
            </div>
          }
          {!this.props.IsPublic &&
            <div className="cbox" onClick={this.setPublic}>
              <EmptyRadio />
            </div>
          }
          <div className="control-label">Share With</div>
          {this.props.IsPublic && !this.state.open &&
            <div className="share-link" onClick={this.openShare}>
              <div>{shareIds.includes('-1') ? 'Everyone' : (userQty + ' User' + (userQty > 1 ? 's' : '') + (tenantQty > 0 ? (' ' + tenantQty + ' District' + (tenantQty > 1 ? 's' : '')) : ''))}</div>
            </div>
          }
          {this.props.IsPublic && this.state.open &&
            <div className="share-with-container">
              <div className="share-with-items">
                {this.state.tenantMap &&
                  <div className="user-tenant-tabs">
                    <div onClick={() => { this.setMode('users') }}>Users</div>
                    <div onClick={() => { this.setMode('tenants') }}>Districts</div>
                  </div>}
                {this.state.mode === 'users' &&
                  <div className="share-with-item" onClick={() => { this.shareClick('-1') }}>
                    <input type="checkbox" checked={shareIds.includes('-1')} />
                    <div>Everyone</div>
                  </div>}
                {this.state.mode === 'users' &&
                  this.state.lookupItems.filter(x => x.On && x.Name).reduce((prev, next) => {
                    if (!prev.names)
                      prev.names = {};

                    if (!prev.names[next.Name]) {
                      prev.push(next);
                      prev.names[next.Name] = 1;
                    }
                    return prev;
                  }, []).map((x, idx) => (
                    <div key={idx} className="share-with-item" onClick={() => { this.shareClick(x.UserId) }}>
                      <input type="checkbox" checked={shareIds.includes(x.UserId + '')} />
                      <div>{x.Name}</div>
                    </div>
                  ))}
                {this.state.mode === 'tenants' &&
                  this.state.tenantMap.map((x, idx) => (
                    <div key={idx} className="share-with-item" onClick={() => { this.shareClick(x.Id) }}>
                      <input type="checkbox" checked={shareIds.includes(x.Id)} />
                      <div>{x.Label}</div>
                    </div>
                  ))}
              </div>
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ShareWith);