import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import Point from 'ol/geom/Point'
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from './../../../../../Utils/Helpers';
import { Vector as LayerVector } from 'ol/layer'
import { Vector as SourceVector } from 'ol/source';
import Feature from 'ol/Feature';
import StyleEngine from './../Styles/StyleEngine';
import { WKT } from 'ol/format';

const mapStateToProps = (state, ownProps) => {
  return {
    SetCenterPoint: state.map_SetCenterPoint
  };
}

export class CenterPoint extends PureComponent {
  componentDidMount() {
    this.drawFeatures();
  }

  componentDidUpdate() {
    if (this.props.SetCenterPoint) {
      this.drawFeatures();
      this.ResetCenterPoint();
    }
  }

  ResetCenterPoint = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'map_SetCenterPoint',
      Value: false
    }));
  }

  drawFeatures = () => {
    if (!this.props.CurrentEntity || !this.props.map)
      return;

    this.drawCenterPoint();
  }

  drawCenterPoint = () => {
    this.clearCenterPoint();
    let vector = this.getDrawingVector();
    let center = this.props.map.getView().getCenter();
    let format = new WKT();
    var pointFet = new Feature({
      name: 'CenterPoint',
      geometry: new Point([center[0], center[1]])
    });

    pointFet.setId('CenterPoint');
    vector.addFeature(pointFet);

    let rawPt = new Point([center[0], center[1]]).transform('EPSG:3857', this.props.map.projection);

    let baseProp = this.props.CurrentEntity.Properties.find(x => x.IsBaseTable);

    if (!baseProp)
      return;

    let saveData = baseProp.SaveData;

    let baseObj = {
      Id: saveData.Id,
      Table: saveData.Table,
      InsertKey: saveData.InsertKey,
      IsBaseTable: true,
      SaveQueue: this.props.map.Layout.SaveQueue
    }

    helpers.addSaveData(this.props.dispatch, baseObj, 'Geometry', format.writeGeometry(rawPt));
  }

  clearCenterPoint = () => {
    let vector = this.getDrawingVector();
    let oldCenter = vector.getFeatureById('CenterPoint');
    if (oldCenter) {
      vector.removeFeature(oldCenter);
    }
  }

  getDrawingVector = () => {
    let layers = this.props.map.getLayers().getArray();
    let drawLayer = layers.find(x => x.LayerId === 'CenterPointLayer');
    let vector;

    if (!drawLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,
        style: StyleEngine.getStyleLibrary()[this.props.map.Layout.CenterPointStyle]
      });
      layer.setZIndex(1111);
      layer.LayerId = 'CenterPointLayer';
      layer.Vector = vector;
      this.props.map.addLayer(layer);
    } else {
      vector = drawLayer.Vector;
    }
    return vector;
  }

  render() {
    return null
  }
}

export default connect(mapStateToProps)(CenterPoint);


