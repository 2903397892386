import cloneDeep from 'lodash/cloneDeep'
import { isMobileOnly, isMobile, isTablet } from 'react-device-detect';
import * as helpers from './../../Utils/Helpers';

let _jwtToken = '';
let _jwtLoadListeners = [];
let _currentEntityCopy = {};

let forceMobile = false;

if (isMobileOnly || forceMobile) {
  var meta = document.createElement('meta');
  meta.name = "viewport";
  meta.content = "width=400, maximum-scale=1.0, user-scalable=no";
  document.getElementsByTagName('head')[0].appendChild(meta);
  document.body.classList.add('mobile-app');
  window.IsMobile = true;
}

export const UpdateProp = (data) => {
  return { type: 'UpdateProp', val: data };
}

export const AutoLoadProp = (data) => {
  return { type: 'AutoLoadProp', val: data };
}

export const UpdateMultiple = (data) => {
  return { type: 'UpdateMultiple', val: data };
}

export const SetSwidActive = (data) => {
  return { type: 'SetSwidActive', val: data };
}

export const SetWidgetState = (data) => {
  return { type: 'SetWidgetState', val: data };
}

export const GlobalRefresh = () => {
  return { type: 'GlobalRefresh' };
}

export const MobileLog = (str) => {
  return { type: 'MobileLog', val: str };
}

export const setToken = (token) => {
  window._getToken().then(result => {
    _jwtToken = result;
    _jwtLoadListeners.forEach(x => {
      ApiRequest(x.path, x.body, x.callback);
    });
  });
  _jwtToken = token;
}

const getScene = (data) => {
  return { type: 'GetScene', val: data };
}

export const SetScene = (obj) => {
  return { type: 'GetScene', val: obj };
}

export const AddSaveData = (data) => {
  return { type: 'AddSaveData', val: data };
}

export const DefaultSaveDataIfMissing = (data) => {
  return { type: 'DefaultSaveDataIfMissing', val: data };
}

export const ClearSaveData = (key) => {
  return { type: 'ClearSaveData', val: key };
}

export const CancelSaveData = (key) => {
  return { type: 'CancelSaveData', val: key };
}

export const AfterSave = (data, key) => {
  _currentEntityCopy[key] = cloneDeep(data);
}

export const SetCurrentEntity = (data, key) => {
  _currentEntityCopy[key] = cloneDeep(data);
  return {
    type: 'SetCurrentEntity', val: {
      Key: key,
      Entity: cloneDeep(data)
    }
  };
}

export const ResetCurrentEntity = (key) => {
  return {
    type: 'SetCurrentEntity', val: {
      Key: key,
      Entity: cloneDeep(_currentEntityCopy[key])
    }
  };
}

export const MoveGridColumn = (obj) => {
  return { type: 'MoveGridColumn', val: obj };
}

export const UpdateQueryData = (obj) => {
  return { type: 'UpdateQueryData', val: obj };
}

export const SetGridColumnWidths = (arr) => {
  return { type: 'SetGridColumnWidths', val: arr };
}

export const UpdateGridColumnProp = (obj) => {
  return { type: 'UpdateGridColumnProp', val: obj };
}

export const ToggleColumnPin = (colId) => {
  return { type: 'ToggleColumnPin', val: colId };
}

export const SetTopRow = (obj) => {
  return { type: 'SetTopRow', val: obj };
}

export const SetHighlightRow = (rowId) => {
  return { type: 'SetHighlightRow', val: rowId };
}

export const AddGridRows = (obj) => {
  return { type: 'AddGridRows', val: obj };
}

export const ResetRows = () => {
  return { type: 'ResetRows', val: {} };
}

export const UpdatePageRequests = (obj) => {
  return { type: 'UpdatePageRequests', val: obj };
}

export const SetRowCount = (obj) => {
  return { type: 'SetRowCount', val: obj };
}

export const ToggleRowPin = (obj) => {
  return { type: 'ToggleRowPin', val: obj };
}

export const UpdateColumnWidth = (obj) => {
  return { type: 'UpdateColumnWidth', val: obj };
}

export const ToggleSortBy = (colId) => {
  return { type: 'ToggleSortBy', val: colId };
}

export const ConfirmFilter = (colId) => {
  return { type: 'ConfirmFilter', val: colId };
}

export const ClearFilter = (colId) => {
  return { type: 'ClearFilter', val: colId };
}

export const SetLookupItems = (obj) => {
  return { type: 'SetLookupItems', val: obj };
}

export const ToggleLookupItem = (obj) => {
  return { type: 'ToggleLookupItem', val: obj };
}

export const SetPendingFilter = (obj) => {
  return { type: 'SetPendingFilter', val: obj };
}

export const SetEntityMap = (map) => {
  return { type: 'SetEntityMap', val: map };
}

export const SetLegendItems = (items) => {
  return { type: 'SetLegendItems', val: items };
}

export const SetMapStyles = (styles) => {
  return { type: 'SetMapStyles', val: styles };
}

export const ToggleMapLayer = (layerId) => {
  return { type: 'ToggleMapLayer', val: layerId };
}

export const SetEntityDocuments = (obj) => {
  return { type: 'SetEntityDocuments', val: obj };
}

export const NewDocument = (obj) => {
  return { type: 'NewDocument', val: obj };
}

export const UpdateDocument = (obj) => {
  return { type: 'UpdateDocument', val: obj };
}

export const UpdateEventGrid = (obj, key) => {
  return { type: 'UpdateEventGrid', val: { events: obj, key: key } };
}

export const SetEventResources = (obj) => {
  return { type: 'SetEventResources', val: obj };
}

export const ClearSaveDataForId = (obj) => {
  return { type: 'ClearSaveDataForId', val: obj };
}

export const ToggleEditMode = () => {
  return { type: 'ToggleEditMode' }
}

export const SetEditingWidget = (obj) => {
  return { type: 'SetEditingWidget', val: obj };
}

export const SetEditingScene = (obj) => {
  return { type: 'SetEditingScene', val: obj };
}

export const UpdateScene = () => {
  return { type: 'UpdateScene' };
}

export const SetEditingWidgetBySwid = (id) => {
  return { type: 'SetEditingWidgetBySwid', val: id };
}

export const ResumePendingAction = () => {
  return { type: 'ResumePendingAction' };
}

export const GetScene = (callback) => {
  let deviceId = 1;
  if (isMobileOnly || forceMobile) {
    deviceId = 2;
    if (window.location.href.includes('qualify.mysalesman.com/?app=MySalesmanAdmin') || window.location.href.includes('qualify.mysalesman.com/?app=SuperAdmin')) deviceId = 1
  }
  return dispatch => {
    SendRequest({
      url: 'Scene/GetScene',
      body: {
        DeviceId: deviceId,
        AppUrl: window.location.href
      },
      callback: (data) => {
        if (data.CSS) {
          addCssElement(data.CSS);
        }
        dispatch(getScene(data));
        if (callback) {
          callback();
        }
      }
    });
  }
}

const addCssElement = (css) => {
  var styleElement = document.createElement('style');
  styleElement.appendChild(document.createTextNode(css));
  document.body.appendChild(styleElement);
}

export const ApiRequest = (path, body, callback, isPublic, errorCallback) => {
  if (window.isSalesmanPublic || window.publicCodes.find(x => window.location.href.toLowerCase().includes(x))) {
    PublicRequest({
      url: path,
      body: body,
      callback: (data, reqBody) => {
        callback(data, reqBody);
      }
    });
    return;
  }

  if (_jwtToken) {
    if (!isPublic) {
      let req = {
        url: path,
        body: body,
        callback: (data, reqBody) => {
          callback(data, reqBody);
        }
      }
      if(errorCallback)
        req = {
          ...req,
          errorCallback: (data, reqBody) => {
            errorCallback(data, reqBody);
          }
        }
      SendRequest(req);
    } else {
      let req = {
        url: path,
        body: body,
        callback: (data, reqBody) => {
          callback(data, reqBody);
        }
      }
      if(errorCallback)
        req = {
          ...req,
          errorCallback: (data, reqBody) => {
            errorCallback(data, reqBody)
          }
        }
      PublicRequest(req);
    }
  } else {
    CallAfterSiteLoads(path, body, callback);
  }
}

export const ApiGet = (path, blob, callback) => {
  window._getToken().then(result => {
    fetch(root + path, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + result,
        'Blob': blob,
        'href-origin': window.location.href
      }
    }).then(res => {
      if (res.status !== 200)
        return null;

      return res.blob();
    }).then(response =>
      callback(response)
    );
  });
}

export const ApiSendData = (path, data, callback) => {
  if (window.isSalesmanPublic || window.publicCodes.find(x => window.location.href.toLowerCase().includes(x))) {
    PublicApiSendData(path, data, callback);
    return;
  }
  window._getToken().then(result => {
    fetch(root + path, {
      method: 'POST',
      body: data,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + result,
        'href-origin': window.location.href
      }
    }).then(res => {
      if (res.status === 500) {
        callback();
        res.json().then(x => {
          LogApiError(path, formDataToObject(data), x, result);
          return x;
        });
      } else {
        return res.json();
      }
    }).then(response =>
      callback(response)
    ).catch(err => {
      callback();
      LogApiError(path, formDataToObject(data), err, result);
    });
  });
}

const formDataToObject = (data) => {
  var obj = {};
  data.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
}

export const PublicApiSendData = (path, data, callback) => {
  let pieces = path.split('/');

  fetch(publicRoot + pieces[1], {
    method: 'POST',
    body: data,
    credentials: 'include',
    headers: {
      'href-origin': window.location.href
    }
  }).then(res => {
    return res.json();
  }).then(response =>
    callback(response)
  );
}

const root = 'https://beehive.azure-api.net/';

const SendRequest = (req) => {
  if (window.isSalesmanPublic || window.publicCodes.find(x => window.location.href.toLowerCase().includes(x))) {
    PublicRequest(req);
    return;
  }

  window._getToken().then(result => {
    fetch(root + req.url, {
      method: 'POST',
      body: JSON.stringify(req.body) || {},
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + result,
        'Content-Type': 'application/json',
        'href-origin': window.location.href
      }
    }).then(res => {
      if (res.status === 500) {
        res.json().then(x => {
          LogApiError(req.url, req.body, x, result);
          return x;
        });
      } else {
        let supportInfo = res.headers.get('support-info');
        if (!window.supportInfo && supportInfo) {
          window.supportInfo = supportInfo;
          let supportElement = document.createElement('div');
          var width = window.innerWidth;
          supportElement.innerHTML = supportInfo;
          supportElement.classList.add("support-info");
          document.body.appendChild(supportElement);
          if (!supportInfo.toLowerCase().includes('test')) {
            document.body.classList.add('support-warning');
          }
        }
        return res.json();
      }
    }).then(response => {
      var result = response;
      if (response && response.ActionJson) {
        helpers.actionJson(response.ActionJson);
      }
      if (response && response.ArrayResult) {
        result = response.ArrayResult;
      }
      req.callback(result, req.body);
    }).catch(response => {
      if(req.errorCallback)
        req.errorCallback("failed so horribly")
      LogApiError(req.url, req.body, response, result);
      console.error(response);
    });
  });
}

const LogApiError = (url, request, response, result) => {
  let body = {
    Request: JSON.stringify({ Function: url, ...request }),
    Response: JSON.stringify(response)
  };

  fetch(root + 'Logging/ApiError', {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + result,
      'Content-Type': 'application/json',
      'href-origin': window.location.href
    }
  });
}

const publicRoot = 'https://public311.azure-api.net/Public311Api/';
const mySalesmanRoot = 'https://bhmysalesman.azure-api.net/bhMySalesman/';

const PublicRequest = (req) => {
  if (req.url.includes('Dialogs'))
    return;

  let isSalesman = window.isSalesmanPublic;
  let root = isSalesman ? mySalesmanRoot : publicRoot;

  let pieces = req.url.split('/');
  fetch(root + pieces[1], {
    method: 'POST',
    body: JSON.stringify(req.body) || {},
    headers: {
      'Content-Type': 'application/json',
      'href-origin': window.location.href
    }
  }).then(res => {
    return res.json();
  }).then(response =>
    req.callback(response, req.body)
  ).catch(err => req.callback(err));
}

// export const PublicApiSendData = (path, data, callback) => {
//   window._getToken().then(result => {
//     fetch(publicRoot + path, {
//       method: 'POST',
//       body: data,
//       credentials: 'include',
//       headers: {
//         'Authorization': 'Bearer ' + result
//       }
//     }).then(res => {
//       return res.json();
//     }).then(response =>
//       callback(response)
//     );
//   });
// }

const CallAfterSiteLoads = (path, body, callback) => {
  _jwtLoadListeners.push({
    path: path,
    body: body,
    callback: callback
  });
}

export const ExternalApiGet = (path, callback) => {
  fetch(path, {
    method: 'GET',
    headers: {}
  }).then(res => {
    return res.json();
  }).then(response => callback(response))
    .catch(err => {
      console.log({ err: err });
    });
}
