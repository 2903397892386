import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as actions from '../Stores/Actions/actions';
import * as helpers from './../Utils/Helpers';
import NotFound from './NotFound';

const mapStateToProps = (state, ownProps) => {
  return {
    EditingWidget: state.EditingWidget || {},
    EditMode: state.EditMode,
    FindWidget: state.FindWidget,
    ActiveSWIDs: state.ActiveSWIDs,
    PersistedWidgets: state.PersistedWidgets
  }
}

export class WidgetLoader extends React.PureComponent {
  getWidgets = () => {
    let widgets = Object.keys(this.props.PersistedWidgets).map((swid) => {
      this.props.PersistedWidgets[swid].Widget.IsActive = this.props.ActiveSWIDs[swid] ? true : false;

      let className = this.props.PersistedWidgets[swid].Widget.Parameters.find(x => x.ParameterName === 'ClassName');
      if (className) {
        this.props.PersistedWidgets[swid].Widget.ClassName = className.ParameterValue;
      }

      return this.props.PersistedWidgets[swid];
    });

    return widgets;
  }
  render() {
    return (
      <React.Fragment>
        {
          this.getWidgets().map((item, idx) => (
            <React.Fragment key={idx}>
              {item.Portal &&
                ReactDOM.createPortal(
                  (
                    <div key={item.Widget.SceneWidgetId} id={'swid-' + item.Widget.SceneWidgetId} className={"widget-loader " + (item.Widget.ClassName || '')
                      + (this.props.EditMode && this.props.EditingWidget.SceneWidgetId === item.Widget.SceneWidgetId ? ' editing-this-widget' : '')
                      + (item.Widget.SceneName === 'Engines' ? ' engine-widget' : '')
                      + (this.props.Resizable ? ' has-handle' : '')
                      + (item.Widget.IsActive ? '' : ' hidden-widget')}
                      style={{ gridColumn: item.Widget.Columns, gridRow: item.Widget.Rows }}
                      onMouseDown={this.props.FindWidget ? (e) => { this.finderClick(e) } : undefined}>
                      {item.Element && <React.Fragment>
                        <item.Element widget={item.Widget} />
                        {this.props.Resizable && <div className="resize-handle" onMouseDown={this.startResize}></div>}
                      </React.Fragment>}
                      {!item.Element && <NotFound name={item.Widget.WidgetName} version={item.Widget.WidgetVersion} />}
                    </div>
                  ),
                  item.Portal
                )
              }
              {!item.Portal &&
                <div key={item.Widget.SceneWidgetId} id={'swid-' + item.Widget.SceneWidgetId} className={"widget-loader " + (item.Widget.ClassName || '')
                  + (this.props.EditMode && this.props.EditingWidget.SceneWidgetId === item.Widget.SceneWidgetId ? ' editing-this-widget' : '')
                  + (item.Widget.SceneName === 'Engines' ? ' engine-widget' : '')
                  + (this.props.Resizable ? ' has-handle' : '')
                  + (item.Widget.IsActive ? '' : ' hidden-widget')}
                  style={{ gridColumn: item.Widget.Columns, gridRow: item.Widget.Rows }}
                  onMouseDown={this.props.FindWidget ? (e) => { this.finderClick(e) } : undefined}>
                  {item.Element && <React.Fragment>
                    <item.Element widget={item.Widget} />
                    {this.props.Resizable && <div className="resize-handle" onMouseDown={this.startResize}></div>}
                  </React.Fragment>}
                  {!item.Element && <NotFound name={item.Widget.WidgetName} version={item.Widget.WidgetVersion} />}
                </div>}
            </React.Fragment>
          ))
        }
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(WidgetLoader);