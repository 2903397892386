
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { batch } from 'react-redux';

export const addSaveDataForRecord = (dispatch, userId, sheet, item) => {
  let day = sheet.Days.find(x => x.Active);

  let baseObj = {
    Id: item.Id || null,
    InsertKey: item.InsertKey || null,
    Table: 'ttr.TimeEntry',
    IsEventData: false,
    SaveQueue: 'ttr_TimesheetSaveData',
    IsBaseTable: true,
    EntityTypeId: 100
  }

  batch(() => {
    helpers.addSaveData(dispatch, baseObj, 'UserId', userId);
    helpers.addSaveData(dispatch, baseObj, 'PayPeriodId', sheet.PayPeriodId);
    helpers.addSaveData(dispatch, baseObj, 'WageCodeId', item.WageCodeId);
    helpers.addSaveData(dispatch, baseObj, 'StartDate', item.MoveToDate ? item.MoveToDate : day.Date);
    helpers.addSaveData(dispatch, baseObj, 'Duration', item.Duration);
    helpers.addSaveData(dispatch, baseObj, 'Note', item.Note || '');
    helpers.addSaveData(dispatch, baseObj, 'IsDeleted', item.IsDeleted || false);

    //999 = leave request entity
    if (item.TimeEntity.ProjectTypeLid === 999) {
      helpers.addSaveData(dispatch, baseObj, 'EntityId', item.TimeEntity.EntityId);
      helpers.addSaveData(dispatch, baseObj, 'EntityTypeId', null);
      helpers.addSaveData(dispatch, baseObj, 'LeaveRequestId', item.TimeEntity.EntityId);
    } else {
      helpers.addSaveData(dispatch, baseObj, 'EntityId', item.TimeEntity.EntityId);
      helpers.addSaveData(dispatch, baseObj, 'EntityTypeId', item.TimeEntity.EntityTypeId);
      helpers.addSaveData(dispatch, baseObj, 'LeaveRequestId', null);
    }

    item.MoveToDate = undefined;
  });
}