import Honeycomb, { GetEmailDetailsResponse, LstGetConsumerBudgetData } from "@beehive-development/honeycomb";
import React from "react";

// DISCLAIMER: Yes, you're not supposed to use things from `react-dom/server` on the frontend in standard use cases.
// Doing so adds quite a bit of additional bundle size, but there's no other option that's feasible in this version of `react-dom`.
// I would upgrade the version of the package and use a better method of rending JSX to HTML, but I'm scared to upgrade :)
import { renderToStaticMarkup } from "react-dom/server";

// @ts-expect-error
// See `config-override.js`, files with the `embedded` extension are treated as strings.
import htmlWrapper from "./EmailDetails.html.embedded";

// @ts-expect-error
// See `config-override.js`, files with the `embedded` extension are treated as strings.
import embeddedCss from "./EmailDetails.css.embedded";

/**
 * Pattern that matches with templates containing the `EmbeddedInnerHtml` identifier.
 * 
 * **Example**:
 * ```html
 * <body>
 *  {{ EmbeddedInnerHtml }}
 *  ^^^^^^^^^^^^^^^^^^^^^^^ MATCH!
 * </body>
 * ```
 * 
 * @see getEmailDetailsAsHtml
 */
const embeddedInnerHtmlTemplatePattern = /{{\s+EmbeddedInnerHtml\s+}}/g;

/**
 * Pattern that matches with templates containing the `EmbeddedCSS` identifier.
 * 
 * **Example**:
 * ```html
 * <style>
 *  {{ EmbeddedCSS }}
 *  ^^^^^^^^^^^^^^^^^ MATCH!
 * </style>
 * ```
 * 
 * @see getEmailDetailsAsHtml
 */
const embeddedCSSTemplatePattern = /{{\s+EmbeddedCSS\s+}}/g;

/**
 * Pattern that matches with Hex3 colors.
 * 
 * **Example**:
 * ```css
 * background-color: #fff;
 *                   ^^^^ MATCH!
 * ```
 */
const hex3RegEx = /^#([0-9a-f]{3})$/;

/**
 * Pattern that matches with Hex6 colors.
 * 
 * **Example**:
 * ```css
 * background-color: #ffffff;
 *                   ^^^^^^^ MATCH!
 * ```
 */
const hex6RegEx = /^#([0-9a-f]{6})$/;

type RgbaColor = {
    r: number;
    g: number;
    b: number;
    a: number;
};

/**
 * Returns a `RgbaColor` from a Hex3 or Hex6 color.
 */
const rgbaColorFromHexColor = (source: string): RgbaColor => {
    if (hex3RegEx.test(source)) {
        return {
            r: parseInt(source[1], 16) * 0x11,
            g: parseInt(source[2], 16) * 0x11,
            b: parseInt(source[3], 16) * 0x11,
            a: 255,
        };
    }

    if (hex6RegEx.test(source)) {
        return {
            r: parseInt(source.substring(1, 2), 16),
            g: parseInt(source.substring(3, 2), 16),
            b: parseInt(source.substring(5, 2), 16),
            a: 255,
        };
    }

    return { r: 0, g: 0, b: 0, a: 255 };
}

/**
 * Calculates a numerical value for representing the intensity of an `RgbaColor`.
 */
const getRgbaColorIntensity = (source: RgbaColor): number =>
    source.r * 0.299 + source.g * 0.587 + source.b * 0.114;

/**
 * Returns a white or black CSS color depending on the intensity of a Hex3 or Hex6 color. 
 */
const getContrastingColor = (source: string): string => {
    const convertedColor = rgbaColorFromHexColor(source);
    const colorIntensity = getRgbaColorIntensity(convertedColor);

    if (colorIntensity > 186) {
        return "rgba(0, 0, 0, 255)";
    }

    return "rgba(255, 255, 255, 255)";
}

export const getEmailDetailsAsHtml = async (consumerId: number, step: string): Promise<string> => {
    // @ts-expect-error
    // TypeScript can't assert `_getToken` exists due to it being placed in the `window` object at runtime in vanilla JS.
    const jwtToken = window.isSalesmanPublic ? await window._getToken : await window._getToken();

    const honeycomb = new Honeycomb({
        requestHeaders: {
            Authorization: `Bearer ${jwtToken}`,
            "href-origin": window.location.href,
        },
    });

    let embeddedInnerHtml 
    if(step === "Step1Panel") {
        const emailDetails: GetEmailDetailsResponse = await honeycomb.mySalesman.getEmailDropoffDetails(consumerId)
            .then(x => x.json());
        embeddedInnerHtml = renderToStaticMarkup(<EmailDetails1 {...emailDetails.emailDetails} />);
    }  else if (step === "Step2Panel") {
        const emailDetails: GetEmailDetailsResponse = await honeycomb.mySalesman.getEmailDropoffDetails(consumerId)
            .then(x => x.json());
        embeddedInnerHtml = renderToStaticMarkup(<EmailDetails2 {...emailDetails.emailDetails} />);
    } else if (step === "Step3Panel") {
        const emailDetails: GetEmailDetailsResponse = await honeycomb.mySalesman.getEmailDetails(consumerId)
            .then(x => x.json());
        embeddedInnerHtml = renderToStaticMarkup(<EmailDetails3 {...emailDetails.emailDetails} />);
    }

    const asHtml = htmlWrapper.replace(embeddedInnerHtmlTemplatePattern, embeddedInnerHtml)
        .replace(embeddedCSSTemplatePattern, embeddedCss);

    return new Promise((resolve) => resolve(asHtml));
}

const EmailDetails1 = (emailProps: LstGetConsumerBudgetData) => {
    return (
        <div id="email-details">
            <header style={{ backgroundColor: emailProps.BannerColor }}>
                <img id="company-logo" src={emailProps.Company.Logo} alt={`${emailProps.Company.Name} Logo`} />
                <div id="contact-info">
                    <span style={{ color: getContrastingColor(emailProps.BannerColor) }}>{emailProps.PrimaryContact.Address}</span>
                    <span style={{ color: getContrastingColor(emailProps.BannerColor) }}>{emailProps.PrimaryContact.City}, {emailProps.PrimaryContact.State} {emailProps.PrimaryContact.Zip}</span>
                    <a style={{ color: getContrastingColor(emailProps.BannerColor) }} href={`tel:+1${emailProps.PrimaryContact.Phone}`}>{emailProps.PrimaryContact.Phone}</a>
                    <a style={{ color: getContrastingColor(emailProps.BannerColor) }} href={`mailto:${emailProps.PrimaryContact.Email}`}>{emailProps.PrimaryContact.Email}</a>
                </div>
            </header>

            <br />

            <main>
                <section id="request-information">
                    <p id="request-number">
                        <strong>Sales Request</strong>
                        <br />
                        {emailProps.RequestNumber}
                    </p>

                    <p id="contact-information">
                        <strong>Contact Information</strong>
                        <br />
                        {emailProps.Consumer.Name}
                        <br />
                        {emailProps.Consumer.PhoneNumber}
                        <br />
                        <a href={`mailto:${emailProps.Consumer.Email}`}>{emailProps.Consumer.Email}</a>
                    </p>
                </section>
            </main>
        </div>
    );
};

const EmailDetails2 = (emailProps: LstGetConsumerBudgetData) => {
    return (
        <div id="email-details">
            <header style={{ backgroundColor: emailProps.BannerColor }}>
                <img id="company-logo" src={emailProps.Company.Logo} alt={`${emailProps.Company.Name} Logo`} />
                <div id="contact-info">
                    <span style={{ color: getContrastingColor(emailProps.BannerColor) }}>{emailProps.PrimaryContact.Address}</span>
                    <span style={{ color: getContrastingColor(emailProps.BannerColor) }}>{emailProps.PrimaryContact.City}, {emailProps.PrimaryContact.State} {emailProps.PrimaryContact.Zip}</span>
                    <a style={{ color: getContrastingColor(emailProps.BannerColor) }} href={`tel:+1${emailProps.PrimaryContact.Phone}`}>{emailProps.PrimaryContact.Phone}</a>
                    <a style={{ color: getContrastingColor(emailProps.BannerColor) }} href={`mailto:${emailProps.PrimaryContact.Email}`}>{emailProps.PrimaryContact.Email}</a>
                </div>
            </header>

            <br />

            <main>
                <section id="request-information">
                    <p id="request-number">
                        <strong>Sales Request</strong>
                        <br />
                        {emailProps.RequestNumber}
                    </p>

                    <p id="property-information">
                        <strong>Property Information</strong>
                        <br />
                        {emailProps.Consumer.Address1}
                        <br />
                        {emailProps.Consumer.Address2}
                    </p>

                    <p id="contact-information">
                        <strong>Contact Information</strong>
                        <br />
                        {emailProps.Consumer.Name}
                        <br />
                        {emailProps.Consumer.PhoneNumber}
                        <br />
                        <a href={`mailto:${emailProps.Consumer.Email}`}>{emailProps.Consumer.Email}</a>
                    </p>
                </section>
            </main>
        </div>
    );
};

const EmailDetails3 = (emailProps: LstGetConsumerBudgetData) => {
    return (
        <div id="email-details">
            <header style={{ backgroundColor: emailProps.BannerColor }}>
                <img id="company-logo" src={emailProps.Company.Logo} alt={`${emailProps.Company.Name} Logo`} />
                <div id="contact-info">
                    <span style={{ color: getContrastingColor(emailProps.BannerColor) }}>{emailProps.PrimaryContact.Address}</span>
                    <span style={{ color: getContrastingColor(emailProps.BannerColor) }}>{emailProps.PrimaryContact.City}, {emailProps.PrimaryContact.State} {emailProps.PrimaryContact.Zip}</span>
                    <a style={{ color: getContrastingColor(emailProps.BannerColor) }} href={`tel:+1${emailProps.PrimaryContact.Phone}`}>{emailProps.PrimaryContact.Phone}</a>
                    <a style={{ color: getContrastingColor(emailProps.BannerColor) }} href={`mailto:${emailProps.PrimaryContact.Email}`}>{emailProps.PrimaryContact.Email}</a>
                </div>
            </header>

            <br />

            <main>
                <section id="request-information">
                    <p id="request-number">
                        <strong>Sales Request</strong>
                        <br />
                        {emailProps.RequestNumber}
                    </p>

                    <p id="estimated-budget">
                        <strong>Estimated Budget</strong>
                        <br />
                        ${emailProps.BudgetMin} - ${emailProps.BudgetMax}
                    </p>

                    <p id="property-information">
                        <strong>Property Information</strong>
                        <br />
                        {emailProps.Consumer.Address1}
                        <br />
                        {emailProps.Consumer.Address2}
                    </p>

                    <p id="contact-information">
                        <strong>Contact Information</strong>
                        <br />
                        {emailProps.Consumer.Name}
                        <br />
                        {emailProps.Consumer.PhoneNumber}
                        <br />
                        <a href={`mailto:${emailProps.Consumer.Email}`}>{emailProps.Consumer.Email}</a>
                    </p>
                </section>
                <img id="map-preview" src={`https://bhpublic.blob.core.windows.net/imagepicker/MapSnapshot/${emailProps.TenantId}/${emailProps.ConsumerId}/BudgetMap.jpg`} alt="Map Preview" height="300" width="450"/>
            </main>

            <section id="budget-summary">
                <h2>Budget Summary</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Type</th>
                            <th>Units</th>
                            <th>Estimate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emailProps.Budget.map((x,idx) => {
                            if (x.Type.includes("Fence Removal")) {
                                return (
                                    <tr key={idx}>
                                        <td className="budget-item">
                                            <strong>{x.Item} </strong>
                                        </td>
                                        <td className="budget-type">{x.Type} </td>
                                        <td className="budget-units">{x.Units} LFT</td>
                                        <td className="budget-estimate">${x.Max.toLocaleString("en-US")} </td>
                                    </tr>
                                );
                            }

                            return (
                                <tr key={idx}>
                                    <td className="budget-item">
                                        <strong>{x.Item} </strong>
                                    </td>
                                    <td className="budget-type">{x.Type} </td>
                                    <td className="budget-units">{x.Units} </td>
                                    <td className="budget-estimate">{x.Estimate} </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <p id="total-estimate">
                    Total Estimate: <strong>${emailProps.BudgetMin} - ${emailProps.BudgetMax}</strong>
                </p>
            </section>
        </div>
    );
};

export default EmailDetails3;