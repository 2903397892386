import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import EditItem from './EditItem';

const mapStateToProps = (state) => {
  return {
    UserList: state.met_EntityMetadata.UserList,
  };
}

export class DocumentItem extends PureComponent {
  setActive = () => {
    if (this.props.model.DocTypeId === 2)
      this.props.model.Hyperlink = this.props.model.DisplayName;

    this.props.dispatch(actions.UpdateDocument({
      ...this.props.model,
      DocumentsKey: this.props.DocumentsKey,
      Active: true,
      ResetActive: true
    }));
  }
  render() {
    let associatedWith = this.props.UserList.find(x => x.UserId === Number(this.props.model.AssociatedWith));
    return (
      !this.props.model.Active ?
        <div className={"document-item " + (this.props.odd ? "odd" : "")} onClick={this.setActive} >
          <div className={"doc-btn " + (this.props.model.IsDisplay ? "highlight" : "")}>
            <div>Display</div>
          </div>
          <div className={"doc-btn " + (this.props.model.IsPublic ? "highlight" : "")}>
            <div>Public</div>
          </div>
          <div className={"doc-btn " + (this.props.model.IsGroup ? "highlight" : "")}>
            <div>Group</div>
          </div>
          <div className="doc-details">
            <div className="top-detail">
              <div className="doc-date">{this.props.model.DisplayDate}</div>
              <div className="doc-name">{associatedWith ? associatedWith.Name : ''}</div>
            </div>
            <div className="bottom-detail">
              <div className="doc-comment">{this.props.NameOnlyPreview ? this.props.model.DisplayName : (this.props.model.Comment || '-')}</div>
            </div>
          </div>
        </div>
        :

        <EditItem model={this.props.model} DocumentsKey={this.props.DocumentsKey} CurrentEntity={this.props.CurrentEntity} SWID={this.props.SWID} dirtySaveQueue={this.props.dirtySaveQueue} SaveQueue={this.props.SaveQueue} RefreshDocuments={this.props.RefreshDocuments} />
    )
  }
}

export default connect(mapStateToProps)(DocumentItem);