import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';

const mapStateToProps = (state, ownProps) => {
  let dateOnly = ownProps.widget.Parameters.find(x => x.ParameterName === 'DateOnly');

  return {
    DateOnly: dateOnly ? helpers.stringToBool(dateOnly.ParameterValue) : false
  };
}

export class DateTimeBar extends React.PureComponent {
  state = {
    DateText: '',
    TimeText: '',
    Interval: null
  }

  componentDidMount() {
    this.updateTime();
    let interval = setInterval(this.updateTime, 10 * 1000);
    this.setState({ Interval: interval });
  }
  componentWillUnmount() {
    if (this.state.Interval) {
      clearInterval(this.state.Interval);
    }
  }

  updateTime = () => {
    let date = new Date();
    let dateString = date.toDateString().slice(0, -4);
    let timeParts = date.toLocaleTimeString().split(' ');
    let time = timeParts[0].split(':');

    this.setState({
      DateText: dateString,
      TimeText: `${time[0]}:${time[1]} ${timeParts[1]}`
    });
  }

  render() {
    return (
      <div className="site-header">
        <div className="time-ticker">
          <div className="date-text">{this.state.DateText}</div>
          {!this.props.DateOnly && <div className="time-text">{this.state.TimeText}</div>}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DateTimeBar);