import React from 'react';
import { EmptyCheck, Checked, IndeterminateCheck, EmptyRadio, FillRadio } from '../../../Icons/Icons';
import * as helpers from '../../../../Utils/Helpers';

const CardItem = props => {
  let getClasses = () => {
    let result = '';

    if (props.card.IsParent && props.card.TopLevel) {
      result += 'top-level-parent-card';
    } else if (props.card.IsParent) {
      result += 'parent-card';
    }

    if (props.card.Expand) {
      result += ' expand';
    }

    if (props.selected && props.selected.Id === props.card.Id && props.card.Id !== undefined) {
      result += ' selected';
    } else if (props.card.Id && props.card.GobId && props.card.Id === props.card.GobId) {
      result += ' selected';
    }

    if (props.multiselect) {
      result += ' multiselect';
    }

    if (props.card.IconSource) {
      result += ' icon-card';
    }

    return result;
  }

  let onClick = (e) => {
    if (props.clickableParent) {
      parentClickableClick(e);
      return;
    }
    if (props.multiselect && !props.card.IsParent) {
      toggleActive(e);
    } else if (!props.card.IsParent) {
      props.parentClick(props.card);
    } else {
      props.card.Expand = !props.card.Expand;
      props.refresh(props.multiselect);
    }
  }

  let parentClickableClick = (e) => {
    if (!props.card.IsParent || (!props.card.TopLevel && !helpers.elementOrAncestorHasClass(e.target, 'chevron'))) {
      props.parentClick(props.card);
    } else {
      props.card.Expand = !props.card.Expand;
      props.refresh(props.multiselect);
    }
  }

  let toggleActive = (e) => {
    e.stopPropagation();

    props.card.Active = !props.card.Active

    if (props.card.IsParent) {
      props.card.Children.filter(x => !x.IsRadio).forEach(x => {
        x.Active = props.card.Active;
        if (x.IsParent) {
          x.Children.filter(y => !y.IsRadio).forEach(y => y.Active = props.card.Active);
        }
      });
    }

    if (props.card.IsRadio && props.card.Parent) {
      props.card.Parent.Children.filter(x => x.Id !== props.card.Id).forEach(x => x.Active = false);
    }

    props.dirtySaveQueueCheck();
    props.refresh(props.multiselect);
  }

  let activeState = '';
  if (props.multiselect) {
    let result = props.card.Active ? 'on' : 'off';
    let hasTrueChildren = false;
    let hasFalseChildren = false;

    if (props.card.IsParent) {
      props.card.Children.forEach(x => {
        if (x.Active) {
          hasTrueChildren = true;
        } else {
          hasFalseChildren = true;
        }

        if (x.IsParent) {
          x.Children.forEach(y => {
            if (y.Active) {
              hasTrueChildren = true;
            } else {
              hasFalseChildren = true;
            }
          })
        }
      })

      if (hasTrueChildren && hasFalseChildren) {
        result = 'indeterminate';
      }

    }

    activeState = result;
  }

  let getStyle = () => {
    let obj = { marginLeft: (props.level * 15) + 'px' };

    if (props.card.Style) {
      try {
        let styles = props.card.Style.split(',').map(x => x.trim());
        styles.forEach(x => {
          let pieces = x.split(':').map(x => x.trim());
          if (pieces.length = 2) {
            obj[pieces[0]] = pieces[1];
          }
        })
      } catch {
        console.error('error parsing card style: ' + props.card.Style);
      }
    }
    return obj;
  }

  let bubbleQty = null;
  if (props.card.Text1 && props.card.Text1 === 'Documents') {
    let evtBubble = props.curEvent && props.curEvent.DocumentCount;
    let assetBubble = props.curEntity && props.curEntity.DocumentCount;

    if (evtBubble || assetBubble) {
      let parentElement = document.getElementById('swid-' + props.SWID);
      let isModal = helpers.elementOrAncestorHasClass(parentElement, 'modal-layer');
      if (evtBubble && isModal) {
        bubbleQty = props.curEvent.DocumentCount;
      }

      if (assetBubble && !isModal) {
        bubbleQty = props.curEntity.DocumentCount;
      }
    }
  }

  return (
    <React.Fragment>
      <div className={"card-item " + getClasses()} onClick={onClick}
        style={getStyle()}>
        {props.card.IsParent &&
          <div className="chevron">
            <div>›</div>
          </div>}
        {props.card.IconSource &&
          <div className={"card-icon" + (props.card.IconClickToPan ? ' click-to-pan' : '')} onClick={(e) => { props.panToCard(e, props.card) }}>
            <img src={props.card.IconSource} onError={(e) => { e.target.src = 'https://bhpublic.blob.core.windows.net/imagepicker/no-image.png'; e.target.onerror = null; }}></img>
          </div>}
        {props.card.Text1 !== undefined && <div className="text-1">
          {props.card.Text1}
          {bubbleQty && <div className="doc-bubble"><div>{bubbleQty}</div></div>}
        </div>}
        {props.card.Text2 !== undefined && <div className="text-2">{props.card.Text2}</div>}
        {props.card.Text3 !== undefined && <div className="text-3">{props.card.Text3}</div>}
        {props.multiselect && /*!props.card.TopLevel &&*/
          <div className="multiselect-box" onClick={toggleActive}>
            {activeState === 'on' && !props.card.IsRadio && <Checked />}
            {activeState === 'off' && !props.card.IsRadio && <EmptyCheck />}
            {activeState === 'on' && props.card.IsRadio && <FillRadio />}
            {activeState === 'off' && props.card.IsRadio && <EmptyRadio />}
            {activeState === 'indeterminate' && <IndeterminateCheck />}
          </div>}
      </div>
      {props.card.Expand && props.card.Children.map((x, idx) => (
        <CardItem key={idx}
          card={x}
          parentClick={props.parentClick}
          refresh={props.refresh}
          selected={props.selected}
          level={props.level + 1}
          multiselect={props.multiselect}
          clickableParent={props.clickableParent}
          dirtySaveQueueCheck={props.dirtySaveQueueCheck} />
      ))}
    </React.Fragment>
  );
}

export default CardItem